import {
  OCard,
  OCardBody,
  OLoader,
  ONotification,
  ORFieldInput,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LimitChart } from "../../../components/limit-chart";
import { useManageOriginatorCreditLimit } from "./manage-originator-credit-limit.hook";
import { updateOriginatorCreditLimitFormFields } from "./manage-originator-credit-limit.form";

export const ManageOriginatorCreditLimit = () => {
  const {
    form,
    loading,
    loadingSubmit,
    onSubmit,
    creditManagerOriginatorLimit,
    isEnableCreditForManagement,
    doneFetching,
    hasError,
    isOriginatorNotFoundError,
    fetchOriginatorDetails,
  } = useManageOriginatorCreditLimit();

  if (loading || !doneFetching) {
    return (
      <OCard>
        <OCardBody className="position-relative">
          <OLoader absolute />
        </OCardBody>
      </OCard>
    );
  }

  if (hasError && !isOriginatorNotFoundError) {
    return (
      <ErrorComponent
        messageTitle="Não foi possível buscar os dados do originador"
        messageParagraph="Clique no botão para tentar novamente"
      >
        <TryAgainButton onClick={() => fetchOriginatorDetails()} />
      </ErrorComponent>
    );
  }

  if (isOriginatorNotFoundError) {
    return (
      <OCard>
        <OCardBody>
          <OTypography>Esta empresa não é um originador.</OTypography>
        </OCardBody>
      </OCard>
    );
  }

  if (!isEnableCreditForManagement) {
    return (
      <ONotification type="info">
        <OTypography type="dark">
          O originador não possui nenhum subtipo elegível para gestão de limite
          de crédito
        </OTypography>
      </ONotification>
    );
  }

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Gerenciar Limite de Crédito"
          description="Gerencie o limite de crédito que a empresa possui para oferecer aos seus clientes."
        />
      }
      actions={
        <LoadingButton loading={loading || loadingSubmit} onClick={onSubmit}>
          Atualizar limite
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <div className="d-flex flex-column gap-4">
          <LimitChart data={creditManagerOriginatorLimit} />
          <OCard>
            <OCardBody className="position-relative">
              <ORFieldInput
                {...updateOriginatorCreditLimitFormFields.newLimit}
              />
            </OCardBody>
          </OCard>
        </div>
      </FormProvider>
    </FormTemplate>
  );
};
