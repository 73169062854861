import { OToastManager } from "@maestro/core";
import { AxiosError } from "axios";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { RouterButton } from "components/router-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { logger } from "utils/logger";
import { WorkflowProdutoLeadById } from "../../../../../routes/workflow.route-params";
import { useLeadApproval } from "../workflow-lead-details.hook";
import { workflowPendenciesGrid } from "./workflow-pendencies.grid";

export const WorkflowPendenciesPage = () => {
  const { id } = useParams<WorkflowProdutoLeadById>();
  const [loading, setLoading] = useState(false);

  if (!id) throw new Error("No id");

  const { callService: getWorkflowLeadsPendencies, value: pendencies } =
    useServiceCall(service.hubCreditManager.getWorkflowLeadsPendencies);

  const {
    callService: getWorkflowLeadsProcessorsExecuting,
    value: processorsExecuting,
  } = useServiceCall(
    service.hubCreditManager.getWorkflowLeadsProcessorsExecuting,
  );

  const hasOpenPendencies = pendencies?.some((p) => p.status === "PENDING");

  const hasApprovalContext = processorsExecuting?.some(
    (p) => p.processorType === "PENDENTE_APROVACAO",
  );

  const { evaluateLead } = useLeadApproval();

  const sendLeadPendencies = useCallback(() => {
    try {
      setLoading(true);
      evaluateLead("ADDED_PENDENCY", processorsExecuting);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  }, [evaluateLead, processorsExecuting]);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(
        () =>
          getWorkflowLeadsPendencies(id).then(({ success, response }) => {
            if (!success) throw new Error("Erro ao buscar detalhes do lead");
            return response.data ?? [];
          }),
        {
          customStoreOptions: {
            remove: async (data) => {
              try {
                await service.hubCreditManager.deleteWorkflowLeadPendency({
                  id: data.id,
                });

                OToastManager.success("Pendência removida com sucesso!");
              } catch (e) {
                const status = (e as AxiosError<unknown>)?.response?.status;

                if (status === 401)
                  OToastManager.danger("Usuário(a) sem permissão.");
                else
                  OToastManager.danger(
                    "Houve um problema ao remover a pendência.",
                  );
              }
            },
          },
        },
      ),
    [getWorkflowLeadsPendencies, id],
  );

  useEffect(() => {
    getWorkflowLeadsProcessorsExecuting(id);
  }, [getWorkflowLeadsProcessorsExecuting, id]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Pendências" />}
      actions={
        <>
          {hasApprovalContext && (
            <ButtonWithConfirmation
              confirmationModal={{
                title:
                  "Tem certeza que deseja enviar as pendências em aberto para correção?",
                loading,
              }}
              disabled={!hasOpenPendencies}
              type="info"
              onConfirm={sendLeadPendencies}
            >
              Enviar pendências
            </ButtonWithConfirmation>
          )}
          <RouterButton
            type="dark"
            outline
            role={roles.workflow.product.leadsDetailsPendenciesAddPage.role}
            href={corporateRouter.routes.workflow.product.leads.details.pendencies.add.path(
              {
                id,
              },
            )}
          >
            Adicionar
          </RouterButton>
          <RefreshGridButton
            onClick={() => !dataSource?.isLoading() && dataSource?.reload()}
          />
        </>
      }
    >
      <ODataGridGenerator
        grid={workflowPendenciesGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
