import { OToastManager } from "@maestro/core";
import { OTypography } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const buildDeskConfigPipelineGrid = (configIdentifier: string) =>
  ({
    datagrid: {
      noDataText: "Nenhum relacionamento",
      dataSource: dataSourceCustomStoreGenerator(
        () =>
          service.onboarding
            .getDeskOnboardingConfigPipeline({
              deskOnboardingConfigIdentifier: configIdentifier,
            })
            .then(({ data }) => data ?? [])
            .catch(() => {
              const validator = "Erro ao buscar os pipelines";

              OToastManager.danger(validator);
              throw new Error(validator);
            }),
        {
          customStoreOptions: {
            remove: async (data) => {
              try {
                if (!data.uuid) throw new Error("No uuid");

                await service.onboarding.removePipelineOnDeskOnboardingConfig(
                  data.uuid,
                );

                OToastManager.success("Pipeline removido com sucesso.");
              } catch (error) {
                OToastManager.danger("Erro ao remover pipeline");
              }
            },
          },
        },
      ),
    },
    columns: [
      {
        role: roles.ferramentas.product
          .onboardingWorkflowsConfigsDeskPipelinesConfigPage.role,
        cellRender: ({ data }) => (
          <DetailsLink
            href={corporateRouter.routes.ferramentas.product.onboarding.workflowsConfigs.desksConfigs.details.pipelines.details.path(
              {
                configIdentifier,
                uuid: data.uuid,
              },
            )}
          />
        ),
      },
      {
        dataField: "uuid",
        caption: "Uuid",
      },
      {
        dataField: "order",
        caption: "Ordem",
      },
      {
        dataField: "pipelineType",
        caption: "Tipo",
      },
      {
        caption: "Pipeline",
        dataField: "pipeline.pipelineName",
      },
      {
        caption: "Argumentos",
        dataField: "pipelineArgument",
        cellRender: ({ data }) => JSON.stringify(data.pipelineArgument),
      },
      {
        caption: "Configuração",
        cellRender: ({ data }) => (
          <div className="d-flex flex-column gap-2">
            <OTypography>{`${data.deskOnboardingConfig.configIdentifier} - ${data.deskOnboardingConfig.configName}`}</OTypography>
            <OTypography type="default">
              {data.deskOnboardingConfig.configDescription}
            </OTypography>
          </div>
        ),
      },
      {
        caption: "Ações",
        formItem: { visible: false },
        cellRender: ({ data, component, rowIndex, row }) =>
          !row.isEditing && (
            <DataGridAction
              actions={[
                {
                  icon: { category: "orq", icon: "orq-edit-trash" },
                  label: "Remover",
                  onClick: () => {
                    component.option(
                      "editing.texts.confirmDeleteMessage",
                      `Você confirma que o pipeline '${data?.uuid}' será removido?`,
                    );
                    component.deleteRow(rowIndex);
                  },
                },
              ]}
            />
          ),
      },
      {
        type: "buttons",
        buttons: [
          {
            hint: "Salvar",
            icon: "save",
            name: "save",
          },
          {
            hint: "Cancelar",
            icon: "undo",
            name: "cancel",
          },
        ],
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    Awaited<
      ReturnType<typeof service.onboarding.getDeskOnboardingConfigPipeline>
    >["data"][number]
  >);
