import { OCheckbox, OLabel, ORFieldCheckboxGroup } from "@maestro/react";
import { useFormContext } from "react-hook-form";

interface CheckboxFieldProps
  extends React.ComponentProps<typeof ORFieldCheckboxGroup> {
  checkboxLabel: string;
}

export const CheckboxField = ({
  checkboxLabel,
  name,
  ...rest
}: CheckboxFieldProps) => {
  const groupFieldName = name + "CheckboxGroup";
  const { setValue, watch } = useFormContext();

  const checkboxWatch = watch(name) as boolean;

  return (
    <ORFieldCheckboxGroup {...rest} name={groupFieldName}>
      <div className="d-flex align-items-center gap-2">
        <OCheckbox
          id={`${rest.id}-checkbox`}
          value="true"
          size="sm"
          checked={!!checkboxWatch}
          onInput={() => setValue(name, !checkboxWatch)}
          disabled={rest.disabled}
        />
        <OLabel htmlFor={`${rest.id}-checkbox`} disabled={rest.disabled}>
          {checkboxLabel}
        </OLabel>
      </div>
    </ORFieldCheckboxGroup>
  );
};
