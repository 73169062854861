import { OTabHeader } from "@maestro/react";
import styled from "styled-components";

export const OTabHeaderStyled = styled(OTabHeader)`
  div {
    width: 100%;

    & > o-tab:last-child {
      width: 100%;

      & > button:hover {
        border-color: #d6d6d6;
        cursor: default;
      }
    }
  }
`;
