import { OTypography } from "@maestro/react";
import { CustomPagination } from "components/custom-pagination";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import {
  NotificationMessage,
  NotificationOperation,
  SharkDataGridResponse,
} from "services/shark/models/responses";
import { EstimulosUsersDetail } from "../users-grid/cobrancas-estimulos-users-detail.grid";
import { useCobrancasEstimulosMensagens } from "./cobrancas-estimulos-mensagens.hook";
import { MensagemGridActions } from "./mensagens-grid-actions.component";

const estimulosMensagensGrid = {
  datagrid: {
    noDataText: "Nenhum estímulo encontrado.",
    headerFilter: { visible: true, allowSearch: false },
  },
  columns: [
    {
      caption: "Data",
      dataField: "insertedAt",
      dataType: "datetime",
    },
    {
      caption: "Tipo",
      dataField: "type",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => (
        <MensagemGridActions
          id={data.id}
          title={data.title}
          subtitle={data.subTitle}
          body={data.content}
        />
      ),
    },
  ],
  paging: {
    enabled: false,
  },
  masterDetail: {
    component: EstimulosUsersDetail,
    enabled: true,
  },
} satisfies ODataGridGeneratorConfig<NotificationMessage[][number]>;

type CobrancasEstimulosMensagensDetailProps = MasterDetailComponentProps<
  SharkDataGridResponse<NotificationOperation>["data"][number]
>;

export const EstimulosMensagensDetail = ({
  data: {
    data: { id },
  },
}: CobrancasEstimulosMensagensDetailProps) => {
  const gridRef = useGridRef();
  const { dataSource, totalItems, page, setPage, rows, setRows } =
    useCobrancasEstimulosMensagens(id);

  return (
    <>
      <OTypography size="md" weight="500" className="mb-3">
        Estímulos
      </OTypography>
      <ODataGridGenerator
        grid={estimulosMensagensGrid}
        gridRef={gridRef}
        dataSource={dataSource}
      />
      <CustomPagination
        totalCount={totalItems}
        currentPage={page}
        setCurrentPage={setPage}
        pagination={[5, 10, 15]}
        selectedPaginationSize={rows}
        setSelectedPaginationSize={setRows}
      />
    </>
  );
};
