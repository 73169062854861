import { OField, OLoader } from "@maestro/react";
import { SelectSearch } from "components/select-search";
import { GetAdminSettlementStrategiesBySettlementIdResponse } from "services/quickfin/models";
import { remapHookFormRegister } from "../../../../../../antecipacao-de-cartoes/components/generic-bank-data/generic-bank-data.utils";
import { useSettlementStrategySearch } from "./settlement-strategy-search.hook";
import { PredicateFields } from "./settlement-strategy-search.types";

interface CompanySearchProps {
  filter: (input: string) => string;
  initialValue:
    | GetAdminSettlementStrategiesBySettlementIdResponse["estrategiaComercial"]
    | GetAdminSettlementStrategiesBySettlementIdResponse["cedente"];
  label: string;
  name: PredicateFields;
  placeholder: string;
}

export const SettlementStrategySearch = ({
  filter,
  initialValue,
  label,
  name,
  placeholder,
}: CompanySearchProps) => {
  const { localForm, loading, options, outerSetValue } =
    useSettlementStrategySearch(initialValue, name, filter);

  const {
    formState: {
      errors: { input: error },
    },
    watch,
  } = localForm;

  const inputWatch = watch("input");

  return (
    <div className="d-flex align-items-center gap-2">
      <OField
        label={label}
        htmlFor="input"
        error={!!error}
        message={error?.message as string}
      >
        <SelectSearch
          id="input"
          name="input"
          placeholder={placeholder}
          options={options}
          value={inputWatch}
          className="w-100"
          maxOptions={50}
          eager={false}
          innerProps={{
            input: {
              ...remapHookFormRegister(localForm.register("input")),
              handleClear: () => outerSetValue(name, undefined),
            },
          }}
        />
      </OField>
      {loading && <OLoader className="d-block" size="sm" />}
    </div>
  );
};
