import {
  modalManager,
  OButton,
  OModal,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { CanvasDevToolsProps } from "../../canva-devtools.types";

export const ReadOnlyModal = ({ setReadOnly }: CanvasDevToolsProps) => {
  return (
    <OModal id="read-only-modal" position="center" size="md">
      <OModalHeader closeButton>
        <OTypography size="lg">
          Deseja ativar o modo de edição do workflow?
        </OTypography>
        <OTypography>
          No modo de edição as informação serão salvas automaticamente.
        </OTypography>
      </OModalHeader>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide("read-only-modal")}
          >
            Cancelar
          </OButton>
          <OButton
            type="tertiary"
            onClick={() => {
              setReadOnly(false);
              modalManager.hide("read-only-modal");
            }}
          >
            Confirmar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
