import dayjs from "dayjs";

export enum CollectionStatus {
  Canceled = "CANCELED",
  CancelFailed = "CANCEL_FAILED",
  Canceling = "CANCELING",
  Created = "CREATED",
  Expired = "EXPIRED",
  Failed = "FAILED",
  Overdue = "OVERDUE",
  Paid = "PAID",
  Processing = "PROCESSING",
  ProcessingPayment = "PROCESSING_PAYMENT",
  Updated = "UPDATED",
  UpdateFailed = "UPDATE_FAILED",
  Updating = "UPDATING",
}

export enum BadCreditStatus {
  Canceled = "CANCELED",
  Canceling = "CANCELING",
  Confirmed = "CONFIRMED",
  ConfirmedWithCancelPending = "CONFIRMED_WITH_CANCEL_PENDING",
  Failed = "FAILED",
  FailedToCancel = "FAILED_TO_CANCEL",
  FailedToSend = "FAILED_TO_SEND",
  Processing = "PROCESSING",
  Starting = "STARTING",
  WarningPeriod = "WARNING_PERIOD",
}

export enum DeliveryMedium {
  Email = "Email",
  Sms = "Sms",
}

export enum InterestType {
  NOT_APPLICABLE = "NOT_APPLICABLE",
  PERCENTAGE_PER_MONTH = "PERCENTAGE_PER_MONTH",
  FIXED_VALUE_PER_MONTH = "FIXED_VALUE_PER_MONTH",
}

export enum FeesType {
  PERCENTAGE = "PERCENTAGE",
  FIXED_VALUE = "FIXED_VALUE",
  NOT_APPLICABLE = "NOT_APPLICABLE",
}

export enum BankslipGridColumn {
  CreatedAt = "CreatedAt",
  DocumentNumber = "DocumentNumber",
  OurNumber = "OurNumber",
  Payer = "Payer",
  Status = "Status",
  BadCredit = "BadCredit",
  DueDate = "DueDate",
  UpdatedAt = "UpdatedAt",
  Amount = "Amount",
  AmountPaid = "AmountPaid",
  CreationType = "CreationType",
  CollectionType = "CollectionType",
  Anticipation = "Anticipation",
  Floating = "Floating",
}

export enum CollectionType {
  BANKSLIP_QRCODE = "BANKSLIP_QRCODE",
  BANKSLIP = "BANKSLIP",
}

export interface CollectionFilterFields {
  payerName?: string;
  payerTaxId?: string;
  documentNumber?: string;
  ourNumber?: string;
  digitableLine?: string;
  barcode?: string;
  createdAt?: [dayjs.Dayjs, dayjs.Dayjs];
  dueDate?: [dayjs.Dayjs, dayjs.Dayjs];
  settledAt?: [dayjs.Dayjs, dayjs.Dayjs];
  status?: string[];
  badCreditStatus?: string[];
  isAnticipated?: string;
}

export interface CollectionFilterParams {
  "payer.name"?: string;
  "payer.taxId"?: string;
  documentNumber?: string;
  ourNumber?: string;
  digitableLine?: string;
  barcode?: string;
  startCreatedAt?: string;
  endCreatedAt?: string;
  startDueDate?: string;
  endDueDate?: string;
  startSettledAt?: string;
  endSettledAt?: string;
  status?: string;
  "badCredit.status"?: string;
  isAnticipated?: string;
}
