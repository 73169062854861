import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormTemplate } from "templates/form-template";
import { SettlementStrategiesForm } from "../_compose/formulario";
import { useEditSettlementStrategy } from "./edit-settlement-strategy.hook";

export const EditSettlementStrategyClient = () => {
  const {
    form,
    initialValues,
    isConsolidated,
    loading,
    onSubmit,
    setIsConsolidated,
  } = useEditSettlementStrategy();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar estratégia de liquidação" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="editar_estrategia_liquidacao:botao:salvar"
          dataLabel="salvar"
          onClick={() => onSubmit()}
        >
          Salvar
        </LoadingButton>
      }
    >
      <SettlementStrategiesForm
        form={form}
        initialValues={initialValues}
        isConsolidated={isConsolidated}
        setIsConsolidated={setIsConsolidated}
      />
    </FormTemplate>
  );
};
