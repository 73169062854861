import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const installmentsGrid = {
  datagrid: {
    noDataText: "Nenhuma parcela encontrada.",
    headerFilter: { visible: false, allowSearch: true },
    filterRow: {
      visible: true,
    },
    onDataErrorOccurred: (e) => {
      if (e.error) e.error.message = "Erro ao buscar parcelas.";
    },
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubLoan.odata.installmentsDueDatesAdmin,
    ),
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
    },
    {
      caption: "Nome da empresa",
      dataField: "name",
    },
    {
      caption: "CPF/CNPJ",
      dataField: "taxId",
      format: (value) => masks.cpfCnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      caption: "Valor",
      dataField: "amount",
      dataType: "number",
      format: "#,##0.00",
    },
    {
      caption: "Produto",
      dataField: "product",
    },
    {
      caption: "Status",
      dataField: "status",
    },
    {
      dataField: "loanId",
    },
    {
      dataField: "code",
    },
    {
      dataField: "identification",
    },
    {
      caption: "Cod. CCB",
      dataField: "externalId",
    },
    {
      caption: "Expira em",
      dataField: "maturityDate",
      dataType: "datetime",
      filterOperations: ["between", "<", ">", "<>"],
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubLoan.odata.getInstallmentsDueDatesAdmin>
    >["data"]["value"]
  >[number]
>;
