import {
  ClerkStep,
  ComplementStep,
  CreateUserRelationshipStep,
  DevelopersWebhookStep,
  GeneralStep,
  LessStep,
  QuickfinSyncStep,
  RiskAnalysisStep,
  ValidateCreateUserRelationshipStep,
  ValidationStep,
  WebhookInternalStep,
  WebhookStep,
} from "./step-fields";

interface ConfigurationFormMapperProps {
  fieldName: "configurations" | "syncConfigurations";
  stepType: ScfDevelopers.StepType;
  index: number;
}

export const ConfigurationFormMapper = ({
  fieldName,
  stepType,
  index,
}: ConfigurationFormMapperProps) => {
  if (stepType === "GENERAL") {
    return <GeneralStep fieldName={fieldName} index={index} />;
  }
  if (stepType === "VALIDATION") {
    return <ValidationStep fieldName={fieldName} index={index} />;
  }
  if (stepType === "COMPLEMENT") {
    return <ComplementStep fieldName={fieldName} index={index} />;
  }
  if (stepType === "RISKANALYSIS") {
    return <RiskAnalysisStep fieldName={fieldName} index={index} />;
  }
  if (stepType === "LESS") {
    return <LessStep fieldName={fieldName} index={index} />;
  }
  if (stepType === "WEBHOOK") {
    return <WebhookStep fieldName={fieldName} index={index} />;
  }
  if (stepType === "WEBHOOK_INTERNAL") {
    return <WebhookInternalStep fieldName={fieldName} index={index} />;
  }
  if (stepType === "DEVELOPERS_WEBHOOK") {
    return <DevelopersWebhookStep fieldName={fieldName} index={index} />;
  }
  if (stepType === "CLERK") {
    return <ClerkStep fieldName={fieldName} index={index} />;
  }
  if (stepType === "QUICKFINSYNC") {
    return <QuickfinSyncStep fieldName={fieldName} index={index} />;
  }
  if (stepType === "CREATE_USER_RELATIONSHIP") {
    return <CreateUserRelationshipStep fieldName={fieldName} index={index} />;
  }
  if (stepType === "VALIDATE_CREATE_USER_RELATIONSHIP") {
    return (
      <ValidateCreateUserRelationshipStep fieldName={fieldName} index={index} />
    );
  }
  return <>{null}</>;
};
