import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";
import { useBeneficiaryData } from "./collection-beneficiary.hook";

interface CollectionBeneficiaryContextProps {
  beneficiary?: BankingHubAdminCollection.Beneficiary;
  errorMessage: string;
  isLoading: boolean;
  getBeneficiary: () => Promise<void>;
}

const CollectionBeneficiaryContextDefaultValue: CollectionBeneficiaryContextProps =
  {
    beneficiary: undefined,
    errorMessage: "",
    isLoading: false,
    getBeneficiary: async () => {},
  };

const CollectionBeneficiaryContext = createContext(
  CollectionBeneficiaryContextDefaultValue,
);

export const CollectionBeneficiaryProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { beneficiary, errorMessage, getBeneficiary, isLoading } =
    useBeneficiaryData();

  const value = useMemo(
    () => ({ beneficiary, errorMessage, getBeneficiary, isLoading }),
    [beneficiary, errorMessage, getBeneficiary, isLoading],
  );

  return (
    <CollectionBeneficiaryContext.Provider value={value}>
      {children}
    </CollectionBeneficiaryContext.Provider>
  );
};

export const useCollectionBeneficiaryContext = () =>
  useContext(CollectionBeneficiaryContext);
