import { client } from "../client";
import { gatekeeperEndpoints } from "./gatekeeper.endpoints";
import {
  GetGatekeeperCompaniesResponse,
  GetGatekeeperGetActsResponse,
  GetProfileByNameResponse,
  GetProfileManagementGetSelfRequestsReponse,
  GetProfilesAllResponse,
  GetRoleByNameResponse,
  GetRolesResponse,
  GetSystemsAllResponse,
  GetSystemsBySystemNameResponse,
  GetUsersByEmailResponse,
  GetUsersGetFromCpfBody,
  GetUsersGetFromCpfResponse,
  PatchCompaniesByIdentificationUsersProfilesAddBody,
  PatchCompaniesByIdentificationUsersProfilesAddResponse,
  PatchCompaniesByIdentificationUsersProfilesRemoveBody,
  PatchCompaniesByIdentificationUsersProfilesRemoveResponse,
  PatchGatekeeperProfilesDetailsUpdateBody,
  PatchGatekeeperProfilesDetailsUpdateResponse,
  PatchRolesUpdateByIdBody,
  PatchRolesUpdateByIdResponse,
  PatchSystemsBySystemNameBody,
  PatchUsersByEmailActivateResponse,
  PatchUsersByEmailBody,
  PatchUsersByEmailDeactivateResponse,
  PatchUsersByEmailResponse,
  PostAllowOperationGetSignatureCombinationsBody,
  PostAllowOperationGetSignatureCombinationsResponse,
  PostGatekeeperProfilesAddActsBody,
  PostGatekeeperProfilesAddActsResponse,
  PostGatekeeperProfilesAddRolesBody,
  PostGatekeeperProfilesAddRolesResponse,
  PostGatekeeperProfilesBody,
  PostGatekeeperProfilesDetailsCreateBody,
  PostGatekeeperProfilesDetailsCreateResponse,
  PostGatekeeperProfilesDetailsDeleteBody,
  PostGatekeeperProfilesDetailsDeleteResponse,
  PostGatekeeperProfilesGetActsBody,
  PostGatekeeperProfilesGetActsResponse,
  PostGatekeeperProfilesGetDetailsBody,
  PostGatekeeperProfilesGetDetailsResponse,
  PostGatekeeperProfilesRemoveActsResponse,
  PostGatekeeperProfilesResponse,
  PostGatekeeperProfilesUpdateSubProfilesBody,
  PostGatekeeperProfilesUpdateSubProfilesResponse,
  PostGatekeeperUsersBody,
  PostGatekeeperUsersResponse,
  PostImpersonateBody,
  PostImpersonateResponse,
  PostInternalCustomerServiceGenerateBody,
  PostInternalCustomerServiceGenerateResponse,
  PostInvalidateSalesforceByCpfBody,
  PostOdataCompaniesBody,
  PostOdataCompaniesParams,
  PostOdataCompaniesResponse,
  PostOdataUsersBlockedLogsResponse,
  PostOdataUsersBody,
  PostOdataUsersResponse,
  PostProfileManagementAdminGetRequestDetailsResponse,
  PostProfileManagementAdminPublishRequestAnalysisResponse,
  PostProfileManagementAdminRequestOdataResponse,
  PostProfileManagementAdminResolveRequestResponse,
  PostProfileManagementAdminUpdateChangeResponse,
  PostProfileManagementCancelRequestResponse,
  PostProfileManagementDeleteChangeResponse,
  PostProfileManagementGetAvailableProfilesForChangeResponse,
  PostProfileManagementGetAvailableRolesForChangeResponse,
  PostProfileManagementGetChangeDetailResponse,
  PostProfileManagementGetRequestDetailsResponse,
  PostProfileManagementNewChangeResponse,
  PostProfileManagementNewRequestResponse,
  PostProfileManagementSendRequestResponse,
  PostProfileManagementUpdateChangeResponse,
  PostRolesBody,
  PostRolesResponse,
  PostSystemsBody,
  PostSystemsResponse,
} from "./models";
import {
  PatchProfilesUpdateProfileBody,
  PatchUsersEmailProfilesAddBody,
  PatchUsersEmailProfilesRemoveBody,
  PostCompaniesCreateByIdentificationUsersProfilesAddBody,
  PostCompaniesDeleteByIdentificationUsersProfilesRemoveBody,
  PostGatekeeperProfilesRemoveActsBody,
  PostGatekeeperUsersGetUserByEmailBody,
  PostProfileManagementAdminGetRequestDetailsBody,
  PostProfileManagementAdminPublishRequestAnalysisBody,
  PostProfileManagementAdminRequestOdataBody,
  PostProfileManagementAdminResolveRequestBody,
  PostProfileManagementAdminUpdateChangeBody,
  PostProfileManagementAdminUpdateChangesBody,
  PostProfileManagementCancelRequestBody,
  PostProfileManagementDeleteChangeBody,
  PostProfileManagementGetAvailableProfilesForChangeBody,
  PostProfileManagementGetAvailableRolesForChangeBody,
  PostProfileManagementGetAvailableSubProfilesForChangeBody,
  PostProfileManagementGetChangeDetailBody,
  PostProfileManagementGetRequestDetailsBody,
  PostProfileManagementNewChangeBody,
  PostProfileManagementNewRequestBody,
  PostProfileManagementSendRequestBody,
  PostProfileManagementUpdateChangeBody,
  PostSsoUtilsRmDecryptBody,
  PostTimePenaltyBody,
  PostUsersUpdateFromEmailBody,
} from "./models/requests";
import { PostGatekeeperProfilesDetailsGetFromProfileBody } from "./models/requests/post-gatekeeper-profiles-details-get-from-profile.request";
import {
  GetGatekeeperAdvisorsResponse,
  GetProfileGroupsListAllResponse,
  PatchUsersEmailProfilesAddResponse,
  PatchUsersEmailProfilesRemoveResponse,
  PostCompaniesCreateByIdentificationUsersProfilesAddResponse,
  PostCompaniesDeleteByIdentificationUsersProfilesRemoveResponse,
  PostGatekeeperProfilesDetailsGetFromProfileResponse,
  PostGatekeeperProfilesDetailsListGroupsResponse,
  PostProfileManagementAdminUpdateChangesResponse,
  PostProfileManagementGetAvailableSubProfilesForChangeResponse,
  PostSsoUtilsRmDecryptResponse,
  PostTimePenaltyResponse,
  PostUsersUpdateFromEmailResponse,
} from "./models/responses";
import { GetGatekeeperAdvisoredByResponse } from "./models/responses/get-gatekeeper-advisored-by.response";
import { gatekeeperProfilesService } from "./profiles/gatekeeper-profiles.service";

export const gatekeeperService = {
  profiles: gatekeeperProfilesService,
  getSignatureCombinations(
    body: PostAllowOperationGetSignatureCombinationsBody,
  ) {
    return client.post<PostAllowOperationGetSignatureCombinationsResponse>(
      gatekeeperEndpoints.allowOperation.getSignatureCombinations,
      body,
    );
  },
  activateUser(email: string) {
    return client.patch<PatchUsersByEmailActivateResponse>(
      gatekeeperEndpoints.users.byEmail.activate(email),
      {},
    );
  },
  deactivateUser(email: string) {
    return client.patch<PatchUsersByEmailDeactivateResponse>(
      gatekeeperEndpoints.users.byEmail.deactivate(email),
      {},
    );
  },
  getUserByCpf(body: GetUsersGetFromCpfBody) {
    return client.post<GetUsersGetFromCpfResponse>(
      gatekeeperEndpoints.users.getFromCpf,
      body,
    );
  },
  impersonate(body: PostImpersonateBody) {
    return client.post<PostImpersonateResponse>(
      gatekeeperEndpoints.impersonate,
      body,
    );
  },
  /** @deprecated use impersonate() */
  impersonateSalesforce(body: PostImpersonateBody) {
    return client.post<PostImpersonateResponse>(
      gatekeeperEndpoints.impersonateSalesforce,
      body,
    );
  },
  getAllProfiles() {
    return client.get<GetProfilesAllResponse>(gatekeeperEndpoints.profiles.all);
  },
  generateSalesforceToken(body: PostInternalCustomerServiceGenerateBody) {
    return client.post<PostInternalCustomerServiceGenerateResponse>(
      gatekeeperEndpoints.internalCustomerService.generate,
      body,
    );
  },
  invalidateByCpf(body: PostInvalidateSalesforceByCpfBody) {
    return client.post(gatekeeperEndpoints.invalidate.salesforce.byCpf, body);
  },
  getCompanies(
    body: PostOdataCompaniesBody,
    params?: PostOdataCompaniesParams,
  ) {
    return client.post<PostOdataCompaniesResponse>(
      gatekeeperEndpoints.odata.companies,
      body,
      { params },
    );
  },
  getRoles() {
    return client.get<GetRolesResponse>(gatekeeperEndpoints.roles.endpoint);
  },
  addRole(body: PostRolesBody) {
    return client.post<PostRolesResponse>(
      gatekeeperEndpoints.roles.endpoint,
      body,
    );
  },
  editRole(body: PatchRolesUpdateByIdBody) {
    return client.patch<PatchRolesUpdateByIdResponse>(
      gatekeeperEndpoints.roles.updateById,
      body,
    );
  },
  getRoleByName(roleName: string) {
    return client.get<GetRoleByNameResponse>(
      gatekeeperEndpoints.roles.byRoleName(roleName),
    );
  },
  listAllSystems() {
    return client.get<GetSystemsAllResponse>(gatekeeperEndpoints.systems.all);
  },
  addSystem(body: PostSystemsBody) {
    return client.post<PostSystemsResponse>(
      gatekeeperEndpoints.systems.endpoint,
      body,
    );
  },
  getSystem(systemName: string) {
    return client.get<GetSystemsBySystemNameResponse>(
      gatekeeperEndpoints.systems.bySystemName(systemName),
    );
  },
  editSystem(systemName: string, body: PatchSystemsBySystemNameBody) {
    return client.patch(
      gatekeeperEndpoints.systems.bySystemName(systemName),
      body,
    );
  },
  getUsers(body: PostOdataUsersBody) {
    return client.post<PostOdataUsersResponse>(
      gatekeeperEndpoints.odata.users,
      body,
    );
  },
  getUser(email: string) {
    return client.get<GetUsersByEmailResponse>(
      gatekeeperEndpoints.users.byEmail.endpoint(email),
    );
  },
  getLogs(body: PostOdataUsersBody) {
    return client.post<PostOdataUsersBlockedLogsResponse>(
      gatekeeperEndpoints.odata.logs,
      body,
    );
  },
  getAdvisors(): Promise<GetGatekeeperAdvisorsResponse> {
    return client
      .get(gatekeeperEndpoints.advisors.index, {
        responseType: "stream",
        adapter: "fetch",
      })
      .then(async (response) => {
        const reader = response.data.getReader();
        const decoder = new TextDecoder();
        let acc = "";
        while (true) {
          const result = await reader.read();
          const text = decoder.decode(result.value);
          acc += text;
          if (result.done) break;
        }
        return JSON.parse(acc);
      });
  },
  getAdvisoredEntities(
    identificationTuple: `cpf:${string}` | "self",
  ): Promise<GetGatekeeperAdvisoredByResponse> {
    return client
      .get(
        gatekeeperEndpoints.entities.advisoredEntities(identificationTuple),
        {
          responseType: "stream",
          adapter: "fetch",
        },
      )
      .then(async (response) => {
        const reader = response.data.getReader();
        const decoder = new TextDecoder();
        let acc = "";
        while (true) {
          const result = await reader.read();
          const text = decoder.decode(result.value);
          acc += text;
          if (result.done) break;
        }
        return JSON.parse(acc);
      });
  },
  /**
   * Use `updateUserProfiles`
   * @deprecated
   */
  editUser(email: string, body: PatchUsersByEmailBody) {
    return client.patch<PatchUsersByEmailResponse>(
      gatekeeperEndpoints.users.byEmail.endpoint(email),
      body,
    );
  },
  /**
   * Use `addProfilesToCompanyUserRelationship`
   * @deprecated
   */
  addProfilesToCompanyUser(
    identification: string,
    body: PatchCompaniesByIdentificationUsersProfilesAddBody,
  ) {
    return client.patch<PatchCompaniesByIdentificationUsersProfilesAddResponse>(
      gatekeeperEndpoints.companies.byIdentification.users.profiles.add(
        identification,
      ),
      body,
    );
  },
  /**
   * Use `removeProfilesFromCompanyUserRelationship`
   * @deprecated
   */
  removeProfilesFromCompanyUser(
    identification: string,
    body: PatchCompaniesByIdentificationUsersProfilesRemoveBody,
  ) {
    return client.patch<PatchCompaniesByIdentificationUsersProfilesRemoveResponse>(
      gatekeeperEndpoints.companies.byIdentification.users.profiles.remove(
        identification,
      ),
      body,
    );
  },
  addProfile(body: PostGatekeeperProfilesBody) {
    return client.post<PostGatekeeperProfilesResponse>(
      gatekeeperEndpoints.profiles.endpoint,
      body,
    );
  },
  updateProfile(body: PatchProfilesUpdateProfileBody, profileName: string) {
    return client.patch<PostGatekeeperProfilesResponse>(
      gatekeeperEndpoints.profiles.updateProfile(profileName),
      body,
    );
  },
  getProfileByName(profileName: string) {
    return client.get<GetProfileByNameResponse>(
      gatekeeperEndpoints.profiles.byProfileName(profileName),
    );
  },
  addProfileRoles(body: PostGatekeeperProfilesAddRolesBody) {
    return client.post<PostGatekeeperProfilesAddRolesResponse>(
      gatekeeperEndpoints.profiles.addRoles,
      body,
    );
  },
  updateSubProfiles(body: PostGatekeeperProfilesUpdateSubProfilesBody) {
    return client.post<PostGatekeeperProfilesUpdateSubProfilesResponse>(
      gatekeeperEndpoints.profiles.updateSubProfiles,
      body,
    );
  },
  addUser(body: PostGatekeeperUsersBody) {
    return client.post<PostGatekeeperUsersResponse>(
      gatekeeperEndpoints.users.endpoint,
      body,
    );
  },
  getAllCompanies() {
    return client.get<GetGatekeeperCompaniesResponse>(
      gatekeeperEndpoints.companies.endpoint,
    );
  },
  getProfileDetails(body: PostGatekeeperProfilesGetDetailsBody) {
    return client.post<PostGatekeeperProfilesGetDetailsResponse>(
      gatekeeperEndpoints.profiles.getDetails,
      body,
    );
  },
  createProfileDetails(body: PostGatekeeperProfilesDetailsCreateBody) {
    return client.post<PostGatekeeperProfilesDetailsCreateResponse>(
      gatekeeperEndpoints.profiles.details.create,
      body,
    );
  },
  updateProfileDetails(body: PatchGatekeeperProfilesDetailsUpdateBody) {
    return client.patch<PatchGatekeeperProfilesDetailsUpdateResponse>(
      gatekeeperEndpoints.profiles.details.update,
      body,
    );
  },
  deleteProfileDetails(body: PostGatekeeperProfilesDetailsDeleteBody) {
    return client.post<PostGatekeeperProfilesDetailsDeleteResponse>(
      gatekeeperEndpoints.profiles.details.delete,
      body,
    );
  },
  getProfileActs(body: PostGatekeeperProfilesGetActsBody) {
    return client.post<PostGatekeeperProfilesGetActsResponse>(
      gatekeeperEndpoints.profiles.getActs,
      body,
    );
  },
  addProfileActs(body: PostGatekeeperProfilesAddActsBody) {
    return client.post<PostGatekeeperProfilesAddActsResponse>(
      gatekeeperEndpoints.profiles.addActs,
      body,
    );
  },
  removeProfileActs(body: PostGatekeeperProfilesRemoveActsBody) {
    return client.post<PostGatekeeperProfilesRemoveActsResponse>(
      gatekeeperEndpoints.profiles.removeActs,
      body,
    );
  },
  getActs() {
    return client.get<GetGatekeeperGetActsResponse>(
      gatekeeperEndpoints.getActs,
    );
  },
  getProfileManagementRequests() {
    return client.get<GetProfileManagementGetSelfRequestsReponse>(
      gatekeeperEndpoints.profileManagement.getSelfRequests,
    );
  },
  createNewRequest(body: PostProfileManagementNewRequestBody) {
    return client.post<PostProfileManagementNewRequestResponse>(
      gatekeeperEndpoints.profileManagement.newRequest,
      body,
    );
  },
  getProfileManagementRequestDetails(
    body: PostProfileManagementGetRequestDetailsBody,
  ) {
    return client.post<PostProfileManagementGetRequestDetailsResponse>(
      gatekeeperEndpoints.profileManagement.getRequestDetails,
      body,
    );
  },
  getProfileManagementRequestChangeDetails(
    body: PostProfileManagementGetChangeDetailBody,
  ) {
    return client.post<PostProfileManagementGetChangeDetailResponse>(
      gatekeeperEndpoints.profileManagement.getChangeDetail,
      body,
    );
  },
  createProfileManagementRequestChange(
    body: PostProfileManagementNewChangeBody,
  ) {
    return client.post<PostProfileManagementNewChangeResponse>(
      gatekeeperEndpoints.profileManagement.newChange,
      body,
    );
  },
  updateProfileManagementRequestChange(
    body: PostProfileManagementUpdateChangeBody,
  ) {
    return client.post<PostProfileManagementUpdateChangeResponse>(
      gatekeeperEndpoints.profileManagement.updateChange,
      body,
    );
  },
  deleteProfileManagementRequestChange(
    body: PostProfileManagementDeleteChangeBody,
  ) {
    return client.post<PostProfileManagementDeleteChangeResponse>(
      gatekeeperEndpoints.profileManagement.deleteChange,
      body,
    );
  },
  sendProfileManagementRequest(body: PostProfileManagementSendRequestBody) {
    return client.post<PostProfileManagementSendRequestResponse>(
      gatekeeperEndpoints.profileManagement.sendRequest,
      body,
    );
  },
  cancelProfileManagementRequest(body: PostProfileManagementCancelRequestBody) {
    return client.post<PostProfileManagementCancelRequestResponse>(
      gatekeeperEndpoints.profileManagement.cancelRequest,
      body,
    );
  },
  getAvailableRolesForChange(
    body: PostProfileManagementGetAvailableRolesForChangeBody,
  ) {
    return client.post<PostProfileManagementGetAvailableRolesForChangeResponse>(
      gatekeeperEndpoints.profileManagement.getAvailableRolesForChange,
      body,
    );
  },
  getAvailableProfilesForChange(
    body: PostProfileManagementGetAvailableProfilesForChangeBody,
  ) {
    return client.post<PostProfileManagementGetAvailableProfilesForChangeResponse>(
      gatekeeperEndpoints.profileManagement.getAvailableProfilesForChange,
      body,
    );
  },
  getAvailableSubProfilesForChange(
    body: PostProfileManagementGetAvailableSubProfilesForChangeBody,
  ) {
    return client.post<PostProfileManagementGetAvailableSubProfilesForChangeResponse>(
      gatekeeperEndpoints.profileManagement.getAvailableSubProfilesForChange,
      body,
    );
  },
  getAdminProfileManagementRequests(
    body: PostProfileManagementAdminRequestOdataBody,
  ) {
    return client.post<PostProfileManagementAdminRequestOdataResponse>(
      gatekeeperEndpoints.profileManagement.admin.requestsOdata,
      body,
    );
  },
  updateAdminProfileManagementRequestChange(
    body: PostProfileManagementAdminUpdateChangeBody,
  ) {
    return client.post<PostProfileManagementAdminUpdateChangeResponse>(
      gatekeeperEndpoints.profileManagement.admin.updateChange,
      body,
    );
  },
  updateAdminProfileManagementRequestChanges(
    body: PostProfileManagementAdminUpdateChangesBody,
  ) {
    return client.post<PostProfileManagementAdminUpdateChangesResponse>(
      gatekeeperEndpoints.profileManagement.admin.updateChanges,
      body,
    );
  },
  resolveAdminProfileManagementRequest(
    body: PostProfileManagementAdminResolveRequestBody,
  ) {
    return client.post<PostProfileManagementAdminResolveRequestResponse>(
      gatekeeperEndpoints.profileManagement.admin.resolveRequest,
      body,
    );
  },
  getAdminProfileManagementRequestDetails(
    body: PostProfileManagementAdminGetRequestDetailsBody,
  ) {
    return client.post<PostProfileManagementAdminGetRequestDetailsResponse>(
      gatekeeperEndpoints.profileManagement.admin.getRequestDetails,
      body,
    );
  },
  publishRequestAnalysisAdminProfileManagement(
    body: PostProfileManagementAdminPublishRequestAnalysisBody,
  ) {
    return client.post<PostProfileManagementAdminPublishRequestAnalysisResponse>(
      gatekeeperEndpoints.profileManagement.admin.publishRequestAnalysis,
      body,
    );
  },
  updateUserProfiles(body: PostUsersUpdateFromEmailBody) {
    return client.patch<PostUsersUpdateFromEmailResponse>(
      gatekeeperEndpoints.users.updateFromEmail,
      body,
    );
  },
  addProfilesToCompanyUserRelationship(
    body: PostCompaniesCreateByIdentificationUsersProfilesAddBody,
  ) {
    return client.patch<PostCompaniesCreateByIdentificationUsersProfilesAddResponse>(
      gatekeeperEndpoints.companies.createByIdentification.users.profiles.add,
      body,
    );
  },
  removeProfilesFromCompanyUserRelationship(
    body: PostCompaniesDeleteByIdentificationUsersProfilesRemoveBody,
  ) {
    return client.patch<PostCompaniesDeleteByIdentificationUsersProfilesRemoveResponse>(
      gatekeeperEndpoints.companies.deleteByIdentification.users.profiles
        .remove,
      body,
    );
  },
  addProfilesToUser(body: PatchUsersEmailProfilesAddBody) {
    return client.patch<PatchUsersEmailProfilesAddResponse>(
      gatekeeperEndpoints.users.email.profiles.add,
      body,
    );
  },
  removeProfilesFromUser(body: PatchUsersEmailProfilesRemoveBody) {
    return client.patch<PatchUsersEmailProfilesRemoveResponse>(
      gatekeeperEndpoints.users.email.profiles.remove,
      body,
    );
  },
  getTimePenaltyStatus(body: PostTimePenaltyBody) {
    return client.post<PostTimePenaltyResponse>(
      gatekeeperEndpoints.users.timePenalty.status,
      body,
    );
  },
  removeTimePenalty(body: PostTimePenaltyBody) {
    return client.post<PostTimePenaltyResponse>(
      gatekeeperEndpoints.users.timePenalty.remove,
      body,
    );
  },
  getProfileGroup() {
    return client.get<GetProfileGroupsListAllResponse>(
      gatekeeperEndpoints.profileGroup.listAll,
    );
  },
  listGroupsProfileDetails() {
    return client.post<PostGatekeeperProfilesDetailsListGroupsResponse>(
      gatekeeperEndpoints.profiles.details.listGroups,
    );
  },
  getProfileDetailsFromProfile(
    body: PostGatekeeperProfilesDetailsGetFromProfileBody,
  ) {
    return client.post<PostGatekeeperProfilesDetailsGetFromProfileResponse>(
      gatekeeperEndpoints.profiles.details.getFromProfile,
      body,
    );
  },
  getUserByEmail(body: PostGatekeeperUsersGetUserByEmailBody) {
    return client.post<GetUsersByEmailResponse>(
      gatekeeperEndpoints.users.getUserByEmail,
      body,
    );
  },
  decryptRmAdminTaxId(body: PostSsoUtilsRmDecryptBody) {
    return client.post<PostSsoUtilsRmDecryptResponse>(
      gatekeeperEndpoints.ssoUtils.rmDecrypt,
      body,
    );
  },
};
