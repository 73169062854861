import { yupResolver } from "@hookform/resolvers/yup";
import {
  modalManager,
  OButton,
  OCheckbox,
  OCol,
  OLabel,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { useEditProcessor } from "../../edit-processor.context";
import {
  propertyModalFormDefaultValues,
  propertyModalFormValidationSchema,
} from "./property-modal-form.schemas";

const propertyTypes = [
  "FORM",
  "TEXT",
  "TEXTAREA",
  "NUMBER",
  "INTEGER",
  "FILE",
  "BATCH_TEMPLATE",
  "CONDITION",
  "BOOLEAN",
  "WORKFLOW_CONFIG",
  "PROCESSOR_CONFIG",
  "CREDIT_LINE",
  "VIEW",
  "UNIQUE_TEXT",
];

export const PROPERTY_MODAL_ID = "property-modal";

export const PropertyModal = () => {
  const {
    processor: { callService: getProcessorDetails },
    id,
    selectedProperty,
    setSelectedProperty,
  } = useEditProcessor();

  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: yupResolver(propertyModalFormValidationSchema),
    defaultValues: propertyModalFormDefaultValues,
  });

  const { getValues, reset, setValue, handleSubmit } = form;

  const { hide } = modalManager;

  const addProperty = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);
          const allowedValues = values.allowedValues
            ? values.allowedValues.split(",")
            : [];

          const isRequired = !!values.isRequired.length;
          const sensitive = !!values.sensitive.length;
          const validateAsList = !!values.validateAsList.length;
          const multiselectType =
            !!values.multiselectType ? values.multiselectType : null;

          await service.hubCreditManager.addProcessorProperty({
            ...values,
            allowedValues,
            isRequired,
            sensitive,
            validateAsList,
            processorId: Number(id),
            multiselectType,
          });

          getProcessorDetails(id);
          hide(PROPERTY_MODAL_ID);
          reset();
          OToastManager.success("Propriedade salva com sucesso.");
        } catch {
          OToastManager.danger(
            "Não foi possível salvar propriedade. Por favor, tente novamente mais tarde.",
          );
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, id, getProcessorDetails, hide, reset],
  );

  const editProperty = useCallback(
    async (propertyId: number) => {
      try {
        setLoading(true);

        const {
          type,
          description,
          name,
          isRequired,
          sensitive,
          validateAsList,
          allowedValues,
          multiselectType,
        } = getValues();
        const arrAllowedValues = allowedValues ? allowedValues.split(",") : [];
        const multiselectTypeValue =
          !!multiselectType ? multiselectType : null;

        await service.hubCreditManager.editProcessorProperty({
          name,
          propertyId,
          description,
          type,
          allowedValues: arrAllowedValues,
          isRequired: !!isRequired.length,
          sensitive: !!sensitive.length,
          validateAsList: !!validateAsList.length,
          multiselectType: multiselectTypeValue,
        });

        getProcessorDetails(id);
        hide(PROPERTY_MODAL_ID);
        reset();
        setSelectedProperty(undefined);
        OToastManager.success("Propriedade editada com sucesso.");
      } catch {
        OToastManager.danger("Não foi possível editar a propriedade.");
      } finally {
        setLoading(false);
      }
    },
    [getProcessorDetails, getValues, hide, reset, setSelectedProperty, id],
  );

  useEffect(() => {
    if (selectedProperty) {
      setValue("type", selectedProperty.type);
      setValue("description", selectedProperty.description);
      setValue("name", selectedProperty.name);
      setValue("multiselectType", selectedProperty.multiselectType || "");
      setValue("isRequired", selectedProperty.isRequired ? ["1"] : []);
      setValue("sensitive", selectedProperty.sensitive ? ["1"] : []);
      setValue("validateAsList", selectedProperty.validateAsList ? ["1"] : []);
      setValue("allowedValues", selectedProperty.allowedValues.join(","));
    } else {
      reset();
    }
  }, [getValues, reset, selectedProperty, setValue]);

  return (
    <OModal id={PROPERTY_MODAL_ID} position="center" size="sm">
      <OModalHeader closeButton>
        <OTypography
          tag="h1"
          size="xxl"
          key={`property-modal-title-${selectedProperty}`}
        >
          {`${selectedProperty ? "Editar" : "Adicionar"} propriedade`}
        </OTypography>
      </OModalHeader>
      <OModalBody>
        {loading && <OLoader size="xl" absolute backdrop />}
        <FormProvider {...form}>
          <form spellCheck="false">
            <div className="d-flex flex-column gap-2">
              <ORow>
                <OCol sm={9}>
                  <ORFieldSelect
                    id="type"
                    name="type"
                    label="Tipo"
                    labelSize="lg"
                  >
                    <div>
                      {propertyTypes.map((value) => (
                        <OOption key={value} value={value}>
                          {value}
                        </OOption>
                      ))}
                    </div>
                  </ORFieldSelect>
                </OCol>
                <OCol sm={3} className="d-flex align-items-center">
                  <ORFieldCheckboxGroup
                    id="validateAsList"
                    name="validateAsList"
                    tooltip="Configuração específica para ProcessorConfigId e View."
                    tooltipPosition="top-left"
                  >
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <OCheckbox size="xs" id="is-list" value="1" />
                      <OLabel htmlFor="is-list">É uma lista?</OLabel>
                    </div>
                  </ORFieldCheckboxGroup>
                </OCol>
              </ORow>

              <ORFieldInput
                id="description"
                name="description"
                tag="text"
                label="Descrição"
                labelSize="lg"
              />
              <ORFieldInput
                id="name"
                name="name"
                tag="text"
                label="Nome"
                labelSize="lg"
              />
              <ORFieldSelect
                id="multiselectType"
                name="multiselectType"
                label="Tipo de seletor múltiplo"
                labelSize="md"
                placeholder="Selecione"
              >
                {["rejectionReasons"].map((option) => (
                  <OOption key={option} value={option}>
                    {option}
                  </OOption>
                ))}
                <OOption value="">NENHUM</OOption>
              </ORFieldSelect>
              <ORFieldInput
                id="allowedValues"
                name="allowedValues"
                tag="text"
                tooltip="Valores separados por vírgula"
                tooltipPosition="top-right"
                label="Valores permitidos"
                labelSize="lg"
              />
              <ORow>
                <OCol>
                  <ORFieldCheckboxGroup id="isRequired" name="isRequired">
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <OCheckbox
                        size="xs"
                        id="is-required-checkbox"
                        value="1"
                      />
                      <OLabel htmlFor="is-required-checkbox">
                        É obrigatório?
                      </OLabel>
                    </div>
                  </ORFieldCheckboxGroup>
                </OCol>
                <OCol>
                  <ORFieldCheckboxGroup id="sensitive" name="sensitive">
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <OCheckbox size="xs" id="sensitive-checkbox" value="1" />
                      <OLabel htmlFor="sensitive-checkbox">É sensível?</OLabel>
                    </div>
                  </ORFieldCheckboxGroup>
                </OCol>
              </ORow>
            </div>
          </form>
        </FormProvider>
      </OModalBody>

      <OModalFooter>
        <div className="d-flex gap-2 justify-content-end">
          <OButton
            outline
            onClick={() => {
              hide(PROPERTY_MODAL_ID);
            }}
          >
            Fechar
          </OButton>
          <LoadingButton
            loading={loading}
            onClick={async () => {
              if (selectedProperty) editProperty(selectedProperty.id);
              else addProperty();
            }}
          >
            {selectedProperty ? "Editar" : "Adicionar"}
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
