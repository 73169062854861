export const stepTypeMap: Record<ScfDevelopers.StepType, string> = {
  GENERAL: "General",
  VALIDATION: "Validation",
  COMPLEMENT: "Complement",
  RISKANALYSIS: "Risk analysis",
  LESS: "LESS",
  WEBHOOK: "Webhook",
  WEBHOOK_INTERNAL: "Webhook interno",
  DEVELOPERS_WEBHOOK: "Developers webhook",
  CLERK: "Clerk sync",
  QUICKFINSYNC: "Quickfin sync",
  VALIDATE_CREATE_USER_RELATIONSHIP: "Validar criação de relationship",
  CREATE_USER_RELATIONSHIP: "Criar relationship",
};

export const requiredFieldsMap = {
  NAME: "Nome",
  PHONENUMBER: "Telefone",
  EMAILADDRESS: "E-mail",
  BIRTHDATE: "Data de nascimento",
  ADDRESSES: "Endereço",
};
