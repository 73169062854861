import { OToastManager, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { OTruncateTypography } from "components/o-truncate-typography";
import { StatusBadge } from "components/status-badge";
import dayjs from "dayjs";
import { roles } from "roles/roles";
import { copyToClipboard } from "utils/copy";
import { OTruncateTypographyStyled } from "./bankslip.styles";
import { BankslipGridColumn, CollectionStatus } from "./bankslip.type";
import {
  MapperBadCreditStatus,
  MapperBankslipGridColumnCaption,
  MapperBankslipGridColumnWidth,
  MapperCollectionStatus,
  MapperCollectionType,
  isAbleToArchive,
  isAbleToCancel,
  isAbleToEditFloating,
} from "./bankslip.utils";

const loadColumnVisibility = (
  visibleColumns: string[],
  column: BankslipGridColumn,
) => {
  const visibleIndex = visibleColumns.indexOf(column);
  if (visibleIndex > -1) return { visible: true, visibleIndex };
  return { visible: false };
};

export const grid: (
  pageSize: number,
  archiveCollection: (collection: BankingHub.Collection) => void,
  cancelCollection: (collection: BankingHub.Collection) => void,
  seeDetails: (collection: BankingHub.Collection) => void,
  seeEvents: (collection: BankingHub.Collection) => void,
  seeCommunicationHistory: (collection: BankingHub.Collection) => void,
  editCollectionFloating: (collection: BankingHub.Collection) => void,
  visibleColumns: string[],
) => ODataGridGeneratorConfig<BankingHub.Collection> = (
  pageSize,
  archiveCollection,
  cancelCollection,
  seeDetails,
  seeEvents,
  seeCommunicationHistory,
  editCollectionFloating,
  visibleColumns,
) => ({
  datagrid: { noDataText: "Nenhum boleto cadastrado" },
  paging: { pageSize },
  columns: [
    {
      dataField: "createdAt",
      dataType: "datetime",
      format: "shortDateShortTime",
      allowSorting: true,
      caption: MapperBankslipGridColumnCaption.CreatedAt,
      width: MapperBankslipGridColumnWidth.CreatedAt,
      ...loadColumnVisibility(visibleColumns, BankslipGridColumn.CreatedAt),
    },
    {
      dataField: "detail.documentNumber",
      caption: MapperBankslipGridColumnCaption.DocumentNumber,
      width: MapperBankslipGridColumnWidth.DocumentNumber,
      ...loadColumnVisibility(
        visibleColumns,
        BankslipGridColumn.DocumentNumber,
      ),
    },
    {
      dataField: "detail.ourNumber",
      caption: MapperBankslipGridColumnCaption.OurNumber,
      width: MapperBankslipGridColumnWidth.OurNumber,
      ...loadColumnVisibility(visibleColumns, BankslipGridColumn.OurNumber),
    },
    {
      dataField: "payer.name",
      caption: MapperBankslipGridColumnCaption.Payer,
      width: MapperBankslipGridColumnWidth.Payer,
      cellRender: ({ data }) => {
        const payerName = data.payer.name;
        const payerTaxId = masks.cpfCnpj(data.payer.taxId);

        return (
          <div className="d-flex flex-column gap-2">
            <OTruncateTypographyStyled title={payerName}>
              {payerName}
            </OTruncateTypographyStyled>
            <OTruncateTypography size="sm" type="dark-80" title={payerTaxId}>
              {payerTaxId}
            </OTruncateTypography>
          </div>
        );
      },
      allowSorting: true,
      ...loadColumnVisibility(visibleColumns, BankslipGridColumn.Payer),
    },
    {
      dataField: "status",
      caption: MapperBankslipGridColumnCaption.Status,
      width: MapperBankslipGridColumnWidth.Status,
      cellRender: ({ data }) => {
        const { status } = data;
        const { label, type } = MapperCollectionStatus[status] ?? {
          label: status,
          type: "warning",
        };

        return <StatusBadge type={type}>{label}</StatusBadge>;
      },
      ...loadColumnVisibility(visibleColumns, BankslipGridColumn.Status),
    },
    {
      dataField: "badCredit.status",
      caption: MapperBankslipGridColumnCaption.BadCredit,
      width: MapperBankslipGridColumnWidth.BadCredit,
      cellRender: ({ data }) => {
        const { badCredit } = data;
        if (!badCredit?.status) {
          return "-";
        }
        const { label, type } = MapperBadCreditStatus[badCredit.status] ?? {
          label: badCredit.status,
          type: "warning",
        };

        return <StatusBadge type={type}>{label}</StatusBadge>;
      },
      ...loadColumnVisibility(visibleColumns, BankslipGridColumn.BadCredit),
    },
    {
      dataField: "dueDate",
      caption: MapperBankslipGridColumnCaption.DueDate,
      width: MapperBankslipGridColumnWidth.DueDate,
      dataType: "date",
      format: "shortDate",
      allowSorting: true,
      ...loadColumnVisibility(visibleColumns, BankslipGridColumn.DueDate),
    },
    {
      dataField: "updatedAt",
      caption: MapperBankslipGridColumnCaption.UpdatedAt,
      width: MapperBankslipGridColumnWidth.UpdatedAt,
      dataType: "datetime",
      customizeText: ({ value }) =>
        value
          ? dayjs(value).subtract(3, "hours").format("DD/MM/YYYY, HH:mm:ss")
          : "-",
      allowSorting: true,
      ...loadColumnVisibility(visibleColumns, BankslipGridColumn.UpdatedAt),
    },
    {
      dataField: "amount",
      caption: MapperBankslipGridColumnCaption.Amount,
      width: MapperBankslipGridColumnWidth.Amount,
      format: (value) => masks.currency(value, "R$", "."),
      allowSorting: true,
      ...loadColumnVisibility(visibleColumns, BankslipGridColumn.Amount),
    },
    {
      dataField: "amountPaid",
      caption: MapperBankslipGridColumnCaption.AmountPaid,
      width: MapperBankslipGridColumnWidth.AmountPaid,
      cellRender: ({ data }) => (
        <OTypography>
          {data.amountPaid ? masks.currency(data.amountPaid, "R$", ".") : "-"}
        </OTypography>
      ),
      allowSorting: true,
      ...loadColumnVisibility(visibleColumns, BankslipGridColumn.AmountPaid),
    },
    {
      caption: MapperBankslipGridColumnCaption.CreationType,
      width: MapperBankslipGridColumnWidth.CreationType,
      cellRender: ({ data }) => (
        <OTypography>{data.batchId ? "Lote" : "Unitário"}</OTypography>
      ),
      ...loadColumnVisibility(visibleColumns, BankslipGridColumn.CreationType),
    },
    {
      caption: MapperBankslipGridColumnCaption.CollectionType,
      width: MapperBankslipGridColumnWidth.CollectionType,
      cellRender: ({ data }) => (
        <OTruncateTypographyStyled>
          {MapperCollectionType[data.type]}
        </OTruncateTypographyStyled>
      ),
      ...loadColumnVisibility(
        visibleColumns,
        BankslipGridColumn.CollectionType,
      ),
    },
    {
      dataField: "anticipation.isAnticipated",
      caption: MapperBankslipGridColumnCaption.Anticipation,
      width: MapperBankslipGridColumnWidth.Anticipation,
      alignment: "center",
      cellRender: ({
        data: {
          anticipation: { isAnticipated },
        },
      }) => {
        if (!isAnticipated) return null;
        return <StatusBadge type="success">Antecipado</StatusBadge>;
      },
      ...loadColumnVisibility(visibleColumns, BankslipGridColumn.Anticipation),
    },
    {
      dataField: "detail.floatingInDays",
      caption: MapperBankslipGridColumnCaption.Floating,
      width: MapperBankslipGridColumnWidth.Floating,
      alignment: "center",
      cellRender: ({
        data: {
          detail: { floatingInDays },
        },
      }) =>
        floatingInDays
          ? `${floatingInDays} ${floatingInDays > 1 ? "dias" : "dia"}`
          : "-",
      ...loadColumnVisibility(visibleColumns, BankslipGridColumn.Floating),
    },
    {
      caption: "Ações",
      alignment: "right",
      width: "85px",
      fixed: true,
      fixedPosition: "right",
      cellRender: ({ data }) => {
        return (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-bank-slip" },
                label: "Copiar linha digitável",
                onClick: () => {
                  copyToClipboard(data.detail.digitableLine ?? "");
                  OToastManager.success("Linha digitável copiada com sucesso");
                },
                visible: !!data.detail.digitableLine,
              },
              {
                icon: { category: "orq", icon: "orq-pix" },
                label: "Copiar Pix Copia e cola",
                onClick: () => {
                  copyToClipboard(data.detail.emv ?? "");
                  OToastManager.success("Pix Copia e cola copiado com sucesso");
                },
                visible: !!data.detail.emv,
              },
              {
                icon: { category: "orq", icon: "orq-archive" },
                label: "Arquivar boleto",
                role: "BankingAdmin:Collection.Archive",
                onClick: () => archiveCollection(data),
                visible: isAbleToArchive(data),
              },
              {
                icon: { category: "orq", icon: "orq-cancel" },
                label: "Cancelar boleto",
                role: "BankingAdmin:Collection.Cancel",
                onClick: () => cancelCollection(data),
                visible: isAbleToCancel(data),
              },
              {
                icon: { category: "orq", icon: "orq-stack-details" },
                label: "Ver detalhes",
                onClick: () => seeDetails(data),
                visible: !(
                  data.status === CollectionStatus.Failed &&
                  !data.detail.digitableLine
                ),
              },
              {
                icon: { category: "orq", icon: "orq-visibility-on" },
                label: "Ver eventos",
                onClick: () => seeEvents(data),
                visible: true,
              },
              {
                icon: { category: "orq", icon: "orq-communication" },
                label: "Ver envios",
                onClick: () => seeCommunicationHistory(data),
                visible: true,
              },
              {
                icon: { category: "orq", icon: "orq-edit-pencil" },
                label: "Editar floating",
                onClick: () => editCollectionFloating(data),
                role: roles.banking.customer.beneficiaryFloatingActions.role,
                visible: isAbleToEditFloating(data),
              },
            ]}
          />
        );
      },
    },
  ],
});
