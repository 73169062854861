import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useRoles } from "hooks/roles";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { GetCompanyProductsResponse } from "services/hubloan/models";
import { BNDESFrameStatus } from "./offers.type";
import { productMap } from "./offers.utils";

export const useOffers = () => {
  const [loading, setLoading] = useState(false);
  const [availableProducts, setAvailableProducts] = useState<
    GetCompanyProductsResponse[]
  >([]);
  const [frameStatus, setFrameStatus] = useState<BNDESFrameStatus>("PENDING");

  const { hasRole } = useRoles();
  const { customer } = useCustomer();

  const getAvailableProducts = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.hubLoan.getAvailableProducts();

      const filteredProducts = data.filter(
        (product) =>
          product.identification in productMap &&
          hasRole(productMap[product.identification]?.roles),
      );

      setAvailableProducts(filteredProducts);
    } catch (e) {
      const errorMessage = "Erro ao buscar os produtos";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [hasRole]);

  useEffect(() => {
    getAvailableProducts();
  }, [getAvailableProducts]);

  const getFrameStatus = useCallback(async () => {
    const bndesOffer = availableProducts?.find(
      (p) => p.fundingType === "BNDES",
    );

    if (!bndesOffer) return;

    try {
      const { data } = await service.hubLoan.frameTaxId(
        customer.identification,
        bndesOffer.offerId,
      );

      if (frameStatus !== data) setFrameStatus(data);
    } catch (e) {
      setFrameStatus("ERROR");
    }
  }, [availableProducts, frameStatus, customer.identification]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    const requestFrameStatus = async () => {
      if (frameStatus !== "PENDING") return;

      await getFrameStatus();

      if (frameStatus === "PENDING") {
        timeoutId = setTimeout(requestFrameStatus, 5000);
      }
    };

    requestFrameStatus();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [frameStatus, getFrameStatus]);

  return {
    availableProducts,
    loading,
    frameStatus,
  };
};
