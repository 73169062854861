import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { MonitoringTemplate } from "../../../../components/monitoramento";
import { GuarantorMonitoringGrid } from "./guarantor-monitoring.grid";

export const GuarantorMonitoring = () => {
  const gridRef = useGridRef();
  const { customer } = useCustomer();

  return (
    <>
      <PageTitle title="Monitoramento Avalistas" />
      <MonitoringTemplate
        filterFields={[
          {
            label: "Tipo de avalista",
            property: "Type",
          },
          {
            label: "Nome do avalista",
            property: "UserName",
          },
          {
            label: "TaxId do avalista",
            property: "UserIdentification",
          },
        ]}
        initialFilter={{
          CompanyIdentification: customer.identification,
        }}
        relationship="GUARANTOR"
        gridBuilder={GuarantorMonitoringGrid}
        gridRef={gridRef}
      />
    </>
  );
};
