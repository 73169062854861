import {
  CedenteSimplesClerkStep,
  CedenteSimplesComplementValidateStep,
  CedenteSimplesLessStep,
  TomadorCreditoClerkStep,
  TomadorCreditoComplementValidateStep,
  TomadorCreditoLessStep,
  TomadorCreditoRiskAnalysisStep,
  TomadorCreditoWebhookDevelopersStep,
} from "./step-fields";

interface WorkflowConfigurationFormMapperProps {
  fieldName: "configurations" | "syncConfigurations";
  stepType: ScfDevelopers.WorkflowStepType;
  index: number;
}

export const WorkflowConfigurationFormMapper = ({
  fieldName,
  stepType,
  index,
}: WorkflowConfigurationFormMapperProps) => {
  if (stepType === "CEDENTE_SIMPLES_COMPLEMENT_VALIDATE") {
    return (
      <CedenteSimplesComplementValidateStep
        fieldName={fieldName}
        index={index}
      />
    );
  }
  if (stepType === "CEDENTE_SIMPLES_LESS") {
    return <CedenteSimplesLessStep fieldName={fieldName} index={index} />;
  }
  if (stepType === "CEDENTE_SIMPLES_CLERK") {
    return <CedenteSimplesClerkStep fieldName={fieldName} index={index} />;
  }
  if (stepType === "TOMADOR_CREDITO_COMPLEMENT_VALIDATE") {
    return (
      <TomadorCreditoComplementValidateStep
        fieldName={fieldName}
        index={index}
      />
    );
  }
  if (stepType === "TOMADOR_CREDITO_RISKANALYSIS") {
    return (
      <TomadorCreditoRiskAnalysisStep fieldName={fieldName} index={index} />
    );
  }
  if (stepType === "TOMADOR_CREDITO_LESS") {
    return <TomadorCreditoLessStep fieldName={fieldName} index={index} />;
  }
  if (stepType === "TOMADOR_CREDITO_CLERK") {
    return <TomadorCreditoClerkStep fieldName={fieldName} index={index} />;
  }
  if (stepType === "TOMADOR_CREDITO_WEBHOOK_DEVELOPERS") {
    return (
      <TomadorCreditoWebhookDevelopersStep
        fieldName={fieldName}
        index={index}
      />
    );
  }
  return <>{null}</>;
};
