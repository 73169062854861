import { modalManager, OTooltip, OTypography } from "@maestro/react";
import { CustomPagination } from "components/custom-pagination";
import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { GridTemplate } from "templates/grid-template";
import { FundSelect } from "../../../../components";
import { ConfirmationPendingModal } from "./_compose/confirmation-modal-pending";
import { pendingDisverbationConfirmationModalId } from "./_compose/confirmation-modal-pending/confirmation-modal.utils";
import { ConfirmationRepostModal } from "./_compose/confirmation-modal-repost";
import { repostDisverbationConfirmationModalId } from "./_compose/confirmation-modal-repost/confirmation-modal.utils";
import { useDisverbationHistorys } from "./disverbation-historys.hook";

export const DisverbationHistorys = () => {
  const {
    grid,
    dataSource,
    totalItems,
    rows,
    setRows,
    page,
    setPage,
    gridRef,
    selectedDisverbations,
    form,
    selectedFundWatch,
    loading,
    repostDisverbation,
  } = useDisverbationHistorys();

  return (
    <FormProvider {...form}>
      <div className="d-flex flex-column gap-5">
        <PageTitle
          title="Histórico de Desaverbação"
          description="Visualize as parcelas por fundo."
        />
        <FundSelect />
        {selectedFundWatch && (
          <GridTemplate
            gridRef={gridRef}
            actions={
              <>
                <OTooltip position="top" maxWidth="600px" floating>
                  <div slot="tooltip-content">
                    <OTypography>
                      O envio automático ocorrerá diariamente às 05h:00 am.
                    </OTypography>
                  </div>
                  <IconButton
                    dataAction="envio_desaverbacao:botao:envio_desaverbacao"
                    dataLabel="envio_desaverbacao"
                    onClick={() =>
                      modalManager.show(pendingDisverbationConfirmationModalId)
                    }
                    type="info"
                    icon={{ category: "far", icon: "fa-paper-plane" }}
                  >
                    Enviar Pendentes
                  </IconButton>
                </OTooltip>
                <IconButton
                  dataAction="republicar_desaverbacao:botao:republicar_desaverbacao"
                  dataLabel="republicar_desaverbacao"
                  disabled={
                    !(selectedDisverbations && selectedDisverbations.length > 0)
                  }
                  onClick={() =>
                    modalManager.show(repostDisverbationConfirmationModalId)
                  }
                  type="success"
                  icon={{ category: "far", icon: "fa-check-square" }}
                >
                  Republicar
                </IconButton>
              </>
            }
            showClearFiltersButton
            showRefreshButton
            showExportButton
          >
            <ODataGridGenerator
              gridRef={gridRef}
              grid={grid}
              dataSource={dataSource}
            />
            <CustomPagination
              totalCount={totalItems}
              currentPage={page}
              setCurrentPage={setPage}
              pagination={[10, 20, 30]}
              selectedPaginationSize={rows}
              setSelectedPaginationSize={setRows}
            />
            <ConfirmationRepostModal
              id={repostDisverbationConfirmationModalId}
              isConfirmationModalProcessing={loading}
              onConfirm={repostDisverbation}
            />
            <ConfirmationPendingModal
              id={pendingDisverbationConfirmationModalId}
              isConfirmationModalProcessing={loading}
              onConfirm={repostDisverbation}
            />
          </GridTemplate>
        )}
      </div>
    </FormProvider>
  );
};
