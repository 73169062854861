import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { PostProfileManagementAdminRequestOdataResponse } from "services/gatekeeper/models";
import { service } from "services/service";
import { GridTemplate } from "templates/grid-template";
import { adminProfilesRequestsGrid } from "./admin-profiles-requests.grid";

const dataSource = dataSourceCustomStoreGenerator<
  PostProfileManagementAdminRequestOdataResponse["data"][number]
>((loadOptions) =>
  service.gatekeeper
    .getAdminProfileManagementRequests(loadOptions as any)
    .then(({ data }) => ({
      data: data.data ?? [],
      totalCount: data.total_count,
    }))
    .catch(() => {
      throw new Error("Erro ao buscar solicitações");
    }),
);

export const AdminProfilesRequestsPage = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Solicitações" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={adminProfilesRequestsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
