import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { RecebiveisClienteAssinaturasByRuleIdByGroupIdRouteParams } from "../../../../../../../recebiveis/routes/recebiveis.route-params";

export const useSignatureGroupDetails = () => {
  const { ruleId, groupId } =
    useParams<RecebiveisClienteAssinaturasByRuleIdByGroupIdRouteParams>();
  if (!ruleId || !groupId) throw new Error("No ruleId or groupId");

  const { callService, hasError, loading, value } = useServiceCall(
    service.paperclipv2.getSignatureRule,
  );

  const getSignatureRule = useCallback(
    () => callService(ruleId),
    [callService, ruleId],
  );

  useEffect(() => {
    getSignatureRule();
  }, [getSignatureRule]);

  const groupOfSignatures = useMemo(
    () =>
      value?.groupOfSignatures.find((group) => group.id.toString() === groupId),
    [groupId, value?.groupOfSignatures],
  );

  return {
    getSignatureRule,
    groupOfSignatures,
    hasError,
    loading,
    ruleId,
  };
};
