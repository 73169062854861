import { PropTypesMap } from "@maestro/react/dist/types/atoms/form/field-input/types";
import { BatchTemplatePropertyField } from "./_compose/batch-template-field/batch-template-field.component";
import { BooleanPropertyField } from "./_compose/boolean-property-field/boolean-property-field.component";
import { ConditionPropertyField } from "./_compose/condition-property-field/condition-property-field.component";
import { CreditLineField } from "./_compose/credit-line-field/credit-line-field.component";
import { FilePropertyField } from "./_compose/file-property-field/file-property-field.component";
import { FormPropertyField } from "./_compose/form-property-field/form-property-field.component";
import { GenericPropertyField } from "./_compose/generic-property-field/generic-property-field.component";
import { MultiSelectField } from "./_compose/multi-select-field";
import { ProcessorConfigField } from "./_compose/processor-config-field/processor-config-field.component";
import { WorkflowConfigField } from "./_compose/workflow-config-field/workflow-config-field.component";
import { GenericPropertyFieldProps } from "./property-field.types";

export const processorPropertyTypeMap: Record<string, keyof PropTypesMap> = {
  TEXT: "text",
  TEXTAREA: "textarea",
  NUMBER: "number",
  INTEGER: "number",
};

export const fields: Record<
  string,
  (props: GenericPropertyFieldProps) => JSX.Element
> = {
  FORM: (props: GenericPropertyFieldProps) => <FormPropertyField {...props} />,
  FILE: (props: GenericPropertyFieldProps) => <FilePropertyField {...props} />,
  CONDITION: (props: GenericPropertyFieldProps) => (
    <ConditionPropertyField {...props} />
  ),
  BOOLEAN: (props: GenericPropertyFieldProps) => (
    <BooleanPropertyField {...props} />
  ),
  BATCH_TEMPLATE: (props: GenericPropertyFieldProps) => (
    <BatchTemplatePropertyField {...props} />
  ),
  WORKFLOW_CONFIG: (props: GenericPropertyFieldProps) => (
    <WorkflowConfigField {...props} />
  ),
  PROCESSOR_CONFIG: (props: GenericPropertyFieldProps) => (
    <ProcessorConfigField {...props} />
  ),
  CREDIT_LINE: (props: GenericPropertyFieldProps) => (
    <CreditLineField {...props} />
  ),
  MULTI_SELECT: (props: GenericPropertyFieldProps) => (
    <MultiSelectField {...props} />
  ),
  DEFAULT: (props: GenericPropertyFieldProps) => (
    <GenericPropertyField {...props} />
  ),
};
