import { isAxiosError } from "axios";
import debounce from "lodash/debounce";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import { logger } from "utils/logger";
import { CustomerSelectorItem } from "../customer-selector.types";

interface SearchEntityContext {
  loading: boolean;
  hasError: boolean;
  entities: CustomerSelectorItem[];
  debouncedSearchEntity: (
    query: string | undefined,
  ) => Promise<void> | undefined;
  setLoading: (loading: boolean) => void;
}

const searchEntityContext = createContext({} as SearchEntityContext);

interface SearchEntityProps {
  children: React.ReactNode;
}

export const SearchEntityProvider = ({ children }: SearchEntityProps) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [entities, setEntities] = useState<CustomerSelectorItem[]>([]);

  const searchEntity = useCallback(async (query: string | undefined) => {
    if (!query) {
      setEntities([]);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const { data } = await service.clerk.entitySearch({
        q: query,
      });

      setEntities(
        data.data.filter(
          (item) => !!item.identification,
        ) as CustomerSelectorItem[],
      );
      setHasError(false);
    } catch (err) {
      if (!isAxiosError(err)) logger.error(err);
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const debouncedSearchEntity = useMemo(() => {
    return debounce(searchEntity, 500);
  }, [searchEntity]);

  const value = useMemo(
    () => ({ loading, hasError, entities, debouncedSearchEntity, setLoading }),
    [debouncedSearchEntity, entities, hasError, loading],
  );

  return (
    <searchEntityContext.Provider value={value}>
      {children}
    </searchEntityContext.Provider>
  );
};

export const useSearchEntity = () => useContext(searchEntityContext);
