import { logger } from "utils/logger";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  OCard,
  OCardBody,
  OCol,
  OLoader,
  ORow,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { PageTitle } from "components/page-title";
import { service } from "services";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import { LoadingButton } from "components/loading-button";
import { ODataGridGenerator } from "components/data-grid";
import { guarantorsGrid } from "./guarantors.grid";
import { SimulationFooter } from "../../footer/footer.component";
import { productMap } from "../../../../../../offers.utils";
import { useGuarantorsSetup } from "../../../../../../offers-guarantors-hook.component";

export const Guarantors = () => {
  const [loading, setLoading] = useState(false);

  const { guarantorsSetup, setGuarantors, guarantors } = useGuarantorsSetup();

  const { type } = useParams<{
    type: string;
  }>();

  const product = productMap[type?.toUpperCase() || ""];

  const displayOnboardingGuarantorButton = useMemo(() => {
    return guarantors?.some((g) => g.canSendToAnalysis);
  }, [guarantors]);

  const sendGuarantors = useCallback(async () => {
    try {
      if (!guarantorsSetup) return;
      setLoading(true);

      await service.onboardingRelationships.onboardingGuarantor(
        guarantorsSetup,
      );

      OToastManager.success("As alterações foram enviadas com sucesso.");
    } catch (err) {
      OToastManager.danger(
        "Ocorreu um erro ao enviar as alterações dos dados do(s) avalista(s).",
      );
    } finally {
      setLoading(false);
    }
  }, [guarantorsSetup]);

  const loadGuarantors = useCallback(async () => {
    try {
      if (!guarantorsSetup) return;

      setLoading(true);

      const { data } = await service.onboardingRelationships.loadGuarantors(
        guarantorsSetup,
      );

      setGuarantors(data.guarantorsResponse);
    } catch (err) {
      OToastManager.danger("Ocorreu um erro ao buscar os avalistas");
      logger.error(err);
    } finally {
      setLoading(false);
    }
  }, [guarantorsSetup, setGuarantors]);

  useEffect(() => {
    loadGuarantors();
  }, [loadGuarantors]);

  const guarantorsMemo = useMemo(() => {
    return (
      <>
        {!!guarantorsSetup && (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <ORow>
                <OCol lg={12}>
                  <OTypography className="mb-2" size="xl">
                    {product?.guarantors.title}
                  </OTypography>
                </OCol>
                <OCol lg={12}>
                  <OTypography type="default">
                    {product?.guarantors.description}
                  </OTypography>
                </OCol>
              </ORow>
            </div>
            <GridTemplate
              actions={
                <>
                  {displayOnboardingGuarantorButton && (
                    <LoadingButton
                      loading={loading}
                      type="tertiary"
                      onClick={sendGuarantors}
                      className="m-2"
                    >
                      Enviar alterações
                    </LoadingButton>
                  )}
                </>
              }
            >
              <div className="mb-4">
                <ODataGridGenerator
                  grid={guarantorsGrid}
                  dataSource={guarantors}
                />
              </div>
            </GridTemplate>
          </>
        )}
      </>
    );
  }, [
    product,
    displayOnboardingGuarantorButton,
    loading,
    sendGuarantors,
    guarantorsSetup,
    guarantors,
  ]);

  return (
    <>
      {loading && <OLoader absolute backdrop />}
      <PageTitle
        title="Responsáveis pelo crédito"
        description="Confira o cadastro de quem precisa assinar como responsável ou avalista, de acordo com regras da empresa e dos créditos solicitados."
      />
      <OCard>
        <OCardBody>
          {guarantors?.length ? (
            guarantorsMemo
          ) : (
            <OTypography>Nenhum avalista atrelado a esta oferta.</OTypography>
          )}
        </OCardBody>
      </OCard>
      <SimulationFooter continueButtonDisabled={false} />
    </>
  );
};
