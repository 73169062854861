export enum BeneficiaryStatus {
  ABLE = "ABLE",
  PROCESSING = "PROCESSING",
  NOT_REGISTERED_CIP = "NOT_REGISTERED_CIP",
  PROCESSING_TREATY = "PROCESSING_TREATY",
}

export enum TreatyStatus {
  PROCESSING = "PROCESSING",
  ACTIVE = "ACTIVE",
}

export enum InterestType {
  NOT_APPLICABLE = "NOT_APPLICABLE",
  PERCENTAGE_PER_MONTH = "PERCENTAGE_PER_MONTH",
  FIXED_VALUE_PER_MONTH = "FIXED_VALUE_PER_MONTH",
}

export enum FeesType {
  NOT_APPLICABLE = "NOT_APPLICABLE",
  PERCENTAGE = "PERCENTAGE",
  FIXED_VALUE = "FIXED_VALUE",
}
