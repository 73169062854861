import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { WorkflowScfForm } from "../_compose/workflow-scf-form";
import { useAddWorkflowConfigurationScf } from "./add-workflow-configuration-scf.hook";

export const AddWorkflowConfigurationScf = () => {
  const { form } = useAddWorkflowConfigurationScf();

  return (
    <>
      <PageTitle title="Adicionar workflow" />
      <FormProvider {...form}>
        <WorkflowScfForm />
      </FormProvider>
    </>
  );
};
