import { OToastManager } from "@maestro/core";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";

export const useBeneficiaryData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [beneficiary, setBeneficiary] =
    useState<BankingHubAdminCollection.Beneficiary>();

  const getBeneficiary = useCallback(async () => {
    try {
      setErrorMessage("");
      setIsLoading(true);

      const { data } =
        await service.adminBankinghub.collection.getBeneficiary();

      setBeneficiary(data);
    } catch (error) {
      const message =
        axios.isAxiosError(error) && error.response?.status === 404
          ? "Beneficiário não encontrado."
          : "Ocorreu um erro ao buscar o beneficiário.";

      OToastManager.danger(message);
      setErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getBeneficiary();
  }, [getBeneficiary]);

  return { beneficiary, errorMessage, getBeneficiary, isLoading };
};
