import { client } from "../../client";
import { adminBankinghubEndpoints } from "../admin-bankinghub.endpoints";
import {
  GetAgreementsResponse,
  GetProductsResponse,
  UpsertProductLayoutRequest,
} from "./models";

export const adminBankinghubCNABService = {
  getAgreements() {
    return client.get<GetAgreementsResponse>(
      adminBankinghubEndpoints.configHub.agreements,
    );
  },

  createAgreement() {
    return client.post(adminBankinghubEndpoints.configHub.agreements);
  },

  getProducts() {
    return client.get<GetProductsResponse>(
      adminBankinghubEndpoints.fileProcessor.products.endpoint,
    );
  },

  upsertProductLayout(body: UpsertProductLayoutRequest) {
    return client.post(
      adminBankinghubEndpoints.fileProcessor.company.layout,
      body,
    );
  },
};
