import {
  modalManager,
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { ManageColumnsModalProps } from "../../data-grid-general-actions.types";
import { DraggableOrderLayout } from "./_compose/draggable-order-layout/draggable-order-layout.component";
import { useManageColumns } from "./hooks/manage-columns";
import { ManageColumnsModalId } from "./manage-columns-modal.types";

export const ManageColumnsModal = ({
  columns,
  columnLabels,
  defaultColumns = columns,
  gridId,
  setVisibleColumns,
}: ManageColumnsModalProps) => {
  const { selectedColumns, setSelectedColumns, updateVisibleColumns } =
    useManageColumns({ columns, defaultColumns, gridId, setVisibleColumns });

  return (
    <OModal id={ManageColumnsModalId} position="center" size="xs">
      <OModalHeader closeButton>
        <OTypography size="lg">Organização de colunas</OTypography>
      </OModalHeader>

      <OModalBody>
        <div className="d-flex flex-column gap-2">
          <OTypography type="dark-80">
            Escolha as colunas e arraste para cima ou para baixo para colocar na
            ordem de sua preferência na tabela.
          </OTypography>

          <DraggableOrderLayout
            columns={columns}
            columnLabels={columnLabels}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </div>
      </OModalBody>

      <OModalFooter divider>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            onClick={() => modalManager.hide(ManageColumnsModalId)}
            outline
          >
            Voltar
          </OButton>
          <OButton onClick={updateVisibleColumns}>Salvar</OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
