import { Roles } from "roles/roles.types";

export const bankingRoles = {
  customer: {
    tab: {
      role: "admin:BankingTabCustomer.View",
      description: "Visualização da tab 'Conta Corrente' na visão cliente",
    },
    accountsPage: {
      role: "admin:BankingAccountsPageCustomer.View",
      description: "Visualização da página 'Contas' na visão cliente",
    },
    accountsAccountAgencyBalancePage: {
      role: "admin:BankingAccountsAccountAgencyBalancePageCustomer.View",
      description:
        "Visualização da página 'Contas - Detalhes da Conta - Saldo' na visão cliente",
    },
    accountsAccountAgencyDailyBalancePage: {
      role: "admin:BankingAccountsAccountAgencyDailyBalancePageCustomer.View",
      description:
        "Visualização da página 'Contas - Detalhes da Conta - Saldo final do dia' na visão cliente",
    },
    accountsAccountAgencyStatementPage: {
      role: "admin:BankingAccountsAccountAgencyStatementPageCustomer.View",
      description:
        "Visualização da página 'Contas - Detalhes da Conta - Extrato' na visão cliente",
    },
    collectionBeneficiaryPage: {
      role: "admin:BankingCollectionBeneficiaryPageCustomer.View",
      description:
        "Visualização da página 'Cobranças - Beneficiário' na visão cliente",
    },
    bankslipListPage: {
      role: "admin:BankingBankslipListPageCustomer.View",
      description:
        "Visualização da página 'Cobranças - Boletos' na visão cliente",
    },
    cnabAgreementListPage: {
      role: "admin:BankingCnabAgreementPageCustomer.View",
      description:
        "Visualização da página 'Arquivos e transmissões - Convênios' na visão cliente",
    },
    cnabUpsertModelPage: {
      role: "admin:BankingCnabUpsertModelPageCustomer.View",
      description:
        "Visualização da página 'Arquivos e transmissões - Configurar modelos' na visão cliente",
    },
    collectionsAgreementListPage: {
      role: "admin:BankingCollectionsAgreementPageCustomer.View",
      description:
        "Visualização da página 'Cobranças - Convênio' na visão cliente",
    },
    cancelAllBankslipsAction: {
      role: "admin:BankingCancelAllBankslipsActionsCustomer.View",
      description:
        "Visualização da ação de Cancelar todos os boletos na página 'Cobranças - Boletos' na visão cliente",
    },
    beneficiaryFloatingActions: {
      role: "admin:BankingBeneficiaryFloatingActionsCustomer.View",
      description:
        "Visualização das ações de Floating do beneficiário na página 'Cobranças - Boletos' na visão cliente",
    },
    pixKeysListPage: {
      role: "admin:BankingPixKeysListPageCustomer.View",
      description:
        "Visualização da página 'Cobranças - Chaves pix' na visão cliente",
    },
    bankslipCancellingPage: {
      role: "admin:BankingBankslipCancellingPageCustomer.View",
      description:
        "Visualização da página 'Boleto - Cancelamento' na visão cliente",
    },
    bankslipAdminConsultationPage: {
      role: "admin:BankingBankslipAdminConsultationPageCustomer.View",
      description:
        "Visualização da página 'Boleto - Consulta' na visão cliente",
    },
    bankslipAdminConsultationDetailsPage: {
      role: "admin:BankingBankslipAdminConsultationDetailsPageCustomer.View",
      description:
        "Visualização da página 'Boleto - Consulta - Detalhes' na visão cliente",
    },
    overdraftPage: {
      role: "admin:BankingOverdraftPageCustomer.View",
      description: "Visualização da página 'Limite da conta' na visão cliente",
    },
    overdraftInstallmentsPage: {
      role: "admin:BankingOverdraftInstallmentsPageCustomer.View",
      description:
        "Visualização da página 'Limite da conta - Parcelamentos' na visão cliente",
    },
    overdraftGuarantorsPage: {
      role: "admin:BankingOverdraftGuarantorsPageCustomer.View",
      description:
        "Visualização da página 'Limite da conta - Avalistas' na visão cliente",
    },
    transfersScheduledPage: {
      role: "admin:BankingTransfersScheduledPageCustomer.View",
      description:
        "Visualização da página 'Transferências - Agendadas' na visão cliente",
    },
    transfersSentPage: {
      role: "admin:BankingTransfersSentPageCustomer.View",
      description:
        "Visualização da página 'Transferências - Realizadas' na visão cliente",
    },
    paymentsScheduledPage: {
      role: "admin:BankingPaymentsScheduledPageCustomer.View",
      description:
        "Visualização da página 'Pagamentos - Agendados' na visão cliente",
    },
    paymentsSentPage: {
      role: "admin:BankingPaymentsSentPageCustomer.View",
      description:
        "Visualização da página 'Pagamentos - Realizados' na visão cliente",
    },
    paymentsAndTransfersPage: {
      role: "admin:BankingPaymentsAndTransfersPageCustomer.View",
      description:
        "Visualização da página 'Pagamentos e transferências' na visão cliente",
    },
    contactsFavoredPage: {
      role: "admin:OnboardingContactsFavoredPageCustomer.View",
      description:
        "Visualização da página 'Contatos / Favorecidos - Favorecidos' na visão cliente",
    },
    contactsFavoredCreatePage: {
      role: "admin:OnboardingContactsFavoredCreatePageCustomer.View",
      description:
        "Visualização da página 'Contatos / Favorecidos - Favorecidos - Cadastrar' na visão cliente",
    },
    contactsFavoredEditPage: {
      role: "admin:OnboardingContactsFavoredEditPageCustomer.View",
      description:
        "Visualização da página 'Contatos / Favorecidos - Favorecidos - Editar' na visão cliente",
    },
    btgAccountsAccountAdminCorporateTaxPage: {
      role: "admin:OnboardingBtgAccountsAccountAdminCorporateTaxPageCustomer.View",
      description:
        "Visualização da página 'Contas BTG - Contas - Informe de rendimentos' na visão cliente",
    },
    supplierPaymentsPage: {
      role: "admin:BankingSupplierPaymentsPageCustomer.View",
      description:
        "Visualização da página 'Pagamentos ao fornecedor' na visão cliente",
    },
  },
  product: {
    tab: {
      role: "admin:BankingTabProduct.View",
      description: "Visualização da tab 'Banking' na visão produto",
    },
    bankingManagerPage: {
      role: "admin:BankingBankingManagerPageProduct.View",
      description:
        "Visualização da página 'Banking - Banking manager' na visão produto",
    },
    bankingManagerRegisterPage: {
      role: "admin:BankingBankingManagerRegisterPageProduct.View",
      description:
        "Visualização da página 'Banking - Banking manager - Ativação da Empresa' na visão produto",
    },
    cnabMonitorPage: {
      role: "admin:BankingCnabMonitorPageProduct.View",
      description:
        "Visualização da página 'Banking - Extrato eletrônico - Monitor' na visão produto",
    },
    cnabRegisterPage: {
      role: "admin:BankingCnabRegisterPageProduct.View",
      description:
        "Visualização da página 'Banking - Extrato eletrônico - Cadastro' na visão produto",
    },
    cnabSearchPage: {
      role: "admin:BankingCnabSearchPageProduct.View",
      description:
        "Visualização da página 'Banking - Extrato eletrônico - Histórico' na visão produto",
    },
    cnabSearchDetailsPage: {
      role: "admin:BankingCnabSearchDetailsPageProduct.View",
      description:
        "Visualização da página 'Banking - Extrato eletrônico - Histórico - Detalhes' na visão produto",
    },
    cnabReprocessFilesPage: {
      role: "admin:BankingCnabReprocessFilesPageProduct.View",
      description:
        "Visualização da página 'Banking - Extrato eletrônico - Reprocessar arquivos' na visão produto",
    },
    overdraftPage: {
      role: "admin:BankingOverdraftPageProduct.View",
      description:
        "Visualização da página 'Banking - Limite da conta' na visão produto",
    },
    pixKeySearchPage: {
      role: "admin:BankingPixKeySearchPageProduct.View",
      description:
        "Visualização da página 'Banking - Consulta de chave Pix' na visão produto",
    },
    bankslipSearchPage: {
      role: "admin:BankingBankslipSearchPageProduct.View",
      description:
        "Visualização da página 'Banking - Consulta de boletos' na visão produto",
    },
    cnabIntegrationPage: {
      role: "admin:BankingCnabIntegrationPageProduct.View",
      description:
        "Visualização da página 'Banking - Integrações' na visão produto",
    },
    supplierPaymentsPage: {
      role: "admin:BankingSupplierPaymentsPageProduct.View",
      description:
        "Visualização da página 'Banking - Pagamentos ao fornecedor' na visão produto",
    },
    reconciliationsPage: {
      role: "admin:BankingReconciliationsProduct.View",
      description:
        "Visualização da página 'Banking - Reconciliações' na visão produto",
    },
    reconciliationsDetailsPage: {
      role: "admin:BankingReconciliationsDetailsProduct.View",
      description:
        "Visualização da página 'Banking - Reconciliações - Detalhes' na visão produto",
    },
    reconciliationsApproveButton: {
      role: "admin:BankingReconciliationsDetailsApproveButton.View",
      description:
        "Visualização do botão de aprovar um resultado de reconciliação",
    },
    reconciliationsJustifyButton: {
      role: "admin:BankingReconciliationsDetailsJustifyButton.View",
      description:
        "Visualização do botão de justificar um resultado de reconciliação",
    },
  },
} satisfies Roles;
