import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { publishInvoicesRequestsGrid } from "./exportacoes-notas-fiscais.grid";

export const PublishInvoicesRequestsPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Exportações de notas fiscais" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
      actions={
        <RouterButton
          href={
            corporateRouter.routes.emprestimos.product.invoices.requestExport
              .path
          }
          role={roles.emprestimos.product.tradeImportsFtsPage.role}
          outline
        >
          Exportar notas fiscais
        </RouterButton>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={publishInvoicesRequestsGrid}
      />
    </GridTemplate>
  );
};
