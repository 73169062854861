import { client } from "../client";
import {
  GetAdministrativeUnitCitiesParams,
  GetAdministrativeUnitCitiesResponse,
  GetAdministrativeUnitCityParams,
  GetAdministrativeUnitCityResponse,
  GetApiAdminCompanyGroupGetCompaniesFromGroupByTaxIdResponse,
  GetApiAdminDesksOnboardingResponse,
  GetApiAdminDownloadFileResponse,
  GetOdataAmlResponseMonitorResponse,
  PostAdminCompanyGroupGetCompaniesFromGroupBody,
  PostAdminCompanyGroupGetCompaniesFromGroupResponse,
  PostAdminContractsBatchCreateBody,
  PostAdminContractsBatchCreateResponse,
  PostAdminOnboardUserWelcomeEmailBody,
  PostAdminOnboardUserWelcomeEmailBodyResponse,
  PostAdminUserBody,
  PostAdminUserCreateAdministratorBody,
  PostAdminUserCreateAdministratorResponse,
  PostAdminUserResponse,
  PostApiAdmibOnboardBatchVerifyBody,
  PostApiAdmibOnboardBatchVerifyResponse,
  PostApiAdminCompanyBody,
  PostApiAdminCompanyGroupRegisterGroupAndAssociationsBody,
  PostApiAdminCompanyGroupRegisterGroupAndAssociationsResponse,
  PostApiAdminCompanyResponse,
  PostApiAdminDesksOnboardingRegisterEntityFromDeskBody,
  PostApiAdminDesksOnboardingRegisterEntityFromDeskResponse,
  PostApiAdminGetAddressByZipCodeBody,
  PostApiAdminGetAddressByZipCodeResponse,
  PostApiAdminGetSemaphoreBody,
  PostApiAdminGetSemaphoreResponse,
  PostApiAdminOnboardBatchBody,
  PostApiAdminOnboardingLevelsRegisterLegalEntityBody,
  PostApiAdminOnboardingLevelsRegisterLegalEntityResponse,
  PostApiAdminUserGetProfilesBody,
  PostApiAdminUserGetProfilesResponse,
  PostApiCustomerInviteEnergyBody,
  PostApiCustomerInviteOpenAccountBody,
  PostApiCustomerServiceContractsCreateAccountOpeningBody,
  PostApiCustomerServiceContractsCreateAccountOpeningResponse,
  PostApiOnboardingLevelsGetSemaphoreFirstAccessBody,
  PostApiOnboardingLevelsGetSemaphoreFirstAccessResponse,
  PostApiSearchLegalEntityDetailsBody,
  PostApiSearchLegalEntityDetailsResponse,
  PostContingencyDocumentsLoadBody,
  PostContingencyDocumentsLoadResponse,
  PostContingencyDocumentsOccurrencesBody,
  PostContingencyDocumentsOccurrencesResponse,
  PostContingencyOnboardingForceReopenBody,
  PostContingencyOnboardingForceReopenResponse,
  PostContingencyOnboardingReopenBody,
  PostContingencyOnboardingReopenResponse,
  PostContingencyOnboardingResendToLessBody,
  PostContingencyOnboardingResendToLessResponse,
  PostContingencyReopenDataReviewBody,
  PostContingencyReopenDataReviewResponse,
  PostContingencyWorkflowTerminateRelationshipBody,
  PostContingencyWorkflowTerminateRelationshipResponse,
  PostCreateAccountOpeningBody,
  PostLoadAccountOpeningBody,
  PostLoadAccountOpeningResponse,
  PostSearchLegalEntityBody,
  PostSearchLegalEntityResponse,
  PostToolsCommonBody,
  PostToolsCommonResponse,
  PutApiAdminOnboardStatusBody,
  PutApiAdminOnboardStatusResponse,
  PutContingencyLegalEntityResetBody,
  PutContingencyLegalEntityUpdateDataLessBody,
  PutContingencyLegalEntityUpdateDataLessResponse,
  PutContingencyOnboadingApproveBody,
  PutContingencyOnboadingApproveResponse,
  PutContingencySyncAccountsBody,
} from "./models";
import {
  DeleteApiToolsCacheBody,
  DeleteContingencyWorkflowMigrationReproveBody,
  GetApiAdminDesksDeskConfigRelationshipParams,
  GetApiAdminDesksDocumentConfigRelationshipParams,
  GetApiAdminDesksOnboardingConfigOfficerParams,
  GettApiAdminDesksNotificationsParams,
  GettApiAdminDesksOnboardingAccountConfigParams,
  PatchAdminDesksOnboardingRegisterBody,
  PatchApiAdminDesksOnboardingAccountConfigUpdateBody,
  PatchApiAdminDesksOnboardingConfigOfficerOfficersBody,
  PatchContingencyWorkflowMigrationApproveBody,
  PostAddressByZipCodeBody,
  PostAdminDesksBody,
  PostAdminDesksOnboardingRegisterBody,
  PostAdminOfficersBody,
  PostApiAdminContractFileConfigurationRegisterBody,
  PostApiAdminDefaultContractFileConfigurationRegisterBody,
  PostApiAdminDesksAccountInfoRegisterBody,
  PostApiAdminDesksDeskConfigRelationshipBody,
  PostApiAdminDesksDocumentConfigDocumentRegisterBody,
  PostApiAdminDesksDocumentConfigRelationshipRegisterBody,
  PostApiAdminDesksNotificationRegisterBody,
  PostApiAdminDesksNotificationRegisterVariablesBody,
  PostApiAdminDesksNotificationUpdateBody,
  PostApiAdminDesksOnboardingAccountConfigRegisterBody,
  PostApiAdminDesksOnboardingAccountConfigRelationshipsBody,
  PostApiAdminDesksOnboardingAccountConfigUpsertProductsServicesBody,
  PostApiAdminOnboardingLevelsOccurencesBody,
  PostContingencyReopenDataReviewForceBody,
  PostContingencyResendDataReviewToLessBody,
  PostContingencySyncDataReviewBody,
  PostContingencyUpdateDataReviewByUuidBody,
  PostContingencyWorkflowMigrationCreateBody,
  PostContingencyWorkflowSyncBody,
  PostWorkflowDataReviewLoadEntityWorkflowsBody,
  PostWorkflowLevelsLoadEntityWorkflowsBody,
  PutAdminDesksBody,
  PutAdminOfficersBody,
  PutApiAdminContractFileConfigurationUpdateByIdBody,
  PutApiAdminDefaultContractFileConfigurationUpdateByIdBody,
  PutApiAdminDesksAccountInfoUpdateBody,
  PutApiAdminDesksDeskConfigRelationshipBody,
  PutContingencyWorkflowApproveBody,
  PutContingencyWorkflowUpdateBody,
} from "./models/requests";
import { GetApiAdminDesksOnboardingConfigPipelineParams } from "./models/requests/get-api-admin-desks-onboarding-config-pipeline.request";
import { GettApiAdminDesksOnboardingPartnerConfigParams } from "./models/requests/get-api-admin-desks-onboarding-partner-config.request";
import { GetContingencyDataUpdateGetChangesParams } from "./models/requests/get-contingency-data-update-get-changes.request";
import { PatchSemaphoreTemplateUpdateStepRequest } from "./models/requests/patch-semaphore-template-update-step.request";
import { PostApiAdminDesksOnboardingConfigOfficerBody } from "./models/requests/post-api-admin-desks-onboarding-config-officer.request";
import { PostApiAdminDesksOnboardingConfigPipelineBody } from "./models/requests/post-api-admin-desks-onboarding-config-pipeline.request";
import { PostApiAdminDesksOnboardingPartnerConfigBody } from "./models/requests/post-api-admin-desks-onboarding-partner-config.request";
import { PostContingencySyncDataUpdateBody } from "./models/requests/post-contingency-sync-data-update.request";
import { PostContingencyUpdateDataUpdateByIdBody } from "./models/requests/post-contingency-update-data-update-by-id.request";
import { PostSemaphoreBatchRequest } from "./models/requests/post-semaphore-batch.request";
import {
  DeleteSemaphoreTemplateStepRequest,
  SemaphoreTemplateStepRequest,
} from "./models/requests/post-semaphore-step-template.request";
import { RequestSemaphoreStep } from "./models/requests/post-semaphore-step.request";
import { PostSemaphoreTemplateBody } from "./models/requests/post-semaphore-template.request";
import { PostWorkflowDataUpdateLoadEntityWorkflowsBody } from "./models/requests/post-workflow-data-update-load-entity-workflows.request";
import { PutApiAdminDesksOnboardingAccountConfigRelationshipsBody } from "./models/requests/put-api-admin-desks-onboarding-account-config-relationships.request";
import { PutApiAdminDesksOnboardingConfigOfficerBody } from "./models/requests/put-api-admin-desks-onboarding-config-officer.request";
import { PutApiAdminDesksOnboardingConfigPipelineBody } from "./models/requests/put-api-admin-desks-onboarding-config-pipeline.request";
import { PutApiAdminDesksOnboardingPartnerConfigBody } from "./models/requests/put-api-admin-desks-onboarding-partner-config.request";
import {
  GetAdministrativeUnitCountriesResponse,
  GetApiAdminBtgProductsResponse,
  GetApiAdminBtgServicesResponse,
  GetApiAdminContractFileConfigurationByIdResponse,
  GetApiAdminContractFileConfigurationEnumsResponse,
  GetApiAdminContractFileConfigurationResponse,
  GetApiAdminDefaultContractFileConfigurationByIdResponse,
  GetApiAdminDefaultContractFileConfigurationEnumsResponse,
  GetApiAdminDefaultContractFileConfigurationResponse,
  GetApiAdminDesksAccountInfoByUuidResponse,
  GetApiAdminDesksAccountInfoEnumsResponse,
  GetApiAdminDesksAccountInfoResponse,
  GetApiAdminDesksByDeskCodeResponse,
  GetApiAdminDesksDeskConfigRelationshipByUuidResponse,
  GetApiAdminDesksDeskConfigRelationshipResponse,
  GetApiAdminDesksDocumentConfigEnumsResponse,
  GetApiAdminDesksDocumentConfigRelationshipResponse,
  GetApiAdminDesksDocumentConfigResponse,
  GetApiAdminDesksNotificationResponse,
  GetApiAdminDesksOnboardingAccountConfigByUuidRelationshipsResponse,
  GetApiAdminDesksOnboardingAccountConfigByUuidResponse,
  GetApiAdminDesksOnboardingAccountConfigEnumsResponse,
  GetApiAdminDesksOnboardingAccountConfigProductsServicesResponse,
  GetApiAdminDesksOnboardingAccountConfigResponse,
  GetApiAdminDesksOnboardingConfigOfficerResponse,
  GetApiAdminDesksOnboardingConfigsByConfigIdentifierResponse,
  GetApiAdminDesksOnboardingConfigsResponse,
  GetApiAdminDesksOnboardingEnumsResponse,
  GetApiAdminDesksResponse,
  GetApiAdminOfficersByIdResponse,
  GetApiAdminOfficersResponse,
  GetApiAdminSegmentGroupResponse,
  GetContingencyDataReviewByUuidLogsResponse,
  GetContingencyWorkflowByUuidLogsResponse,
  GetContingencyWorkflowMigrationByMigrationIdDetailsResponse,
  GetContingencyWorkflowMigrationListResponse,
  GetToolsCommonAllResponse,
  GetToolsCommonByGroupKeyResponse,
  GetWorkflowDataReviewEnumsResponse,
  GetWorkflowLevelsEnumsResponse,
  GetWorkflowLevelsGetAccountsByWorkflowResponse,
  GettWorkflowDataReviewByUuidResponse,
  PostAddressByZipCodeResponse,
  PostWorkflowDataReviewLoadEntityWorkflowsResponse,
  PostWorkflowLevelsLoadEntityWorkflowsResponse,
} from "./models/responses";
import { GetApiAdminDesksOnboardingConfigPipelineEnumsResponse } from "./models/responses/get-api-admin-desks-onboarding-config-pipeline-enums.response";
import { GetApiAdminDesksOnboardingConfigPipelineResponse } from "./models/responses/get-api-admin-desks-onboarding-config-pipeline.response";
import { GetApiAdminDesksOnboardingPartnerConfigByUuidResponse } from "./models/responses/get-api-admin-desks-onboarding-partner-config-by-uuid.response";
import { GetApiAdminDesksOnboardingPartnerConfigEnumsResponse } from "./models/responses/get-api-admin-desks-onboarding-partner-config-enums.response";
import { GetApiAdminDesksOnboardingPartnerConfigResponse } from "./models/responses/get-api-admin-desks-onboarding-partner-config.response";
import { GetContingencyDataUpdateByUuidLogsResponse } from "./models/responses/get-contingency-data-update-by-uuid-logs.response";
import {
  GetEntitySemaphoreItem,
  GetEntitySemaphoreList,
} from "./models/responses/get-entity-semaphore.response";
import { GetGroupAssociationResponse } from "./models/responses/get-group-association.response";
import { GetPipelineConfigurationList } from "./models/responses/get-pipeline-configuration.response";
import {
  GetSemaphoreTemplateListResponse,
  GetSemaphoreTemplateResponse,
} from "./models/responses/get-semaphore-configuration.response";
import {
  GetSemaphoreStepListResponse,
  GetSemaphoreStepResponse,
} from "./models/responses/get-semaphore-step.response";
import { GettWorkflowDataUpdateByUuidResponse } from "./models/responses/get-workflow-data-update-by-uuid.response";
import { GetWorkflowDataUpdateEnumsResponse } from "./models/responses/get-workflow-data-update-enums.response";
import { PostWorkflowDataUpdateLoadEntityWorkflowsResponse } from "./models/responses/post-workflow-data-update-load-entity-workflows.response";
import { hubOnboardingODataService } from "./odata/onboarding-odata.service";
import { onboardingEndpoints } from "./onboarding.endpoints";

export const onboardingService = {
  odata: hubOnboardingODataService,
  loadAccountOpening(body: PostLoadAccountOpeningBody) {
    return client.post<PostLoadAccountOpeningResponse>(
      onboardingEndpoints.customerService.invites.loadAccountOpening,
      body,
    );
  },
  createAccountOpening(body: PostCreateAccountOpeningBody) {
    return client.post(
      onboardingEndpoints.customerService.invites.createAccountOpening,
      body,
    );
  },
  updateUserEmail(body: PostAdminUserBody) {
    return client.post<PostAdminUserResponse>(
      onboardingEndpoints.admin.user.endpoint,
      body,
    );
  },
  sendWelcomeEmail(body: PostAdminOnboardUserWelcomeEmailBody) {
    return client.post<PostAdminOnboardUserWelcomeEmailBodyResponse>(
      onboardingEndpoints.admin.onboard.user.welcomeEmail,
      body,
    );
  },
  getAMLMonitor() {
    return client.get<GetOdataAmlResponseMonitorResponse>(
      onboardingEndpoints.odata.amlResponse.monitor,
    );
  },
  getAddress(body: PostAddressByZipCodeBody) {
    return client.post<PostAddressByZipCodeResponse>(
      onboardingEndpoints.address.byZipCode,
      body,
    );
  },
  getCity(params: GetAdministrativeUnitCityParams) {
    return client.get<GetAdministrativeUnitCityResponse>(
      onboardingEndpoints.administrativeUnit.city,
      { params },
    );
  },
  getCities(params: GetAdministrativeUnitCitiesParams) {
    return client.get<GetAdministrativeUnitCitiesResponse>(
      onboardingEndpoints.administrativeUnit.cities,
      { params },
    );
  },
  getCountries() {
    return client.get<GetAdministrativeUnitCountriesResponse>(
      onboardingEndpoints.administrativeUnit.countries,
    );
  },
  searchLegalEntity(body: PostSearchLegalEntityBody) {
    return client.post<PostSearchLegalEntityResponse>(
      onboardingEndpoints.searchLegalEntity.endpoint,
      body,
    );
  },
  getSegmentGroups() {
    return client.get<GetApiAdminSegmentGroupResponse>(
      onboardingEndpoints.admin.segmentGroup.endpoint,
    );
  },
  getDesks() {
    return client.get<GetApiAdminDesksResponse>(
      onboardingEndpoints.admin.desks.endpoint,
    );
  },
  getDeskDetails(deskCode: string | number) {
    return client.get<GetApiAdminDesksByDeskCodeResponse>(
      onboardingEndpoints.admin.desks.byDeskCode(deskCode),
    );
  },
  registerDesk(body: PostAdminDesksBody) {
    return client.post(onboardingEndpoints.admin.desks.endpoint, body);
  },
  updateDesk(body: PutAdminDesksBody) {
    return client.put(onboardingEndpoints.admin.desks.endpoint, body);
  },
  getDesksOnboardingEnums() {
    return client.get<GetApiAdminDesksOnboardingEnumsResponse>(
      onboardingEndpoints.admin.desks.onboarding.enums,
    );
  },
  getDesksOnboarding() {
    return client.get<GetApiAdminDesksOnboardingResponse>(
      onboardingEndpoints.admin.desks.onboarding.endpoint,
    );
  },
  getDesksOnboardingConfigs() {
    return client.get<GetApiAdminDesksOnboardingConfigsResponse>(
      onboardingEndpoints.admin.desks.onboarding.configs,
    );
  },
  getDesksOnboardingConfigDetails(configIdentifier: string) {
    return client.get<GetApiAdminDesksOnboardingConfigsByConfigIdentifierResponse>(
      onboardingEndpoints.admin.desks.onboarding.configsByConfigIdentifier(
        configIdentifier,
      ),
    );
  },
  registerDesksOnboardingConfig(body: PostAdminDesksOnboardingRegisterBody) {
    return client.post(
      onboardingEndpoints.admin.desks.onboarding.register,
      body,
    );
  },
  updateDesksOnboardingConfig(body: PatchAdminDesksOnboardingRegisterBody) {
    return client.patch(
      onboardingEndpoints.admin.desks.onboarding.update,
      body,
    );
  },
  getDeskConfigRelationships(
    params?: GetApiAdminDesksDeskConfigRelationshipParams,
  ) {
    return client.get<GetApiAdminDesksDeskConfigRelationshipResponse>(
      onboardingEndpoints.admin.desks.deskConfigRelationship.endpoint,
      { params },
    );
  },
  getDeskConfigRelationshipDetails(uuid: string) {
    return client.get<GetApiAdminDesksDeskConfigRelationshipByUuidResponse>(
      onboardingEndpoints.admin.desks.deskConfigRelationship.byUuid(uuid),
    );
  },
  registerDeskConfigRelationship(
    body: PostApiAdminDesksDeskConfigRelationshipBody,
  ) {
    return client.post(
      onboardingEndpoints.admin.desks.deskConfigRelationship.endpoint,
      body,
    );
  },
  updateDeskConfigRelationship(
    body: PutApiAdminDesksDeskConfigRelationshipBody,
  ) {
    return client.put(
      onboardingEndpoints.admin.desks.deskConfigRelationship.endpoint,
      body,
    );
  },
  removeDeskConfigRelationships(uuid: string) {
    return client.delete(
      onboardingEndpoints.admin.desks.deskConfigRelationship.byUuid(uuid),
    );
  },
  getDeskOnboardingDocumentConfig() {
    return client.get<GetApiAdminDesksDocumentConfigResponse>(
      onboardingEndpoints.admin.desks.documentConfig.endpoint,
    );
  },
  getDeskOnboardingDocumentConfigEnums() {
    return client.get<GetApiAdminDesksDocumentConfigEnumsResponse>(
      onboardingEndpoints.admin.desks.documentConfig.enums,
    );
  },
  registerDeskOnboardingDocumentConfig(
    body: PostApiAdminDesksDocumentConfigDocumentRegisterBody,
  ) {
    return client.post(
      onboardingEndpoints.admin.desks.documentConfig.documentRegister,
      body,
    );
  },
  getDeskOnboardingDocumentConfigRelationships(
    params?: GetApiAdminDesksDocumentConfigRelationshipParams,
  ) {
    return client.get<GetApiAdminDesksDocumentConfigRelationshipResponse>(
      onboardingEndpoints.admin.desks.documentConfig.relationships,
      { params },
    );
  },
  registerDeskOnboardingDocumentConfigRelationship(
    body: PostApiAdminDesksDocumentConfigRelationshipRegisterBody,
  ) {
    return client.post(
      onboardingEndpoints.admin.desks.documentConfig.relationshipRegister,
      body,
    );
  },
  removeDeskOnboardingDocumentConfigRelationship(uuid: string) {
    return client.delete(
      onboardingEndpoints.admin.desks.documentConfig.relationshipsByUuid(uuid),
    );
  },
  getDeskOnboardingAccountInfo() {
    return client.get<GetApiAdminDesksAccountInfoResponse>(
      onboardingEndpoints.admin.desks.accountInfo.endpoint,
    );
  },
  getDeskOnboardingAccountInfoDetails(uuid: string) {
    return client.get<GetApiAdminDesksAccountInfoByUuidResponse>(
      onboardingEndpoints.admin.desks.accountInfo.byUuid(uuid),
    );
  },
  getDeskOnboardingAccountInfoEnums() {
    return client.get<GetApiAdminDesksAccountInfoEnumsResponse>(
      onboardingEndpoints.admin.desks.accountInfo.enums,
    );
  },
  registerDeskOnboardingAccountInfo(
    body: PostApiAdminDesksAccountInfoRegisterBody,
  ) {
    return client.post(
      onboardingEndpoints.admin.desks.accountInfo.register,
      body,
    );
  },
  updateDeskOnboardingAccountInfo(body: PutApiAdminDesksAccountInfoUpdateBody) {
    return client.put(onboardingEndpoints.admin.desks.accountInfo.update, body);
  },
  getBtgProducts() {
    return client.get<GetApiAdminBtgProductsResponse>(
      onboardingEndpoints.admin.btgProducts,
    );
  },
  getBtgServices() {
    return client.get<GetApiAdminBtgServicesResponse>(
      onboardingEndpoints.admin.btgServices,
    );
  },
  getDeskOnboardingAccountConfigProductsAndServices(uuid: string) {
    return client.get<GetApiAdminDesksOnboardingAccountConfigProductsServicesResponse>(
      onboardingEndpoints.admin.desks.onboardingAccountConfig.byUuidProductsServices(
        uuid,
      ),
    );
  },
  upsertDeskOnboardingAccountConfigProductsAndServices(
    body: PostApiAdminDesksOnboardingAccountConfigUpsertProductsServicesBody,
  ) {
    return client.post(
      onboardingEndpoints.admin.desks.onboardingAccountConfig
        .upsertProductsServices,
      body,
    );
  },
  getDeskOnboardingAccountConfig(
    params?: GettApiAdminDesksOnboardingAccountConfigParams,
  ) {
    return client.get<GetApiAdminDesksOnboardingAccountConfigResponse>(
      onboardingEndpoints.admin.desks.onboardingAccountConfig.endpoint,
      { params },
    );
  },
  getDeskOnboardingAccountConfigRelationships(uuid: string) {
    return client.get<GetApiAdminDesksOnboardingAccountConfigByUuidRelationshipsResponse>(
      onboardingEndpoints.admin.desks.onboardingAccountConfig.byUuidRelationships(
        uuid,
      ),
    );
  },
  removeDeskOnboardingAccountConfigRelationship(
    uuid: string,
    relationshipUuid: string,
  ) {
    return client.delete<GetApiAdminDesksOnboardingAccountConfigByUuidRelationshipsResponse>(
      onboardingEndpoints.admin.desks.onboardingAccountConfig.byUuidRelationshipsByRelationshipUuid(
        uuid,
        relationshipUuid,
      ),
    );
  },
  registerDeskOnboardingAccountConfigRelationships(
    body: PostApiAdminDesksOnboardingAccountConfigRelationshipsBody,
  ) {
    return client.post(
      onboardingEndpoints.admin.desks.onboardingAccountConfig.relationships,
      body,
    );
  },
  updateDeskOnboardingAccountConfigRelationships(
    body: PutApiAdminDesksOnboardingAccountConfigRelationshipsBody,
  ) {
    return client.put(
      onboardingEndpoints.admin.desks.onboardingAccountConfig.relationships,
      body,
    );
  },
  getDeskOnboardingAccountConfigEnums() {
    return client.get<GetApiAdminDesksOnboardingAccountConfigEnumsResponse>(
      onboardingEndpoints.admin.desks.onboardingAccountConfig.enums,
    );
  },
  getDeskOnboardingAccountConfigDetails(uuid: string) {
    return client.get<GetApiAdminDesksOnboardingAccountConfigByUuidResponse>(
      onboardingEndpoints.admin.desks.onboardingAccountConfig.byUuid(uuid),
    );
  },
  registerDeskOnboardingAccountConfig(
    body: PostApiAdminDesksOnboardingAccountConfigRegisterBody,
  ) {
    return client.post(
      onboardingEndpoints.admin.desks.onboardingAccountConfig.register,
      body,
    );
  },
  updateDeskOnboardingAccountConfig(
    body: PatchApiAdminDesksOnboardingAccountConfigUpdateBody,
  ) {
    return client.patch(
      onboardingEndpoints.admin.desks.onboardingAccountConfig.update,
      body,
    );
  },
  removeDeskOnboardingAccountConfig(uuid: string) {
    return client.delete(
      onboardingEndpoints.admin.desks.onboardingAccountConfig.byUuid(uuid),
    );
  },
  getDeskOnboardingPartnerConfig(
    params?: GettApiAdminDesksOnboardingPartnerConfigParams,
  ) {
    return client.get<GetApiAdminDesksOnboardingPartnerConfigResponse>(
      onboardingEndpoints.admin.desks.onboardingPartnerConfig.endpoint,
      { params },
    );
  },
  getDeskOnboardingPartnerConfigDetails(uuid: string) {
    return client.get<GetApiAdminDesksOnboardingPartnerConfigByUuidResponse>(
      onboardingEndpoints.admin.desks.onboardingPartnerConfig.byUuid(uuid),
    );
  },
  getDeskOnboardingPartnerConfigEnums() {
    return client.get<GetApiAdminDesksOnboardingPartnerConfigEnumsResponse>(
      onboardingEndpoints.admin.desks.onboardingPartnerConfig.enums,
    );
  },
  registerDeskOnboardingPartnerConfig(
    body: PostApiAdminDesksOnboardingPartnerConfigBody,
  ) {
    return client.post(
      onboardingEndpoints.admin.desks.onboardingPartnerConfig.endpoint,
      body,
    );
  },
  updateDeskOnboardingPartnerConfig(
    body: PutApiAdminDesksOnboardingPartnerConfigBody,
  ) {
    return client.put(
      onboardingEndpoints.admin.desks.onboardingPartnerConfig.endpoint,
      body,
    );
  },
  getDeskNotifications(params?: GettApiAdminDesksNotificationsParams) {
    return client.get<GetApiAdminDesksNotificationResponse>(
      onboardingEndpoints.admin.desks.notification.endpoint,
      { params },
    );
  },
  registerDeskNotification(body: PostApiAdminDesksNotificationRegisterBody) {
    return client.post(
      onboardingEndpoints.admin.desks.notification.register,
      body,
    );
  },
  updateDeskNotification(body: PostApiAdminDesksNotificationUpdateBody) {
    return client.post(
      onboardingEndpoints.admin.desks.notification.update,
      body,
    );
  },
  removeDeskNotification(uuid: string) {
    return client.delete(
      onboardingEndpoints.admin.desks.notification.byUuid(uuid),
    );
  },
  registerDeskNotificationVariables(
    body: PostApiAdminDesksNotificationRegisterVariablesBody,
  ) {
    return client.post(
      onboardingEndpoints.admin.desks.notification.registerVariables,
      body,
    );
  },
  removeDeskNotificationVariables(uuid: string) {
    return client.delete(
      onboardingEndpoints.admin.desks.notification.removeVariablesByUuid(uuid),
    );
  },
  getContractFileConfiguration() {
    return client.get<GetApiAdminContractFileConfigurationResponse>(
      onboardingEndpoints.admin.contractFileConfiguration.endpoint,
    );
  },
  getContractFileConfigurationEnums() {
    return client.get<GetApiAdminContractFileConfigurationEnumsResponse>(
      onboardingEndpoints.admin.contractFileConfiguration.enums,
    );
  },
  getContractFileConfigurationDetails(id: string) {
    return client.get<GetApiAdminContractFileConfigurationByIdResponse>(
      onboardingEndpoints.admin.contractFileConfiguration.byId(id),
    );
  },
  registerContractFileConfiguration(
    body: PostApiAdminContractFileConfigurationRegisterBody,
  ) {
    return client.post(
      onboardingEndpoints.admin.contractFileConfiguration.register,
      body,
    );
  },
  updateContractFileConfiguration(
    id: string,
    body: PutApiAdminContractFileConfigurationUpdateByIdBody,
  ) {
    return client.put(
      onboardingEndpoints.admin.contractFileConfiguration.updateById(id),
      body,
    );
  },
  getDefaultContractFileConfiguration() {
    return client.get<GetApiAdminDefaultContractFileConfigurationResponse>(
      onboardingEndpoints.admin.defaultContractFileConfiguration.endpoint,
    );
  },
  getDefaultContractFileConfigurationEnums() {
    return client.get<GetApiAdminDefaultContractFileConfigurationEnumsResponse>(
      onboardingEndpoints.admin.defaultContractFileConfiguration.enums,
    );
  },
  getDefaultContractFileConfigurationDetails(id: string) {
    return client.get<GetApiAdminDefaultContractFileConfigurationByIdResponse>(
      onboardingEndpoints.admin.defaultContractFileConfiguration.byId(id),
    );
  },
  registerDefaultContractFileConfiguration(
    body: PostApiAdminDefaultContractFileConfigurationRegisterBody,
  ) {
    return client.post(
      onboardingEndpoints.admin.defaultContractFileConfiguration.register,
      body,
    );
  },
  updateDefaultContractFileConfiguration(
    id: string,
    body: PutApiAdminDefaultContractFileConfigurationUpdateByIdBody,
  ) {
    return client.put(
      onboardingEndpoints.admin.defaultContractFileConfiguration.updateById(id),
      body,
    );
  },
  getDeskOnboardingConfigOfficers(
    params?: GetApiAdminDesksOnboardingConfigOfficerParams,
  ) {
    return client.get<GetApiAdminDesksOnboardingConfigOfficerResponse>(
      onboardingEndpoints.admin.desks.onboardingConfigOfficer.endpoint,
      { params },
    );
  },
  upsertOfficersOnDeskOnboardingConfig(
    body: PatchApiAdminDesksOnboardingConfigOfficerOfficersBody,
  ) {
    return client.patch(
      onboardingEndpoints.admin.desks.onboardingConfigOfficer.officers,
      body,
    );
  },
  registerOfficersOnDeskOnboardingConfig(
    body: PostApiAdminDesksOnboardingConfigOfficerBody,
  ) {
    return client.post(
      onboardingEndpoints.admin.desks.onboardingConfigOfficer.endpoint,
      body,
    );
  },
  updateOfficersOnDeskOnboardingConfig(
    body: PutApiAdminDesksOnboardingConfigOfficerBody,
  ) {
    return client.put(
      onboardingEndpoints.admin.desks.onboardingConfigOfficer.endpoint,
      body,
    );
  },
  removeOfficersOnDeskOnboardingConfig(uuid: string) {
    return client.delete(
      onboardingEndpoints.admin.desks.onboardingConfigOfficer.byUuid(uuid),
    );
  },
  getDeskOnboardingConfigPipeline(
    params?: GetApiAdminDesksOnboardingConfigPipelineParams,
  ) {
    return client.get<GetApiAdminDesksOnboardingConfigPipelineResponse>(
      onboardingEndpoints.admin.desks.configPipeline.endpoint,
      { params },
    );
  },
  getDeskOnboardingConfigPipelineEnums() {
    return client.get<GetApiAdminDesksOnboardingConfigPipelineEnumsResponse>(
      onboardingEndpoints.admin.desks.configPipeline.enums,
    );
  },
  registerPipelineOnDeskOnboardingConfig(
    body: PostApiAdminDesksOnboardingConfigPipelineBody,
  ) {
    return client.post(
      onboardingEndpoints.admin.desks.configPipeline.endpoint,
      body,
    );
  },
  updatePipelineOnDeskOnboardingConfig(
    body: PutApiAdminDesksOnboardingConfigPipelineBody,
  ) {
    return client.put(
      onboardingEndpoints.admin.desks.configPipeline.endpoint,
      body,
    );
  },
  removePipelineOnDeskOnboardingConfig(uuid: string) {
    return client.delete(
      onboardingEndpoints.admin.desks.configPipeline.byUuid(uuid),
    );
  },
  getOfficers() {
    return client.get<GetApiAdminOfficersResponse>(
      onboardingEndpoints.admin.officers,
    );
  },
  getOfficerDetails(id: string | number) {
    return client.get<GetApiAdminOfficersByIdResponse>(
      onboardingEndpoints.admin.officersById(id),
    );
  },
  registerOfficer(body: PostAdminOfficersBody) {
    return client.post(onboardingEndpoints.admin.officers, body);
  },
  updateOfficer(body: PutAdminOfficersBody) {
    return client.put(onboardingEndpoints.admin.officers, body);
  },
  getSemaphore(body: PostApiAdminGetSemaphoreBody) {
    return client.post<PostApiAdminGetSemaphoreResponse>(
      onboardingEndpoints.admin.getSemaphore,
      body,
    );
  },
  getSemaphoreFirstAccess(
    body: PostApiOnboardingLevelsGetSemaphoreFirstAccessBody,
  ) {
    return client.post<PostApiOnboardingLevelsGetSemaphoreFirstAccessResponse>(
      onboardingEndpoints.onboardingLevels.getSemaphoreFirstAccess,
      body,
    );
  },
  registerLegalEntity(
    body: PostApiAdminOnboardingLevelsRegisterLegalEntityBody,
  ) {
    return client.post<PostApiAdminOnboardingLevelsRegisterLegalEntityResponse>(
      onboardingEndpoints.onboardingLevels.registerLegalEntity,
      body,
    );
  },
  openAccount(body: PostApiCustomerInviteOpenAccountBody) {
    return client.post(onboardingEndpoints.customerInvite.openAccount, body);
  },
  registerEntityFromDesk(
    body: PostApiAdminDesksOnboardingRegisterEntityFromDeskBody,
  ) {
    return client.post<PostApiAdminDesksOnboardingRegisterEntityFromDeskResponse>(
      onboardingEndpoints.admin.desks.registerEntityFromDesk,
      body,
    );
  },
  onboardStatus(body: PutApiAdminOnboardStatusBody) {
    return client.put<PutApiAdminOnboardStatusResponse>(
      onboardingEndpoints.admin.onboard.status,
      body,
    );
  },
  sendEnergyInvite(body: PostApiCustomerInviteEnergyBody) {
    return client.post(onboardingEndpoints.customerInvite.energy, body);
  },
  uploadFiles(body: FormData) {
    return client.post(onboardingEndpoints.contingency.files.upload, body);
  },
  resendOnboardingLess(body: PostContingencyOnboardingResendToLessBody) {
    return client.post<PostContingencyOnboardingResendToLessResponse>(
      onboardingEndpoints.contingency.onboarding.resendToLess,
      body,
    );
  },
  resendOnboardingLessAllSegments(
    body: PostContingencyOnboardingResendToLessBody,
  ) {
    return client.post<PostContingencyOnboardingResendToLessResponse>(
      onboardingEndpoints.contingency.onboarding.resendToLessAllSegments,
      body,
    );
  },
  reopenOnboarding(body: PostContingencyOnboardingReopenBody) {
    return client.post<PostContingencyOnboardingReopenResponse>(
      onboardingEndpoints.contingency.onboarding.reopen,
      body,
    );
  },
  reopenOnboardingAllSegments(body: PostContingencyOnboardingReopenBody) {
    return client.post<PostContingencyOnboardingReopenResponse>(
      onboardingEndpoints.contingency.onboarding.reopenAllSegments,
      body,
    );
  },
  reopenDataReview(body: PostContingencyReopenDataReviewBody) {
    return client.post<PostContingencyReopenDataReviewResponse>(
      onboardingEndpoints.contingency.reopenDataReview,
      body,
    );
  },
  reopenDataReviewForce(body: PostContingencyReopenDataReviewForceBody) {
    return client.post(
      onboardingEndpoints.contingency.reopenDataReviewForce,
      body,
    );
  },
  resendDataReview(body: PostContingencyResendDataReviewToLessBody) {
    return client.post<PostContingencyReopenDataReviewResponse>(
      onboardingEndpoints.contingency.resendDataReviewToLess,
      body,
    );
  },
  updateDataLess(body: PutContingencyLegalEntityUpdateDataLessBody) {
    return client.put<PutContingencyLegalEntityUpdateDataLessResponse>(
      onboardingEndpoints.contingency.legalEntity.updateDataLess,
      body,
    );
  },
  approveOnboading(body: PutContingencyOnboadingApproveBody) {
    return client.put<PutContingencyOnboadingApproveResponse>(
      onboardingEndpoints.contingency.onboarding.approve,
      body,
    );
  },
  loadDocuments(body: PostContingencyDocumentsLoadBody) {
    return client.post<PostContingencyDocumentsLoadResponse>(
      onboardingEndpoints.contingency.documents.load,
      body,
    );
  },
  documentsOccurrences(body: PostContingencyDocumentsOccurrencesBody) {
    return client.post<PostContingencyDocumentsOccurrencesResponse>(
      onboardingEndpoints.contingency.documents.occurrences,
      body,
    );
  },
  downloadFile(key: string) {
    return client.get<GetApiAdminDownloadFileResponse>(
      onboardingEndpoints.admin.onboardingLevels.download.byKey(key),
      {
        responseType: "blob",
      },
    );
  },
  terminateRelationship(
    body: PostContingencyWorkflowTerminateRelationshipBody,
  ) {
    return client.post<PostContingencyWorkflowTerminateRelationshipResponse>(
      onboardingEndpoints.contingency.workflow.terminateRelationship,
      body,
    );
  },
  approveWorkflow(body: PutContingencyWorkflowApproveBody) {
    return client.put(onboardingEndpoints.contingency.workflow.approve, body);
  },
  updateWorkflow(body: PutContingencyWorkflowUpdateBody) {
    return client.put(onboardingEndpoints.contingency.workflow.update, body);
  },
  syncWorkflow(body: PostContingencyWorkflowSyncBody) {
    return client.post(onboardingEndpoints.contingency.workflow.sync, body);
  },
  getWorkflowLogs(uuid: string) {
    return client.get<GetContingencyWorkflowByUuidLogsResponse>(
      onboardingEndpoints.contingency.workflow.byUuidLogs(uuid),
    );
  },
  disableOnboarding(uuid: string) {
    return client.delete(onboardingEndpoints.contingency.workflow.byUuid(uuid));
  },
  createAccountOpeningContracts(
    body: PostApiCustomerServiceContractsCreateAccountOpeningBody,
  ) {
    return client.post<PostApiCustomerServiceContractsCreateAccountOpeningResponse>(
      onboardingEndpoints.customerService.contracts.createAccountOpening,
      body,
    );
  },
  getLegalEntityDetails(body: PostApiSearchLegalEntityDetailsBody) {
    return client.post<PostApiSearchLegalEntityDetailsResponse>(
      onboardingEndpoints.searchLegalEntity.details,
      body,
    );
  },
  getUserProfiles(body: PostApiAdminUserGetProfilesBody) {
    return client.post<PostApiAdminUserGetProfilesResponse>(
      onboardingEndpoints.admin.user.getProfiles,
      body,
    );
  },
  getCompaniesFromGroup(body: PostAdminCompanyGroupGetCompaniesFromGroupBody) {
    return client.post<PostAdminCompanyGroupGetCompaniesFromGroupResponse>(
      onboardingEndpoints.admin.companyGroup.getCompaniesFromGroup.endpoint,
      body,
    );
  },
  getCompaniesFromGroupByTaxId(taxId: string) {
    return client.get<GetApiAdminCompanyGroupGetCompaniesFromGroupByTaxIdResponse>(
      onboardingEndpoints.admin.companyGroup.getCompaniesFromGroup.byTaxId(
        taxId,
      ),
    );
  },
  getAdminCompany(body: PostApiAdminCompanyBody) {
    return client.post<PostApiAdminCompanyResponse>(
      onboardingEndpoints.admin.company.endpoint,
      body,
    );
  },
  registerGroupAndAssociations(
    body: PostApiAdminCompanyGroupRegisterGroupAndAssociationsBody,
  ) {
    return client.post<PostApiAdminCompanyGroupRegisterGroupAndAssociationsResponse>(
      onboardingEndpoints.admin.companyGroup.registerGroupAndAssociations,
      body,
    );
  },
  getGroupAssociations() {
    return client.get<GetGroupAssociationResponse>(
      onboardingEndpoints.admin.companyGroup.getGroupAssociations,
    );
  },
  reprocessContracts(body: PostAdminContractsBatchCreateBody) {
    return client.post<PostAdminContractsBatchCreateResponse>(
      onboardingEndpoints.admin.contracts.batch.create,
      body,
    );
  },
  createAdministrator(body: PostAdminUserCreateAdministratorBody) {
    return client.post<PostAdminUserCreateAdministratorResponse>(
      onboardingEndpoints.admin.user.createAdministrator,
      body,
    );
  },
  batchCreateContract(body: PostAdminContractsBatchCreateBody) {
    return client.post<PostAdminContractsBatchCreateResponse>(
      onboardingEndpoints.admin.contracts.batch.create,
      body,
    );
  },
  putSyncLessAccounts(body: PutContingencySyncAccountsBody) {
    return client.put(onboardingEndpoints.contingency.syncAccounts, body);
  },
  reopenOnboardingForce(body: PostContingencyOnboardingForceReopenBody) {
    return client.post<PostContingencyOnboardingForceReopenResponse>(
      onboardingEndpoints.contingency.onboarding.forceReopen,
      body,
    );
  },
  reopenOnboardingForceAllSegments(
    body: PostContingencyOnboardingForceReopenBody,
  ) {
    return client.post<PostContingencyOnboardingForceReopenResponse>(
      onboardingEndpoints.contingency.onboarding.forceReopenAllSegments,
      body,
    );
  },
  resetEntityRegistration(body: PutContingencyLegalEntityResetBody) {
    return client.put(onboardingEndpoints.contingency.legalEntity.reset, body);
  },
  sendSignedContractToLess(externalReference: string) {
    return client.post(
      onboardingEndpoints.admin.contracts.byExternalReference.sendContractSignedToLess(
        externalReference,
      ),
    );
  },
  getAddressByZipCode(body: PostApiAdminGetAddressByZipCodeBody) {
    return client.post<PostApiAdminGetAddressByZipCodeResponse>(
      onboardingEndpoints.address.byZipCode,
      body,
    );
  },
  getEntityWorkflows(body: PostWorkflowLevelsLoadEntityWorkflowsBody) {
    return client.post<PostWorkflowLevelsLoadEntityWorkflowsResponse>(
      onboardingEndpoints.workflowLevels.loadEntityWorkflows,
      body,
    );
  },
  getWorkflowEnums() {
    return client.get<GetWorkflowLevelsEnumsResponse>(
      onboardingEndpoints.workflowLevels.enums,
    );
  },
  getAccountsByWorkflow(uuid: string) {
    return client.get<GetWorkflowLevelsGetAccountsByWorkflowResponse>(
      onboardingEndpoints.workflowLevels.getAccountsByWorkflow.byUuid(uuid),
    );
  },
  getDataReviewWorkflows(body: PostWorkflowDataReviewLoadEntityWorkflowsBody) {
    return client.post<PostWorkflowDataReviewLoadEntityWorkflowsResponse>(
      onboardingEndpoints.workflowDataReview.loadEntityWorkflows,
      body,
    );
  },
  getDataReviewDetails(uuid: string) {
    return client.get<GettWorkflowDataReviewByUuidResponse>(
      onboardingEndpoints.workflowDataReview.byUuid(uuid),
    );
  },
  getDataReviewEnums() {
    return client.get<GetWorkflowDataReviewEnumsResponse>(
      onboardingEndpoints.workflowDataReview.enums,
    );
  },
  getDataUpdateWorkflows(body: PostWorkflowDataUpdateLoadEntityWorkflowsBody) {
    return client.post<PostWorkflowDataUpdateLoadEntityWorkflowsResponse>(
      onboardingEndpoints.workflowDataUpdate.loadEntityWorkflows,
      body,
    );
  },
  getDataUpdateDetails(uuid: string) {
    return client.get<GettWorkflowDataUpdateByUuidResponse>(
      onboardingEndpoints.workflowDataUpdate.byUuid(uuid),
    );
  },
  getDataUpdateEnums() {
    return client.get<GetWorkflowDataUpdateEnumsResponse>(
      onboardingEndpoints.workflowDataUpdate.enums,
    );
  },
  createDeskMigration(body: PostContingencyWorkflowMigrationCreateBody) {
    return client.post(
      onboardingEndpoints.contingency.workflowMigration.create,
      body,
    );
  },
  getDeskMigrations() {
    return client.get<GetContingencyWorkflowMigrationListResponse>(
      onboardingEndpoints.contingency.workflowMigration.list,
    );
  },
  getDeskMigration(migrationId: string) {
    return client.get<GetContingencyWorkflowMigrationByMigrationIdDetailsResponse>(
      onboardingEndpoints.contingency.workflowMigration.byMigrationId.details(
        migrationId,
      ),
    );
  },
  approveDeskMigration(body: PatchContingencyWorkflowMigrationApproveBody) {
    return client.patch(
      onboardingEndpoints.contingency.workflowMigration.approve,
      body,
    );
  },
  reproveDeskMigration(body: DeleteContingencyWorkflowMigrationReproveBody) {
    return client.delete(
      onboardingEndpoints.contingency.workflowMigration.reprove,
      { data: body },
    );
  },
  verifyBatches(body: PostApiAdmibOnboardBatchVerifyBody) {
    return client.post<PostApiAdmibOnboardBatchVerifyResponse>(
      onboardingEndpoints.admin.onboard.batch.verify,
      body,
    );
  },
  importBatches(body: PostApiAdminOnboardBatchBody) {
    return client.post(onboardingEndpoints.admin.onboard.batch.endpoint, body);
  },
  getOccurrencesWorkflow(body: PostApiAdminOnboardingLevelsOccurencesBody) {
    return client.post(
      onboardingEndpoints.admin.onboardingLevels.occurences,
      body,
    );
  },
  addDomainConfig(body: PostToolsCommonBody) {
    return client.post<PostToolsCommonResponse>(
      onboardingEndpoints.tools.common.endpoint,
      body,
    );
  },
  getAllDomainConfig() {
    return client.get<GetToolsCommonAllResponse>(
      onboardingEndpoints.tools.common.list,
    );
  },
  getConfigDomainByGroupKey(
    domainConfigGroup: string,
    domainConfigKey: string,
  ) {
    return client.get<GetToolsCommonByGroupKeyResponse>(
      onboardingEndpoints.tools.common.byDomainConfigGroup.byDomainConfigKey(
        domainConfigGroup,
        domainConfigKey,
      ),
    );
  },
  findSemaphoreTemplate(uuid: string) {
    return client.get<GetSemaphoreTemplateResponse>(
      onboardingEndpoints.admin.semaphoreConfiguration.template.findBy(uuid),
    );
  },
  getSemaphoreTemplateList() {
    return client.get<GetSemaphoreTemplateListResponse>(
      onboardingEndpoints.admin.semaphoreConfiguration.template.list,
    );
  },
  addSemaphoreTemplate(body: PostSemaphoreTemplateBody) {
    return client.post<GetSemaphoreTemplateResponse>(
      onboardingEndpoints.admin.semaphoreConfiguration.template.register,
      body,
    );
  },
  removeSemaphoreTemplate(uuid: string) {
    return client.delete(
      onboardingEndpoints.admin.semaphoreConfiguration.template.remove(uuid),
    );
  },
  addSemaphoreStepOnTemplate(body: SemaphoreTemplateStepRequest) {
    return client.post<GetSemaphoreTemplateResponse>(
      onboardingEndpoints.admin.semaphoreConfiguration.template.addStep,
      body,
    );
  },
  removeSemaphoreStepOnTemplate(body: DeleteSemaphoreTemplateStepRequest) {
    return client.delete<GetSemaphoreTemplateResponse>(
      onboardingEndpoints.admin.semaphoreConfiguration.template.removeStep,
      { data: body },
    );
  },
  updateStepOrderOnTemplate(body: SemaphoreTemplateStepRequest) {
    return client.patch<GetSemaphoreTemplateResponse>(
      onboardingEndpoints.admin.semaphoreConfiguration.template.updateStepOrder,
      body,
    );
  },
  updateStepOnTemplate(body: PatchSemaphoreTemplateUpdateStepRequest) {
    return client.patch(
      onboardingEndpoints.admin.semaphoreConfiguration.template.updateStep,
      body,
    );
  },
  findSemaphoreStep(uuid: string) {
    return client.get<GetSemaphoreStepResponse>(
      onboardingEndpoints.admin.semaphoreConfiguration.step.findBy(uuid),
    );
  },
  getSemaphoreStepList() {
    return client.get<GetSemaphoreStepListResponse>(
      onboardingEndpoints.admin.semaphoreConfiguration.step.list,
    );
  },
  addSemaphoreStep(body: RequestSemaphoreStep) {
    return client.post<GetSemaphoreStepResponse>(
      onboardingEndpoints.admin.semaphoreConfiguration.step.add,
      body,
    );
  },
  updateSemaphoreStep(uuid: string, body: RequestSemaphoreStep) {
    return client.put<GetSemaphoreStepResponse>(
      onboardingEndpoints.admin.semaphoreConfiguration.step.update(uuid),
      body,
    );
  },
  removeSemaphoreStep(uuid: string) {
    return client.delete(
      onboardingEndpoints.admin.semaphoreConfiguration.step.remove(uuid),
    );
  },
  getPipelineConfigurationList() {
    return client.get<GetPipelineConfigurationList>(
      onboardingEndpoints.admin.pipelineConfiguration.list,
    );
  },
  getSemaphoreEntityConsults(taxId: string) {
    return client.get<GetEntitySemaphoreList>(
      onboardingEndpoints.admin.entitySemaphore.list(taxId),
    );
  },
  findSemaphoreEntityConsult(uuid: string) {
    return client.get<GetEntitySemaphoreItem>(
      onboardingEndpoints.admin.entitySemaphore.find(uuid),
    );
  },
  clearBCheckCache(body: DeleteApiToolsCacheBody) {
    return client.delete(onboardingEndpoints.tools.cache, { data: body });
  },
  requestSemaphoreBatch(body: PostSemaphoreBatchRequest) {
    return client.post(onboardingEndpoints.admin.sendSemaphoreBatch, body);
  },
  updateDataReviewByUuid(
    uuid: string,
    body: PostContingencyUpdateDataReviewByUuidBody,
  ) {
    return client.post(
      onboardingEndpoints.contingency.updateDataReviewByUuid(uuid),
      body,
    );
  },
  syncDataReview(body: PostContingencySyncDataReviewBody) {
    return client.post(onboardingEndpoints.contingency.syncDataReview, body);
  },
  getDataReviewLogs(uuid: string) {
    return client.get<GetContingencyDataReviewByUuidLogsResponse>(
      onboardingEndpoints.contingency.dataReviewByUuidLogs(uuid),
    );
  },
  updateDataUpdateById(
    id: string | number,
    body: PostContingencyUpdateDataUpdateByIdBody,
  ) {
    return client.post(
      onboardingEndpoints.contingency.updateDataUpdateById(id),
      body,
    );
  },
  syncDataUpdate(body: PostContingencySyncDataUpdateBody) {
    return client.post(onboardingEndpoints.contingency.syncDataUpdate, body);
  },
  getDataUpdateLogs(uuid: string) {
    return client.get<GetContingencyDataUpdateByUuidLogsResponse>(
      onboardingEndpoints.contingency.dataUpdateByUuidLogs(uuid),
    );
  },
  getDataUpdateChanges(params: GetContingencyDataUpdateGetChangesParams) {
    return client.get(onboardingEndpoints.contingency.dataUpdateGetChanges, {
      params,
    });
  },
};
