import { CustomerSelectorItem } from "../customer-selector.types";

const ignoreNotAlphanumeric = /[^0-9A-Z]+/gi;
const ignoreNotNumeric = /\D+/gi;

/** Checks if an expression matches a filter, while ignoring certain characters */
const testFilter = (
  value: string | null,
  filter: string,
  charactersToIgnore: RegExp,
) => {
  if (!filter.replace(charactersToIgnore, "")) return false;

  const matches = value
    ?.replace(charactersToIgnore, "")
    .match(RegExp(filter.replace(charactersToIgnore, ""), "i"));

  return matches;
};

export const validateOption = (
  { officialName, identification }: CustomerSelectorItem,
  filter?: string,
) => {
  if (!filter) return true;

  return (
    testFilter(officialName, filter, ignoreNotAlphanumeric) ||
    testFilter(identification, filter, ignoreNotNumeric)
  );
};

/** Returns options that match a filter */
export const filterOptions = (
  options: CustomerSelectorItem[],
  filter?: string,
) => {
  if (!filter) return options;

  return options.filter((option) => validateOption(option, filter));
};
