import {
  OButton,
  OCol,
  OIcon,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useUser } from "contexts/user";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { loanProductOptions } from "../../../../components/utils/loan-products.utils";

export interface TradeFTSImportFormValues {
  tradeIds: string;
  partner?: string;
  product: string;
}

export const TradeFTSImportPage = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm<TradeFTSImportFormValues>();
  const { handleSubmit } = form;

  const { user } = useUser();

  const {
    callService: partnersCallService,
    hasError: partnersHasError,
    loading: partnersLoading,
    value: partnersValue,
  } = useServiceCall(service.hubLoan.getPartner);

  const importTrade = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          await service.hubLoan.importTrades({
            partner: values.partner ?? "",
            product: values.product,
            tradeIds: values.tradeIds,
            requestedBy: user.email ?? "",
          });

          OToastManager.success("Solicitação enviada com sucesso");
        } catch {
          OToastManager.danger("A solicitação falhou");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, user.email],
  );

  useEffect(() => {
    partnersCallService();
  }, [partnersCallService]);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Solicitar importação de Trade criado via FTS"
          description="A importação não é instantânea. Os trades solicitados serão importados todos os dias em um determinado horário via republicação do FTS..."
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={importTrade}>
          Importar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <OCol>
            <ORFieldInput
              tag="text"
              id="tradeIds"
              name="tradeIds"
              label="TradeIds"
            />
          </OCol>
          <OCol className="align-items-center">
            <ORFieldSelect
              id="partner"
              name="partner"
              label="Parceiro"
              key={partnersValue?.length}
            >
              {partnersValue?.map((partner) => (
                <OOption
                  key={partner.taxId || partner.id?.toString()}
                  value={partner.taxId || partner.id?.toString()}
                >
                  {partner.name}
                </OOption>
              ))}
            </ORFieldSelect>
            {partnersLoading && <OLoader />}
            {partnersHasError && (
              <OButton
                type="default"
                bordered={false}
                outline
                onClick={() => partnersCallService()}
              >
                <OIcon
                  category="fa"
                  icon="fa-repeat"
                  size="sm"
                  type="default"
                />
              </OButton>
            )}
          </OCol>
          <OCol>
            <ORFieldSelect id="product" name="product" label="Produto">
              {loanProductOptions.map((product) => (
                <OOption key={product.value} value={product.value}>
                  {product.text}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
