import { OToastManager } from "@maestro/react";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { useCallback, useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { service } from "services/service";
import { WorkflowScfFormFields } from "../../../workflow-configuration-scf.form";

interface LocalFormValuesType {
  input: string | undefined;
}

export const useDesksFields = () => {
  const [allDesks, setAllDesks] = useState<SelectSearchOption<number>[]>([]);

  const form = useFormContext<WorkflowScfFormFields>();
  const {
    formState: { errors },
    setValue,
    watch,
  } = form;
  const watchDesks = watch("desks");

  const mainDeskError = errors?.mainDesk;

  const localForm = useForm<LocalFormValuesType>();
  const { watch: localWatch, setValue: localSetValue } = localForm;
  const watchInput = localWatch("input");

  const getAllDesks = useCallback(async () => {
    try {
      const { data } = await service.clerk.getDesks();

      const newData = data.map((d) => {
        const segment = d.segmentName
          ? ` ${d.segmentName}/${d.cosegmentName}`
          : "";

        return {
          value: d.code,
          label: `${d.code}${segment} - ${d.name}`,
          onSelect: () => {
            const desks = watchDesks ?? [];

            setValue("desks", [...new Set([...desks, d.code])]);
          },
        };
      });

      setAllDesks(newData);
    } catch {
      OToastManager.danger("Não foi possível obter as mesas possíveis");
    }
  }, [setValue, watchDesks]);

  useEffect(() => {
    getAllDesks();
  }, [getAllDesks]);

  useEffect(() => {
    if (watchDesks) localSetValue("input", undefined);
  }, [localSetValue, watchDesks]);

  return {
    allDesks,
    form,
    localForm,
    mainDeskError,
    setValue,
    watchDesks,
    watchInput,
  };
};
