import { OCol, ORFieldInput, ORow, OToastManager } from "@maestro/react";
import { useCallback, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { service } from "services/service";
import { AddressesList } from "../../../addresses-list";
import { EmailsList } from "../../../emails-list";
import { PhonesList } from "../../../phones-list";
import { RepresentativeDocument } from "../representatives-document";
import { RepresentativesErrorsList } from "../representatives-errors-list";

interface RepresentativesFieldsProps {
  index: number;
  ocurrences?: ScfDevelopers.CedenteCompletoRejection;
  representativeKey: string;
}

export const RepresentativesFields = ({
  index,
  ocurrences,
  representativeKey,
}: RepresentativesFieldsProps) => {
  const [loading, setLoading] = useState(false);

  const form = useFormContext<ScfDevelopers.UpdateCedenteCompleto>();
  const { control, getValues } = form;

  const { append, fields, remove } = useFieldArray({
    control,
    name: `representatives.${index}.document.files`,
  });

  const handleAddFile = useCallback(
    async (file: File) => {
      const [name, extension] = file.name.split(".");

      if (!name || !extension) {
        return;
      }

      const formData = new FormData();
      formData.set("image", file);

      setLoading(true);

      try {
        const { data } = await service.scfDevelopers.fileUpload(formData);

        append({
          name,
          pageType: "COMPLETO",
          url: data,
          extension,
        });
      } catch {
        OToastManager.danger(
          "Erro ao adicionar arquivo. Tente novamente mais tarde",
        );
      } finally {
        setLoading(false);
      }
    },
    [append],
  );

  const handleRemoveFile = (fileIndex: number) => {
    const file = getValues(
      `representatives.${index}.document.files.${fileIndex}`,
    );

    if (!file) {
      OToastManager.danger("Erro ao remover documento");
      return;
    }

    remove(fileIndex);
  };

  const removeAll = () => remove();

  return (
    <ORow key={representativeKey}>
      {ocurrences && (
        <OCol xs={12}>
          <RepresentativesErrorsList
            rejections={ocurrences}
            removeAll={removeAll}
            representativeIndex={index}
          />
        </OCol>
      )}
      <OCol xs={6}>
        <ORFieldInput
          tag="text"
          id={`representatives.${index}.taxId`}
          name={`representatives.${index}.taxId`}
          label="Tax Id do representante"
        />
      </OCol>
      <OCol xs={6}>
        <ORFieldInput
          tag="text"
          id={`representatives.${index}.name`}
          name={`representatives.${index}.name`}
          label="Nome do representante"
        />
      </OCol>
      <OCol xs={12} className="d-flex flex-column gap-2">
        <PhonesList fieldName={`representatives.${index}.phones`} />
        <AddressesList fieldName={`representatives.${index}.addresses`} />
        <EmailsList fieldName={`representatives.${index}.emails`} />
        <RepresentativeDocument
          fields={fields}
          handleAddFile={handleAddFile}
          handleRemoveFile={handleRemoveFile}
          index={index}
          loading={loading}
        />
      </OCol>
    </ORow>
  );
};
