import { CustomPagination } from "components/custom-pagination";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { GridTemplate } from "templates/grid-template";
import {
  cedenteCompletoErrorsGrid,
  dataSourceGenerator,
} from "./cedente-completo-errors.grid";

export const CedenteCompletoErrors = () => {
  const gridRef = useGridRef();

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(-1);

  const dataSource = useMemo(
    () => dataSourceGenerator(pageSize, pageNumber, setTotalCount),
    [pageNumber, pageSize],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Cedente Completo" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={cedenteCompletoErrorsGrid}
        dataSource={dataSource}
      />
      <CustomPagination
        totalCount={totalCount}
        currentPage={pageNumber}
        setCurrentPage={setPageNumber}
        pagination={[5, 10, 20]}
        selectedPaginationSize={pageSize}
        setSelectedPaginationSize={setPageSize}
      />
    </GridTemplate>
  );
};
