import { ONotification, OTypography } from "@maestro/react";
import { useErrorsList } from "./errors-list.hook";
import { ErrorsListProps } from "./errors-list.types";

export const ErrorsList = ({
  rejections,
  setDocumentsWithError,
}: ErrorsListProps) => {
  const { ocurrences } = useErrorsList(setDocumentsWithError, rejections);

  if (ocurrences.length === 0) {
    return <>{null}</>;
  }

  return (
    <ONotification type="danger">
      <div className="d-flex flex-column gap-3">
        <OTypography>Erros do Representante:</OTypography>
        {ocurrences.map((ocurrence, index) => (
          <div key={ocurrence} className="w-100">
            <OTypography>{`${index + 1}) ${ocurrence}`}</OTypography>
          </div>
        ))}
      </div>
    </ONotification>
  );
};
