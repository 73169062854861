import { masks } from "@maestro/utils";
import dayjs from "dayjs";

export const loadNotificationData = (
  communication?: BankingHubAdminCollection.CommunicationData,
  showFullDate?: boolean,
) => {
  if (!communication) return "";
  const {
    notification: { email, sms },
    sentAt,
  } = communication;

  const date = dayjs(sentAt).format(
    showFullDate ? "DD/MM/YYYY, HH:mm:ss" : "DD/MM/YYYY",
  );

  const values: string[] = [];

  if (email) {
    const newValues = Array.isArray(email) ? email : [email];
    values.push(...newValues);
  }

  if (sms) {
    const newValues = Array.isArray(sms) ? sms : [sms];
    values.push(...newValues.map((value) => masks.phone(value)));
  }

  return `[${date}] ${values.join(" | ")}`;
};
