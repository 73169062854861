import { yupResolver } from "@hookform/resolvers/yup";
import {
  modalManager,
  OButton,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { useBatchFileTemplateColumns } from "../../../../../../../../components/hooks/use-workflow-batch-template.context";
import {
  addColumnFormDefaultValues,
  addColumnFormValidationSchema,
} from "./add-column-modal-form.schemas";

export const ADD_COLUMN_MODAL_ID = "add-column-modal";

interface TemplateDetailsProps {
  getBatchFileTemplatesDetails: (id: string | number) => void;
}

export const AddColumnModal = ({
  getBatchFileTemplatesDetails,
}: TemplateDetailsProps) => {
  const { id, batchConfigurableColumns } = useBatchFileTemplateColumns();

  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: yupResolver(addColumnFormValidationSchema),
    defaultValues: addColumnFormDefaultValues,
  });
  const { handleSubmit, reset } = form;

  const batchConfigurableColumnsOptions =
    batchConfigurableColumns?.map((option) => ({
      value: option.id,
      label: option.name,
    })) ?? [];

  const { hide } = modalManager;

  const addColumn = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);
          await service.hubCreditManager.addBatchFileTemplateColumns({
            batchFileTemplateId: Number(id),
            configurableBatchFileColumnId: Number(values.column),
          });
          reset();
          hide(ADD_COLUMN_MODAL_ID);

          getBatchFileTemplatesDetails(id);

          OToastManager.success("Coluna salva com sucesso.");
        } catch {
          OToastManager.danger(
            "Não foi possível salvar coluna. Por favor, tente novamente mais tarde.",
          );
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, id, hide, reset, getBatchFileTemplatesDetails],
  );

  return (
    <OModal id={ADD_COLUMN_MODAL_ID} position="center" size="md">
      <OModalHeader closeButton>
        <OTypography tag="h1" size="xxl">
          Adicionar coluna
        </OTypography>
      </OModalHeader>
      <OModalBody>
        {loading && <OLoader size="xl" absolute backdrop />}
        <FormProvider {...form}>
          <div className="d-flex flex-column gap-2">
            <SelectSearchField
              id="column"
              name="column"
              label="Coluna"
              labelSize="lg"
              options={batchConfigurableColumnsOptions}
            />
          </div>
        </FormProvider>
      </OModalBody>

      <OModalFooter>
        <div className="d-flex gap-2 justify-content-end">
          <OButton
            outline
            onClick={() => {
              hide(ADD_COLUMN_MODAL_ID);
            }}
          >
            Fechar
          </OButton>
          <OButton
            onClick={() => {
              addColumn();
            }}
          >
            Adicionar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
