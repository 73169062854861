import {
  OButton,
  OCard,
  OCardBody,
  OSkeletonCard,
  OTypography,
} from "@maestro/react";
import { useRoles } from "hooks/roles";
import { useMemo } from "react";
import { roles } from "roles/roles";
import { useCollectionBeneficiaryContext } from "../../collection-beneficiary.context";
import { CancelFloatingModal } from "./_compose/cancel-floating-modal";
import { FloatingSettingsModal } from "./_compose/floating-settings-modal";
import { assembleFloatingActions } from "./floating-details.utils";

export const FloatingDetails = () => {
  const { beneficiary, isLoading } = useCollectionBeneficiaryContext();
  const { hasRole } = useRoles();

  const showFloatingActions = useMemo(
    () => hasRole(roles.banking.customer.beneficiaryFloatingActions.role),
    [hasRole],
  );

  const actions = useMemo(
    () => assembleFloatingActions(beneficiary?.floatingInDays),
    [beneficiary?.floatingInDays],
  );

  return (
    <div>
      {showFloatingActions && (
        <>
          <FloatingSettingsModal />
          <CancelFloatingModal />
        </>
      )}

      <OCard>
        <OCardBody className="d-flex flex-column gap-3">
          <OTypography type="dark" size="lg">
            Floating
          </OTypography>

          <div className="d-flex flex-column gap-2 w-50">
            <OTypography type="dark">Quantidade em dias</OTypography>
            {isLoading ? (
              <OSkeletonCard height="1rem" width="4rem" rounded />
            ) : (
              <OTypography type="dark-80" key={beneficiary?.floatingInDays}>
                {beneficiary?.floatingInDays ?? "-"}
              </OTypography>
            )}
          </div>

          {showFloatingActions && !isLoading && (
            <div className="d-flex align-items-center gap-3">
              {actions.map(({ label, onClick }) => (
                <OButton key={label} onClick={onClick} type="default" outline>
                  {label}
                </OButton>
              ))}
            </div>
          )}
        </OCardBody>
      </OCard>
    </div>
  );
};
