export const integrationDocumentsMap: Record<
  ScfDevelopers.PayerDocumentType,
  string
> = {
  CONTRATO_SOCIAL: "Contrato social",
  ESTATUTO_SOCIAL: "Estatuto social",
  REQUERIMENTO_EMPRESARIO: "Requerimento empresário",
  PROCURACAO: "Procuração",
  ATA_ELEICAO_DIRETORIA: "Ata eleição da diretoria",
  QUADRO_SOCIETARIO: "Quadro societário",
  DOCUMENTO_FINANCEIRO: "Documento financeiro",
  CCMEI: "CCMEI",
  COMPROVANTE_DE_RESIDENCIA: "Comprovante de residência",
  INDEFINIDO: "Indefinido",
  IDENTIFICACAO: "Identificação",
  ATA_ELEICAO: "Ata eleição",
  FICHA_CADASTRAL: "Ficha cadastral",
  DECLARACAO_ITIOF: "Declaração ITIOF",
  DECLARACAO_ITIR: "Declaração ITIR",
  KNOW_YOUR_CUSTOMER: "Know your customer",
  BALANCO_DRE: "Balanço DRE",
  DRE_DEFIS: "DRE DEFIS",
  DECLARACAO_PREVISAO_FATURAMENTO: "Declaração previsão de faturamento",
  DECLARACAO_ANUAL_SIMPLES_NACIONAL: "Declaração anual simples nacional",
  CERTIFICADO_CONDICACAO_MEI: "Certificado condicação MEI",
  CERTIDAO_DE_CASAMENTO: "Certidão de casamento",
  BIOMETRIA: "Biometria",
};
