import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { RouterButton } from "components/router-button";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { WorkflowLeadCreditLine } from "services/hubcreditmanager/models/responses";
import { workflowRoles } from "../../../../../roles/workflow.roles";
import { WorkflowCreditLineMasterDetail } from "./workflow-credit-lines.master-detail";

export const buildDataSource = (id: string) =>
  dataSourceCustomStoreGenerator(() =>
    service.hubCreditManager.getWorkflowLeadCreditLines(id),
  );

export const workflowCreditLinesGrid = (id: string) =>
  ({
    datagrid: {
      noDataText: "Nenhuma linha de crédito encontrada",
    },
    columns: [
      {
        dataField: "id",
        caption: "Id",
      },
      {
        dataField: "creditLine.identification",
        caption: "Produto",
      },
      {
        dataField: "desiredAmount",
        caption: "Valor",
        cellRender: ({ data }) => masks.currency(data.desiredAmount, "R$"),
      },
      {
        dataField: "status",
        caption: "Status",
      },
      {
        caption: "Carência",
        cellRender: ({ data }) =>
          data.desiredRate?.quotes[0]?.gracePeriod ?? "-",
      },
      {
        caption: "Parcelas",
        cellRender: ({ data }) => data.desiredRate?.quotes[0]?.maxTerm ?? "-",
      },
      {
        caption: "Taxa de juros",
        cellRender: ({ data }) =>
          data.desiredRate?.quotes[0]?.monthlyValue
            ? masks.percentage(data.desiredRate?.quotes[0]?.monthlyValue)
            : "-",
      },
      {
        caption: "Garantias",
        cellRender: ({ data }) =>
          data.offeredCollaterals.length
            ? data.offeredCollaterals.map((c) => c.collateralType).join(" - ")
            : "-",
      },
      {
        caption: "Proposta aprovada",
        role: workflowRoles.product.leadsDetailsProposalsDetailsPage.role,
        cellRender: ({ data }) =>
          data.proposedCreditLine ? (
            <RouterButton
              href={corporateRouter.routes.workflow.product.leads.details.proposals.details.path(
                {
                  id,
                  proposedCreditLineId: data.proposedCreditLine.id,
                },
              )}
              outline
            >
              Visualizar
            </RouterButton>
          ) : (
            <>-</>
          ),
      },
    ],
    masterDetail: {
      enabled: true,
      component: ({ data }) => <WorkflowCreditLineMasterDetail data={data} />,
    },
  } satisfies ODataGridGeneratorConfig<WorkflowLeadCreditLine>);
