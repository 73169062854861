import { OTab } from "@maestro/react";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { MapperProductName } from "services/admin-bankinghub/cnab/models";
import { UpsertLayoutFormFields } from "../../upsert-layout.form-schema";
import { OTabHeaderStyled } from "./products-tab-header.styles";
import { AVAILABLE_OPTIONS } from "./products-tab-header.utils";

export const ProductsTabHeader = () => {
  const { setValue } = useFormContext<UpsertLayoutFormFields>();

  const handleSelected = useCallback(
    (index: number) => {
      const productName = AVAILABLE_OPTIONS[index];
      setValue("productName", productName);
    },
    [setValue],
  );

  return (
    <OTabHeaderStyled handleSelected={handleSelected}>
      {AVAILABLE_OPTIONS.map((productName) => (
        <OTab size="lg" key={productName}>
          {MapperProductName[productName]}
        </OTab>
      ))}

      <OTab disabled />
    </OTabHeaderStyled>
  );
};
