import { ODataGridGeneratorConfig } from "components/data-grid";
import { ProcessorDetails } from "services/hubcreditmanager/models/types/workflow/workflow.types";
import { PropertiesGridActions } from "./properties-grid-actions.component";

export const propertiesGrid = {
  datagrid: {
    noDataText: "Nenhuma propriedade configurada",
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
    },
    {
      dataField: "type",
      caption: "Tipo",
    },
    {
      dataField: "description",
      caption: "Descrição",
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "isRequired",
      caption: "É obrigatório",
    },
    {
      dataField: "sensitive",
      caption: "É sensível",
    },
    {
      dataField: "validateAsList",
      caption: "Validação por lista",
    },
    {
      dataField: "multiselectType",
      caption: "Tipo de seletor múltipo",
    },
    {
      cellRender: ({ data }) => data.allowedValues?.join(", "),
      caption: "Valores permitidos",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => <PropertiesGridActions data={data} />,
    },
  ],
} satisfies ODataGridGeneratorConfig<ProcessorDetails["properties"][number]>;
