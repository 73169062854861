import * as yup from "yup";

export interface CreateCustomerCertificateFormValues {
  taxId: string;
  file: File[];
  password: string;
}

export const createCustomerCertificateFormSchema = yup.object().shape({
  taxId: yup.string().required("Campo obrigatório"),
  password: yup.string().required("Campo obrigatório"),
  file: yup.array().min(1, "Campo obrigatório"),
});

export const createCustomerCertificateFormDefaultValues = {
  taxId: "",
  password: "",
  file: [],
};
