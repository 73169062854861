import { OLoader } from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { useMemo } from "react";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useCreditLineField } from "./credit-line-field.hook";

export const CreditLineField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorPropertyId, name, description } = processorConfigProperty;
  const { creditLines, loadingCreditLines } = useCreditLineField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const options = useMemo(
    () =>
      creditLines?.map(({ identification }) => {
        return {
          value: identification,
          label: identification,
        };
      }) ?? [],
    [creditLines],
  );

  const creditLineField = useMemo(
    () => (
      <div className="d-flex align-items-center">
        <SelectSearchField
          key={creditLines
            ?.map(({ identification }) => identification)
            .join("")}
          id={`properties.${name}_${processorPropertyId}`}
          name={`properties.${name}_${processorPropertyId}`}
          placeholder="Selecione"
          label={description}
          options={options}
        />
        {loadingCreditLines && <OLoader />}
      </div>
    ),
    [
      options,
      creditLines,
      name,
      processorPropertyId,
      description,
      loadingCreditLines,
    ],
  );

  return <>{creditLineField}</>;
};
