import { OButton, OTypography } from "@maestro/react";
import { useNavigate } from "react-router";
import { StyledButton } from "./footer.styles";
import { SimulationFooterProps } from "./footer.types";
import { useSimulation } from "../../simulation.context";

export const SimulationFooter = ({
  continueButtonDisabled,
  continueAction,
  continueText,
  handleAcceptOffer,
}: SimulationFooterProps) => {
  const { activeStep, setActiveStep, currentStepsMap } = useSimulation();
  const navigate = useNavigate();

  const showContinueButton =
    !!currentStepsMap?.length && currentStepsMap?.length > activeStep + 2;

  const handleContinue = async () => {
    if (continueAction) {
      await continueAction();
    }

    setActiveStep((prevStep: number) => prevStep + 1);
  };

  return (
    <div className="d-flex justify-content-between align-items-center mt-5">
      <StyledButton
        bordered={false}
        outline
        type="dark"
        onClick={() => navigate("/emprestimos/cliente/ofertas")}
      >
        <OTypography>Cancelar</OTypography>
      </StyledButton>
      <div className="d-flex gap-2">
        <OButton
          outline
          disabled={activeStep === 0}
          onClick={() => setActiveStep((prevStep: number) => prevStep - 1)}
        >
          <OTypography type="dark">Voltar</OTypography>
        </OButton>
        {showContinueButton ? (
          <OButton disabled={continueButtonDisabled} onClick={handleContinue}>
            Continuar
          </OButton>
        ) : (
          <OButton
            disabled={continueButtonDisabled}
            onClick={handleAcceptOffer}
          >
            {continueText}
          </OButton>
        )}
      </div>
    </div>
  );
};
