import {
  OCard,
  OCardBody,
  OCardFooter,
  OCol,
  ODivider,
  ORFieldRadioGroup,
  ORow,
  OSkeletonAvatar,
  OSkeletonLine,
} from "@maestro/react";
import { CardContainer } from "../../offers.styles";

export const OffersSkeleton = () => {
  return (
    <ORow>
      <ORFieldRadioGroup name="skeleton" id="skeleton" key="skeleton">
        <OCol className="gap-4">
          {[1, 2].map((item, _) => {
            return (
              <CardContainer key={item}>
                <OCard>
                  <OCardBody>
                    <div className="d-flex justify-content-between align-items-center w-100 gap-1 mb-2">
                      <div className="w-60 d-flex flex-column gap-2">
                        <div className="w-100">
                          <OSkeletonLine size="xs" />
                        </div>
                        <div className="w-100">
                          <OSkeletonLine size="xxs" />
                        </div>
                      </div>
                      <OSkeletonAvatar size="sm" />
                    </div>

                    <ODivider />
                    <div className="my-3">
                      <div>
                        <div className="w-100 d-flex justify-content-between py-2">
                          <div className="w-40">
                            <OSkeletonLine size="sm" />
                          </div>
                          <div className="w-25">
                            <OSkeletonLine size="sm" />
                          </div>
                        </div>
                        <ODivider position="horizontal" />
                      </div>
                      <div>
                        <div className="w-100 d-flex justify-content-between py-2">
                          <div className="w-40">
                            <OSkeletonLine size="sm" />
                          </div>
                          <div className="w-25">
                            <OSkeletonLine size="sm" />
                          </div>
                        </div>
                        <ODivider position="horizontal" />
                      </div>
                      <div>
                        <div className="w-100 d-flex justify-content-between py-2">
                          <div className="w-40">
                            <OSkeletonLine size="sm" />
                          </div>
                          <div className="w-25">
                            <OSkeletonLine size="sm" />
                          </div>
                        </div>
                        <ODivider position="horizontal" />
                      </div>
                      <div>
                        <div className="w-100 d-flex justify-content-between py-2">
                          <div className="w-40">
                            <OSkeletonLine size="sm" />
                          </div>
                          <div className="w-25">
                            <OSkeletonLine size="sm" />
                          </div>
                        </div>
                        <ODivider position="horizontal" />
                      </div>
                      <div>
                        <div className="w-100 d-flex justify-content-between py-2">
                          <div className="w-40">
                            <OSkeletonLine size="sm" />
                          </div>
                          <div className="w-25">
                            <OSkeletonLine size="sm" />
                          </div>
                        </div>
                        <ODivider position="horizontal" />
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2 justify-content-start">
                      <div className="w-30">
                        <OSkeletonLine size="xs" />
                      </div>
                      <div className="w-30">
                        <OSkeletonLine size="xxs" />
                      </div>
                    </div>
                  </OCardBody>
                  <OCardFooter>
                    <div className="w-60">
                      <OSkeletonLine />
                    </div>
                  </OCardFooter>
                </OCard>
              </CardContainer>
            );
          })}
        </OCol>
      </ORFieldRadioGroup>
    </ORow>
  );
};
