import { OLoader } from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { useSimulation } from "./simulation-form/simulation.context";
import { SimulationSteps } from "./simulation-form/compose/steps-timeline/steps-timeline.component";
import { RedirectSignatureModal } from "./simulation-form/compose/redirect-signature-modal/redirect-signature.component";

export const Simulation = () => {
  const { activeStep, form, loading, currentStepsMap } = useSimulation();

  return (
    <FormProvider {...form}>
      <RedirectSignatureModal />
      <div className="d-flex flex-column gap-4">
        {loading && <OLoader absolute backdrop />}
        <SimulationSteps />
        {currentStepsMap?.[activeStep]?.content}
      </div>
    </FormProvider>
  );
};
