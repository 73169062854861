import { OIcon } from "@maestro/react";
import { masks } from "@maestro/utils";
import dayjs from "dayjs";
import { DocumentCell } from "./_compose";

export const valueCellMap: Record<
  HubEnergy.EDiligenceStepPropertyMask,
  (props: { property: HubEnergy.DiligenceStepPropertyResponse }) => JSX.Element
> = {
  ADEQUACY_OPTION: ({ property }) => (
    <>
      {property.adequacyOption?.name} -{" "}
      {masks.cpfCnpj(property.adequacyOption?.entity.taxId)}
    </>
  ),
  BOOLEAN: ({ property }) =>
    property.value?.toLowerCase() === "true" ? (
      <OIcon
        category="fa"
        icon="fa-check-square"
        type="info"
        className="d-inline-block"
        size="lg"
      />
    ) : (
      <OIcon
        category="fa"
        icon="fa-times-square"
        type="danger"
        className="d-inline-block"
        size="lg"
      />
    ),
  DOCUMENT: ({ property }) => <DocumentCell property={property} />,
  DATE: ({ property }) =>
    property.value ? (
      <>{dayjs(property.value).format("DD/MM/YYYY")}</>
    ) : (
      <>{property.value}</>
    ),
  CNPJ: ({ property }) => <>{masks.cpfCnpj(property.value)}</>,
  CPF: ({ property }) => <>{masks.cpfCnpj(property.value)}</>,
  NUMBER: ({ property }) => <>{property.value}</>,
  TEXT: ({ property }) => <>{property.value}</>,
  PASSWORD: ({ property }) => <>{property.value}</>,
  CURRENCY: ({ property }) => (
    <>{masks.currency(property.value, undefined, ".")}</>
  ),
  PERCENTAGE: ({ property }) => <>{masks.percentage(property.value)}</>,
  PHONE: ({ property }) => <>{masks.phone(property.value)}</>,
  TEXTAREA: ({ property }) => (
    <span style={{ whiteSpace: "normal" }}>{property.value}</span>
  ),
};
