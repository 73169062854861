import { OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import dayjs from "dayjs";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { Agenda } from "./agendas.types";
import { XMLDownloadButton } from "./_compose";

export const dataSourceWithOperatedValue = (customer?: string) =>
  dataSourceCustomStoreGenerator<Agenda>(
    (loadOptions) =>
      service.recebiveisCartao
        .getAgendas(loadOptions as any)
        .then(({ data }) => data.data)
        .catch(() => {
          const errorMessage = "Erro ao buscar as agendas";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    {
      dataSourceOptions: {
        filter: customer
          ? ["company_tax_id", "contains", customer.slice(0, 8)]
          : ["company_tax_id", "contains", ""],
      },
    },
  );

export const dataSourceWithoutOperatedValue = (customer?: string) =>
  dataSourceCustomStoreGenerator<Agenda>(
    (loadOptions) =>
      service.recebiveisCartao
        .getAgendasWithoutOperatedValue(loadOptions as any)
        .then(({ data }) => data.data as Agenda[])
        .catch(() => {
          const errorMessage = "Erro ao buscar as agendas";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    {
      dataSourceOptions: {
        filter: customer
          ? ["company_tax_id", "contains", customer.slice(0, 8)]
          : ["company_tax_id", "contains", ""],
      },
    },
  );

export const buildAgendasGrid = (
  withOperatedValue: boolean,
): ODataGridGeneratorConfig<Agenda> => ({
  datagrid: {
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    remoteOperations: true,
    scrolling: {
      showScrollbar: "always",
      mode: "infinite",
      rowRenderingMode: "virtual",
    },
    dateSerializationFormat: "yyyy-MM-dd",
    height: 900,
  },
  columns: [
    {
      dataField: "agenda_date",
      dataType: "date",
      format: "shortDate",
      caption: "Data",
      filterValue: dayjs().startOf("day").toDate(),
      selectedFilterOperation: ">=",
      allowHeaderFiltering: false,
    },
    {
      dataField: "id",
      dataType: "number",
      caption: "Id",
      allowHeaderFiltering: false,
    },
    {
      dataField: "receivable_on_quick_fin",
      dataType: "boolean",
      caption: "Recebível no portal",
      allowHeaderFiltering: false,
    },
    {
      dataField: "company_name",
      dataType: "string",
      caption: "Empresa",
      allowHeaderFiltering: false,
    },
    {
      dataField: "company_tax_id",
      dataType: "string",
      caption: "CNPJ da empresa",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      allowHeaderFiltering: false,
    },
    {
      dataField: "bandeira",
      dataType: "string",
      caption: "Bandeira",
    },
    {
      dataField: "credenciadora_name",
      dataType: "string",
      caption: "Credenciadora",
    },
    {
      dataField: "credenciadora_tax_id",
      dataType: "string",
      caption: "CNPJ da credenciadora",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      allowHeaderFiltering: false,
    },
    {
      dataField: "maturity_date",
      dataType: "date",
      format: "shortDate",
      caption: "Vencimento",
      allowHeaderFiltering: false,
    },
    {
      dataField: "maturity_amount",
      dataType: "number", // casting to number
      caption: "Valor bruto",
      format: (value) => masks.currency(value, "R$", "."),
      allowHeaderFiltering: false,
    },
    {
      dataField: "free_maturity_amount",
      dataType: "number", // casting to number
      caption: "Valor livre",
      format: (value) => masks.currency(value, "R$", "."),
      allowHeaderFiltering: false,
    },
    {
      dataField: "maturity_amount_available",
      dataType: "number", // casting to number
      caption: "Valor disponível",
      // cell render to show default value on grid but not on export
      cellRender: ({ data }) =>
        data.maturity_amount_available
          ? masks.currency(data.maturity_amount_available, "R$", ".")
          : "Nenhum Recebível",
      allowHeaderFiltering: false,
    },
    {
      dataField: "disbursement_amount",
      dataType: "number", // casting to number
      caption: "Valor líquido",
      format: (value) => masks.currency(value, "R$", "."),
      allowHeaderFiltering: false,
    },
    {
      dataField: "operated_value_btg",
      dataType: "number", // casting to number
      caption: "Valor operado pelo BTG",
      format: (value) => masks.currency(value, "R$", "."),
      visible: withOperatedValue,
      allowHeaderFiltering: false,
    },
    {
      dataField: "registradora",
      dataType: "string",
      caption: "Registradora",
    },
    {
      dataField: "import_s3",
      dataType: "string",
      caption: "Import S3",
      cellRender: ({ data }) =>
        data.import_s3 ? <XMLDownloadButton s3Key={data.import_s3} /> : "-----",
      allowHeaderFiltering: false,
    },
    {
      dataField: "external_id",
      dataType: "string",
      caption: "Id externa",
      allowHeaderFiltering: false,
    },
  ],
});
