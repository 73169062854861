import { SelectSearchField } from "components/select-search";
import { useMemo } from "react";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useConditionPropertyField } from "./condition-property-field.hook";

export const ConditionPropertyField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorPropertyId, name, description } = processorConfigProperty;
  const { allConditions } = useConditionPropertyField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const options = [
    { conditionName: "", description: "Nenhum" },
    ...(allConditions ?? []),
  ]?.map((config) => {
    return {
      value: config.conditionName,
      label: config?.description,
    };
  });

  const formPropertyField = useMemo(() => {
    if (options)
      return (
        <SelectSearchField
          key={allConditions?.map((cond) => cond.conditionName).join("")}
          id={`properties.${name}_${processorPropertyId}`}
          name={`properties.${name}_${processorPropertyId}`}
          placeholder="Selecione"
          labelSize="md"
          label={description}
          options={options}
        />
      );
  }, [options, allConditions, name, processorPropertyId, description]);

  return <>{formPropertyField}</>;
};
