import { yupResolver } from "@hookform/resolvers/yup";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services/service";
import { getSearchParams } from "utils/search-params/get-search-params";
import { FerramentasProdutoOnboardingConfiguracaoDeWorkflowsScfByIntegrationType } from "../../../../../../routes/ferramentas.route-params";
import { scfDevelopersOnboardingStatusMap } from "../../../../../../utils/scf-configuration-maps";
import {
  WorkflowScfFormFields,
  workflowScfValidationSchema,
} from "../../workflow-configuration-scf.form";
import { buildWorkflowConfigurationsIntoForm } from "../../workflow-configuration-scf.utils";

interface EditWorkflowConfigurationScfParams {
  authClientId: string;
  configurationId: string;
}

export const useEditWorkflowConfigurationScf = () => {
  const { integrationType } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeWorkflowsScfByIntegrationType>();
  if (!integrationType) throw new Error("Tipo de configuração não existe");

  const {
    value: workflowConfiguration,
    callService: getWorkflowConfiguration,
    loading,
  } = useServiceCall(service.scfDevelopers.getWorkflowConfiguration);

  const form = useForm<WorkflowScfFormFields>({
    resolver: yupResolver(workflowScfValidationSchema),
  });
  const { reset } = form;

  const { authClientId, configurationId } =
    getSearchParams<EditWorkflowConfigurationScfParams>();

  if (!authClientId || !configurationId) {
    throw new Error("No url parameters");
  }

  useEffect(() => {
    getWorkflowConfiguration(authClientId, configurationId, integrationType);
  }, [
    getWorkflowConfiguration,
    authClientId,
    configurationId,
    integrationType,
  ]);

  useEffect(() => {
    if (workflowConfiguration) {
      const configurations = buildWorkflowConfigurationsIntoForm(
        workflowConfiguration.configurations,
      );
      const syncConfigurations = buildWorkflowConfigurationsIntoForm(
        workflowConfiguration.syncConfigurations,
      );

      reset({
        allowedReprocessStatuses:
          workflowConfiguration.allowedReprocessStatuses.length === 0
            ? Object.keys(scfDevelopersOnboardingStatusMap)
            : workflowConfiguration.allowedReprocessStatuses,
        authClientId: workflowConfiguration.authClientId,
        configurationId: workflowConfiguration.configurationId,
        configurationName: workflowConfiguration.configurationName,
        configurationDisplayName:
          workflowConfiguration.configurationDisplayName,
        configurations,
        desks: workflowConfiguration.desks ?? [],
        integrationType: workflowConfiguration.integrationType,
        mainDesk: workflowConfiguration.mainDesk,
        partnerId: parseInt(workflowConfiguration.partnerId ?? "0"),
        partnerName: workflowConfiguration.partnerName,
        isFidc: workflowConfiguration.isFidc ? ["true"] : [],
        requiredOnboardingDocuments:
          workflowConfiguration.requiredOnboardingDocuments,
        syncConfigurations,
        requestRequiredFields:
          workflowConfiguration.requestRequiredFields ?? [],
      });
    }
  }, [workflowConfiguration, reset]);

  return {
    form,
    loading,
  };
};
