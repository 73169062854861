import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { customer } from "contexts/customer";
import { service } from "services";
import { GetCompaniesResponse } from "services/recebiveis-cartao/models";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

type Company = GetCompaniesResponse["companies"][number];

export const dataSource = dataSourceCustomStoreGenerator<Company>(() =>
  service.recebiveisCartao
    .getCompanies()
    .then(({ data }) =>
      data.companies.filter(
        (c) =>
          c.tax_id.slice(0, 8) === customer.value?.identification.slice(0, 8),
      ),
    )
    .catch(() => {
      const errorMessage = "Erro ao buscar as empresas";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    }),
);

export const buildRelocateCompaniesGrid = (
  setSelectedCompanies: (selectedCompanies: Company[]) => void,
): ODataGridGeneratorConfig<Company> => ({
  datagrid: {
    noDataText: "Nenhuma empresa.",

    pager: {
      allowedPageSizes: [20, 40, 100, 500],
      showPageSizeSelector: true,
    },
    onSelectionChanged: (evt) => setSelectedCompanies(evt.selectedRowsData),
  },
  columns: [
    {
      dataField: "id",
      caption: "ID",
    },
    {
      dataField: "name",
      caption: "Nome do cedente",
    },
    {
      dataField: "tax_id",
      caption: "CNPJ do cedente",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
  ],
  selection: {
    mode: "multiple",
    showCheckBoxesMode: "always",
    selectAllMode: "allPages",
  },
});
