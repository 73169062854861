import { client } from "../../client";
import { adminBankinghubEndpoints } from "../admin-bankinghub.endpoints";
import {
  CreateAgreementRequest,
  CreateBeneficiaryRequest,
  CreateFloatingParams,
  EditCollectionFloatingParams,
  GetAgreementsResponse,
  GetBeneficiaryResponse,
  GetCommunicationHistoryResponse,
  UpdateAgreementRequest,
  UpdateFloatingParams,
} from "./models";

export const adminBankinghubCollectionService = {
  getAgreements() {
    return client.get<GetAgreementsResponse>(
      adminBankinghubEndpoints.collection.agreement.endpoint,
    );
  },

  createBeneficiary(params: CreateBeneficiaryRequest) {
    return client.post(adminBankinghubEndpoints.beneficiary.cip, params);
  },

  getBeneficiary() {
    return client.get<GetBeneficiaryResponse>(
      adminBankinghubEndpoints.beneficiary.endpoint,
    );
  },

  getCommunicationHistory(collectionId: string) {
    return client.get<GetCommunicationHistoryResponse>(
      adminBankinghubEndpoints.collection.communicationHistory(collectionId),
    );
  },

  createAgreement(params: CreateAgreementRequest) {
    return client.post(
      adminBankinghubEndpoints.collection.agreement.endpoint,
      params,
    );
  },

  deleteAgreement(agreementId: string) {
    return client.delete(
      adminBankinghubEndpoints.collection.agreement.byId(agreementId),
    );
  },

  updateAgreement(agreementId: string, params: UpdateAgreementRequest) {
    return client.put(
      adminBankinghubEndpoints.collection.agreement.byId(agreementId),
      params,
    );
  },

  editCollectionFloating(params: EditCollectionFloatingParams) {
    return client.put(adminBankinghubEndpoints.collection.floating, params);
  },

  createFloating(params: CreateFloatingParams) {
    return client.post(adminBankinghubEndpoints.beneficiary.floating, params);
  },

  updateFloating(params: UpdateFloatingParams) {
    return client.put(adminBankinghubEndpoints.beneficiary.floating, params);
  },

  cancelFloating() {
    return client.delete(adminBankinghubEndpoints.beneficiary.floating);
  },

  reprocessCollections() {
    return client.post(adminBankinghubEndpoints.collection.reprocess);
  },
};
