import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOptionMultiple,
  ORFieldInput,
  ORFieldInputDate,
  ORFieldSelectMultiple,
  OTypography,
  modalManager,
} from "@maestro/react";
import dayjs from "dayjs";
import { FormProvider } from "react-hook-form";
import { useSearchModal } from "./search-modal.hook";
import { CompanySearch } from "../../../_compose/company-search";

const brands = ["Visa", "MasterCard", "AmericanExpress", "Elo", "HiperCard"];

export const searchModalClientId = "search-modal-client";

export const SearchModal = () => {
  const { form, submit, clear } = useSearchModal();
  const { hide } = modalManager;

  const { watch } = form;

  const startDateWatch = watch("dataVencimentoInicial");

  return (
    <OModal id={searchModalClientId}>
      <OModalHeader>
        <OTypography size="lg">Parâmetros de busca</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <div className="d-flex flex-column gap-4">
            <CompanySearch
              mode="Sacado"
              label="Nome ou identificação dos sacados"
              name="sacados"
              placeholder="Selecione o(s) sacado(s)"
              multiple
            />
            <CompanySearch
              mode="Cedente"
              label="Nome ou identificação dos cedentes"
              name="cedentes"
              placeholder="Selecione o(s) cedente(s)"
              multiple
            />
            <ORFieldSelectMultiple
              id="bandeiras"
              name="bandeiras"
              label="Bandeiras de cartão"
            >
              {brands.map((brand) => (
                <OOptionMultiple key={brand} value={String(brand)}>
                  {brand}
                </OOptionMultiple>
              ))}
            </ORFieldSelectMultiple>
            <ORFieldInput
              id="valorDesembolsoDesejado"
              name="valorDesembolsoDesejado"
              tag="currency"
              symbol="R$"
              label="Valor de desembolso desejado"
              required
            />
            <ORFieldInputDate
              id="dataVencimentoInicial"
              name="dataVencimentoInicial"
              label="Data de vencimento inicial"
              disabledDate={(date) =>
                dayjs(date.toDate()).isBefore(dayjs().startOf("day"))
              }
            />
            <ORFieldInputDate
              id="dataVencimentoFinal"
              name="dataVencimentoFinal"
              label="Data de vencimento final"
              disabledDate={(date) =>
                startDateWatch
                  ? dayjs(date.toDate()).isBefore(
                      dayjs(startDateWatch.toDate()).startOf("day"),
                    )
                  : false
              }
            />
          </div>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-between">
          <OButton type="dark" outline onClick={() => clear()}>
            Limpar
          </OButton>
          <OButton
            onClick={() => {
              submit();
              hide(searchModalClientId);
            }}
          >
            Buscar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
