import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useCallback, useState } from "react";
import { service } from "services";
import { useCNABContext } from "../cnab.context";
import { UpsertLayoutFormFields } from "./upsert-layout.form-schema";
import { assembleUpsertLayoutPayload } from "./upsert-layout.utils";

export const useUpsertLayout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { products } = useCNABContext();
  const {
    customer: { identification },
  } = useCustomer();

  const upsertLayout = useCallback(
    async (formValues: UpsertLayoutFormFields) => {
      if (!products) return;

      try {
        setIsLoading(true);

        const payload = assembleUpsertLayoutPayload(
          products,
          formValues,
          identification,
        );

        await service.adminBankinghub.cnab.upsertProductLayout(payload);
        OToastManager.success("Modelo configurado com sucesso.");
      } catch (error) {
        OToastManager.danger("Ocorreu um erro para configurar o modelo.");
      } finally {
        setIsLoading(false);
      }
    },
    [identification, products],
  );

  return { upsertLayout, isLoading };
};
