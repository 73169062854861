import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { GridTemplate } from "templates/grid-template";
import { customer } from "contexts/customer";
import {
  buildAgendasGrid,
  dataSourceWithOperatedValue,
  dataSourceWithoutOperatedValue,
} from "./agendas.grid";
import { ConfigureColumnButton } from "./_compose";

export const AgendasClient = () => {
  const [withOperatedValue, setWithOperatedValue] = useState(false);
  const gridRef = useGridRef();

  const dataSource = withOperatedValue
    ? dataSourceWithOperatedValue
    : dataSourceWithoutOperatedValue;

  const grid = useMemo(
    () => buildAgendasGrid(withOperatedValue),
    [withOperatedValue],
  );

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Monitor de cartões"
          description="Podemos visualizar agendas do portal, agendas agrupadas, health check e resumo dos trades do dia."
        />
      }
      actions={
        <ConfigureColumnButton
          withOperatedValue={withOperatedValue}
          setWithOperatedValue={setWithOperatedValue}
        />
      }
      gridRef={gridRef}
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource(customer.value?.identification)}
      />
    </GridTemplate>
  );
};
