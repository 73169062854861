import * as yup from "yup";

export const schema = yup.object().shape({
  bookCr: yup.number().notRequired(),
  defaultUserNotificationType: yup.string().nullable(),
  description: yup.string().trim().required("Campo obrigatório"),
  detailsPageType: yup.string().required("Este campo é obrigatório"),
  finishPreviousBigNumberOnStartNewOne: yup.array().of(yup.string()),
  gridTitle: yup.string().nullable(),
  icon: yup.string().trim().required("Campo obrigatório"),
  identification: yup.string().trim().required("Campo obrigatório"),
  minimumCriteria: yup.array().of(yup.string()),
  name: yup.string().trim().required("Campo obrigatório"),
  nameId: yup.string().trim().required("Campo obrigatório"),
  uniqueNameId: yup.string().trim().required("Campo obrigatório"),
  tabDescription: yup.string().nullable(),
  tabTitle: yup.string().nullable(),
});

export const defaultValues = {
  bookCr: 0,
  defaultUserNotificationType: "",
  description: "",
  detailsPageType: "",
  finishPreviousBigNumberOnStartNewOne: [] as string[],
  gridTitle: "",
  icon: "",
  identification: "",
  minimumCriteria: [] as string[],
  workflowConfigIncompatibleIds: [] as string[],
  name: "",
  nameId: "",
  uniqueNameId: "",
  tabDescription: "",
  tabTitle: "",
};
