import { modalManager } from "@maestro/core";
import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { GridTemplate } from "templates/grid-template";
import { ShowConfigurationsModal } from "../../../../components/show-configurations-modal";
import {
  IntegrationTypeModal,
  integrationTypeModalId,
} from "./_compose/integration-type-modal";
import {
  dataSource,
  workflowConfigurationGrid,
} from "./workflow-configuration-scf.grid";

export const WorkflowConfigurationsScf = () => {
  const gridRef = useGridRef();

  const [configurations, setConfigurations] =
    useState<ScfDevelopers.WorkflowConfiguration[]>();

  const grid = useMemo(() => {
    return workflowConfigurationGrid(setConfigurations);
  }, []);

  return (
    <>
      <PageTitle title="Listagem de workflows de hubonboarding scf" />

      <GridTemplate
        actions={
          <OButton onClick={() => modalManager.show(integrationTypeModalId)}>
            Adicionar workflow
          </OButton>
        }
        gridRef={gridRef}
        showRefreshButton
        showExportButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={grid}
          dataSource={dataSource}
        />
      </GridTemplate>

      <ShowConfigurationsModal
        configurations={configurations}
        title="Configurações"
      />
      <IntegrationTypeModal />
    </>
  );
};
