import { EstrategiaFormLabels } from "../estrategia-form.types";
import { YesNoRadioField } from "./yes-no-radio-field.component";

export const LinkWithBankSlipsField = () => {
  return (
    <YesNoRadioField
      label={EstrategiaFormLabels.linkWithBankSlips}
      name="linkWithBankSlips"
      description="Quando habilitado, vinculará os recebíveis a boletos para liquidação."
    />
  );
};
