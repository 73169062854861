import { env } from "utils/environments";

export const hubCreditManagerEndpoints = {
  odata: {
    adminCustomerLimits: `${env.API_CREDIT_MANAGER}/odata/AdminCustomerLimits`,
    limitsAdmin: `${env.API_CREDIT_MANAGER}/odata/LimitsAdmin`,
    limitRequestsInactivesAdmin: `${env.API_CREDIT_MANAGER}/odata/LimitRequestsInactivesAdmin`,
    workflowLeadsAdmin: `${env.API_CREDIT_MANAGER}/odata/WorkflowLeadsAdmin`,
    workflowLeadsBatchRequestAdmin: `${env.API_CREDIT_MANAGER}/odata/WorkflowLeadsBatchRequestsAdmin`,
    workflowLeadsBatchRequestRowAdmin: (batchRequestId: number) =>
      `${env.API_CREDIT_MANAGER}/odata/WorkflowLeadsBatchRequestRowsAdmin(WorkflowLeadsBatchRequestId=${batchRequestId})`,
    batchFileTemplateAdmin: `${env.API_CREDIT_MANAGER}/odata/BatchFileTemplatesAdmin`,
    entityScoresAdmin: `${env.API_CREDIT_MANAGER}/odata/EntityScoresAdmin`,
    publishInvoicesRequestsAdmin: `${env.API_CREDIT_MANAGER}/odata/PublishInvoicesRequestsAdmin`,
    denyListAdmin: `${env.API_CREDIT_MANAGER}/odata/DenyListAdmin`,
    customerCertificateAdmin: `${env.API_CREDIT_MANAGER}/odata/CustomerCertificateAdmin`,
    erpConnectionsAdmin: `${env.API_CREDIT_MANAGER}/odata/ERPConnectionAdmin`,
  },
  adminCustomer: {
    limits: `${env.API_CREDIT_MANAGER}/api/admin/customer/limits`,
  },
  admin: {
    components: {
      endpoint: `${env.API_CREDIT_MANAGER}/api/admin/components`,
      byId: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/components/${id}`,
      property: {
        add: `${env.API_CREDIT_MANAGER}/api/admin/components/property/add`,
        edit: `${env.API_CREDIT_MANAGER}/api/admin/components/property/edit`,
        remove: `${env.API_CREDIT_MANAGER}/api/admin/components/property/remove`,
      },
      configProperty: {
        update: `${env.API_CREDIT_MANAGER}/api/admin/components/config-property/update`,
      },
    },
    customerOriginator: `${env.API_CREDIT_MANAGER}/api/admin/customer-originator`,
    unitConsumer: {
      byUnitId: (unitId: string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/unit-consumer/${unitId}`,
      endpoint: `${env.API_CREDIT_MANAGER}/api/admin/unit-consumer`,
    },
    originators: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/originators`,
      producingLeads: `${env.API_CREDIT_MANAGER}/api/admin/originators/producing-leads`,
    },
    form: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/form`,
      byId: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/form/${id}`,
      step: {
        updatePositions: `${env.API_CREDIT_MANAGER}/api/admin/form/step/update-positions`,
        add: `${env.API_CREDIT_MANAGER}/api/admin/form/step/add`,
        remove: `${env.API_CREDIT_MANAGER}/api/admin/form/step/remove`,
        api: `${env.API_CREDIT_MANAGER}/api/admin/form/step`,
        condition: {
          add: `${env.API_CREDIT_MANAGER}/api/admin/form/step/condition/add`,
          remove: `${env.API_CREDIT_MANAGER}/api/admin/form/step/condition/remove`,
        },
        card: {
          updatePositions: `${env.API_CREDIT_MANAGER}/api/admin/form/step/card/update-positions`,
          remove: `${env.API_CREDIT_MANAGER}/api/admin/form/step/card/remove`,
          add: `${env.API_CREDIT_MANAGER}/api/admin/form/step/card/add`,
          api: `${env.API_CREDIT_MANAGER}/api/admin/form/step/card`,
          component: {
            updatePositions: `${env.API_CREDIT_MANAGER}/api/admin/form/step/card/component/update-positions`,
            add: `${env.API_CREDIT_MANAGER}/api/admin/form/step/card/component/add`,
            remove: `${env.API_CREDIT_MANAGER}/api/admin/form/step/card/component/remove`,
            api: `${env.API_CREDIT_MANAGER}/api/admin/form/step/card/component`,
            condition: {
              remove: `${env.API_CREDIT_MANAGER}/api/admin/form/step/card/component/condition/remove`,
              add: `${env.API_CREDIT_MANAGER}/api/admin/form/step/card/component/condition/add`,
            },
          },
        },
      },
    },
    condition: {
      api: (orderParam?: string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/condition?orderParam=${
          orderParam ?? "Description"
        }`,
      create: `${env.API_CREDIT_MANAGER}/api/admin/condition/create`,
      update: `${env.API_CREDIT_MANAGER}/api/admin/condition/update`,
      execute: `${env.API_CREDIT_MANAGER}/api/admin/condition/execute`,
    },
    bigNumbers: `${env.API_CREDIT_MANAGER}/api/admin/big-numbers`,
    configurableStatus: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/configurable-status`,
    },
    grid: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/grid`,
      byId: (id: string) => `${env.API_CREDIT_MANAGER}/api/admin/grid/${id}`,
      updateColumn: `${env.API_CREDIT_MANAGER}/api/admin/grid/update-column`,
    },
    view: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/view`,
    },
    foreignCurrencyIndex: {
      create: `${env.API_CREDIT_MANAGER}/api/admin/foreign-currency-index/create`,
      update: `${env.API_CREDIT_MANAGER}/api/admin/foreign-currency-index/update`,
    },
    settlementInstruction: {
      update: `${env.API_CREDIT_MANAGER}/api/admin/settlement-instruction/update`,
    },
    limitRequests: {
      updateExpirationDate: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/update-expiration-date`,
      updateStatus: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/update-status`,
      updateCreatingLimitStatus: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/update-to-creating-limit-status`,
      getAllPending: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/get-all-pending`,
      updateQuotesIndexcode: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/update-quotes-indexcode`,
      getLimitRequest: {
        byId: {
          endpoint: (id: string) =>
            `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/get-limit-request/${id}`,
          limit: {
            customer: {
              legalEntity: {
                policies: (id: string) =>
                  `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/get-limit-request/${id}/limit/customer/legalEntity/policies`,
              },
            },
          },
        },
      },
      byId: {
        collaterals: (limitRequestId: number | string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/${limitRequestId}/collaterals`,
        financedAssets: (limitRequestId: number | string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/${limitRequestId}/financed-assets`,
        externalIdentifications: (limitRequestId: number | string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/${limitRequestId}/external-identifications`,
        financialConditions: (limitRequestId: number | string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/${limitRequestId}/financial-conditions`,
        disbursementInstructions: (limitRequestId: number | string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/${limitRequestId}/disbursement-instructions`,
        contractTemplates: (limitRequestId: number | string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/${limitRequestId}/contract-templates`,
        invoices: (limitRequestId: number | string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/${limitRequestId}/invoices`,
        additionalFees: (limitRequestId: number | string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/${limitRequestId}/additional-fees`,
        foreignCurrencyIndex: (limitRequestId: number | string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/${limitRequestId}/foreign-currency-index`,
        guarantors: (limitRequestId: number | string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/${limitRequestId}/guarantors`,
      },
      minifiedById: (limitRequestId: number | string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/minified/${limitRequestId}`,
      updateAgroSacks: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/update-agro-sacks`,
      updateAmount: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/update-amount`,
      removeGuarantor: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/remove-guarantor`,
      syncAgromatic: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/sync-agromatic`,
      getAllCprIds: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/cpr-ids`,
      linkToCpr: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/link-to-cpr`,
    },
    limits: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/limits`,
      getLimit: `${env.API_CREDIT_MANAGER}/api/admin/limits/get-limit`,
      getAllLimitsByTaxId: `${env.API_CREDIT_MANAGER}/api/admin/limits/get-all-limits-by-tax-id`,
      approval: `${env.API_CREDIT_MANAGER}/api/admin/limits/approval`,
      reevaluate: `${env.API_CREDIT_MANAGER}/api/admin/limits/reevaluate`,
      deactivate: `${env.API_CREDIT_MANAGER}/api/admin/limits/deactivate`,
      createCollateralDocument: `${env.API_CREDIT_MANAGER}/api/admin/limits/create-collateral-document`,
      deleteCollateralDocument: (id: string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/limits/collateral-document/${id}`,
    },
    file: {
      upload: `${env.API_CREDIT_MANAGER}/api/admin/file/upload`,
      download: `${env.API_CREDIT_MANAGER}/api/admin/file/download`,
    },
    transaction: {
      byId: {
        getAllByLimit: (transactionId: number | string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/transaction/${transactionId}/get-all-by-limit`,
      },
      create: `${env.API_CREDIT_MANAGER}/api/admin/transaction/create`,
      reverse: `${env.API_CREDIT_MANAGER}/api/admin/transaction/reverse`,
    },
    collateral: {
      byId: (collateralId: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/collateral/${collateralId}`,
      create: `${env.API_CREDIT_MANAGER}/api/admin/collateral/create`,
      update: `${env.API_CREDIT_MANAGER}/api/admin/collateral/update`,
    },
    property: {
      update: `${env.API_CREDIT_MANAGER}/api/admin/property/update`,
    },
    additionalFee: {
      create: `${env.API_CREDIT_MANAGER}/api/admin/additional-fee/create`,
      update: `${env.API_CREDIT_MANAGER}/api/admin/additional-fee/update`,
      delete: `${env.API_CREDIT_MANAGER}/api/admin/additional-fee/delete`,
    },
    contractTemplate: {
      create: `${env.API_CREDIT_MANAGER}/api/admin/contract-template/create`,
      update: `${env.API_CREDIT_MANAGER}/api/admin/contract-template/update`,
      delete: {
        byId: (contractTemplateId: number | string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/contract-template/delete/${contractTemplateId}`,
      },
    },
    invoice: `${env.API_CREDIT_MANAGER}/api/admin/invoice`,
    financedAsset: {
      byId: (financedAssetId: string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/financed-asset/${financedAssetId}`,
    },
    creditLine: {
      endpoint: `${env.API_CREDIT_MANAGER}/api/admin/credit-line`,
    },
    creditLineConfiguration: {
      endpoint: `${env.API_CREDIT_MANAGER}/api/admin/credit-line-configuration`,
      create: `${env.API_CREDIT_MANAGER}/api/admin/credit-line-configuration/create`,
      update: `${env.API_CREDIT_MANAGER}/api/admin/credit-line-configuration/update`,
      byId: (id: number | string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/credit-line-configuration/${id}`,
    },
    book: {
      endpoint: `${env.API_CREDIT_MANAGER}/api/admin/book`,
      create: `${env.API_CREDIT_MANAGER}/api/admin/book/create`,
      update: `${env.API_CREDIT_MANAGER}/api/admin/book/update`,
      delete: `${env.API_CREDIT_MANAGER}/api/admin/book/delete`,
    },
    strategy: {
      endpoint: `${env.API_CREDIT_MANAGER}/api/admin/strategy`,
      create: `${env.API_CREDIT_MANAGER}/api/admin/strategy/create`,
      update: `${env.API_CREDIT_MANAGER}/api/admin/strategy/update`,
      delete: `${env.API_CREDIT_MANAGER}/api/admin/strategy/delete`,
    },
    bookStrategy: {
      endpoint: `${env.API_CREDIT_MANAGER}/api/admin/book-strategy`,
      addStrategiesToBook: `${env.API_CREDIT_MANAGER}/api/admin/book-strategy/add-strategies-to-book`,
      delete: `${env.API_CREDIT_MANAGER}/api/admin/book-strategy/delete`,
    },
    banner: {
      get: `${env.API_CREDIT_MANAGER}/api/admin/banner`,
      byId: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/banner/${id}`,
      create: `${env.API_CREDIT_MANAGER}/api/admin/banner/create`,
      update: `${env.API_CREDIT_MANAGER}/api/admin/banner/update`,
      toggle: `${env.API_CREDIT_MANAGER}/api/admin/banner/toggle-active`,
    },
    bannerTemplates: {
      get: `${env.API_CREDIT_MANAGER}/api/admin/banner/template`,
      getById: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/banner/template/${id}`,
      upsert: `${env.API_CREDIT_MANAGER}/api/admin/banner/template/upsert`,
    },
    accessRules: `${env.API_CREDIT_MANAGER}/api/admin/access-rule`,
    minimumCriteria: {
      endpoint: `${env.API_CREDIT_MANAGER}/api/admin/minimum-criteria`,
      create: `${env.API_CREDIT_MANAGER}/api/admin/minimum-criteria/create`,
      update: `${env.API_CREDIT_MANAGER}/api/admin/minimum-criteria/update`,
      delete: `${env.API_CREDIT_MANAGER}/api/admin/minimum-criteria/delete`,
    },
    commonDomain: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/common-domain`,
      delete: `${env.API_CREDIT_MANAGER}/api/admin/common-domain/delete`,
    },
    configurableGridColumn: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/configurable-grid-column`,
      delete: `${env.API_CREDIT_MANAGER}/api/admin/configurable-grid-column/delete`,
    },
    customerCertificate: {
      create: `${env.API_CREDIT_MANAGER}/api/admin/customer-certificate`,
      delete: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/customer-certificate/${id}`,
    },
    erpConnection: {
      create: `${env.API_CREDIT_MANAGER}/api/admin/erp-connection`,
      delete: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/erp-connection/${id}`,
    },
    workflowConfig: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config`,
      addProcessorConfig: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/add-processor-config`,
      addCanvasItem: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/add-canvas-item`,
      updateCanvasItem: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/update-canvas-item`,
      updateCanvasItemPosition: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/update-canvas-item-position`,
      removeCanvasItem: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/remove-canvas-item`,
      edit: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/edit`,
      create: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/create`,
      exportCanvas: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/export-canvas`,
      importCanvas: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/import-canvas`,
      byId: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/${id}`,
      byIdHierarchy: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/${id}/hierarchy`,
      byIdCanvasData: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/${id}/canvas-data`,
      byIdAllowedChilWorkflowConfigs: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/${id}/allowed-child-workflow-configs`,
      byIdChildProcessorConfigs: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/${id}/child-processor-configs`,
      byIdChildWorkflowConfigs: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/${id}/child-workflow-configs`,
      creditLines: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/credit-lines`,
      detailsPageType: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/allowed-details-type`,
      updateBigNumbers: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/update-big-numbers`,
      updateConfigurableGridColumns: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/update-configurable-grid-columns`,
      offersById: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/${id}/offers`,
      viewsById: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/${id}/views`,
      createOffer: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/create-offer`,
      hierarchy: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config/hierarchy`,
    },
    workflowLeads: {
      addPendency: `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/add-pendency`,
      deletePendency: `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/delete-pendency`,
      byId: {
        api: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/${id}`,
        pendencies: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/${id}/pendencies`,
        summary: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/${id}/summary`,
        energyConsumerUnits: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/${id}/energy-consumer-units`,
        stateHistory: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/${id}/state-history`,
        processorsExecuting: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/${id}/processors-executing`,
        documents: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/${id}/documents`,
        processorContexts: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/${id}/processor-contexts`,
        userActions: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/${id}/user-actions`,
        proposalGroups: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/${id}/proposal-groups`,
        creditLines: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/${id}/credit-lines`,
        proposedCreditLine: {
          byId: (id: string | number, proposedCreditLineId: string | number) =>
            `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/${id}/proposed-credit-line/${proposedCreditLineId}`,
        },
      },
      approval: `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/approval`,
      republishLeadStatus: `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/republish-lead-status`,
      deleteWorkflowLead: `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/delete-leads`,
      republishLeadToCreditEngine: `${env.API_CREDIT_MANAGER}/api/admin/workflow-leads/republish-lead-credit-engine`,
    },
    workflowConfigView: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config-view`,
      byId: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/workflow-config-view/${id}`,
      updateDefaultGrid: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config-view/update-default-grid`,
      updateViewBigNumbers: `${env.API_CREDIT_MANAGER}/api/admin/workflow-config-view/update-view-big-numbers`,
    },
    viewBigNumber: {
      byId: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/view-big-number/${id}`,
      updateGrid: `${env.API_CREDIT_MANAGER}/api/admin/view-big-number/update-grid`,
    },
    workflowDashboard: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/workflow-dashboard`,
      data: `${env.API_CREDIT_MANAGER}/api/admin/workflow-dashboard/data`,
    },
    workflowLeadsBatchRequests: {
      byId: {
        api: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/workflow-lead-batch-request/${id}`,
        downloadFile: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/workflow-lead-batch-requests/${id}/download-file`,
        reprocess: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/workflow-lead-batch-request/${id}/reprocess`,
      },
      updateBatch: `${env.API_CREDIT_MANAGER}/api/admin/workflow-lead-batch-request/update-batch`,
    },
    batchFileTemplates: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/batch-file-templates`,
      downloadTemplateAdmin: `${env.API_CREDIT_MANAGER}/api/admin/batch-file-templates/download-template-admin`,
      byId: {
        api: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/batch-file-templates/${id}`,
        templateColumns: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/batch-file-templates/${id}/template-columns`,
      },
    },
    batchFileTemplateConditions: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/batch-file-template-conditions`,
      remove: `${env.API_CREDIT_MANAGER}/api/admin/batch-file-template-conditions/remove`,
    },
    batchFileTemplateColumns: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/batch-file-template-columns`,
      remove: `${env.API_CREDIT_MANAGER}/api/admin/batch-file-template-columns/remove`,
    },
    configurableBatchFileColumns: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/configurable-batch-file-columns`,
      byId: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/configurable-batch-file-columns/${id}`,
    },
    userActionRequest: {
      sendNotification: `${env.API_CREDIT_MANAGER}/api/admin/user-action-request/send-notification`,
    },
    processor: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/processor`,
      byId: {
        api: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/processor/${id}`,
        outputs: (id: string | number) =>
          `${env.API_CREDIT_MANAGER}/api/admin/processor/${id}/outputs`,
      },
      property: {
        add: `${env.API_CREDIT_MANAGER}/api/admin/processor/property/add`,
        edit: `${env.API_CREDIT_MANAGER}/api/admin/processor/property/edit`,
        remove: `${env.API_CREDIT_MANAGER}/api/admin/processor/property/remove`,
      },
      output: {
        add: `${env.API_CREDIT_MANAGER}/api/admin/processor/output/add`,
        edit: `${env.API_CREDIT_MANAGER}/api/admin/processor/output/edit`,
        remove: `${env.API_CREDIT_MANAGER}/api/admin/processor/output/remove`,
      },
    },
    rejectionReasons: `${env.API_CREDIT_MANAGER}/api/admin/rejection-reason`,
    processorConfig: {
      byId: (id: number | string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/processor-config/${id}`,
      basicDataById: (id: number | string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/processor-config/${id}/basic-data`,
      seetingsById: (id: number | string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/processor-config/${id}/settings`,
      userActionsById: (id: number | string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/processor-config/${id}/user-actions`,
      channelById: (id: number | string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/processor-config/${id}/channel`,
      propertiesById: (id: number | string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/processor-config/${id}/properties`,
      monitoringById: (id: number | string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/processor-config/${id}/monitoring`,
      basicData: `${env.API_CREDIT_MANAGER}/api/admin/processor-config/basic-data`,
      settings: `${env.API_CREDIT_MANAGER}/api/admin/processor-config/settings`,
      userActions: `${env.API_CREDIT_MANAGER}/api/admin/processor-config/user-actions`,
      channel: `${env.API_CREDIT_MANAGER}/api/admin/processor-config/channel`,
      byIds: `${env.API_CREDIT_MANAGER}/api/admin/processor-config/get-by/ids`,
      byWorkflowLeadId: (id: number | string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/processor-config/get-by-workflow-lead-id/${id}`,
      condition: {
        add: `${env.API_CREDIT_MANAGER}/api/admin/processor-config/condition/add`,
        remove: `${env.API_CREDIT_MANAGER}/api/admin/processor-config/condition/remove`,
      },
      relationship: {
        remove: `${env.API_CREDIT_MANAGER}/api/admin/processor-config/relationship/remove`,
        create: `${env.API_CREDIT_MANAGER}/api/admin/processor-config/relationship/create`,
      },
      property: {
        update: `${env.API_CREDIT_MANAGER}/api/admin/processor-config/property/update`,
      },
      byWorkflowConfigId: (id: number | string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/processor-config/get-by-workflow-config-id/${id}`,
    },
    processorConfigView: {
      outputConfigStatus: `${env.API_CREDIT_MANAGER}/api/admin/processor-config-view/output-config-status`,
      outputConfigBigNumbers: `${env.API_CREDIT_MANAGER}/api/admin/processor-config-view/output-config-big-numbers`,
    },
    processorContext: {
      updateStatus: `${env.API_CREDIT_MANAGER}/api/admin/processor-context/update-status`,
      add: `${env.API_CREDIT_MANAGER}/api/admin/processor-context/add`,
    },
    channel: `${env.API_CREDIT_MANAGER}/api/admin/channel`,
    energyWorkflow: {
      getSelectedProposal: `${env.API_CREDIT_MANAGER}/api/admin/energy-workflow/get-selected-proposal`,
    },
    offer: {
      byId: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/offer/${id}`,
      update: `${env.API_CREDIT_MANAGER}/api/admin/offer/update`,
      delete: `${env.API_CREDIT_MANAGER}/api/admin/offer/delete`,
    },
    exportRequest: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/workflow-lead-export-requests`,
      reject: `${env.API_CREDIT_MANAGER}/api/admin/workflow-lead-export-requests/reject`,
    },
    scoreModules: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/score-module`,
      byId: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/score-module/${id}`,
      updateOrders: `${env.API_CREDIT_MANAGER}/api/admin/score-module/update-orders`,
    },
    scoreBenefits: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/score-benefit`,
      byId: (id: string | number) =>
        `${env.API_CREDIT_MANAGER}/api/admin/score-benefit/${id}`,
      updateOrders: `${env.API_CREDIT_MANAGER}/api/admin/score-benefit/update-orders`,
    },
    scoreLevels: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/score-level`,
    },
    legalEntity: {
      api: `${env.API_CREDIT_MANAGER}/api/admin/legalEntity`,
      byTaxId: `${env.API_CREDIT_MANAGER}/api/admin/legalEntity/by-tax-id`,
      addToDenyList: `${env.API_CREDIT_MANAGER}/api/admin/legalEntity/add-to-deny-list`,
      removeFromDenyList: `${env.API_CREDIT_MANAGER}/api/admin/legalEntity/remove-from-deny-list`,
    },
  },
  guarantors: {
    createCreditLineGuarantor: `${env.API_CREDIT_MANAGER}/api/guarantors/create-credit-line-guarantor`,
  },
  rmAdmin: {
    originator: {
      api: `${env.API_CREDIT_MANAGER}/api/rm-admin/main-originator`,
    },
  },
  b2b: {
    originator: {
      updateMaxLimit: `${env.API_CREDIT_MANAGER}/api/b2b/originator/max-limit`,
    },
  },
};
