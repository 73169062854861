import { StepsMap } from "../../simulation.types";
import {
  ValuesAndConditions,
  Collaterals,
  Conditions,
  Guarantors,
  Offers,
} from "../steps-components";

export const stepsMap: StepsMap[] = [
  {
    title: "Valores e condições",
    content: <ValuesAndConditions />,
    index: 0,
  },
  {
    title: "Ofertas",
    content: <Offers />,
    index: 1,
  },
  {
    title: "Garantias",
    content: <Collaterals />,
    index: 2,
  },
  {
    title: "Avalistas",
    content: <Guarantors />,
    index: 3,
  },
  {
    title: "Condições",
    content: <Conditions />,
    index: 4,
  },
  {
    title: "Contrato",
    index: 5,
  },
];
