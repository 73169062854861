import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import {
  OCol,
  OField,
  OOption,
  ORFieldInput,
  ORFieldInputDate,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import { validators } from "@maestro/utils";
import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  creditTypeOptions,
  portfolioClassificationTypes,
  ratingTypes,
} from "../../../../../components/utils/loan-agreement.utils";
import { loanProductOptions } from "../../../../../components/utils/loan-products.utils";
import { limitCollateralsFormGrid } from "./limit-collaterals-form.grid";
import {
  LimitFormValues,
  limitFormValidationsSchema,
} from "./limit-form.schemas";
import { limitGuarantorsFormGrid } from "./limit-guarantors-form.grid";
import { limitInstallmentsFormGrid } from "./limit-installments-form.grid";

export const AddLimitPage = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm<LimitFormValues>({
    resolver: yupResolver(limitFormValidationsSchema),
    defaultValues: {
      customerTaxId: "",
    },
  });

  const {
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = form;

  const watchCustomerTaxId = watch("customerTaxId");

  const limitInstallmentsGrid = useMemo(
    () =>
      limitInstallmentsFormGrid((limitInstallments) => {
        setValue("rate.quotes", limitInstallments);
      }),
    [setValue],
  );
  const limitCollateralsGrid = useMemo(
    () =>
      limitCollateralsFormGrid((limitCollaterals) => {
        setValue("collaterals", limitCollaterals);
      }),
    [setValue],
  );
  const limitGuarantorsGrid = useMemo(
    () =>
      limitGuarantorsFormGrid((limitGuarantors) => {
        setValue("guarantors", limitGuarantors);
      }),
    [setValue],
  );

  const addPartner = useCallback(async (values: LimitFormValues) => {
    try {
      setLoading(true);

      await service.hubCreditManager.addLimit({
        ...values,
        originator: "PORTAL_ADMIN",
      });

      OToastManager.success("Limite solicitado com sucesso");
    } catch {
      OToastManager.danger("Houve um problema. Tente novamente.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (!validators.cnpj(watchCustomerTaxId)) return;

      try {
        const { data } = await service.clerk.admin.getCompanyDetails({
          taxId: watchCustomerTaxId,
        });

        setValue("name", data.officialName ?? "");
      } catch {
        setValue("name", "");
        setError("customerTaxId", { message: "Empresa não encontrada" });
      }
    })();
  }, [watchCustomerTaxId, setValue, setError]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar limite" />}
      actions={
        <LoadingButton loading={loading} onClick={handleSubmit(addPartner)}>
          Adicionar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol sm={6}>
              <ORFieldInput
                tag="cnpj"
                id="customerTaxId"
                name="customerTaxId"
                label="CNPJ"
              />
            </OCol>
            <OCol sm={6}>
              <ORFieldInput
                tag="text"
                id="name"
                name="name"
                label="Nome da empresa"
                disabled
              />
            </OCol>
            <OCol sm={6}>
              <ORFieldSelect id="ratingType" name="ratingType" label="Risco">
                {["AA", "A", "B", "C", "D", "E"].map((rating) => (
                  <OOption key={`ratingType_${rating}`} value={rating}>
                    {rating}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={6}>
              <ORFieldSelect
                id="productType"
                name="productType"
                label="Linhas de crédito"
              >
                {loanProductOptions.map((productOption) => (
                  <OOption
                    key={productOption.value}
                    value={productOption.value}
                  >
                    {productOption.text}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={6}>
              <ORFieldInputDate
                size="md"
                id="expirationDate"
                name="expirationDate"
                label="Data de Expiração"
              />
            </OCol>
            <OCol sm={6}>
              <ORFieldInput
                tag="number"
                id="amount"
                name="amount"
                label="Valor"
              />
            </OCol>
          </ORow>

          <OTypography className="mt-4" tag="h3" weight="normal" size="lg">
            Provisão de Rating
          </OTypography>
          <ORow>
            <OCol sm={6}>
              <ORFieldSelect
                id="creditType"
                name="creditType"
                label="Tipo de Crédito"
              >
                {creditTypeOptions.map((cto) => (
                  <OOption key={`creditType_${cto.value}`} value={cto.value}>
                    {cto.label}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={6}>
              <ORFieldSelect
                id="portfolioClassification"
                name="portfolioClassification"
                label="Classificação do Carteira"
              >
                {portfolioClassificationTypes.map((type) => (
                  <OOption key={`portfolioClassification_${type}`} value={type}>
                    {type}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={6}>
              <ORFieldSelect
                id="ratingCounterparty"
                name="ratingCounterparty"
                label="Rating da Contraparte"
              >
                {ratingTypes.map((rating) => (
                  <OOption key={`ratingCounterparty_${rating}`} value={rating}>
                    {rating}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={6}>
              <ORFieldInput
                tag="percentage"
                id="exposureAtDefault"
                name="exposureAtDefault"
                label="Exposição à Inadimplência"
              />
            </OCol>
            <OCol sm={6}>
              <ORFieldInput
                tag="percentage"
                id="defaultProbability"
                name="defaultProbability"
                label="Probabilidade de Incumprimento"
              />
            </OCol>
            <OCol sm={6}>
              <ORFieldInput
                tag="percentage"
                id="lostGivenDefault"
                name="lostGivenDefault"
                label="Perda em Função da Inadimplência"
              />
            </OCol>
            <OCol sm={6}>
              <ORFieldInput
                tag="percentage"
                id="expectedLoss"
                name="expectedLoss"
                label="Perda Esperada"
              />
            </OCol>
            <OCol sm={6}>
              <ORFieldInput
                tag="percentage"
                id="incurredLoss"
                name="incurredLoss"
                label="Perda a Incorrer"
              />
            </OCol>
            <OCol sm={6}>
              <ORFieldSelect id="stage" name="stage" label="Fase">
                {[1, 2, 3].map((s) => (
                  <OOption key={`stage_${s}`} value={s}>
                    {s}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          </ORow>

          <OTypography className="mt-4" tag="h3" weight="normal" size="lg">
            Parcelamentos
          </OTypography>
          {errors?.rate?.quotes?.message && (
            <OField error message={errors.rate.quotes.message} />
          )}
          <ODataGridGenerator grid={limitInstallmentsGrid} />

          <OTypography className="mt-4" tag="h3" weight="normal" size="lg">
            Garantias - Cartões
          </OTypography>
          <ODataGridGenerator grid={limitCollateralsGrid} />

          <OTypography className="mt-4" tag="h3" weight="normal" size="lg">
            Garantias - Avalistas
          </OTypography>
          <ODataGridGenerator grid={limitGuarantorsGrid} />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
