import { modalManager } from "@maestro/core";
import { OConfirmationModal } from "@maestro/react";
import { showConfigurationsModalId } from "./show-configurations-modal.utils";
import { ConfigurationPre } from "./show-configurations-modal.styles";

interface ShowConfigurationsModalProps {
  configurations?: (
    | ScfDevelopers.Configuration
    | ScfDevelopers.WorkflowConfiguration
  )[];
  title: string;
}

export const ShowConfigurationsModal = ({
  configurations,
  title,
}: ShowConfigurationsModalProps) => {
  return (
    <OConfirmationModal
      modalId={showConfigurationsModalId}
      title={title}
      confirmLabel="Ok"
      divider={false}
      size="md"
      onConfirm={() => modalManager.hide(showConfigurationsModalId)}
    >
      <ConfigurationPre>
        {JSON.stringify(configurations, null, 4)}
      </ConfigurationPre>
    </OConfirmationModal>
  );
};
