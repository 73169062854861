import { ORFieldInputDate } from "@maestro/react";
import styled from "styled-components";

export const DatePickerWithoutIconIfDisabled = styled(ORFieldInputDate)<{
  disabled: boolean;
}>`
  span {
    display: ${(props) => (props.disabled ? "none" : "inline")};
  }
`;
