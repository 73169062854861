import { IIcon, IType } from "@maestro/core";
import {
  OBadge,
  OCheckbox,
  OCol,
  OIcon,
  OLabel,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearchField } from "components/select-search";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { orqIcons } from "../workflow-configuration.utils";
import { useStatusForm } from "./configurable-status-form.hook";
import { StatusFormProps } from "./configurable-status-form.types";
import { ColorBox } from "./configurable-status.styles";

const colors = ["info", "danger", "success", "warning"];

export const StatusForm = ({ mode }: StatusFormProps) => {
  const {
    allConfigurableStatusValue,
    colorWatcher,
    form,
    iconName,
    labelName,
    loading,
    loadingAllConfigurableStatus,
    setValue,
    upsertStatus,
  } = useStatusForm({ mode });

  const iconOptions = orqIcons.map((icon) => ({
    value: icon,
    label: icon,
  }));

  const allConfigurableStatusOptions =
    allConfigurableStatusValue
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .map((option) => ({
        value: option.id,
        label: option.name,
      })) ?? [];

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title={mode === "add" ? "Adicionar Status" : "Editar Status"}
        />
      }
      actions={
        <LoadingButton
          loading={loading || loadingAllConfigurableStatus}
          type="info"
          onClick={upsertStatus}
        >
          Salvar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <CardTemplate>
          <ORow gap={3}>
            <OCol sm={4}>
              <ORFieldInput
                id="name"
                name="name"
                tag="text"
                label="Nome do status"
                labelSize="md"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                id="nameId"
                name="nameId"
                tag="text"
                label="Identificador do status"
                labelSize="md"
              />
            </OCol>
            <OCol sm={4}>
              <SelectSearchField
                id="iconName"
                name="iconName"
                label="Nome do ícone"
                labelSize="md"
                key={orqIcons.map((i) => i).join("_")}
                options={iconOptions}
              />
            </OCol>

            <OCol sm={4}>
              <OLabel htmlFor="color" size="md">
                Cor
              </OLabel>

              <div className="d-flex align-items-center my-2 gap-5">
                <div className="d-flex gap-2">
                  {colors.map((color) => (
                    <ColorBox
                      key={color}
                      type={color as IType}
                      isActive={color === colorWatcher}
                      onClick={() => setValue("color", color)}
                    />
                  ))}
                </div>

                <OBadge
                  key={`${labelName}-${iconName}-${colorWatcher}`}
                  type={`${colorWatcher}-light` as IType}
                  rounded
                >
                  {!!iconName && (
                    <OIcon
                      category="orq"
                      icon={iconName as IIcon}
                      size="xl"
                      type={colorWatcher as IType}
                    />
                  )}
                  {labelName || "Status"}
                </OBadge>
              </div>
            </OCol>

            <OCol sm={4}>
              <ORFieldCheckboxGroup
                id="endOnStart"
                name="endOnStart"
                label="Etapas"
                labelSize="md"
                tooltip="Se selecionado, o status inicializará encerrado (Valor em StatusEndedAt). Somente para configuração de etapas paralelas ."
                tooltipPosition="top-right"
              >
                <div className="d-flex flex-row gap-2 align-items-center">
                  <OCheckbox size="xs" id="endOnStart-checkbox" value="true" />
                  <OLabel htmlFor="endOnStart-checkbox">
                    Status deve inicializar encerrado
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>

            {mode === "edit" && (
              <OCol sm={4}>
                <SelectSearchField
                  id="statusGroupId"
                  name="statusGroupId"
                  label="Grupo de status"
                  labelSize="md"
                  options={allConfigurableStatusOptions}
                  key={allConfigurableStatusValue
                    ?.map((cs) => `${cs.name}-${cs.id}`)
                    .join("_")}
                />
              </OCol>
            )}
          </ORow>
        </CardTemplate>
      </FormProvider>
    </FormTemplate>
  );
};
