import { parseExcelDate } from "utils/parse-excel-date";
import { normalizeObjectKeys } from "utils/parse-spreadsheet";
import { readBufferAsSpreadsheet } from "utils/read-buffer-as-spreadsheet";
import { IntubateValues } from "../../intubate-generic.types";

export const parseIntubateAddAllowSpreadsheet = (
  buffer: FileReader["result"],
): IntubateValues[] => {
  const { firstSheetJson: sheetData } = readBufferAsSpreadsheet(buffer);

  const rows = sheetData.map((row) => {
    const cleanRow = normalizeObjectKeys(row);
    return {
      identification: String(cleanRow["identificação"]),
      from: parseExcelDate(cleanRow["data início"]),
      until: parseExcelDate(cleanRow["data fim"]),
      type: String(cleanRow["tipo loja"]),
    };
  });

  return rows;
};
