import {
  OIcon,
  OSkeletonAvatar,
  OSkeletonCard,
  OTypography,
} from "@maestro/react";
import { AccountUtils } from "utils/financial-institution/account";
import { useCollectionBeneficiaryContext } from "../../../../collection-beneficiary.context";
import { MapperTreatyStatusToIconProps } from "./treaty-items.utils";

export const TreatyItems = () => {
  const { beneficiary, isLoading } = useCollectionBeneficiaryContext();

  if (isLoading) {
    return (
      <div className="d-flex align-items-center gap-2">
        <OSkeletonAvatar size="xs" />
        <OSkeletonCard height="1rem" width="12rem" rounded />
      </div>
    );
  }

  return (
    <>
      {beneficiary?.treaties.map(({ branchCode, number, status }) => (
        <div
          className="d-flex align-items-center gap-2"
          key={`${branchCode}${number}`}
        >
          <OIcon
            {...MapperTreatyStatusToIconProps[status]}
            category="orq"
            size="lg"
          />

          <OTypography type="dark-80">
            {AccountUtils.assembleResume(branchCode, number)}
          </OTypography>
        </div>
      ))}
    </>
  );
};
