import { OLoader } from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { useMemo } from "react";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useProcessorConfigField } from "./processor-config-field.hook";

export const ProcessorConfigField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorPropertyId, name, description } = processorConfigProperty;
  const { chidProcessorConfigs, loading } = useProcessorConfigField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const options = useMemo(
    () =>
      chidProcessorConfigs?.map((config) => {
        return {
          value: String(config?.id),
          label: config?.name,
        };
      }) ?? [],
    [chidProcessorConfigs],
  );

  const processorConfigField = useMemo(
    () => (
      <div className="d-flex align-items-center">
        <SelectSearchField
          key={chidProcessorConfigs?.map((config) => config.id).join("")}
          id={`properties.${name}_${processorPropertyId}`}
          name={`properties.${name}_${processorPropertyId}`}
          placeholder="Selecione"
          label={description}
          options={options}
        />
        {loading && <OLoader />}
      </div>
    ),
    [
      options,
      chidProcessorConfigs,
      name,
      processorPropertyId,
      description,
      loading,
    ],
  );

  return <>{processorConfigField}</>;
};
