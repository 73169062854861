import { modalManager, OToastManager } from "@maestro/core";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ManageColumnsModalId } from "../../manage-columns-modal.types";

interface ManageColumnsProps {
  columns: string[];
  defaultColumns: string[];
  gridId: string;
  setVisibleColumns: Dispatch<SetStateAction<string[]>>;
}

export const useManageColumns = ({
  columns,
  defaultColumns,
  gridId,
  setVisibleColumns,
}: ManageColumnsProps) => {
  const [selectedColumns, setSelectedColumns] =
    useState<string[]>(defaultColumns);

  const resetColumns = useCallback(() => {
    setVisibleColumns(defaultColumns);
    setSelectedColumns(defaultColumns);
    localStorage.setItem(gridId, defaultColumns.join());
  }, [defaultColumns, gridId, setVisibleColumns]);

  useEffect(() => {
    const storedData = localStorage.getItem(gridId);
    if (!storedData) {
      resetColumns();
      return;
    }

    const storedColumns = storedData.split(",");
    const isValid = storedColumns.every((value) => columns.includes(value));

    if (isValid) {
      setSelectedColumns(storedColumns);
      setVisibleColumns(storedColumns);
    } else {
      resetColumns();
    }
  }, []);

  const updateVisibleColumns = useCallback(() => {
    if (!selectedColumns.length) {
      return OToastManager.warning(
        "Você precisa selecionar pelo menos uma coluna.",
      );
    }
    localStorage.setItem(gridId, selectedColumns.join());
    setVisibleColumns(selectedColumns);
    modalManager.hide(ManageColumnsModalId);
  }, [gridId, selectedColumns, setVisibleColumns]);

  return { selectedColumns, setSelectedColumns, updateVisibleColumns };
};
