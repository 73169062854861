import { HTMLAttributes } from "react";
import { ToolButtonProps } from "./tool-button.types";

export const ToolButton = ({
  active,
  setActive,
  children,
  handleClick,
  ...rest
}: ToolButtonProps & HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      type="button"
      onClick={() => {
        setActive && setActive((a) => !a);
        handleClick && handleClick();
      }}
      className={active ? "active" : ""}
      {...rest}
    >
      {children}
    </button>
  );
};
