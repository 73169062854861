import * as yup from "yup";

export interface ExchangeDocumentGroupsFormValues {
  isRequired: string[];
  mustShowForPf: string[];
  mustShowForPj: string[];
  mustShowWhenOutgoing: string[];
  mustShowWhenIncoming: string[];
  documentConfigIds: number[];
}

export const exchangeDocumentGroupsFormValidationFormSchema: yup.ObjectSchema<ExchangeDocumentGroupsFormValues> =
  yup.object({
    isRequired: yup.array().default([]),
    mustShowForPf: yup.array().default([]),
    mustShowForPj: yup.array().default([]),
    mustShowWhenOutgoing: yup.array().default([]),
    mustShowWhenIncoming: yup.array().default([]),
    documentConfigIds: yup
      .array()
      .required("Campo obrigatório")
      .min(1, "É necessário selecionar ao menos um documento."),
  });
