import { OCard, OCardBody, OCheckbox, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { PageTitle } from "components/page-title";
import { useSimulation } from "../../../simulation.context";
import { SimulationFooter } from "../../footer/footer.component";
import { collateralTypeMap } from "../../../simulation.utils";

export const Collaterals = () => {
  const { selectedOfferLoan } = useSimulation();

  const filteredCollaterals = selectedOfferLoan?.collaterals.filter(
    (c) => c.collateralType !== "GARANTIDOR",
  );

  return (
    <>
      <PageTitle
        title="Confira as garantias de crédito"
        description="Já selecionamos garantias que sua empresa pode oferecer para melhorar as condições e o valor disponível em sua oferta"
      />
      {filteredCollaterals?.length ? (
        filteredCollaterals?.map((collateral) => {
          return (
            <OCard key={collateral.id}>
              <OCardBody>
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <OTypography>
                    {collateralTypeMap[collateral.collateralType]?.label}
                  </OTypography>
                  <OCheckbox
                    id={collateral.id.toString()}
                    name={collateral.id.toString()}
                    value={collateral.id.toString()}
                    checked
                    disabled
                  />
                </div>
                {collateral.collateralType === "PAINEIS_SOLARES" && (
                  <OTypography type="dark-80">
                    {collateral.description} | {collateral.manufacturer} |{" "}
                    {collateral.quantity}{" "}
                    {collateral.quantity > 1 ? "unidades" : "unidade"} a{" "}
                    {masks.currency(collateral.amount)}
                  </OTypography>
                )}
              </OCardBody>
            </OCard>
          );
        })
      ) : (
        <OCard>
          <OCardBody>
            <OTypography>Nenhuma garantia atrelada a esta oferta.</OTypography>
          </OCardBody>
        </OCard>
      )}
      <SimulationFooter continueButtonDisabled={false} />
    </>
  );
};
