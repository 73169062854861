import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetOdataSettlementStrategiesOdataResponse } from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { productTypeMap } from "./settlement-strategies.utils";

export const settlementStrategiesGrid = (
  setSettlementId: React.Dispatch<React.SetStateAction<string | undefined>>,
): ODataGridGeneratorConfig<
  GetOdataSettlementStrategiesOdataResponse["value"][number]
> => ({
  datagrid: {
    noDataText: "Nenhuma estratégia encontrada.",
    pager: {
      infoText: "Página {0} de {1} ({2} estratégias)",
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
    },
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "TipoProduto",
      caption: "Produto",
      cellRender: ({ data }) =>
        getValueFromMap(productTypeMap, data.TipoProduto),
      lookup: {
        dataSource: Object.entries(productTypeMap).map(([key, label]) => ({
          value: key,
          label,
        })),
        displayExpr: "label",
        valueExpr: "value",
      },
      allowHeaderFiltering: false,
    },
    {
      dataField: "Cedente.OfficialName",
      caption: "Cedente",
    },
    {
      dataField: "Cedente.Cnpj",
      caption: "CNPJ do cedente",
      cellRender: ({ data }) => masks.cpfCnpj(data.Cedente.Cnpj),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "Sacado.OfficialName",
      caption: "Sacado",
    },
    {
      dataField: "Sacado.Cnpj",
      caption: "CNPJ do sacado",
      cellRender: ({ data }) => masks.cpfCnpj(data.Sacado.Cnpj),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "Portfolio.Name",
      caption: "Carteira",
      allowFiltering: false,
      allowHeaderFiltering: true,
      customizeText: (value) => {
        return (value.valueText === "") ? "N/A" : value.valueText;
      },
    },
    {
      dataField: "EstrategiaComercial.UniqueId",
      caption: "Estratégia comercial",
    },
    {
      dataField: "SettlementMethodCode",
      caption: "SettlementMethodCode",
      allowFiltering: false,
      allowHeaderFiltering: true,
    },
    {
      dataField: "SettlementLockInfo",
      caption: "SettlementLockInfo",
      allowFiltering: false,
      allowHeaderFiltering: true,
    },
    {
      dataField: "BankAccount.Bank",
      caption: "Banco",
    },
    {
      dataField: "BankAccount.Agency",
      caption: "Agência",
    },
    {
      dataField: "BankAccount.Number",
      caption: "Conta",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => {
        return (
          <DataGridAction
            actions={[
              {
                label: "Editar",
                icon: { category: "orq", icon: "orq-edit-pencil" },
                role: roles.recebiveis.product.settlementStrategiesEditPage
                  .role,
                route:
                  corporateRouter.routes.recebiveis.product.settlementStrategies.edit.path(
                    {
                      settlementId: data.Id,
                    },
                  ),
              },
              {
                label: "Excluir",
                icon: { category: "orq", icon: "orq-edit-trash" },
                onClick: () => setSettlementId(data.Id.toString()),
              },
            ]}
          />
        );
      },
    },
  ],
});
