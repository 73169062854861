import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasConfiguracaoByConfigIdentifierRouteParams } from "../../../../../../routes/ferramentas.route-params";
import { DeskConfigForm } from "../../_compose/desk-config-form.component";
import {
  DeskConfigFormValues,
  deskConfigFormValidationSchema,
} from "../../_compose/desk-config.form";

export const UpdateDeskConfigPage = () => {
  const { configIdentifier } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasConfiguracaoByConfigIdentifierRouteParams>();
  if (!configIdentifier) throw new Error("No configIdentifier");
  const form = useForm<DeskConfigFormValues>({
    resolver: yupResolver(deskConfigFormValidationSchema),
  });
  const { reset, handleSubmit } = form;
  const {
    callService: getDeskConfigDetails,
    loading,
    hasError,
    value: deskConfig,
  } = useServiceCall(service.onboarding.getDesksOnboardingConfigDetails);
  const { callService: updateDesksOnboardingConfig, loading: submitLoading } =
    useServiceCall(service.onboarding.updateDesksOnboardingConfig);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await updateDesksOnboardingConfig(values);

        if (!success)
          return OToastManager.danger("Erro ao atualizar configuração");

        return OToastManager.success("Configuraação atualizada com sucesso");
      }),
    [handleSubmit, updateDesksOnboardingConfig],
  );

  useEffect(() => {
    getDeskConfigDetails(configIdentifier);
  }, [getDeskConfigDetails, configIdentifier]);

  useEffect(() => {
    deskConfig &&
      reset({
        ...deskConfig,
        initialStages: JSON.stringify(deskConfig.initialStages, null, 2),
        profilesAvailable: JSON.stringify(
          deskConfig.profilesAvailable,
          null,
          2,
        ),
        statusToSync: deskConfig.statusToSync ?? [],
      });
  }, [deskConfig, reset]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar configuração de mesa" />}
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar configuração"
            callback={() => getDeskConfigDetails(configIdentifier)}
          />
        }
        noValue
        render={() => (
          <FormProvider {...form}>
            <DeskConfigForm editOnly />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
