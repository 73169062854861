import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { useMemo } from "react";
import { OToastManager } from "@maestro/core";
import { grid } from "./company-offers.grid";
import { Offer } from "./company-offers.type";

export const CompanyOffers = () => {
  const gridRef = useGridRef();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<Offer>(async () => {
        const identification = sessionStorage.getItem(
          "corporate-portal:selected-customer",
        );

        try {
          const offerActiveResponse =
            await service.clerk.getOfferActiveByIdentification(
              identification ?? "",
            );
          const offerInfoResponse = await service.clerk.getOfferInfo();

          const productsRelationships =
            await service.onboardingRelationships.getProfileAllProduct();

          const productsResult = offerInfoResponse.data.filter((product) => {
            const foundRelationships = productsRelationships.data.find(
              ({ code }) => product.code === code,
            );

            return foundRelationships;
          });

          if (!offerInfoResponse.data) return [];

          const activeProductCodes = new Set(
            offerActiveResponse.data.products.flatMap((item) => item.code),
          );

          return productsResult.map((offer) => ({
            menu: offer.name,
            isEnable: activeProductCodes.has(offer.code),
            code: offer.code,
          }));
        } catch {
          const errorMessage = "Erro ao buscar a listagem";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }
      }),
    [],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Oferta" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
