import { OButton } from "@maestro/react";
import styled from "styled-components";

export const StyledButton = styled(OButton)`
  button {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const TotalValueBadge = styled.div`
  background-color: #d2e5ff;
  display: flex;
  justify-content: space-between;
  border: 1px solid #87baff;
  border-radius: 4px;
  padding: 24px;
`;

export const AccountInfoBadge = styled.div`
  background-color: #d2e5ff;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  border: 1px solid #00000029;
  border-radius: 4px;
  padding: 16px 24px;
`;
