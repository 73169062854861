import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router";
import { service } from "services";
import { stepsMap } from "./compose/steps-timeline/steps-timeline.utils";
import { SimulationSummary, StepsMap } from "./simulation.types";

export interface UseSummaryProps {
  type?: string;
  id?: string;
  offerId?: string;
  form: UseFormReturn<FieldValues, object>;
}

export const useSummary = ({ id, type, form, offerId }: UseSummaryProps) => {
  const [summary, setSummary] = useState<SimulationSummary[]>();
  const [loading, setLoading] = useState(false);
  const [currentStepsMap, setCurrentStepsMap] = useState<StepsMap[]>();
  const navigate = useNavigate();

  const initialized = useRef(false);

  const { setValue } = form;

  const handleSetStepsMap = (data: SimulationSummary[]) => {
    const hasCollaterals = data?.some(
      (s) =>
        s?.collaterals?.filter((c) => c.collateralType !== "GARANTIDOR")
          ?.length,
    );
    const hasGuarantors = data?.some(
      (s) => s?.guarantors?.requiredGuarantors?.length,
    );

    const filters: string[] = [];
    if (!hasCollaterals) filters.push("Garantias");
    if (!hasGuarantors) filters.push("Avalistas");
    if (data?.length < 2) filters.push("Ofertas");

    setCurrentStepsMap(
      stepsMap
        ?.filter((m) => !filters.includes(m.title))
        .sort((a, b) => a.index - b.index),
    );
  };

  const getSummary = useCallback(
    async (typeInfo: string, limitId: string, limitRequestId: string) => {
      try {
        setLoading(true);
        const { data }: { data: SimulationSummary[] } =
          await service.hubLoan.getSimulationSummaryV2(
            typeInfo,
            limitId,
            limitRequestId,
          );

        if (data?.[0].limit <= 0) {
          navigate("/emprestimos/cliente/ofertas");
          return;
        }

        setValue("value", data?.[0].limit);
        setSummary(data);
        handleSetStepsMap(data);
        if (data.length < 2 && !data?.[0].isFirstPaymentDateChangeAvailable) {
          setValue("date", data?.[0]?.firstPaymentDate);
        } else if (data.length < 2) {
          setValue("date", data?.[0]?.paymentDatesIntervals?.[0].maxDate);
        }
      } catch (error) {
        OToastManager.danger(
          "Não foi possível obter os dados para realizar a simulação do empréstimo.",
        );
        navigate("/emprestimos/cliente/ofertas");
      } finally {
        setLoading(false);
      }
    },
    [setValue, navigate],
  );

  useEffect(() => {
    if (id && type && offerId && !initialized.current) {
      initialized.current = true;
      getSummary(type, id, offerId);
    }
  }, [id, type, getSummary, offerId]);

  return { summary, loading, currentStepsMap };
};
