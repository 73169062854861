import { CreateBeneficiaryRequest } from "services/admin-bankinghub/collection/models";
import { TreatyStatus } from "services/admin-bankinghub/collection/models/types";

export const assembleCreateBeneficiaryRequest = ({
  companyName,
  personType,
  treaties,
}: BankingHubAdminCollection.Beneficiary): CreateBeneficiaryRequest => {
  const accounts = treaties
    .filter(({ status }) => status !== TreatyStatus.ACTIVE)
    .map(({ branchCode, number }) => ({ branchCode, number }));

  return { accounts, name: companyName, personType };
};
