import { IType, OBadge, OButton, OIcon, OTypography } from "@maestro/react";
import { helpers, masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { service } from "services";
import { FinancedAsset } from "services/hubcreditmanager/models/responses";
import { invoiceStatusMap } from "../../workflow-leads.utils";

export const creditLineFinancedAssetsGrid = {
  datagrid: {
    noDataText: "Nenhum ativo financiado disponível",
    columnAutoWidth: true,
  },
  columns: [
    {
      dataField: "id",
      caption: "Id do ativo",
    },
    {
      dataField: "type",
      caption: "Tipo",
    },
    {
      dataField: "amount",
      caption: "Valor financiado",
      cellRender: ({ data }) => masks.currency(data.amount, "R$"),
    },
    {
      dataField: "invoice.id",
      caption: "Id da invoice",
    },
    {
      dataField: "invoice.type",
      caption: "Tipo de invoice",
      cellRender: ({ data }) => data.invoice?.type || "-",
    },
    {
      dataField: "invoice.invoiceDataOrigin",
      caption: "Origem",
    },
    {
      dataField: "invoice.amount",
      caption: "Valor da invoice",
      cellRender: ({ data }) =>
        data.invoice ? masks.currency(data.invoice?.amount, "R$") : "-",
    },
    {
      dataField: "invoice.status",
      caption: "Status da invoice",
      cellRender: ({ data }) => {
        const invoiceStatus = invoiceStatusMap[data.invoice?.status];

        if (!invoiceStatus) return "-";

        return (
          <>
            {invoiceStatus ? (
              <OBadge
                size="sm"
                type={`${invoiceStatus.color}-light` as IType}
                rounded
              >
                <OIcon
                  category="orq"
                  icon={invoiceStatus.iconName}
                  type={invoiceStatus.color}
                  size="lg"
                />
                {invoiceStatus.name}
              </OBadge>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      dataField: "invoice.payee.taxId",
      caption: "Emitente",
      cellRender: ({ data }) => (
        <div className="d-flex flex-column">
          <OTypography size="sm">{data.invoice?.payee?.name}</OTypography>
          <OTypography size="sm">
            {masks.cpfCnpj(data.invoice?.payee?.taxId)}
          </OTypography>
        </div>
      ),
    },
    {
      dataField: "invoice.payer.taxId",
      caption: "Pagador",
      cellRender: ({ data }) => (
        <div className="d-flex flex-column">
          <OTypography size="sm">{data.invoice?.payer?.name}</OTypography>
          <OTypography size="sm">
            {masks.cpfCnpj(data.invoice?.payer?.taxId)}
          </OTypography>
        </div>
      ),
    },
    {
      caption: "Arquivo",
      cellRender: ({ data }) =>
        !!data.invoice?.file && (
          <OButton
            size="sm"
            onClick={async () => {
              const { data: response } =
                await service.hubCreditManager.download({
                  key: data.invoice.file.key,
                });

              helpers.downloadBlobFile(data.invoice.file.name, response);
            }}
          >
            <OIcon category="orq" icon="orq-download" size="sm" />
          </OButton>
        ),
    },
  ],
} satisfies ODataGridGeneratorConfig<FinancedAsset>;
