import { client } from "../../client";
import { hubloanEndpoints } from "../hubloan.endpoints";
import {
  GetOdataFailedRequestsParams,
  GetOdataInstallmentsDueDatesAdminParams,
  GetOdataLoanAdminParams,
  GetOdataTradeImportsAdminParams,
} from "./models/requests";
import {
  GetOdataFailedRequestsResponse,
  GetOdataInstallmentsDueDatesAdminResponse,
  GetOdataLoanAdminResponse,
  GetOdataTradeImportsAdminResponse,
} from "./models/responses";
import { GetOdataCustomerCertificatesAdminResponse } from "./models/responses/get-odata-customer-certificates-admin.response";

export const hubLoanODataService = {
  getFailedRequests(params?: GetOdataFailedRequestsParams) {
    return client.get<GetOdataFailedRequestsResponse>(
      hubloanEndpoints.odata.failedRequests,

      { params },
    );
  },
  getTradeImportsAdmin(params?: GetOdataTradeImportsAdminParams) {
    return client.get<GetOdataTradeImportsAdminResponse>(
      hubloanEndpoints.odata.tradeImportsAdmin,

      { params },
    );
  },
  getInstallmentsDueDatesAdmin(
    params?: GetOdataInstallmentsDueDatesAdminParams,
  ) {
    return client.get<GetOdataInstallmentsDueDatesAdminResponse>(
      hubloanEndpoints.odata.installmentsDueDatesAdmin,

      { params },
    );
  },
  getCustomerCertificatesAdmin(
    params?: GetOdataInstallmentsDueDatesAdminParams,
  ) {
    return client.get<GetOdataCustomerCertificatesAdminResponse>(
      hubloanEndpoints.odata.customerCertificateAdmin,

      { params },
    );
  },
  getLoanAdmin(params?: GetOdataLoanAdminParams) {
    return client.get<GetOdataLoanAdminResponse>(
      hubloanEndpoints.odata.loanAdmin,

      { params },
    );
  },
};
