import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { PageTitle } from "components/page-title";
import { Filiais } from "./filiais.types";
import { filiaisGrid } from "./filiais.grid";

export const SubsidiariesComponent = () => {
  const gridRef = useGridRef();

  const dataSource = useMemo(() => {
    return dataSourceCustomStoreGenerator<NonNullable<Filiais["data"]>[number]>(
      () =>
        service.quickfin.getFiliais().then(({ data }) => {
          return data;
        }),
    );
  }, []);

  return (
    <GridTemplate
      pageTitle={
        <PageTitle
          title="Filiais"
          description="
      Veja os valores disponíveis por filial."
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={filiaisGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
