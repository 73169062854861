import { ODropdown } from "@maestro/react";
import { AlternativeButton } from "../alternative-button";
import { GridActions } from "./_compose/grid-actions/grid-actions.component";
import { ManageColumnsModal } from "./_compose/manage-columns-modal/manage-columns-modal.component";
import { DataGridGeneralActionsProps } from "./data-grid-general-actions.types";

export const DataGridGeneralActions = ({
  customActions,
  disabled,
  manageColumns,
  downloadAction,
  refreshAction,
}: DataGridGeneralActionsProps) => {
  return (
    <div className="align-self-center">
      {manageColumns && <ManageColumnsModal {...manageColumns} />}

      <ODropdown floating positionX="left" positionY="bottom">
        <AlternativeButton
          text="Ações"
          contentType="dark"
          icon={{ icon: "orq-configuration", category: "orq", size: "lg" }}
          disabled={disabled}
        />

        <GridActions
          customActions={customActions}
          downloadAction={downloadAction}
          refreshAction={refreshAction}
          manageColumns={!!manageColumns}
        />
      </ODropdown>
    </div>
  );
};
