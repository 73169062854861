import { OCard, OCardBody, OTypography } from "@maestro/react";
import { TreatyItems } from "./_compose/treaty-items";

export const TreatiesDetails = () => {
  return (
    <OCard>
      <OCardBody className="d-flex flex-column gap-3">
        <OTypography type="dark" size="lg">
          Status das contas
        </OTypography>

        <TreatyItems />
      </OCardBody>
    </OCard>
  );
};
