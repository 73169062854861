import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { MasterDetail } from "devextreme-react/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { customer } from "contexts/customer";
import { dataSourceClient, tradesGridClient } from "./trades.grid";
import { TradesMasterDetail } from "./trades.master-detail";

export const TradesClient = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Trades" />}
      gridRef={gridRef}
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={tradesGridClient}
        dataSource={dataSourceClient(
          customer.value?.identification.slice(0, 8),
        )}
      >
        <MasterDetail component={TradesMasterDetail} enabled />
      </ODataGridGenerator>
    </GridTemplate>
  );
};
