import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { DataGrid } from "devextreme-react";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { hubCreditManagerODataService } from "services/hubcreditmanager/odata/hubcreditmanager-odata.service";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const erpConnectionsGrid = (gridRef: React.RefObject<DataGrid>) =>
  ({
    datagrid: {
      ref: gridRef,
      noDataText: "Nenhuma conexão ERP encontrada.",
      headerFilter: { visible: false, allowSearch: true },
      filterRow: {
        visible: true,
      },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao buscar conexões ERP.";
      },
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubCreditManager.odata.erpConnectionsAdmin,
      ),
    },
    columns: [
      {
        dataField: "Id",
        caption: "Id",
        sortOrder: "desc",
      },
      {
        caption: "Data de criação",
        dataField: "CreatedDate",
      },
      {
        caption: "Conexão ERP",
        dataField: "Name",
      },
      {
        caption: "Nome da empresa",
        dataField: "CustomerName",
      },
      {
        caption: "CPF/CNPJ",
        dataField: "CustomerTaxId",
        format: (value) => masks.cpfCnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        caption: "Ações",
        cellRender: ({ data }) => (
          <DataGridAction
            actions={[
              {
                label: "Deletar",
                icon: { category: "orq", icon: "orq-remove-circle" },
                onClick: async () => {
                  try {
                    await service.hubCreditManager.deleteERPConnection(data.Id);

                    OToastManager.info("Conexão ERP deletada com sucesso.");

                    gridRef.current?.instance.refresh();
                  } catch (err) {
                    OToastManager.danger(
                      "Um erro ocorreu ao tentar deletar a conexão ERP.",
                    );
                  }
                },
              },
            ]}
          />
        ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    NonNullable<
      Awaited<
        ReturnType<typeof hubCreditManagerODataService.getERPConnections>
      >["data"]["value"]
    >[number]
  >);
