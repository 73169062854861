import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, OTypography } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearchField } from "components/select-search";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { WorkflowProdutoWorkflowConfigByIdWorkflowConfigViewByIdViewBigNumberById } from "../../../../../../../../../routes/workflow.route-params";
import {
  viewBigNumberFormDefaultValues,
  ViewBigNumberFormFields,
  viewBigNumberFormSchema,
} from "./view-big-number-grid.schema";

export const ViewBigNumberGrid = () => {
  const { id, workflowConfigViewId, viewBigNumberId } =
    useParams<WorkflowProdutoWorkflowConfigByIdWorkflowConfigViewByIdViewBigNumberById>();
  if (!id) throw new Error("No id");
  if (!workflowConfigViewId) throw new Error("No workflowConfigViewId");
  if (!viewBigNumberId) throw new Error("No viewBigNumberId");

  const form = useForm<ViewBigNumberFormFields>({
    defaultValues: viewBigNumberFormDefaultValues,
    resolver: yupResolver(viewBigNumberFormSchema),
  });

  const { setValue, handleSubmit, watch } = form;

  const gridIdWatch = watch("gridId");

  const navigate = useNavigate();

  const {
    callService: getViewBigNumberById,
    value: viewBigNumber,
    loading: loadingGetViewBigNumberById,
  } = useServiceCall(service.hubCreditManager.getViewBigNumberById);

  const {
    callService: getGrids,
    value: grids,
    loading: loadingGetGrids,
    hasError: hasErrorGetGrids,
  } = useServiceCall(service.hubCreditManager.getGrids);

  const {
    callService: updateViewBigNumberGrid,
    loading: loadingUpdateViewBigNumberGrid,
  } = useServiceCall(service.hubCreditManager.updateViewBigNumberGrid);

  const options =
    [{ id: 0, name: "Nenhum" }, ...(grids ?? [])]?.map((config) => ({
      value: config.id,
      label: config.name,
    })) ?? [];

  const update = useCallback(
    async ({ gridId }: ViewBigNumberFormFields) => {
      const { success } = await updateViewBigNumberGrid({
        gridId: gridId !== 0 ? gridId : null,
        viewBigNumberId: Number(viewBigNumberId),
      });

      if (success) {
        OToastManager.success("Grid salvo com sucesso.");
        navigate(
          corporateRouter.routes.workflow.product.workflowsConfig.details.edit.views.configure.bigNumbers.path(
            { id, workflowConfigViewId },
          ),
        );
      } else {
        OToastManager.warning(
          "Um erro ocorreu ao tentar salvar o grid. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [
      id,
      navigate,
      updateViewBigNumberGrid,
      viewBigNumberId,
      workflowConfigViewId,
    ],
  );

  useEffect(() => {
    getViewBigNumberById(viewBigNumberId);
    getGrids();
  }, [getGrids, getViewBigNumberById, viewBigNumberId]);

  useEffect(() => {
    if (viewBigNumber?.grid?.id) {
      setValue("gridId", viewBigNumber?.grid?.id);
    } else {
      setValue("gridId", 0);
    }
  }, [setValue, viewBigNumber?.grid?.id]);

  useEffect(() => {
    hasErrorGetGrids &&
      OToastManager.danger(
        "Não foi possível receber as opções de grids padrões. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorGetGrids]);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Configurar big number da visualização"
          description={`Big number: ${viewBigNumber?.bigNumber.name}`}
        />
      }
      actions={
        <LoadingButton
          loading={
            loadingUpdateViewBigNumberGrid ||
            loadingGetViewBigNumberById ||
            loadingGetGrids
          }
          onClick={handleSubmit(update)}
          dataAction="configurar_grid_padrao:botao:salvar"
          dataLabel="salvar"
          disabled={hasErrorGetGrids}
        >
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        noValue
        render={() => (
          <CardTemplate>
            <FormProvider {...form}>
              <SelectSearchField
                id="gridId"
                name="gridId"
                options={options}
                key={grids?.length}
              />
            </FormProvider>
            {!!gridIdWatch && (
              <OTypography className="mt-2">
                {`Colunas: ${grids
                  ?.find((grid) => grid.id === gridIdWatch)
                  ?.gridColumns.map(
                    (gridColumn) => gridColumn.configurableGridColumn.caption,
                  )
                  .join(", ")}`}
              </OTypography>
            )}
          </CardTemplate>
        )}
      />
    </FormTemplate>
  );
};
