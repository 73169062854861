import { client } from "../client";
import { onboardingRelationshipsB2CService } from "./b2c/onboarding-relationships-b2c.service";
import {
  GetOdataCompanyPowersLegalEntityPowersParams,
  GetOdataCompanyPowersParametrizationStatusQueryParams,
  GetOdataCompanyPowersUserActChangedParams,
  GetRepresentativeWorkflowParams,
  PostAdminAddUserBody,
  PostAdminContingencyRunBody,
  PostAdminGetPowersStatusBody,
  PostAdminGuarantorsOccurrencesBody,
  PostAdminGuarantorsResendBody,
  PostAdminLoaAllUserBody,
  PostAdminLoaDetailsBody,
  PostAdminLoaDownloadBody,
  PostAdminLoaOccurrencesBody,
  PostAdminLoaRemoveBody,
  PostAdminLoaUploadParams,
  PostAdminMonitoringGetStatusBody,
  PostAdminNewGetIrisPowersCachedBody,
  PostAdminRoleassignmentsAllBody,
  PostAdminRoleassignmentsCreateInternacionalUserBody,
  PostAdminRoleassignmentsCreateUserBody,
  PostAdminRoleassignmentsGetProfilesBody,
  PostAdminRoleassignmentsGetUserDataBody,
  PostAdminRoleassignmentsGetWorkflowBody,
  PostAdminRoleassignmentsRelatedEntityBody,
  PostAdminRoleassignmentsValidateDataBody,
  PostAdminRoleassignmentsValidateProfilesBody,
  PostAdminScriptVerifyScriptsBody,
  PostAdminToolsUserWelcomeEmailBody,
  PostAdminUsersExcludeUserBody,
  PostAdminVerifyOptinPowersBody,
  PostGuarantorGetBody,
  PostGuarantorOnboardingGuarantorBody,
  PostGuarantorsGetAllBody,
  PostIrisPowersBody,
  PostUserUsersAttachUserBody,
  UpdateReprsentativeBody,
} from "./models/requests";
import { PostLoaDocumentTypeUpdateBody } from "./models/requests/post-loa-document-type-update.request";
import {
  GetAdminLoadRepresentativeGetRepresentativeResponse,
  GetOdataCompanyPowersGuarantorsResponse,
  GetOdataCompanyPowersLegalEntityPowersResponse,
  GetOdataCompanyPowersLetterofattorneyRepresentativeResponse,
  GetOdataCompanyPowersLetterofattorneyResponse,
  GetOdataCompanyPowersParametrizationStatusResponse,
  GetOdataCompanyPowersRoleassignmentResponse,
  GetOdataCompanyPowersUserActChangedResponse,
  GetProfileAllProductResponse,
  PostAdminCompanyGetByTaxIdResponse,
  PostAdminGetPowersStatusResponse,
  PostAdminGuarantorsOccurrencesResponse,
  PostAdminLoaAllResponse,
  PostAdminLoaAllResponseItemResponse,
  PostAdminLoaAllUserResponse,
  PostAdminLoaOccurrencesResponse,
  PostAdminMonitoringGetStatusResponse,
  PostAdminNewGetIrisPowersCachedResponse,
  PostAdminRoleassignmentsAllResponse,
  PostAdminRoleassignmentsGetProfilesResponse,
  PostAdminRoleassignmentsGetUserDataResponse,
  PostAdminRoleassignmentsGetWorkflowResponse,
  PostAdminRoleassignmentsValidateDataResponse,
  PostAdminRoleassignmentsValidateProfilesResponse,
  PostAdminScriptVerifyScriptsResponse,
  PostAdminVerifyOptinPowersResponse,
  PostGuarantorGetResponse,
  PostGuarantorsGetAllResponse,
  PostGuarantorsToLoadResponse,
  PostIrisPowersResponse,
  PostUserUsersAttachUserResponse,
} from "./models/responses";
import { onboardingRelationshipsEndpoints } from "./onboarding-relationships.endpoints";

export const onboardingRelationshipsService = {
  b2c: onboardingRelationshipsB2CService,
  getGuarantor(body: PostGuarantorGetBody) {
    return client.post<PostGuarantorGetResponse>(
      onboardingRelationshipsEndpoints.guarantor.get,
      body,
    );
  },
  loadGuarantors(body: PostGuarantorOnboardingGuarantorBody) {
    return client.post(
      onboardingRelationshipsEndpoints.guarantor.loadGuarantors,
      body,
    );
  },
  onboardingGuarantor(body: PostGuarantorOnboardingGuarantorBody) {
    return client.post<PostGuarantorsToLoadResponse>(
      onboardingRelationshipsEndpoints.guarantor.onboardingGuarantor,
      body,
    );
  },
  adminGuarantorsGetAll(body: PostGuarantorsGetAllBody) {
    return client.post<PostGuarantorsGetAllResponse>(
      onboardingRelationshipsEndpoints.admin.guarantors.getAll,
      body,
    );
  },
  getGuarantorOccurrences(body: PostAdminGuarantorsOccurrencesBody) {
    return client.post<PostAdminGuarantorsOccurrencesResponse>(
      onboardingRelationshipsEndpoints.admin.guarantors.occurrences,
      body,
    );
  },
  getLoaOccurrences(body: PostAdminLoaOccurrencesBody) {
    return client.post<PostAdminLoaOccurrencesResponse>(
      onboardingRelationshipsEndpoints.admin.loa.occurrences,
      body,
    );
  },
  getIrisPowers(body: PostIrisPowersBody) {
    return client.post<PostIrisPowersResponse>(
      onboardingRelationshipsEndpoints.customerService.irisPowers,
      body,
    );
  },
  getCompanyPowersParametrizationStatus(
    params?: GetOdataCompanyPowersParametrizationStatusQueryParams,
  ) {
    return client.get<GetOdataCompanyPowersParametrizationStatusResponse>(
      onboardingRelationshipsEndpoints.odata.companyPowers
        .parametrizationStatus,
      { params },
    );
  },
  runContingency(body: PostAdminContingencyRunBody) {
    return client.post(
      onboardingRelationshipsEndpoints.admin.contingency.run,
      body,
    );
  },
  postAdminToolsUserWelcomeEmail(body: PostAdminToolsUserWelcomeEmailBody) {
    return client.post(
      onboardingRelationshipsEndpoints.admin.tools.user.welcomeEmail,
      body,
    );
  },
  getProfiles(body: PostAdminRoleassignmentsGetProfilesBody) {
    return client.post<PostAdminRoleassignmentsGetProfilesResponse>(
      onboardingRelationshipsEndpoints.admin.roleassignments.getProfiles,
      body,
    );
  },
  checkEmail(body: PostAdminRoleassignmentsValidateDataBody) {
    return client.post<PostAdminRoleassignmentsValidateDataResponse>(
      onboardingRelationshipsEndpoints.admin.roleassignments.validateData,
      body,
    );
  },
  checkProfiles(body: PostAdminRoleassignmentsValidateProfilesBody) {
    return client.post<PostAdminRoleassignmentsValidateProfilesResponse>(
      onboardingRelationshipsEndpoints.admin.roleassignments.validateProfiles,
      body,
    );
  },
  createUser(body: PostAdminRoleassignmentsCreateUserBody) {
    return client.post(
      onboardingRelationshipsEndpoints.admin.roleassignments.createUser,
      body,
    );
  },
  createInternacionalUser(
    body: PostAdminRoleassignmentsCreateInternacionalUserBody,
  ) {
    return client.post(
      onboardingRelationshipsEndpoints.admin.roleassignments
        .createInternacionalUser,
      body,
    );
  },
  removeRelationship(body: PostAdminRoleassignmentsRelatedEntityBody) {
    return client.post(
      onboardingRelationshipsEndpoints.admin.roleassignments.relatedEntity,
      body,
    );
  },
  getRoleAssignmentsWorkflow(body: PostAdminRoleassignmentsGetWorkflowBody) {
    return client.post<PostAdminRoleassignmentsGetWorkflowResponse>(
      onboardingRelationshipsEndpoints.admin.roleassignments.getWorkflow,
      body,
    );
  },
  getUserData(body: PostAdminRoleassignmentsGetUserDataBody) {
    return client.post<PostAdminRoleassignmentsGetUserDataResponse>(
      onboardingRelationshipsEndpoints.admin.roleassignments.getUserData,
      body,
    );
  },
  getUserActChanges(params?: GetOdataCompanyPowersUserActChangedParams) {
    return client.get<GetOdataCompanyPowersUserActChangedResponse>(
      onboardingRelationshipsEndpoints.odata.companyPowers.userActChanged,
      { params },
    );
  },
  getPowersStatus(body: PostAdminGetPowersStatusBody) {
    return client.post<PostAdminGetPowersStatusResponse>(
      onboardingRelationshipsEndpoints.admin.getPowersStatus,
      body,
    );
  },
  getLegalEntityPowers(params?: GetOdataCompanyPowersLegalEntityPowersParams) {
    return client.get<GetOdataCompanyPowersLegalEntityPowersResponse>(
      onboardingRelationshipsEndpoints.odata.companyPowers.legalEntityPowers,
      { params },
    );
  },
  postVerifyOptinPowers(body: PostAdminVerifyOptinPowersBody) {
    return client.post<PostAdminVerifyOptinPowersResponse>(
      onboardingRelationshipsEndpoints.admin.verifyOptinPowers,
      body,
    );
  },
  getLoaWorkflows() {
    return client.post<PostAdminLoaAllResponse>(
      onboardingRelationshipsEndpoints.admin.loa.all,
    );
  },
  getUserLoaWorkflows(body: PostAdminLoaAllUserBody) {
    return client.post<PostAdminLoaAllUserResponse>(
      onboardingRelationshipsEndpoints.admin.loa.allUser,
      body,
    );
  },
  getAdminRoleAssingments(body: PostAdminRoleassignmentsAllBody) {
    return client.post<PostAdminRoleassignmentsAllResponse>(
      onboardingRelationshipsEndpoints.admin.roleassignments.all,
      body,
    );
  },
  getIrisPowersNew(
    body: PostAdminNewGetIrisPowersCachedBody,
    overrideXIdentification?: string,
  ) {
    return client.post<PostAdminNewGetIrisPowersCachedResponse>(
      onboardingRelationshipsEndpoints.admin.newGetIrisPowersCached,
      body,
      {
        headers: overrideXIdentification
          ? { "x-identification": overrideXIdentification }
          : {},
      },
    );
  },
  attachUser(body: PostUserUsersAttachUserBody) {
    return client.post<PostUserUsersAttachUserResponse>(
      onboardingRelationshipsEndpoints.user.users.attachUser,
      body,
    );
  },
  resendGuarantorsInvite(body: PostAdminGuarantorsResendBody) {
    return client.post(
      onboardingRelationshipsEndpoints.admin.guarantors.resend,
      body,
    );
  },
  getUsersInfo() {
    return client.post<PostAdminCompanyGetByTaxIdResponse>(
      onboardingRelationshipsEndpoints.admin.company.getByTaxId,
    );
  },
  excludeUser(body: PostAdminUsersExcludeUserBody) {
    return client.post(
      onboardingRelationshipsEndpoints.admin.users.excludeUser,
      body,
    );
  },
  getWorkflowDetails(body: PostAdminLoaDetailsBody) {
    return client.post<PostAdminLoaAllResponseItemResponse>(
      onboardingRelationshipsEndpoints.admin.loa.get,
      body,
    );
  },
  removeUser(body: PostAdminLoaDetailsBody) {
    return client.post(
      onboardingRelationshipsEndpoints.loa.legalRepresentative.remove,
      body,
    );
  },
  addUser(body: PostAdminAddUserBody) {
    return client.post(
      onboardingRelationshipsEndpoints.loa.legalRepresentative.add,
      body,
    );
  },
  removeFile(body: PostAdminLoaDetailsBody) {
    return client.post(
      onboardingRelationshipsEndpoints.loa.documents.remove,
      body,
    );
  },
  addFile(file: File, params: PostAdminLoaUploadParams) {
    const dataForm = new FormData();
    dataForm.append("file", file);

    return client.post(
      onboardingRelationshipsEndpoints.loa.documents.upload,
      dataForm,
      { params },
    );
  },
  downloadFile(body: PostAdminLoaDownloadBody) {
    return client.post<Blob>(
      onboardingRelationshipsEndpoints.admin.loa.download,
      body,
      {
        responseType: "blob",
      },
    );
  },
  sendWorkflow(body: PostAdminLoaDetailsBody) {
    return client.post(
      onboardingRelationshipsEndpoints.admin.loa.sendToAnalysis,
      body,
    );
  },
  getRepresentative(params: GetRepresentativeWorkflowParams) {
    return client.get<GetAdminLoadRepresentativeGetRepresentativeResponse>(
      onboardingRelationshipsEndpoints.admin.loa.representative
        .getrepresentative,
      { params },
    );
  },
  configRepresentative(body: GetRepresentativeWorkflowParams) {
    return client.post(
      onboardingRelationshipsEndpoints.admin.loa.configRepresentative,
      body,
    );
  },
  updateRepresentative(body: UpdateReprsentativeBody) {
    return client.patch(
      onboardingRelationshipsEndpoints.loa.legalRepresentative.update,
      body,
    );
  },
  updateDocumentType(body: PostLoaDocumentTypeUpdateBody) {
    return client.post(
      onboardingRelationshipsEndpoints.admin.loa.documentType.update,
      body,
    );
  },
  verifyScripts(body: PostAdminScriptVerifyScriptsBody) {
    return client.post<PostAdminScriptVerifyScriptsResponse>(
      onboardingRelationshipsEndpoints.admin.script.verifyScripts,
      body,
    );
  },
  getMonitoringStatus(body: PostAdminMonitoringGetStatusBody) {
    return client.post<PostAdminMonitoringGetStatusResponse>(
      onboardingRelationshipsEndpoints.admin.monitoring.getStatus,
      body,
    );
  },
  getGuarantorMonitoring() {
    return client.get<GetOdataCompanyPowersGuarantorsResponse>(
      onboardingRelationshipsEndpoints.odata.companyPowers.guarantor,
    );
  },
  getLoaMonitoring() {
    return client.get<GetOdataCompanyPowersLetterofattorneyResponse>(
      onboardingRelationshipsEndpoints.odata.companyPowers.letterofattorney,
    );
  },
  getEloaMonitoring() {
    return client.get<GetOdataCompanyPowersRoleassignmentResponse>(
      onboardingRelationshipsEndpoints.odata.companyPowers.roleassignment,
    );
  },
  getLoaRepresentatives() {
    return client.get<GetOdataCompanyPowersLetterofattorneyRepresentativeResponse>(
      onboardingRelationshipsEndpoints.odata.companyPowers
        .letterofattorneyRepresentative,
    );
  },
  getProfileAllProduct() {
    return client.get<GetProfileAllProductResponse>(
      onboardingRelationshipsEndpoints.profile.product.all,
    );
  },
  createWorkflow() {
    return client.post(onboardingRelationshipsEndpoints.admin.loa.create.grant);
  },
  removeWorkflow(body: PostAdminLoaRemoveBody) {
    return client.post(onboardingRelationshipsEndpoints.admin.loa.remove, body);
  },
};
