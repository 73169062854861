import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { eCNPJsGrid } from "./e-cnpjs.grid";

export const ECNPJs = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Certificados e-CNPJ" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
      actions={
        <RouterButton
          href={corporateRouter.routes.emprestimos.product.eCNPJs.create.path}
          role={roles.emprestimos.product.eCNPJsCreateECNPJPage.role}
        >
          Registrar
        </RouterButton>
      }
    >
      <ODataGridGenerator gridRef={gridRef} grid={eCNPJsGrid(gridRef)} />
    </GridTemplate>
  );
};
