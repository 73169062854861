import { EmbeddedContainer, EmbeddedPortal } from "components/portal-embeded";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWalletDefense } from "./wallet-defense.hook";
import { ContentTemplate } from "templates/content-template";
import { DefaultErrorComponent } from "components/empty-state";

export const WalletDefenseManagementPage = () => {
  const navigate = useNavigate();
  const {
    embeddedRoute,
    routeCb,
    partnerIdentification,
    getPartnerByAuthOperator,
    loading,
    hasError,
  } = useWalletDefense(null);

  useEffect(() => {
    return function cleanup() {
      window.removeEventListener("message", routeCb);
    };
  }, [navigate]);

  useEffect(() => {
    getPartnerByAuthOperator();
  }, [getPartnerByAuthOperator]);

  return (
    <ContentTemplate
      loading={loading || partnerIdentification === undefined}
      errorComponent={
        <DefaultErrorComponent
          title="Não foi possível buscar o originador"
          callback={() => getPartnerByAuthOperator()}
        />
      }
      hasError={hasError}
      value={partnerIdentification}
      render={(value) => (
        <EmbeddedContainer>
          <EmbeddedPortal
            headers={{ "x-identification": value }}
            route={embeddedRoute}
          />
        </EmbeddedContainer>
      )}
    />
  );
};
