import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { service } from "services";
import { OToastManager } from "@maestro/core";
import { Offer } from "./company-offers.type";

export const grid: ODataGridGeneratorConfig<Offer> = {
  datagrid: {
    noDataText: "Nenhum produto encontrado",
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "menu",
      caption: "Menu",
    },
    {
      dataField: "isEnable",
      caption: "Oferta",
      allowEditing: true,
    },
    {
      caption: "Ações",
      cellRender: ({ component, rowIndex }) => (
        <DataGridAction
          actions={[
            {
              label: "Editar",
              icon: { category: "orq", icon: "orq-edit-pencil" },
              onClick: () => {
                component.editRow(rowIndex);
              },
            },
          ]}
        />
      ),
    },
    {
      type: "buttons",
      buttons: [
        {
          hint: "Salvar",
          icon: "save",
          name: "save",
          onClick: (prop) => {
            const identification = sessionStorage.getItem(
              "corporate-portal:selected-customer",
            );

            const data = prop.row?.data;

            if (data && identification) {
              if (data.isEnable) {
                service.clerk
                  .addProductByIdentification(data.code, {
                    identification,
                  })
                  .then(() =>
                    OToastManager.success("Operação realizada com sucesso"),
                  )
                  .catch(() => OToastManager.danger("Erro durante a operação"));
              } else {
                service.clerk
                  .removeProductByIdentification(data.code, {
                    identification,
                  })
                  .then(() =>
                    OToastManager.success("Operação realizada com sucesso"),
                  )
                  .catch(() => OToastManager.danger("Erro durante a operação"));
              }
              return;
            }

            return OToastManager.danger("Erro durante a operação");
          },
        },
        {
          hint: "Cancelar",
          icon: "undo",
          name: "cancel",
        },
      ],
    },
  ],
};
