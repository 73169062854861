import { OProgressBar, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import React from "react";

export interface LineChartProps {
  maxAmount?: number;
  progressAmount?: number;
  progressTitle: string;
  restAmount?: number;
  restTitle: string;
}

const LineChart = ({
  maxAmount,
  progressAmount,
  progressTitle,
  restAmount,
  restTitle,
}: LineChartProps) => {
  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex w-100 mb-2">
        <OProgressBar
          className="w-100"
          value={progressAmount || 0}
          max={maxAmount || 0}
        />
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <OTypography type="dark-80" size="md">
            {masks.currency(progressAmount ?? 0, "R$")}
          </OTypography>
          <OTypography type="dark-40" size="sm">
            {progressTitle}
          </OTypography>
        </div>
        <div className="d-flex flex-column align-items-end">
          <OTypography type="dark-80" size="md">
            {masks.currency(restAmount ?? 0, "R$")}
          </OTypography>
          <OTypography type="dark-40" size="sm">
            {restTitle}
          </OTypography>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default React.memo(LineChart);
