import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { off, on, trigger } from "devextreme/events";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { gridStorage } from "utils/storage";
import { RuleAndActionsGridCell } from "./_compose";
import { actionTextBuilder, ruleTextBuilder } from "./_utils";

export const dataSource =
  dataSourceCustomStoreGenerator<HubEnergy.DiligenceEventRuleResponse>(() =>
    service.hubEnergy
      .getDiligenceEventRules()
      .then(({ data }) => data.response)
      .catch(() => {
        const errorMessage = "Não foi possível buscar as regras de eventos";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

// Reference for drag and dropping between pages: https://supportcenter.devexpress.com/ticket/details/t1113044/datagrid-how-to-reorder-rows-between-pages
// Reference for drag and dropping multiple rows: https://supportcenter.devexpress.com/ticket/details/t892902/datagrid-how-to-drag-and-drop-multiple-rows
export const buildDiligenceEventRulesGrid = (
  getRawData: () => HubEnergy.DiligenceEventRuleResponse[],
): ODataGridGeneratorConfig<HubEnergy.DiligenceEventRuleResponse> => ({
  datagrid: {
    noDataText: "Nenhuma regra de evento",
    filterRow: { visible: true },
    pager: { showPageSizeSelector: true },
    stateStoring: gridStorage("diligenceEventRules"),
    columnAutoWidth: false,
    rowDragging: {
      allowReordering: true,
      onReorder: async ({ component, itemData, toIndex }) => {
        if (!itemData) return;
        const data = getRawData();
        const newData = [...data];
        const visibleRows = component.getVisibleRows();

        const spliceTo = data.findIndex(
          (item) => item.id === visibleRows[toIndex].data.id,
        );
        const spliceFrom = data.findIndex((item) => item.id === itemData.id);

        const selectedRowsData = await component.getSelectedRowsData();
        if (selectedRowsData.length >= 1) {
          // remove from original position
          selectedRowsData.forEach((i) => {
            const index = newData.findIndex((item) => item.id === i.id);
            if (index >= 0) newData.splice(index, 1);
          });
          // put in new position
          const dstIndex =
            spliceFrom <= spliceTo
              ? spliceTo - selectedRowsData.length + 1
              : spliceTo;
          newData.splice(dstIndex, 0, ...selectedRowsData);
        } else {
          newData.splice(spliceFrom, 1);
          newData.splice(spliceTo, 0, itemData);
        }

        try {
          await service.hubEnergy.updateDiligenceEventRulesOrder({
            diligenceEventRuleIds: newData.map((d) => d.id),
          });
        } catch (err) {
          const errorMessage = "Erro ao reordenar eventos";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        } finally {
          component.clearSelection();
          await component.getDataSource().reload();
        }
      },
    },
    onContentReady: (e) => {
      const pageIndicators = e.element.querySelectorAll(".dx-page");
      pageIndicators.forEach((pageIndicator) => {
        off(pageIndicator, "dxdragenter");
        on(pageIndicator, "dxdragenter", () => {
          trigger(pageIndicator, "dxclick");
        });
      });
    },
  },
  columns: [
    {
      role: roles.energia.product.diligenceConfigDiligenceEventsDetails.role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.energia.product.diligenceConfig.diligenceEvents.details.path(
            {
              diligenceEventRuleId: data.id,
            },
          )}
        />
      ),
      width: "auto",
    },
    {
      dataField: "id",
      dataType: "number",
      caption: "Id",
      width: "auto",
    },
    {
      dataType: "string",
      caption: "Regra / Ações",
      cellRender: ({ data }) => <RuleAndActionsGridCell rule={data} />,
      calculateCellValue: (data) =>
        [
          ruleTextBuilder(data),
          ...(data.actions?.map((a) => actionTextBuilder(a)) ?? []),
          ...(data.stopProcessingMoreRules
            ? [" e parar de processar mais regras"]
            : []),
        ].join(" "),
      allowFiltering: true,
      cssClass: "dx-word-wrap",
    },
    {
      dataType: "string",
      caption: "Distribuidoras",
      calculateCellValue: (data) =>
        data.allowedDistributors?.length
          ? data.allowedDistributors.map((ad) => ad.officialName).join(", ")
          : "Todas",
      allowFiltering: true,
      width: "200px",
    },
  ],
  selection: {
    mode: "multiple",
    showCheckBoxesMode: "always",
    selectAllMode: "page",
    allowSelectAll: true,
  },
});
