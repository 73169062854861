import * as yup from "yup";

const phoneValidationSchema: yup.ObjectSchema<ScfDevelopers.CedenteCompletoPhone> =
  yup.object({
    number: yup.string().required("Este campo é obrigatório"),
    type: yup
      .mixed<ScfDevelopers.PhoneType>()
      .required("Este campo é obrigatório"),
  });

const addressValidationSchema: yup.ObjectSchema<ScfDevelopers.CedenteCompletoAddress> =
  yup.object({
    phoneNumber: yup
      .string()
      .defined("Este campo não pode ser indefinido")
      .nullable(),
    contactName: yup
      .string()
      .defined("Este campo não pode ser indefinido")
      .nullable(),
    countryCode: yup.string().required("Este campo é obrigatório"),
    countryName: yup
      .string()
      .defined("Este campo não pode ser indefinido")
      .nullable(),
    cityCode: yup
      .string()
      .defined("Este campo não pode ser indefinido")
      .nullable(),
    cityName: yup
      .string()
      .defined("Este campo não pode ser indefinido")
      .nullable(),
    stateCode: yup
      .string()
      .defined("Este campo não pode ser indefinido")
      .nullable(),
    stateName: yup
      .string()
      .defined("Este campo não pode ser indefinido")
      .nullable(),
    zipCode: yup.string().required("Este campo é obrigatório"),
    street: yup.string().required("Este campo é obrigatório"),
    number: yup
      .string()
      .defined("Este campo não pode ser indefinido")
      .nullable(),
    complement: yup
      .string()
      .defined("Este campo não pode ser indefinido")
      .nullable(),
    type: yup
      .mixed<ScfDevelopers.AddressType>()
      .required("Este campo é obrigatório"),
    neighborhood: yup.string().required("Este campo é obrigatório"),
  });

const emailValidationSchema: yup.ObjectSchema<ScfDevelopers.CedenteCompletoEmail> =
  yup.object({
    email: yup.string().required("Este campo é obrigatório"),
    type: yup
      .mixed<ScfDevelopers.EmailType>()
      .required("Este campo é obrigatório"),
  });

const fileValidationSchema: yup.ObjectSchema<ScfDevelopers.PayerFile> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    url: yup.string().required("Este campo é obrigatório"),
    pageType: yup
      .mixed<ScfDevelopers.FilePageType>()
      .required("Este campo é obrigatório"),
    extension: yup.string().required("Este campo é obrigatório"),
  });

const documentValidationSchema: yup.ObjectSchema<ScfDevelopers.CedenteCompletoDocument> =
  yup.object({
    type: yup
      .mixed<ScfDevelopers.CedenteCompletoDocumentType>()
      .required("Este campo é obrigatório"),
    subType: yup
      .mixed<ScfDevelopers.PayerDocumentSubType | "">()
      .nullable()
      .defined()
      .test({
        name: "subtype-is-required",
        message:
          "É necessário fornecer um sbtype quando documento é biometria ou identidade",
        test: (value, context) => {
          if (["IDENTIFICACAO", "BIOMETRIA"].includes(context.parent.type)) {
            return !!value;
          }
          return true;
        },
      }),
    identificationNumber: yup
      .string()
      .defined("Este campo não pode ser indefinido")
      .nullable(),
    files: yup
      .array(fileValidationSchema)
      .default([])
      .min(1, "Insira ao menos um arquivo")
      .test(
        "complete-file-only",
        "Não devem haver outros tipos de arquivo misturados com um de tipo completo",
        (value) => {
          const completoFiles = value.filter(
            (file) => file.pageType === "COMPLETO",
          );
          return !(
            completoFiles.length > 0 && completoFiles.length !== value.length
          );
        },
      )
      .test(
        "multiple-complete-files",
        "Deve haver apenas um arquivo do tipo completo",
        (value) => {
          const completoFiles = value.filter(
            (file) => file.pageType === "COMPLETO",
          );
          return !(completoFiles.length > 1);
        },
      )
      .test(
        "multi-page-only",
        "Não devem haver outros tipos de arquivo misturados com um de tipo multi página",
        (value) => {
          const multiPaginasFiles = value.filter(
            (file) => file.pageType === "MULTI_PAGINAS",
          );
          return !(
            multiPaginasFiles.length > 0 &&
            multiPaginasFiles.length !== value.length
          );
        },
      )
      .test(
        "single-multi-pages-file",
        "Deve haver mais de um arquivo do tipo multi página",
        (value) => {
          const multiPaginasFiles = value.filter(
            (file) => file.pageType === "MULTI_PAGINAS",
          );
          return !(multiPaginasFiles.length === 1);
        },
      )
      .test("missing-front-file", "Falta frente do documento", (value) => {
        const frenteFiles = value.filter((file) => file.pageType === "FRENTE");
        const versoFiles = value.filter((file) => file.pageType === "VERSO");
        return !(frenteFiles.length === 0 && versoFiles.length > 0);
      })
      .test("missing-back-file", "Falta verso do documento", (value) => {
        const frenteFiles = value.filter((file) => file.pageType === "FRENTE");
        const versoFiles = value.filter((file) => file.pageType === "VERSO");
        return !(frenteFiles.length > 0 && versoFiles.length === 0);
      }),
  });

const representativeValidationSchema: yup.ObjectSchema<ScfDevelopers.CedenteSimplesRepresentativeToUpdate> =
  yup.object({
    taxId: yup.string().required("Este campo é obrigatório"),
    name: yup.string().required("Este campo é obrigatório"),
    phones: yup.array(phoneValidationSchema).default([]),
    addresses: yup.array(addressValidationSchema).default([]),
    emails: yup.array(emailValidationSchema).default([]),
    document: documentValidationSchema,
  });

export const editCedenteCompletoValidationSchema: yup.ObjectSchema<ScfDevelopers.UpdateCedenteCompleto> =
  yup.object({
    authClientId: yup.string().required("Este campo é obrigatório"),
    taxId: yup.string().required("Este campo é obrigatório"),
    name: yup.string().required("Este campo é obrigatório"),
    payerName: yup.string().required("Este campo é obrigatório"),
    payerTaxId: yup.string().required("Este campo é obrigatório"),
    status: yup.string().required("Este campo é obrigatório"),
    birthDate: yup.string().required("Este campo é obrigatório"),
    phones: yup.array(phoneValidationSchema).default([]),
    addresses: yup.array(addressValidationSchema).default([]),
    emails: yup.array(emailValidationSchema).default([]),
    documents: yup.array(documentValidationSchema).default([]),
    representatives: yup.array(representativeValidationSchema).default([]),
    workflowConfigurationId: yup.string().required("Este campo é obrigatório"),
  });

export const editeCedenteCompletoDefaultValues: ScfDevelopers.UpdateCedenteCompleto =
  {
    authClientId: "",
    taxId: "",
    name: "",
    payerName: "",
    payerTaxId: "",
    status: "",
    birthDate: "",
    phones: [],
    addresses: [],
    emails: [],
    documents: [],
    representatives: [],
    workflowConfigurationId: "",
  };
