import { env } from "utils/environments";

export const clerkEndpoints = {
  admin: {
    odata: {
      desks: {
        allUsers: {
          self: `${env.API_CLERK}/api/admin/odata/desks/all_users/self`,
        },
        b2bUsers: {
          self: `${env.API_CLERK}/api/admin/odata/desks/B2B_users/self`,
        },
        companies: {
          accountPf: {
            self: `${env.API_CLERK}/api/admin/odata/desks/companies/account_pf/self`,
          },
          accountPj: {
            self: `${env.API_CLERK}/api/admin/odata/desks/companies/account_pj/self`,
          },
          assignor: {
            self: `${env.API_CLERK}/api/admin/odata/desks/companies/assignor/self`,
          },
          energy: {
            self: `${env.API_CLERK}/api/admin/odata/desks/companies/energy/self`,
          },
          noOnboarding: {
            self: `${env.API_CLERK}/api/admin/odata/desks/companies/no_onboarding/self`,
          },
          payer: {
            self: `${env.API_CLERK}/api/admin/odata/desks/companies/payer/self`,
          },
          desks: {
            self: `${env.API_CLERK}/api/admin/odata/desks/companies/desks/self`,
          },
        },
        reviewWorkflow: {
          self: `${env.API_CLERK}/api/admin/odata/desks/review_workflow/self`,
        },
        dataUpdateWorkflow: {
          self: `${env.API_CLERK}/api/admin/odata/desks/data_update/self`,
        },
      },
    },
  },
  adminCustomer: {
    getCompanyDetails: `${env.API_CLERK}/api/admin/companies/get_by_tax_id`,
    getCompanyUsers: `${env.API_CLERK}/api/salesforce/get_company_users`,
    users: `${env.API_CLERK}/api/salesforce/users`,
  },
  bankAccounts: {
    getBankAccount: `${env.API_CLERK}/api/bank_accounts`,
  },
  companies: {
    getByTaxId: `${env.API_CLERK}/api/companies/get_by_tax_id`,
  },
  customerPlans: {
    getFromCompany: `${env.API_CLERK}/api/customer_plans/get_from_company`,
  },
  desks: {
    endpoint: `${env.API_CLERK}/api/desks`,
    getEntityDesks: `${env.API_CLERK}/api/desks/get_entity_desks`,
    upsertEntity: `${env.API_CLERK}/api/desks/upsert_entity`,
    removeDesksFromEntity: `${env.API_CLERK}/api/desks/remove_desks_from_entity`,
  },
  entities: {
    sendUpdatedMessage: `${env.API_CLERK}/api/entities/send_updated_message`,
  },
  entitiesRelationship: {
    byUserId: {
      byCompanyId: (userId: string, companyId: string) =>
        `${env.API_CLERK}/api/entities_relationships/${userId}/${companyId}`,
    },
  },
  groups: {
    endpoint: `${env.API_CLERK}/api/groups`,
  },
  metrics: {
    getAll: `${env.API_CLERK}/api/metrics/get_all`,
    getMetricsByIdentifiers: `${env.API_CLERK}/api/metrics/get_metrics_by_identifiers`,
    getByRoles: `${env.API_CLERK}/api/metrics/get_by_roles`,
  },
  pmeAccounts: `${env.API_CLERK}/api/pme_accounts`,
  powerOfAttorney: {
    isEloaAvailable: `${env.API_CLERK}/api/power_of_attorney/is_eloa_available`,
  },
  searchEngine: {
    entity: `${env.API_CLERK}/api/search_engine/entity`,
  },
  strategies: {
    byId: {
      byGuid: (id: string, guid: string) =>
        `${env.API_CLERK}/api/strategies/${id}/${guid}`,
      endpoint: (id: string) => `${env.API_CLERK}/api/strategies/${id}`,
    },
    endpoint: `${env.API_CLERK}/api/strategies`,
    entity: {
      byId: (id: number) => `${env.API_CLERK}/api/strategies/entity/${id}`,
    },
  },
  systems: {
    byName: (name: string) => `${env.API_CLERK}/api/systems/${name}`,
    endpoint: `${env.API_CLERK}/api/systems`,
  },
  users: {
    completeSelf: `${env.API_CLERK}/api/users/complete_self`,
    getByTaxId: `${env.API_CLERK}/api/users/get_by_tax_id`,
    updateByEmail: `${env.API_CLERK}/api/users/update_by_email`,
  },
  customerInvites: {
    getCompanyWorkflows: `${env.API_CLERK}/api/customer_invites/get_company_workflows`,
    getWorkflowDetails: `${env.API_CLERK}/api/customer_invites/get_workflow_details`,
    getWorkflowEvents: `${env.API_CLERK}/api/customer_invites/get_workflow_events`,
    updateWorkflow: `${env.API_CLERK}/api/customer_invites/update_workflow`,
    resendWorkflowNotification: `${env.API_CLERK}/api/customer_invites/resend_workflow_notification`,
  },
  ddis: {
    getDdis: `${env.API_CLERK}/api/ddis`,
  },
  emailConfigs: {
    endpoint: `${env.API_CLERK}/api/email_configs`,
    getById: `${env.API_CLERK}/api/email_configs/get_by_id`,
    create: `${env.API_CLERK}/api/email_configs/create`,
    updateById: `${env.API_CLERK}/api/email_configs/update_by_id`,
    deleteById: `${env.API_CLERK}/api/email_configs/delete_by_id`,
  },
  emailConfigsType: {
    endpoint: `${env.API_CLERK}/api/email_configs_type`,
    getById: `${env.API_CLERK}/api/email_configs_type/get_by_id`,
    create: `${env.API_CLERK}/api/email_configs_type/create`,
    updateById: `${env.API_CLERK}/api/email_configs_type/update_by_id`,
    deleteById: `${env.API_CLERK}/api/email_configs_type/delete_by_id`,
  },
  franchise: {
    endpoint: `${env.API_CLERK}/api/franchise`,
    byId: (id: number) => `${env.API_CLERK}/api/franchise/${id}`,
    odata: `${env.API_CLERK}/api/franchise/odata`,
  },
  products: {
    endpoint: `${env.API_CLERK}/api/products`,
    byId: (id: string) => `${env.API_CLERK}/api/products/${id}`,
    createByIdentification: (id: number) =>
      `${env.API_CLERK}/api/products/create_by_identification/${id}`,
    deleteByIdentification: (id: number) =>
      `${env.API_CLERK}/api/products/delete_by_identification/${id}`,
  },
  getBankingClerk: `${env.API_CLERK}/api/bank_accounts`,
};
