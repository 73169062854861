import { useParams } from "react-router-dom";
import { GuarantorsSetupProvider } from "../../../../../offers-guarantors-hook.component";
import { productMap } from "../../../../../offers.utils";
import { SimulationProvider } from "../../../../../compose/simulation/simulation-form/simulation.context";

export const Simulation = () => {
  const { type } = useParams();

  return (
    <GuarantorsSetupProvider>
      <SimulationProvider>
        {type &&
          productMap[type.toUpperCase()].simulation.component(
            productMap[type.toUpperCase()],
          )}
      </SimulationProvider>
    </GuarantorsSetupProvider>
  );
};
