import { FormTemplate } from "templates/form-template";
import { PageTitle } from "components/page-title";
import { LoadingButton } from "components/loading-button";
import { useAddSettlementStrategy } from "./add-settlement-strategy.hook";
import { SettlementStrategiesForm } from "../_compose/formulario";

export const AddSettlementStrategyClient = () => {
  const { form, isConsolidated, loading, onSubmit, setIsConsolidated } =
    useAddSettlementStrategy();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Nova estratégia de liquidação" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="adicionar_estrategia_liquidacao:botao:salvar"
          dataLabel="salvar"
          onClick={() => onSubmit()}
        >
          Salvar
        </LoadingButton>
      }
    >
      <SettlementStrategiesForm
        form={form}
        isConsolidated={isConsolidated}
        setIsConsolidated={setIsConsolidated}
      />
    </FormTemplate>
  );
};
