import { ORFieldInput, ORFieldInputDate } from "@maestro/react";
import { DocumentUpload } from "../../../../../../../../../../../../components/document-upload";
import { AdequacyOptionSelector } from "./adequacy-option";
import { BillsField } from "./bills-field";
import { BooleanField } from "./boolean-field";
import { MaskFieldsMapProps } from "./mask-fields-map.types";

export const maskFieldsMap: Record<
  HubEnergy.EDiligenceStepPropertyMask,
  (props: MaskFieldsMapProps) => JSX.Element
> = {
  ADEQUACY_OPTION: ({ property }) => (
    <AdequacyOptionSelector id={`adequacyOption-${property.id}`} />
  ),
  BOOLEAN: BooleanField,
  DOCUMENT: ({ property }) =>
    property?.document.type.name === "FATURA_ENERGIA" ? (
      <BillsField property={property} />
    ) : (
      <DocumentUpload
        id={`value-${property.id}`}
        name="files"
        documentId={property.document.id}
      />
    ),
  DATE: ({ property }) => (
    <ORFieldInputDate
      id={`value-${property.id}`}
      name="value"
      label={property.type.label}
      placeholder="Preencher"
    />
  ),
  CNPJ: ({ property }) => (
    <ORFieldInput
      tag="cnpj"
      id={`value-${property.id}`}
      name="value"
      label={property.type.label}
      placeholder="Preencher"
    />
  ),
  CPF: ({ property }) => (
    <ORFieldInput
      tag="cpf"
      id={`value-${property.id}`}
      name="value"
      label={property.type.label}
      placeholder="Preencher"
    />
  ),
  NUMBER: ({ property }) => (
    <ORFieldInput
      tag="number"
      id={`value-${property.id}`}
      name="value"
      label={property.type.label}
      placeholder="Preencher"
    />
  ),
  TEXT: ({ property }) => (
    <ORFieldInput
      tag="text"
      id={`value-${property.id}`}
      name="value"
      label={property.type.label}
      placeholder="Preencher"
    />
  ),
  PASSWORD: ({ property }) => (
    <ORFieldInput
      tag="text"
      id={`value-${property.id}`}
      name="value"
      label={property.type.label}
      placeholder="Preencher"
    />
  ),
  CURRENCY: ({ property }) => (
    <ORFieldInput
      tag="currency"
      id={`value-${property.id}`}
      name="value"
      label={property.type.label}
      placeholder="Preencher"
    />
  ),
  PERCENTAGE: ({ property }) => (
    <ORFieldInput
      tag="percentage"
      id={`value-${property.id}`}
      name="value"
      label={property.type.label}
      placeholder="Preencher"
    />
  ),
  PHONE: ({ property }) => (
    <ORFieldInput
      tag="phone"
      id={`value-${property.id}`}
      name="value"
      label={property.type.label}
      placeholder="Preencher"
    />
  ),
  TEXTAREA: ({ property }) => (
    <ORFieldInput
      tag="textarea"
      id={`value-${property.id}`}
      name="value"
      label={property.type.label}
      placeholder="Preencher"
      adaptHeight
    />
  ),
};
