import { Roles } from "roles/roles.types";

export const duplicatasRoles = {
  product: {
    tab: {
      role: "admin:DuplicatasTabProduct.View",
      description: "Visualização da tab 'Duplicatas' na visão produto",
    },
    walletsPage: {
      role: "admin:WalletsPageProduct.View",
      description:
        "Visualização da página 'Duplicatas - Carteiras' na visão produto",
    },
    walletDefenseManagementPage: {
      role: "admin:WalletDefenseManagementPageProduct.View",
      description:
        "Visualização da página 'Duplicatas - Gestão de defesas' na visão produto",
    },
    walletDetailsPage: {
      role: "admin:WalletDetailsPageProduct.View",
      description:
        "Visualização da página 'Carteira - Detalhes' na visão produto",
    },
  },
  customer: {
    tab: {
      role: "admin:DuplicatasTabProduct.View",
      description: "Visualização da tab 'Duplicatas' na visão cliente",
    },
    walletDetailsPage: {
      role: "admin:WalletDetailsPageProduct.View",
      description:
        "Visualização da página 'Carteira - Detalhes' na visão cliente",
    },
    hiringPage: {
      role: "admin:DuplicatasHiringTab.View",
      description: "Visualização da página 'Contratação' na visão cliente",
    },
    notesPage: {
      role: "admin:DuplicatasNotesTab.View",
      description: "Visualização da página 'Nota a nota' na visão cliente",
    },
  },
} satisfies Roles;
