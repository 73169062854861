import { modalManager } from "@maestro/react";
import { ManageColumnsModalId } from "../manage-columns-modal/manage-columns-modal.types";
import { DropdownAction, GridActionsProps } from "./grid-actions.types";

export const assembleDropdownActions = ({
  customActions,
  manageColumns,
  downloadAction,
  refreshAction,
}: GridActionsProps): DropdownAction[] => {
  const actions: DropdownAction[] = [];

  if (downloadAction) {
    const downloadLabel = downloadAction.label ?? "Baixar tabela";

    actions.push({
      icon: { icon: "orq-download", category: "orq", type: "dark", size: "xl" },
      text: downloadLabel,
      textType: "dark",
      onClick: downloadAction.onClick,
    });
  }

  if (manageColumns) {
    const manageColumnsLabel = "Organizar colunas";

    actions.push({
      icon: { icon: "orq-table", category: "orq", type: "dark", size: "xl" },
      text: manageColumnsLabel,
      textType: "dark",
      onClick: () => modalManager.show(ManageColumnsModalId),
    });
  }

  if (refreshAction) {
    const refreshLabel = refreshAction.label ?? "Atualizar dados";

    actions.push({
      icon: { icon: "orq-refresh", category: "orq", type: "dark", size: "xl" },
      text: refreshLabel,
      textType: "dark",
      onClick: refreshAction.onClick,
    });
  }

  customActions?.forEach(({ label, onClick, icon, visible }) => {
    if (visible) {
      actions.push({
        ...(!!icon && { icon: { ...icon, type: "dark", size: "xl" } }),
        text: label,
        textType: "dark",
        onClick,
      });
    }
  });

  return actions;
};
