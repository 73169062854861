import * as yup from "yup";

interface BaseWorkflowConfigurationFormField {
  stepId: string;
  stepType: ScfDevelopers.WorkflowStepType;
  nextStepId: string | null;
  isStepActive: string[];
  _t: string;
}

interface BCheckComplementCedenteSimplesWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "CEDENTE_SIMPLES_COMPLEMENT_VALIDATE";
}

interface LessCedenteSimplesWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "CEDENTE_SIMPLES_LESS";
  partyGroupTypeIds: number[];
  segment: string;
  accountType: string | null;
  mainOfficerCGE: number;
  mainOfficerName: string | null;
  skipValidationAlreadyIfExistsPartyWithRelationship: string[];
}

interface SyncClerkCedenteSimplesWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "CEDENTE_SIMPLES_CLERK";
}

interface BCheckComplementorTomadorDeCreditoWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "TOMADOR_CREDITO_COMPLEMENT_VALIDATE";
}

interface FraudSysTomadorDeCreditoWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "TOMADOR_CREDITO_RISKANALYSIS";
  segment: string;
  coSegment: string;
  riskAnalysisType: string;
}

interface LessTomadorDeCreditoWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "TOMADOR_CREDITO_LESS";
  partyGroupTypeIds: number[];
  segment: string;
  accountType: string | null;
  mainOfficerCGE: number;
  mainOfficerName: string | null;
  skipValidationAlreadyIfExistsPartyWithRelationship: string[];
}

interface SyncClerkTomadorDeCreditoWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "TOMADOR_CREDITO_CLERK";
}

interface WebhookViaDevelopersTomadorDeCreditoWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "TOMADOR_CREDITO_WEBHOOK_DEVELOPERS";
}

export type CedenteSimplesConfigurationFormField =
  | BCheckComplementCedenteSimplesWorkflowConfigurationFormField
  | LessCedenteSimplesWorkflowConfigurationFormField
  | SyncClerkCedenteSimplesWorkflowConfigurationFormField;

export type TomardorCreditoConfigurationFormField =
  | BCheckComplementorTomadorDeCreditoWorkflowConfigurationFormField
  | FraudSysTomadorDeCreditoWorkflowConfigurationFormField
  | LessTomadorDeCreditoWorkflowConfigurationFormField
  | SyncClerkTomadorDeCreditoWorkflowConfigurationFormField
  | WebhookViaDevelopersTomadorDeCreditoWorkflowConfigurationFormField;

export type WorkflowConfigurationFormField =
  | CedenteSimplesConfigurationFormField
  | TomardorCreditoConfigurationFormField;

export interface WorkflowScfFormFields {
  allowedReprocessStatuses: string[];
  authClientId: string;
  configurationId?: string;
  configurationName: string;
  configurationDisplayName: string;
  configurations: WorkflowConfigurationFormField[];
  desks: number[];
  integrationType: ScfDevelopers.IntegrationType;
  isFidc: string[];
  mainDesk: number;
  partnerId: number;
  partnerName: string;
  requiredOnboardingDocuments: ScfDevelopers.PayerDocumentType[];
  syncConfigurations: WorkflowConfigurationFormField[];
  requestRequiredFields: string[];
}

const hasWhiteSpace = (str: string) => {
  return /\s/g.test(str);
};

const hasHash = (str: string) => {
  return /#/g.test(str);
};

const hasAccent = (str: string) => {
  return /[áéíóúâêîôûãõàç]+/gi.test(str);
};

const validateStringWithoutHash = yup
  .string()
  .required("Campo obrigatório")
  .test(
    "invalidWhitespaceChar",
    "Não deve haver espaço",
    (value) => !hasWhiteSpace(value),
  )
  .test(
    "invalidHashChar",
    "Caractere inválido: '#'",
    (value) => !hasHash(value),
  )
  .test(
    "InvalidAccentChar",
    "Caractere não deve possuir acento",
    (value) => !hasAccent(value),
  );

const baseWorkflowConfigurationValidationSchema: yup.ObjectSchema<BaseWorkflowConfigurationFormField> =
  yup.object({
    stepId: yup.string().required(),
    stepType: yup.mixed<ScfDevelopers.WorkflowStepType>().defined(),
    nextStepId: yup.string().required().nullable(),
    isStepActive: yup.array(yup.string().required()).default([]),
    _t: yup.string().required("Este campo é obrigatório"),
  });

const bCheckComplementCedenteSimplesWorkflowConfigurationValidationSchema: yup.ObjectSchema<BCheckComplementCedenteSimplesWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"CEDENTE_SIMPLES_COMPLEMENT_VALIDATE">().required(),
  });

const lessCedenteSimplesWorkflowConfigurationValidationSchema: yup.ObjectSchema<LessCedenteSimplesWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"CEDENTE_SIMPLES_LESS">().required(),
    partyGroupTypeIds: yup
      .array()
      .required("Este campo é obrigatório")
      .default([]),
    segment: yup.string().required("Este campo é obrigatório"),
    accountType: yup.string().nullable().defined(),
    mainOfficerCGE: yup
      .number()
      .typeError("Deve ser um Id numérico")
      .required("Este campo é obrigatório"),
    mainOfficerName: yup.string().nullable().defined(),
    skipValidationAlreadyIfExistsPartyWithRelationship: yup
      .array(yup.string().required())
      .default([]),
  });

const syncClerkCedenteSimplesWorkflowConfigurationValidationSchema: yup.ObjectSchema<SyncClerkCedenteSimplesWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"CEDENTE_SIMPLES_CLERK">().required(),
  });

const bCheckComplementorTomadorDeCreditoWorkflowConfigurationValidationSchema: yup.ObjectSchema<BCheckComplementorTomadorDeCreditoWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"TOMADOR_CREDITO_COMPLEMENT_VALIDATE">().required(),
  });

const fraudSysTomadorDeCreditoWorkflowConfigurationValidationSchema: yup.ObjectSchema<FraudSysTomadorDeCreditoWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"TOMADOR_CREDITO_RISKANALYSIS">().required(),
    segment: yup.string().required("Este campo é obrigatório"),
    coSegment: yup.string().required("Este campo é obrigatório"),
    riskAnalysisType: yup.string().required("Este campo é obrigatório"),
  });

const lessTomadorDeCreditoWorkflowConfigurationValidationSchema: yup.ObjectSchema<LessTomadorDeCreditoWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"TOMADOR_CREDITO_LESS">().required(),
    partyGroupTypeIds: yup
      .array()
      .required("Este campo é obrigatório")
      .default([]),
    segment: yup.string().required("Este campo é obrigatório"),
    accountType: yup.string().nullable().defined(),
    mainOfficerCGE: yup
      .number()
      .typeError("Deve ser um Id numérico")
      .required("Este campo é obrigatório"),
    mainOfficerName: yup.string().nullable().defined(),
    skipValidationAlreadyIfExistsPartyWithRelationship: yup
      .array(yup.string().required())
      .default([]),
  });

const syncClerkTomadorDeCreditoWorkflowConfigurationValidationSchema: yup.ObjectSchema<SyncClerkTomadorDeCreditoWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"TOMADOR_CREDITO_CLERK">().required(),
  });

const webhookViaDevelopersTomadorDeCreditoWorkflowConfigurationValidationSchema: yup.ObjectSchema<WebhookViaDevelopersTomadorDeCreditoWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"TOMADOR_CREDITO_WEBHOOK_DEVELOPERS">().required(),
  });

const workflowConfigurationSchemaMapper = yup.lazy(
  (value: WorkflowConfigurationFormField) => {
    if (value.stepType === "CEDENTE_SIMPLES_COMPLEMENT_VALIDATE") {
      return bCheckComplementCedenteSimplesWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "CEDENTE_SIMPLES_LESS") {
      return lessCedenteSimplesWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "CEDENTE_SIMPLES_CLERK") {
      return syncClerkCedenteSimplesWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "TOMADOR_CREDITO_COMPLEMENT_VALIDATE") {
      return bCheckComplementorTomadorDeCreditoWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "TOMADOR_CREDITO_RISKANALYSIS") {
      return fraudSysTomadorDeCreditoWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "TOMADOR_CREDITO_LESS") {
      return lessTomadorDeCreditoWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "TOMADOR_CREDITO_CLERK") {
      return syncClerkTomadorDeCreditoWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "TOMADOR_CREDITO_WEBHOOK_DEVELOPERS") {
      return webhookViaDevelopersTomadorDeCreditoWorkflowConfigurationValidationSchema;
    }
    return yup.mixed<WorkflowConfigurationFormField>().required();
  },
);

const configurationsWorkflowConfigurationValidationSchema = yup
  .array()
  .of(workflowConfigurationSchemaMapper)
  .default([]);

export const workflowScfValidationSchema: yup.ObjectSchema<WorkflowScfFormFields> =
  yup.object({
    allowedReprocessStatuses: yup.array(yup.string().required()).default([]),
    authClientId: yup.string().required("Campo obrigatório"),
    configurationId: yup.string(),
    configurationName: validateStringWithoutHash,
    configurationDisplayName: yup.string().required("Campo obrigatório"),
    configurations: configurationsWorkflowConfigurationValidationSchema,
    desks: yup
      .array(
        yup.number().typeError("Mesas devem possuir ids numéricos").required(),
      )
      .default([])
      .min(1, "Deve inserir ao menos uma mesa"),
    integrationType: yup.mixed<ScfDevelopers.IntegrationType>().required(),
    isFidc: yup.array(yup.string().required()).default([]),
    mainDesk: yup
      .number()
      .typeError("Mesa deve possuir id numérico")
      .required("Selecione uma mesa")
      .test("choosesADesk", "Deve ser uma das mesas", (value, context) => {
        return value && context.parent.desks?.includes(value);
      }),
    partnerId: yup
      .number()
      .typeError("Digite um valor numérico")
      .required("Campo obrigatório"),
    partnerName: validateStringWithoutHash,
    requiredOnboardingDocuments: yup
      .array(yup.mixed<ScfDevelopers.PayerDocumentType>().required())
      .default([]),
    requestRequiredFields: yup
      .array()
      .transform((value) => (Array.isArray(value) ? value : []))
      .optional()
      .default([]),
    syncConfigurations: configurationsWorkflowConfigurationValidationSchema,
  });

const BCheckComplementCedenteSimplesWorkflowConfigurationDefaultValues: BCheckComplementCedenteSimplesWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "CEDENTE_SIMPLES_COMPLEMENT_VALIDATE",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "BCheckComplementCedenteSimplesWorkflowConfigurationStep",
  };

const LessCedenteSimplesWorkflowConfigurationDefaultValues: LessCedenteSimplesWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "CEDENTE_SIMPLES_LESS",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "LessCedenteSimplesWorkflowConfigurationStep",
    partyGroupTypeIds: [],
    segment: "",
    accountType: "",
    mainOfficerCGE: 0,
    mainOfficerName: "",
    skipValidationAlreadyIfExistsPartyWithRelationship: [],
  };

const SyncClerkCedenteSimplesWorkflowConfigurationDefaultValues: SyncClerkCedenteSimplesWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "CEDENTE_SIMPLES_CLERK",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "SyncClerkCedenteSimplesWorkflowConfigurationStep",
  };

const BCheckComplementorTomadorDeCreditoWorkflowConfigurationDefaultValues: BCheckComplementorTomadorDeCreditoWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "TOMADOR_CREDITO_COMPLEMENT_VALIDATE",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "BCheckComplementorTomadorDeCreditoWorkflowConfigurationStep",
  };

const FraudSysTomadorDeCreditoWorkflowConfigurationDefaultValues: FraudSysTomadorDeCreditoWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "TOMADOR_CREDITO_RISKANALYSIS",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "FraudSysTomadorDeCreditoWorkflowConfigurationStep",
    segment: "",
    coSegment: "",
    riskAnalysisType: "",
  };

const LessTomadorDeCreditoWorkflowConfigurationDefaultValues: LessTomadorDeCreditoWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "TOMADOR_CREDITO_LESS",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "LessTomadorDeCreditoWorkflowConfigurationStep",
    partyGroupTypeIds: [],
    segment: "",
    accountType: "",
    mainOfficerCGE: 0,
    mainOfficerName: "",
    skipValidationAlreadyIfExistsPartyWithRelationship: [],
  };

const SyncClerkTomadorDeCreditoWorkflowConfigurationDefaultValues: SyncClerkTomadorDeCreditoWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "TOMADOR_CREDITO_CLERK",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "SyncClerkTomadorDeCreditoWorkflowConfigurationStep",
  };

const WebhookViaDevelopersTomadorDeCreditoWorkflowConfigurationDefaultValues: WebhookViaDevelopersTomadorDeCreditoWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "TOMADOR_CREDITO_WEBHOOK_DEVELOPERS",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "WebhookViaDevelopersTomadorDeCreditoWorkflowConfigurationStep",
  };

export const workflowConfigurationDefaultValues: Record<
  ScfDevelopers.WorkflowStepType,
  WorkflowConfigurationFormField
> = {
  CEDENTE_SIMPLES_COMPLEMENT_VALIDATE:
    BCheckComplementCedenteSimplesWorkflowConfigurationDefaultValues,
  CEDENTE_SIMPLES_LESS: LessCedenteSimplesWorkflowConfigurationDefaultValues,
  CEDENTE_SIMPLES_CLERK:
    SyncClerkCedenteSimplesWorkflowConfigurationDefaultValues,
  TOMADOR_CREDITO_COMPLEMENT_VALIDATE:
    BCheckComplementorTomadorDeCreditoWorkflowConfigurationDefaultValues,
  TOMADOR_CREDITO_RISKANALYSIS:
    FraudSysTomadorDeCreditoWorkflowConfigurationDefaultValues,
  TOMADOR_CREDITO_LESS: LessTomadorDeCreditoWorkflowConfigurationDefaultValues,
  TOMADOR_CREDITO_CLERK:
    SyncClerkTomadorDeCreditoWorkflowConfigurationDefaultValues,
  TOMADOR_CREDITO_WEBHOOK_DEVELOPERS:
    WebhookViaDevelopersTomadorDeCreditoWorkflowConfigurationDefaultValues,
};
