import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { customer } from "contexts/customer";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import { PostB2BOriginatorUpdateMaxLimitBody } from "services/hubcreditmanager/models/requests";
import { PostAdminLimitsGetLimitResponse } from "services/hubcreditmanager/models/responses";
import { useServiceCall } from "hooks/service-call";
import {
  updateOriginatorCreditLimitValidationSchema,
  updateOriginatorCreditLimitDefaultValues,
} from "./manage-originator-credit-limit.form";

export const useManageOriginatorCreditLimit = () => {
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [creditManagerOriginatorLimit, setCreditManagerOriginatorLimit] =
    useState<PostAdminLimitsGetLimitResponse>();
  const [isEnableCreditForManagement, setIsEnableCreditForManagement] =
    useState(false);
  const [doneFetching, setDoneFetching] = useState(false);
  const [isOriginatorNotFoundError, setIsOriginatorNotFoundError] =
    useState(false);

  const form = useForm({
    resolver: yupResolver(updateOriginatorCreditLimitValidationSchema),
    defaultValues: updateOriginatorCreditLimitDefaultValues,
  });
  const { handleSubmit } = form;

  const onSubmit = handleSubmit(async (values) => {
    if (!customer.value?.identification) {
      OToastManager.danger({
        title: "Ocorreu um erro",
        message: "Originador não encontrado.",
      });
      throw new Error("Originador não encontrado.");
    }

    const payload: PostB2BOriginatorUpdateMaxLimitBody = {
      cnpj: customer.value.identification,
      lastModifiedAt: new Date().toISOString(),
      limit: values.newLimit,
    };

    setLoadingSubmit(true);
    try {
      await service.hubCreditManager.updateB2BOriginatorMaxLimit(payload);
      OToastManager.success(
        "Limite de crédito do Originador atualizado com sucesso!",
      );
    } catch (err) {
      OToastManager.danger(
        "Não foi possível atualizar o limite do originador.",
      );
    } finally {
      setLoadingSubmit(false);
    }
  });

  const fetchOriginatorCreditLimit = useCallback(async () => {
    if (!isEnableCreditForManagement) return;

    const originatorCNPJ = customer.value?.identification;
    if (!originatorCNPJ) return;

    setLoading(true);

    try {
      const { data } = await service.hubCreditManager.getLimit({
        identification: "ORIGINACAO_SOLAR",
        taxId: originatorCNPJ,
        companyTaxId: "",
        userEmail: "",
        originSystem: "",
        originIP: "",
      });
      setCreditManagerOriginatorLimit(data);
      setDoneFetching(true);
    } catch {
      OToastManager.danger("Falha ao obter o crédito do originador");
      setDoneFetching(true);
    } finally {
      setLoading(false);
      setDoneFetching(true);
    }
  }, [isEnableCreditForManagement]);

  useEffect(() => {
    fetchOriginatorCreditLimit();
  }, [fetchOriginatorCreditLimit]);

  const {
    loading: originatorDetailsLoading,
    value: originatorDetails,
    callService: getOriginatorDetails,
    hasError,
  } = useServiceCall(service.hubPartners.originatorDetails);

  const handleError = (error: {
    response?: { data?: { failures?: { errorCode?: string }[] } };
  }) => {
    return !!error?.response?.data?.failures?.find(
      (x) => x?.errorCode === "OriginatorNotFound",
    );
  };

  const fetchOriginatorDetails = useCallback(async () => {
    const customerTaxId = customer.value?.identification;
    if (!customerTaxId) return;

    const { success, error } = await getOriginatorDetails(customerTaxId);
    if (!success) {
      const isNotFound = handleError(
        error as {
          response?: { data?: { failures?: { errorCode?: string }[] } };
        },
      );

      if (isNotFound) {
        setIsOriginatorNotFoundError(true);
      } else {
        OToastManager.danger("Falha ao obter detalhes do originador");
      }
      setDoneFetching(true);
    }
  }, [getOriginatorDetails]);

  useEffect(() => {
    fetchOriginatorDetails();
  }, [fetchOriginatorDetails]);

  const getIsEnableCreditManagement = useCallback(async () => {
    if (!originatorDetails) {
      return;
    }

    setLoading(true);
    try {
      const subtypes = originatorDetails?.data.originatorSubTypes.map(
        (subType) => subType.code,
      );

      const { data } = await service.hubPartners.searchCommonDomain(
        "OriginatorSubtypesWithCreditLimitManagement",
      );

      const allowedOriginatorSubtypes = data.data.value
        .replace(/[[\]']/g, "")
        .split(",");

      const hasTypes = subtypes?.some((subtype) =>
        allowedOriginatorSubtypes.includes(subtype),
      );

      setIsEnableCreditForManagement(!!hasTypes);

      if (!hasTypes) {
        setDoneFetching(true);
      }
    } catch {
      OToastManager.danger("Falha ao obter detalhes do originador");
      setDoneFetching(true);
    } finally {
      setLoading(false);
    }
  }, [originatorDetails]);

  useEffect(() => {
    getIsEnableCreditManagement();
  }, [getIsEnableCreditManagement]);

  return {
    form,
    loading: loading || originatorDetailsLoading,
    loadingSubmit,
    onSubmit,
    creditManagerOriginatorLimit,
    isEnableCreditForManagement,
    doneFetching,
    isOriginatorNotFoundError,
    hasError,
    fetchOriginatorDetails,
  };
};
