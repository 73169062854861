import { OSkeletonCard } from "@maestro/react";
import { OTruncateTypography } from "components/o-truncate-typography";
import { useMemo } from "react";
import { loadNotificationData } from "./collection-communication-detail.utils";

interface CollectionCommunicationDetailProps {
  communication?: BankingHubAdminCollection.CommunicationData;
  isLoading?: boolean;
  showFullDate?: boolean;
}

export const CollectionCommunicationDetail = ({
  communication,
  isLoading,
  showFullDate,
}: CollectionCommunicationDetailProps) => {
  const notificationData = useMemo(
    () => loadNotificationData(communication, showFullDate),
    [communication, showFullDate],
  );

  if (isLoading) {
    return (
      <div className="d-flex gap-2">
        <OSkeletonCard height="1rem" width="4rem" rounded />
        <OSkeletonCard height="1rem" width="14rem" rounded />
      </div>
    );
  }

  if (!communication) return null;

  return (
    <OTruncateTypography
      type="dark"
      key={notificationData}
      title={notificationData}
    >
      {notificationData}
    </OTruncateTypography>
  );
};
