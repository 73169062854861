import { useMemo } from "react";
import { OStep, OStepHeader, OStepsTimeline } from "@maestro/react";
import { useSimulation } from "../../simulation.context";

export const SimulationSteps = () => {
  const { activeStep, currentStepsMap } = useSimulation();

  const decideStepStatus = (index: number) => {
    if (activeStep === index) return "current";

    if (activeStep < index) return "pending";

    return "completed";
  };

  const steps = useMemo(() => {
    return (
      <OStepsTimeline activeStep={activeStep ?? 0}>
        <OStepHeader disableNavigation activeStep={activeStep ?? 0}>
          {currentStepsMap?.map((step, index) => (
            <OStep status={decideStepStatus(index)} key={step.index}>
              {step.title}
            </OStep>
          ))}
        </OStepHeader>
      </OStepsTimeline>
    );
  }, [currentStepsMap, activeStep]);

  return <>{steps}</>;
};
