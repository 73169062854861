import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasRelacionamentoByUuidRouteParams } from "../../../../../../../routes/ferramentas.route-params";
import { DeskConfigPipelineForm } from "../_compose/desk-config-pipeline-form.component";
import {
  DeskConfigPipelineFormValues,
  deskConfigPipelineFormValidationSchema,
} from "../_compose/desk-config-pipeline.form";

export const UpdateDeskConfigPipelinePage = () => {
  const { uuid, configIdentifier } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasRelacionamentoByUuidRouteParams>();
  if (!uuid || !configIdentifier)
    throw new Error("No uuid or configIdentifier");
  const form = useForm<DeskConfigPipelineFormValues>({
    resolver: yupResolver(deskConfigPipelineFormValidationSchema),
  });
  const { reset, handleSubmit } = form;
  const {
    callService: getDeskOnboardingConfigPipeline,
    loading,
    hasError,
    value: deskConfigPipelines,
  } = useServiceCall(service.onboarding.getDeskOnboardingConfigPipeline);
  const { callService: updateDeskConfigPipeline, loading: submitLoading } =
    useServiceCall(service.onboarding.updatePipelineOnDeskOnboardingConfig);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await updateDeskConfigPipeline({
          deskOnboardingConfigPipelineUuid: uuid,
          ...values,
        });

        if (!success) return OToastManager.danger("Erro ao atualizar pipeline");

        return OToastManager.success("Pipeline atualizado com sucesso");
      }),
    [handleSubmit, updateDeskConfigPipeline, uuid],
  );

  useEffect(() => {
    getDeskOnboardingConfigPipeline({
      deskOnboardingConfigIdentifier: configIdentifier,
    });
  }, [configIdentifier, getDeskOnboardingConfigPipeline]);

  useEffect(() => {
    const deskConfigPipeline = deskConfigPipelines?.find(
      (config) => config.uuid === uuid,
    );
    deskConfigPipeline &&
      reset({
        ...deskConfigPipeline,
        deskOnboardingConfigIdentifier:
          deskConfigPipeline.deskOnboardingConfig?.configIdentifier,
        pipelineUuid: deskConfigPipeline.pipeline?.uuid,
        pipelineArgument:
          deskConfigPipeline.pipelineArgument &&
          JSON.stringify(deskConfigPipeline.pipelineArgument),
      });
  }, [deskConfigPipelines, reset, uuid]);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title={`Atualizar pipeline na mesa ${configIdentifier}`} />
      }
      actions={
        <LoadingButton loading={submitLoading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        errorComponent={
          <DefaultErrorComponent
            title="Erro ao buscar pipeline"
            callback={() => getDeskOnboardingConfigPipeline()}
          />
        }
        noValue
        render={() => (
          <FormProvider {...form}>
            <DeskConfigPipelineForm />
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
