import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { RouterButton } from "components/router-button";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services/service";
import { getValueFromMap } from "utils/get-value-from-map";
import { scfDevelopersOnboardingStatusMap } from "./editar/edit-cedente-completo.utils";

export const dataSourceGenerator = (
  pageSize: number,
  pageNumber: number,
  setTotalCount: (totalCount: number) => void,
) =>
  dataSourceCustomStoreGenerator<ScfDevelopers.CedenteCompleto>(() =>
    service.scfDevelopers
      .getAllCedenteCompletos({
        pageSize,
        pageNumber,
      })
      .then(({ data }) => {
        setTotalCount(data.totalItems);
        return {
          data: data.items,
          totalCount: data.totalItems,
        };
      })
      .catch(() => {
        const errorMessage = "Erro ao buscar reprovações";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

const redirectEditLink = (taxId: string) => {
  const baseUrl =
    corporateRouter.routes.empresas.product.cedenteCompleto.edit.path;

  return `${baseUrl}?taxId=${taxId}`;
};

export const cedenteCompletoErrorsGrid: ODataGridGeneratorConfig<ScfDevelopers.CedenteCompleto> =
  {
    datagrid: {
      noDataText: "Nenhum cedente completo com erro.",
      paging: undefined,
    },
    columns: [
      {
        caption: "Tax Id",
        dataField: "taxId",
      },
      {
        caption: "Status",
        dataField: "status",
        cellRender: ({ data }) =>
          getValueFromMap(scfDevelopersOnboardingStatusMap, data.status),
      },
      {
        caption: "Ações",
        cellRender: ({ data }) => (
          <RouterButton href={redirectEditLink(data.taxId)}>
            Resolver pendências
          </RouterButton>
        ),
      },
    ],
  };
