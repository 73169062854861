import {
  OCard,
  OCardBody,
  OCol,
  OLabel,
  OOption,
  ORFieldInput,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORadio,
  ORow,
  OTypography,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";
import {
  amortizationFrequencyCodeMap,
  creditTypeOptions,
  portfolioClassificationTypes,
  ratingTypes,
  spreadDayCountCodeMap,
} from "../utils/loan-agreement.utils";

export interface LoanAgreementFormValues {
  name: string;
  defaultGracePeriod: number;
  maxGracePeriod: number;
  partialLimitConsumeAllowed: boolean;
  paymentDayType: string;
  isIofExempt: boolean;
  iofFinance: boolean;
  hasGuarantee: boolean;
  isSocialImpact: boolean;
  product: string;
  valorizationMethod: string;
  lockDisbursement?: string | null;
  bookCr: number;
  strategyCr: number;
  minimumGuarantors?: number | null;
  maximumLimitForSimpleGuarantor?: number | null;
  minimumParticipationForGuarantor?: number | null;
  settlementAgencyCode: number;
  amortizationFrequencyCode: string;
  spreadDayCountCode: string;
  automaticallyApproves: boolean;
  useInstallmentFlow: boolean;
  minRate?: number | null;
  maxRate?: number | null;
  minAmount?: number | null;
  maxAmount?: number | null;
  minTerm?: number | null;
  maxTerm?: number | null;
  endorserCge?: number | null;
  minPmtAmount?: number | null;
  rating?: string | null;
  sourceSystem?: string | null;
  tradeType?: string | null;
  warrantyDocumentSystem?: string | null;
  additionalDisbursementAccountType?: string | null;
  financeOrigin: string;
  creditType?: string | null;
  portfolioClassification?: string | null;
  ratingCounterparty?: string | null;
  exposureAtDefault?: number | null;
  defaultProbability?: number | null;
  lostGivenDefault?: number | null;
  expectedLoss?: number | null;
  incurredLoss?: number | null;
  stage?: number | null;
}

export const LoanAgreementForm = () => {
  const {
    callService: callServiceSettlement,
    loading: loadingSettlementTypes,
    value: settlementTypes,
  } = useServiceCall(service.hubLoan.getSettlementTypes);

  const {
    callService: callServiceProducts,
    loading: loadingProductTypes,
    value: loanProductOptions,
  } = useServiceCall(service.hubLoan.getProductTypes);

  const {
    callService: callServiceAgreementRating,
    loading: loadingGetAgreementRating,
    value: agreementRating,
  } = useServiceCall(service.hubLoan.getAgreementRating);

  const {
    callService: callServiceAgreementTradeType,
    loading: loadingGetAgreementTradeType,
    value: agreementTradeType,
  } = useServiceCall(service.hubLoan.getAgreementTradeType);

  const {
    callService: callServiceAgreementSourceSystem,
    loading: loadingGetAgreementSourceSystem,
    value: agreementSourceSystem,
  } = useServiceCall(service.hubLoan.getAgreementSourceSystem);

  const {
    callService: callServiceAgreementWarrantyDocumentSystem,
    loading: loadingGetAgreementWarrantyDocumentSystem,
    value: agreementWarrantyDocumentSystem,
  } = useServiceCall(service.hubLoan.getAgreementWarrantyDocumentSystem);

  const {
    callService: callServiceAgreementAdditionalDisbursementAccountType,
    loading: loadingGetAgreementAdditionalDisbursementAccountType,
    value: agreementAdditionalDisbursementAccountType,
  } = useServiceCall(
    service.hubLoan.getAgreementAdditionalDisbursementAccountType,
  );

  const {
    callService: callServiceAgreementFinanceOrigin,
    loading: loadingGetAgreementFinanceOrigin,
    value: agreementFinanceOrigin,
  } = useServiceCall(service.hubLoan.getAgreementFinanceOrigin);

  useEffect(() => {
    callServiceSettlement();
  }, [callServiceSettlement]);

  useEffect(() => {
    callServiceProducts();
  }, [callServiceProducts]);

  useEffect(() => {
    callServiceAgreementRating();
  }, [callServiceAgreementRating]);

  useEffect(() => {
    callServiceAgreementTradeType();
  }, [callServiceAgreementTradeType]);

  useEffect(() => {
    callServiceAgreementSourceSystem();
  }, [callServiceAgreementSourceSystem]);

  useEffect(() => {
    callServiceAgreementWarrantyDocumentSystem();
  }, [callServiceAgreementWarrantyDocumentSystem]);

  useEffect(() => {
    callServiceAgreementAdditionalDisbursementAccountType();
  }, [callServiceAgreementAdditionalDisbursementAccountType]);

  useEffect(() => {
    callServiceAgreementFinanceOrigin();
  }, [callServiceAgreementFinanceOrigin]);

  return (
    <>
      <OCard className="justify-content-between my-3">
        <OCardBody>
          <ORow>
            <OCol sm={8}>
              <ORFieldInput tag="text" id="name" name="name" label="Nome" />
            </OCol>
            <OCol sm={4}>
              <ORFieldSelect
                id="product"
                name="product"
                label="Produto"
                key={loanProductOptions?.map((lpo) => lpo.value).join("-")}
              >
                {loanProductOptions?.map((product) => (
                  <OOption key={product?.value} value={product.value}>
                    {product.text}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
      <OCard className="justify-content-between my-3">
        <OCardBody>
          <ORow>
            <div className="d-flex justify-content-between align-items-center my-2">
              <OTypography size="md">Condições</OTypography>
            </div>
            <OCol sm={4}>
              <ORFieldInput
                tag="number"
                id="minPmtAmount"
                name="minPmtAmount"
                label="Valor mínimo da PMT"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="number"
                id="minAmount"
                name="minAmount"
                label="Valor mínimo"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="number"
                id="maxAmount"
                name="maxAmount"
                label="Valor máximo"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="number"
                id="minTerm"
                name="minTerm"
                label="Prazo mínimo (em meses)"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="number"
                id="maxTerm"
                name="maxTerm"
                label="Prazo máximo (em meses)"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="number"
                id="minRate"
                name="minRate"
                label="Taxa mínima"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="number"
                id="maxRate"
                name="maxRate"
                label="Taxa máxima"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="number"
                id="defaultGracePeriod"
                name="defaultGracePeriod"
                label="Período de carência recomendado (em meses)"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="number"
                id="maxGracePeriod"
                name="maxGracePeriod"
                label="Período de carência máxima (em dias)"
              />
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
      <OCard className="justify-content-between my-3">
        <OCardBody>
          <ORow>
            <div className="d-flex justify-content-between align-items-center my-2">
              <OTypography size="md">Garantias</OTypography>
            </div>
            <OCol sm={4}>
              <ORFieldRadioGroup
                id="hasGuarantee"
                name="hasGuarantee"
                label="Tem garantia?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol sm={4}>
              <ORFieldSelect
                id="warrantyDocumentSystem"
                name="warrantyDocumentSystem"
                label="Sistema de documentos de garantia"
                disabled={loadingGetAgreementWarrantyDocumentSystem}
              >
                {agreementWarrantyDocumentSystem?.map(
                  (warrantyDocumentSystem: string) => (
                    <OOption
                      key={warrantyDocumentSystem}
                      value={warrantyDocumentSystem}
                    >
                      {warrantyDocumentSystem}
                    </OOption>
                  ),
                )}
              </ORFieldSelect>
            </OCol>
            <OCol sm={4}>
              <ORFieldSelect
                id="lockDisbursement"
                name="lockDisbursement"
                label="Método de trava de desembolso"
              >
                {[
                  {
                    value: "PRE",
                    label: "PRE",
                  },
                  {
                    value: "POS",
                    label: "POS",
                  },
                ].map((lock) => (
                  <OOption key={lock.value} value={lock.value}>
                    {lock.label}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="number"
                id="minimumGuarantors"
                name="minimumGuarantors"
                label="Quantidade mínima de avalistas"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="number"
                id="maximumLimitForSimpleGuarantor"
                name="maximumLimitForSimpleGuarantor"
                label="Limite máximo para avalista simples"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="number"
                id="minimumParticipationForGuarantor"
                name="minimumParticipationForGuarantor"
                label="Participação mínima para avalista"
              />
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
      <OCard className="justify-content-between my-3">
        <OCardBody>
          <ORow>
            <div className="d-flex justify-content-between align-items-center my-2">
              <OTypography size="md">Imposto</OTypography>
            </div>
            <OCol sm={4}>
              <ORFieldRadioGroup
                id="isIofExempt"
                name="isIofExempt"
                label="É isento de IOF?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol sm={4}>
              <ORFieldRadioGroup
                id="iofFinance"
                name="iofFinance"
                label="Há financiamento da taxa de IOF?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol sm={4}>
              <ORFieldRadioGroup id="iofDue" name="iofDue" label="IOF Pendente">
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
      <OCard className="justify-content-between my-3">
        <OCardBody>
          <ORow>
            <div className="d-flex justify-content-between align-items-center my-2">
              <OTypography size="md">Cálculo</OTypography>
            </div>
            <OCol>
              <ORFieldSelect
                id="spreadDayCountCode"
                name="spreadDayCountCode"
                label="Base de cálculo de juros"
              >
                {Object.entries(spreadDayCountCodeMap).map(([value, label]) => (
                  <OOption key={value} value={value}>
                    {label}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={4}>
              <ORFieldRadioGroup
                id="useInstallmentFlow"
                name="useInstallmentFlow"
                label="Fluxo parcela"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol>
              <ORFieldSelect
                id="valorizationMethod"
                name="valorizationMethod"
                label="Modo de Cálculo"
              >
                {[
                  {
                    value: "PRIC",
                    label: "Tabela Price",
                  },
                  {
                    value: "BAND",
                    label: "Saldo devedor",
                  },
                  {
                    value: "INST",
                    label: "Parcela",
                  },
                ].map((valorization) => (
                  <OOption key={valorization.value} value={valorization.value}>
                    {valorization.label}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
      <OCard className="justify-content-between my-3">
        <OCardBody>
          <ORow>
            <div className="d-flex justify-content-between align-items-center my-2">
              <OTypography size="md">Custódia</OTypography>
            </div>
            <OCol sm={4}>
              <ORFieldSelect
                id="sourceSystem"
                name="sourceSystem"
                label="Sistema de boletagem"
                disabled={loadingGetAgreementSourceSystem}
              >
                {agreementSourceSystem?.map((sourceSystem: string) => (
                  <OOption key={sourceSystem} value={sourceSystem}>
                    {sourceSystem}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={4}>
              <ORFieldSelect
                id="tradeType"
                name="tradeType"
                label="Tipo do Trade"
                disabled={loadingGetAgreementTradeType}
              >
                {agreementTradeType?.map((tradeType: string) => (
                  <OOption key={tradeType} value={tradeType}>
                    {tradeType}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={4}>
              <ORFieldSelect
                id="financeOrigin"
                name="financeOrigin"
                label="Origem do limite"
                disabled={loadingGetAgreementFinanceOrigin}
              >
                {agreementFinanceOrigin?.map((financeOrigin: string) => (
                  <OOption key={financeOrigin} value={financeOrigin}>
                    {financeOrigin}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
      <OCard className="justify-content-between my-3">
        <OCardBody>
          <ORow>
            <div className="d-flex justify-content-between align-items-center my-2">
              <OTypography size="md">Liquidação</OTypography>
            </div>
            <OCol sm={4}>
              <ORFieldSelect
                id="defaultSettlementType"
                name="defaultSettlementType"
                label="Método de liquidação padrão"
                disabled={loadingProductTypes || loadingSettlementTypes}
              >
                {settlementTypes?.map((settlementType: string) => (
                  <OOption key={settlementType} value={settlementType}>
                    {settlementType}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
      <OCard className="justify-content-between my-3">
        <OCardBody>
          <ORow>
            <div className="d-flex justify-content-between align-items-center my-2">
              <OTypography size="md">Book/Estratégia</OTypography>
            </div>
            <OCol sm={6}>
              <ORFieldInput
                tag="number"
                id="bookCr"
                name="bookCr"
                label="Book das operações"
              />
            </OCol>
            <OCol sm={6}>
              <ORFieldInput
                tag="number"
                id="strategyCr"
                name="strategyCr"
                label="Estratégia das operações"
              />
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
      <OCard className="justify-content-between my-3">
        <OCardBody>
          <ORow>
            <div className="d-flex justify-content-between align-items-center my-2">
              <OTypography size="md">Provisão de Rating</OTypography>
            </div>
            <OCol sm={4}>
              <ORFieldSelect
                id="creditType"
                name="creditType"
                label="Tipo de Crédito"
              >
                {creditTypeOptions.map((cto) => (
                  <OOption key={`creditType_${cto.value}`} value={cto.value}>
                    {cto.label}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={4}>
              <ORFieldSelect
                id="portfolioClassification"
                name="portfolioClassification"
                label="Classificação do Carteira"
              >
                {portfolioClassificationTypes.map((type) => (
                  <OOption key={`portfolioClassification_${type}`} value={type}>
                    {type}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={4}>
              <ORFieldSelect
                id="ratingCounterparty"
                name="ratingCounterparty"
                label="Rating da Contraparte"
              >
                {ratingTypes.map((rating) => (
                  <OOption key={`ratingCounterparty_${rating}`} value={rating}>
                    {rating}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="percentage"
                id="exposureAtDefault"
                name="exposureAtDefault"
                label="Exposição à Inadimplência"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="percentage"
                id="defaultProbability"
                name="defaultProbability"
                label="Probabilidade de Incumprimento"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="percentage"
                id="lostGivenDefault"
                name="lostGivenDefault"
                label="Perda em Função da Inadimplência"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="percentage"
                id="expectedLoss"
                name="expectedLoss"
                label="Perda Esperada"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="percentage"
                id="incurredLoss"
                name="incurredLoss"
                label="Perda a Incorrer"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldSelect id="stage" name="stage" label="Fase">
                {[1, 2, 3].map((s) => (
                  <OOption key={`stage_${s}`} value={s}>
                    {s}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
      <OCard className="justify-content-between my-3">
        <OCardBody>
          <ORow>
            <div className="d-flex justify-content-between align-items-center my-2">
              <OTypography size="md">Outros</OTypography>
            </div>
            <OCol sm={4}>
              <ORFieldSelect
                id="paymentDayType"
                name="paymentDayType"
                label="Dia do pagamento"
              >
                {[
                  {
                    value: "FIRST_DAY_OF_MONTH",
                    label: "Primeiro dia do mês",
                  },
                  {
                    value: "LAST_DAY_OF_MONTH",
                    label: "Último dia do mês",
                  },
                  {
                    value: "ANY",
                    label: "Qualquer um",
                  },
                ].map((paymentDay) => (
                  <OOption key={paymentDay.value} value={paymentDay.value}>
                    {paymentDay.label}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={4}>
              <ORFieldRadioGroup
                id="isSocialImpact"
                name="isSocialImpact"
                label="É impacto social?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol sm={4}>
              <ORFieldRadioGroup
                id="partialLimitConsumeAllowed"
                name="partialLimitConsumeAllowed"
                label="Consumo parcial de limite é permitido?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol sm={4}>
              <ORFieldSelect
                id="settlementAgencyCode"
                name="settlementAgencyCode"
                label="Código da agência de liquidação"
              >
                {[1, 50].map((agency) => (
                  <OOption key={agency} value={agency}>
                    {agency}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={4}>
              <ORFieldSelect
                id="amortizationFrequencyCode"
                name="amortizationFrequencyCode"
                label="Código da frequência de amortização"
              >
                {Object.entries(amortizationFrequencyCodeMap).map(
                  ([value, label]) => (
                    <OOption key={value} value={value}>
                      {label}
                    </OOption>
                  ),
                )}
              </ORFieldSelect>
            </OCol>
            <OCol sm={4}>
              <ORFieldInput
                tag="number"
                id="endorserCge"
                name="endorserCge"
                label="CGE do Endossatario"
              />
            </OCol>
            <OCol sm={4}>
              <ORFieldRadioGroup
                id="automaticallyApproves"
                name="automaticallyApproves"
                label="Aprova automaticamente"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol sm={4}>
              <ORFieldSelect
                id="rating"
                name="rating"
                label="Rating"
                disabled={loadingGetAgreementRating}
              >
                {agreementRating?.map((rating: string) => (
                  <OOption key={rating} value={rating}>
                    {rating}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            <OCol sm={4}>
              <ORFieldSelect
                id="additionalDisbursementAccountType"
                name="additionalDisbursementAccountType"
                label="Conta de desembolso"
                disabled={loadingGetAgreementAdditionalDisbursementAccountType}
              >
                {agreementAdditionalDisbursementAccountType?.map(
                  (additionalDisbursementAccountType: string) => (
                    <OOption
                      key={additionalDisbursementAccountType}
                      value={additionalDisbursementAccountType}
                    >
                      {additionalDisbursementAccountType}
                    </OOption>
                  ),
                )}
              </ORFieldSelect>
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
    </>
  );
};
