import { ODivider, ORow, OTypography } from "@maestro/react";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { addEstrategiaFormDefaultValues } from "../estrategia-form.form";
import { EstrategiaForm } from "../estrategia-form.types";
import {
  ApprovalGroupsField,
  AutomaticAnticipationField,
  BiometryRequiredField,
  CessaoOffshoreField,
  ContractTemplateIdField,
  CreditorIdField,
  DiasParaVencimentoDataRepasseRiscoCedenteField,
  ExigeAprovacaoBancoField,
  ExigeIndicacaoHedgeField,
  FccIdSacadoField,
  FilesForOperationField,
  FilesForReceivablesField,
  HorarioLimiteOperacaoField,
  InstrumentCodeIdField,
  MarketplaceIdField,
  ModeloDeTaxaField,
  NeedsFilesForOperationField,
  NeedsFilesForReceivablesField,
  NomeField,
  PnlBookField,
  PnlStrategyField,
  PortfolioIdField,
  PrazoLimiteOperacaoField,
  ReporteDiarioRiscoCedenteField,
  TipoContratoField,
  TipoOnboardingField,
  GracePeriodField,
  ToCreditField,
  HiringContractTemplateIdField,
  HiringContractTypeField,
  IsLoanAssignmentField,
  PenaltyFeeField,
  InterestArrearsBaseField,
  InterestArrearsRateField,
  LatePaymentRateField,
  LoanAgreementIdField,
  NeedsRegistrationInfoField,
  AllowAggregateBorderosField,
  InterestIndexCodeField,
  LinkWithBankSlipsField,
} from "../fields";

interface RiscoCedenteFormProps {
  form: UseFormReturn<EstrategiaForm>;
  shouldReset?: boolean;
}

export const RiscoCedenteForm = ({
  form,
  shouldReset,
}: RiscoCedenteFormProps) => {
  const { getValues, reset, watch } = form;

  useEffect(() => {
    if (shouldReset)
      reset({
        ...addEstrategiaFormDefaultValues,
        tipoProduto: getValues("tipoProduto"),
        exigeAprovacaoBanco: "no",
        toCredit: "yes",
        horarioLimiteOperacao: "17:00",
        modeloDeTaxa: "configuracao",
        prazoLimiteOperacao: 0,
      });
  }, [getValues, reset, shouldReset]);

  const reporteDiarioRiscoCedenteWatch = watch("reporteDiarioRiscoCedente");
  const needsFilesForOperationWatch = watch("needsFilesForOperation");
  const needsFilesForReceivablesWatch = watch("needsFilesForReceivables");
  const isLoanAssignmentWatch = watch("isLoanAssignment");

  return (
    <>
      <OTypography size="lg">Geral</OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <NomeField />
        <ModeloDeTaxaField />
        <HorarioLimiteOperacaoField />
        <ExigeAprovacaoBancoField />
        <ExigeIndicacaoHedgeField />
        <TipoOnboardingField type="RISCO_CEDENTE" />
        <PrazoLimiteOperacaoField />
        <GracePeriodField />
        <FccIdSacadoField />
        <PnlBookField />
        <PnlStrategyField />
        <InstrumentCodeIdField />
        <MarketplaceIdField />
        <PortfolioIdField />
        <CreditorIdField />
        <BiometryRequiredField />
        <ToCreditField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Cessão de crédito
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <HiringContractTypeField />
        <HiringContractTemplateIdField />
        <TipoContratoField required />
        <ContractTemplateIdField />
        <IsLoanAssignmentField />
        <LoanAgreementIdField disabled={isLoanAssignmentWatch !== "yes"} />
        <AllowAggregateBorderosField />
        <CessaoOffshoreField />
        <AutomaticAnticipationField />
        <LinkWithBankSlipsField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Validações de entrada
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <NeedsFilesForReceivablesField />
        <FilesForReceivablesField
          disabled={needsFilesForReceivablesWatch !== "yes"}
        />
        <NeedsFilesForOperationField />
        <FilesForOperationField
          disabled={needsFilesForOperationWatch !== "yes"}
        />
        <NeedsRegistrationInfoField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Encargos de atraso
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <PenaltyFeeField disabled={isLoanAssignmentWatch !== "yes"} />
        <InterestArrearsRateField disabled={isLoanAssignmentWatch !== "yes"} />
        <InterestArrearsBaseField disabled={isLoanAssignmentWatch !== "yes"} />
        <LatePaymentRateField disabled={isLoanAssignmentWatch !== "yes"} />
        <InterestIndexCodeField disabled={isLoanAssignmentWatch !== "yes"} />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Notificações (cedente)
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <ReporteDiarioRiscoCedenteField />
        {reporteDiarioRiscoCedenteWatch === "yes" && (
          <DiasParaVencimentoDataRepasseRiscoCedenteField />
        )}
      </ORow>

      <OTypography size="lg" className="mt-5">
        Avançado
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <ApprovalGroupsField />
      </ORow>
    </>
  );
};
