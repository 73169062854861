import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { service } from "services";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  amortizationFrequencyCodeMap,
  spreadDayCountCodeMap,
} from "../../../../../components/utils/loan-agreement.utils";

type PartnerAgreement = Awaited<
  ReturnType<typeof service.hubLoan.getArrangementId>
>["data"];

export const agreementsDetailsGenerator = (
  partnerAgreement: PartnerAgreement,
) => {
  return {
    partnerAgreement: {
      items: [
        {
          label: "Identificação",
          value: partnerAgreement.agreement?.identification,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Nome",
          value: partnerAgreement.agreement?.name,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Período de carência recomendado (meses)",
          value: partnerAgreement.agreement?.defaultGracePeriod,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Consumo parcial de limite permitido?",
          value: partnerAgreement.agreement?.partialLimitConsumeAllowed
            ? "Sim"
            : "Não",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Período de carência máxima (dias)",
          value: partnerAgreement.agreement?.maxGracePeriod,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Tipo de dia do pagamento",
          value: partnerAgreement.agreement?.paymentDayType,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Isento de IOF?",
          value: partnerAgreement.agreement?.isIofExempt ? "Sim" : "Não",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Há financiamento da taxa de IOF?",
          value: partnerAgreement.agreement?.iofFinance ? "Sim" : "Não",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Possui garantia?",
          value: partnerAgreement.agreement?.hasGuarantee ? "Sim" : "Não",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "É de impacto social?",
          value: partnerAgreement.agreement?.isSocialImpact ? "Sim" : "Não",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Modo de cálculo",
          value: partnerAgreement.agreement?.valorizationMethod,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Método de trava de desembolso",
          value: partnerAgreement.agreement?.lockDisbursement,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Book",
          value: partnerAgreement.agreement?.bookCr,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Estratégia",
          value: partnerAgreement.agreement?.strategyCr,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Quantidade mínima de avalistas",
          value: partnerAgreement.agreement?.minimumGuarantors,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Limite máximo para avalista simples",
          value: partnerAgreement.agreement?.maximumLimitForSimpleGuarantor,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Participação mínima para avalista",
          value: partnerAgreement.agreement?.minimumParticipationForGuarantor,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Código da agência de liquidação",
          value: partnerAgreement.agreement?.settlementAgencyCode,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Código da frequência de amortização",
          value: getValueFromMap(
            amortizationFrequencyCodeMap,
            partnerAgreement.agreement?.amortizationFrequencyCode,
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Base de cálculo de juros",
          value: getValueFromMap(
            spreadDayCountCodeMap,
            partnerAgreement.agreement?.spreadDayCountCode,
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Sistema de documento de garantia",
          value: partnerAgreement.agreement?.warrantyDocumentSystem,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Fluxo parcela",
          value: partnerAgreement.agreement?.useInstallmentFlow ? "Sim" : "Não",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Aprova automaticamente",
          value: partnerAgreement.agreement?.automaticallyApproves
            ? "Sim"
            : "Não",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Taxa mínima",
          value: partnerAgreement.agreement?.minRate,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Taxa máxima",
          value: partnerAgreement.agreement?.maxRate,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Valor mínimo",
          value: masks.currency(
            partnerAgreement.agreement?.minAmount,
            currencySymbol.BRL,
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Valor máximo",
          value: masks.currency(
            partnerAgreement.agreement?.maxAmount,
            currencySymbol.BRL,
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Prazo mínimo (meses)",
          value: partnerAgreement.agreement?.minTerm,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Prazo máximo (meses)",
          value: partnerAgreement.agreement?.maxTerm,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
