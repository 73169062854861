import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { NewSignatureRuleForm } from "./new-signature-rule.types";

export const useNewSignatureRule = () => {
  const {
    customer: { identification, quickfinId },
  } = useCustomer();

  const {
    callService: _getCompanyWithOnboarding,
    hasError: getCompanyHasError,
    loading: getCompanyLoading,
    value: company,
  } = useServiceCall(service.quickfin.getCompanyWithOnboarding);

  const { callService: createSignatureRule, loading: submitLoading } =
    useServiceCall(service.paperclipv2.createSignatureRule);

  const navigate = useNavigate();

  const form = useForm<NewSignatureRuleForm>();

  const { handleSubmit } = form;

  const getCompanyWithOnboarding = useCallback(
    () => _getCompanyWithOnboarding(quickfinId),
    [_getCompanyWithOnboarding, quickfinId],
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success } = await createSignatureRule({
          cnpj: identification,
          contractType: "ContratoAprovacao",
          minimumValue: String(values.minimumValue),
          maximumValue: String(values.maximumValue),
          startingDateOfPermission: values.startingDateOfPermission,
          endingDateOfPermission: values.endingDateOfPermission,
          groupOfSignatures: [
            {
              name: values.name,
              minimumNumberOfSigners: values.minimumNumberOfSigners,
              emails: values.emails,
            },
          ],
        });

        if (success) {
          OToastManager.success("Regra de assinaturas criada com sucesso");
          navigate(corporateRouter.routes.antecipacaoDeCartoes.customer.signatures.path);
        } else OToastManager.danger("Erro ao criar regra de assinaturas");
      }),
    [createSignatureRule, handleSubmit, identification, navigate],
  );

  useEffect(() => {
    getCompanyWithOnboarding();
  }, [getCompanyWithOnboarding]);

  const legalRepresentatives = useMemo(
    () =>
      company?.legalRepresentatives
        .map((representative) => ({
          name: representative.name,
          email: representative.email,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [company?.legalRepresentatives],
  );

  return {
    form,
    getCompanyHasError,
    getCompanyLoading,
    getCompanyWithOnboarding,
    legalRepresentatives,
    submit,
    submitLoading,
  };
};
