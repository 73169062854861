import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { WorkflowProdutoWorkflowConfigById } from "../../../../../../routes/workflow.route-params";
import { defaultValues, schema } from "./workflow-config-hierarchy.schema";
import { EditWorkflowConfigMainDataFormValuesType } from "./workflow-config-hierarchy.types";

export const useWorkflowConfigHierarchy = () => {
  const { id } = useParams<WorkflowProdutoWorkflowConfigById>();
  if (!id) throw new Error("No id");

  const form = useForm<EditWorkflowConfigMainDataFormValuesType>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { control, setValue, watch, getValues } = form;
  const allowedChildWorkflowConfigsWatch = watch("allowedChildWorkflowConfigs");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "hierarchy",
  });

  const {
    value: hierarchyDetails,
    callService: getWorkflowConfigHierarchyDetails,
    loading: loadingWorkflowConfigHierarchy,
    hasError: hasErrorWorkflowConfigHierarchy,
  } = useServiceCall(
    service.hubCreditManager.getWorkflowConfigHierarchyDetails,
  );
  const {
    value: allowedChildWorkflowConfigsOptions,
    callService: getAllowedChildWorkflowConfigs,
    loading: loadingAllowedChildWorkflowConfigs,
    hasError: hasErrorAllowedChildWorkflowConfigs,
  } = useServiceCall(service.hubCreditManager.getAllowedChildWorkflowConfigs);
  const {
    callService: callEditWorkflowConfigHierarchy,
    loading: loadingEditWorkflowConfigHierarchy,
  } = useServiceCall(service.hubCreditManager.editWorkflowConfigHierarchy);

  const hasError = useMemo(() => {
    return (
      hasErrorAllowedChildWorkflowConfigs || hasErrorWorkflowConfigHierarchy
    );
  }, [hasErrorAllowedChildWorkflowConfigs, hasErrorWorkflowConfigHierarchy]);

  const loading = useMemo(() => {
    return loadingAllowedChildWorkflowConfigs || loadingWorkflowConfigHierarchy;
  }, [loadingAllowedChildWorkflowConfigs, loadingWorkflowConfigHierarchy]);

  const updateWorkflow = useCallback(
    async ({
      allowedChildWorkflowConfigs,
      tabName,
      hierarchy,
    }: EditWorkflowConfigMainDataFormValuesType) => {
      const childWorkflowConfigPayload = allowedChildWorkflowConfigs?.map(
        (config) => {
          const configId = Number(config);

          return {
            id: configId,
            gridTitle: hierarchy?.[configId]?.gridTitle ?? "",
            tabName: hierarchy?.[configId]?.tabName ?? "",
            tabTitle: hierarchy?.[configId]?.tabTitle ?? "",
            tabDescription: hierarchy?.[configId]?.tabDescription ?? "",
          };
        },
      );

      const { success } = await callEditWorkflowConfigHierarchy({
        id,
        childWorkflowConfig: childWorkflowConfigPayload ?? [],
        tabName,
      });

      if (success) {
        OToastManager.success("Workflow editado com sucesso!");
      } else {
        OToastManager.danger(
          "Houve um problema ao tentar editar a configuração de workflow. Por favor, tente novamente mais tarde.",
        );
      }
    },
    [callEditWorkflowConfigHierarchy, id],
  );

  const getAllData = useCallback(() => {
    getWorkflowConfigHierarchyDetails(id);
    getAllowedChildWorkflowConfigs(id);
  }, [getAllowedChildWorkflowConfigs, getWorkflowConfigHierarchyDetails, id]);

  useEffect(() => {
    if (!fields || !fields.length) return;

    const hierarchyValues = getValues("hierarchy");

    fields.forEach((f) => {
      const hierarchyData = hierarchyDetails?.childWorkflowConfigs?.find(
        (o) => o.id === f.childId,
      );
      const optionData = allowedChildWorkflowConfigsOptions?.find(
        (o) => o.id === f.childId,
      );

      const gridTitle =
        hierarchyValues?.[f.childId]?.gridTitle ||
        hierarchyData?.gridTitle ||
        optionData?.gridTitle;

      const tabName =
        hierarchyValues?.[f.childId]?.tabName ||
        hierarchyData?.tabName ||
        optionData?.tabName;

      const tabTitle =
        hierarchyValues?.[f.childId]?.tabTitle ||
        hierarchyData?.tabTitle ||
        optionData?.tabTitle;

      const tabDescription =
        hierarchyValues?.[f.childId]?.tabDescription ||
        hierarchyData?.tabDescription ||
        optionData?.tabDescription;

      setValue(`hierarchy.${f.childId}.gridTitle`, gridTitle);
      setValue(`hierarchy.${f.childId}.tabName`, tabName);
      setValue(`hierarchy.${f.childId}.tabTitle`, tabTitle);
      setValue(`hierarchy.${f.childId}.tabDescription`, tabDescription);
    });
  }, [
    allowedChildWorkflowConfigsOptions,
    fields,
    hierarchyDetails,
    getValues,
    setValue,
  ]);

  useEffect(() => {
    if (
      !allowedChildWorkflowConfigsOptions ||
      !allowedChildWorkflowConfigsOptions?.length ||
      !allowedChildWorkflowConfigsWatch?.length
    )
      return;

    const childsToAdd = allowedChildWorkflowConfigsOptions.filter((allOpt) =>
      allowedChildWorkflowConfigsWatch.includes(allOpt.id.toString()),
    );

    const newValues = childsToAdd.map((child) => ({
      childId: child.id,
      identification: child.identification,
      name: child.name,
    }));

    remove();
    newValues.forEach((vals) => append(vals));
  }, [
    allowedChildWorkflowConfigsOptions,
    allowedChildWorkflowConfigsWatch,
    append,
    remove,
  ]);

  useEffect(() => {
    if (!hierarchyDetails) return;

    const { childWorkflowConfigs, tabName } = hierarchyDetails;

    setTimeout(() => {
      setValue("tabName", tabName);
      setValue(
        "allowedChildWorkflowConfigs",
        childWorkflowConfigs?.map((child) => String(child.id)) ?? [],
      );
    }, 200);
  }, [hierarchyDetails, setValue]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  return {
    allowedChildWorkflowConfigsOptions,
    fields,
    form,
    hasError,
    loading,
    loadingEditWorkflowConfigHierarchy,
    getAllData,
    updateWorkflow,
  };
};
