import { OBadge, OCol, OField, OIcon, OTypography } from "@maestro/react";
import { SelectSearch } from "components/select-search";
import { UnstyledButton } from "components/unstyled-button";
import { remapHookFormRegister } from "../../../../../../../../antecipacao-de-cartoes/components/generic-bank-data/generic-bank-data.utils";
import { useDesksFields } from "./desks-fields.hook";
import { StyledSelect } from "../../../workflow-configuration-scf.style";

export const DesksFields = () => {
  const {
    allDesks,
    form,
    localForm,
    mainDeskError,
    setValue,
    watchDesks,
    watchInput,
  } = useDesksFields();

  return (
    <>
      <OCol xs={4}>
        <OField label="Mesas" htmlFor="input">
          <SelectSearch
            id="input"
            name="input"
            placeholder="Selecionar"
            options={allDesks}
            value={watchInput}
            className="w-100"
            maxOptions={50}
            innerProps={{
              input: {
                ...remapHookFormRegister(localForm.register("input")),
              },
            }}
          />
        </OField>
      </OCol>
      <OCol xs={4} className="d-flex flex-column gap-4">
        <OTypography tag="span" size="sm">
          Mesas selecionadas:
        </OTypography>

        {!!watchDesks?.length && (
          <div className="d-flex flex-wrap gap-2">
            {watchDesks.map((desk) => {
              const findDesk = allDesks.find((all) => all.value === desk);

              if (!findDesk?.label) return;

              return (
                <OBadge key={desk} type="dark" size="sm" rounded outline>
                  <div className="d-flex align-items-center gap-2">
                    {findDesk.label}
                    <UnstyledButton
                      onClick={() => {
                        const filteredDesks = watchDesks.filter(
                          (d) => d !== desk,
                        );

                        setValue("desks", filteredDesks);
                      }}
                    >
                      <OIcon category="fal" icon="fa-times" />
                    </UnstyledButton>
                  </div>
                </OBadge>
              );
            })}
          </div>
        )}
      </OCol>
      <OCol xs={4}>
        <OField
          htmlFor="mainDesk"
          label="Mesa principal"
          error={!!mainDeskError}
          message={mainDeskError?.message}
        >
          <StyledSelect
            {...form.register("mainDesk")}
            id="mainDesk"
            name="mainDesk"
            disabled={!(watchDesks && watchDesks.length > 0)}
          >
            {watchDesks?.map((desk) => (
              <option key={`main-desk-${desk}`} value={desk}>
                {desk}
              </option>
            ))}
          </StyledSelect>
        </OField>
      </OCol>
    </>
  );
};
