import { yupResolver } from "@hookform/resolvers/yup";
import { modalManager, OToastManager } from "@maestro/core";
import dayjs from "dayjs";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { modalManagerPayload } from "utils/modal-manager-payload";
import { getValidationMessages } from "../../../../../../../../../../utils";
import {
  PropertyValueForm,
  propertyValueFormDefaultValues,
  propertyValueFormValidationSchema,
} from "./edit-property-value-modal.form";
import { editPropertyValueModalId } from "./edit-property-value-modal.utils";

export const useEditPropertyValueModal = (reload: () => void) => {
  const [property, setProperty] =
    useState<HubEnergy.DiligenceStepPropertyResponse>();

  const { callService, loading } = useServiceCall(
    service.hubEnergy.patchDiligencePropertyValue,
  );

  const form = useForm<PropertyValueForm>({
    defaultValues: propertyValueFormDefaultValues,
    resolver: yupResolver(propertyValueFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async ({ value, adequacyOptionId }) => {
        if (!property) return OToastManager.danger("Nenhuma propriedade");

        const { success, error } = await callService({
          diligenceStepPropertyId: property.id,
          value:
            value === null || value === undefined
              ? null
              : property.type.mask === "DATE"
              ? dayjs(value).toISOString()
              : String(value),
          adequacyOptionId,
        });

        if (success) {
          OToastManager.success("Propriedade atualizada com sucesso");
          reload();
          modalManager.hide(editPropertyValueModalId);
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0]?.errorMessage ??
              "Erro ao atualizar a propriedade",
          );
        }
      }),
    [callService, handleSubmit, property, reload],
  );

  useEffect(() => {
    if (property)
      reset({
        adequacyOptionId: property.adequacyOption?.id ?? null,
        value: property.value,
        // helper
        propertyType: property.type.name,
        files: property.document?.files ?? null,
      });
  }, [property, reset]);

  useEffect(() => {
    const cleanup =
      modalManagerPayload.on<HubEnergy.DiligenceStepPropertyResponse>(
        editPropertyValueModalId,
        "modalOpen",
        setProperty,
      );
    return cleanup;
  }, []);

  useEffect(() => {
    const cleanup = modalManagerPayload.on(
      editPropertyValueModalId,
      "modalClose",
      () => setProperty(undefined),
    );
    return cleanup;
  }, []);

  return { form, submit, loading, property };
};
