import {
  OButton,
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  OIcon,
  OLabel,
  ORow,
} from "@maestro/react";
import { useCallback, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { editeCedenteCompletoDefaultValues } from "../../edit-cedente-completo.form";
import { StyledBadge, StyledRow } from "../../edit-cedente-completo.styles";
import { RepresentativesFields } from "./_compose";

interface RepresentativesListProps {
  representatives?: ScfDevelopers.CedenteCompletoRepresentative[];
}

export const RepresentativesList = ({
  representatives,
}: RepresentativesListProps) => {
  const form = useFormContext<ScfDevelopers.UpdateCedenteCompleto>();
  const { control, setValue } = form;

  const { append, fields, remove } = useFieldArray({
    control,
    name: "representatives",
  });

  const handleRepresentativesProps = useCallback(() => {
    if (!representatives) {
      return;
    }

    const updatedRepresentatives = representatives.map((representative) => ({
      ...representative,
      addresses: representative.addresses.map((address) => ({
        cityName: address.cityName?.toUpperCase() ?? null,
        complement: address.complement,
        countryCode: "BR",
        neighborhood: address.neighborhood.toUpperCase(),
        number: address.number,
        stateCode: address.stateCode?.toUpperCase() ?? null,
        street: address.street?.toUpperCase(),
        zipCode: address.zipCode,
        countryName: "BRASIL",
        stateName: address.stateName?.toUpperCase() ?? null,
        type: "COMERCIAL" as ScfDevelopers.AddressType,
        phoneNumber: address.phoneNumber,
        contactName: address.contactName?.toUpperCase() ?? null,
        cityCode: address.cityCode?.toUpperCase() ?? null,
      })),
    }));

    setValue("representatives", updatedRepresentatives);
  }, [representatives, setValue]);

  useEffect(() => {
    handleRepresentativesProps();
  }, [handleRepresentativesProps]);

  return (
    <OCard>
      <OCardHeader className="d-flex flex-column gap-3">
        <div className="d-flex flex-row align-items-center gap-3">
          <OLabel htmlFor="representatives">
            {fields.length > 1 ? "Representantes" : "Representante"}
          </OLabel>
          <OButton
            type="tertiary"
            onClick={() =>
              append(editeCedenteCompletoDefaultValues.representatives[0])
            }
          >
            <OIcon category="fa" icon="fa-plus" />
          </OButton>
        </div>
      </OCardHeader>
      <OCardBody>
        <ORow className="mx-1">
          {fields.map((field, index) => (
            <StyledRow key={field.id}>
              <OCol xs={1} className="h-100">
                <StyledBadge>{index + 1}</StyledBadge>
              </OCol>
              <OCol xs={10} align="center">
                <RepresentativesFields
                  index={index}
                  ocurrences={
                    representatives
                      ? representatives[index].rejection
                      : undefined
                  }
                  representativeKey={field.id}
                />
              </OCol>
              <OCol xs={1} align="center">
                <OButton onClick={() => remove(index)}>
                  <OIcon category="fa" icon="fa-trash" />
                </OButton>
              </OCol>
            </StyledRow>
          ))}
        </ORow>
      </OCardBody>
    </OCard>
  );
};
