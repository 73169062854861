import { ODataGridGeneratorConfig } from "components/data-grid";
import { masks } from "@maestro/utils";
import { corporateRouter } from "routes/corporate-router.context";
import { roles } from "roles/roles";
import { DetailsLink } from "components/standard-link";
import { Filiais } from "./filiais.types";

const buildDetailsLink = (identification: string) => {
  return (
    corporateRouter.routes.antecipacaoDeCartoes.customer.details.path +
    "?select-customer=" +
    identification
  );
};

export const filiaisGrid: ODataGridGeneratorConfig<
  NonNullable<Filiais["data"]>[number]
> = {
  datagrid: {
    noDataText: "Nenhuma informação de filiais.",
  },
  columns: [
    {
      caption: "Detalhes",
      role: roles.antecipacaoDeCartoes.customer.detailsPage.role,
      cellRender: ({ data }) =>
        !!data.identification && (
          <DetailsLink href={buildDetailsLink(data.identification)} />
        ),
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      name: "identification",
      dataField: "identification",
      caption: "CNPJ",
      format: (value) => masks.cnpj(value),
    },
    {
      dataField: "valorVencimento",
      caption: "Total das vendas",
      alignment: "left",
      dataType: "number",
      format: (value) => masks.currency(value, "R$"),
    },
    {
      dataField: "valorDesembolso",
      caption: "Disponível para receber",
      alignment: "left",
      dataType: "number",
      sortOrder: "desc",
      format: (value) => masks.currency(value, "R$"),
    },
  ],
};
