import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useCNABContext } from "../../../../../cnab.context";
import { UpsertLayoutFormFields } from "../../../../upsert-layout.form-schema";

export const BaseLayoutFields = () => {
  const { products } = useCNABContext();
  const { setValue, watch } = useFormContext<UpsertLayoutFormFields>();

  const productNameWatched = watch("productName");

  const availableTypes = useMemo(() => {
    const selectedProduct = products?.find(
      ({ name }) => name === productNameWatched,
    );

    if (!selectedProduct) return [];

    const options = selectedProduct.layouts.reduce<string[]>(
      (items, { selected, type, erp }) => {
        if (selected) {
          setValue("layoutType", type);
          setValue("erp", erp);
        }
        if (!items.includes(type)) items.push(type);
        return items;
      },
      [],
    );

    return options.sort();
  }, [productNameWatched, products, setValue]);

  return (
    <ORow gap={3}>
      <OCol>
        <ORFieldSelect
          id="layoutType"
          name="layoutType"
          label="Layout do arquivo"
          placeholder="Selecione o layout"
          key={`${productNameWatched}-layoutType`}
        >
          {availableTypes.map((type) => (
            <OOption key={type} value={type}>
              {type}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>

      <OCol>
        <ORFieldInput
          id="erp"
          name="erp"
          tag="text"
          label="Sistema ERP utilizado (opcional)"
        />
      </OCol>
    </ORow>
  );
};
