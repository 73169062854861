import { OCard, OCardBody, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { useCollectionBeneficiaryContext } from "../../collection-beneficiary.context";
import { BeneficiaryHeader } from "./_compose/beneficiary-header";
import { BeneficiaryStatusBadge } from "./_compose/beneficiary-status-badge";
import { SingleDetail } from "./_compose/single-detail";

export const BeneficiaryDetails = () => {
  const { beneficiary, isLoading } = useCollectionBeneficiaryContext();

  return (
    <OCard>
      <OCardBody className="d-flex flex-column gap-3">
        <BeneficiaryHeader />

        <div className="d-flex align-items-center gap-3">
          <SingleDetail
            title="Razão Social"
            isLoading={isLoading}
            skeletonWidth="20rem"
            content={beneficiary?.companyName}
          />
          <SingleDetail
            title="Nome fantasia"
            isLoading={isLoading}
            skeletonWidth="20rem"
            content={beneficiary?.fantasyName}
          />
        </div>

        <div className="d-flex align-items-center gap-3">
          <SingleDetail
            title="CPF/CNPJ"
            isLoading={isLoading}
            skeletonWidth="10rem"
            content={masks.cpfCnpj(beneficiary?.taxId)}
          />

          <div className="d-flex flex-column gap-1 w-50">
            <OTypography type="dark">Status</OTypography>
            <BeneficiaryStatusBadge />
          </div>
        </div>
      </OCardBody>
    </OCard>
  );
};
