import { OToastManager } from "@maestro/react";
import { AxiosError } from "axios";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import dayjs from "dayjs";
import { roles } from "roles/roles";
import { service } from "services";
import { copyToClipboard } from "utils/copy";
import { env } from "utils/environments";

export const workflowUserActionRequestsGrid = {
  datagrid: {
    noDataText: "Nenhuma solicitação encontrada",
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
    },
    {
      dataField: "identification",
      caption: "Identificação",
    },
    {
      dataField: "status",
      caption: "Status",
    },
    {
      caption: "Tipo",
      dataField: "actionType",
    },
    {
      caption: "E-mail",
      dataField: "email",
    },
    {
      caption: "Última notificação",
      dataField: "updatedDate",
      dataType: "datetime",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => (
        <DataGridAction
          actions={[
            {
              icon: {
                category: "fal",
                icon: "fa-send-backward",
              },
              label: "Reenviar notificação",
              onClick: async () => {
                try {
                  if (
                    dayjs().diff(data.lastNotificationSentAt, "hour", true) < 1
                  ) {
                    OToastManager.warning(
                      "Notificação já enviada na última hora",
                    );
                    return;
                  }

                  await service.hubCreditManager.sendUserActionRequestNotification(
                    {
                      userActionRequestId: data.id,
                    },
                  );
                  OToastManager.success("Notificação enviada com sucesso");
                } catch (e) {
                  const status = (e as AxiosError<unknown>)?.response?.status;

                  if (status === 401)
                    OToastManager.danger("Usuário(a) sem permissão.");
                  else OToastManager.danger("Erro ao enviar notificação");
                }
              },
            },
            {
              icon: {
                category: "fal",
                icon: "fa-copy",
              },
              visible: data.actionType === "ECNPJ",
              disabled: data.status !== "REQUESTED",
              label: "Copiar conteúdo",
              role: roles.workflow.product.workflowsPage.role,
              onClick: () =>
                copyToClipboard(
                  {
                    ECNPJ: `${env.PORTAL_CORE_FRONT_URL}/emprestimos/workflow-de-propostas/e-cnpj/${data.identification}`,
                  }[data.actionType] ?? "",
                ),
            },
          ]}
        />
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<
      typeof service.hubCreditManager.getWorkflowLeadProcessorUserActions
    >
  >["data"][number]
>;
