import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { getValueFromMap } from "utils/get-value-from-map";
import { documentSubtypeByDocumentMap } from "../../../../edit-cedente-completo.utils";

export const useRepresentativeDocument = (index: number) => {
  const form = useFormContext<ScfDevelopers.UpdateCedenteCompleto>();
  const {
    formState: { errors },
    getValues,
  } = form;

  const possibleDocumentSubtypes = useMemo(
    () =>
      getValueFromMap(
        documentSubtypeByDocumentMap,
        getValues(`representatives.${index}.document.type`),
      ),
    [getValues, index],
  );

  const fileError = useMemo(() => {
    const representativesErrors = errors.representatives;

    if (!representativesErrors) {
      return undefined;
    }

    const documentErrorMessage =
      representativesErrors[index]?.document?.message;

    const fileErrorMessage =
      representativesErrors[index]?.document?.files?.message;

    const genericFileErrorMessage = representativesErrors[index]?.document
      ?.files?.[0]
      ? "Há erro em algum arquivo anexado, abra o modal para ver mais detalhes"
      : undefined;

    return (
      documentErrorMessage ??
      fileErrorMessage ??
      genericFileErrorMessage ??
      undefined
    );
  }, [errors.representatives, index]);

  return {
    fileError,
    possibleDocumentSubtypes,
  };
};
