import { modalManager, OToastManager } from "@maestro/core";
import debounce from "lodash/debounce";
import moment from "moment";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { hubLoanService } from "services/hubloan/hubloan.service";
import { service } from "services";
import { ConfirmationBody } from "services/hubloan/models/requests/post-api-confirm-simulation.request";
import { useGuarantorsSetup } from "../../../offers-guarantors-hook.component";
import { BankAccount, LoanDetails, LoanStatus } from "../../../offers.type";
import { useSummary } from "./simulation-summary.hook";
import {
  SimulationContextInterface,
  SimulationPayload,
  SimulationResult,
  SimulationResultLimit,
  SimulationResultV2,
  SimulationSummary,
} from "./simulation.types";
import { getBankAccountList } from "./compose/bank-account-list/bank-account.utils";
import { REDIRECT_MODAL } from "./compose/redirect-signature-modal/redirect-signature.component";

interface SimulationProps {
  children: ReactNode;
}

export const SimulationContext = createContext(
  {} as SimulationContextInterface,
);

export const SimulationProvider = ({ children }: SimulationProps) => {
  const { type, id, offerId } = useParams<{
    type: string;
    id: string;
    offerId: string;
  }>();
  const navigate = useNavigate();
  const { setGuarantorsSetup, guarantors } = useGuarantorsSetup();

  const [loading, setLoading] = useState(false);
  const [loadingSimulation, setLoadingSimulation] = useState(false);
  const [loadingBankingAccounts, setLoadingBankingAccounts] = useState(false);

  const [simulationResult, setSimulationResult] = useState<
    SimulationResultV2[]
  >([]);
  const [bankAccountsList, setBankAccountsList] = useState<BankAccount[]>([]);

  const [activeStep, setActiveStep] = useState(0);
  const [selectedOfferLoan, setSelectedOfferLoan] =
    useState<SimulationResult>();
  const [selectedOfferLimit, setSelectedOfferLimit] =
    useState<SimulationResultLimit>();

  const [loanId, setLoanId] = useState<number>();
  const [loanDetails, setLoanDetails] = useState<LoanDetails>();

  const [landingPage, setLandingPage] = useState<string | null>(null);

  const form = useForm({});
  const { getValues, setError, clearErrors, setValue, watch } = form;

  const valueWatch = watch("value");
  const termWatch = watch("term");
  const offerWatch = watch("offer");
  const dateWatch = watch("date");
  const bankWatch = watch("bank");
  const paymentMethodWatch = watch("paymentMethod");

  // summary custom hooks
  const {
    loading: loadingSummary,
    summary,
    currentStepsMap,
  } = useSummary({
    id: id || "",
    type: (type || "").toUpperCase(),
    form,
    offerId: offerId || "",
  });

  const isLoading = useMemo(
    () => loading || loadingSummary,
    [loading, loadingSummary],
  );

  const validateValue = useCallback(
    (value: number, summaryData?: SimulationSummary[]) => {
      if (!type || !summaryData) return false;

      if (["agro", "solar"].includes(type)) return true;

      const minAmount = Math.min(
        ...summaryData.map((s) => s?.agreement?.minAmount || 0),
      );

      const maxAmount = Math.max(...summaryData.map((s) => s?.limit));

      if (value < minAmount) {
        setError("value", { message: "Valor inferior ao mínimo oferecido" });
        return false;
      }

      if (value > maxAmount) {
        setError("value", { message: "Valor superior ao máximo oferecido" });
        return false;
      }

      if (value < 0) {
        setError("value", { message: "Valor inválido" });
        return false;
      }

      return true;
    },
    [setError, type],
  );

  const simulateButtonDisabled = useMemo(() => {
    const { value, term, date } = getValues();

    if (
      !!summary?.length &&
      summary?.length >= 2 &&
      (!validateValue(value, summary) || !term)
    )
      return true;

    if (
      !!summary?.length &&
      summary?.length < 2 &&
      (!validateValue(value, summary) || !term || !date)
    )
      return true;

    return false;
  }, [valueWatch, termWatch, dateWatch, summary]);

  const selectOfferButtonDisabled = useMemo(() => {
    const { offer } = getValues();

    if (!offer) return true;

    return false;
  }, [offerWatch]);

  const acceptOfferButtonDisabled = useMemo(() => {
    const { bank, paymentMethod, date } = getValues();

    if (selectedOfferLoan?.disbursementInstructions.length) {
      return !paymentMethod || !date;
    }

    return !bank || !paymentMethod || !date;
  }, [bankWatch, paymentMethodWatch, dateWatch, selectedOfferLoan]);

  const showMoneyInAccountBadge = useMemo(() => {
    return paymentMethodWatch === "DebitoEmConta";
  }, [paymentMethodWatch]);

  const simulate = useCallback(
    async (passTerm = true) => {
      if (!type || !id || !offerId) return;

      try {
        setLoadingSimulation(true);

        const { value, term, date } = getValues();

        const passFirstPaymentDate =
          (!!summary?.length && summary.length < 2) || activeStep != 0;

        const payload: SimulationPayload = {
          product: type?.toUpperCase(),
          limitId: +id,
          offerId: +offerId,
          term: passTerm ? term?.split("_")?.[0] : null,
          amount: value,
          firstPaymentDate: passFirstPaymentDate ? date : null,
          simulateMainOfferOnly: false,
        };

        const { data } = await hubLoanService.postSimulationV2(payload);

        setSimulationResult(data);

        if (
          activeStep + 2 === currentStepsMap?.length &&
          summary?.length &&
          summary.length < 2
        ) {
          const selectedResult = data?.find((result) =>
            result.loans?.some(
              (loan) => loan.term.toString() === term?.split("_")?.[0],
            ),
          );

          const selectedLoan = selectedResult?.loans.find(
            (loan) => loan.term.toString() === term?.split("_")?.[0],
          );

          setSelectedOfferLimit(selectedResult?.limit);
          setSelectedOfferLoan(selectedLoan);
        }

        if (!!selectedOfferLimit && summary?.length && summary.length >= 2) {
          const selectedResult = data?.find(
            (result) => result.limit.id === selectedOfferLimit.id,
          );

          const selectedLoan = selectedResult?.loans.find(
            (loan) => loan.term.toString() === term?.split("_")?.[0],
          );

          setSelectedOfferLimit(selectedResult?.limit);
          setSelectedOfferLoan(selectedLoan);
          setValue("offer", undefined);
        }

        if (data[0]?.loans?.[0]?.guarantors?.requiredGuarantors?.length)
          setGuarantorsSetup(data[0].loans?.[0]?.guarantors);
      } catch (error) {
        OToastManager.danger(
          "Não foi possível realizar a simulação do crédito.",
        );
        navigate("/emprestimos/cliente/ofertas");
      } finally {
        setLoadingSimulation(false);
      }
    },
    [valueWatch, termWatch, dateWatch],
  );

  const selectOffer = useCallback(async () => {
    const { offer } = getValues();

    const selectedResult = simulationResult?.find((result) =>
      result.loans?.some((loan) => loan.identification === offer),
    );

    const selectedLoan = selectedResult?.loans.find(
      (loan) => loan.identification === offer,
    );

    setSelectedOfferLimit(selectedResult?.limit);
    setSelectedOfferLoan(selectedLoan);

    if (
      !!summary?.length &&
      summary.length < 2 &&
      summary?.[0]?.firstPaymentDate
    ) {
      setValue("date", summary?.[0]?.firstPaymentDate);
    } else {
      setValue("date", selectedLoan?.settlement.installments[0].maturityDate);
    }
  }, [offerWatch]);

  const getBankingAccounts = useCallback(async () => {
    try {
      setLoadingBankingAccounts(true);

      const { data } = await service.clerk.getBankingAccounts();

      const filteredAccounts = getBankAccountList(
        summary?.find((s) => s.id === selectedOfferLimit?.id),
        data,
      );

      setBankAccountsList(filteredAccounts);
    } finally {
      setLoadingBankingAccounts(false);
    }
  }, [setBankAccountsList, selectedOfferLimit, summary]);

  const disabledDate = useCallback(
    (current: moment.Moment) => {
      const canChangeWithMultipleOffers =
        !!summary?.find((s) => s.id === selectedOfferLimit?.id)
          ?.paymentDatesIntervals.length &&
        summary?.find((s) => s.id === selectedOfferLimit?.id)
          ?.isFirstPaymentDateChangeAvailable;

      const hasSingleOffer = !!summary?.length && summary.length < 2;

      const term = hasSingleOffer
        ? Number(termWatch?.split("_")?.[0])
        : termWatch;

      if (canChangeWithMultipleOffers && !hasSingleOffer) {
        return !summary
          ?.find((s) => s.id === selectedOfferLimit?.id)
          ?.paymentDatesIntervals?.filter(
            (i) => i.minTerm <= term && i?.maxTerm >= term,
          )
          .some((interval) =>
            current.isBetween(
              interval.minDate,
              moment(interval.maxDate).endOf("day"),
              undefined,
              "[]",
            ),
          );
      }

      if (hasSingleOffer) {
        return !summary[0]?.paymentDatesIntervals.some((interval) =>
          current.isBetween(
            interval.minDate,
            moment(interval.maxDate).endOf("day"),
            undefined,
            "[]",
          ),
        );
      }
      return false;
    },
    [summary, selectedOfferLimit, termWatch],
  );

  const getLoanDetails = useCallback(async () => {
    if (!loanId) return;

    const { data }: { data: LoanDetails } =
      await service.hubLoan.getLoanDetails(loanId.toString());

    if (data.status === LoanStatus.Cancelado) {
      OToastManager.danger(
        "Não foi possível seguir com a contratação desta linha de crédito.",
      );
      navigate("/emprestimos/cliente/ofertas");
    }

    setLoanDetails(data);
  }, [loanId, navigate]);

  const handleAcceptOffer = useCallback(async () => {
    try {
      setLoading(true);

      const hasDisbursement = !!selectedOfferLoan?.bankAccount;

      const { paymentMethod } = getValues();

      const payload = {
        identification: selectedOfferLoan?.identification,
        settlementType: paymentMethod,
        limitId: +(id || 0),
        offerId: +(offerId || 0),
      } as ConfirmationBody;

      const { bank } = getValues();

      const selectedBank = bankAccountsList.find((b) => b.id === bank);

      if (!hasDisbursement && !!selectedBank) {
        payload.accountDigit = selectedBank.accountDigit;
        payload.accountNumber = selectedBank.accountNumber;
        payload.agency = selectedBank.agency;
        payload.agencyDigit = selectedBank.agencyDigit;
        payload.bankCode = selectedBank.bankCode;
      }

      const { data } = await service.hubLoan.postConfirmSimulation(payload);

      setLoanId(data.id);

      await getLoanDetails();

      modalManager.show(REDIRECT_MODAL);
    } catch (e) {
      OToastManager.danger({
        title: "Não foi possível realizar a contratação",
        message: "Tente novamente mais tarde",
      });
    } finally {
      setLoading(false);
    }
  }, [
    selectedOfferLoan,
    id,
    offerId,
    bankAccountsList,
    getValues,
    getLoanDetails,
  ]);

  // validação de valor solicitado
  useEffect(() => {
    clearErrors();
    validateValue(valueWatch, summary);
  }, [valueWatch]);

  // simula após a mudança de data, valor ou número de parcelas
  // multiplas ofertas: só simula quando muda a data
  useEffect(() => {
    if (!!summary?.length && summary.length < 2) return;

    const { date } = getValues();

    if (!date) return;

    if (currentStepsMap?.[activeStep]?.title === "Ofertas") return;

    simulate();
  }, [dateWatch]);

  // oferta única: simula de cara sem passar o term
  const handleValueChange = useCallback(() => {
    if (!!summary?.length && summary.length >= 2) return;

    if (activeStep !== 0) return;

    const { value, date } = getValues();

    if (!value || !date) return;

    simulate(false);
    setValue("term", null);
  }, [valueWatch]);

  const debouncedValueChange = useMemo(() => {
    return debounce(handleValueChange, 800);
  }, [handleValueChange]);

  useEffect(() => {
    debouncedValueChange();

    return () => {
      debouncedValueChange.cancel();
    };
  }, [debouncedValueChange]);

  useEffect(() => {
    if (!!summary?.length && summary.length >= 2) return;

    if (activeStep != 0) return;

    const { date, value } = getValues();

    if (!date || !value) return;

    simulate(false);
    setValue("term", null);
  }, [dateWatch]);

  useEffect(() => {
    if (!!summary?.length && summary.length >= 2) return;

    const { term } = getValues();

    const selectedResult = simulationResult?.find((result) =>
      result.loans?.some(
        (loan) => loan.identification === term?.split("_")?.[1],
      ),
    );

    const selectedLoan = selectedResult?.loans.find(
      (loan) => loan.identification === term?.split("_")?.[1],
    );

    setSelectedOfferLimit(selectedResult?.limit);
    setSelectedOfferLoan(selectedLoan);
  }, [termWatch]);

  return (
    <SimulationContext.Provider
      value={{
        activeStep,
        setActiveStep,
        bankAccountsList,
        form,
        guarantors,
        id: +(id || 0),
        loading: isLoading,
        loadingSimulation,
        loadingBankingAccounts,
        simulationResult,
        summary,
        type: (type || "").toUpperCase(),
        disabledDate,
        setBankAccountsList,
        simulate,
        simulateButtonDisabled,
        selectOfferButtonDisabled,
        acceptOfferButtonDisabled,
        selectOffer,
        selectedOfferLoan,
        selectedOfferLimit,
        getBankingAccounts,
        landingPage,
        setLandingPage,
        handleAcceptOffer,
        loanDetails,
        loanId,
        getLoanDetails,
        setValue,
        currentStepsMap,
        showMoneyInAccountBadge,
      }}
    >
      {children}
    </SimulationContext.Provider>
  );
};

export const useSimulation = () => useContext(SimulationContext);
