import { IType, IIcon } from "@maestro/core";

type InvoiceStatusMap = {
  name: string;
  color: IType;
  iconName: IIcon;
};

export const productCreditLineMapper = {
  CARTAO_DE_CREDITO: "Cartão de Crédito",
  KGIRO: "Capital de Giro",
  LIMITE_MAIS: "Limite da conta",
  ENERGIA: "Energia",
  ANTECIPACAO_DE_RECEBIVEIS_CARTAO: "Antecipação de recebíveis de cartão",
};

export const invoiceStatusMap: Record<string, InvoiceStatusMap> = {
  PENDING: {
    name: "Em análise",
    color: "info",
    iconName: "orq-info-on",
  },
  APPROVED: {
    name: "Aprovado",
    color: "success",
    iconName: "orq-status-success",
  },
  DISAPPROVED: {
    name: "Reprovado",
    color: "danger",
    iconName: "orq-status-disapproved",
  },
};
