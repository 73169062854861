import {
  modalManager,
  OButton,
  OIcon,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  OUpload,
  OUploadInput,
  OUploadLabel,
  OUploadTip,
} from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { FileField } from "./_compose/file-field";

interface FilesModalProps {
  fieldName: `documents.${number}` | `representatives.${number}.document`;
  fields: Record<"id", string>[];
  handleAddFile: (file: File) => void;
  handleRemoveFile: (index: number) => void;
  loading: boolean;
}

export const FilesModal = ({
  fieldName,
  fields,
  handleAddFile,
  handleRemoveFile,
  loading,
}: FilesModalProps) => {
  const form = useFormContext<ScfDevelopers.UpdateCedenteCompleto>();
  const { getValues } = form;

  return (
    <OModal id={`${fieldName}-files-modal`} position="center">
      <OModalHeader>
        <OTypography>{`Arquivos do documento ${getValues(
          `${fieldName}.type`,
        )}`}</OTypography>
      </OModalHeader>
      <OModalBody>
        <div className="d-flex flex-column gap-3">
          <OUpload onAddFile={(e) => handleAddFile(e.detail)}>
            <OUploadInput
              id={`${fieldName}.addFile`}
              name={`${fieldName}.addFile`}
              disabled={loading}
              accept=".jpg, .jpeg, .png, .gif, .bmp, .pdf, .doc, .docx"
            />
            <OUploadLabel htmlFor={`${fieldName}.addFile`} size="md">
              Selecionar documento
              <OIcon category="orq" icon="orq-upload" size="lg" />
            </OUploadLabel>
            <OUploadTip>
              Serão aceitos documentos no formato .jpg, .jpeg, .png, .gif, .bmp,
              .pdf ou .doc/.docx
            </OUploadTip>
          </OUpload>
          {fields.map((field, fileIndex) => (
            <FileField
              index={fileIndex}
              fieldName={fieldName}
              fileKey={field.id}
              key={field.id}
              removeFile={handleRemoveFile}
            />
          ))}
        </div>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex gap-2 justify-content-end">
          <OButton
            type="dark"
            outline
            onClick={() => {
              modalManager.hide(`${fieldName}-files-modal`);
            }}
          >
            Fechar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
