import * as yup from "yup";

export interface CreateERPConnectionsFormValues {
  taxId: string;
  connections: string[];
}

export const createERPConnectionsFormSchema = yup.object().shape({
  taxId: yup.string().required("Campo obrigatório"),
  connections: yup.array().of(yup.string()).min(1, "Campo obrigatório"),
});

export const createERPConnectionsDefaultValues = {
  taxId: "",
  connections: [],
};
