import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { LimitManager } from "../pages/cliente/gestor-de-limites";
import { LimitManagerGuarantorsPage } from "../pages/cliente/gestor-de-limites/[id]/avalistas";
import { LimitManagerQuotesPage } from "../pages/cliente/gestor-de-limites/[id]/parcelas";
import { OffersPage } from "../pages/cliente/ofertas";
import { LandingPageWrapper } from "../pages/cliente/ofertas/landing-page/[type]/landing-page.page";
import { Simulation } from "../pages/cliente/ofertas/simulacao/[type]/[limitId]/oferta/[offerId]/simulation.page";
import { LoanOperations } from "../pages/cliente/operacoes";
import { OperationsContractsPage } from "../pages/cliente/operacoes/[id]/contratos/operations-contracts.page";
import { OperationsAgreementsPage } from "../pages/cliente/operacoes/[id]/convenio/operations-agreements.page";
import { OperationsDisbursementsPage } from "../pages/cliente/operacoes/[id]/desembolsos/operations-disbursements.page";
import { OperationsDetailsPage } from "../pages/cliente/operacoes/[id]/detalhes/operations-details.page";
import { OperationsLogsPage } from "../pages/cliente/operacoes/[id]/logs/operations-logs.page";
import { OperationsPaymentsPage } from "../pages/cliente/operacoes/[id]/pagamentos/operations-payments.page";
import { OperationsInstallmentsPage } from "../pages/cliente/operacoes/[id]/parcelas";
import { AnticipationPage } from "../pages/cliente/operacoes/[id]/parcelas/antecipacao/[contractNumber]/anticipation.page";
import { AnticipationSuccessPage } from "../pages/cliente/operacoes/[id]/parcelas/antecipacao/[contractNumber]/sucesso/anticipation-success.page";
import { OperationsPendenciesPage } from "../pages/cliente/operacoes/[id]/pendencias/operations-pendencies.page";
import { CreateERPConnectionsPage } from "../pages/produto/conexoes-erp/criar/create-erp-connection.page";
import { ERPConnections } from "../pages/produto/conexoes-erp/erp-connections.page";
import { AddBookPage } from "../pages/produto/contingencia/books-estrategias/adicionar-book/add-book.page";
import { AddStrategyPage } from "../pages/produto/contingencia/books-estrategias/adicionar-estrategia/add-strategy.page";
import { AddBookStrategyPage } from "../pages/produto/contingencia/books-estrategias/adicionar/add-book-strategy.page";
import { BooksAndStrategiesPage } from "../pages/produto/contingencia/books-estrategias/books-and-strategies.page";
import { AddCreditLineConfigurationPage } from "../pages/produto/contingencia/linhas-de-credito/adicionar/add-credit-line-configuration.page";
import { CreditLineConfigurationsPage } from "../pages/produto/contingencia/linhas-de-credito/credit-line-configurations.page";
import { UpdateCreditLineConfigurationPage } from "../pages/produto/contingencia/linhas-de-credito/[id]/update-credit-line-configuration.page";
import { LoanAddContractsConfigPage } from "../pages/produto/contingencia/templates-de-contrato/adicionar/loan-add-contracts-config.page";
import { LoanContractsConfigPage } from "../pages/produto/contingencia/templates-de-contrato/loan-contracts-config.page";
import { LoanContractsConfigDetails } from "../pages/produto/contingencia/templates-de-contrato/[loanContractsId]/detalhes/loan-contracts-config-details.page";
import { LoanEditContractsConfigPage } from "../pages/produto/contingencia/templates-de-contrato/[loanContractsId]/editar/loan-edit-contracts-config.page";
import { LoanAddConfigurationVariablePage } from "../pages/produto/contingencia/variaveis-de-configuracao/adicionar/loan-add-configuration-variable.page";
import { LoanConfigurationVariablePage } from "../pages/produto/contingencia/variaveis-de-configuracao/loan-configuration-variable.page";
import { LoanAddAgreementPage } from "../pages/produto/convenios/adicionar/loan-add-agreement.page";
import { LoanEquatePage } from "../pages/produto/convenios/cadastrar-perfis/loan-equate.page";
import { LoanAgreementsPage } from "../pages/produto/convenios/loan-agreements.page";
import { LoanEditAgreementPage } from "../pages/produto/convenios/[id]/editar/loan-edit-agreement.page";
import { LoanProductDashboardPage } from "../pages/produto/dashboard/loan-product-dashboard.page";
import { ECNPJs } from "../pages/produto/e-cnpjs/e-cnpjs.page";
import { CreateECNPJ } from "../pages/produto/e-cnpjs/registrar/create-e-cnpj.page";
import { LoanPage } from "../pages/produto/emprestimos/loan.page";
import { ContractGeneratorPage } from "../pages/produto/gerador-de-contratos/contract-generator.page";
import { LimitRequestsInactivesPage } from "../pages/produto/gestor-de-limites/limites-inativos/limit-requests-inactives.page";
import { AddLimitPage } from "../pages/produto/gestor-de-limites/limites-vigentes/adicionar/add-limit.page";
import { LimitsPage } from "../pages/produto/gestor-de-limites/limites-vigentes/limits.page";
import { FinancedAssetDetailsPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/ativos-financiados/detalhes/financed-asset-details.page";
import { FinancedAssetsPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/ativos-financiados/financed-assets.page";
import { AddGuarantorPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/avalistas/adicionar/add-guarantor.page";
import { GuarantorsPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/avalistas/guarantors.page";
import { LimitConditionsPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/condicoes/limit-conditions.page";
import { LimitUpdateAmountPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/contingencias/atualizar-valor/limit-update-amount.page";
import { LimitManagerContingenciesPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/contingencias/limit-manager-contingencies.page";
import { DisbursementPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/desembolso/disbursement.page";
import { LimitDetailsPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/detalhes/limit-details.page";
import { AddCollateralPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/garantias/adicionar/add-collateral.page";
import { CollateralsPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/garantias/collaterals.page";
import { UpdateCollateralPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/garantias/editar/[collateralId]/update-collateral.page";
import { ImovelAdditionalDataPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/garantias/[collateralId]/imovel/imovel-additional-data.page";
import { AgroAdditionalDataPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/garantias/[collateralId]/safra/agro-additional-data.page";
import { ExternalIdentificationsPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/identificacoes-externas/external-identifications.page";
import { EditLimitInvoicePage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/notas-fiscais/editar/edit-limit-invoices.page";
import { LimitInvoicePage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/notas-fiscais/limit-invoices.page";
import { AdditionalOffersPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/ofertas-adicionais/additional-offers.page";
import { AdditionalFeesPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/taxas-adicionais/additional-fees.page";
import { ContractTemplatesPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/templates-de-contrato/contract-templates.page";
import { AddTransactionPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/transacoes/adicionar/add-transaction.page";
import { TransactionsPage } from "../pages/produto/gestor-de-limites/limites-vigentes/[id]/transacoes/transactions.page";
import { LimitRequestsPage } from "../pages/produto/gestor-de-limites/solicitacoes-pendentes/limit-requests.page";
import { TradeFTSImportPage } from "../pages/produto/importacoes-de-trade/fts/trade-fts-import.page";
import { TradeImportsPage } from "../pages/produto/importacoes-de-trade/trade-imports.page";
import { LoanLogsPage } from "../pages/produto/logs/loan-logs.page";
import { LoanLogDetailsPage } from "../pages/produto/logs/[id]/loan-log-details.page";
import { PublishInvoicesRequestsPage } from "../pages/produto/notas-fiscais/exportacoes-notas-fiscais.page";
import { ExportInvoicesByContractNumberPage } from "../pages/produto/notas-fiscais/solicitar-exportacao/notas-fiscais-solicitar-exportacao.page";
import { AddPartnerPage } from "../pages/produto/parceiros/adicionar/add-partner.page";
import { EditPartnerPage } from "../pages/produto/parceiros/editar/edit-partner.page";
import { PartnersPage } from "../pages/produto/parceiros/partners.page";
import { PartnerAssociateAgreementPage } from "../pages/produto/parceiros/[partnerName]/[partnerId]/associar-convenio/partner-associate-agreement.page";
import { PartnerDetailsPage } from "../pages/produto/parceiros/[partnerName]/[partnerId]/partner-details.page";
import { InstallmentsPage } from "../pages/produto/parcelas/installments.page";
import { emprestimosRoles } from "../roles/emprestimos.roles";
import { emprestimosRouteParams } from "./emprestimos.route-params";

export const emprestimosRoutes = {
  customer: {
    name: "",
    path: "/emprestimos/cliente",
    element: <RedirectWithRoles />,
    children: {
      operations: {
        name: "Operações",
        path: "/emprestimos/cliente/operacoes",
        element: <LoanOperations />,
        role: emprestimosRoles.customer.operationsPage.role,
        showChildren: true,
        children: {
          details: {
            name: "Detalhes",
            path: `/emprestimos/cliente/operacoes/:${emprestimosRouteParams.id}/detalhes`,
            element: <OperationsDetailsPage />,
            role: emprestimosRoles.customer.operationsDetailsPage.role,
          },
          Contracts: {
            name: "Contratos",
            path: `/emprestimos/cliente/operacoes/:${emprestimosRouteParams.id}/contratos`,
            element: <OperationsContractsPage />,
            role: emprestimosRoles.customer.operationsContractsPage.role,
          },
          quotes: {
            name: "Parcelas",
            path: `/emprestimos/cliente/operacoes/:${emprestimosRouteParams.id}/parcelas`,
            element: <OperationsInstallmentsPage />,
            role: emprestimosRoles.customer.operationsQuotesPage.role,
            children: {
              anticipation: {
                path: `/emprestimos/cliente/operacoes/:${emprestimosRouteParams.id}/parcelas/antecipacao`,
                name: "Pré-pagamento",
                children: {
                  installmentAnticipation: {
                    name: "Parcelas",
                    path: `/emprestimos/cliente/operacoes/:${emprestimosRouteParams.id}/parcelas/antecipacao/:${emprestimosRouteParams.contractNumber}`,
                    element: <AnticipationPage />,
                    role: emprestimosRoles.customer
                      .operationsPrepaymentsConfirmPage.role,
                    children: {
                      success: {
                        name: "Boleto",
                        path: `/emprestimos/cliente/operacoes/:${emprestimosRouteParams.id}/parcelas/antecipacao/:${emprestimosRouteParams.contractNumber}/sucesso`,
                        element: <AnticipationSuccessPage />,
                        role: emprestimosRoles.customer
                          .operationsPrepaymentSuccessPage.role,
                      },
                    },
                  },
                },
              },
            },
          },
          payments: {
            name: "Pagamentos",
            path: `/emprestimos/cliente/operacoes/:${emprestimosRouteParams.id}/pagamentos`,
            element: <OperationsPaymentsPage />,
            role: emprestimosRoles.customer.operationsPaymentsPage.role,
          },
          disbursements: {
            name: "Desembolsos",
            path: `/emprestimos/cliente/operacoes/:${emprestimosRouteParams.id}/desembolsos`,
            element: <OperationsDisbursementsPage />,
            role: emprestimosRoles.customer.operationsDisbursementsPage.role,
          },
          agreements: {
            name: "Convênio",
            path: `/emprestimos/cliente/operacoes/:${emprestimosRouteParams.id}/convenio`,
            element: <OperationsAgreementsPage />,
            role: emprestimosRoles.customer.operationsAgreementsPage.role,
          },
          pendencies: {
            name: "Pendências",
            path: `/emprestimos/cliente/operacoes/:${emprestimosRouteParams.id}/pendencias`,
            element: <OperationsPendenciesPage />,
            role: emprestimosRoles.customer.operationsPendenciesPage.role,
          },
          logs: {
            name: "Logs",
            path: `/emprestimos/cliente/operacoes/:${emprestimosRouteParams.id}/logs`,
            element: <OperationsLogsPage />,
            role: emprestimosRoles.customer.operationsLogsPage.role,
          },
        },
      },
      offers: {
        name: "Ofertas",
        path: "/emprestimos/cliente/ofertas",
        element: <OffersPage />,
        role: emprestimosRoles.customer.offersPage.role,
        children: {
          details: {
            name: "Simulação",
            path: `/emprestimos/cliente/ofertas/simulacao/:${emprestimosRouteParams.type}/:${emprestimosRouteParams.id}/oferta/:${emprestimosRouteParams.offerId}`,
            element: <Simulation />,
            role: emprestimosRoles.customer.simulationsPage.role,
          },
          landingPage: {
            name: "Simulação - Landing Page",
            path: `/emprestimos/cliente/ofertas/landing-page/:${emprestimosRouteParams.type}`,
            element: <LandingPageWrapper />,
            role: emprestimosRoles.customer.simulationsPage.role,
          },
        },
      },
      limitManager: {
        name: "Gestor de limites",
        path: "/emprestimos/cliente/gestor-de-limites",
        element: <LimitManager />,
        role: emprestimosRoles.customer.limitManagerPage.role,
        children: {
          details: {
            name: "Detalhes",
            path: `/emprestimos/cliente/gestor-de-limites/:${emprestimosRouteParams.id}`,
            showChildren: true,
            children: {
              quotes: {
                name: "Parcelas",
                path: `/emprestimos/cliente/gestor-de-limites/:${emprestimosRouteParams.id}/parcelas`,
                element: <LimitManagerQuotesPage />,
                role: emprestimosRoles.customer.limitManagerDetailsQuotesPage
                  .role,
              },
              guarantors: {
                name: "Avalistas",
                path: `/emprestimos/cliente/gestor-de-limites/:${emprestimosRouteParams.id}/avalistas`,
                element: <LimitManagerGuarantorsPage />,
                role: emprestimosRoles.customer
                  .limitManagerDetailsGuarantorsPage.role,
              },
            },
          },
        },
      },
    },
  },
  product: {
    name: "",
    path: "/emprestimos/produto",
    element: <RedirectWithRoles />,
    children: {
      dashboard: {
        name: "Dashboard",
        path: "/emprestimos/produto/dashboard",
        element: <LoanProductDashboardPage />,
        role: emprestimosRoles.product.dashboardPage.role,
      },
      loan: {
        name: "Empréstimos",
        path: "/emprestimos/produto/emprestimos",
        element: <LoanPage />,
        role: emprestimosRoles.product.loanPage.role,
      },
      installments: {
        name: "Parcelas",
        path: "/emprestimos/produto/parcelas",
        element: <InstallmentsPage />,
        role: emprestimosRoles.product.installmentsPage.role,
      },
      tradeImports: {
        name: "Importações de Trade",
        path: "/emprestimos/produto/trade",
        element: <TradeImportsPage />,
        role: emprestimosRoles.product.tradeImportsPage.role,
        children: {
          fts: {
            name: "Importações de Trade - FTS",
            path: "/emprestimos/produto/trade/fts",
            element: <TradeFTSImportPage />,
            role: emprestimosRoles.product.tradeImportsFtsPage.role,
          },
        },
      },
      logs: {
        name: "Logs",
        path: "/emprestimos/produto/logs",
        element: <LoanLogsPage />,
        role: emprestimosRoles.product.logsPage.role,
        children: {
          details: {
            name: "Detalhes",
            path: `/emprestimos/produto/logs/:${emprestimosRouteParams.id}`,
            element: <LoanLogDetailsPage />,
            role: emprestimosRoles.product.logsDetailsPage.role,
          },
        },
      },
      partners: {
        name: "Parceiros",
        path: "/emprestimos/produto/parceiros",
        element: <PartnersPage />,
        role: emprestimosRoles.product.partnersPage.role,
        children: {
          add: {
            name: "Cadastrar",
            path: "/emprestimos/produto/parceiros/adicionar",
            element: <AddPartnerPage />,
            role: emprestimosRoles.product.partnersAddPage.role,
          },
          partner: {
            name: "Parceiro",
            path: `/emprestimos/produto/parceiros/:${emprestimosRouteParams.partnerName}`,
            children: {
              details: {
                name: "Detalhes",
                path: `/emprestimos/produto/parceiros/:${emprestimosRouteParams.partnerName}/:${emprestimosRouteParams.partnerId}`,
                showChildren: true,
                children: {
                  edit: {
                    name: "Editar",
                    path: `/emprestimos/produto/parceiros/:${emprestimosRouteParams.partnerName}/:${emprestimosRouteParams.partnerId}/editar`,
                    element: <EditPartnerPage />,
                    role: emprestimosRoles.product.partnersEditPage.role,
                  },
                  agreements: {
                    name: "Convênios Associados",
                    path: `/emprestimos/produto/parceiros/:${emprestimosRouteParams.partnerName}/:${emprestimosRouteParams.partnerId}/convenios`,
                    element: <PartnerDetailsPage />,
                    role: emprestimosRoles.product.partnersPartnerDetailsPage
                      .role,
                    children: {
                      associateAgreement: {
                        name: "Associar convênio",
                        path: `/emprestimos/produto/parceiros/:${emprestimosRouteParams.partnerName}/:${emprestimosRouteParams.partnerId}/convenios/associar-convenio`,
                        element: <PartnerAssociateAgreementPage />,
                        role: emprestimosRoles.product
                          .partnersPartnerDetailsAssociateAgreementPage.role,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      contingency: {
        name: "Contingência",
        path: "/emprestimos/produto/contingencia",
        hasSubItems: true,
        children: {
          configVariable: {
            name: "Variáveis de configuração",
            path: "/emprestimos/produto/contingencia/variaveis-de-configuracao",
            element: <LoanConfigurationVariablePage />,
            role: emprestimosRoles.product.contingencyConfigVariablePage.role,
            children: {
              create: {
                name: "Adicionar",
                path: "/emprestimos/produto/contingencia/variaveis-de-configuracao/adicionar",
                element: <LoanAddConfigurationVariablePage />,
                role: emprestimosRoles.product.contingencyAddConfigVariablePage
                  .role,
              },
            },
          },
          contractsConfig: {
            name: "Templates de contrato",
            path: "/emprestimos/produto/contingencia/templates-de-contrato",
            element: <LoanContractsConfigPage />,
            role: emprestimosRoles.product.contingencyContractsConfigPage.role,
            children: {
              add: {
                name: "Adicionar",
                path: "/emprestimos/produto/contingencia/templates-de-contrato/adicionar",
                element: <LoanAddContractsConfigPage />,
                role: emprestimosRoles.product.contingencyContractsConfigAddPage
                  .role,
              },
              details: {
                name: "Detalhes",
                path: `/emprestimos/produto/contingencia/templates-de-contrato/:${emprestimosRouteParams.loanContractsId}`,
                element: <LoanContractsConfigDetails />,
                role: emprestimosRoles.product
                  .contingencyContractsConfigDetailsPage.role,

                children: {
                  edit: {
                    name: "Editar",
                    path: `/emprestimos/produto/contingencia/templates-de-contrato/:${emprestimosRouteParams.loanContractsId}/editar`,
                    element: <LoanEditContractsConfigPage />,
                    role: emprestimosRoles.product
                      .contingencyContractsConfigDetailsEditPage.role,
                  },
                },
              },
            },
          },
          creditLinesConfigurations: {
            name: "Linhas de crédito",
            path: "/emprestimos/produto/contingencia/linhas-de-credito",
            element: <CreditLineConfigurationsPage />,
            role: emprestimosRoles.product
              .contingencyCreditLineConfigurationsPage.role,
            children: {
              add: {
                name: "Adicionar",
                path: "/emprestimos/produto/contingencia/linhas-de-credito/adicionar",
                element: <AddCreditLineConfigurationPage />,
                role: emprestimosRoles.product
                  .contingencyCreditLineConfigurationsAddPage.role,
              },
              update: {
                name: "Editar",
                path: `/emprestimos/produto/contingencia/linhas-de-credito/:${emprestimosRouteParams.id}`,
                element: <UpdateCreditLineConfigurationPage />,
                role: emprestimosRoles.product
                  .contingencyCreditLineConfigurationsUpdatePage.role,
              },
            },
          },
          booksAndStrategies: {
            name: "Books / Estratégias",
            path: "/emprestimos/produto/contingencia/books-estrategias",
            element: <BooksAndStrategiesPage />,
            role: emprestimosRoles.product.contingencyConfigVariablePage.role,
            children: {
              add: {
                name: "Adicionar",
                path: "/emprestimos/produto/contingencia/books-estrategias/adicionar",
                element: <AddBookStrategyPage />,
                role: emprestimosRoles.product
                  .contingencyBooksAndStrategiesAddPage.role,
              },
              addBook: {
                name: "Adicionar book",
                path: "/emprestimos/produto/contingencia/books-estrategias/adicionar-book",
                element: <AddBookPage />,
                role: emprestimosRoles.product
                  .contingencyBooksAndStrategiesAddBookPage.role,
              },
              addStrategy: {
                name: "Adicionar estratégia",
                path: "/emprestimos/produto/contingencia/books-estrategias/adicionar-estrategia",
                element: <AddStrategyPage />,
                role: emprestimosRoles.product
                  .contingencyBooksAndStrategiesAddStrategyPage.role,
              },
            },
          },
        },
      },
      agreements: {
        name: "Convênios",
        path: "/emprestimos/produto/convenios",
        element: <LoanAgreementsPage />,
        role: emprestimosRoles.product.agreementsPage.role,
        children: {
          add: {
            name: "Adicionar",
            path: "/emprestimos/produto/convenios/adicionar",
            element: <LoanAddAgreementPage />,
            role: emprestimosRoles.product.agreementsAddPage.role,
          },
          createProfile: {
            name: "Cadastrar Perfis",
            path: "/emprestimos/produto/convenios/cadastrar-perfis",
            element: <LoanEquatePage />,
            role: emprestimosRoles.product.agreementsCreateProfilePage.role,
          },
          details: {
            name: "Convênios",
            path: `/emprestimos/produto/convenios/:${emprestimosRouteParams.id}`,
            children: {
              edit: {
                name: "Editar",
                path: `/emprestimos/produto/convenios/:${emprestimosRouteParams.id}/editar`,
                element: <LoanEditAgreementPage />,
                role: emprestimosRoles.product.agreementsDetailsEditPage.role,
              },
            },
          },
        },
      },
      limitManager: {
        name: "Gestor de limites",
        path: "/emprestimos/produto/gestor-de-limites",
        hasSubItems: true,
        children: {
          limits: {
            name: "Limites Vigentes",
            path: "/emprestimos/produto/gestor-de-limites/limites-vigentes",
            element: <LimitsPage />,
            role: emprestimosRoles.product.limitManagerLimitsPage.role,

            children: {
              add: {
                name: "Adicionar",
                path: "/emprestimos/produto/gestor-de-limites/limites-vigentes/adicionar",
                element: <AddLimitPage />,
                role: emprestimosRoles.product.limitManagerLimitsAddPage.role,
              },
              details: {
                name: "Gestor de limite: Detalhes",
                path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}`,
                showChildren: true,
                children: {
                  summary: {
                    name: "Resumo",
                    path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/resumo`,
                    element: <LimitDetailsPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsSummaryPage.role,
                  },
                  conditions: {
                    name: "Condições",
                    path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/condicoes`,
                    element: <LimitConditionsPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsConditionsPage.role,
                  },
                  transactions: {
                    name: "Transações",
                    path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/transacoes`,
                    element: <TransactionsPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsTransactionsPage.role,

                    children: {
                      add: {
                        name: "Adicionar",
                        path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/transacoes/adicionar`,
                        element: <AddTransactionPage />,
                        role: emprestimosRoles.product
                          .limitManagerLimitsDetailsTransactionsAddPage.role,
                      },
                    },
                  },
                  guarantors: {
                    name: "Avalistas",
                    path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/avalistas`,
                    element: <GuarantorsPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsGuarantorsPage.role,

                    children: {
                      add: {
                        name: "Adicionar",
                        path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/avalistas/adicionar`,
                        element: <AddGuarantorPage />,
                        role: emprestimosRoles.product
                          .limitManagerLimitsDetailsGuarantorsAddPage.role,
                      },
                    },
                  },
                  invoices: {
                    name: "Notas fiscais",
                    path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/notas-fiscais`,
                    element: <LimitInvoicePage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsInvoicesPage.role,

                    children: {
                      edit: {
                        name: "Editar",
                        path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/notas-fiscais/editar`,
                        element: <EditLimitInvoicePage />,
                        role: emprestimosRoles.product
                          .limitManagerLimitsDetailsInvoicesEditPage.role,
                      },
                    },
                  },
                  financedAssets: {
                    name: "Ativos financiados",
                    path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/ativos-financiados`,
                    element: <FinancedAssetsPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsFinancedAssetsPage.role,

                    children: {
                      details: {
                        name: "Detalhes",
                        path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/ativos-financiados/detalhes`,
                        element: <FinancedAssetDetailsPage />,
                        role: emprestimosRoles.product
                          .limitManagerLimitsDetailsFinancedAssetsDetailsPage
                          .role,
                      },
                    },
                  },
                  collaterals: {
                    name: "Garantias",
                    path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/garantias`,
                    element: <CollateralsPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsCollateralsPage.role,

                    children: {
                      add: {
                        name: "Adicionar",
                        path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/garantias/adicionar`,
                        element: <AddCollateralPage />,
                        role: emprestimosRoles.product
                          .limitManagerLimitsDetailsCollateralsAddPage.role,
                      },
                      update: {
                        name: "Editar",
                        path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/garantias/editar/:${emprestimosRouteParams.collateralId}`,
                        element: <UpdateCollateralPage />,
                        role: emprestimosRoles.product
                          .limitManagerLimitsDetailsCollateralsUpdatePage.role,
                      },
                      details: {
                        name: "Garantias",
                        path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/garantias/:${emprestimosRouteParams.collateralId}`,
                        children: {
                          safra: {
                            name: "Safra",
                            path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/garantias/:${emprestimosRouteParams.collateralId}/safra`,
                            element: <AgroAdditionalDataPage />,
                            role: emprestimosRoles.product
                              .limitManagerLimitsDetailsCollateralsDetailsSafraPage
                              .role,
                          },
                          imovel: {
                            name: "Imóvel",
                            path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/garantias/:${emprestimosRouteParams.collateralId}/imovel`,
                            element: <ImovelAdditionalDataPage />,
                            role: emprestimosRoles.product
                              .limitManagerLimitsDetailsCollateralsDetailsImovelPage
                              .role,
                          },
                        },
                      },
                    },
                  },
                  additionalFees: {
                    name: "Taxas Adicionais",
                    path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/taxas-adicionais`,
                    element: <AdditionalFeesPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsAdditionalFeesPage.role,
                  },
                  disbursement: {
                    name: "Desembolso",
                    path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/desembolso`,
                    element: <DisbursementPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsDisbursementPage.role,
                  },
                  contractTemplates: {
                    name: "Templates de contrato",
                    path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/templates-de-contrato`,
                    element: <ContractTemplatesPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsContractTemplatesPage.role,
                  },
                  externalIdentifications: {
                    name: "Identificações externas",
                    path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/identificacoes-externas`,
                    element: <ExternalIdentificationsPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsExternalIdentificationsPage
                      .role,
                  },
                  additionalOffers: {
                    name: "Ofertas adicionais",
                    path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/ofertas-adicionais`,
                    element: <AdditionalOffersPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsAdditionalOffersPage.role,
                  },
                  contingencies: {
                    name: "Contingências",
                    path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/contingencias`,
                    element: <LimitManagerContingenciesPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsContingenciesPage.role,
                    children: {
                      updateAmount: {
                        name: "Atualizar valor",
                        path: `/emprestimos/produto/gestor-de-limites/limites-vigentes/:${emprestimosRouteParams.id}/contingencias/atualizar-valor`,
                        element: <LimitUpdateAmountPage />,
                        role: emprestimosRoles.product
                          .limitManagerLimitsDetailsContingenciesUpdateAmountPage
                          .role,
                      },
                    },
                  },
                },
              },
            },
          },
          limitRequests: {
            name: "Solicitações Pendentes",
            path: "/emprestimos/produto/gestor-de-limites/solicitacoes-pendentes",
            element: <LimitRequestsPage />,
            role: emprestimosRoles.product.limitManagerLimitRequestsPage.role,
          },
          limitRequestsInactives: {
            name: "Limites Inativos",
            path: "/emprestimos/produto/gestor-de-limites/limites-inativos",
            element: <LimitRequestsInactivesPage />,
            role: emprestimosRoles.product
              .limitManagerLimitRequestsInactivesPage.role,
            children: {
              details: {
                name: "Gestor de limite: Detalhes",
                path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}`,
                showChildren: true,
                children: {
                  summary: {
                    name: "Resumo",
                    path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/resumo`,
                    element: <LimitDetailsPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsSummaryPage.role,
                  },
                  conditions: {
                    name: "Condições",
                    path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/condicoes`,
                    element: <LimitConditionsPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsConditionsPage.role,
                  },
                  guarantors: {
                    name: "Avalistas",
                    path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/avalistas`,
                    element: <GuarantorsPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsGuarantorsPage.role,

                    children: {
                      add: {
                        name: "Adicionar",
                        path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/avalistas/adicionar`,
                        element: <AddGuarantorPage />,
                        role: emprestimosRoles.product
                          .limitManagerLimitsDetailsGuarantorsAddPage.role,
                      },
                    },
                  },
                  invoices: {
                    name: "Notas fiscais",
                    path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/notas-fiscais`,
                    element: <LimitInvoicePage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsInvoicesPage.role,

                    children: {
                      edit: {
                        name: "Editar",
                        path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/notas-fiscais/editar`,
                        element: <EditLimitInvoicePage />,
                        role: emprestimosRoles.product
                          .limitManagerLimitsDetailsInvoicesEditPage.role,
                      },
                    },
                  },
                  financedAssets: {
                    name: "Ativos financiados",
                    path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/ativos-financiados`,
                    element: <FinancedAssetsPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsFinancedAssetsPage.role,

                    children: {
                      details: {
                        name: "Detalhes",
                        path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/ativos-financiados/detalhes`,
                        element: <FinancedAssetDetailsPage />,
                        role: emprestimosRoles.product
                          .limitManagerLimitsDetailsFinancedAssetsDetailsPage
                          .role,
                      },
                    },
                  },
                  collaterals: {
                    name: "Garantias",
                    path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/garantias`,
                    element: <CollateralsPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsCollateralsPage.role,

                    children: {
                      add: {
                        name: "Adicionar",
                        path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/garantias/adicionar`,
                        element: <AddCollateralPage />,
                        role: emprestimosRoles.product
                          .limitManagerLimitsDetailsCollateralsAddPage.role,
                      },
                      update: {
                        name: "Editar",
                        path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/garantias/editar/:${emprestimosRouteParams.collateralId}`,
                        element: <UpdateCollateralPage />,
                        role: emprestimosRoles.product
                          .limitManagerLimitsDetailsCollateralsUpdatePage.role,
                      },
                      details: {
                        name: "Garantias",
                        path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/garantias/:${emprestimosRouteParams.collateralId}`,
                        children: {
                          safra: {
                            name: "Safra",
                            path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/garantias/:${emprestimosRouteParams.collateralId}/safra`,
                            element: <AgroAdditionalDataPage />,
                            role: emprestimosRoles.product
                              .limitManagerLimitsDetailsCollateralsDetailsSafraPage
                              .role,
                          },
                          imovel: {
                            name: "Imóvel",
                            path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/garantias/:${emprestimosRouteParams.collateralId}/imovel`,
                            element: <ImovelAdditionalDataPage />,
                            role: emprestimosRoles.product
                              .limitManagerLimitsDetailsCollateralsDetailsImovelPage
                              .role,
                          },
                        },
                      },
                    },
                  },
                  additionalFees: {
                    name: "Taxas Adicionais",
                    path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/taxas-adicionais`,
                    element: <AdditionalFeesPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsAdditionalFeesPage.role,
                  },
                  disbursement: {
                    name: "Desembolso",
                    path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/desembolso`,
                    element: <DisbursementPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsDisbursementPage.role,
                  },
                  contractTemplates: {
                    name: "Templates de contrato",
                    path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/templates-de-contrato`,
                    element: <ContractTemplatesPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsContractTemplatesPage.role,
                  },
                  externalIdentifications: {
                    name: "Identificações externas",
                    path: `/emprestimos/produto/gestor-de-limites/limites-inativos/:${emprestimosRouteParams.id}/identificacoes-externas`,
                    element: <ExternalIdentificationsPage />,
                    role: emprestimosRoles.product
                      .limitManagerLimitsDetailsExternalIdentificationsPage
                      .role,
                  },
                },
              },
            },
          },
        },
      },
      contractGenerator: {
        name: "Gerador de contratos",
        path: "/emprestimos/produto/gestor-de-contratos",
        element: <ContractGeneratorPage />,
        role: emprestimosRoles.product.contractGeneratorPage.role,
      },
      invoices: {
        name: "Notas fiscais",
        path: "/emprestimos/produto/exportacoes-notas-fiscais",
        element: <PublishInvoicesRequestsPage />,
        role: emprestimosRoles.product.exportInvoicesPage.role,
        children: {
          requestExport: {
            name: "Exportação de notas fiscais",
            path: "/emprestimos/produto/exportacoes-notas-fiscais/solicitar-exportacao",
            element: <ExportInvoicesByContractNumberPage />,
            role: emprestimosRoles.product.exportInvoicesRequestExportPage.role,
          },
        },
      },
      eCNPJs: {
        name: "Certificados e-CNPJ",
        path: "/emprestimos/produto/e-cnpjs",
        element: <ECNPJs />,
        role: emprestimosRoles.product.eCNPJsPage.role,
        children: {
          create: {
            name: "Registrar certificado e-CNPJ",
            path: "/emprestimos/produto/e-cnpjs/registrar",
            element: <CreateECNPJ />,
            role: emprestimosRoles.product.eCNPJsCreateECNPJPage.role,
          },
        },
      },
      erpConnections: {
        name: "Conexões ERP",
        path: "/emprestimos/produto/conexoes-erp",
        element: <ERPConnections />,
        role: emprestimosRoles.product.erpConnectionsPage.role,
        children: {
          create: {
            name: "Criar conexões ERP",
            path: "/emprestimos/produto/conexoes-erp/criar",
            element: <CreateERPConnectionsPage />,
            role: emprestimosRoles.product
              .erpConnectionsCreateERPConnectionsPage.role,
          },
        },
      },
    },
  },
} as const satisfies Routes;
