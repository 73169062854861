import { OCol, ORow, OSkeletonLine } from "@maestro/react";

export const ValuesAndConditionsSkeleton = () => {
  return (
    <div className="d-flex flex-column justify-content-start gap-3">
      <ORow>
        <OCol sm={4}>
          <div className="d-flex flex-column gap-2 w-100">
            <div className="w-20">
              <OSkeletonLine size="xs" />
            </div>
            <div className="w-100 ">
              <OSkeletonLine size="sm" />
            </div>
            <div className="w-50">
              <OSkeletonLine size="xs" />
            </div>
          </div>
        </OCol>
        <OCol sm={4}>
          <div className="d-flex flex-column gap-1 w-100">
            <div className="w-20">
              <OSkeletonLine size="xs" />
            </div>
            <div className="w-100 ">
              <OSkeletonLine size="sm" />
            </div>
            <div className="w-50">
              <OSkeletonLine size="xs" />
            </div>
          </div>
        </OCol>
        <OCol sm={4}>
          <div className="d-flex flex-column gap-1 w-100">
            <div className="w-20">
              <OSkeletonLine size="xs" />
            </div>
            <div className="w-100 ">
              <OSkeletonLine size="sm" />
            </div>
            <div className="w-50">
              <OSkeletonLine size="xs" />
            </div>
          </div>
        </OCol>
      </ORow>
      <div className="w-100">
        <OSkeletonLine size="xxxl" />
      </div>
      <div className="w-15">
        <OSkeletonLine />
      </div>
    </div>
  );
};
