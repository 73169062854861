import { useEffect, useMemo } from "react";
import {
  modalManager,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";
import range from "lodash/range";
import { masks } from "@maestro/utils";
import { PageTitle } from "components/page-title";
import { rateIndexerMask } from "utils/mask";
import { SimulationFooter } from "../../footer/footer.component";
import { StyledButton, TotalValueBadge } from "../conditions/conditions.styles";
import { CalendarModal } from "../../calendar-modal/calendar-modal.component";
import { ValuesAndConditionsSkeleton } from "./compose/skeleton/values-and-conditions-skeleton.component";
import { useSimulation } from "../../../simulation.context";
import { DatePickerWithoutIconIfDisabled } from "../steps-components.styles";

export const ValuesAndConditions = () => {
  const {
    summary,
    simulate,
    simulateButtonDisabled,
    type,
    disabledDate,
    selectedOfferLoan,
    simulationResult,
    setValue,
    loadingSimulation,
  } = useSimulation();

  const multipleOffersInstallmentOptions = uniq(
    sortBy(
      summary?.flatMap((s) =>
        s.rate.quotes.flatMap(
          (q) =>
            q.validatedTermOptions ??
            range(Number(q.minTerm), Number(q.maxTerm + 1)),
        ),
      ),
    ),
  );

  const singleOfferInstallmentOptions = sortBy(
    simulationResult.flatMap((r) => r.loans),
    [
      function (r) {
        return r.settlement.installments.length;
      },
    ],
  );

  const showSingleOffer = useMemo(() => {
    return !!summary?.length && summary.length < 2;
  }, [summary]);

  const showSimulationResult = useMemo(
    () => showSingleOffer && !!selectedOfferLoan,
    [showSingleOffer, selectedOfferLoan],
  );

  const allowValueChange = !["AGRO", "SOLAR", "SOFTWARE_FINANCING"].includes(
    type,
  );

  const handleContinue = () => (showSingleOffer ? null : simulate());

  useEffect(() => {
    if (!showSingleOffer) {
      setValue(
        "term",
        multipleOffersInstallmentOptions[
          multipleOffersInstallmentOptions.length - 1
        ].toString(),
      );
    }
  }, [summary]);

  useEffect(() => {
    if (showSingleOffer) {
      const option =
        singleOfferInstallmentOptions[singleOfferInstallmentOptions.length - 1];
      setValue("term", `${option?.term}_${option?.identification}`);
    }
  }, [simulationResult]);

  return (
    <>
      <PageTitle
        title="Informe os valores e as condições do empréstimo"
        description="Preencha o valor e a quantidade de parcelas para simulação."
      />
      <OCard>
        <OCardBody>
          {loadingSimulation ? (
            <ValuesAndConditionsSkeleton />
          ) : (
            <ORow>
              <OCol>
                <ORFieldInput
                  id="value"
                  name="value"
                  tag="currency"
                  symbol="R$"
                  label="Valor solicitado"
                  description={
                    allowValueChange && summary?.[0]?.limit
                      ? `Valor máximo disponível de ${masks.currency(
                          summary?.[0]?.limit,
                          "R$",
                        )}`
                      : ""
                  }
                  readonly={!allowValueChange}
                />
              </OCol>
              {showSingleOffer && (
                <OCol>
                  <DatePickerWithoutIconIfDisabled
                    id="date"
                    name="date"
                    label="Primeiro vencimento"
                    size="md"
                    disabledDate={disabledDate}
                    disabled={!summary?.[0]?.isFirstPaymentDateChangeAvailable}
                    allowClear={summary?.[0]?.isFirstPaymentDateChangeAvailable}
                  />
                </OCol>
              )}
              {!showSingleOffer ? (
                <OCol>
                  <ORFieldSelect
                    id="term"
                    name="term"
                    label="Quantidade de parcelas"
                    placeholder="Selecione:"
                    description="Selecione a proposta de acordo com sua necessidade."
                  >
                    {multipleOffersInstallmentOptions.map((installment) => (
                      <OOption key={installment} value={`${installment}`}>
                        {installment === 1
                          ? `${installment} parcela`
                          : `${installment} parcelas`}
                      </OOption>
                    ))}
                  </ORFieldSelect>
                </OCol>
              ) : (
                <OCol>
                  <ORFieldSelect
                    id="term"
                    name="term"
                    label="Quantidade de parcelas"
                    placeholder="Selecione:"
                    description="Selecione a proposta de acordo com sua necessidade."
                    key={`${singleOfferInstallmentOptions
                      .map((i) => i.identification)
                      .join("_")}`}
                  >
                    {singleOfferInstallmentOptions.map((installment) => {
                      const amount = masks.currency(
                        installment.settlement.installments[0].amount,
                        "R$",
                      );

                      const termText =
                        installment.term === 1 ? "parcela" : "parcelas";

                      return (
                        <OOption
                          key={installment.identification}
                          value={`${installment.term}_${installment.identification}`}
                        >
                          {`${
                            installment.term
                          } ${termText} de ${amount} com taxa de ${rateIndexerMask(
                            installment.rate,
                            installment.interest.indexCode,
                          )}`}
                        </OOption>
                      );
                    })}
                  </ORFieldSelect>
                </OCol>
              )}
            </ORow>
          )}

          {showSimulationResult && (
            <div className="d-flex flex-column gap-2 mt-2">
              <CalendarModal
                id={`calendar-modal-${selectedOfferLoan?.identification}`}
                selectedOfferLoan={selectedOfferLoan}
              />
              <TotalValueBadge>
                <div className="d-flex gap-2">
                  <OIcon size="xl" category="orq" icon="orq-payment-bills" />
                  <OTypography>Total a prazo</OTypography>
                </div>
                <OTypography>
                  {masks.currency(selectedOfferLoan?.settlement.amount, "R$")}
                </OTypography>
              </TotalValueBadge>
              <StyledButton
                bordered={false}
                outline
                onClick={() => {
                  modalManager.show(
                    `calendar-modal-${selectedOfferLoan?.identification}`,
                  );
                }}
              >
                <div className="d-flex gap-1 align-items-center">
                  Consultar parcelas
                  <OIcon category="orq" icon="orq-chevron-right" />
                </div>
              </StyledButton>
            </div>
          )}
        </OCardBody>
      </OCard>
      <SimulationFooter
        continueButtonDisabled={simulateButtonDisabled}
        continueAction={handleContinue}
      />
    </>
  );
};
