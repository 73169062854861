import { OCol, ORow, OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services/service";
import { MultiSelectAll } from "../../../../../../components/multi-select-all";
import {
  integrationDocumentsMap,
  scfDevelopersOnboardingStatusMap,
} from "../../../../../../utils/scf-configuration-maps";
import { DomainScfFormFields } from "../../domain-configuration-scf.form";
import { requiredFieldsMap } from "../../domain-configuration-scf.utils";

export const MultiSelectFields = () => {
  const [possibleFields, setPossibleFields] = useState<string[]>([]);

  const form = useFormContext<DomainScfFormFields>();
  const { setValue, watch } = form;

  const watchAllowedReprocessStatuses = watch("allowedReprocessStatuses");
  const watchRequiredOnboardingDocuments = watch("requiredOnboardingDocuments");
  const watchRequestRequiredFields = watch("requestRequiredFields");

  const changeAllowedStatus = (values: string[]) =>
    setValue("allowedReprocessStatuses", values);

  const changeRequiredDocuments = (values: ScfDevelopers.PayerDocumentType[]) =>
    setValue("requiredOnboardingDocuments", values);

  const changeRequiredFields = (values: string[]) =>
    setValue("requestRequiredFields", values);

  const fetchFields = useCallback(async () => {
    try {
      const { data } = await service.scfDevelopers.getFields();

      setPossibleFields(data);
    } catch {
      OToastManager.danger(
        "Não foi possível obter os campos possíveis de serem obrigatórios.",
      );
    }
  }, []);

  useEffect(() => {
    fetchFields();
  }, [fetchFields]);

  return (
    <ORow>
      <OCol xs={4}>
        <MultiSelectAll
          id="allowedReprocessStatuses"
          items={Object.keys(scfDevelopersOnboardingStatusMap)}
          label="Status permitidos para reprocessamento de onboarding"
          mapping={scfDevelopersOnboardingStatusMap}
          selected={watchAllowedReprocessStatuses}
          setSelected={changeAllowedStatus}
        />
      </OCol>
      <OCol xs={4}>
        <MultiSelectAll
          id="requiredOnboardingDocuments"
          items={Object.keys(integrationDocumentsMap)}
          label="Documentos necessários"
          mapping={integrationDocumentsMap}
          selected={watchRequiredOnboardingDocuments}
          setSelected={changeRequiredDocuments}
        />
      </OCol>
      <OCol xs={4}>
        <MultiSelectAll
          id="requestRequiredFields"
          items={possibleFields}
          label="Campos obrigatórios da request"
          mapping={requiredFieldsMap}
          selected={watchRequestRequiredFields}
          setSelected={changeRequiredFields}
        />
      </OCol>
    </ORow>
  );
};
