import { env } from "utils/environments";

export const hubloanEndpoints = {
  odata: {
    failedRequests: `${env.API_LOAN}/odata/FailedRequests`,
    tradeImportsAdmin: `${env.API_LOAN}/odata/TradeImportsAdmin`,
    installmentsDueDatesAdmin: `${env.API_LOAN}/odata/InstallmentsDueDatesAdmin`,
    customerCertificateAdmin: `${env.API_LOAN}/odata/CustomerCertificateAdmin`,
    loanAdmin: `${env.API_LOAN}/odata/LoanAdmin`,
  },
  admin: {
    customer: {
      history: `${env.API_LOAN}/api/admin/customer/history`,
      contractByIdDownlod: (contractId: string) =>
        `${env.API_LOAN}/api/admin/customer/contract/${contractId}/download`,
      loanById: (loanId: string) =>
        `${env.API_LOAN}/api/admin/customer/loan/${loanId}`,
    },
    failedRequestById: (id: number | string) =>
      `${env.API_LOAN}/api/admin/failedrequest/${id}`,

    arrangement: {
      api: `${env.API_LOAN}/api/admin/arrangement`,
      byPartnerName: (partnerName: string) =>
        `${env.API_LOAN}/api/admin/arrangement/${partnerName}`,
      idById: (id: string | number) =>
        `${env.API_LOAN}/api/admin/arrangement/id/${id}`,
    },
    agreement: {
      api: `${env.API_LOAN}/api/admin/agreement`,
      types: `${env.API_LOAN}/api/admin/agreement/types`,
      fund: `${env.API_LOAN}/api/admin/agreement/fund`,
      rating: `${env.API_LOAN}/api/admin/agreement/rating`,
      sourceSystem: `${env.API_LOAN}/api/admin/agreement/source-system`,
      tradeType: `${env.API_LOAN}/api/admin/agreement/trade-type`,
      warrantyDocumentSystem: `${env.API_LOAN}/api/admin/agreement/warranty-document-system`,
      additionalDisbursementAccountType: `${env.API_LOAN}/api/admin/agreement/additional-disbursement-account-type`,
      financeOrigin: `${env.API_LOAN}/api/admin/agreement/finance-origin`,
    },
    products: `${env.API_LOAN}/api/admin/products`,
    loanContractsConfig: {
      api: `${env.API_LOAN}/api/admin/loan-contracts-config`,
      all: `${env.API_LOAN}/api/admin/loan-contracts-config/all`,
      byId: (id: string) =>
        `${env.API_LOAN}/api/admin/loan-contracts-config/${id}`,
    },
    partner: `${env.API_LOAN}/api/admin/partner`,
    partnerById: (id: number | string) =>
      `${env.API_LOAN}/api/admin/partner/${id}`,
    configurationVariable: {
      api: `${env.API_LOAN}/api/admin/configuration-variable`,
      all: `${env.API_LOAN}/api/admin/configuration-variable/all`,
      create: `${env.API_LOAN}/api/admin/configuration-variable/create`,
    },
    contractTemplateConfig: {
      api: `${env.API_LOAN}/api/admin/contract-template-config`,
      byId: (id: number) =>
        `${env.API_LOAN}/api/admin/contract-template-config/${id}`,
      getByProductAndTaxId: `${env.API_LOAN}/api/admin/contract-template-config/get-by-product-and-tax-id`,
    },
    contractGenerator: {
      api: `${env.API_LOAN}/api/admin/contract-generator`,
      downloadMapping: `${env.API_LOAN}/api/admin/contract-generator/download-mapping`,
      guarantees: `${env.API_LOAN}/api/admin/contract-generator/guarantees`,
    },
    company: {
      uploadContractTemplate: `${env.API_LOAN}/api/admin/company/upload-contract-template`,
      upload: `${env.API_LOAN}/api/admin/company/upload`,
      download: `${env.API_LOAN}/api/admin/company/download`,
      byIdReadiness: (id: string) =>
        `${env.API_LOAN}/api/admin/company/${id}/readiness`,
    },
    simulation: {
      autoCreate: `${env.API_LOAN}/api/admin/simulation/auto/create`,
      autoReadiness: `${env.API_LOAN}/api/admin/simulation/auto/readiness`,
    },
    contractById: (id: string) => `${env.API_LOAN}/api/admin/contract/${id}`,
    updateContractStatus: `${env.API_LOAN}/api/admin/contract/status`,
    contract: {
      publishCorrelationIds: `${env.API_LOAN}/api/admin/contract/publish-correlation-ids`,
    },
    loan: {
      byIdDetails: (id: string) =>
        `${env.API_LOAN}/api/admin/loan/${id}/details`,
      byIdCancel: (loanId: number | string) =>
        `${env.API_LOAN}/api/admin/loan/${loanId}/cancel`,
      byIdDisbursements: (id: string) =>
        `${env.API_LOAN}/api/admin/loan/${id}/disbursements`,
      byIdUpdatePosition: (id: string) =>
        `${env.API_LOAN}/api/admin/loan/${id}/update-position`,
      status: `${env.API_LOAN}/api/admin/loan/status`,
      integrationLog: `${env.API_LOAN}/api/admin/loan/integration-log`,
      republish: `${env.API_LOAN}/api/admin/loan/republish`,
      pendencyByIdResolve: (pendencyId: number | string) =>
        `${env.API_LOAN}/api/admin/loan/pendency/${pendencyId}/resolve`,
      byIdPendencyResolveAll: (loanId: number | string) =>
        `${env.API_LOAN}/api/admin/loan/${loanId}/pendency/resolve-all`,
      businessApprove: `${env.API_LOAN}/api/admin/loan/business/approve`,
      possibleStatusByLoanId: (loanId: number | string) =>
        `${env.API_LOAN}/api/admin/loan/${loanId}/status`,
    },
    disbursement: {
      value: `${env.API_LOAN}/api/admin/disbursement/value`,
    },
    trades: {
      import: `${env.API_LOAN}/api/admin/trades/import`,
    },
    dashboard: `${env.API_LOAN}/api/admin/dashboard`,
  },
  companyProducts: `${env.API_LOAN}/api/company/products`,
  loanByIdDetails: (id: number | string) =>
    `${env.API_LOAN}/api/loan/${id}/details`,
  simulationAccept: `${env.API_LOAN}/api/simulation/accept`,
  simulation: `${env.API_LOAN}/api/simulation/simulate`,
  simulationV2: `${env.API_LOAN}/api/simulation/simulate/v2`,
  frameTaxId: `${env.API_LOAN}/api/simulation/frame`,
  simulationSummary: (product: string, id: string, offerId?: string) =>
    `${env.API_LOAN}/api/simulation/summary?product=${product}&id=${id}&offerId=${offerId}`,
  simulationSummaryV2: (product: string, id: string, offerId?: string) =>
    `${env.API_LOAN}/api/simulation/summary/v2?product=${product}&id=${id}&offerId=${offerId}`,
  reportsMainreportSummary: `${env.API_LOAN}/api/reports/mainreport/summary`,
};
