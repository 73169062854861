import { OButton } from "@maestro/react";
import { StandardLink } from "components/standard-link";
import { modalManagerPayload } from "utils/modal-manager-payload";
import { editPropertyValueModalId } from "../../../edit-property-value-modal";
import { DownloadFileButton } from "./download-file-button.component";

interface DocumentCellProps {
  property: HubEnergy.DiligenceStepPropertyResponse;
}

export const DocumentCell = ({ property }: DocumentCellProps) => {
  const numberOfFiles = property.document?.files?.length ?? 0;
  const firstFile = property.document?.files?.[0];

  if (property.type.name === "DOCUMENTO_ASSINATURA_EXTERNA") {
    return property.value ? (
      <StandardLink href={property.value}>{property.value}</StandardLink>
    ) : null;
  }

  return (
    <div className="d-flex flex-column align-items-start gap-1">
      {firstFile && (
        <DownloadFileButton
          key={firstFile.key}
          fileName={firstFile.name}
          fileKey={firstFile.key}
        />
      )}
      {numberOfFiles > 1 && (
        <OButton
          type="dark"
          outline
          onClick={() =>
            modalManagerPayload.show(editPropertyValueModalId, property)
          }
        >
          Ver todos
        </OButton>
      )}
    </div>
  );
};
