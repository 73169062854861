import { WorkflowConfigurationFormField } from "./workflow-configuration-scf.form";

export const workflowStepTypeMap: Record<
  ScfDevelopers.IntegrationType,
  Partial<Record<ScfDevelopers.WorkflowStepType, string>>
> = {
  CEDENTE_SIMPLES: {
    CEDENTE_SIMPLES_COMPLEMENT_VALIDATE: "Complemento",
    CEDENTE_SIMPLES_LESS: "LESS",
    CEDENTE_SIMPLES_CLERK: "Clerk",
  },
  TOMADOR_DE_CREDITO: {
    TOMADOR_CREDITO_COMPLEMENT_VALIDATE: "Complemento",
    TOMADOR_CREDITO_RISKANALYSIS: "Análise de risco",
    TOMADOR_CREDITO_LESS: "LESS",
    TOMADOR_CREDITO_CLERK: "Clerk",
    TOMADOR_CREDITO_WEBHOOK_DEVELOPERS: "Webhook developers",
  },
};

export const requiredFieldsMap = {
  NAME: "Nome",
  PHONENUMBER: "Telefone",
  EMAILADDRESS: "E-mail",
  BIRTHDATE: "Data de nascimento",
  ADDRESSES: "Endereço",
};

export const generateUuidV4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const buildWorkflowConfigurationsIntoForm = (
  configurations: ScfDevelopers.WorkflowConfiguration[],
) => {
  return configurations.map((config) => {
    if (config.stepType === "CEDENTE_SIMPLES_COMPLEMENT_VALIDATE") {
      return {
        stepId: config.stepId,
        stepType:
          "CEDENTE_SIMPLES_COMPLEMENT_VALIDATE" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
      };
    }
    if (config.stepType === "CEDENTE_SIMPLES_LESS") {
      return {
        stepId: config.stepId,
        stepType: "CEDENTE_SIMPLES_LESS" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
        segment: config.segment,
        accountType: config.accountType,
        mainOfficerName: config.mainOfficerName,
        mainOfficerCGE: config.mainOfficerCGE,
        partyGroupTypeIds: config.partyGroupTypeIds,
        skipValidationAlreadyIfExistsPartyWithRelationship:
          config.skipValidationAlreadyIfExistsPartyWithRelationship
            ? ["true"]
            : [],
      };
    }
    if (config.stepType === "CEDENTE_SIMPLES_CLERK") {
      return {
        stepId: config.stepId,
        stepType: "CEDENTE_SIMPLES_CLERK" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
      };
    }
    if (config.stepType === "TOMADOR_CREDITO_COMPLEMENT_VALIDATE") {
      return {
        stepId: config.stepId,
        stepType:
          "TOMADOR_CREDITO_COMPLEMENT_VALIDATE" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
      };
    }
    if (config.stepType === "TOMADOR_CREDITO_RISKANALYSIS") {
      return {
        stepId: config.stepId,
        stepType:
          "TOMADOR_CREDITO_RISKANALYSIS" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
        segment: config.segment,
        coSegment: config.coSegment,
        riskAnalysisType: config.riskAnalysisType,
      };
    }
    if (config.stepType === "TOMADOR_CREDITO_LESS") {
      return {
        stepId: config.stepId,
        stepType: "TOMADOR_CREDITO_LESS" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
        segment: config.segment,
        accountType: config.accountType,
        mainOfficerName: config.mainOfficerName,
        mainOfficerCGE: config.mainOfficerCGE,
        partyGroupTypeIds: config.partyGroupTypeIds,
        skipValidationAlreadyIfExistsPartyWithRelationship:
          config.skipValidationAlreadyIfExistsPartyWithRelationship
            ? ["true"]
            : [],
      };
    }
    if (config.stepType === "TOMADOR_CREDITO_CLERK") {
      return {
        stepId: config.stepId,
        stepType: "TOMADOR_CREDITO_CLERK" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
      };
    }
    if (config.stepType === "TOMADOR_CREDITO_WEBHOOK_DEVELOPERS") {
      return {
        stepId: config.stepId,
        stepType:
          "TOMADOR_CREDITO_WEBHOOK_DEVELOPERS" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive ? ["true"] : [],
        _t: config._t,
      };
    }
    return {};
  });
};

export const buildWorkflowConfigurationsFromForm = (
  configurations: WorkflowConfigurationFormField[],
) => {
  const formattedConfigurations = configurations.map((config) => {
    if (config.stepType === "CEDENTE_SIMPLES_COMPLEMENT_VALIDATE") {
      return {
        stepId: config.stepId,
        stepType:
          "CEDENTE_SIMPLES_COMPLEMENT_VALIDATE" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
      };
    }
    if (config.stepType === "CEDENTE_SIMPLES_LESS") {
      return {
        stepId: config.stepId,
        stepType: "CEDENTE_SIMPLES_LESS" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId ?? null,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
        segment: config.segment,
        accountType: config.accountType,
        mainOfficerName: config.mainOfficerName,
        mainOfficerCGE: config.mainOfficerCGE,
        partyGroupTypeIds: config.partyGroupTypeIds,
        skipValidationAlreadyIfExistsPartyWithRelationship:
          config.skipValidationAlreadyIfExistsPartyWithRelationship.includes(
            "true",
          ),
      };
    }
    if (config.stepType === "CEDENTE_SIMPLES_CLERK") {
      return {
        stepId: config.stepId,
        stepType: "CEDENTE_SIMPLES_CLERK" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
      };
    }
    if (config.stepType === "TOMADOR_CREDITO_COMPLEMENT_VALIDATE") {
      return {
        stepId: config.stepId,
        stepType:
          "TOMADOR_CREDITO_COMPLEMENT_VALIDATE" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
      };
    }
    if (config.stepType === "TOMADOR_CREDITO_RISKANALYSIS") {
      return {
        stepId: config.stepId,
        stepType:
          "TOMADOR_CREDITO_RISKANALYSIS" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
        segment: config.segment,
        coSegment: config.coSegment,
        riskAnalysisType: config.riskAnalysisType,
      };
    }
    if (config.stepType === "TOMADOR_CREDITO_LESS") {
      return {
        stepId: config.stepId,
        stepType: "TOMADOR_CREDITO_LESS" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
        segment: config.segment,
        accountType: config.accountType,
        mainOfficerName: config.mainOfficerName,
        mainOfficerCGE: config.mainOfficerCGE,
        partyGroupTypeIds: config.partyGroupTypeIds,
        skipValidationAlreadyIfExistsPartyWithRelationship:
          config.skipValidationAlreadyIfExistsPartyWithRelationship.includes(
            "true",
          ),
      };
    }
    if (config.stepType === "TOMADOR_CREDITO_CLERK") {
      return {
        stepId: config.stepId,
        stepType: "TOMADOR_CREDITO_CLERK" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
      };
    }
    if (config.stepType === "TOMADOR_CREDITO_WEBHOOK_DEVELOPERS") {
      return {
        stepId: config.stepId,
        stepType:
          "TOMADOR_CREDITO_WEBHOOK_DEVELOPERS" as ScfDevelopers.WorkflowStepType,
        nextStepId: config.nextStepId,
        isStepActive: config.isStepActive.includes("true"),
        _t: config._t,
      };
    }
    return {};
  });

  const getNextStepId = (index: number, configs: any[]) => {
    if (index === configs.length) {
      return null;
    }

    return configs[index].stepId;
  };

  return formattedConfigurations.map((config, index, configs) => {
    return {
      ...config,
      nextStepId: getNextStepId(index + 1, configs),
    };
  });
};
