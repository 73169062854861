import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

export const useErrorsList = (
  representativeIndex: number,
  removeAll: () => void,
  rejections?: ScfDevelopers.CedenteCompletoRejection,
) => {
  const [alreadyFilled, setAlreadyFilled] = useState(false);

  const form = useFormContext();
  const {
    formState: { errors },
    getValues,
    setError,
  } = form;

  const documents: ScfDevelopers.CedenteCompletoDocument = getValues(
    `representatives.${representativeIndex}.document`,
  );

  const genericOcurrences: string[] = useMemo(
    () => rejections?.rejectionReasons ?? [],
    [rejections],
  );

  const documentOcurrences: string[] = useMemo(() => [], []);
  useEffect(() => {
    if (alreadyFilled) {
      return;
    }

    if (
      rejections?.documentRejectionReasons &&
      rejections.documentRejectionReasons.length > 0
    ) {
      setAlreadyFilled(true);
    }

    rejections?.documentRejectionReasons.forEach((documentOcurrence) => {
      documentOcurrence.files.forEach((fileOcurrence) => {
        documentOcurrences.push(
          `${documentOcurrence.documentType}: ${fileOcurrence.message}`,
        );
        if (
          documents.type === documentOcurrence.documentType &&
          !errors[`representatives.${representativeIndex}.document`]
        ) {
          removeAll();
          setError(`representatives.${representativeIndex}.document`, {
            type: "custom",
            message: fileOcurrence.message,
          });
        }
      });
    });
  }, [
    alreadyFilled,
    documentOcurrences,
    documents,
    errors,
    rejections,
    setError,
    getValues,
    representativeIndex,
    removeAll,
  ]);

  const ocurrences = [...genericOcurrences, ...documentOcurrences];

  return {
    ocurrences,
  };
};
