import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useReactFlow } from "reactflow";
import { CanvaSearchBarProps } from "./canva-search-bar.types";
import { matchesSearchCriteria } from "./canva-search-bar.utils";

export const useCanvaSearchBar = ({ nodes }: CanvaSearchBarProps) => {
  const { setCenter } = useReactFlow();

  const [selected, setSelected] = useState(false);
  const [matchedNodeIndex, setMatchedNodeIndex] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const prevSearchValue = useRef(searchValue);
  const prevMatchedIndex = useRef(matchedNodeIndex);

  const filteredNodes = useMemo(() => {
    const filteredData =
      nodes?.filter((node) => matchesSearchCriteria(node.data, searchValue)) ||
      [];

    const matchedNode = filteredData[matchedNodeIndex];

    if (
      searchValue &&
      (prevSearchValue.current !== searchValue ||
        prevMatchedIndex.current !== matchedNodeIndex)
    ) {
      prevSearchValue.current = searchValue;
      prevMatchedIndex.current = matchedNodeIndex;

      matchedNode && setCenter(matchedNode.position.x, matchedNode.position.y);
    }

    return filteredData;
  }, [matchedNodeIndex, nodes, searchValue, setCenter]);

  const activeArrows = !!searchValue && !!filteredNodes?.length;

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLOInputTextElement>) => {
      if (!nodes) return;

      const query = event.target.value;

      setSearchValue(String(query));
    },
    [nodes],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLOInputTextElement>) => {
      const isLast = matchedNodeIndex + 1 === filteredNodes?.length;

      if (event.key === "Enter" && filteredNodes) {
        setMatchedNodeIndex((prevValue) => (isLast ? 0 : prevValue + 1));
      }
    },
    [filteredNodes, matchedNodeIndex],
  );

  useEffect(() => {
    if (!filteredNodes?.length) return;

    setMatchedNodeIndex(0);
  }, [filteredNodes?.length]);

  return {
    filteredNodes,
    searchValue,
    matchedNodeIndex,
    selected,
    prevSearchValue,
    activeArrows,
    handleSearchChange,
    setMatchedNodeIndex,
    setSelected,
    handleKeyDown,
  };
};
