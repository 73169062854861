import {
  OButton,
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  OIcon,
  OLabel,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { editeCedenteCompletoDefaultValues } from "../../edit-cedente-completo.form";
import { StyledBadge, StyledRow } from "../../edit-cedente-completo.styles";
import { EmailTypesList } from "../../edit-cedente-completo.utils";

interface EmailsListProps {
  fieldName: "emails" | `representatives.${number}.emails`;
}

export const EmailsList = ({ fieldName }: EmailsListProps) => {
  const form = useFormContext<ScfDevelopers.UpdateCedenteCompleto>();
  const { control } = form;

  const { append, fields, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  return (
    <OCard>
      <OCardHeader>
        <div className="d-flex flex-row align-items-center gap-3">
          <OLabel htmlFor={fieldName}>
            {fields.length > 1 ? "Endereços de e-mail" : "Endereço de e-mail"}
          </OLabel>
          <OButton
            type="tertiary"
            onClick={() => append(editeCedenteCompletoDefaultValues.emails[0])}
          >
            <OIcon category="fa" icon="fa-plus" />
          </OButton>
        </div>
      </OCardHeader>
      <OCardBody>
        <ORow className="mx-1">
          {fields.map((field, index) => (
            <StyledRow key={field.id}>
              <OCol xs={1} className="h-100">
                <StyledBadge>{index + 1}</StyledBadge>
              </OCol>
              <OCol xs={10} align="center">
                <ORow className="w-100">
                  <OCol xs={6}>
                    <ORFieldInput
                      tag="text"
                      id={`${fieldName}.${index}.email`}
                      name={`${fieldName}.${index}.email`}
                      label="E-mail"
                    />
                  </OCol>
                  <OCol xs={6}>
                    <ORFieldSelect
                      id={`${fieldName}.${index}.type`}
                      name={`${fieldName}.${index}.type`}
                      label="Tipo de e-mail"
                    >
                      {Object.entries(EmailTypesList).map(([value, label]) => (
                        <OOption key={value} value={value}>
                          {label}
                        </OOption>
                      ))}
                    </ORFieldSelect>
                  </OCol>
                </ORow>
              </OCol>
              <OCol xs={1} align="center">
                <OButton onClick={() => remove(index)}>
                  <OIcon category="fa" icon="fa-trash" />
                </OButton>
              </OCol>
            </StyledRow>
          ))}
        </ORow>
      </OCardBody>
    </OCard>
  );
};
