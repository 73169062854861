import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
  modalManager,
} from "@maestro/react";
import { CommunicationHistoryDetail } from "./_compose/communication-history-detail";
import { CommunicationHistoryModalId } from "./communication-history-modal.types";

export const CommunicationHistoryModal = () => {
  return (
    <OModal id={CommunicationHistoryModalId} position="center">
      <OModalHeader closeButton>
        <OTypography type="dark" size="lg">
          Comunicações da cobrança
        </OTypography>
      </OModalHeader>

      <OModalBody>
        <CommunicationHistoryDetail />
      </OModalBody>

      <OModalFooter divider>
        <div className="d-flex justify-content-end">
          <OButton
            onClick={() => modalManager.hide(CommunicationHistoryModalId)}
          >
            Fechar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
