import { IIcon } from "@maestro/react";
import { Role } from "roles/roles.types";
import { emprestimosRoles } from "../../../roles/emprestimos.roles";
import { FaqModal } from "./compose/faq-modal/faq-modal.component";
import { ProductCard } from "./compose/products-card/products-card.component";
import { Simulation } from "./compose/simulation/simulation.page";

export interface ProductItem {
  roles?: Role | undefined;
  title: (customName?: string) => string;
  subtitle?: string;
  description?: string;
  identification: string;
  iconName: IIcon;
  redirectPath: string;
  needsProposalCreditManager: boolean;
  checkReadiness: boolean;
  faqModal: (props: any) => React.ReactNode;
  content: (props: any) => React.ReactNode;
  proposal?: { description: string; card: () => React.ReactNode };
  status?: () => React.ReactNode;
  simulation: {
    title: (productName?: string) => string;
    description: string;
    component: (props: any) => React.ReactNode;
    bankDescription: string;
  };
  guarantors: {
    title: string;
    description: string;
  };
}
export interface ProductMap {
  [key: string]: ProductItem;
}

export const productMap: ProductMap = {
  KGIRO: {
    roles: emprestimosRoles.customer.offersPageKgiroCard.role,
    title: (customName) => customName || "Capital de giro",
    identification: "KGIRO",
    iconName: "orq-payment-tribute",
    redirectPath: "/emprestimos/cliente/ofertas/simulacao/kgiro",
    content: (props) => <ProductCard {...props} />,
    needsProposalCreditManager: false,
    checkReadiness: false,
    faqModal: (props) => <FaqModal {...props} />,
    simulation: {
      title: (productName) =>
        `Simule um crédito ${productName || "Capital de Giro"}`,
      description:
        "Escolha o valor, a quantidade de parcelas e o dia de pagamento para avaliar a nossa oferta.",
      component: () => <Simulation />,
      bankDescription:
        "Indique a conta em que o dinheiro do empréstimo será depositado.",
    },
    guarantors: {
      title: "Avalistas",
      description:
        "Todos os avalistas precisam estar com o processo cadastral concluído e com a biometria aprovada para ocorrer o desembolso do empréstimo.",
    },
  },
  SOFTWARE_FINANCING: {
    roles: emprestimosRoles.customer.offersPageSoftwareFinancingCard.role,
    title: (customName) => customName || "Financiamento de Software",
    identification: "SOFTWARE_FINANCING",
    iconName: "orq-payment-tribute",
    redirectPath: "/emprestimos/cliente/ofertas/simulacao/software_financing",
    content: (props) => <ProductCard {...props} />,
    needsProposalCreditManager: false,
    checkReadiness: false,
    faqModal: (props) => <FaqModal {...props} />,
    simulation: {
      title: () => "Simule um crédito para Financiamento de Software",
      description:
        "Escolha o valor, a quantidade de parcelas e o dia de pagamento para avaliar a nossa oferta.",
      component: () => <Simulation />,
      bankDescription: "Indique a conta em que o valor será depositado.",
    },
    guarantors: {
      title: "Avalistas",
      description:
        "Todos os avalistas precisam estar com o processo cadastral concluído e com a biometria aprovada para ocorrer o desembolso do empréstimo.",
    },
  },
  SOLAR: {
    roles: emprestimosRoles.customer.offersPageSolarCard.role,
    title: (customName) => customName || "Solar",
    identification: "SOLAR",
    description:
      "Condições especiais para créditos de comercialização, custeio e equipamentos",
    iconName: "orq-solar-credit",
    redirectPath: "/emprestimos/cliente/ofertas/simulacao/solar",
    content: (props) => <ProductCard {...props} />,
    needsProposalCreditManager: true,
    checkReadiness: true,
    faqModal: (props) => <FaqModal {...props} />,
    simulation: {
      title: () => "Contrate aqui o seu financiamento Solar",
      description:
        "Confira a proposta do BTG para a sua empresa e aproveite para contratar o empréstimo.",
      component: () => <Simulation />,
      bankDescription:
        "O valor do empréstimo será desembolsado na conta do distribuidor e na conta do integrador.",
    },
    guarantors: {
      title: "Avalistas",
      description:
        "Todos os avalistas precisam estar com o processo cadastral concluído e com a biometria aprovada para ocorrer o desembolso do empréstimo.",
    },
  },
  // AGRO: {
  //   roles: emprestimosRoles.customer.offersPageAgroCard.role,
  //   title: "Agro",
  //   identification: "AGRO",
  //   description:
  //     "Condições especiais para créditos de comercialização, custeio e equipamentos",
  //   iconName: "orq-capital-goods",
  //   redirectPath: "/emprestimos/cliente/ofertas/simulacao/agro",
  //   content: (props) => <ProductCard {...props} />,
  //   needsProposalCreditManager: true,
  //   checkReadiness: false,
  //   faqModal: (props) => <FaqModal {...props} />,
  //   simulation: {
  //     title: "Proposta de Crédito Agro",
  //     description:
  //       "Confira a proposta do BTG para a sua empresa e aproveite para contratar o empréstimo.",
  //     component: () => <Simulation />,
  //     bankDescription:
  //       "Indique a conta em que o dinheiro do empréstimo será depositado.",
  //   },
  //   guarantors: {
  //     title: "Avalistas e fiduciantes",
  //     description:
  //       "Todos os avalistas e fiduciantes precisam estar com o processo cadastral concluído e com a biometria aprovada para ocorrer o desembolso do empréstimo.",
  //   },
  // },
  AGRO_COSTING: {
    roles: emprestimosRoles.customer.offersPageAgroCard.role,
    title: (customName) => customName || "Custeio Agro",
    identification: "AGRO_COSTING",
    description:
      "Condições especiais para créditos de comercialização, custeio e equipamentos",
    iconName: "orq-capital-goods",
    redirectPath: "/proposta/agro_costing",
    content: (props) => <ProductCard {...props} />,
    needsProposalCreditManager: true,
    checkReadiness: false,
    faqModal: (props) => <FaqModal {...props} />,
    simulation: {
      title: () => "Proposta de Crédito Custeio Agro",
      description:
        "Confira a proposta do BTG para a sua empresa e aproveite para contratar o empréstimo.",
      component: () => <Simulation />,
      bankDescription:
        "Indique a conta em que o dinheiro do empréstimo será depositado.",
    },
    guarantors: {
      title: "Avalistas e fiduciantes",
      description:
        "Todos os avalistas e fiduciantes precisam estar com o processo cadastral concluído e com a biometria aprovada para ocorrer o desembolso do empréstimo.",
    },
  },
  AGRO_INVESTMENT: {
    roles: emprestimosRoles.customer.offersPageAgroCard.role,
    title: (customName) => customName || "Agro - Investimento",
    identification: "AGRO_INVESTMENT",
    description:
      "Condições especiais para créditos de comercialização, custeio e equipamentos",
    iconName: "orq-capital-goods",
    redirectPath: "/proposta/agro_investment",
    content: (props) => <ProductCard {...props} />,
    needsProposalCreditManager: true,
    checkReadiness: false,
    faqModal: (props) => <FaqModal {...props} />,
    simulation: {
      title: () => "Proposta de crédito agro em equipamento novo",
      description:
        "Confira a proposta do BTG para a sua empresa e aproveite para contratar o empréstimo.",
      component: () => <Simulation />,
      bankDescription:
        "Indique a conta em que o dinheiro do empréstimo será depositado.",
    },
    guarantors: {
      title: "Avalistas e fiduciantes",
      description:
        "Todos os avalistas e fiduciantes precisam estar com o processo cadastral concluído e com a biometria aprovada para ocorrer o desembolso do empréstimo.",
    },
  },
  AGRO_USED_INVESTMENT: {
    roles: emprestimosRoles.customer.offersPageAgroCard.role,
    title: (customName) => customName || "Agro - Equipamento novo",
    identification: "AGRO_USED_INVESTMENT",
    description:
      "Condições especiais para créditos de comercialização, custeio e equipamentos",
    iconName: "orq-capital-goods",
    redirectPath: "/proposta/agro_used_investment",
    content: (props) => <ProductCard {...props} />,
    needsProposalCreditManager: true,
    checkReadiness: false,
    faqModal: (props) => <FaqModal {...props} />,
    simulation: {
      title: () => "Proposta de crédito agro em equipamento usado",
      description:
        "Confira a proposta do BTG para a sua empresa e aproveite para contratar o empréstimo.",
      component: () => <Simulation />,
      bankDescription:
        "Indique a conta em que o dinheiro do empréstimo será depositado.",
    },
    guarantors: {
      title: "Avalistas e fiduciantes",
      description:
        "Todos os avalistas e fiduciantes precisam estar com o processo cadastral concluído e com a biometria aprovada para ocorrer o desembolso do empréstimo.",
    },
  },
};
