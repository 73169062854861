import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services/service";
import { scfDevelopersOnboardingStatusMap } from "../../../../../../utils/scf-configuration-maps";
import { DomainScfFormFields } from "../../domain-configuration-scf.form";
import { buildConfigurationsFromForm } from "../../editar/edit-partner-configuration-scf.utils";

export const useDomainScfForm = (isEditing: boolean) => {
  const navigate = useNavigate();

  const form = useFormContext<DomainScfFormFields>();
  const { handleSubmit } = form;

  const { callService: upsertPartnerConfiguration, loading: loadingSubmit } =
    useServiceCall(service.scfDevelopers.upsertPartnerConfiguration);

  const submit = handleSubmit(async (values) => {
    const partnerConfiguration = {
      allowedReprocessStatuses:
        values.allowedReprocessStatuses.length ===
        Object.keys(scfDevelopersOnboardingStatusMap).length
          ? []
          : values.allowedReprocessStatuses ?? [],
      authClientId: values.authClientId,
      configurationId: values.configurationId,
      configurationName: values.configurationName,
      configurationDisplayName: values.configurationDisplayName,
      configurations: buildConfigurationsFromForm(values.configurations),
      desks: values.desks,
      integrationType: "SACADO_SIMPLES",
      isFidc: values.isFidc.includes("true"),
      mainDesk: values.mainDesk,
      partnerId: values.partnerId.toString(),
      partnerName: values.partnerName,
      requiredOnboardingDocuments: values.requiredOnboardingDocuments,
      syncConfigurations: buildConfigurationsFromForm(
        values.syncConfigurations,
      ),
      requestRequiredFields: values.requestRequiredFields ?? [],
    };

    const { success } = await upsertPartnerConfiguration({
      partnerConfiguration,
    });

    if (!success) {
      const errorMessage = `Erro ao ${
        isEditing ? "atualizar" : "adicionar"
      } a configuração de parceiro.`;
      OToastManager.danger(errorMessage);
    } else {
      const message = `Domínio ${
        isEditing ? "atualizado" : "adicionado"
      } com sucesso!`;
      OToastManager.success(message);
      navigate(
        corporateRouter.routes.ferramentas.product.onboarding.domainConfigScf
          .partnerConfiguration.path,
      );
    }
  });

  return {
    form,
    loadingSubmit,
    submit,
  };
};
