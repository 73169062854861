import { ITipsList } from "./tips.type";

export const landingPageTipsBndes: ITipsList = [
  {
    icon: "orq-financing" as const,
    title: "Crédito para Micro e Pequenas Empresas",
    subtitle:
      "Disponível para alavancar os negócios dos micro e pequenos empresários que podem alcançar seus objetivos ou manter o bom funcionamento do negócio.",
  },
  {
    icon: "orq-online" as const,
    title: "Solicitação online",
    subtitle:
      "Contratação é 100% digital e sem burocracias na plataforma do BTG Empresas. As checagens de elegibilidade são realizadas durante o processo de contratação.",
  },
  {
    icon: "orq-fees-fines" as const,
    title: "Sem burocracias",
    subtitle:
      "Sem lastro ou comprovação pode ser utilizado para compra de insumos, estoques ou para investimentos.",
  },
  {
    icon: "orq-schedule" as const,
    title: "Prazos e condições flexíveis",
    subtitle:
      "Conte agora com taxas subsidiadas pelo BNDES para alavancar o crescimento do pequeno e médio empresário.",
  },
];

export const landingPageTipsBtg: ITipsList = [
  {
    icon: "orq-bank" as const,
    title: "Acesso rápido ao crédito",
    subtitle:
      "Obtenha recursos de maneira ágil, permitindo uma resposta eficiente a necessidades imediatas de caixa.",
  },
  {
    icon: "orq-bank" as const,
    title: "Flexibilidade nas condições",
    subtitle:
      "Prazos e condições de pagamento de acordo com seu negócio, proporcionando controle financeiro.",
  },
  {
    icon: "orq-bank" as const,
    title: "Gestão de fluxo de caixa",
    subtitle:
      "Equilibra o fluxo de caixa da sua empresa, permitindo a continuidade das operações sem interrupções.",
  },
  {
    icon: "orq-bank" as const,
    title: "Taxas competitivas",
    subtitle:
      "Taxas de juros competitivas, tornando o crédito mais acessível e vantajoso para diferentes tipos de empresas.",
  },
  {
    icon: "orq-bank" as const,
    title: "Facilidade de contratação",
    subtitle:
      "Processo de contratação totalmente digital por meio da sua Conta PJ e do aplicativo BTG Pactual Empresas​.",
  },
  {
    icon: "orq-bank" as const,
    title: "Previsibilidade financeira.",
    subtitle:
      "Ajustada às suas necessidades, possibilitando melhor planejamento das finanças a médio e longo prazo.",
  },
];

export const landingPageTipsSoftware: ITipsList = [
  {
    icon: "orq-bank" as const,
    title: "Facilidade de acesso",
    subtitle:
      "Solicitação intuitiva e transparente, permitindo financiamento de forma rápida e online",
  },
  {
    icon: "orq-bank" as const,
    title: "Prazos atrativos",
    subtitle:
      "O financiamento pode ser contratado com prazos flexíveis, para adequar os pagamentos à capacidade financeira.",
  },
  {
    icon: "orq-bank" as const,
    title: "Taxas competitivas",
    subtitle:
      "Taxas de juros pré-fixadas, proporcionando previsibilidade nos custos do financiamento.",
  },
  {
    icon: "orq-bank" as const,
    title: "Infraestrutura tecnológica",
    subtitle:
      "Destinado a aquisição de softwares e serviços necessários para sua implementação.",
  },
  {
    icon: "orq-bank" as const,
    title: "Ampla acessibilidade",
    subtitle:
      "Disponível para facilitar o acesso a capital para tecnologias que impulsionam o crescimento.",
  },
  {
    icon: "orq-bank" as const,
    title: "Rapidez na aprovação",
    subtitle:
      "Análise ágil, possibilitando o rápido desembolso dos recursos, o que agiliza os projetos de implementação.",
  },
];

export const landingPageTipsSolar: ITipsList = [
  {
    icon: "orq-bank" as const,
    title: "Longo prazo de pagamento",
    subtitle:
      "Prazo extendido, facilitando a adequação do financiamento ao fluxo de caixa do cliente​",
  },
  {
    icon: "orq-bank" as const,
    title: "Taxas pré-fixadas e competitivas",
    subtitle:
      "Garantem previsibilidade, com até 180 dias de carência para o início do pagamento.",
  },
  {
    icon: "orq-bank" as const,
    title: "Parcelas fixas proporcionais",
    subtitle:
      "O valor das parcelas é projetado para ser equivalente à economia gerada na conta de luz.",
  },
  {
    icon: "orq-bank" as const,
    title: "Cobertura completa",
    subtitle:
      "Abrange tanto a aquisição dos equipamentos quanto a instalação do sistema solar.",
  },
  {
    icon: "orq-bank" as const,
    title: "Processo digital e ágil",
    subtitle:
      "Solicitação e todo acompanhamento do financiamento é feito através da nossa plataforma digital.​",
  },
  {
    icon: "orq-bank" as const,
    title: "Pessoas físicas e jurídicas",
    subtitle:
      "Acessível tanto para empresas quanto para clientes individuais, ampliando o alcance do financiamento​",
  },
];
