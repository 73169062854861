import { ODropdown, OInputSearch, OTypography } from "@maestro/react";
import {
  HighlightText,
  buildTextHighlight,
} from "components/customer-selector";
import debounce from "lodash/debounce";
import { useCallback, useMemo, useRef, useState } from "react";
import { GetApiStrategiesResponseItem } from "services/clerk";
import {
  ButtonItem,
  EntitySearchContainer,
  StyledDropdownItem,
  StyledDropdownList,
} from "./entity-search.styles";
import { EntitySearchProps } from "./entity-search.types";

export const EntitySearch = ({
  strategies,
  addStrategy,
}: EntitySearchProps) => {
  const inputSearchRef = useRef<HTMLOInputSearchElement>(null);
  const [query, setQuery] = useState("");
  const [searchValues, setSearchValues] = useState<
    GetApiStrategiesResponseItem[]
  >([]);

  const search = useCallback(
    (searchValue: string) => {
      const trimmedValue = searchValue.trim();
      if (!trimmedValue) return;

      const data = strategies?.filter(
        ({ estrategiaUniqueId, name }) =>
          estrategiaUniqueId.includes(trimmedValue) ||
          name.includes(trimmedValue),
      );

      setSearchValues(data ?? []);
    },
    [strategies],
  );

  const debouncedSearch = useMemo(() => debounce(search, 500), [search]);

  return (
    <EntitySearchContainer>
      <ODropdown className="w-100">
        <OInputSearch
          ref={inputSearchRef}
          placeholder="Buscar estratégias"
          id="entity-search"
          name="entity-search"
          autocomplete="off"
          value={query}
          onInput={({ currentTarget: { value } }) => {
            if (value) {
              setQuery(value);
              debouncedSearch(value.trim());
            } else {
              setQuery("");
              setSearchValues([]);
            }
          }}
          handleClear={() => {
            setQuery("");
            setSearchValues([]);
            inputSearchRef.current?.querySelector("input")?.focus();
          }}
        />
        <StyledDropdownList className="w-100">
          {searchValues.map(({ estrategiaUniqueId, name }, index) => {
            const dropdownKey = `${estrategiaUniqueId}_${name}_${index}`;
            const text = `${estrategiaUniqueId} - ${name}`;

            return (
              <StyledDropdownItem
                key={dropdownKey}
                onKeyDown={({ key }) => {
                  if (key === "Enter") addStrategy(estrategiaUniqueId);
                }}
              >
                <ButtonItem
                  className="d-flex align-items-center gap-2"
                  onClick={(event) => {
                    event.preventDefault();
                    addStrategy(estrategiaUniqueId);
                  }}
                >
                  <div className="text-left w-100">
                    <OTypography type="default">
                      <HighlightText
                        text={text}
                        highlightExpression={buildTextHighlight(query)}
                      />
                    </OTypography>
                  </div>
                </ButtonItem>
              </StyledDropdownItem>
            );
          })}
        </StyledDropdownList>
      </ODropdown>
    </EntitySearchContainer>
  );
};
