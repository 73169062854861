import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { WorkflowScfForm } from "../_compose/workflow-scf-form";
import { useEditWorkflowConfigurationScf } from "./edit-workflow-configuration-scf.hook";

export const EditWorkflowConfigurationScf = () => {
  const { form, loading } = useEditWorkflowConfigurationScf();

  return (
    <>
      <PageTitle title="Editar workflow" />
      <FormProvider {...form}>
        <WorkflowScfForm loading={loading} isEditing />
      </FormProvider>
    </>
  );
};
