import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services/service";
import { WorkflowScfFormFields } from "../../../workflow-configuration-scf.form";

export const usePartnerFields = () => {
  const {
    callService: fetchPartnersList,
    loading,
    value: partnersList,
  } = useServiceCall(service.scfDevelopers.fetchPartnersList);

  const form = useFormContext<WorkflowScfFormFields>();
  const { setValue, watch } = form;

  const watchPartnerName = watch("partnerName");

  const getPartners = useCallback(async () => {
    const { success } = await fetchPartnersList();

    if (!success) {
      OToastManager.danger("Erro ao obter listagem de parceiros");
    }
  }, [fetchPartnersList]);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  useEffect(() => {
    if (watchPartnerName && partnersList) {
      const foundPartner = partnersList.find(
        (partner) => partner.name === watchPartnerName,
      );

      if (foundPartner) {
        setValue("partnerId", parseInt(foundPartner.legacyRelationalId ?? "0"));
        setValue("authClientId", foundPartner.authClientId);
      }
    }
  }, [partnersList, setValue, watchPartnerName]);

  return {
    loading,
    partnersList,
  };
};
