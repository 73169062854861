import { getValueFromMap } from "utils/get-value-from-map";
import { valueCellMap } from "./value-cell.utils";

interface ValueCellProps {
  property: HubEnergy.DiligenceStepPropertyResponse;
}

export const ValueCell = ({ property }: ValueCellProps) => {
  const Mask = getValueFromMap(valueCellMap, property.type.mask);

  if (Mask) return <Mask property={property} />;

  return <>{property.value}</>;
};
