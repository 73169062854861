import * as yup from "yup";

export const updateOriginatorCreditLimitFormFields = {
  newLimit: {
    id: "newLimit",
    name: "newLimit",
    dataAction: "b2b-originadores:texto:form-novo-limite",
    dataLabel: "form-novo-limite",
    initialValue: undefined,
    tag: "currency" as const,
    label: "Novo limite de crédito",
    placeholder: "Informe um novo limite",
    symbol: "R$",
    validator: yup
      .number()
      .min(0, "O valor não pode ser menor que 0.")
      .max(1000000000, "O valor inserido é maior que o permitido.")
      .required("Este campo é obrigatório!")
      .typeError("Este campo é obrigatório!"),
  },
};

export const updateOriginatorCreditLimitValidationSchema = yup.object({
  newLimit: updateOriginatorCreditLimitFormFields.newLimit.validator,
});

type UpdateOriginatorCreditLimitFormFields = yup.InferType<
  typeof updateOriginatorCreditLimitValidationSchema
>;

export const updateOriginatorCreditLimitDefaultValues: Partial<UpdateOriginatorCreditLimitFormFields> =
  {
    newLimit: updateOriginatorCreditLimitFormFields.newLimit.initialValue,
  };
