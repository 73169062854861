import {
  OCheckbox,
  OCol,
  OLabel,
  OOptionMultiple,
  ORFieldCheckboxGroup,
  ORFieldSelectMultiple,
  ORow,
} from "@maestro/react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { integrationDocumentsMap } from "../../../../../../../utils/scf-configuration-maps";
import { generateUuidV4 } from "../../../editar/edit-partner-configuration-scf.utils";
import { BaseStepFields } from "./base-step-fields.component";

interface ValidationStepProps {
  fieldName: "configurations" | "syncConfigurations";
  index: number;
}

export const ValidationStep = ({ fieldName, index }: ValidationStepProps) => {
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    setValue(`${fieldName}.${index}._t`, "ValidationPartnerConfigurationStep");
    if (!getValues(`${fieldName}.${index}.stepId`)) {
      setValue(
        `${fieldName}.${index}.stepId`,
        `validation-${generateUuidV4()}`,
      );
    }
    if (!getValues(`${fieldName}.${index}.nextStepId`)) {
      setValue(`${fieldName}.${index}.nextStepId`, null);
    }
  });

  return (
    <ORow className="w-100">
      <BaseStepFields
        fieldName={fieldName}
        index={index}
        stepName="VALIDATION"
      />
      <OCol xs={6}>
        <ORFieldSelectMultiple
          dataAction={`partner_configuration_scf:select:${fieldName}_${index}_risk_type`}
          dataLabel={`${fieldName}_${index}_risk_type`}
          id={`${fieldName}.${index}.requiredDocuments`}
          name={`${fieldName}.${index}.requiredDocuments`}
          label="Documentos necessários"
        >
          {Object.entries(integrationDocumentsMap).map(([value, label]) => (
            <OOptionMultiple key={`onboarding-status-${value}`} value={value}>
              {label}
            </OOptionMultiple>
          ))}
        </ORFieldSelectMultiple>
      </OCol>
      <OCol xs={6} align="center">
        <ORFieldCheckboxGroup
          dataAction={`partner_configuration_scf:checkbox:${fieldName}_${index}_should_validate_address`}
          dataLabel={`${fieldName}_${index}_should_validate_address`}
          id={`${fieldName}.${index}.shouldValidateAddress`}
          name={`${fieldName}.${index}.shouldValidateAddress`}
        >
          <div className="d-flex align-items-center gap-2 mb-2">
            <OCheckbox
              size="xs"
              id={`${fieldName}.${index}.shouldValidateAddressCheckbox`}
              value="true"
            />
            <OLabel
              htmlFor={`${fieldName}.${index}.shouldValidateAddressCheckbox`}
              id={`${fieldName}.${index}.isFidcLabel`}
            >
              Precisa validar endereço
            </OLabel>
          </div>
        </ORFieldCheckboxGroup>
      </OCol>
    </ORow>
  );
};
