import { ODivider, OTypography } from "@maestro/react";
import { Fragment } from "react";
import { useCommunicationHistory } from "../../communication-history-modal.hook";
import { CollectionCommunicationDetail } from "./_compose/collection-communication-detail";

export const CommunicationHistoryDetail = () => {
  const {
    communicationHistory: { ruler, shared },
    isLoading,
  } = useCommunicationHistory();

  if (isLoading) {
    return (
      <div className="d-flex flex-column gap-3">
        <CollectionCommunicationDetail isLoading />
        <ODivider size="xxs" />
        <CollectionCommunicationDetail isLoading />
        <ODivider size="xxs" />
        <CollectionCommunicationDetail isLoading />
      </div>
    );
  }

  if (!ruler?.length && !shared?.length) {
    return (
      <OTypography type="dark">
        Não existem envios registrados desta cobrança.
      </OTypography>
    );
  }

  return (
    <div className="d-flex flex-column gap-3">
      {!!ruler?.length && (
        <div className="d-flex flex-column gap-3">
          <OTypography type="dark" weight="bold">
            Via Régua de cobrança
          </OTypography>

          {ruler.map((communication, index, { length }) => (
            <Fragment key={`ruler-communication-${communication.sentAt}`}>
              <CollectionCommunicationDetail communication={communication} />
              {index < length - 1 && <ODivider size="xxs" />}
            </Fragment>
          ))}
        </div>
      )}

      {!!ruler?.length && !!shared?.length && <ODivider size="xs" />}

      {!!shared?.length && (
        <div className="d-flex flex-column gap-3">
          <OTypography type="dark" weight="bold">
            Compartilhamento direto
          </OTypography>

          {shared.map((communication, index, { length }) => (
            <Fragment key={`shared-communication-${communication.sentAt}`}>
              <CollectionCommunicationDetail
                communication={communication}
                showFullDate
              />
              {index < length - 1 && <ODivider size="xxs" />}
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
