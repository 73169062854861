import { OButton, OLoader } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useCNABContext } from "../cnab.context";
import { LayoutFormCard } from "./_compose/layout-form-card";
import { ProductsTabHeader } from "./_compose/products-tab-header";
import { UpsertLayoutFormFields } from "./upsert-layout.form-schema";
import { useUpsertLayout } from "./upsert-layout.hook";
import { loadDefaultValues } from "./upsert-layout.utils";

export const UpsertLayoutPage = () => {
  const { isLoadingProducts, products } = useCNABContext();
  const { isLoading, upsertLayout } = useUpsertLayout();

  const form = useForm<UpsertLayoutFormFields>();
  const { handleSubmit, reset } = form;

  useEffect(() => {
    if (products) reset(loadDefaultValues(products));
  }, [products, reset]);

  if (isLoadingProducts || !products) return <OLoader absolute backdrop />;

  return (
    <FormProvider {...form}>
      {isLoading && <OLoader absolute backdrop />}
      <div className="d-flex flex-column gap-4">
        <PageTitle title="Configurar modelos" />
        <ProductsTabHeader />
        <LayoutFormCard />

        <div className="d-flex justify-content-end">
          <OButton onClick={handleSubmit(upsertLayout)}>Salvar</OButton>
        </div>
      </div>
    </FormProvider>
  );
};
