import { SimulationSummaryResponse } from "services/hubloan/models/responses/get-api-simulation-summary.response";
import { BankAccount } from "../../../../../offers.type";

export const getBankAccountList = (
  summary?: SimulationSummaryResponse,
  accountList?: BankAccount[],
) => {
  if (accountList)
    return summary?.agreement.additionalDisbursementAccountType === "INTERNAL"
      ? accountList.filter((account) =>
          ["0001", "0050", "50", "1"].includes(account.agency),
        )
      : accountList;

  return [];
};
