import { modalManager } from "@maestro/core";
import { useState } from "react";
import { Panel } from "reactflow";
import {
  ChangeLogger,
  NodeInspector,
  ReadOnlyModal,
  ToolButton,
  ViewportLogger,
} from "./_compose";
import { FlowDevtools } from "./canva-devtools.styles";
import { CanvasDevToolsProps } from "./canva-devtools.types";

export const CanvaDevtools = ({
  setReadOnly,
  readOnly,
}: CanvasDevToolsProps) => {
  const [nodeInspectorActive, setNodeInspectorActive] = useState(false);
  const [changeLoggerActive, setChangeLoggerActive] = useState(false);
  const [viewportLoggerActive, setViewportLoggerActive] = useState(false);

  return (
    <FlowDevtools>
      <Panel position="top-left">
        <ToolButton
          setActive={setNodeInspectorActive}
          active={nodeInspectorActive}
          title="Toggle Node Inspector"
        >
          Node Inspector
        </ToolButton>
        <ToolButton
          setActive={setChangeLoggerActive}
          active={changeLoggerActive}
          title="Toggle Change Logger"
        >
          Change Logger
        </ToolButton>
        <ToolButton
          setActive={setViewportLoggerActive}
          active={viewportLoggerActive}
          title="Toggle Viewport Logger"
        >
          Viewport Logger
        </ToolButton>
        <ToolButton
          handleClick={() =>
            readOnly ? modalManager.show("read-only-modal") : setReadOnly(true)
          }
          active={!readOnly}
          title="Edição"
        >
          Edição {readOnly ? "(inativo)" : "(ativo)"}
        </ToolButton>
      </Panel>

      {changeLoggerActive && <ChangeLogger />}
      {nodeInspectorActive && <NodeInspector />}
      {viewportLoggerActive && <ViewportLogger />}
      <ReadOnlyModal setReadOnly={setReadOnly} />
    </FlowDevtools>
  );
};
