import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { customer } from "contexts/customer";
import { dataSourceClient, registersGridClient } from "./registers.grid";

export const RegistersClient = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Registros" />}
      gridRef={gridRef}
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={registersGridClient}
        dataSource={dataSourceClient(customer.value?.identification)}
      />
    </GridTemplate>
  );
};
