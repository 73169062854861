import {
  OCard,
  OCardBody,
  OCardHeader,
  OStep,
  OStepHeader,
} from "@maestro/react";
import { cases } from "@maestro/utils";
import { PageTitle } from "components/page-title";
import { DetailsTemplate } from "templates/details-template";
import { RelocateProvider, useRelocate } from "./relocate.context";
import { steps } from "./_steps/steps";

const RelocateComponentClient = () => {
  const { stepNumber } = useRelocate();

  const CurrentStep = steps[stepNumber].component;

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Motor de realocação"
          description="É possível simular e executar a realocação, a partir do CNPJ das empresas. Na simulação é possível visualizar o que o motor conseguiu realocar, isto é, detectou que o trade está descoberto e achou agenda livre para alocar para o BTG."
        />
      }
    >
      <OCard>
        <OCardHeader divider>
          <OStepHeader activeStep={stepNumber} disableNavigation>
            {steps.map((step) => {
              const dataLabel = cases.snake(step.name);

              return (
                <OStep
                  key={step.name}
                  dataAction={`realocacao:step:${dataLabel}`}
                  dataLabel={dataLabel}
                >
                  {step.name}
                </OStep>
              );
            })}
          </OStepHeader>
        </OCardHeader>
        <OCardBody className="pt-5">
          <CurrentStep />
        </OCardBody>
      </OCard>
    </DetailsTemplate>
  );
};

export const RelocateClient = () => (
  <RelocateProvider>
    <RelocateComponentClient />
  </RelocateProvider>
);
