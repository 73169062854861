import { env } from "utils/environments";

export const onboardingRelationshipsEndpoints = {
  admin: {
    company: {
      getByTaxId: `${env.API_RELATIONSHIPS}/admin/company/get-by-tax-id`,
    },
    contingency: {
      run: `${env.API_RELATIONSHIPS}/admin/contingency/run`,
    },
    getPowersStatus: `${env.API_RELATIONSHIPS}/api/admin/get-powers-status`,
    guarantors: {
      getAll: `${env.API_RELATIONSHIPS}/api/admin/guarantors/get-all`,
      occurrences: `${env.API_RELATIONSHIPS}/api/admin/guarantors/occurrences`,
      resend: `${env.API_RELATIONSHIPS}/api/admin/guarantors/resend`,
    },
    loa: {
      create: {
        grant: `${env.API_RELATIONSHIPS}/admin/loa/create/grant`,
      },
      documentType: {
        update: `${env.API_RELATIONSHIPS}/admin/loa/document-type/update`,
      },
      representative: {
        getrepresentative: `${env.API_RELATIONSHIPS}/admin/loa/representative`,
      },
      all: `${env.API_RELATIONSHIPS}/admin/loa/all`,
      allUser: `${env.API_RELATIONSHIPS}/admin/loa/all-user`,
      get: `${env.API_RELATIONSHIPS}/admin/loa/get`,
      sendToAnalysis: `${env.API_RELATIONSHIPS}/admin/loa/send-to-analysis`,
      configRepresentative: `${env.API_RELATIONSHIPS}/admin/loa/config-representative`,
      occurrences: `${env.API_RELATIONSHIPS}/admin/loa/occurrences`,
      download: `${env.API_RELATIONSHIPS}/admin/loa/download`,
      remove: `${env.API_RELATIONSHIPS}/admin/loa/remove`,
    },
    monitoring: {
      getStatus: `${env.API_RELATIONSHIPS}/admin/monitoring/get-status`,
    },
    newGetIrisPowersCached: `${env.API_RELATIONSHIPS}/api/admin/new-get-iris-powers-cached`,
    roleassignments: {
      all: `${env.API_RELATIONSHIPS}/api/admin/roleassignments/all`,
      createUser: `${env.API_RELATIONSHIPS}/api/admin/roleassignments/create-user`,
      getProfiles: `${env.API_RELATIONSHIPS}/api/admin/roleassignments/get-profiles`,
      getWorkflow: `${env.API_RELATIONSHIPS}/api/admin/roleassignments/get-workflow`,
      relatedEntity: `${env.API_RELATIONSHIPS}/api/admin/roleassignments/related-entity`,
      validateData: `${env.API_RELATIONSHIPS}/api/admin/roleassignments/validate-data`,
      validateProfiles: `${env.API_RELATIONSHIPS}/api/admin/roleassignments/validate-profiles`,
      getUserData: `${env.API_RELATIONSHIPS}/api/admin/roleassignments/get-user-data`,
      createInternacionalUser: `${env.API_RELATIONSHIPS}/api/admin/roleassignments/create-internacional-user`,
    },
    script: {
      verifyScripts: `${env.API_RELATIONSHIPS}/api/admin/script/verify-scripts`,
    },
    tools: {
      user: {
        welcomeEmail: `${env.API_RELATIONSHIPS}/admin/tools/user/welcome-email`,
      },
    },
    verifyOptinPowers: `${env.API_RELATIONSHIPS}/api/admin/verify-optin-powers`,
    users: {
      excludeUser: `${env.API_RELATIONSHIPS}/admin/users/exclude-user`,
    },
  },
  loa: {
    legalRepresentative: {
      remove: `${env.API_RELATIONSHIPS}/loa/legal-representative/remove`,
      add: `${env.API_RELATIONSHIPS}/loa/legal-representative/add`,
      update: `${env.API_RELATIONSHIPS}/loa/legal-representative/update`,
    },
    documents: {
      remove: `${env.API_RELATIONSHIPS}/loa/documents/remove`,
      upload: `${env.API_RELATIONSHIPS}/loa/documents/upload`,
    },
  },
  customerService: {
    irisPowers: `${env.API_RELATIONSHIPS}/api/customer-service/iris-powers`,
  },
  guarantor: {
    get: `${env.API_RELATIONSHIPS}/api/guarantor/get`,
    loadGuarantors: `${env.API_RELATIONSHIPS}/api/guarantor/load-guarantors`,
    onboardingGuarantor: `${env.API_RELATIONSHIPS}/api/guarantor/onboarding-guarantor`,
  },
  odata: {
    companyPowers: {
      guarantor: `${env.API_RELATIONSHIPS}/odata/company-powers/guarantor`,
      legalEntityPowers: `${env.API_RELATIONSHIPS}/odata/company-powers/legal-entity-powers`,
      letterofattorney: `${env.API_RELATIONSHIPS}/odata/company-powers/letterofattorney`,
      letterofattorneyRepresentative: `${env.API_RELATIONSHIPS}/odata/company-powers/letterofattorney-representative`,
      parametrizationStatus: `${env.API_RELATIONSHIPS}/odata/company-powers/parametrization-status`,
      roleassignment: `${env.API_RELATIONSHIPS}/odata/company-powers/roleassignment`,
      userActChanged: `${env.API_RELATIONSHIPS}/odata/company-powers/user-act-changed`,
    },
  },
  user: {
    users: {
      attachUser: `${env.API_RELATIONSHIPS}/api/user/users/attach-user`,
    },
  },
  profile: {
    product: {
      all: `${env.API_RELATIONSHIPS}/api/profile/all-product`,
    },
  },
};
