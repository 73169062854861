import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import {
  GetAdminSettlementStrategiesBySettlementIdResponse,
  PutAdminSettlementStrategiesBySettlementIdBody,
} from "services/quickfin/models";
import { service } from "services/service";
import { ReceivablesCustomerBySettlementIdRouteParams } from "../../../../routes/recebiveis.route-params";
import { addSettlementStrategySchema } from "../_compose/formulario/settlement-strategies-form.form";

export const useEditSettlementStrategy = () => {
  const [isConsolidated, setIsConsolidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] =
    useState<GetAdminSettlementStrategiesBySettlementIdResponse>();
  const { settlementId } =
    useParams<ReceivablesCustomerBySettlementIdRouteParams>();
  const form = useForm({
    resolver: yupResolver(addSettlementStrategySchema),
  });
  const { handleSubmit } = form;
  const navigate = useNavigate();

  const fetchSettlementStrategy = useCallback(async () => {
    if (!settlementId) {
      return;
    }

    try {
      const { data: settlementStrategy } =
        await service.quickfin.getSettlementStrategiesBySettlementId(
          settlementId,
        );
      setInitialValues(settlementStrategy);
      setIsConsolidated(settlementStrategy.isConsolidated);
    } catch {
      OToastManager.danger(
        "Ocorreu um erro ao carregar essa estratégia de liquidação.",
      );
    }
  }, [settlementId]);

  useEffect(() => {
    fetchSettlementStrategy();
  }, [fetchSettlementStrategy]);

  const onSubmit = handleSubmit(async (values) => {
    setLoading(true);
    const body: PutAdminSettlementStrategiesBySettlementIdBody = {
      bankAccount: values.bankAccount,
      id: parseInt(settlementId ?? "0"),
      settlementMethodCode: values.settlementMethodCode,
      settlementLockInfo: values.settlementLockInfo,
      isConsolidated,
      tipoProduto: values.tipoProduto,
    };
    if (values.portfolioId !== 0)
      body.portfolioId = values.portfolioId;

    if (values.cedenteId) {
      body.cedenteId = values.cedenteId;
    }
    if (values.sacadoId) {
      body.sacadoId = values.sacadoId;
    }

    if (values.estrategiaComercialId) {
      body.estrategiaComercialId = values.estrategiaComercialId;
    }

    try {
      await service.quickfin.putSettlementStrategy(body);
      OToastManager.success(
        "A estratégia de liquidação foi alterada com sucesso.",
      );
      navigate(
        corporateRouter.routes.recebiveis.product.settlementStrategies.path,
      );
    } catch {
      OToastManager.danger(
        "Ocorreu um erro ao carregar essa estratégia de liquidação.",
      );
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    initialValues,
    isConsolidated,
    loading,
    onSubmit,
    setIsConsolidated,
  };
};
