import { ONotification, OTypography } from "@maestro/react";
import { useErrorsList } from "./representatives-errors-list.hook";
import { RepresentativesErrorsListProps } from "./representatives-errors-list.types";

export const RepresentativesErrorsList = ({
  rejections,
  removeAll,
  representativeIndex,
}: RepresentativesErrorsListProps) => {
  const { ocurrences } = useErrorsList(
    representativeIndex,
    removeAll,
    rejections,
  );

  if (ocurrences.length === 0) {
    return <>{null}</>;
  }

  return (
    <ONotification type="danger">
      <div className="d-flex flex-column gap-3">
        <OTypography>Erros do Representante:</OTypography>
        {ocurrences.map((ocurrence, index) => (
          <div key={ocurrence} className="w-100">
            <OTypography>{`${index + 1}) ${ocurrence}`}</OTypography>
          </div>
        ))}
      </div>
    </ONotification>
  );
};
