import { SelectSearchField } from "components/select-search";
import { useMemo } from "react";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useBatchTemplatePropertyField } from "./batch-template-field.hook";

export const BatchTemplatePropertyField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorPropertyId, name, description } = processorConfigProperty;
  const { allBatchTemplates } = useBatchTemplatePropertyField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const options = useMemo(
    () =>
      allBatchTemplates?.map((config) => {
        return {
          value: config.identification,
          label: config?.name,
        };
      }) ?? [],
    [allBatchTemplates],
  );

  const formPropertyField = useMemo(() => {
    return (
      <SelectSearchField
        key={allBatchTemplates
          ?.map((template) => template.identification)
          .join("")}
        id={`properties.${name}_${processorPropertyId}`}
        name={`properties.${name}_${processorPropertyId}`}
        placeholder="Selecione"
        labelSize="md"
        options={options}
        label={description}
      />
    );
  }, [options, allBatchTemplates, name, processorPropertyId, description]);

  return <>{formPropertyField}</>;
};
