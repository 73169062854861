import { ORow } from "@maestro/react";
import styled from "styled-components";

export const StyledBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-quaternary);
  border-radius: 50%;
  color: var(--theme-light);
  height: 36px;
  width: 36px;
`;

export const StyledRow = styled(ORow)`
  display: flex;
  flex-direction: row;

  border: 1px solid var(--theme-dark-20);
  padding: 16px;
`;

export const StyledSelect = styled.select`
  border-color: transparent transparent #ccc transparent;
  height: 36px;
  width: 100%;

  &:focus {
    border-color: transparent transparent var(--theme-focus) transparent;
  }
`;
