import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { duplicatasRoles } from "../roles/duplicatas.roles";
import { WalletClientPage } from "../pages/cliente/carteiras/wallet.page";
import { WalletsPage } from "../pages/produto/carteiras/wallets.page";
import { WalletInfoPage } from "../pages/cliente/informacoes/wallet-info.page";
import { NotesPage } from "../pages/cliente/notes/notes.page";
import { HiringStatusPage } from "../pages/cliente/hiring-status/hiring-status.page";
import { WalletDefenseManagementPage } from "../pages/cliente/defesa/wallet-defense-management.page";
import { WalletDefenseManagementCustomerPage } from "../pages/cliente/defesa/wallet-defense-management-customer.page";

export const duplicatasRoutes = {
  customer: {
    name: "",
    path: "/duplicatas/cliente",
    element: <RedirectWithRoles />,
    children: {
      info: {
        name: "Informações",
        path: "/duplicatas/cliente/informacoes",
        role: duplicatasRoles.product.walletDetailsPage.role,
        element: <WalletInfoPage />,
      },
      wallets: {
        name: "Carteiras",
        path: "/duplicatas/cliente/carteiras",
        role: duplicatasRoles.product.walletDetailsPage.role,
        element: <WalletClientPage />,
      },
      notes: {
        name: "Nota a nota",
        path: "/duplicatas/cliente/notes",
        role: duplicatasRoles.customer.notesPage.role,
        element: <NotesPage />,
      },
      hiringStatus: {
        name: "Contratação",
        path: "/duplicatas/cliente/status-contratacao",
        role: duplicatasRoles.customer.hiringPage.role,
        element: <HiringStatusPage />,
      },
      walletDefenseManagement: {
        name: "Defesa de carteira",
        path: "/duplicatas/cliente/gestao-de-defesa",
        role: duplicatasRoles.product.walletDefenseManagementPage.role,
        element: <WalletDefenseManagementCustomerPage />,
      },
    },
  },
  product: {
    name: "",
    path: "/duplicatas/produto",
    element: <RedirectWithRoles />,
    children: {
      wallets: {
        name: "Carteiras",
        path: "/duplicatas/produto/carteiras",
        role: duplicatasRoles.product.walletsPage.role,
        element: <WalletsPage />,
      },
      walletDefenseManagement: {
        name: "Defesa de carteira",
        path: "/duplicatas/produto/gestao-de-defesa",
        role: duplicatasRoles.product.walletDefenseManagementPage.role,
        element: <WalletDefenseManagementPage />,
      },
    },
  },
} as const satisfies Routes;
