import { OButton, OCol, ORow } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { DetailsTemplate } from "templates/details-template";
import { CheckboxField } from "../../../../../../../../components/checkbox-field";
import { DistributorSelector, EventTypeSelector } from "../../../selects";
import { ruleEditorFormFields } from "./rule-editor.form";
import { useRuleEditor } from "./rule-editor.hook";
import { ruleEditorFields } from "./rule-editor.utils";
import { FieldRenderer } from "./_compose";

interface RuleEditorProps {
  ruleToEdit?: HubEnergy.DiligenceEventRuleSimpleResponse;
  setRule?: React.Dispatch<
    React.SetStateAction<HubEnergy.DiligenceEventRuleResponse | undefined>
  >;
  setMode?: React.Dispatch<React.SetStateAction<"display" | "editing">>;
}

export const RuleEditor = ({
  ruleToEdit,
  setRule,
  setMode,
}: RuleEditorProps) => {
  const { eventTypeWatcher, form, submit, submitLoading } = useRuleEditor(
    ruleToEdit,
    setRule,
    setMode,
  );

  return (
    <DetailsTemplate
      actions={
        <>
          {ruleToEdit && setMode && (
            <OButton onClick={() => setMode("display")} type="dark" outline>
              Cancelar
            </OButton>
          )}
          <LoadingButton loading={submitLoading} onClick={submit}>
            Salvar
          </LoadingButton>
        </>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12}>
              <EventTypeSelector {...ruleEditorFormFields.eventType} />
            </OCol>
          </ORow>
          {eventTypeWatcher && (
            <>
              <ORow>
                {ruleEditorFields.step.map((field) => (
                  <FieldRenderer
                    key={field.key}
                    field={field}
                    eventType={eventTypeWatcher}
                  />
                ))}
              </ORow>
              <ORow>
                {ruleEditorFields.pendency.map((field) => (
                  <FieldRenderer
                    key={field.key}
                    field={field}
                    eventType={eventTypeWatcher}
                  />
                ))}
              </ORow>
              <ORow>
                {ruleEditorFields.property.map((field) => (
                  <FieldRenderer
                    key={field.key}
                    field={field}
                    eventType={eventTypeWatcher}
                  />
                ))}
              </ORow>
            </>
          )}
          <ORow>
            <OCol xs={12}>
              <DistributorSelector
                {...ruleEditorFormFields.allowedDistributorIds}
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12}>
              <CheckboxField
                {...ruleEditorFormFields.stopProcessingMoreRules}
              />
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </DetailsTemplate>
  );
};
