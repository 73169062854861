import { ODataRequest } from "../service.types";
import { client } from "../client";
import {
  GetAgendasImportParams,
  GetEmailConfigsParams,
  GetRegistrosDownloadParams,
  GetV2DeflatoresParams,
  GetOptinViewRequest,
  PostAgendasCipBody,
  PostAddOptinBody,
  PostCompaniesMapGroupBody,
  PostConveniosGridBody,
  PostDeflatoresBody,
  PostEmailConfigsBody,
  PostFilterTableAgendasBody,
  PostFilterTableAgendasCompanyMapBody,
  PostFilterTableAgendasWithoutOperatedValueBody,
  PostFilterTableAutoRealocacoesBody,
  PostFilterTableErrorsBody,
  PostFilterTableJobsBody,
  PostFilterTableRegistrosBody,
  PostFilterTableRegistrosSolicitationsBody,
  PostFilterTableTradesBody,
  PostJobsRunJobBody,
  PostJobsRunWorkerBody,
  PostJobsStopJobBody,
  PostMonitorHealthCheckBody,
  PostMonitorResumoDiarioBody,
  PostMonitorTradesBody,
  PostRealocacaoConfigBody,
  PostRealocacaoExecutarBody,
  PostRealocacaoSimularBody,
  PostRegistrosEntubaAlteracaoBody,
  PostRegistrosEntubaCancelamentoBody,
  PostRegistrosEntubaInclusaoBody,
  PostWhitelistsBody,
  PostWhitelistsGridBody,
  PutDeflatoresByIdBody,
  PutDeflatoresDefaultBody,
  PutEmailConfigsByIdBody,
  PutWhitelistsByIdBody,
  PostOptoutBody,
  PostAddCompanyTraitsRequest,
  PutRetryOptinBody,
  PostRedisponibilizaRecebiveis,
} from "./models/requests";
import {
  DeleteCompaniesMapGroupByPrefixResponse,
  GetAgendasImportResponse,
  GetBandeirasAllResponse,
  GetCompaniesCredenciadorasResponse,
  GetCompaniesMapGroupByPrefixResponse,
  GetCompaniesNotCredenciadorasResponse,
  GetCompaniesResponse,
  GetConciliacaoResponse,
  GetDeflatoresDefaultResponse,
  GetEmailConfigsResponse,
  GetJobsCrontabResponse,
  GetRegistrosDownloadResponse,
  GetTradesByParentIdChildrenResponse,
  GetV2DeflatoresResponse,
  PostAddOptinResponse,
  PostCompaniesMapGroupResponse,
  PostConveniosGridResponse,
  PostDeflatoresResponse,
  PostEmailConfigsResponse,
  PostFilterTableAgendasCompanyMapResponse,
  PostFilterTableAgendasResponse,
  PostFilterTableAgendasWithoutOperatedValueResponse,
  PostFilterTableAutoRealocacoesResponse,
  PostFilterTableErrorsResponse,
  PostFilterTableJobsResponse,
  PostFilterTableRegistrosResponse,
  PostFilterTableRegistrosSolicitationsResponse,
  PostFilterTableTradesResponse,
  PostJobsRunJobResponse,
  PostJobsStopJobResponse,
  PostMonitorHealthCheckResponse,
  PostMonitorResumoDiarioResponse,
  PostMonitorTradesResponse,
  PostRealocacaoConfigResponse,
  PostRealocacaoExecutarResponse,
  PostRealocacaoSimularResponse,
  PostRegistrosEntubaAlteracaoResponse,
  PostRegistrosEntubaCancelamentoResponse,
  PostRegistrosEntubaInclusaoResponse,
  PostWhitelistsGridResponse,
  PostWhitelistsResponse,
  PutDeflatoresByIdResponse,
  PutDeflatoresDefaultResponse,
  PutEmailConfigsByIdResponse,
  PutWhitelistsByIdResponse,
  GetConciliacaoDetailResponseItem,
  GetOptinViewResponse,
  GetVnpSummaryResponse,
  CompanyTraitsGridResponse,
  CompanyTraitPropertiesResponse,
  GetRejectedOptinsResponse,
  GetAntecipacaoAutomaticaConfigResponse,
  GetAntecipacaoAutomaticaGridResponse,
  GetCedenteDeflatorResponse,
  GetProcessDashboardResponse,
  GetVnpTotalResponse,
  PostGenericStatus,
  CompanyTraitsByTaxIdResponse,
} from "./models/responses";
import { recebiveisCartaoEndpoints } from "./recebiveis-cartao.endpoints";
import { GetVnpTotalTradesResponse } from "./models/responses/get-vnp-total-trades";

export const recebiveisCartaoService = {
  getSolicitations(body: PostFilterTableRegistrosSolicitationsBody) {
    return client.post<PostFilterTableRegistrosSolicitationsResponse>(
      recebiveisCartaoEndpoints.filterTable.registrosSolicitations,
      body,
    );
  },
  getRegisters(body: PostFilterTableRegistrosBody) {
    return client.post<PostFilterTableRegistrosResponse>(
      recebiveisCartaoEndpoints.filterTable.registros,
      body,
    );
  },
  downloadRegister(params?: GetRegistrosDownloadParams) {
    return client.get<GetRegistrosDownloadResponse>(
      recebiveisCartaoEndpoints.registros.download,
      { params },
    );
  },
  entubaInclusao(body: PostRegistrosEntubaInclusaoBody) {
    return client.post<PostRegistrosEntubaInclusaoResponse>(
      recebiveisCartaoEndpoints.registros.entubaInclusao,
      body,
    );
  },
  entubaAlteracao(body: PostRegistrosEntubaAlteracaoBody) {
    return client.post<PostRegistrosEntubaAlteracaoResponse>(
      recebiveisCartaoEndpoints.registros.entubaAlteracao,
      body,
    );
  },
  entubaCancelamento(body: PostRegistrosEntubaCancelamentoBody) {
    return client.post<PostRegistrosEntubaCancelamentoResponse>(
      recebiveisCartaoEndpoints.registros.entubaCancelamento,
      body,
    );
  },
  getCompanies() {
    return client.get<GetCompaniesResponse>(
      recebiveisCartaoEndpoints.companies.endpoint,
    );
  },
  getAutoRelocations(body: PostFilterTableAutoRealocacoesBody) {
    return client.post<PostFilterTableAutoRealocacoesResponse>(
      recebiveisCartaoEndpoints.filterTable.autoRealocacoes,
      body,
    );
  },
  configureAutoRelocation(body: PostRealocacaoConfigBody) {
    return client.post<PostRealocacaoConfigResponse>(
      recebiveisCartaoEndpoints.realocacao.config,
      body,
    );
  },
  getTrades(body: PostFilterTableTradesBody) {
    return client.post<PostFilterTableTradesResponse>(
      recebiveisCartaoEndpoints.filterTable.trades,
      body,
    );
  },
  getTradeChildren(parentId: string | number) {
    return client.get<GetTradesByParentIdChildrenResponse>(
      recebiveisCartaoEndpoints.trades.byParentId.children(parentId),
    );
  },
  getVnpTotal() {
    return client.get<GetVnpTotalResponse>(
      recebiveisCartaoEndpoints.trades.getAllVnp,
    );
  },
  getVnpTrades() {
    return client.get<GetVnpTotalTradesResponse["data"]>(
      recebiveisCartaoEndpoints.trades.getAllVnpTrades,
    );
  },
  getAntecipacaoAutomaticaConfig() {
    return client.get<GetAntecipacaoAutomaticaConfigResponse>(
      recebiveisCartaoEndpoints.antecipacaoAutomatica.getCurrentConfig,
    );
  },
  getAntecipacaoAutomaticaGrid() {
    return client.post<GetAntecipacaoAutomaticaGridResponse>(
      recebiveisCartaoEndpoints.antecipacaoAutomaticaGrid,
    );
  },
  getAgendaCompanies(body: PostFilterTableAgendasCompanyMapBody) {
    return client.post<PostFilterTableAgendasCompanyMapResponse>(
      recebiveisCartaoEndpoints.filterTable.agendasCompanyMap,
      body,
    );
  },
  getCompaniesGroup(prefix: string | number) {
    return client.get<GetCompaniesMapGroupByPrefixResponse>(
      recebiveisCartaoEndpoints.companies.map.group.byPrefix(prefix),
    );
  },
  addGroup(body: PostCompaniesMapGroupBody) {
    return client.post<PostCompaniesMapGroupResponse>(
      recebiveisCartaoEndpoints.companies.map.group.endpoint,
      body,
    );
  },
  removeGroup(prefix: string | number) {
    return client.delete<DeleteCompaniesMapGroupByPrefixResponse>(
      recebiveisCartaoEndpoints.companies.map.group.byPrefix(prefix),
    );
  },
  getAcquirers() {
    return client.get<GetCompaniesCredenciadorasResponse>(
      recebiveisCartaoEndpoints.companies.credenciadoras,
    );
  },
  getBrands() {
    return client.get<GetBandeirasAllResponse>(
      recebiveisCartaoEndpoints.bandeiras.all,
    );
  },
  addOptin(body: PostAddOptinBody) {
    return client.post<PostAddOptinResponse>(
      recebiveisCartaoEndpoints.optin.requestOptins,
      body,
    );
  },
  getOptins(body: GetOptinViewRequest) {
    return client.post<GetOptinViewResponse>(
      recebiveisCartaoEndpoints.optin.getOptins,
      body,
    );
  },
  getRejectedOptins(body: ODataRequest) {
    return client.post<GetRejectedOptinsResponse>(
      recebiveisCartaoEndpoints.optin.rejectedOptins,
      body,
    );
  },
  getDeflators(params?: GetV2DeflatoresParams) {
    return client.get<GetV2DeflatoresResponse>(
      recebiveisCartaoEndpoints.v2.deflatores,
      { params },
    );
  },
  getConciliacao(startDate: string, endDate: string) {
    return client.get<GetConciliacaoResponse>(
      recebiveisCartaoEndpoints.slc.getConciliacao(startDate, endDate),
    );
  },
  getConciliacaoDetail(maturityDate: string) {
    return client.get<GetConciliacaoDetailResponseItem[]>(
      recebiveisCartaoEndpoints.slc.getConciliacaoDetail(maturityDate),
    );
  },
  getCedenteDeflator() {
    return client.get<GetCedenteDeflatorResponse>(
      recebiveisCartaoEndpoints.deflatores.cedente,
    );
  },
  getDefaultDeflator() {
    return client.get<GetDeflatoresDefaultResponse>(
      recebiveisCartaoEndpoints.deflatores.default,
    );
  },
  setDefaultDeflator(body: PutDeflatoresDefaultBody) {
    return client.put<PutDeflatoresDefaultResponse>(
      recebiveisCartaoEndpoints.deflatores.default,
      body,
    );
  },
  addDeflator(body: PostDeflatoresBody) {
    return client.post<PostDeflatoresResponse>(
      recebiveisCartaoEndpoints.deflatores.endpoint,
      body,
    );
  },
  editDeflator(id: string | number, body: PutDeflatoresByIdBody) {
    return client.put<PutDeflatoresByIdResponse>(
      recebiveisCartaoEndpoints.deflatores.byId(id),
      body,
    );
  },
  getConvenios(body: PostConveniosGridBody) {
    return client.post<PostConveniosGridResponse>(
      recebiveisCartaoEndpoints.convenios.grid,
      body,
    );
  },
  getEmailConfigs(params?: GetEmailConfigsParams) {
    return client.get<GetEmailConfigsResponse>(
      recebiveisCartaoEndpoints.emailConfigs.endpoint,
      { params },
    );
  },
  addEmailConfig(body: PostEmailConfigsBody) {
    return client.post<PostEmailConfigsResponse>(
      recebiveisCartaoEndpoints.emailConfigs.endpoint,
      body,
    );
  },
  editEmailConfig(id: string | number, body: PutEmailConfigsByIdBody) {
    return client.put<PutEmailConfigsByIdResponse>(
      recebiveisCartaoEndpoints.emailConfigs.byId(id),
      body,
    );
  },
  removeEmailConfig(id: string | number) {
    return client.delete(recebiveisCartaoEndpoints.emailConfigs.byId(id));
  },
  getNonAcquirerCompanies() {
    return client.get<GetCompaniesNotCredenciadorasResponse>(
      recebiveisCartaoEndpoints.companies.notCredenciadoras,
    );
  },
  getAgendasWithoutOperatedValue(
    body: PostFilterTableAgendasWithoutOperatedValueBody,
  ) {
    return client.post<PostFilterTableAgendasWithoutOperatedValueResponse>(
      recebiveisCartaoEndpoints.filterTable.agendasWithoutOperatedValue,
      body,
    );
  },
  getAgendas(body: PostFilterTableAgendasBody) {
    return client.post<PostFilterTableAgendasResponse>(
      recebiveisCartaoEndpoints.filterTable.agendas,
      body,
    );
  },
  getDailySummary(body: PostMonitorResumoDiarioBody) {
    return client.post<PostMonitorResumoDiarioResponse>(
      recebiveisCartaoEndpoints.monitor.resumoDiario,
      body,
    );
  },
  downloadAgenda(params?: GetAgendasImportParams) {
    return client.get<GetAgendasImportResponse>(
      recebiveisCartaoEndpoints.agendas.import,
      { params },
    );
  },
  importAgenda(body: PostAgendasCipBody) {
    return client.post(recebiveisCartaoEndpoints.agendasCip, body);
  },
  executeRelocation(body: PostRealocacaoExecutarBody) {
    return client.post<PostRealocacaoExecutarResponse>(
      recebiveisCartaoEndpoints.realocacao.executar,
      body,
    );
  },
  simulateRelocation(body: PostRealocacaoSimularBody) {
    return client.post<PostRealocacaoSimularResponse>(
      recebiveisCartaoEndpoints.realocacao.simular,
      body,
    );
  },
  getWhitelists(body: PostWhitelistsGridBody) {
    return client.post<PostWhitelistsGridResponse>(
      recebiveisCartaoEndpoints.whitelists.grid,
      body,
    );
  },
  addWhitelist(body: PostWhitelistsBody) {
    return client.post<PostWhitelistsResponse>(
      recebiveisCartaoEndpoints.whitelists.endpoint,
      body,
    );
  },
  editWhitelist(id: string | number, body: PutWhitelistsByIdBody) {
    return client.put<PutWhitelistsByIdResponse>(
      recebiveisCartaoEndpoints.whitelists.byId(id),
      body,
    );
  },
  getJobs(body: PostFilterTableJobsBody) {
    return client.post<PostFilterTableJobsResponse>(
      recebiveisCartaoEndpoints.filterTable.jobs,
      body,
    );
  },
  getCrontab() {
    return client.get<GetJobsCrontabResponse>(
      recebiveisCartaoEndpoints.jobs.crontab,
    );
  },
  runJobs(body: PostJobsRunJobBody) {
    return client.post<PostJobsRunJobResponse>(
      recebiveisCartaoEndpoints.jobs.runJob,
      body,
    );
  },
  stopJobs(body: PostJobsStopJobBody) {
    return client.post<PostJobsStopJobResponse>(
      recebiveisCartaoEndpoints.jobs.stopJob,
      body,
    );
  },
  runWorker(body: PostJobsRunWorkerBody) {
    return client.post(recebiveisCartaoEndpoints.jobs.runWorker, body);
  },
  getErrors(body: PostFilterTableErrorsBody) {
    return client.post<PostFilterTableErrorsResponse>(
      recebiveisCartaoEndpoints.filterTable.errors,
      body,
    );
  },
  getHealthStatus(body: PostMonitorHealthCheckBody) {
    return client.post<PostMonitorHealthCheckResponse>(
      recebiveisCartaoEndpoints.monitor.healthCheck,
      body,
    );
  },
  getTradesHealthStatus(body: PostMonitorTradesBody) {
    return client.post<PostMonitorTradesResponse>(
      recebiveisCartaoEndpoints.monitor.trades,
      body,
    );
  },
  redisponibilizarRecebiveis(body: PostRedisponibilizaRecebiveis) {
    return client.post<PostGenericStatus>(
      recebiveisCartaoEndpoints.agendas.redisponibilizar,
      body,
    );
  },
  postOptOut(body: PostOptoutBody) {
    return client.post(recebiveisCartaoEndpoints.optout.requestOptout, body);
  },
  putRetryOptin(body: PutRetryOptinBody) {
    return client.put(recebiveisCartaoEndpoints.optin.retryOptin, body);
  },
  getProcessDashboard() {
    return client.get<GetProcessDashboardResponse>(
      recebiveisCartaoEndpoints.processDashboard.endpoint,
    );
  },
  getVnpSummary() {
    return client.get<GetVnpSummaryResponse>(
      recebiveisCartaoEndpoints.trades.getVnpSummary,
    );
  },
  getCompanyTraits(body: ODataRequest) {
    return client.post<CompanyTraitsGridResponse>(
      `${recebiveisCartaoEndpoints.companyTraits.endpoint}/grid`,
      body,
    );
  },
  getCompanyProperties() {
    return client.post<CompanyTraitPropertiesResponse>(
      `${recebiveisCartaoEndpoints.companyTraits.endpoint}/grid`,
      {
        group: [{ selector: "trait", isExpanded: false }],
        skip: 0,
        take: 20,
        userData: {},
        dataField: "trait",
      },
    );
  },
  getCompanyTraitsByTaxId(taxId: string) {
    return client.get<CompanyTraitsByTaxIdResponse>(recebiveisCartaoEndpoints.companyTraits.byCompanyTaxId(taxId))
  },
  deleteCompanyTraits({ id }: { id: string }) {
    return client.delete(
      `${recebiveisCartaoEndpoints.companyTraits.endpoint}?id=${id}`,
    );
  },
  addCompanyTraits(body: PostAddCompanyTraitsRequest) {
    return client.post(
      `${recebiveisCartaoEndpoints.companyTraits.endpoint}`,
      body,
    );
  },
};
