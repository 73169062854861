import { Roles } from "roles/roles.types";

export const ferramentasRoles = {
  product: {
    tab: {
      role: "admin:ToolsTabProduct.View",
      description: "Visualização da tab 'Ferramentas' na visão produto",
    },
    eligibilityPage: {
      role: "admin:ToolsEligibilityPageProduct.View",
      description: "Visualização da página 'Elegibilidade' na visão produto",
    },
    ddiManagementPage: {
      role: "admin:ToolsDDIManagementPageProduct.View",
      description: "Visualização da página 'Gestão de DDIs' na visão produto",
    },
    eligibilityClearCacheBCheckButton: {
      role: "admin:ToolsElegibilityClearCacheBCheckButtonProduct.View",
      description:
        "Visualização do botão de limpar cache BCheck na visão produto 2",
    },
    amlDisapprovalPage: {
      role: "admin:ToolsAmlDisapprovalPageProduct.View",
      description:
        "Visualização da página 'Monitor de reprovações AML' na visão produto",
    },
    MonitoringGuarantorAndLoaPage: {
      role: "admin:ToolsMonitoringGuarantorAndLoaPageProduct.View",
      description:
        "Visualização da página 'Monitoramento avalista e procurações' na visão produto",
    },
    MonitoringGuarantorsPage: {
      role: "admin:ToolsMonitoringGuarantorsProduct.View",
      description:
        "Visualização da página 'Monitoramento - Monitoramento Avalistas' na visão produto",
    },
    MonitoringLoaPage: {
      role: "admin:ToolsMonitoringLoaPageProduct.View",
      description:
        "Visualização da página 'Monitoramento - Monitoramento Proc Física' na visão produto",
    },
    MonitoringElosPage: {
      role: "admin:ToolsMonitoringElosPageProduct.View",
      description:
        "Visualização da página 'Monitoramento - Monitoramento Proc Eletrônica' na visão produto",
    },
    powersMonitoringPage: {
      role: "admin:ToolsPowersMonitoringPageProduct.View",
      description:
        "Visualização da página 'Monitoramento de poderes' na visão produto",
    },
    gatekeeperProfilesPage: {
      role: "admin:ToolsGatekeeperProfilesPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Profiles' na visão produto",
    },
    gatekeeperProfilesAddPage: {
      role: "admin:ToolsGatekeeperProfilesAddPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Profiles - Adicionar' na visão produto",
    },
    gatekeeperProfilesGeneralInformationPage: {
      role: "admin:ToolsGatekeeperProfilesGeneralInformationPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Profiles - Informações Gerais' na visão produto",
    },
    gatekeeperProfilesRolesPage: {
      role: "admin:ToolsGatekeeperProfilesRolesPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Profiles - Roles' na visão produto",
    },
    gatekeeperProfilesSubProfilesPage: {
      role: "admin:ToolsGatekeeperProfilesSubProfilesPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Profiles - SubProfiles' na visão produto",
    },
    gatekeeperProfilesDetailsPage: {
      role: "admin:ToolsGatekeeperProfilesDetailsPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Profiles - Detalhes do profile' na visão produto",
    },
    gatekeeperProfilesActsMappingPage: {
      role: "admin:ToolsGatekeeperProfilesActsMappingPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Profiles - Mapeamento de atos' na visão produto",
    },
    gatekeeperRolesPage: {
      role: "admin:ToolsGatekeeperRolesPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Roles' na visão produto",
    },
    gatekeeperRolesAddPage: {
      role: "admin:ToolsGatekeeperRolesAddPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Roles - Adicionar' na visão produto",
    },
    gatekeeperRolesDetailsPage: {
      role: "admin:ToolsGatekeeperRolesDetailsPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Roles - Detalhes' na visão produto",
    },
    gatekeeperSystemsPage: {
      role: "admin:ToolsGatekeeperSystemsPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Sistemas' na visão produto",
    },
    gatekeeperSystemsAddPage: {
      role: "admin:ToolsGatekeeperSystemsAddPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Sistemas - Adicionar' na visão produto",
    },
    gatekeeperSystemsDetailsPage: {
      role: "admin:ToolsGatekeeperSystemsDetailsPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Sistemas - Detalhes' na visão produto",
    },
    gatekeeperProfilesManagementPage: {
      role: "admin:ToolsGatekeeperProfilesManagementPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Gerenciamento de perfis' na visão produto",
    },
    gatekeeperAdminProfilesManagementPage: {
      role: "admin:ToolsGatekeeperAdminProfilesManagementPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Análise de solicitações' na visão produto",
    },
    gatekeeperUsersPage: {
      role: "admin:ToolsGatekeeperUsersPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Usuários' na visão produto",
    },
    gatekeeperAdvisorsPage: {
      role: "gatekeeper:Advisors.Read",
      description:
        "Visualização da página 'Gatekeeper - Assessores' na visão produto",
    },
    gatekeeperUsersUserProfilesPage: {
      role: "admin:ToolsGatekeeperUsersUserProfilesPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Usuários - Profiles do usuário' na visão produto",
    },
    gatekeeperUsersCompanyUserProfilesPage: {
      role: "admin:ToolsGatekeeperUsersCompanyUserProfilesPageProduct.View",
      description:
        "Visualização da página 'Gatekeeper - Usuários - Profiles da empresa' na visão produto",
    },
    securityBiometricsReleasePage: {
      role: "admin:ToolsSecurityBiometricsReleasePageProduct.View",
      description:
        "Visualização da página 'Segurança e Antifraude - Liberação Biométrica' na visão produto",
    },
    securityAntifraudAnalysisPage: {
      role: "admin:ToolsSecurityAntifraudAnalysisPageProduct.View",
      description:
        "Visualização da página 'Segurança e Antifraude - Análise Antifraude' na visão produto",
    },
    securityAntifraudAnalysisDetailsPage: {
      role: "admin:ToolsSecurityAntifraudAnalysisDetailsPageProduct.View",
      description:
        "Visualização da página 'Segurança e Antifraude - Análise Antifraude - Detalhes' na visão produto",
    },
    clerkPage: {
      role: "admin:ToolsClerkPageProduct.View",
      description: "Visualização da página 'Sistemas Clerk' na visão produto",
    },
    clerkAddPage: {
      role: "admin:ToolsClerkAddPageProduct.View",
      description:
        "Visualização da página 'Sistemas Clerk - Adicionar' na visão produto",
    },
    clerkDetailsPage: {
      role: "admin:ToolsClerkDetailsPageProduct.View",
      description:
        "Visualização da página 'Sistemas Clerk - Detalhes' na visão produto",
    },
    ravenPage: {
      role: "admin:ToolsRavenPageProduct.View",
      description: "Visualização da página 'Raven Templates' na visão produto",
    },
    ravenAddPage: {
      role: "admin:ToolsRavenAddPageProduct.View",
      description:
        "Visualização da página 'Raven Templates - Adicionar' na visão produto",
    },
    ravenDetailsPage: {
      role: "admin:ToolsRavenDetailsPageProduct.View",
      description:
        "Visualização da página 'Raven Templates - Detalhes' na visão produto",
    },
    ravenNotificationTypesPage: {
      role: "raven:NotificationType.Read",
      description:
        "Visualização da página 'Raven - Notificações' na visão produto",
    },
    ravenAddNotificationTypesPage: {
      role: "raven:NotificationType.Create",
      description:
        "Visualização da página 'Raven - Notificações - Adicionar' na visão produto",
    },
    ravenRemoveNotificationTypes: {
      role: "raven:NotificationType.Create",
      description: "Visualização da botão de deleção de um Tipo de Notificação",
    },
    ravenNotificationTypesDetailsPage: {
      role: "raven:NotificationType.Update",
      description:
        "Visualização da página 'Raven - Notificações - Detalhes' na visão produto",
    },
    ravenAddNotificationConfigPage: {
      role: "raven:NotificationConfig.Create",
      description:
        "Visualização da página 'Raven - Notificações - Nova configuração' na visão produto",
    },
    ravenNotificationConfigDetailsPage: {
      role: "raven:NotificationConfig.Update",
      description:
        "Visualização da página 'Raven - Notificações - Configuração - Detalhes' na visão produto",
    },
    ravenJobsPage: {
      role: "raven:Jobs.Read",
      description:
        "Visualização da página 'Raven - Notificações - Jobs' na visão produto",
    },
    ravenRunJobs: {
      role: "raven:Jobs.Run",
      description: "Visualização do modal 'Redisparar Job' na visão produto",
    },
    ravenStopJobs: {
      role: "raven:Jobs.Stop",
      description: "Visualização do modal 'Parar Job' na visão produto",
    },
    desksPage: {
      role: "admin:ToolsDesksPageProduct.View",
      description: "Visualização da página 'Mesas' na visão produto",
    },
    registrarAtendentePage: {
      role: "admin:ToolsRegistrarAtendentePageProduct.View",
      description:
        "Visualização da página 'Registrar atendente' na visão produto",
    },
    manageDeskMigrationsPage: {
      role: "admin:ToolsManageDeskMigrationsPageProduct.View",
      description:
        "Visualização da página 'Gerenciar migrações de segmento' na visão produto",
    },
    manageDeskMigrationsDetailsPage: {
      role: "admin:ToolsManageDeskMigrationsDetailsPageProduct.View",
      description:
        "Visualização da página 'Gerenciar migrações de segmento - Detalhes' na visão produto",
    },
    roadwalker: {
      role: "admin:ToolsRoadwalkerPageProduct.View",
      description: "Visualização da página 'Roadwalker' na visão produto",
    },
    onboardingDomainConfigPage: {
      role: "admin:ToolsOnboardingDomainConfigPageProduct.View",
      description:
        "Visualização da página 'Onboarding - Configuração de Domínio' na visão produto",
    },
    onboardingDomainConfigAddPage: {
      role: "admin:ToolsOnboardingDomainConfigAddPageProduct.View",
      description:
        "Visualização da página 'Onboarding - Configuração de Domínio - Adicionar' na visão produto",
    },
    onboardingDomainConfigEditPage: {
      role: "admin:ToolsOnboardingDomainConfigEditPageProduct.View",
      description:
        "Visualização da página 'Onboarding - Configuração de Domínio - Editar' na visão produto",
    },
    onboardingDomainConfigScfPage: {
      role: "admin:ToolsOnboardingDomainConfigScfPageProduct.View",
      description:
        "Visualização da página 'Onboarding - Configuração de Domínio Scf' na visão produto",
    },
    onboardingDomainConfigScfAddPage: {
      role: "admin:ToolsOnboardingDomainConfigScfAddPageProduct.View",
      description:
        "Visualização da página 'Onboarding - Configuração de Domínio Scf - Adicionar' na visão produto",
    },
    onboardingDomainConfigScfEditPage: {
      role: "admin:ToolsOnboardingDomainConfigScfEditPageProduct.View",
      description:
        "Visualização da página 'Onboarding - Configuração de Domínio Scf - Editar' na visão produto",
    },
    onboardingWorkflowConfigScfPage: {
      role: "admin:ToolsOnboardingWorkflowConfigScfPageProduct.View",
      description:
        "Visualização da página 'Onboarding - Configuração de Workflow Scf' na visão produto",
    },
    onboardingWorkflowConfigScfAddPage: {
      role: "admin:ToolsOnboardingWorkflowConfigScfAddPageProduct.View",
      description:
        "Visualização da página 'Onboarding - Configuração de Workflow Scf - Adicionar' na visão produto",
    },
    onboardingWorkflowConfigScfEditPage: {
      role: "admin:ToolsOnboardingWorkflowConfigScfEditPageProduct.View",
      description:
        "Visualização da página 'Onboarding - Configuração de Workflow Scf - Editar' na visão produto",
    },
    onboardingPartnersPage: {
      role: "admin:OnboardingPartnersPageProduct.View",
      description:
        "Visualização da página 'Onboarding - Parceiros' na visão produto",
    },
    onboardingPartnerUpsertPage: {
      role: "admin:OnboardingPartnerUpsertPageProduct.View",
      description:
        "Visualização da página 'Onboarding - Parceiros - Adicionar parceiro' na visão produto",
    },
    onboardingScfOfficersPage: {
      role: "admin:OnboardingScfOfficersPageProduct.View",
      description:
        "Visualização da página 'Onboarding - Officers' na visão produto",
    },
    onboardingScfOfficerUpsertPage: {
      role: "admin:OnboardingScfOfficerUpsertPageProduct.View",
      description:
        "Visualização da página 'Onboarding - Officers - Adicionar Officer' na visão produto",
    },
    semaphoreConfigurationPage: {
      role: "admin:SemaphoreConfiguration.View",
      description:
        "Visualização da página 'Configuração de Semáforo' na visão produto",
    },
    semaphoreConfigTemplatePage: {
      role: "admin:SemaphoreConfigurationTemplate.View",
      description:
        "Visualização da página 'Configuração de Semáforo - Template' na visão produto",
    },
    semaphoreConfigTemplateDetails: {
      role: "admin:SemaphoreConfigTemplateDetails.View",
      description:
        "Visualização da página 'Configuração de Semáforo - Template - Detalhes' na visão produto",
    },
    semaphoreConfigTemplateAdd: {
      role: "admin:SemaphoreConfigTemplateAdd.View",
      description:
        "Visualização da página 'Configuração de Semáforo - Template - Adicionar' na visão produto",
    },
    semaphoreConfigTemplateRemove: {
      role: "admin:SemaphoreConfigTemplateRemove.View",
      description:
        "Visualização da página 'Configuração de Semáforo - Template - Detalhes - Remover' na visão produto",
    },
    semaphoreConfigStepPage: {
      role: "admin:SemaphoreConfigurationSteps.View",
      description:
        "Visualização da página 'Configuração de Semáforo - Steps' na visão produto",
    },
    semaphoreConfigStepDetails: {
      role: "admin:SemaphoreConfigTemplateDetails.View",
      description:
        "Visualização da página 'Configuração de Semáforo - Steps - Detalhes' na visão produto",
    },
    semaphoreConfigStepAdd: {
      role: "admin:SemaphoreConfigTemplateAdd.View",
      description:
        "Visualização da página 'Configuração de Semáforo - Steps - Adicionar' na visão produto",
    },
    semaphoreConfigStepUpdate: {
      role: "admin:SemaphoreConfigTemplateUpdate.View",
      description:
        "Visualização da página 'Configuração de Semáforo - Steps - Detalhes - Atualizar' na visão produto",
    },
    semaphoreConfigStepRemove: {
      role: "admin:SemaphoreConfigTemplateRemove.View",
      description:
        "Visualização da página 'Configuração de Semáforo - Steps - Detalhes - Remove' na visão produto",
    },
    entitySemaphorePage: {
      role: "admin:EntitySemaphorePage.View",
      description:
        "Visualização da página 'Onboarding - Semáforo - Consultas' na visão de produto",
    },
    entitySemaphoreDetails: {
      role: "admin:EntitySemaphoreDetails.View",
      description:
        "Visualização do botão de detalhes na página 'Onboarding - Semáforo - Consultas' na visão de produto",
    },
    entitySemaphoreDetailsPage: {
      role: "admin:EntitySemaphoreDetailsPage.View",
      description:
        "Visualização da página 'Onboarding - Semáforo - Consultas - Consulta Específica' na visão de produto",
    },
    onboardingWorkflowsConfigsDesksPage: {
      role: "admin:OnboardingWorkflowsConfigsDesksPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Mesas' na visão de produto",
    },
    onboardingWorkflowsConfigsDesksRegisterPage: {
      role: "admin:OnboardingWorkflowsConfigsDesksRegisterPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Mesas - Adicionar' na visão de produto",
    },
    onboardingWorkflowsConfigsDesksDetailsPage: {
      role: "admin:OnboardingWorkflowsConfigsDesksDetailsPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflow - Mesas - Detalhes' na visão de produto",
    },
    onboardingWorkflowsConfigsConfigsPage: {
      role: "admin:OnboardingWorkflowsConfigsConfigsPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa' na visão de produto",
    },
    onboardingWorkflowsConfigsDeskConfigsRegisterPage: {
      role: "admin:OnboardingWorkflowsConfigsDeskConfigsRegisterPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa - Adicionar' na visão de produto",
    },
    onboardingWorkflowsConfigsDeskConfigsDetailsPage: {
      role: "admin:OnboardingWorkflowsConfigsDeskConfigsDetailsPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa - Detalhes' na visão de produto",
    },
    onboardingWorkflowsConfigsDeskConfigRelationshipPage: {
      role: "admin:OnboardingWorkflowsConfigsDeskConfigRelationshipPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa - Relacionamento mesa/configuração' na visão de produto",
    },
    onboardingWorkflowsConfigsDeskConfigRelationshipRegisterPage: {
      role: "admin:OnboardingWorkflowsConfigsDeskConfigRelationshipRegisterPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa - Relacionamento mesa/configuração - Adicionar' na visão de produto",
    },
    onboardingWorkflowsConfigsDeskConfigRelationshipDetailsPage: {
      role: "admin:OnboardingWorkflowsConfigsDeskConfigRelationshipDetailsPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa - Relacionamento mesa/configuração - Detalhes' na visão de produto",
    },
    onboardingWorkflowsConfigsDeskDocumentConfigRelationshipPage: {
      role: "admin:OnboardingWorkflowsConfigsDeskDocumentConfigRelationshipPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa - Relacionamento documento/configuração' na visão de produto",
    },
    onboardingWorkflowsConfigsDeskDocumentConfigRelationshipRegisterPage: {
      role: "admin:OnboardingWorkflowsConfigsDeskDocumentConfigRelationshipRegisterPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa - Relacionamento documento/configuração - Adicionar' na visão de produto",
    },
    onboardingWorkflowsConfigsDeskDocumentConfigPage: {
      role: "admin:OnboardingWorkflowsConfigsDeskDocumentConfigPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configuração de documentos' na visão de produto",
    },
    onboardingWorkflowsConfigsDeskDocumentConfigRegisterPage: {
      role: "admin:OnboardingWorkflowsConfigsDeskDocumentConfigRegisterPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configuração de documentos - Adicionar' na visão de produto",
    },
    onboardingWorkflowsConfigsAccountInfoPage: {
      role: "admin:OnboardingWorkflowsConfigsAccountInfoPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Informações de conta' na visão de produto",
    },
    onboardingWorkflowsConfigsAccountInfoRegisterPage: {
      role: "admin:OnboardingWorkflowsConfigsAccountInfoRegisterPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Informações de conta - Adicionar' na visão de produto",
    },
    onboardingWorkflowsConfigsAccountInfoDetailsPage: {
      role: "admin:OnboardingWorkflowsConfigsAccountInfoDetailsPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Informações de conta - Detalhes' na visão de produto",
    },
    onboardingWorkflowsConfigsPartnerConfigPage: {
      role: "admin:OnboardingWorkflowsConfigsPartnerConfigPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa com parceiros' na visão de produto",
    },
    onboardingWorkflowsConfigsPartnerConfigRegisterPage: {
      role: "admin:OnboardingWorkflowsConfigsPartnerConfigRegisterPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa com parceiros - Adicionar' na visão de produto",
    },
    onboardingWorkflowsConfigsPartnerConfigDetailsPage: {
      role: "admin:OnboardingWorkflowsConfigsPartnerConfigDetailsPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa com parceiros - Detalhes' na visão de produto",
    },
    onboardingWorkflowsConfigsAccountConfigPage: {
      role: "admin:OnboardingWorkflowsConfigsAccountConfigPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa - Relacionamento informações de conta/configuração' na visão de produto",
    },
    onboardingWorkflowsConfigsAccountConfigRegisterPage: {
      role: "admin:OnboardingWorkflowsConfigsAccountConfigRegisterPage.View",
      description:
        "Visualização da página 'Onboarding -Configuração de workflows - Configurações de mesa - Relacionamento informações de conta/configuração - Adicionar' na visão de produto",
    },
    onboardingWorkflowsConfigsAccountConfigDetailsPage: {
      role: "admin:OnboardingWorkflowsConfigsAccountConfigDetailsPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa - Relacionamento informações de conta/configuração - Detalhes' na visão de produto",
    },
    onboardingWorkflowsConfigsAccountConfigDetailsRelationshipsPage: {
      role: "admin:OnboardingWorkflowsConfigsAccountConfigDetailsRelationshipsPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa - Relacionamento informações de conta/configuração - Detalhes - Relacionamentos' na visão de produto",
    },
    onboardingWorkflowsConfigsContractFileConfigurationPage: {
      role: "admin:OnboardingWorkflowsConfigsContractFileConfigurationPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de arquivo do contrato' na visão de produto",
    },
    onboardingWorkflowsConfigsContractFileConfigurationRegisterPage: {
      role: "admin:OnboardingWorkflowsConfigsContractFileConfigurationRegisterPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de arquivo do contrato - Adicionar' na visão de produto",
    },
    onboardingWorkflowsConfigsContractFileConfigurationDetailsPage: {
      role: "admin:OnboardingWorkflowsConfigsContractFileConfigurationDetailsPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de arquivo do contrato - Detalhes' na visão de produto",
    },
    onboardingWorkflowsConfigsDefaultContractFileConfigurationPage: {
      role: "admin:OnboardingWorkflowsConfigsDefaultContractFileConfigurationPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações padrão de arquivo do contrato' na visão de produto",
    },
    onboardingWorkflowsConfigsDefaultContractFileConfigurationRegisterPage: {
      role: "admin:OnboardingWorkflowsConfigsDefaultContractFileConfigurationRegisterPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações padrão de arquivo do contrato - Adicionar' na visão de produto",
    },
    onboardingWorkflowsConfigsDefaultContractFileConfigurationDetailsPage: {
      role: "admin:OnboardingWorkflowsConfigsDefaultContractFileConfigurationDetailsPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações padrão de arquivo do contrato - Detalhes' na visão de produto",
    },
    onboardingWorkflowsConfigsDeskNotificationsPage: {
      role: "admin:OnboardingWorkflowsConfigsDeskNotificationsPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa - Detalhes - Notificações' na visão de produto",
    },
    onboardingWorkflowsConfigsDeskOfficersConfigPage: {
      role: "admin:OnboardingWorkflowsConfigsDeskOfficersConfigPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa - Detalhes - Officers' na visão de produto",
    },
    onboardingWorkflowsConfigsDeskPipelinesConfigPage: {
      role: "admin:OnboardingWorkflowsConfigsDeskPipelinesConfigPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Configurações de mesa - Detalhes - Pipelines' na visão de produto",
    },
    onboardingUploadOnboardingFilesPage: {
      role: "admin:OnboardingUploadOnboardingFilesPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Upload de arquivos' na visão de produto",
    },
    onboardingOfficersPage: {
      role: "admin:OnboardingOfficersPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de workflows - Officers' na visão de produto",
    },
    onboardingInviteConfigsEmailTemplatesPage: {
      role: "admin:OnboardingInviteConfigsEmailTemplatesPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de convite - Templates de email' na visão de produto",
    },
    onboardingInviteConfigsEmailTemplatesAddPage: {
      role: "admin:OnboardingInviteConfigsEmailTemplatesAddPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de convite - Templates de email - Adicionar' na visão de produto",
    },
    onboardingInviteConfigsEmailTemplatesDetailsPage: {
      role: "admin:OnboardingInviteConfigsEmailTemplatesDetailsPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de convite - Templates de email - Detalhes' na visão de produto",
    },
    onboardingInviteConfigsEmailTemplatesRemovePage: {
      role: "admin:OnboardingInviteConfigsEmailTemplatesRemovePage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de convite - Templates de email - Remover' na visão de produto",
    },
    onboardingInviteConfigsInviteTypesPage: {
      role: "admin:OnboardingInviteConfigsInviteTypesPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de convite - Tipos de convite' na visão de produto",
    },
    onboardingInviteConfigsInviteTypesAddPage: {
      role: "admin:OnboardingInviteConfigsInviteTypesAddPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de convite - Tipos de convite - Adicionar' na visão de produto",
    },
    onboardingInviteConfigsInviteTypesDetailsPage: {
      role: "admin:OnboardingInviteConfigsInviteTypesDetailsPage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de convite - Tipos de convite - Detalhes' na visão de produto",
    },
    onboardingInviteConfigsInviteTypesRemovePage: {
      role: "admin:OnboardingInviteConfigsInviteTypesRemovePage.View",
      description:
        "Visualização da página 'Onboarding - Configuração de convite - Tipos de convite - Remover' na visão de produto",
    },
  },
} satisfies Roles;
