import { Currency } from "utils/currency";

export interface Installment {
  code: number;
  status: string;
  balance: number;
  amortization: number;
  amount: number;
  interest: number;
  maturityDate: Date;
  settlementDate?: Date;
  currency: string;
}

export interface Settlement {
  amount: number;
  iofAmount: number;
  cet: number;
  status: string;
  installments: Installment[];
}

export interface GuarantorsSetup {
  type: string;
  requiredGuarantors: string[];
}

export interface BankAccount {
  id: number;
  primary: boolean;
  bankCode: string;
  agency: string;
  agencyDigit?: string;
  accountNumber: string;
  accountDigit?: string;
  description?: string;
  accountType: string;
  company?: string;
}

export interface DisbursementInstruction {
  amount: number;
  type: string;
  taxId: string;
  holderName: string;
  bankCode: string;
  account: string;
  accountDigit: string;
  agency: string;
  agencyDigit: string;
  accountType: string;
}

export interface AdditionalFee {
  amount: number;
  type:
    | "Registration"
    | "Report"
    | "Diligence"
    | "INVC"
    | "FORM"
    | "TAC"
    | "CAPF"
    | "Seguro";
}

export interface SimulationErrorMap {
  [key: string]: SimulationErrorItem;
}

interface SimulationErrorItem {
  message: string;
}

export interface GracePeriodInterval {
  gracePeriod: number;
  minDate: moment.Moment;
  maxDate: moment.Moment;
}

interface Interest {
  indexCode: IndexCodeType;
}

export type IndexCodeType = "PRE" | "CDIE" | "NIPCA";

export interface Collateral {
  id: number;
  collateralType: string;
  guarantorClassification: string;
  description: string;
  amount: number;
  manufacturer: string;
  quantity: number;
  identification: string;
  unitPrice?: number;
  priceCode?: string;
  weight?: number;
  weightUnit?: string;
  guaranteedPercentage?: number;
  reviewPrice: number;
  hasOptional: boolean;
  lockDisbursement: string;
  properties: Property[];
  contractualParties: ContractualParty[];
  guarantor: CreditManagerGuarantor;
}

export interface Property {
  id: number;
  identification: string;
  name: string;
  rgi: string;
  totalArea?: number;
  ccir: string;
  nirf: string;
  idealFraction?: number;
  registration: string;
  car: string;
  address: Address;
  registries: Registry[];
}

export interface Registry {
  protocol: string;
  registryDate?: string;
  registryFirmTaxId: string;
  registryFirmName: string;
  zipCode: string;
  address: string;
  neighborhood: string;
  county: string;
  city: string;
  state: string;
  country: string;
}

export interface Address {
  zipCode: string;
  type: string;
  buildingNumber: string;
  complement: string;
  district: string;
  city: string;
  cityId?: number;
  state: string;
  addressStreet: string;
}

export interface ContractualParty {
  name: string;
  taxId: string;
  type: string;
  representativeName: string;
  email: string;
  phoneNumber: string;
  originator?: Originator;
}

export interface Originator {
  city?: string;
  identification: string;
  name: string;
  state: string;
}

export interface CreditManagerGuarantor {
  type: string;
  legalEntity: LegalEntity;
}

export interface LegalEntity {
  name: string;
  taxId: string;
  type: string;
  birthDate: string;
}

export enum ProductType {
  ACC_OPERACAO = "ACC_OPERACAO",
  ACC_LIMITE_GLOBAL = "ACC_LIMITE_GLOBAL",
  AGRO = "AGRO",
  AGRO_COSTING = "AGRO_COSTING",
  AGRO_INVESTMENT = "AGRO_INVESTMENT",
  ANTECIPACAO_DE_RECEBIVEIS_BOLETOS = "ANTECIPACAO_DE_RECEBIVEIS_BOLETOS",
  ANTECIPACAO_DE_RECEBIVEIS_CARTAO = "ANTECIPACAO_DE_RECEBIVEIS_CARTAO",
  CARTAO_DE_CREDITO = "CARTAO_DE_CREDITO",
  CCB = "CCB",
  FIDC = "FIDC",
  FUMACA = "FUMACA",
  FUMACAPEAC = "FUMACAPEAC",
  KGIRO = "KGIRO",
  KGIROPEAC = "KGIROPEAC",
  LIMITE_MAIS = "LIMITE_MAIS",
  SOLAR = "SOLAR",
  TOMBAMENTO_CARTAO = "TOMBAMENTO_CARTAO",
}

export const currencySymbolMap: Record<string, string | undefined> = {
  BRL: "R$",
  USD: "$",
  EUR: "€",
  GBP: "£",
  CHF: "₣",
};

export interface Contract {
  status: string;
  formalizationDate: string;
  downloadUri: string;
  number: string;
  externalId: string;
  signatureOrigin: string;
  type: ContractType;
}

export enum ContractType {
  CCB = "CCB",
  IFC = "IFC",
  Cessao = "Cessao",
  Operacao = "Operacao",
  Renegociacao = "Renegociacao",
  CPR = "CPR",
  AF_IMOVEL = "AF_IMOVEL",
  CAC = "CAC",
}

export interface Disbursement {
  id: number;
  authentication: string;
  disbursementValue: number;
  disbursementDate?: Date;
  paymentTicket: PaymentTicket;
  type: string;
  account: Account;
}

interface PaymentTicket {
  key: string;
  name: string;
  source: string;
}

export interface Account {
  id: number;
  accountType: string;
  acccountHolder: string | null;
  taxIdHolder: string | null;
  bankCode: string;
  agency: string;
  agencyDigit: string | null;
  accountNumber: string;
  accountDigit: string | null;
  description: null;
  pixKey: string | null;
  accountDoc: string | null;
  settlementInternalDoc: null;
  agreementIdentification: null;
  active: boolean;
}

export enum LoanStatus {
  Invalido = "Invalido",
  Rejeitado = "Rejeitado",
  Cancelado = "Cancelado",
  Expirado = "Expirado",
  Criado = "Criado",
  Simulado = "Simulado",
  PendenteOnboardingTerceiros = "PendenteOnboardingTerceiros",
  PendenteAtivacaoConta = "PentendeAtivacaoConta",
  GeracaoDeContrato = "GeracaoDeContrato",
  PendenteValidacao = "PendenteValidacao",
  Validado = "Validado",
  PendenteConfirmacaoLimite = "PendenteConfirmacaoLimite",
  PendenteAssinatura = "PendenteAssinatura",
  PendenteTrade = "PendenteTrade",
  TradeEnviado = "TradeEnviado",
  Assinado = "Assinado",
  Desembolsado = "Desembolsado",
  Liquidado = "Liquidado",
  AguardandoAprovacao = "AguardandoAprovação",
  AguardandoDesembolso = "AguardandoDesembolso",
  Atrasado = "Atrasado",
  HomologacaoBNDES = "HomologacaoBNDES",
  AprovacaoEnvioTrade = "AprovacaoEnvioTrade",
}

export interface LoanDetails {
  settlement: Settlement;
  contract: Contract;
  contractList: Contract[];
  disbursements: Disbursement[];
  guarantors: {
    type: string;
    requiredGuarantors: string[];
  };
  id: number;
  identification: string;
  externalId: string;
  amount: number;
  currency: Currency;
  currencyPrice: number;
  rate: number;
  tax: number;
  fee: number;
  charges: number;
  cet: number;
  iofAmount: number;
  status: LoanStatus;
  rejectedStatusCodes: Array<string>;
  reason: string;
  settlementDay: number;
  product: string;
  term: number;
  party: {
    taxId: string;
    cge: number;
    name: string;
  };
  brokerCounterparty: {
    taxId: string;
    cge: number;
    name: string;
  };
  company: {
    taxId: string;
    cge: number;
    name: string;
  };
  firtsInstallmentDate: string;
  createdDate: string;
  detailsOrigin: string;
  parentLoanContract?: string;
  interest: Interest;
  baseDate: Date;
  amortizationFrequency: "ANUAL" | "BULLET" | "MENSAL";
  parentLoans: ParentLoan[];
  correlationId: string;
  pendencies: Pendency[];
  partnerChannelAgreementId: number;
  loanStatusHistory: LoanStatusHistoryItem[];
}

interface LoanStatusHistoryItem {
  newStatus: string;
  oldStatus: string;
  createdDate: string;
}

interface Pendency {
  id: number;
  message: string | null;
  loanStatus: string;
  type: string;
  pending: boolean;
  solvedDate: string | null;
  resolvedBy: string | null;
}

export interface ParentLoan {
  amount: number;
  status: string;
  contract: Contract;
}

export interface GuarantorsToLoad {
  type: GuarantorType;
  requiredGuarantors: string[];
  companyTaxId?: string;
}

export type GuarantorType =
  | "COMPLETO"
  | "SIMPLES"
  | "REPRESENTANTE_LEGAL"
  | "OPERADOR";

export type BNDESFrameStatus = "PENDING" | "REJECTED" | "APPROVED" | "ERROR";
