import { OButton, OLoader } from "@maestro/react";
import styled from "styled-components";

export const CardContainer = styled.div`
  min-width: 16rem;
  max-width: 21rem;
`;

export const StyledButton = styled(OButton)`
  button {
    min-width: 30%;
  }
`;

export const CentralizedLoader = styled(OLoader)`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
