import * as yup from "yup";
import { LimitCollateralsFormValues } from "./limit-collaterals-form.grid";
import { LimitGuarantorsFormValues } from "./limit-guarantors-form.grid";
import { LimitInstallmentFormValues } from "./limit-installments-form.grid";

export type LimitFormValues = {
  customerTaxId: string;
  name: string;
  ratingType: string;
  productType: string;
  expirationDate: string;
  amount: number;
} & {
  creditType: string;
  portfolioClassification: string;
  ratingCounterparty: string;
  exposureAtDefault: number;
  defaultProbability: number;
  lostGivenDefault: number;
  expectedLoss: number;
  incurredLoss: number;
  stage: number;
} & {
  collaterals: LimitCollateralsFormValues[];
  guarantors: LimitGuarantorsFormValues[];
  rate: { quotes: LimitInstallmentFormValues[] };
};

export const limitFormValidationsSchema: yup.ObjectSchema<LimitFormValues> =
  yup.object({
    customerTaxId: yup.string().required("Este campo é obrigatório"),
    name: yup.string().required("Este campo é obrigatório"),
    ratingType: yup.string().required("Este campo é obrigatório"),
    productType: yup.string().required("Este campo é obrigatório"),
    expirationDate: yup
      .string()
      .transform((date) => {
        return date && new Date(date).toISOString();
      })
      .required("Este campo é obrigatório"),
    amount: yup
      .number()
      .transform((value) => value || undefined)
      .required("Este campo é obrigatório"),

    creditType: yup.string().required("Este campo é obrigatório"),
    portfolioClassification: yup.string().required("Este campo é obrigatório"),
    ratingCounterparty: yup.string().required("Este campo é obrigatório"),
    exposureAtDefault: yup.number().required("Este campo é obrigatório"),
    defaultProbability: yup.number().required("Este campo é obrigatório"),
    lostGivenDefault: yup.number().required("Este campo é obrigatório"),
    expectedLoss: yup.number().required("Este campo é obrigatório"),
    incurredLoss: yup.number().required("Este campo é obrigatório"),
    stage: yup.number().required("Este campo é obrigatório"),

    rate: yup.object({
      quotes: yup.array().default([]).min(1, "Este campo é obrigatório"),
    }),
    collaterals: yup.array().default([]),
    guarantors: yup.array().default([]),
  });
