import * as yup from "yup";

export interface PropertyValueForm {
  value: string | null;
  adequacyOptionId: number | null;
  // helpers
  propertyType: string | null;
  files: { name: string; key: string }[] | null;
}

export const propertyValueFormDefaultValues: PropertyValueForm = {
  adequacyOptionId: null,
  value: null,
  propertyType: null,
  files: null,
};

export const propertyValueFormValidationSchema = yup.object({
  value: yup.string().when("propertyType", {
    is: "DOCUMENTO_ASSINATURA_EXTERNA",
    then: (s) =>
      s.test(
        "is-valid-href",
        "O link de assinatura deve começar com 'http://' ou 'https://'",
        (value) =>
          !!value?.startsWith("http://") || !!value?.startsWith("https://"),
      ),
    otherwise: (s) => s.nullable(),
  }),
});
