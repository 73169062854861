import { OSkeletonCard, OTypography } from "@maestro/react";
import { CopyToClipboard } from "components/copy-to-clipboard";

interface SingleDetailProps {
  title: string;
  isLoading: boolean;
  content?: string;
  skeletonWidth: string;
}

export const SingleDetail = ({
  isLoading,
  title,
  content = "-",
  skeletonWidth,
}: SingleDetailProps) => {
  return (
    <div className="d-flex flex-column gap-2 w-50">
      <OTypography type="dark" key={title}>
        {title}
      </OTypography>

      {isLoading ? (
        <OSkeletonCard height="1rem" width={skeletonWidth} rounded />
      ) : (
        <CopyToClipboard
          text={content}
          successText="Copiado com sucesso"
          textType="dark-80"
        />
      )}
    </div>
  );
};
