import { modalManager, OToastManager } from "@maestro/core";
import { ODivider, OLoader } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { DataGridGeneralActions } from "components/data-grid-general-actions";
import { PageTitle } from "components/page-title";
import { Section, SideFilterBar } from "components/side-filter-bar";
import { useGridRef } from "hooks/grid-ref";
import { useRoles } from "hooks/roles";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useMemo } from "react";
import { roles } from "roles/roles";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import {
  ArchiveBankslipModal,
  ArchiveBankslipModalId,
} from "./_compose/archive-bankslip-modal";
import {
  BankslipDetailsDrawer,
  BankslipDetailsDrawerId,
} from "./_compose/bankslip-details-drawer";
import {
  BankslipEventsModal,
  BankslipEventsModalId,
} from "./_compose/bankslip-events-modal";
import {
  CancelAllBankslipsModal,
  CancelAllBankslipsModalId,
} from "./_compose/cancel-all-bankslips-modal";
import {
  CancelBankslipModal,
  CancelBankslipModalId,
} from "./_compose/cancel-bankslip-modal";
import {
  CommunicationHistoryModal,
  CommunicationHistoryModalId,
} from "./_compose/communication-history-modal";
import {
  EditCollectionFloatingModal,
  EditCollectionFloatingModalId,
} from "./_compose/edit-collection-floating-modal";
import { PaginationGrid } from "./_compose/pagination-grid";
import { BankslipProvider, useBankslipContext } from "./bankslip.context";
import { grid } from "./bankslip.grid";
import { BankslipGridColumn } from "./bankslip.type";
import {
  assembleSections,
  isAbleToReprocess,
  MapperBankslipGridColumnCaption,
} from "./bankslip.utils";

const BankslipComponent = () => {
  const {
    collectionsList,
    refreshGrid,
    filters,
    handleSubmitFilter,
    isLoading,
    pageSize,
    setBankslipToArchive,
    setBankslipToCancel,
    setDetailedBankslip,
    setCollectionRef,
    setBankslipToSeeEvents,
    setVisibleColumns,
    visibleColumns,
  } = useBankslipContext();

  const { callService: reprocessCollections, loading: isLoadingReprocess } =
    useServiceCall(service.adminBankinghub.collection.reprocessCollections);

  const { hasRole } = useRoles();
  const gridRef = useGridRef();

  const showCancelAllBankslips = useMemo(
    () => hasRole(roles.banking.customer.cancelAllBankslipsAction.role),
    [hasRole],
  );

  const showReprocessCollections = useMemo(
    () => collectionsList.some(isAbleToReprocess),
    [collectionsList],
  );

  const handleReprocessCollections = useCallback(async () => {
    try {
      await reprocessCollections();
      OToastManager.success(
        "A solicitação para reprocessar as cobranças foi enviada com sucesso.",
      );
    } catch {
      OToastManager.danger("Não foi possível reprocessar as cobranças.");
    }
  }, [reprocessCollections]);

  const archiveCollection = useCallback(
    (collection: BankingHub.Collection) => {
      setBankslipToArchive(collection);
      modalManager.show(ArchiveBankslipModalId);
    },
    [setBankslipToArchive],
  );

  const cancelCollection = useCallback(
    (collection: BankingHub.Collection) => {
      setBankslipToCancel(collection);
      modalManager.show(CancelBankslipModalId);
    },
    [setBankslipToCancel],
  );

  const seeDetails = useCallback(
    (collection: BankingHub.Collection) => {
      setDetailedBankslip(collection);
      modalManager.show(BankslipDetailsDrawerId);
    },
    [setDetailedBankslip],
  );

  const seeEvents = useCallback(
    (collection: BankingHub.Collection) => {
      setBankslipToSeeEvents(collection);
      modalManager.show(BankslipEventsModalId);
    },
    [setBankslipToSeeEvents],
  );

  const seeCommunicationHistory = useCallback(
    (collection: BankingHub.Collection) => {
      setCollectionRef(collection);
      modalManager.show(CommunicationHistoryModalId);
    },
    [setCollectionRef],
  );

  const editCollectionFloating = useCallback(
    (collection: BankingHub.Collection) => {
      setCollectionRef(collection);
      modalManager.show(EditCollectionFloatingModalId);
    },
    [setCollectionRef],
  );

  const memoGrid = useMemo(
    () =>
      grid(
        pageSize,
        archiveCollection,
        cancelCollection,
        seeDetails,
        seeEvents,
        seeCommunicationHistory,
        editCollectionFloating,
        visibleColumns,
      ),
    [
      archiveCollection,
      cancelCollection,
      pageSize,
      seeDetails,
      seeEvents,
      seeCommunicationHistory,
      editCollectionFloating,
      visibleColumns,
    ],
  );

  const sections = useMemo(
    (): Section[] => assembleSections(filters),
    [filters],
  );

  return (
    <div>
      <ArchiveBankslipModal />
      <CancelBankslipModal />
      <BankslipEventsModal />
      <CommunicationHistoryModal />
      <BankslipDetailsDrawer />
      <CancelAllBankslipsModal />
      <EditCollectionFloatingModal />

      {isLoadingReprocess && <OLoader absolute backdrop />}

      <div className="d-flex flex-column gap-3">
        <PageTitle title="Boletos" />

        <GridTemplate
          gridRef={gridRef}
          actions={
            <div className="d-flex gap-3">
              <DataGridGeneralActions
                customActions={[
                  {
                    label: "Cancelar todas as cobranças",
                    onClick: () => modalManager.show(CancelAllBankslipsModalId),
                    icon: { category: "orq", icon: "orq-cancel" },
                    visible: showCancelAllBankslips,
                  },
                  {
                    label: "Reprocessar cobranças",
                    onClick: handleReprocessCollections,
                    icon: { category: "orq", icon: "orq-automatic-transfer" },
                    visible: showReprocessCollections,
                  },
                ]}
                disabled={isLoading || isLoadingReprocess}
                manageColumns={{
                  columns: Object.values(BankslipGridColumn),
                  columnLabels: MapperBankslipGridColumnCaption,
                  gridId: "bankslipGridColumns",
                  setVisibleColumns,
                }}
                refreshAction={{ onClick: refreshGrid }}
              />

              <ODivider position="vertical" />

              <SideFilterBar
                sections={sections}
                submitFilters={handleSubmitFilter}
                defaultValuesSubmitted
              />
            </div>
          }
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={memoGrid}
            dataSource={collectionsList}
            loading={isLoading}
          />
          <PaginationGrid />
        </GridTemplate>
      </div>
    </div>
  );
};

export const Bankslip = () => {
  return (
    <BankslipProvider>
      <BankslipComponent />
    </BankslipProvider>
  );
};
