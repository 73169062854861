import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCol,
  OOptionMultiple,
  ORFieldInput,
  ORFieldSelectMultiple,
  ORow,
  OToastManager,
} from "@maestro/react";
import { isAxiosError } from "axios";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  createERPConnectionsDefaultValues,
  createERPConnectionsFormSchema,
  CreateERPConnectionsFormValues,
} from "./create-erp-connection.schema";

const options = ["SENIOR", "CONTA_AZUL", "OMIE", "FINBITS", "ICERTUS"];

export const CreateERPConnectionsPage = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm<CreateERPConnectionsFormValues>({
    defaultValues: createERPConnectionsDefaultValues,
    resolver: yupResolver(createERPConnectionsFormSchema),
  });
  const { handleSubmit } = form;
  const navigate = useNavigate();

  const createERPConnections = useMemo(
    () =>
      handleSubmit(async ({ connections, taxId }) => {
        setLoading(true);

        try {
          const { data: createdERPConnectionsAmount } =
            await service.hubCreditManager.createERPConnections({
              connections,
              taxId,
            });

          if (createdERPConnectionsAmount === connections.length) {
            OToastManager.success("Conexões ERP criadas com sucesso.");
          } else if (createdERPConnectionsAmount === 0) {
            OToastManager.warning("As conexões ERP especificadas já existem.");
            return;
          } else {
            OToastManager.info(
              "Conexões ERP criadas com sucesso. No entanto, algumas delas já existiam e não foram criadas novamente.",
            );
          }

          navigate(
            corporateRouter.routes.emprestimos.product.erpConnections.path,
          );
        } catch (e) {
          if (
            isAxiosError(e) &&
            e?.response?.data.exceptionMessage === "LegalEntityNotFound"
          ) {
            OToastManager.danger(
              "CNPJ não encontrado / Conta sem acesso à seção de Crédito",
            );
          } else
            OToastManager.danger(
              "Um erro ocorreu ao tentar criar as conexões ERP.",
            );
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, navigate],
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Criar conexões ERP"
          description="Relacionamentos já existentes entre o cliente e um tipo de conexão não serão criados adicionalmente caso sejam solicitados"
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={createERPConnections}>
          Criar conexões
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol>
              <ORFieldInput tag="cnpj" id="taxId" name="taxId" label="CNPJ" />
            </OCol>
            <OCol>
              <ORFieldSelectMultiple
                id="connections"
                name="connections"
                label="Conexões ERP"
              >
                {options.map((erpConnection) => (
                  <OOptionMultiple key={erpConnection} value={erpConnection}>
                    {erpConnection}
                  </OOptionMultiple>
                ))}
              </ORFieldSelectMultiple>
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
