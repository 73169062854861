export const amortizationFrequencyCodeMap = {
  MNTH: "Mensal",
  MTRY: "Bullet",
};

export const spreadDayCountCodeMap = {
  B024: "30",
  B013: "30/360",
  B001: "365",
  B003: "252",
};

export const creditTypeOptions = [
  {
    label: "Crédito Novo",
    value: "NCR",
  },
  {
    label: "Renegociação da Dívida",
    value: "DRN",
  },
  {
    label: "Reestruturação da Dívida",
    value: "DRT",
  },
];

export const ratingTypes = [
  "AAA",
  "AA+",
  "AA",
  "AA-",
  "A+",
  "A",
  "A-",
  "BBB",
  "BB+",
  "BB",
  "BB-",
  "B+",
  "B",
  "B-",
  "CCC",
];

export const portfolioClassificationTypes = ["C1", "C2", "C3", "C4", "C5"];
