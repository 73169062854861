export type TipoProduto =
  | "Cartao"
  | "CCC"
  | "FIDC"
  | "FundingProprio"
  | "Offshore"
  | "RiscoSacado"
  | "RiscoCedente";

export interface EstrategiaForm {
  id?: number;
  tipoProduto: TipoProduto;
  nome: string;
  modeloDeTaxa: string;
  exigeAprovacaoBanco: string;
  prazoLimiteOperacao: number;
  instrumentCodeId: number;
  approvalGroups?: string[];
  aprovacaoAposAntecipacaoSacado?: string;
  biometryRequired?: string;
  cancelaNaoAntecipadasD0?: string;
  cessaoOffshore?: string;
  collateralIdentification?: string;
  collateralRequired?: string;
  comandoOperacao?: string;
  contractTemplateId?: number;
  diasCampanhaNotasNaoAntecipadasCedente?: string[];
  diasParaVencimentoDataRepasseRiscoCedente?: string[];
  diasParaVencimentoSacado?: string[];
  eligibilityType?: string;
  emailsSacadoNotification?: string[];
  exigeAprovacaoSacado?: string;
  exigeIndicacaoHedge?: string;
  fccIdSacado?: number;
  filesForOperation?: string[];
  filesForReceivables?: string[];
  fundConfig?: string;
  fundRelatedName?: string;
  habilitaAntecipacoesParciais?: string;
  habilitaPagFor?: string;
  habilitaPagForBanking?: string;
  hasGuarantors?: string;
  hasPayerValidation?: string;
  hiringContractTemplateId?: number;
  hiringContractType?: string;
  horarioLimiteOperacao?: string;
  idContaDeRetirada?: string;
  interestArrearsBase?: string;
  interestArrearsRate?: number;
  latePaymentRate?: number;
  interestIndexCode?: string;
  isLoanAssignment?: string;
  loanAgreementId?: string;
  linkTaggeado?: string[];
  linkWithBankSlips?: string;
  marketplaceId?: number;
  portfolioId: number;
  creditorId?: number;
  toCredit?: string;
  needsFilesForOperation?: string;
  needsFilesForReceivables?: string;
  needsRegistrationInfo?: string;
  allowAggregateBorderos?: string;
  notaDisponivelCedente?: string;
  notificacaoOnboardingSacado?: string;
  notificacaoRecebiveisAntecipados?: string;
  notificacaoTedDevolvidaCedente?: string;
  notificacaoTedDevolvidaSacado?: string;
  penaltyFee?: number;
  permiteTaxaParticular?: string;
  pnlBook?: number;
  pnlStrategy?: number;
  rebateAlongaPrazo?: string;
  rebateRebarbaSacado?: string;
  rebateTolerance?: number;
  reporteDiarioRiscoCedente?: string;
  reporteDiarioSacado?: string;
  reporteDiarioCCC?: string;
  reporteNotasNaoAntecipadasCedente?: string;
  requiresPMEAccount?: string;
  riscoOperacao?: string;
  sacadoDefineContaDesembolso?: string;
  sefazValidation?: string;
  sendErrorCallback?: string;
  tipoContrato?: string;
  tipoOnboarding?: string;
  tipoTaxaParticular?: string;
  typeFIDC?: string;
  visibilidadePrazoVencimentoCedente?: string;
  visualizaRebateSacado?: string;
  worksiteEnvConfig?: string;
  automaticAnticipation?: string;
  gracePeriod?: number;
}

export const EstrategiaFormLabels: {
  [key in EstrategiaFormLabelsEnum]: string;
} = {
  nome: "Descrição",
  modeloDeTaxa: "Entrada de Taxas",
  exigeAprovacaoBanco: "Aprovação BTG",
  prazoLimiteOperacao: "Prazo limite para operação",
  instrumentCodeId: "Produto Catálogo",
  approvalGroups: "Grupos de aprovação",
  aprovacaoAposAntecipacaoSacado: "Requer aprovação do sacado após antecipação",
  biometryRequired: "Biometria obrigatória",
  cancelaNaoAntecipadasD0: "Cancela não antecipadas em D0",
  cessaoOffshore: "Cessão Offshore",
  collateralIdentification: "Garantia",
  collateralRequired: "Exige garantia",
  comandoOperacao: "Comando da antecipação",
  contractTemplateId: "Template de contrato",
  diasCampanhaNotasNaoAntecipadasCedente:
    "Notificar depois de (dias) nota não antecipad",
  diasParaVencimentoDataRepasseRiscoCedente: "Dias para data repasse",
  diasParaVencimentoSacado: "Dias para vencimento",
  eligibilityType: "Tipo de elegibilidade",
  emailsSacadoNotification: "Emails para receber notificações",
  exigeAprovacaoSacado: "Exige aprovação do sacado",
  exigeIndicacaoHedge: "Exige indicação Hedge",
  fccIdSacado: "FCC",
  filesForOperation: "Arquivos obrigatórios para a solicitação da operação",
  filesForReceivables: "Arquivos obrigatórios para o borderô",
  fundConfig: "Indentificador interno do Fundo",
  fundRelatedName: "Nome do Fundo",
  habilitaAntecipacoesParciais: "Habilitar antecipação parcial dos recebíveis",
  habilitaPagFor: "Habilita PagFor CROS",
  habilitaPagForBanking: "Habilita PagFor Banking",
  hasGuarantors: "Possui avalistas/devedores solidários",
  hasPayerValidation: "Habilitar a validação de sacados?",
  hiringContractTemplateId: "Template de contrato de contratação",
  hiringContractType: "Tipo de contrato de contratação",
  horarioLimiteOperacao: "Limite de horário operação",
  idContaDeRetirada: "Conta de retirada",
  interestArrearsBase: "Base Mora",
  interestArrearsRate: "Mora",
  interestIndexCode: "Índice Juros",
  latePaymentRate: "Taxa juros remuneratórios",
  isLoanAssignment: "Cessão de empréstimos",
  loanAgreementId: "Convênio empréstimo",
  linkTaggeado: "Link Taggeado Cedente",
  linkWithBankSlips: "Vincular boletos à cessão",
  marketplaceId: "Marketplace",
  portfolioId: "Carteira",
  creditorId: "Financiador",
  toCredit: "Desembolso com crédito",
  needsFilesForOperation:
    "Habilita a obrigação de upload de arquivos na solicitação da operação",
  needsFilesForReceivables:
    "Habilita a obrigação de upload de arquivos no envio do borderô",
  needsRegistrationInfo:
    "Habilita a obrigação de informar nº de registro e registradora",
  allowAggregateBorderos: "Agrupamento de borderos",
  notaDisponivelCedente: "Nota disponível",
  notificacaoOnboardingSacado: "Notificações de onboarding",
  notificacaoRecebiveisAntecipados: "Notificações de recebíveis antecipados",
  notificacaoTedDevolvidaCedente: "Notificações de TED devolvida Cedente",
  notificacaoTedDevolvidaSacado: "Notificações de TED devolvida Sacado",
  penaltyFee: "Multa",
  permiteTaxaParticular: "Permitir definição de taxa Sacado/Cedente",
  pnlBook: "Book PNL",
  pnlStrategy: "Estratégia PNL",
  rebateAlongaPrazo: "Rebate alonga prazo",
  rebateRebarbaSacado: "Rebarba do rebate volta para o sacado",
  rebateTolerance: "Tolerância de rebate",
  reporteDiarioRiscoCedente: "Notificações de reporte diário",
  reporteDiarioSacado: "Notificações de reporte diário",
  reporteDiarioCCC: "Notificações diárias de oferta",
  reporteNotasNaoAntecipadasCedente: "Report de notas não antecipadas",
  requiresPMEAccount: "Requer abertura de conta",
  riscoOperacao: "Risco operação",
  sacadoDefineContaDesembolso: "Sacado define conta de desembolso",
  sefazValidation: "Habilita validação SEFAZ",
  sendErrorCallback: "Envia callback de rejeição",
  tipoContrato: "Tipo de contrato",
  tipoOnboarding: "Tipo de onboarding",
  tipoTaxaParticular: "Configuração taxa Sacado/Cedente",
  typeFIDC: "Tipo FIDC",
  visibilidadePrazoVencimentoCedente: "Prazo que o cedente visualiza",
  visualizaRebateSacado: "Sacado visualiza rebate",
  worksiteEnvConfig: "Pasta do Worksite para contratos",
  automaticAnticipation: "Antecipa automaticamente",
  gracePeriod: "Prazo de cura",
};

export enum EstrategiaFormLabelsEnum {
  nome = "nome",
  modeloDeTaxa = "modeloDeTaxa",
  exigeAprovacaoBanco = "exigeAprovacaoBanco",
  prazoLimiteOperacao = "prazoLimiteOperacao",
  instrumentCodeId = "instrumentCodeId",
  approvalGroups = "approvalGroups",
  aprovacaoAposAntecipacaoSacado = "aprovacaoAposAntecipacaoSacado",
  biometryRequired = "biometryRequired",
  cancelaNaoAntecipadasD0 = "cancelaNaoAntecipadasD0",
  cessaoOffshore = "cessaoOffshore",
  collateralIdentification = "collateralIdentification",
  collateralRequired = "collateralRequired",
  comandoOperacao = "comandoOperacao",
  contractTemplateId = "contractTemplateId",
  diasCampanhaNotasNaoAntecipadasCedente = "diasCampanhaNotasNaoAntecipadasCedente",
  diasParaVencimentoDataRepasseRiscoCedente = "diasParaVencimentoDataRepasseRiscoCedente",
  diasParaVencimentoSacado = "diasParaVencimentoSacado",
  eligibilityType = "eligibilityType",
  emailsSacadoNotification = "emailsSacadoNotification",
  exigeAprovacaoSacado = "exigeAprovacaoSacado",
  exigeIndicacaoHedge = "exigeIndicacaoHedge",
  fccIdSacado = "fccIdSacado",
  filesForOperation = "filesForOperation",
  filesForReceivables = "filesForReceivables",
  fundConfig = "fundConfig",
  fundRelatedName = "fundRelatedName",
  habilitaAntecipacoesParciais = "habilitaAntecipacoesParciais",
  habilitaPagFor = "habilitaPagFor",
  habilitaPagForBanking = "habilitaPagForBanking",
  hasGuarantors = "hasGuarantors",
  hasPayerValidation = "hasPayerValidation",
  hiringContractTemplateId = "hiringContractTemplateId",
  hiringContractType = "hiringContractType",
  horarioLimiteOperacao = "horarioLimiteOperacao",
  idContaDeRetirada = "idContaDeRetirada",
  interestArrearsBase = "interestArrearsBase",
  interestArrearsRate = "interestArrearsRate",
  interestIndexCode = "interestIndexCode",
  latePaymentRate = "latePaymentRate",
  isLoanAssignment = "isLoanAssignment",
  loanAgreementId = "loanAgreementId",
  linkTaggeado = "linkTaggeado",
  linkWithBankSlips = "linkWithBankSlips",
  marketplaceId = "marketplaceId",
  portfolioId = "portfolioId",
  creditorId = "creditorId",
  toCredit = "toCredit",
  needsFilesForOperation = "needsFilesForOperation",
  needsFilesForReceivables = "needsFilesForReceivables",
  needsRegistrationInfo = "needsRegistrationInfo",
  allowAggregateBorderos = "allowAggregateBorderos",
  notaDisponivelCedente = "notaDisponivelCedente",
  notificacaoOnboardingSacado = "notificacaoOnboardingSacado",
  notificacaoRecebiveisAntecipados = "notificacaoRecebiveisAntecipados",
  notificacaoTedDevolvidaCedente = "notificacaoTedDevolvidaCedente",
  notificacaoTedDevolvidaSacado = "notificacaoTedDevolvidaSacado",
  penaltyFee = "penaltyFee",
  permiteTaxaParticular = "permiteTaxaParticular",
  pnlBook = "pnlBook",
  pnlStrategy = "pnlStrategy",
  rebateAlongaPrazo = "rebateAlongaPrazo",
  rebateRebarbaSacado = "rebateRebarbaSacado",
  rebateTolerance = "rebateTolerance",
  reporteDiarioRiscoCedente = "reporteDiarioRiscoCedente",
  reporteDiarioSacado = "reporteDiarioSacado",
  reporteDiarioCCC = "reporteDiarioCCC",
  reporteNotasNaoAntecipadasCedente = "reporteNotasNaoAntecipadasCedente",
  requiresPMEAccount = "requiresPMEAccount",
  riscoOperacao = "riscoOperacao",
  sacadoDefineContaDesembolso = "sacadoDefineContaDesembolso",
  sefazValidation = "sefazValidation",
  sendErrorCallback = "sendErrorCallback",
  tipoContrato = "tipoContrato",
  tipoOnboarding = "tipoOnboarding",
  tipoTaxaParticular = "tipoTaxaParticular",
  typeFIDC = "typeFIDC",
  visibilidadePrazoVencimentoCedente = "visibilidadePrazoVencimentoCedente",
  visualizaRebateSacado = "visualizaRebateSacado",
  worksiteEnvConfig = "worksiteEnvConfig",
  automaticAnticipation = "automaticAnticipation",
  gracePeriod = "gracePeriod",
}
