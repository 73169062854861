import { ODivider, OIcon, OTypography } from "@maestro/react";
import { IconButtonContainer } from "./draggable-item.styles";

interface DraggableItemProps {
  label: string;
  handleClick: () => void;
  icon: HTMLOIconElement["icon"];
  showDivider: boolean;
}

export const DraggableItem = ({
  handleClick,
  label,
  icon,
  showDivider,
}: DraggableItemProps) => {
  return (
    <>
      <div className="d-flex justify-content-between w-100">
        <div className="d-flex gap-2 align-items-center py-2">
          <OIcon icon="orq-drag-drop-vertical" size="lg" category="orq" />
          <OTypography>{label}</OTypography>
        </div>

        <IconButtonContainer onClick={handleClick} bordered={false} outline>
          <OIcon icon={icon} category="orq" type="dark" size="lg" />
        </IconButtonContainer>
      </div>

      {showDivider && <ODivider size="xxs" />}
    </>
  );
};
