import { OToastManager, modalManager } from "@maestro/react";
import { useCallback, useState } from "react";
import { service } from "services";
import { EditCollectionFloatingParams } from "services/admin-bankinghub/collection/models";
import { useBankslipContext } from "../../bankslip.context";
import {
  EditCollectionFloatingModalFormFields,
  EditCollectionFloatingModalId,
} from "./edit-collection-floating-modal.types";

export const useEditCollectionFloating = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { collectionRef, refreshGrid } = useBankslipContext();

  const editCollectionFloating = useCallback(
    async ({ quantityInDays }: EditCollectionFloatingModalFormFields) => {
      if (!collectionRef) return;
      try {
        setIsLoading(true);
        const params: EditCollectionFloatingParams = {
          collections: [collectionRef.collectionId],
          quantityInDays,
        };
        await service.adminBankinghub.collection.editCollectionFloating(params);
        OToastManager.success("Floating atualizado com sucesso");
        modalManager.hide(EditCollectionFloatingModalId);
        refreshGrid();
      } catch {
        OToastManager.danger(
          "Ocorreu um erro para atualizar o Floating do beneficiário.",
        );
      } finally {
        setIsLoading(false);
      }
    },
    [collectionRef, refreshGrid],
  );

  return { editCollectionFloating, isLoading };
};
