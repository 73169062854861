import { modalManager } from "@maestro/react";
import { CustomPagination } from "components/custom-pagination";
import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useEffect, useMemo, useState } from "react";
import { PostAdminLimitsGetLimitResponse } from "services/hubcreditmanager/models/responses";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { WalletInvoicesFilter } from "./_compose/wallet-invoices-filter";
import { walletInvoicesFilterModalId } from "./_compose/wallet-invoices-filter/wallet-invoices.utils";
import { grid } from "./wallet-details.grid";
import { useWalletDetails } from "./wallet-details.hook";
import { MenuWallet } from "./wallet-menu.component";

export const WalletDetailsComponent = (documentNumber: string) => {
  const gridRef = useGridRef();
  const {
    dataSource,
    page,
    setPage,
    rows,
    setRows,
    form,
    onSubmit,
    resetFilter,
    setSortQuery,
    exportData,
    getLimit,
    totals,
  } = useWalletDetails(documentNumber);
  const [limit, setLimit] = useState<PostAdminLimitsGetLimitResponse>();
  useEffect(() => {
    const fetchWalletLimit = async () => {
      const response = await getLimit();
      setLimit(response);
    };

    fetchWalletLimit();
  }, [getLimit]);

  const memoizedGrid = useMemo(
    () => (
      <ODataGridGenerator
        grid={grid(setSortQuery, rows)}
        gridRef={gridRef}
        dataSource={dataSource}
      />
    ),
    [gridRef, dataSource, setSortQuery, rows],
  );

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title="Detalhes da Carteira"
          description="Visualize os boletos nesta carteira e suas informações principais"
        />
      }
    >
      <MenuWallet
        amount={limit?.amount ?? 0}
        availableAmount={limit?.availableAmount ?? 0}
        totals={totals}
      />
      <GridTemplate
        gridRef={gridRef}
        actions={
          <>
            <IconButton
              icon={{ category: "orq", icon: "orq-tools-search" }}
              onClick={() => modalManager.show(walletInvoicesFilterModalId)}
            >
              Abrir busca
            </IconButton>
            <IconButton
              icon={{ category: "far", icon: "fa-file-spreadsheet" }}
              style={{ width: "16px", marginRight: "30px" }}
              onClick={() => exportData()}
              type="default"
              outline
            />
          </>
        }
        showRefreshButton
      >
        {memoizedGrid}
        <CustomPagination
          totalCount={totals?.totalItems ?? 0}
          currentPage={page}
          setCurrentPage={setPage}
          pagination={[10, 20, 30, 50, 100]}
          selectedPaginationSize={rows}
          setSelectedPaginationSize={setRows}
        />
      </GridTemplate>
      <WalletInvoicesFilter
        form={form}
        onSubmit={onSubmit}
        resetFilter={resetFilter}
      />
    </DetailsTemplate>
  );
};
