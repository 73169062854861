import { OIcon } from "@maestro/react";
import styled, { css } from "styled-components";

export const IconStyled = styled(OIcon)`
  ${({ icon }) =>
    css`
      .${icon} {
        background: none;
      }
    `};
`;
