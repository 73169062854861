import { CustomPagination } from "components/custom-pagination";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { estimulosGrid } from "./cobrancas-estimulos.grid";
import { useCobrancasEstimulos } from "./cobrancas-estimulos.hook";

export const CobrancasEstimulosPage = () => {
  const gridRef = useGridRef();
  const { dataSource, totalItems, page, setPage, rows, setRows } =
    useCobrancasEstimulos();

  const memoizedGrid = useMemo(
    () => (
      <ODataGridGenerator
        gridRef={gridRef}
        grid={estimulosGrid}
        dataSource={dataSource}
      />
    ),
    [gridRef, dataSource],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Lista de estímulos" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      {memoizedGrid}
      <CustomPagination
        totalCount={totalItems}
        currentPage={page}
        setCurrentPage={setPage}
        pagination={[10, 20, 30]}
        selectedPaginationSize={rows}
        setSelectedPaginationSize={setRows}
      />
    </GridTemplate>
  );
};
