import { yupResolver } from "@hookform/resolvers/yup";
import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInput,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useBankslipContext } from "../../bankslip.context";
import { editCollectionFloatingModalValidationSchema } from "./edit-collection-floating-modal.form.schema";
import { useEditCollectionFloating } from "./edit-collection-floating-modal.hook";
import {
  EditCollectionFloatingModalFormFields,
  EditCollectionFloatingModalId,
} from "./edit-collection-floating-modal.types";

export { EditCollectionFloatingModalId } from "./edit-collection-floating-modal.types";

export const EditCollectionFloatingModal = () => {
  const { editCollectionFloating, isLoading } = useEditCollectionFloating();
  const { collectionRef } = useBankslipContext();

  const form = useForm<EditCollectionFloatingModalFormFields>({
    resolver: yupResolver(editCollectionFloatingModalValidationSchema),
  });

  const { handleSubmit, reset } = form;

  useEffect(() => {
    if (collectionRef) {
      const quantityInDays = collectionRef.detail.floatingInDays ?? 0;
      reset({ quantityInDays });
    }
  }, [collectionRef, reset]);

  return (
    <OModal
      id={EditCollectionFloatingModalId}
      backdrop={!isLoading}
      position="center"
      size="sm"
    >
      <OModalHeader closeButton={!isLoading}>
        <OTypography type="dark" size="lg" className="text-nowrap">
          Editar Floating da cobrança
        </OTypography>
      </OModalHeader>

      <OModalBody>
        <FormProvider {...form}>
          <ORFieldInput
            label="Número de dias"
            description="Defina o prazo de floating desta cobrança"
            id="quantityInDays"
            name="quantityInDays"
            tag="number"
          />
        </FormProvider>
      </OModalBody>

      <OModalFooter divider>
        <div className="d-flex align-items-center justify-content-end gap-3">
          <OButton
            onClick={() => modalManager.hide(EditCollectionFloatingModalId)}
            disabled={isLoading}
            outline
          >
            Voltar
          </OButton>

          <LoadingButton
            onClick={handleSubmit(editCollectionFloating)}
            loading={isLoading}
          >
            Editar floating
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
