import { ActionButtonContent } from "../action-button-content";
import { OTextButton } from "../o-text-button";
import { AlternativeActionButton } from "./alternative-button.types";

export const AlternativeButton = ({
  disabled,
  onClick,
  text,
  textSize,
  textDecoration = "none",
  icon,
  contentType = "dark",
  buttonSize,
}: AlternativeActionButton) => {
  return (
    <div className="d-flex">
      <OTextButton
        disabled={disabled}
        onClick={onClick}
        bordered={false}
        padding="0"
        type={contentType}
        textDecoration={textDecoration}
        size={buttonSize}
        outline
      >
        <ActionButtonContent
          text={text}
          textSize={textSize}
          textType={contentType}
          icon={icon}
          gapSize={2}
        />
      </OTextButton>
    </div>
  );
};
