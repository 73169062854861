import { ORow } from "@maestro/react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { generateUuidV4 } from "../../../../workflow-configuration-scf.utils";
import { BaseStepFields } from "./base-step-fields.component";

interface TomadorCreditoComplementValidateStepProps {
  fieldName: "configurations" | "syncConfigurations";
  index: number;
}

export const TomadorCreditoComplementValidateStep = ({
  fieldName,
  index,
}: TomadorCreditoComplementValidateStepProps) => {
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    setValue(
      `${fieldName}.${index}._t`,
      "BCheckComplementorTomadorDeCreditoWorkflowConfigurationStep",
    );
    if (!getValues(`${fieldName}.${index}.stepId`)) {
      setValue(
        `${fieldName}.${index}.stepId`,
        `complement-${generateUuidV4()}`,
      );
    }
    if (!getValues(`${fieldName}.${index}.nextStepId`)) {
      setValue(`${fieldName}.${index}.nextStepId`, null);
    }
  }, [fieldName, getValues, index, setValue]);

  return (
    <ORow className="w-100">
      <BaseStepFields
        fieldName={fieldName}
        index={index}
        stepName="TOMADOR_CREDITO_COMPLEMENT_VALIDATE"
      />
    </ORow>
  );
};
