import { OBadge } from "@maestro/react";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { endpoints } from "services/endpoints";

export const publishInvoicesRequestsGrid = {
  datagrid: {
    noDataText: "Nenhuma solicitação encontrada encontrado.",
    headerFilter: { visible: true, allowSearch: true },
    onDataErrorOccurred: (e) => {
      if (e.error) e.error.message = "Erro ao buscar solicitações.";
    },
    filterRow: {
      visible: true,
    },
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubCreditManager.odata.publishInvoicesRequestsAdmin,
    ),
  },
  columns: [
    {
      dataField: "Id",
      dataType: "number",
      caption: "Id",
      sortOrder: "desc",
    },
    {
      caption: "Número do contrato",
      dataField: "ContractNumber",
      dataType: "string",
    },
    {
      caption: "Status",
      dataField: "Status",
      dataType: "string",
      cellRender: ({ data }) => (
        <div className="d-flex justify-content-center align-items-center">
          <OBadge type={data.Status === "Success" ? "success" : "danger"}>
            {data.Status === "Success" ? "Sucesso" : "Erro"}
          </OBadge>
        </div>
      ),
      allowFiltering: false,
    },
    {
      caption: "Solicitado em",
      dataField: "CreatedDate",
      dataType: "datetime",
    },
    {
      caption: "Solicitado por",
      dataField: "RequestedBy",
      dataType: "string",
    },
    {
      caption: "Correlation Id",
      dataField: "CorrelationId",
      dataType: "string",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<
        typeof service.hubCreditManager.odata.getPublishInvoicesRequests
      >
    >["data"]["value"]
  >[number]
>;
