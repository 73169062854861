import { masks } from "@maestro/utils";
import { IndexCodeType } from "pages/emprestimos/pages/cliente/ofertas/offers.type";

const indexerLabel: Record<string, string> = {
  CDIE: "CDI",
  NIPCA: "IPCA",
};

export const rateIndexerMask = (
  value: number | undefined,
  rateType: IndexCodeType | undefined,
) => {
  let rateLabel = masks.percentage(value);

  if (!!rateType && rateType !== "PRE")
    rateLabel = `${indexerLabel[rateType] || rateType} + ` + rateLabel;

  return rateLabel;
};
