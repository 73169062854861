import { OToastManager } from "@maestro/core";
import { useCallback, useState } from "react";
import { service } from "services";
import { useCollectionBeneficiaryContext } from "../../../../collection-beneficiary.context";
import { assembleCreateBeneficiaryRequest } from "./beneficiary-header.utils";

export const useBeneficiaryData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { beneficiary } = useCollectionBeneficiaryContext();

  const createBeneficiary = useCallback(async () => {
    if (!beneficiary) return;
    try {
      setIsLoading(true);
      const params = assembleCreateBeneficiaryRequest(beneficiary);
      await service.adminBankinghub.collection.createBeneficiary(params);
      OToastManager.success("Solicitação de cadastro enviada com sucesso.");
    } catch {
      OToastManager.danger("Ocorreu um erro ao cadastrar o beneficiário.");
    } finally {
      setIsLoading(false);
    }
  }, [beneficiary]);

  return { createBeneficiary, isLoading };
};
