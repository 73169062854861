import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { limitsGrid } from "./limits.grid";

export const LimitsPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Limites Vigentes" />}
      actions={
        <RouterButton
          type="tertiary"
          href={
            corporateRouter.routes.emprestimos.product.limitManager.limits.add
              .path
          }
          role={roles.emprestimos.product.limitManagerLimitsAddPage.role}
          outline
        >
          Adicionar limite
        </RouterButton>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={limitsGrid} />
    </GridTemplate>
  );
};
