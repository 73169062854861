import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { MonitoringTemplate } from "../../../../../components/monitoramento";
import { loaMonitoringGrid } from "./loa-monitoring.grid";

export const LoaMonitoring = () => {
  const gridRef = useGridRef();
  const { customer } = useCustomer();

  return (
    <>
      <PageTitle title="Monitoramento Procuração física" />
      <MonitoringTemplate
        filterFields={[
          {
            label: "Uuid",
            property: "Uuid",
          },
        ]}
        initialFilter={{
          CompanyIdentification: customer.identification,
        }}
        relationship="LETTER_OF_ATTORNEY"
        gridBuilder={loaMonitoringGrid}
        gridRef={gridRef}
      />
    </>
  );
};
