import { ODivider, ODropdownItem, ODropdownList } from "@maestro/react";
import { Fragment, useMemo } from "react";
import { ActionButtonContent } from "../../../action-button-content";
import { GridActionsProps } from "./grid-actions.types";
import { assembleDropdownActions } from "./grid-actions.utils";

export const GridActions = (gridActionsProps: GridActionsProps) => {
  const dropdownActions = useMemo(
    () => assembleDropdownActions(gridActionsProps),
    [gridActionsProps],
  );

  return (
    <ODropdownList bordered={false}>
      {dropdownActions.map((dropdownAction, index, { length }) => (
        <Fragment key={dropdownAction.text}>
          <ODropdownItem
            handleInteraction={dropdownAction.onClick}
            className="d-flex"
          >
            <ActionButtonContent {...dropdownAction} reverseRow />
          </ODropdownItem>

          {index < length - 1 && <ODivider size="xxs" />}
        </Fragment>
      ))}
    </ODropdownList>
  );
};
