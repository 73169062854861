export const loadTitles = (
  availableColumnsLength: number,
  selectedColumnsLength: number,
  totalColumns: number,
) => {
  const selectedTitle = `Colunas incluídas (${selectedColumnsLength}/${totalColumns})`;

  const availableTitle = `Colunas retiradas (${availableColumnsLength}/${totalColumns})`;

  return { selectedTitle, availableTitle };
};
