import * as yup from "yup";

export type DeskConfigFormValues =
  HubOnboarding.Onboarding.DeskOnboardingConfig;

export const deskConfigFormValidationSchema: yup.ObjectSchema<DeskConfigFormValues> =
  yup.object({
    relationship: yup.string().required("Este campo é obrigatório"),
    integration: yup.string().required("Este campo é obrigatório"),
    segmentGroupId: yup.number().required("Este campo é obrigatório"),
    configIdentifier: yup.string().required("Este campo é obrigatório"),
    configName: yup.string().required("Este campo é obrigatório"),
    configDescription: yup.string().required("Este campo é obrigatório"),
    relationshipsToSync: yup
      .mixed((input): input is string[] => Array.isArray(input))
      .transform((value) => {
        if (Array.isArray(value)) return value;

        return value?.split(",");
      })
      .required("Este campo é obrigatório"),
    statusToSync: yup
      .array()
      .transform((value) => value || [])
      .nullable()
      .optional(),
    whereShouldSignFirst: yup.string().required("Este campo é obrigatório"),
    initialStages: yup
      .mixed((input): input is unknown[] => Array.isArray(input))
      .transform((value) => {
        try {
          return JSON.parse(value);
        } catch {
          return {};
        }
      })
      .typeError("Formato inválido")
      .required("Este campo é obrigatório"),
    profilesAvailable: yup
      .mixed((input): input is unknown[] => Array.isArray(input))
      .transform((value) => {
        try {
          return JSON.parse(value);
        } catch {
          return {};
        }
      })
      .typeError("Formato inválido")
      .required("Este campo é obrigatório"),
    legalRepresentativeWithPowerShouldBeAnalyzed: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .optional()
      .nullable(),
    shouldNotCreateUsersOnBtgEmpresas: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .optional()
      .nullable(),
    shouldVerifyOriginator: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .optional()
      .nullable(),
    shouldIgnoreEmailForExistingBtgEmpresasUsers: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    originatorTagCode: yup
      .string()
      .transform((value) => value || null)
      .optional()
      .nullable(),
    serviceToSync: yup
      .string()
      .transform((value) => value || null)
      .optional()
      .nullable(),
    isAvailableOnApp: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .optional()
      .nullable(),
    channelLess: yup
      .string()
      .transform((value) => value || null)
      .optional()
      .nullable(),
    businessType: yup
      .string()
      .transform((value) => value || null)
      .optional()
      .nullable(),
    businessSubType: yup
      .string()
      .transform((value) => value || null)
      .optional()
      .nullable(),
    shouldNotCreateOrUpdateActivations: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .optional()
      .nullable(),
    shouldSendWelcomeEmail: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .optional()
      .nullable(),
    shouldNotifyOnlyOnHOB: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .optional()
      .nullable(),
    shouldSyncOfficerAsAdvisor: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    shouldNotifyUpdatesOnTeams: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .optional()
      .nullable(),
    teamsNotificationGroup: yup
      .string()
      .transform((value) => value || null)
      .optional()
      .nullable(),
    containCustomProfiles: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .optional()
      .nullable(),
    allGenericOccurrenceAllowed: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .optional()
      .nullable(),
    onboardingAnalysisSLA: yup
      .number()
      .transform((value) => value || undefined)
      .optional()
      .nullable(),
    contactEmail: yup
      .string()
      .transform((value) => value || null)
      .optional()
      .nullable(),
    shouldSyncDeskAutomaticaly: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .optional()
      .nullable(),
    mustBeAuthenticatedToSign: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .optional()
      .nullable(),
    shouldNotifyByEvent: yup
      .mixed((input): input is boolean => typeof input === "boolean")
      .transform(
        (value) => ({ true: true, false: false }[value as string] ?? null),
      )
      .required("Este campo é obrigatório"),
    flowType: yup
      .string()
      .transform((value) => value || null)
      .optional()
      .nullable(),
  });
