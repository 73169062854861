import { yupResolver } from "@hookform/resolvers/yup";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import { getSearchParams } from "utils/search-params/get-search-params";
import { scfDevelopersOnboardingStatusMap } from "../../../../../utils/scf-configuration-maps";
import {
  DomainScfFormFields,
  DomainScfValidationSchema,
} from "../domain-configuration-scf.form";
import { buildConfigurationsIntoForm } from "./edit-partner-configuration-scf.utils";

interface EditPartnerConfigurationScfSearchParams {
  authClientId: string;
  configurationId: string;
}

export const useEditPartnerConfigurationScf = () => {
  const {
    value: partnerConfiguration,
    callService: getPartnerConfiguration,
    loading,
  } = useServiceCall(service.scfDevelopers.getConfiguration);

  const form = useForm<DomainScfFormFields>({
    resolver: yupResolver(DomainScfValidationSchema),
  });
  const { reset } = form;

  const { authClientId, configurationId } =
    getSearchParams<EditPartnerConfigurationScfSearchParams>();

  if (!authClientId || !configurationId) {
    throw new Error("No url parameters");
  }

  useEffect(() => {
    getPartnerConfiguration(authClientId, configurationId);
  }, [getPartnerConfiguration, authClientId, configurationId]);

  useEffect(() => {
    if (partnerConfiguration) {
      const configurations = buildConfigurationsIntoForm(
        partnerConfiguration.configurations,
      );
      const syncConfigurations = buildConfigurationsIntoForm(
        partnerConfiguration.syncConfigurations,
      );

      reset({
        allowedReprocessStatuses:
          partnerConfiguration.allowedReprocessStatuses.length === 0
            ? Object.keys(scfDevelopersOnboardingStatusMap)
            : partnerConfiguration.allowedReprocessStatuses,
        authClientId: partnerConfiguration.authClientId,
        configurationId: partnerConfiguration.configurationId,
        configurationName: partnerConfiguration.configurationName,
        configurationDisplayName: partnerConfiguration.configurationDisplayName,
        configurations,
        desks: partnerConfiguration.desks ?? [],
        integrationType: "SACADO_SIMPLES",
        mainDesk: partnerConfiguration.mainDesk,
        partnerId: parseInt(partnerConfiguration.partnerId ?? "0"),
        partnerName: partnerConfiguration.partnerName,
        isFidc: partnerConfiguration.isFidc ? ["true"] : [],
        requiredOnboardingDocuments:
          partnerConfiguration.requiredOnboardingDocuments,
        syncConfigurations,
        requestRequiredFields: partnerConfiguration.requestRequiredFields ?? [],
      });
    }
  }, [partnerConfiguration, reset]);

  return {
    form,
    loading,
  };
};
