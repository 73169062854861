import { ODivider, OTypography } from "@maestro/react";
import { OfferItemProps } from "./offer-item.types";

export const OfferItem = ({ label, value }: OfferItemProps) => {
  return (
    <div>
      <div className="w-100 d-flex justify-content-between py-2">
        <OTypography type="dark-80">{label}</OTypography>
        <OTypography>{value}</OTypography>
      </div>
      <ODivider position="horizontal" />
    </div>
  );
};
