import {
  OButton,
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  OIcon,
  OLabel,
  ORow,
} from "@maestro/react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { editeCedenteCompletoDefaultValues } from "../../edit-cedente-completo.form";
import { AddressFields } from "./_compose";
import { StyledBadge, StyledRow } from "../../edit-cedente-completo.styles";

interface AddressesListProps {
  fieldName: "addresses" | `representatives.${number}.addresses`;
}

export const AddressesList = ({ fieldName }: AddressesListProps) => {
  const form = useFormContext<ScfDevelopers.UpdateCedenteCompleto>();
  const { control } = form;

  const { append, fields, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  return (
    <OCard>
      <OCardHeader>
        <div className="d-flex flex-row align-items-center gap-3">
          <OLabel htmlFor={fieldName}>
            {fields.length > 1 ? "Endereços" : "Endereço"}
          </OLabel>
          <OButton
            type="tertiary"
            onClick={() =>
              append(editeCedenteCompletoDefaultValues.addresses[0])
            }
          >
            <OIcon category="fa" icon="fa-plus" />
          </OButton>
        </div>
      </OCardHeader>
      <OCardBody>
        <ORow className="mx-1">
          {fields.map((field, index) => (
            <StyledRow key={field.id}>
              <OCol xs={1} className="h-100">
                <StyledBadge>{index + 1}</StyledBadge>
              </OCol>
              <OCol xs={10} align="center">
                <AddressFields
                  addressKey={field.id}
                  fieldName={fieldName}
                  index={index}
                />
              </OCol>
              <OCol xs={1} align="center">
                <OButton onClick={() => remove(index)}>
                  <OIcon category="fa" icon="fa-trash" />
                </OButton>
              </OCol>
            </StyledRow>
          ))}
        </ORow>
      </OCardBody>
    </OCard>
  );
};
