import { Roles } from "roles/roles.types";

export const parceirosRoles = {
  customer: {
    partnersTab: {
      role: "admin:HubOnboardingPartnersTabCustomer.View",
      description: "Visualização da tab 'Parceiros' na visão cliente",
    },
    operatorsPage: {
      role: "admin:HubOnboardingPartnersOperatorsPageCustomer.View",
      description: "Visualização da página 'Operadores' na visão cliente",
    },
    economicGroupPage: {
      role: "admin:HubOnboardingPartnersEconomicGroupPageCustomer.View",
      description: "Visualização da página 'Grupo Econômico' na visão cliente",
    },
    operatorDetailsPage: {
      role: "admin:HubOnboardingPartnersOperatorDetailsPageCustomer.View",
      description:
        "Visualização da página 'Operadores - Detalhes' na visão cliente",
    },
    operatorViewingPermissionsPage: {
      role: "admin:HubOnboardingPartnersOperatorViewingPermissionsPageCustomer.View",
      description:
        "Visualização da página 'Operadores - Permissões de visualização' na visão cliente",
    },
    operatorUpdatePage: {
      role: "admin:HubOnboardingPartnersOperatorUpdatePageCustomer.View",
      description:
        "Visualização da página 'Operadores - Atualizar' na visão cliente",
    },
    originatorPage: {
      role: "admin:HubOnboardingPartnersOriginatorPageCustomer.View",
      description: "Visualização da página 'Originador' na visão cliente",
    },
    originatorEditInfo: {
      role: "admin:HubOnboardingPartnersOriginatorEditInfoCustomer.View",
      description:
        "Visualização da página 'Originador - Editar detalhes' na visão cliente",
    },
    originatorEditPermissions: {
      role: "admin:HubOnboardingPartnersOriginatorEditPermissionsCustomer.View",
      description:
        "Visualização da página 'Originador - Editar Permissões' na visão cliente",
    },
    originatorEditIndication: {
      role: "admin:HubOnboardingPartnersOriginatorEditIndicationCustomer.View",
      description:
        "Visualização da página 'Originador - Editar Indicação' na visão cliente",
    },
    originatorManageCreditLimit: {
      role: "admin:HubOnboardingPartnersOriginatorManageCreditLimitCustomer.View",
      description:
        "Visualização da página 'Originador - Limite de Crédito' na visão cliente",
    },
    customersRelationship: {
      role: "admin:HubOnboardingPartnersCustomersRelationshipPageCustomer.View",
      description:
        "Visualização da página 'Vínculos de Clientes' na visão cliente",
    },
    accountsRelationship: {
      role: "admin:HubOnboardingPartnersAccountsRelationshipPageCustomer.View",
      description:
        "Visualização da página 'Vínculos de Contas' na visão cliente",
    },
    referralsRelationship: {
      role: "admin:HubOnboardingPartnersReferralsRelationshipPageCustomer.View",
      description:
        "Visualização da página 'Vínculos de Indicações' na visão cliente",
    },
  },
  product: {
    partnersTab: {
      role: "admin:HubOnboardingPartnersTabProduct.View",
      description: "Visualização da tab 'Parceiros' na visão produto",
    },
    economicGroupsPage: {
      role: "admin:HubOnboardingPartnersEconomicGroups.View",
      description:
        "Visualização da página 'Grupos Econômicos' na visão produto",
    },
    economicGroupsFormPage: {
      role: "admin:HubOnboardingPartnersEconomicGroupsForm.View",
      description:
        "Visualização da página 'Grupos Econômicos' na visão produto",
    },
    customersRelationship: {
      role: "admin:HubOnboardingPartnersCustomersRelationshipPageProduct.View",
      description:
        "Visualização da página 'Vínculos de Clientes' na visão produto",
    },
    accountsRelationship: {
      role: "admin:HubOnboardingPartnersAccountsRelationshipPageProduct.View",
      description:
        "Visualização da página 'Vínculos de Contas' na visão produto",
    },
    referralsRelationship: {
      role: "admin:HubOnboardingPartnersReferralsRelationshipPageProduct.View",
      description:
        "Visualização da página 'Vínculos de Indicações' na visão produto",
    },
    originatorsPage: {
      role: "admin:HubOnboardingPartnersOriginatorsPageProduct.View",
      description:
        "Visualização da página 'Parceiros - Originadores' na visão produto",
    },
    originatorsAdd: {
      role: "admin:HubOnboardingPartnersOriginatorsAddPageProduct.View",
      description:
        "Visualização da página 'Pareiros - Originadores - Cadastro' na visão produto",
    },
    addOperator: {
      role: "admin:HubOnboardingPartnersAddOperatorPageProduct.View",
      description:
        "Visualização da página 'Operadores - Novo Operador' na visão produto",
    },
    operatorDetails: {
      role: "admin:HubOnboardingPartnersOperatorDetailsPageProduct.View",
      description:
        "Visualização da página 'Operadores - Detalhes' na visão produto",
    },
    operatorRelatedEntities: {
      role: "admin:HubOnboardingPartnersOperatorRelatedEntitiesPageProduct.View",
      description:
        "Visualização da página 'Operadores - Entidades Relacionadas' na visão produto",
    },
    operatorByIdentificationViewingPermissions: {
      role: "admin:HubOnboardingPartnersOperatorByIdentificationViewingPermissionsPageProduct.View",
      description:
        "Visualização da página 'Operadores - Por Identification - Permissões de Visualização' na visão produto",
    },
    operatorByIdentificationUpdateRelationship: {
      role: "admin:HubOnboardingPartnersOperatorByIdentificationUpdateRelationshipPageProduct.View",
      description:
        "Visualização da página 'Operadores - Por Identification - Atualizar relacionamento' na visão produto",
    },
    operatorViewingPermissions: {
      role: "admin:HubOnboardingPartnersOperatorViewingPermissionsPageProduct.View",
      description:
        "Visualização da página 'Operadores - Permissões de Visualização' na visão produto",
    },
    operatorUpdateRelationship: {
      role: "admin:HubOnboardingPartnersOperatorUpdateRelationshipPageProduct.View",
      description:
        "Visualização da página 'Operadores - Atualizar relacionamento' na visão produto",
    },
    partnershipTypes: {
      role: "admin:HubOnboardingPartnersOriginatorTypesPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Tipos de Originador' na visão produto",
    },
    configurations: {
      role: "admin:HubOnboardingPartnersConfigurationPageProduct.View",
      description: "Visualização do menu 'Configuracoes' na visão produto",
    },
    addPartnershipType: {
      role: "admin:HubOnboardingPartnersAddOriginatorTypePageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Adicionar Tipo de Originador' na visão produto",
    },
    editPartnershipType: {
      role: "admin:HubOnboardingPartnersEditOriginatorTypePageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Editar Tipo de Originador' na visão produto",
    },
    originatorSubtypes: {
      role: "admin:HubOnboardingPartnersOriginatorSubtypesPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Subtipos de Originador' na visão produto",
    },
    addOriginatorSubtype: {
      role: "admin:HubOnboardingPartnersAddOriginatorSubtypePageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Adicionar Subtipo de Originador' na visão produto",
    },
    editOriginatorSubtype: {
      role: "admin:HubOnboardingPartnersEditProductsPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Editar Subtipo de Originador' na visão produto",
    },
    desks: {
      role: "admin:HubOnboardingPartnersDeskPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Mesas' na visão produto",
    },
    addDesk: {
      role: "admin:HubOnboardingPartnersAddDeskPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Adicionar Mesa' na visão produto",
    },
    editDesk: {
      role: "admin:HubOnboardingPartnersEditProductsPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Editar Mesa' na visão produto",
    },
    products: {
      role: "admin:HubOnboardingPartnersProductsPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Produtos' na visão produto",
    },
    addProduct: {
      role: "admin:HubOnboardingPartnersAddProductPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Adicionar Produto' na visão produto",
    },
    editProduct: {
      role: "admin:HubOnboardingPartnersEditProductPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Editar Produto' na visão produto",
    },
    books: {
      role: "admin:HubOnboardingPartnersBooksPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Books' na visão produto",
    },
    addBook: {
      role: "admin:HubOnboardingPartnersAddBookPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Adicionar Book' na visão produto",
    },
    editBook: {
      role: "admin:HubOnboardingPartnersEditBookPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Editar Book' na visão produto",
    },
    catalogs: {
      role: "admin:HubOnboardingPartnersCatalogsPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Catálogos' na visão produto",
    },
    addCatalog: {
      role: "admin:HubOnboardingPartnersAddCatalogPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Adicionar Catálogo' na visão produto",
    },
    editCatalog: {
      role: "admin:HubOnboardingPartnersEditCatalogPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Editar Catálogo' na visão produto",
    },
    links: {
      role: "admin:HubOnboardingPartnersLinkssPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Vínculos' na visão produto",
    },
    addLink: {
      role: "admin:HubOnboardingPartnersAddLinkPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Adicionar Vínculo' na visão produto",
    },
  },
} satisfies Roles;
