import { ODivider, OTypography } from "@maestro/react";
import { ConditionItemProps } from "./condition-item.types";

export const ConditionItem = ({ label, value }: ConditionItemProps) => {
  return (
    <div className="w-100">
      <div className="py-3">
        <OTypography type="dark-80" size="sm">
          {label}
        </OTypography>
        <OTypography>{value}</OTypography>
      </div>
      <ODivider position="horizontal" />
    </div>
  );
};
