import { OToastManager } from "@maestro/core";
import { OTypography } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { customer } from "contexts/customer";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services/service";
import { DetailsTemplate } from "templates/details-template";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { DetailsCard } from "components/details-card";
import { OriginatorFields } from "./client-originator.details";

export const ClientOriginatorPage = () => {
  const [isOriginatorNotFoundError, setIsOriginatorNotFoundError] =
    useState(false);
  const {
    loading,
    value: originatorDetails,
    callService: getOriginatorDetails,
    hasError,
  } = useServiceCall(service.hubPartners.originatorDetails);

  const handleError = (error: {
    response?: { data?: { failures?: { errorCode?: string }[] } };
  }) => {
    return !!error?.response?.data?.failures?.find(
      (x) => x?.errorCode === "OriginatorNotFound",
    );
  };

  const getInfo = useCallback(async () => {
    const customerTaxId = customer.value?.identification;

    if (!customerTaxId) {
      return;
    }

    const { success, error } = await getOriginatorDetails(customerTaxId);

    if (!success) {
      const isNotFound = handleError(
        error as {
          response?: { data?: { failures?: { errorCode?: string }[] } };
        },
      );

      if (isNotFound) {
        setIsOriginatorNotFoundError(true);
      } else {
        OToastManager.danger("Falha ao obter detalhes do originador");
      }
    }
  }, [getOriginatorDetails]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Detalhes do originador" />}
      actions={
        <RouterButton
          href={
            corporateRouter.routes.parceiros.customer.originator.details.path
          }
          role={roles.parceiros.customer.originatorEditInfo.role}
          disabled={isOriginatorNotFoundError}
        >
          Editar Originador
        </RouterButton>
      }
    >
      <DetailsCard
        loading={loading}
        hasError={hasError && !isOriginatorNotFoundError}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível buscar os dados do originador"
            messageParagraph="Clique no botão para tentar novamente"
          >
            <TryAgainButton onClick={() => getInfo()} />
          </ErrorComponent>
        }
        emptyStateComponent={
          <OTypography>Esta empresa não é um originador.</OTypography>
        }
        value={originatorDetails?.data}
        fields={OriginatorFields}
      />
    </DetailsTemplate>
  );
};
