import { OCol, ORFieldInput, OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";

export interface ExportInvoicesFormValues {
  contractNumbers: string;
}

export const ExportInvoicesByContractNumberPage = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm<ExportInvoicesFormValues>();
  const { handleSubmit } = form;

  const importTrade = useMemo(
    () =>
      handleSubmit(async ({ contractNumbers }) => {
        try {
          setLoading(true);

          await service.hubLoan.exportInvoicesByContractNumbers({
            contractNumbers: contractNumbers.split(","),
          });

          OToastManager.success("Solicitação enviada com sucesso.");
        } catch {
          OToastManager.danger("O envio da solicitação falhou.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit],
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Solicitar exportação de notas fiscais"
          description="Digite os números de contratos separados por vírgula"
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={importTrade}>
          Exportar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <OCol>
            <ORFieldInput
              tag="text"
              id="contractNumbers"
              name="contractNumbers"
              label="Números de contratos (separados por vírgula)"
              tooltip="Ex: CCB-20241000-12345,CCB-20241001-23456"
            />
          </OCol>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
