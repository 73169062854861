import { useEffect, useMemo, useState } from "react";
import { usePropertyFieldComponent } from "./property-field.hook";
import { ProcessorConfigPropertyProps } from "./property-field.types";
import { fields } from "./property-field.utils";

export const PropertyFieldComponent = ({
  processorConfigProperty,
}: ProcessorConfigPropertyProps) => {
  const { handleSubmitProperty } = usePropertyFieldComponent();
  const [value, setValue] = useState(processorConfigProperty.value);

  useEffect(() => {
    setValue(processorConfigProperty.value);
  }, [processorConfigProperty]);

  const fieldRender = useMemo(() => {
    const { type, multiselectType } = processorConfigProperty;
    const fieldType = Object.keys(fields).includes(type)
      ? type
      : !multiselectType
      ? "DEFAULT"
      : "MULTI_SELECT";

    return fields[fieldType]({
      processorConfigProperty: { ...processorConfigProperty, value },
      handleSubmitProperty: async (fieldValue) => {
        setValue(fieldValue);
        await handleSubmitProperty(
          fieldValue,
          processorConfigProperty.processorPropertyId,
          processorConfigProperty.processorConfigId,
        );
      },
    });
  }, [processorConfigProperty, handleSubmitProperty, value]);

  return fieldRender;
};
