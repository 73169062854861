export const eventTypeMap = {
  PENDENCY_CHANGED_STATUS: "Pendência mudar de status",
  PENDENCY_CREATED: "Pendência for criada",
  PROPERTY_MODIFIED: "Propriedade for modificada",
  STEP_CHANGED_STATUS: "Step mudar de status",
  STEP_CREATED: "Step for criada",
} as const satisfies Record<HubEnergy.EDiligenceEventType, string>;

export const actionTypeMap = {
  ADD_PENDENCY_NOTE: "Adicionar anotação na pendência",
  CHANGE_PENDENCY_STATUS: "Mudar status da pendência",
  CHANGE_PENDENCY_STATUS_SCHEDULED: "Agendar mudança de status da pendência",
  CHANGE_STEP_STATUS: "Mudar status da step",
  CREATE_PENDENCY: "Criar pendência",
  CREATE_PROPERTY: "Criar propriedade",
  RESTART_PENDENCY_ANALYSIS_SCHEDULED:
    "Agendar retorno da pendência para análise",
} as const satisfies Record<HubEnergy.EDiligenceEventActionType, string>;
