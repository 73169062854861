import { useServiceCall } from "hooks/service-call";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { service } from "services";

interface CNABContextProps {
  isLoadingProducts: boolean;
  products?: BankingHubAdminCNAB.Product[];
}

const CNABContextDefaultValue: CNABContextProps = {
  isLoadingProducts: false,
  products: [],
};

const CNABContext = createContext(CNABContextDefaultValue);

export const CNABProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    callService: getProducts,
    loading: isLoadingProducts,
    value: productsResponse,
  } = useServiceCall(service.adminBankinghub.cnab.getProducts);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const value = useMemo(
    () => ({ isLoadingProducts, products: productsResponse?.products }),
    [isLoadingProducts, productsResponse],
  );

  return <CNABContext.Provider value={value}>{children}</CNABContext.Provider>;
};

export const useCNABContext = () => useContext(CNABContext);
