export const antecipacaoDeCartoesRouteParams = {
  companyId: "companyId",
  operationId: "operationId",
  taxId: "taxId",
  tierId: "tierId",
  maturityDate: "maturityDate",
  settlementId: "settlementId",
  groupId: "groupId",
  ruleId: "ruleId",
} as const;

type AntecipacaoDeCartoesParams = Record<
  keyof typeof antecipacaoDeCartoesRouteParams,
  string
>;

export type AntecipacaoDeCartoesEnquadramentoCarteiraTiersByTierId = Pick<
  AntecipacaoDeCartoesParams,
  "tierId"
>;

export type AntecipacaoDeCartoesOperacoesEmpresaByCompanyIdOperacaoByOperationIdDetalhes =
  Pick<AntecipacaoDeCartoesParams, "companyId" | "operationId">;

export type AntecipacaoDeCartoesContractSignatoriesByTaxId = Pick<
  AntecipacaoDeCartoesParams,
  "taxId"
>;

export type AntecipacaoDeCartoesExtratoLiquidacaoPosicaoDetalhes = Pick<
  AntecipacaoDeCartoesParams,
  "maturityDate"
>;

export type AntecipacaoDeCartoesCustomerBySettlementIdRouteParams = Pick<
  AntecipacaoDeCartoesParams,
  "settlementId"
>;

export type AntecipacaoDeCartoesClienteAssinaturasByRuleIdByGroupIdRouteParams =
  Pick<AntecipacaoDeCartoesParams, "ruleId" | "groupId">;
