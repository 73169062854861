import { yupResolver } from "@hookform/resolvers/yup";
import {
  OButton,
  OCheckbox,
  OIcon,
  OLoader,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInput,
  OTooltip,
  OTypography,
  modalManager,
} from "@maestro/react";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useCollectionBeneficiaryContext } from "../../../../collection-beneficiary.context";
import { floatingSettingsModalValidationSchema } from "./floating-settings-modal.form.schema";
import { useUpsertFloating } from "./floating-settings-modal.hook";
import {
  FloatingSettingsModalFormFields,
  FloatingSettingsModalId,
} from "./floating-settings-modal.types";

export { FloatingSettingsModalId } from "./floating-settings-modal.types";

export const FloatingSettingsModal = () => {
  const { upsertFloating, isLoading } = useUpsertFloating();
  const { beneficiary } = useCollectionBeneficiaryContext();

  const form = useForm<FloatingSettingsModalFormFields>({
    resolver: yupResolver(floatingSettingsModalValidationSchema),
  });
  const { handleSubmit, setValue, watch } = form;

  const immediateEffect = watch("immediateEffect");

  const handleImmediateEffectChange = useCallback(
    () => setValue("immediateEffect", !immediateEffect),
    [immediateEffect, setValue],
  );

  const handleContinue = useCallback(
    (formValues: FloatingSettingsModalFormFields) =>
      upsertFloating(formValues, !beneficiary?.floatingInDays),
    [beneficiary?.floatingInDays, upsertFloating],
  );

  useEffect(() => {
    if (beneficiary?.floatingInDays)
      setValue("quantityInDays", beneficiary?.floatingInDays);
  }, [beneficiary?.floatingInDays, setValue]);

  return (
    <OModal
      id={FloatingSettingsModalId}
      backdrop={!isLoading}
      position="center"
      size="sm"
    >
      <OModalHeader closeButton={!isLoading}>
        <OTypography type="dark" size="lg" className="text-nowrap">
          Configuração do Floating
        </OTypography>
      </OModalHeader>

      <OModalBody>
        <FormProvider {...form}>
          <div className="d-flex flex-column gap-3">
            <ORFieldInput
              label="Número de dias"
              description="Defina o prazo de floating das cobranças"
              id="quantityInDays"
              name="quantityInDays"
              tag="number"
            />

            <div className="d-flex align-items-center gap-3">
              <OCheckbox
                id="immediateEffect"
                name="immediateEffect"
                value="immediateEffect"
                checked={immediateEffect}
                onClick={handleImmediateEffectChange}
                size="sm"
              />

              <div className="d-flex align-items-center gap-2">
                <OTypography type="dark">
                  Atualizar floating dos boletos em aberto?
                </OTypography>
                <OTooltip maxWidth="24rem" position="top" floating>
                  <OIcon type="dark" category="fal" icon="fa-info-circle" />
                  <div slot="tooltip-content" className="py-1">
                    Ao selecionar essa opção, todos os boletos em aberto
                    passarão a ter o floating com essa configuração
                  </div>
                </OTooltip>
              </div>
            </div>
          </div>
        </FormProvider>
      </OModalBody>

      <OModalFooter divider>
        <div className="d-flex align-items-center justify-content-end gap-3">
          <OButton
            onClick={() => modalManager.hide(FloatingSettingsModalId)}
            disabled={isLoading}
            outline
          >
            Voltar
          </OButton>
          <OButton onClick={handleSubmit(handleContinue)} disabled={isLoading}>
            <div className="d-flex align-items-center gap-2">
              {beneficiary?.floatingInDays
                ? "Editar Floating"
                : "Adicionar Floating"}
              {isLoading && <OLoader size="xs" type="light" />}
            </div>
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
