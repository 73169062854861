import React, { useEffect, useState } from "react";

import { OCard, OCardBody, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { PostAdminLimitsGetLimitResponse } from "services/hubcreditmanager/models/responses";
import { LineChart } from "./_compose";

interface LimitChartProps {
  data?: PostAdminLimitsGetLimitResponse;
}

export const LimitChart: React.FC<LimitChartProps> = ({ data }) => {
  const [originatorLimit, setOriginatorLimit] =
    useState<PostAdminLimitsGetLimitResponse>();

  useEffect(() => {
    setOriginatorLimit(data ?? undefined);
  }, [data]);

  return (
    <OCard rounded>
      <OCardBody>
        <div className="d-flex justify-content-between mb-2">
          <OTypography type="dark" size="lg">
            Limite total
          </OTypography>
          <OTypography key={originatorLimit?.amount} type="dark" size="lg">
            {originatorLimit
              ? originatorLimit.limitType !== "INFINITY"
                ? masks.currency(originatorLimit?.amount, "R$")
                : "Limite infinito"
              : "Sem limite definido"}
          </OTypography>
        </div>
        <LineChart
          maxAmount={originatorLimit?.amount}
          progressAmount={
            originatorLimit
              ? originatorLimit.amount - originatorLimit.availableAmount
              : 0
          }
          progressTitle="Valor consumido"
          restAmount={originatorLimit?.availableAmount}
          restTitle="Disponível"
        />
      </OCardBody>
    </OCard>
  );
};
