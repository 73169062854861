import { EstrategiaComercial } from "./post-estrategia-comercial-by-company-id.request";

export interface PatchTemplateEstrategiaComercialBody {
  modoAtualizacaoVinculadas: UpdateStrategyTemplateChildrenOptions;
  estrategiaComercial: EstrategiaComercial;
  estrategiaJson: string;
}

export enum UpdateStrategyTemplateChildrenOptions {
  ONLY_DIRTY = "OnlyDirty",
  ALL_FIELDS = "AllFields",
  NOT_UPDATE = "NotUpdate",
}
