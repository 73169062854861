import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { useDiligenceEvents } from "./diligence-events.hook";

export const DiligenceEventsPage = () => {
  const gridRef = useGridRef();

  const { dataSource, grid } = useDiligenceEvents();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Regras de eventos" />}
      actions={
        <RouterButton
          href={
            corporateRouter.routes.energia.product.diligenceConfig
              .diligenceEvents.create.path
          }
          role={roles.energia.product.diligenceConfigDiligenceEventsCreate.role}
        >
          Adicionar
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
