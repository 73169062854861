import { modalManager, OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { useBankslipContext } from "../../bankslip.context";
import { CommunicationHistoryModalId } from "./communication-history-modal.types";

export const useCommunicationHistory = () => {
  const [communicationHistory, setCommunicationHistory] =
    useState<BankingHubAdminCollection.CollectionCommunication>({});
  const [isLoading, setIsLoading] = useState(false);
  const { collectionRef } = useBankslipContext();

  const getCommunicationHistory = useCallback(async () => {
    try {
      if (!collectionRef) return;
      setIsLoading(true);
      const { data } =
        await service.adminBankinghub.collection.getCommunicationHistory(
          collectionRef.collectionId,
        );
      setCommunicationHistory(data);
    } catch (err) {
      setCommunicationHistory({});
      OToastManager.danger(
        "Ocorreu um erro ao buscar as comunicações desta cobrança.",
      );
      modalManager.hide(CommunicationHistoryModalId);
    } finally {
      setIsLoading(false);
    }
  }, [collectionRef]);

  useEffect(() => {
    getCommunicationHistory();
  }, [getCommunicationHistory]);

  return { communicationHistory, isLoading };
};
