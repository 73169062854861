import { OLoader } from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { useMemo } from "react";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useWorkflowConfigField } from "./workflow-config-field.hook";

export const WorkflowConfigField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorPropertyId, name, description } = processorConfigProperty;
  const { chidWorkflowConfigs, loading } = useWorkflowConfigField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const options = useMemo(
    () =>
      chidWorkflowConfigs?.map((config) => {
        return {
          value: config.identification,
          label: config?.name,
        };
      }) ?? [],
    [chidWorkflowConfigs],
  );

  const workflowConfigField = useMemo(() => {
    const fieldId = `properties.${name}_${processorPropertyId}`;

    return (
      <div className="d-flex align-items-center">
        <SelectSearchField
          key={chidWorkflowConfigs
            ?.map((config) => config.identification)
            .join("")}
          id={fieldId}
          name={fieldId}
          placeholder="Selecione"
          label={description}
          options={options}
        />
        {loading && <OLoader />}
      </div>
    );
  }, [
    name,
    processorPropertyId,
    chidWorkflowConfigs,
    description,
    options,
    loading,
  ]);

  return <>{workflowConfigField}</>;
};
