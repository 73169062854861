import { useEffect } from "react";
import {
  modalManager,
  OCard,
  OCardBody,
  OCardFooter,
  OCardHeader,
  OCol,
  OIcon,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import dayjs from "dayjs";
import { masks } from "@maestro/utils";
import { PageTitle } from "components/page-title";
import { banksList } from "utils/bank-list";
import { rateIndexerMask } from "utils/mask";
import { ConditionItem } from "./compose/condition-item/condition-item.component";
import {
  AccountInfoBadge,
  StyledButton,
  TotalValueBadge,
} from "./conditions.styles";
import { SimulationFooter } from "../../footer/footer.component";
import { CalendarModal } from "../../calendar-modal/calendar-modal.component";
import { mapBankIcon } from "../../bank-account-list/map-icon";
import { ConditionsSkeleton } from "./compose/skeleton/conditions-skeleton.component";
import { useSimulation } from "../../../simulation.context";
import { collateralTypeMap } from "../../../simulation.utils";
import { paymentMethodMap } from "../../settlement-type/settlement-type.utils";
import { DatePickerWithoutIconIfDisabled } from "../steps-components.styles";

const mapBank = (bankCode: string) => banksList.find((i) => i.id === bankCode);

export const Conditions = () => {
  const {
    selectedOfferLoan,
    selectedOfferLimit,
    getBankingAccounts,
    bankAccountsList,
    disabledDate,
    summary,
    acceptOfferButtonDisabled,
    handleAcceptOffer,
    setValue,
    loadingSimulation,
    loadingBankingAccounts,
    showMoneyInAccountBadge,
  } = useSimulation();

  useEffect(() => {
    if (selectedOfferLoan) getBankingAccounts();
  }, [selectedOfferLoan, getBankingAccounts]);

  let collaterals = selectedOfferLoan?.collaterals
    .filter((c) => c.collateralType !== "GARANTIDOR")
    .map((c) => collateralTypeMap[c.collateralType]?.label);

  if (
    !!collaterals &&
    (!!selectedOfferLoan?.guarantors.requiredGuarantors.length ||
      selectedOfferLoan?.collaterals.some(
        (c) => c.collateralType === "GARANTIDOR",
      ))
  )
    collaterals = [...collaterals, "Avalistas"];

  const showDateField = !!summary?.length && summary.length >= 2;

  const allowedSettlementTypes = summary?.find(
    (s) => s.id === selectedOfferLimit?.id,
  )?.allowedSettlementTypes;

  useEffect(() => {
    const prioritizedAgencies = ["50", "0050", "1", "0001"];

    let prioritizedBank = bankAccountsList.find(
      (b) => b.bankCode === "208" && prioritizedAgencies.includes(b.agency),
    );
    if (!prioritizedBank)
      prioritizedBank = bankAccountsList.find((b) => b.bankCode === "208");
    if (!prioritizedBank)
      prioritizedBank = bankAccountsList.find((b) =>
        prioritizedAgencies.includes(b.agency),
      );
    if (!prioritizedBank) prioritizedBank = bankAccountsList?.[0];

    if (!prioritizedBank) return;

    setValue("bank", prioritizedBank.id);
  }, [bankAccountsList, setValue]);

  useEffect(() => {
    if (allowedSettlementTypes?.includes("Boleto")) {
      setValue("paymentMethod", "Boleto");
    } else if (allowedSettlementTypes?.length) {
      setValue("paymentMethod", "DebitoEmConta");
    }
  }, [allowedSettlementTypes, setValue]);

  // disbursement instructions
  const instructions = selectedOfferLoan?.disbursementInstructions;
  const integrator = instructions?.find((i) => i.type === "INTEGRATOR");
  const distributor = instructions?.find((i) => i.type === "DISTRIBUTOR");

  const integratorTooltip =
    integrator &&
    `${integrator?.holderName} | ${mapBank(integrator.bankCode)?.title} (${
      integrator.bankCode
    }) - Agência ${integrator.agency} | Conta ${integrator.account} | Conta ${
      integrator.accountType
    }`;
  const distributorTooltip =
    distributor &&
    `${distributor?.holderName} | ${mapBank(distributor.bankCode)?.title} (${
      distributor.bankCode
    }) - Agência ${distributor.agency} | Conta ${distributor.account} | Conta ${
      distributor.accountType
    }`;

  return (
    <>
      {loadingSimulation && <OLoader absolute backdrop />}
      <PageTitle
        title="Condições de pagamento"
        description="Confira as condicões do empréstimo e, se estiver de acordo, continue o processo."
      />
      <OCard>
        <OCardHeader>
          <OTypography>Informações do empréstimo</OTypography>
        </OCardHeader>
        <OCardBody>
          <ORow>
            <OCol>
              <ConditionItem
                label="Valor solicitado"
                value={masks.currency(selectedOfferLoan?.amount, "R$")}
              />
            </OCol>
            <OCol>
              <ConditionItem
                label="Quantidade de parcelas"
                value={
                  selectedOfferLoan?.term === 1
                    ? `1 parcela`
                    : `${selectedOfferLoan?.term} parcelas`
                }
              />
            </OCol>
            <OCol>
              <ConditionItem
                label="Taxa de juros (a.m.)"
                value={rateIndexerMask(
                  selectedOfferLoan?.rate,
                  selectedOfferLoan?.interest.indexCode,
                )}
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol>
              <ConditionItem
                label="IOF"
                value={masks.currency(
                  selectedOfferLoan?.settlement.iofAmount,
                  "R$",
                )}
              />
            </OCol>
            <OCol>
              <ConditionItem
                label="CET (a.a.)"
                value={masks.percentage(selectedOfferLoan?.cet)}
              />
            </OCol>
            <OCol>
              <ConditionItem
                label="Primeiro vencimento"
                value={dayjs(
                  selectedOfferLoan?.settlement.installments[0].maturityDate,
                ).format("DD/MM/YYYY")}
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol sm={4}>
              <ConditionItem
                label="Garantias"
                value={collaterals?.length ? collaterals.join(", ") : "-"}
              />
            </OCol>
          </ORow>
        </OCardBody>
        <OCardFooter>
          <div className="d-flex flex-column gap-4 mt-3">
            <TotalValueBadge>
              <div className="d-flex gap-2">
                <OIcon size="xl" category="orq" icon="orq-payment-bills" />
                <OTypography>Total a prazo</OTypography>
              </div>
              <OTypography>
                {masks.currency(selectedOfferLoan?.settlement.amount, "R$")}
              </OTypography>
            </TotalValueBadge>
            <StyledButton
              bordered={false}
              outline
              onClick={() => {
                modalManager.show(
                  `calendar-modal-${selectedOfferLoan?.identification}`,
                );
              }}
            >
              <div className="d-flex gap-1 align-items-center">
                Consultar parcelas
                <OIcon category="orq" icon="orq-chevron-right" />
              </div>
            </StyledButton>
          </div>
        </OCardFooter>
      </OCard>
      <OCard>
        <OCardBody>
          <ORow>
            {selectedOfferLoan?.disbursementInstructions?.length ? (
              <>
                {integrator && (
                  <OCol>
                    <ORFieldInput
                      readonly
                      tooltip={integratorTooltip}
                      label="Desembolso para o integrador"
                      tag="currency"
                      symbol="R$"
                      id="integrator"
                      name="integrator"
                      value={integrator?.amount}
                    />
                  </OCol>
                )}
                {distributor && (
                  <OCol>
                    <ORFieldInput
                      readonly
                      tooltip={distributorTooltip}
                      label="Desembolso para o distribuidor"
                      tag="currency"
                      symbol="R$"
                      id="distributor"
                      name="distributor"
                      value={distributor?.amount}
                    />
                  </OCol>
                )}
              </>
            ) : (
              <OCol>
                {loadingBankingAccounts ? (
                  <ConditionsSkeleton />
                ) : (
                  <ORFieldSelect
                    id="bank"
                    name="bank"
                    label="Selecione a conta para recebimento"
                    key={bankAccountsList.map((b) => b.id).join("-")}
                  >
                    {bankAccountsList.map((account) => (
                      <OOption key={account.id} value={account.id}>
                        <div className="d-flex gap-2 align-items-start">
                          <img
                            className="my-2"
                            src={
                              mapBankIcon[account.bankCode]?.icon ??
                              mapBankIcon.default.icon
                            }
                            alt={mapBank(account.bankCode)?.title}
                            style={{
                              height: "30px",
                              width: "30px",
                            }}
                          />
                          <OTypography className="mt-1">
                            {mapBank(account.bankCode)?.title} (
                            {account.bankCode}) - Agência {account.agency} |
                            Conta {account.accountNumber}-{account.accountDigit}{" "}
                            | Conta {account.accountType}
                          </OTypography>
                        </div>
                      </OOption>
                    ))}
                  </ORFieldSelect>
                )}
              </OCol>
            )}
            <OCol>
              <ORFieldSelect
                id="paymentMethod"
                name="paymentMethod"
                label="Método de pagamento"
                key={`${allowedSettlementTypes?.join("_")}`}
              >
                {allowedSettlementTypes?.map((method) => (
                  <OOption key={method} value={method}>
                    {paymentMethodMap[method || ""].label || "-"}
                  </OOption>
                ))}
              </ORFieldSelect>
            </OCol>
            {showDateField && (
              <OCol>
                <DatePickerWithoutIconIfDisabled
                  id="date"
                  name="date"
                  label="Primeiro vencimento"
                  size="md"
                  disabledDate={disabledDate}
                  disabled={selectedOfferLimit?.fundingType === "BNDES"}
                  allowClear={selectedOfferLimit?.fundingType !== "BNDES"}
                />
              </OCol>
            )}
          </ORow>
        </OCardBody>
        {showMoneyInAccountBadge && (
          <OCardFooter>
            <AccountInfoBadge>
              <OIcon size="lg" category="orq" icon="orq-info-on" type="info" />
              <div className="d-flex flex-column gap-1">
                <OTypography size="sm" type="dark-60">
                  Mantenha seu saldo em conta suficiente para o pagamento das
                  parcelas do seu crédito e evite problemas com inadimplência.
                </OTypography>
              </div>
            </AccountInfoBadge>
          </OCardFooter>
        )}
      </OCard>
      <CalendarModal
        id={`calendar-modal-${selectedOfferLoan?.identification}`}
        selectedOfferLoan={selectedOfferLoan}
      />
      <SimulationFooter
        continueButtonDisabled={acceptOfferButtonDisabled}
        handleAcceptOffer={handleAcceptOffer}
        continueText="Aceitar proposta"
      />
    </>
  );
};
