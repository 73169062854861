import { OToastManager } from "@maestro/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import dayjs from "dayjs";
import { service } from "services";
import { yupResolver } from "@hookform/resolvers/yup";
import { BlockListTypesMap, FormValuesType } from "../block.types";
import { getKeyByValue, validationSchema } from "../block.utils";

export const useEligibilityBlockListAdd = () => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;
  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const objectBlockList = {
        BlockListItems: [
          {
            identification: values.identification,
            type: getKeyByValue(BlockListTypesMap, values.type),
            from: values.from ? dayjs(values.from).format("YYYY-MM-DD") : null,
            until: values.until
              ? dayjs(values.until).format("YYYY-MM-DD")
              : null,
          },
        ],
      };

      await service.validator.addBlockList(objectBlockList);

      OToastManager.success("Bloqueio adicionado com sucesso");

      navigate(routes.fidc.product.eligibilitys.eligibilityBlockList.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    loading,
    submit,
  };
};
