import { useBehavior } from "contexts/behavior";
import { BehaviorRoles } from "contexts/behavior/types";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CustomerSelectorItem } from "../customer-selector.types";
import { useFavorites } from "./favorites.context";
import { useSearchEntity } from "./search-entity.context";
import { useUserCustomers } from "./user-customers.context";

interface FullOptionsContext {
  fullOptions: CustomerSelectorItem[];
  mode: "fetch" | "select";
}

const fullOptionsContext = createContext({} as FullOptionsContext);

interface FullOptionsProps {
  children: React.ReactNode;
}

export const FullOptionsProvider = ({ children }: FullOptionsProps) => {
  const [fullOptions, setFullOptions] = useState<CustomerSelectorItem[]>([]);

  const { hasRoles } = useBehavior();

  const mode = hasRoles([BehaviorRoles.HeaderCustomerSelectorFetch_Enable])
    ? "fetch"
    : "select";

  const { entities } = useSearchEntity();
  const { userCustomers } = useUserCustomers();
  const { favorites } = useFavorites();

  useEffect(() => {
    if (mode === "select") {
      setFullOptions([...userCustomers]);
    } else if (mode === "fetch") {
      setFullOptions([...favorites, ...entities]);
    }
  }, [entities, favorites, mode, userCustomers]);

  const value: FullOptionsContext = useMemo(
    () => ({ fullOptions, mode }),
    [fullOptions, mode],
  );

  return (
    <fullOptionsContext.Provider value={value}>
      {children}
    </fullOptionsContext.Provider>
  );
};

export const useFullOptions = () => useContext(fullOptionsContext);
