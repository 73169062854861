import dayjs from "dayjs";
import { NotesFilterForm } from "./note-filter.types";

export const newFilterDefaultValues: NotesFilterForm = {
  sacadoIdList: [],
  cedenteIdList: [],
  tipoProdutoList: [
    "RiscoSacado",
    "RiscoCedente",
    "Offshore",
    "FundingProprio",
    "CCC",
  ],
  currentStateList: [],
  receivableIdentification: [],
  externalIds: [],
  contractNumbers: [],
  strategyGuids: [],
  receivableId: [],
  tradeId: [],
  borderoId: [],
  operationId: [],
  creditorIds: [],
  marketplaceIds: [],
  portfolioIds: ["1", "4", "5", "6"],
  tradeDate: [],
  disbursementDate: [],
  maturityDate: [],
  createdAt: [],
  updatedAt: [dayjs(), dayjs()],
};
