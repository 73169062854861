import { OToastManager } from "@maestro/core";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useMemo, useRef } from "react";
import { service } from "services";
import { buildDiligenceEventRulesGrid } from "./diligence-events.grid";

export const useDiligenceEvents = () => {
  const rawData = useRef<HubEnergy.DiligenceEventRuleResponse[]>([]);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<HubEnergy.DiligenceEventRuleResponse>(() =>
        service.hubEnergy
          .getDiligenceEventRules()
          .then(({ data }) => {
            rawData.current = data.response;
            return data.response;
          })
          .catch(() => {
            const errorMessage = "Não foi possível buscar as regras de eventos";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
      ),
    [],
  );

  const grid = useMemo(
    () => buildDiligenceEventRulesGrid(() => rawData.current),
    [],
  );

  return { dataSource, grid };
};
