import { OBadge, OIcon, OInputText, OTypography } from "@maestro/react";
import styled from "styled-components";
import { SelectableItemProps } from "./canva-search-bar.types";

export const SearchBarDiv = styled.div<SelectableItemProps>`
  border-radius: var(--border-radius);
  background-color: var(--theme-primary);
  padding: 0 0.5rem;
  height: calc(var(--font-lg) + 2rem);
  position: absolute;

  opacity: 0;
  visibility: hidden;
  right: -1rem;
  transition: all 0.3s ease-in-out;

  ${(props) =>
    props.active &&
    `
    right: 0;
    visibility: visible;
    opacity: 1;

  `}
`;

export const StyledText = styled(OTypography)`
  text-wrap: nowrap;
  margin: 0.5rem 1rem;
`;

export const StyledBadge = styled(OBadge)`
  cursor: pointer;
  color: var(--theme-primary);
`;

export const StyledDiv = styled.div`
  position: relative;
`;

export const StyledIcon = styled(OIcon)<SelectableItemProps>`
  padding: 0 0.5rem;
  height: calc(var(--font-lg) + 1rem);

  ${(props) =>
    props.active &&
    `
      cursor: pointer;

      &:hover {
       background-color: var(--theme-secondary);
      }
  `}
`;

export const StyledInput = styled(OInputText)`
  width: 12rem;

  o-input .o-input--outline {
    background-color: var(--theme-secondary);
    border-color: var(--theme-default);
    color: var(--theme-light);
  }
`;
