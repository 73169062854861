import {
  OCard,
  OCardBody,
  OCheckbox,
  OCol,
  OLabel,
  OLoader,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { FormTemplate } from "templates/form-template";
import { ConfigurationsForm } from "../configuration-form";
import { DesksFields } from "../desks-fields";
import { MultiSelectFields } from "../multi-select-fields";
import { PartnerFields } from "../partner-fields";
import { useWorkflowScfForm } from "./workflow-scf-form.hook";

interface WorkflowScfFormProps {
  isEditing?: boolean;
  loading?: boolean;
}

export const WorkflowScfForm = ({
  isEditing = false,
  loading = false,
}: WorkflowScfFormProps) => {
  const { loadingSubmit, submit } = useWorkflowScfForm(isEditing);

  return (
    <FormTemplate
      actions={
        <LoadingButton onClick={submit} loading={loadingSubmit || loading}>
          {isEditing ? "Atualizar" : "Enviar"}
        </LoadingButton>
      }
    >
      <OCard>
        <OCardBody>
          {loading && <OLoader absolute backdrop />}
          <ORow>
            <OCol xs={4}>
              <ORFieldInput
                tag="text"
                id="configurationName"
                name="configurationName"
                label="Nome workflow"
              />
            </OCol>
            <OCol xs={4}>
              <ORFieldInput
                tag="text"
                id="configurationDisplayName"
                name="configurationDisplayName"
                label="Nome visual do workflow"
              />
            </OCol>
            <OCol xs={4}>
              <ORFieldInput
                tag="text"
                id="configurationId"
                name="configurationId"
                label="Configuration Id"
                disabled
              />
            </OCol>
            <PartnerFields isEditing={isEditing} />
            <DesksFields />
            <OCol xs={4} align="center">
              <ORFieldCheckboxGroup id="isFidc" name="isFidc">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox size="xs" id="isFidc-checkbox" value="true" />
                  <OLabel htmlFor="isFidc-checkbox">É de FIDC?</OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <MultiSelectFields />
            <OCol xs={12} className="mb-3">
              <OTypography size="xl">
                Configurações da Esteira de Onboarding
              </OTypography>
            </OCol>
            <OCol xs={12}>
              <ConfigurationsForm
                fieldName="configurations"
                title="Configurações"
              />
            </OCol>
            <OCol xs={12}>
              <ConfigurationsForm
                fieldName="syncConfigurations"
                title="Configurações sync"
              />
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
    </FormTemplate>
  );
};
