import { GetCompanyProductsResponse } from "services/hubloan/models";
import { productMap } from "../../offers.utils";
import { BNDESFrameStatus } from "../../offers.type";

interface ProductsProps {
  availableProducts: GetCompanyProductsResponse[];
  frameStatus: BNDESFrameStatus;
}

export const Products = ({ availableProducts, frameStatus }: ProductsProps) => {
  const visibleProducts = availableProducts.filter(
    (p) => p.fundingType !== "BNDES",
  );

  return (
    <>
      {visibleProducts.map((product) => {
        const props = productMap[product.identification];
        const title = props.title(product.creditLineVisibleName);

        const redirectPath = `${props.redirectPath}/${product.id}/oferta/${product.offerId}`;

        const mustWaitForBndesFrame =
          product.identification === "KGIRO" &&
          availableProducts.some((p) => p.fundingType === "BNDES");

        if (props?.content)
          return (
            <div key={product.id}>
              {props.content({
                ...props,
                limit: product.availableAmount,
                key: props.identification,
                title,
                creditLineDescription: product.creditLineDescription,
                iconName: product.creditLineIcon ?? props.iconName,
                redirectPath,
                mustWaitForBndesFrame,
                frameStatus,
              })}
            </div>
          );

        return null;
      })}
    </>
  );
};
