import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services/service";
import { scfDevelopersOnboardingStatusMap } from "../../../../../../../utils/scf-configuration-maps";
import { WorkflowScfFormFields } from "../../../workflow-configuration-scf.form";
import { buildWorkflowConfigurationsFromForm } from "../../../workflow-configuration-scf.utils";

export const useWorkflowScfForm = (isEditing: boolean) => {
  const navigate = useNavigate();

  const form = useFormContext<WorkflowScfFormFields>();
  const { handleSubmit } = form;

  const { callService: upsertWorkflowConfiguration, loading: loadingSubmit } =
    useServiceCall(service.scfDevelopers.upsertWorkflowConfiguration);

  const submit = handleSubmit(async (values) => {
    const workflowConfiguration = {
      allowedReprocessStatuses:
        values.allowedReprocessStatuses.length ===
        Object.keys(scfDevelopersOnboardingStatusMap).length
          ? []
          : values.allowedReprocessStatuses ?? [],
      authClientId: values.authClientId,
      configurationId: values.configurationId,
      configurationName: values.configurationName,
      configurationDisplayName: values.configurationDisplayName,
      configurations: buildWorkflowConfigurationsFromForm(
        values.configurations,
      ),
      desks: values.desks,
      integrationType: values.integrationType,
      isFidc: values.isFidc.includes("true"),
      mainDesk: values.mainDesk,
      partnerId: values.partnerId.toString(),
      partnerName: values.partnerName,
      requiredOnboardingDocuments: values.requiredOnboardingDocuments,
      syncConfigurations: buildWorkflowConfigurationsFromForm(
        values.syncConfigurations,
      ),
      requestRequiredFields: values.requestRequiredFields ?? [],
    };

    const { success } = await upsertWorkflowConfiguration({
      workflowConfiguration,
    });

    if (!success) {
      const errorMessage = `Erro ao ${
        isEditing ? "atualizar" : "adicionar"
      } o workflow.`;
      OToastManager.danger(errorMessage);
    } else {
      const message = `Workflow ${
        isEditing ? "atualizado" : "adicionado"
      } com sucesso!`;
      OToastManager.success(message);
      navigate(
        corporateRouter.routes.ferramentas.product.onboarding.domainConfigScf
          .workflowConfiguration.path,
      );
    }
  });

  return {
    loadingSubmit,
    submit,
  };
};
