import {
  modalManager,
  OButton,
  OCard,
  OCardBody,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { getValidationMessages } from "../../../../../../../../utils";
import { ruleTextBuilder } from "../../../../_utils";
import {
  RemoveRuleConfirmationModal,
  removeRuleConfirmationModalId,
} from "../../../confirmation-modals";

interface RuleDisplayProps {
  rule: HubEnergy.DiligenceEventRuleResponse;
  setMode: React.Dispatch<React.SetStateAction<"display" | "editing">>;
}

export const RuleDisplay = ({ rule, setMode }: RuleDisplayProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.deleteDiligenceEventRule,
  );

  const navigate = useNavigate();

  const deleteRule = useCallback(async () => {
    const { success, error } = await callService(rule.id);

    if (success) {
      OToastManager.success("Regra excluída com sucesso");
      navigate(
        corporateRouter.routes.energia.product.diligenceConfig.diligenceEvents
          .path,
        { replace: true },
      );
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.errorMessage ??
          "Erro ao excluir regra",
      );
    }
  }, [callService, rule.id, navigate]);

  return (
    <>
      <DetailsTemplate
        actions={
          <>
            <LoadingButton
              loading={loading}
              onClick={() =>
                modalManager.show(removeRuleConfirmationModalId(rule.id))
              }
              type="danger"
            >
              Excluir
            </LoadingButton>
            <OButton onClick={() => setMode("editing")}>Editar</OButton>
          </>
        }
      >
        <OCard>
          <OCardBody>
            <OTypography>{ruleTextBuilder(rule)}</OTypography>
            {rule.stopProcessingMoreRules && (
              <OTypography className="mt-3">
                - Parar de processar regras após esta
              </OTypography>
            )}
            {!!rule.allowedDistributors.length && (
              <OTypography className="mt-3">
                - Válido para as distribuidoras:{" "}
                {rule.allowedDistributors.map((ad) => ad.name).join(", ")}
              </OTypography>
            )}
          </OCardBody>
        </OCard>
      </DetailsTemplate>
      <RemoveRuleConfirmationModal rule={rule} remove={deleteRule} />
    </>
  );
};
