import { OCol, OLoader, OOption, ORFieldSelect } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { usePortfolioIdField } from "./portfolio-id-field.hook";

export const PortfolioIdField = ()=> {
  const { portfolios, getPortfolios, loading, hasFetchError } = usePortfolioIdField();
  
  return (
    <OCol xs={12} md={6} align="center" className="flex-fill">
      <ORFieldSelect
        id="portfolioId"
        name="portfolioId"
        label="Carteira"
        placeholder="Selecionar"
        disabled={hasFetchError || loading}
        key={portfolios?.length}
      >
        <OOption key="empty" value={0}>Selecionar</OOption>
        {portfolios?.map((portfolio) => (
          <OOption key={portfolio.id} value={portfolio.id}>
            {portfolio.name}
          </OOption>
        ))}
      </ORFieldSelect>
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton
          dataAction="carteiras:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => getPortfolios()}
        />
      )}
    </OCol>
  );
};
