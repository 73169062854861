import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { erpConnectionsGrid } from "./erp-connections.grid";

export const ERPConnections = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Conexões ERP" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
      actions={
        <RouterButton
          href={
            corporateRouter.routes.emprestimos.product.erpConnections.create
              .path
          }
          role={roles.emprestimos.product.eCNPJsCreateECNPJPage.role}
        >
          Criar
        </RouterButton>
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={erpConnectionsGrid(gridRef)}
      />
    </GridTemplate>
  );
};
