import { ODivider } from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { ProductName } from "services/admin-bankinghub/cnab/models";
import { UpsertLayoutFormFields } from "../../../../upsert-layout.form-schema";
import { CheckboxField } from "./_compose/checkbox-field";

export const CollectionConfigFields = () => {
  const { watch } = useFormContext<UpsertLayoutFormFields>();

  const productNameWatched = watch("productName");

  if (productNameWatched !== ProductName.Collection) return null;

  return (
    <div className="d-flex flex-column gap-3">
      <ODivider size="xxs" />

      <CheckboxField
        fieldName="isBolepix"
        label="Boleto com Código de barras e QR Code Pix"
      />

      <CheckboxField
        fieldName="sendEmail"
        label="Enviar cobranças por e-mail"
      />

      <CheckboxField fieldName="sendSMS" label="Enviar cobranças por SMS" />
    </div>
  );
};
