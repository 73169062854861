import { env } from "utils/environments";

export const scfDevelopersEndpoints = {
  onboarding: {
    batch: {
      endpoint: `${env.API_SCF_DEVELOPERS}/onboarding/batch`,
      isUpsertAvailable: `${env.API_SCF_DEVELOPERS}/onboarding/payer-low/is-upsert-available`,
      verify: `${env.API_SCF_DEVELOPERS}/onboarding/batch/verify`,
    },
    cedenteCompleto: {
      byTaxId: (taxId: string) =>
        `${env.API_SCF_DEVELOPERS}/onboarding/cedente-completo/${taxId}`,
      endpoint: `${env.API_SCF_DEVELOPERS}/onboarding/cedente-completo`,
    },
    file: {
      uploadToTempBucket: `${env.API_SCF_DEVELOPERS}/onboarding/file/upload-to-temp-bucket`,
    },
    officer: {
      endpoint: `${env.API_SCF_DEVELOPERS}/onboarding/officer`,
      list: `${env.API_SCF_DEVELOPERS}/onboarding/officer/list`,
      upsert: `${env.API_SCF_DEVELOPERS}/onboarding/officer/upsert`,
    },
    partner: {
      endpoint: `${env.API_SCF_DEVELOPERS}/onboarding/partner`,
      list: `${env.API_SCF_DEVELOPERS}/onboarding/partner/list`,
      upsert: `${env.API_SCF_DEVELOPERS}/onboarding/partner/upsert`,
    },
    partnerConfiguration: {
      endpoint: `${env.API_SCF_DEVELOPERS}/onboarding/partner-configuration`,
      byAuthClientId: {
        byConfigurationId: (authClientId: string, configurationId: string) =>
          `${env.API_SCF_DEVELOPERS}/onboarding/partner-configuration/${authClientId}/${configurationId}`,
      },
      withoutSteps: `${env.API_SCF_DEVELOPERS}/onboarding/partner-configuration/without-steps`,
    },
    payerLow: {
      endpoint: `${env.API_SCF_DEVELOPERS}/onboarding/payer-low`,
      isUpsertAvailable: `${env.API_SCF_DEVELOPERS}/onboarding/payer-low/is-upsert-available`,
      mandatoryFields: `${env.API_SCF_DEVELOPERS}/onboarding/payer-low/mandatory-fields`,
    },
    workflowConfiguration: {
      endpoint: `${env.API_SCF_DEVELOPERS}/onboarding/workflow-configuration`,
      byAuthClientId: {
        byConfigurationId: {
          byIntegrationType: (
            authClientId: string,
            configurationId: string,
            integrationType: string,
          ) =>
            `${env.API_SCF_DEVELOPERS}/onboarding/workflow-configuration/${authClientId}/${configurationId}/${integrationType}`,
        },
      },
    },
  },
};
