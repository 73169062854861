import {
  modalManager,
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import dayjs from "dayjs";
import {
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { GridTemplate } from "templates/grid-template";
import { useGridRef } from "hooks/grid-ref";
import { Installment } from "../../../../../offers.type";
import { useSimulation } from "../../simulation.context";
import { CalendarModalProps } from "./calendar-modal.types";

export const CalendarModal = ({
  selectedOfferLoan,
  id,
}: CalendarModalProps) => {
  const { loading } = useSimulation();

  const grid: ODataGridGeneratorConfig<Installment[][number]> = {
    datagrid: {
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
      noDataText: "Nenhuma parcela encontrada.",
      pager: {
        infoText: "Página {0} de {1} ({2} parcelas)",
      },
      remoteOperations: true,
    },
    columns: [
      {
        dataField: "code",
        dataType: "string",
        caption: "Parcela",
      },
      {
        dataField: "maturityDate",
        caption: "Vencimento",
        cellRender: ({ data }) => {
          return dayjs(data.maturityDate, "YYYY-MM-DD").format("DD/MM/YYYY");
        },
      },
      {
        dataField: "amount",
        caption: "Valor",
        format: (value) => masks.currency(value, "R$", "."),
      },
    ],
  };

  const gridRef = useGridRef();

  return (
    <OModal id={id} position="center">
      <OModalHeader closeButton>
        <OTypography size="lg">Detalhes da parcela</OTypography>
        <OTypography type="default">
          Confira todas as informações antes de aprovar.
        </OTypography>
      </OModalHeader>

      <OModalBody>
        <OTypography size="lg">Calendário de pagamentos</OTypography>
        <GridTemplate gridRef={gridRef} showRefreshButton>
          <ODataGridGenerator
            gridRef={gridRef}
            grid={grid}
            loading={loading}
            dataSource={selectedOfferLoan?.settlement.installments}
          />
        </GridTemplate>
      </OModalBody>

      <OModalFooter className="justify-content-start">
        <OButton
          type="light"
          className="btn-outline-dark"
          onClick={() => modalManager.hide(id)}
        >
          Fechar
        </OButton>
      </OModalFooter>
    </OModal>
  );
};
