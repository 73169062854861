import { SelectSearchField } from "components/select-search";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useFormPropertyField } from "./form-property-field.hook";

export const FormPropertyField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorPropertyId, name, description } = processorConfigProperty;
  const { formList } = useFormPropertyField({
    processorConfigProperty,
    handleSubmitProperty,
  });

  const options = [
    { identification: "", name: "Nenhum" },
    ...(formList ?? []),
  ].map((config) => {
    return {
      value: config.identification,
      label: config?.name,
    };
  });

  return (
    <SelectSearchField
      key={formList?.map((form) => form.identification).join("")}
      id={`properties.${name}_${processorPropertyId}`}
      name={`properties.${name}_${processorPropertyId}`}
      placeholder="Selecione"
      label={description}
      options={options}
    />
  );
};
