import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { logger } from "utils/logger";

export const useCNABAgreement = () => {
  const [isLoadingCreateAgreement, setIsLoadingCreateAgreement] =
    useState(false);

  const {
    callService: getAgreementsList,
    loading: isLoadingAgreementsList,
    value: agreementsList,
  } = useServiceCall(service.adminBankinghub.cnab.getAgreements);

  const createAgreement = useCallback(async () => {
    try {
      setIsLoadingCreateAgreement(true);
      await service.adminBankinghub.cnab.createAgreement();
      OToastManager.success("Convênio cadastrado com sucesso.");
      getAgreementsList();
    } catch (err) {
      OToastManager.danger("Ocorreu um erro para cadastrar o convênio");
      logger.warn(err);
    } finally {
      setIsLoadingCreateAgreement(false);
    }
  }, [getAgreementsList]);

  useEffect(() => {
    getAgreementsList();
  }, [getAgreementsList]);

  return {
    agreementsList,
    createAgreement,
    isLoadingAgreementsList,
    isLoadingCreateAgreement,
  };
};
