import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { DataGrid } from "devextreme-react/data-grid";
import { useRef } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { exchangeProductDocumentGroupsGrid } from "./exchange-product-document-groups.grid";

export const ExchangeProductDocumentGroupsPage = () => {
  const dataGridRef = useRef<DataGrid>(null);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Grupo de Documentos" />}
      actions={
        <RouterButton
          href={
            corporateRouter.routes.cambio.product.contingency.natures
              .documentGroups.create.path
          }
          role={
            roles.cambio.product.contingencyNaturesDocumentGroupsCreatePage.role
          }
        >
          Adicionar grupo de documentos
        </RouterButton>
      }
      gridRef={dataGridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={dataGridRef}
        grid={exchangeProductDocumentGroupsGrid}
      />
    </GridTemplate>
  );
};
