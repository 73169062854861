import { GetTemplateEstrategiaComercialResponse } from "services/quickfin/models";
import { DeepNullable } from "utils/types/nullable";
import {
  EstrategiaForm,
  TipoProduto,
} from "../../../../../../components/estrategia-form";
import { booleanToRadio } from "../../../../../../utils";

export const buildInitialValues = (
  estrategia: GetTemplateEstrategiaComercialResponse[number],
) => {
  const initialValues: DeepNullable<EstrategiaForm> = {
    id: estrategia.id,
    tipoProduto: estrategia.tipoProduto as TipoProduto,
    nome: estrategia.nome,
    modeloDeTaxa: estrategia.modeloDeTaxa,
    exigeAprovacaoBanco: booleanToRadio(estrategia.exigeAprovacaoBanco),
    prazoLimiteOperacao: estrategia.prazoLimiteOperacao,
    instrumentCodeId: estrategia.instrumentCodeId,
    approvalGroups: estrategia.approvalGroups,
    aprovacaoAposAntecipacaoSacado: booleanToRadio(
      estrategia.estrategia.aprovacaoAposAntecipacaoSacado,
    ),
    biometryRequired: booleanToRadio(estrategia.biometryRequired),
    cancelaNaoAntecipadasD0: booleanToRadio(
      estrategia.estrategia.cancelaNaoAntecipadasD0,
    ),
    cessaoOffshore: booleanToRadio(estrategia.estrategia.cessaoOffshore),
    collateralIdentification: estrategia.estrategia.collateralIdentification,
    collateralRequired: booleanToRadio(
      estrategia.estrategia.collateralRequired,
    ),
    comandoOperacao: estrategia.estrategia.comandoOperacao,
    contractTemplateId: estrategia.estrategia.contractTemplateId,
    diasCampanhaNotasNaoAntecipadasCedente:
      estrategia.estrategia.notificacoes?.diasCampanhaNotasNaoAntecipadasCedente?.map(
        String,
      ),
    diasParaVencimentoDataRepasseRiscoCedente:
      estrategia.estrategia.notificacoes?.diasParaVencimentoDataRepasseRiscoCedente?.map(
        String,
      ),
    diasParaVencimentoSacado:
      estrategia.estrategia.notificacoes?.diasParaVencimentoSacado?.map(String),
    eligibilityType: estrategia.estrategia.eligibilityType,
    emailsSacadoNotification:
      estrategia.estrategia.notificacoes?.emailsSacadoNotification,
    exigeAprovacaoSacado: booleanToRadio(
      estrategia.estrategia.exigeAprovacaoSacado,
    ),
    exigeIndicacaoHedge: booleanToRadio(estrategia.exigeIndicacaoHedge),
    fccIdSacado: estrategia.fccIdSacado,
    filesForOperation: estrategia.estrategia.filesForOperation,
    filesForReceivables: estrategia.estrategia.filesForReceivables,
    fundConfig: estrategia.estrategia.fundConfig,
    fundRelatedName: estrategia.estrategia.fundRelatedName,
    toCredit: booleanToRadio(estrategia.toCredit),
    habilitaAntecipacoesParciais: booleanToRadio(
      estrategia.estrategia.habilitaAntecipacoesParciais,
    ),
    habilitaPagFor: booleanToRadio(estrategia.estrategia.habilitaPagFor),
    habilitaPagForBanking: booleanToRadio(
      estrategia.estrategia.habilitaPagForBanking,
    ),
    hasGuarantors: booleanToRadio(estrategia.estrategia.hasGuarantors),
    hasPayerValidation: booleanToRadio(
      estrategia.estrategia.hasPayerValidation,
    ),
    automaticAnticipation: booleanToRadio(
      estrategia.estrategia.automaticAnticipation,
    ),
    hiringContractTemplateId: estrategia.estrategia.hiringContractTemplateId,
    hiringContractType: estrategia.estrategia.hiringContractType,
    horarioLimiteOperacao: estrategia.horarioLimiteOperacao,
    idContaDeRetirada: estrategia.estrategia.idContaDeRetirada,
    interestArrearsBase: estrategia.estrategia.interestArrearsBase,
    interestArrearsRate: estrategia.estrategia.interestArrearsRate,
    latePaymentRate: estrategia.estrategia.latePaymentRate,
    interestIndexCode: estrategia.estrategia.interestIndexCode,
    isLoanAssignment: booleanToRadio(estrategia.estrategia.isLoanAssignment),
    loanAgreementId: estrategia.estrategia.loanAgreementId,
    linkTaggeado: estrategia.portaisAssociados?.map(
      (portal) => portal.portalCode,
    ),
    linkWithBankSlips: booleanToRadio(estrategia.estrategia.linkWithBankSlips),
    marketplaceId: estrategia.marketplaceId,
    portfolioId: estrategia.portfolioId,
    creditorId: estrategia.creditorId,
    needsFilesForOperation: booleanToRadio(
      estrategia.estrategia.needsFilesForOperation,
    ),
    needsFilesForReceivables: booleanToRadio(
      estrategia.estrategia.needsFilesForReceivables,
    ),
    needsRegistrationInfo: booleanToRadio(
      estrategia.estrategia.needsRegistrationInfo,
    ),
    allowAggregateBorderos: booleanToRadio(
      estrategia.estrategia.allowAggregateBorderos,
    ),
    notaDisponivelCedente: booleanToRadio(
      estrategia.estrategia.notificacoes?.notaDisponivelCedente,
    ),
    notificacaoOnboardingSacado: booleanToRadio(
      estrategia.estrategia.notificacoes?.notificacaoOnboardingSacado,
    ),
    notificacaoRecebiveisAntecipados: booleanToRadio(
      estrategia.estrategia.notificacoes?.notificacaoRecebiveisAntecipados,
    ),
    notificacaoTedDevolvidaCedente: booleanToRadio(
      estrategia.estrategia.notificacoes?.notificacaoTedDevolvidaCedente,
    ),
    notificacaoTedDevolvidaSacado: booleanToRadio(
      estrategia.estrategia.notificacoes?.notificacaoTedDevolvidaSacado,
    ),
    penaltyFee: estrategia.estrategia.penaltyFee,
    permiteTaxaParticular: booleanToRadio(
      estrategia.estrategia.permiteTaxaParticular,
    ),
    pnlBook: estrategia.pnlBook,
    pnlStrategy: estrategia.pnlStrategy,
    rebateAlongaPrazo: booleanToRadio(estrategia.estrategia.rebateAlongaPrazo),
    rebateRebarbaSacado: booleanToRadio(
      estrategia.estrategia.rebateRebarbaSacado,
    ),
    rebateTolerance: estrategia.estrategia.rebateTolerance,
    reporteDiarioRiscoCedente: booleanToRadio(
      estrategia.estrategia.notificacoes?.reporteDiarioRiscoCedente,
    ),
    reporteDiarioCCC: booleanToRadio(
      estrategia.estrategia.notificacoes?.reporteDiarioCCC,
    ),
    reporteDiarioSacado: booleanToRadio(
      estrategia.estrategia.notificacoes?.reporteDiarioSacado,
    ),
    reporteNotasNaoAntecipadasCedente: booleanToRadio(
      estrategia.estrategia.notificacoes?.reporteNotasNaoAntecipadasCedente,
    ),
    // requiresPMEAccount: estrategia.requiresPMEAccount,
    riscoOperacao: estrategia.estrategia.riscoOperacao,
    sacadoDefineContaDesembolso: booleanToRadio(
      estrategia.estrategia.sacadoDefineContaDesembolso,
    ),
    sefazValidation: booleanToRadio(estrategia.estrategia.sefazValidation),
    sendErrorCallback: booleanToRadio(estrategia.estrategia.sendErrorCallback),
    tipoContrato: estrategia.estrategia.tipoContrato,
    tipoOnboarding: estrategia.tipoOnboarding,
    tipoTaxaParticular: estrategia.estrategia.tipoTaxaParticular,
    typeFIDC: estrategia.estrategia.typeFIDC,
    visibilidadePrazoVencimentoCedente:
      estrategia.estrategia.visibilidadePrazoVencimentoCedente,
    visualizaRebateSacado: booleanToRadio(
      estrategia.estrategia.visualizaRebateSacado,
    ),
    worksiteEnvConfig: estrategia.estrategia.worksiteEnvConfig,
    gracePeriod: estrategia.gracePeriod,
  };

  return initialValues;
};
