import { OTypography } from "@maestro/react";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { logger } from "utils/logger";
import { DraggableItem } from "./_compose/draggable-item";
import { ReactGridLayoutStyled } from "./draggable-order-layout.styles";
import { Action } from "./draggable-order-layout.types";
import { loadTitles } from "./draggable-order-layout.utils";

const DIVIDER = "divider";

export interface DraggableOrderLayoutProps {
  columns: string[];
  selectedColumns: string[];
  setSelectedColumns: Dispatch<SetStateAction<string[]>>;
  columnLabels: Record<string, string>;
}

export const DraggableOrderLayout = ({
  columns,
  selectedColumns,
  setSelectedColumns,
  columnLabels,
}: DraggableOrderLayoutProps) => {
  const [availableColumns, setAvailableColumns] = useState<string[]>([]);

  useEffect(() => {
    setAvailableColumns(
      columns.filter((value) => !selectedColumns.includes(value)),
    );
  }, [columns, selectedColumns]);

  const layout = useMemo(() => {
    const data: ReactGridLayout.Layout[] = [];

    const activeLayoutItems = selectedColumns.map((value, index) => ({
      i: value,
      x: 0,
      y: index,
      w: 1,
      h: 1,
      isDraggable: true,
    }));

    data.push(...activeLayoutItems);

    const dividerY = activeLayoutItems.length;

    data.push({
      i: DIVIDER,
      x: 0,
      y: dividerY,
      w: 1,
      h: 1,
      isDraggable: false,
    });

    const availableLayoutItems = availableColumns.map((value, index) => ({
      i: value,
      x: 0,
      y: dividerY + 1 + index,
      w: 1,
      h: 1,
      isDraggable: true,
    }));

    data.push(...availableLayoutItems);

    return data;
  }, [selectedColumns, availableColumns]);

  const onDragStop = useCallback(
    (draggedLayout: ReactGridLayout.Layout[]) => {
      try {
        const draggedItems = draggedLayout.sort(
          (current, next) => current.y - next.y,
        );

        const dividerItem = draggedItems.find(({ i }) => i === DIVIDER);

        if (!dividerItem) throw Error("Empty divider item into dragged layout");

        const actives = draggedItems.reduce<string[]>((ids, item) => {
          const { y, i } = item;
          const isUpperThanDivider = y < dividerItem.y;
          if (isUpperThanDivider) return ids.concat(i);
          return ids;
        }, []);

        setSelectedColumns(actives);

        const availables = draggedItems.reduce<string[]>((ids, item) => {
          const { y, i } = item;
          const isLowerThanDivider = y > dividerItem.y;
          if (isLowerThanDivider) return ids.concat(i);
          return ids;
        }, []);

        setAvailableColumns(availables);
      } catch (error) {
        logger.warn(error);
      }
    },
    [setSelectedColumns, setAvailableColumns],
  );

  const handleAction = useCallback(
    (action: Action, column: string) => {
      if (action === Action.Add) {
        setSelectedColumns((prev) => prev.concat(column));
        setAvailableColumns((prev) => prev.filter((id) => id !== column));
      }

      if (action === Action.Remove) {
        setSelectedColumns((prev) => prev.filter((id) => id !== column));
        setAvailableColumns((prev) => prev.concat(column));
      }
    },
    [setSelectedColumns, setAvailableColumns],
  );

  const { selectedTitle, availableTitle } = useMemo(
    () =>
      loadTitles(
        availableColumns.length,
        selectedColumns.length,
        columns.length,
      ),
    [availableColumns.length, selectedColumns.length, columns.length],
  );

  return (
    <>
      <OTypography type="dark" key={selectedTitle}>
        {selectedTitle}
      </OTypography>

      <ReactGridLayoutStyled
        className="layout"
        isResizable={false}
        layout={layout}
        cols={1}
        rowHeight={50}
        width={420}
        margin={[10, 10]}
        containerPadding={[0, 0]}
        onDragStop={onDragStop}
        isDraggable
      >
        {selectedColumns.map((column, index, { length }) => (
          <div
            className="d-flex flex-column gap-2"
            style={{ cursor: "pointer" }}
            key={column}
          >
            <DraggableItem
              handleClick={() => handleAction(Action.Remove, column)}
              showDivider={index < length - 1}
              label={columnLabels[column]}
              icon="orq-remove-circle"
            />
          </div>
        ))}

        <div
          className="d-flex flex-column justify-content-center"
          key={DIVIDER}
        >
          <OTypography type="dark" key={availableTitle}>
            {availableTitle}
          </OTypography>
        </div>

        {availableColumns.map((column, index, { length }) => (
          <div
            className="d-flex flex-column gap-2"
            style={{ cursor: "pointer" }}
            key={column}
          >
            <DraggableItem
              handleClick={() => handleAction(Action.Add, column)}
              showDivider={index < length - 1}
              label={columnLabels[column]}
              icon="orq-add-circle"
            />
          </div>
        ))}
      </ReactGridLayoutStyled>
    </>
  );
};
