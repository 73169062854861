import {
  OCheckbox,
  OLabel,
  ORFieldCheckboxGroup,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearchField } from "components/select-search";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { useWorkflowConfigViewEditConfiguration } from "./workflow-config-view-edit-configuration.hook";

export const WorkflowConfigViewEditConfiguration = () => {
  const {
    defaultGridIdWatch,
    form,
    grids,
    loadingGetGrids,
    loadingGetWorkflowConfigViewById,
    loadingUpdateWorkflowConfigViewDefaultGrid,
    handleSubmit,
    update,
  } = useWorkflowConfigViewEditConfiguration();

  const options = [{ id: 0, name: "Nenhum" }, ...(grids ?? [])]?.map(
    (config) => ({
      value: config.id,
      label: config.name,
    }),
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Configurar visualização" />}
      actions={
        <LoadingButton
          loading={
            loadingUpdateWorkflowConfigViewDefaultGrid ||
            loadingGetWorkflowConfigViewById ||
            loadingGetGrids
          }
          onClick={handleSubmit(update)}
        >
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        noValue
        render={() => (
          <CardTemplate>
            <FormProvider {...form}>
              <ORFieldCheckboxGroup id="isVisible" name="isVisible">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox size="xs" id="isVisibleField" value="true" />
                  <OLabel htmlFor="isVisibleField">É visível ?</OLabel>
                </div>
              </ORFieldCheckboxGroup>

              <SelectSearchField
                id="defaultGridId"
                name="defaultGridId"
                label="Grid padrão"
                options={options}
                key={grids?.map((grid) => grid.id).join("")}
              />
            </FormProvider>

            {!!defaultGridIdWatch && (
              <OTypography className="mt-2">
                {`Colunas: ${grids
                  ?.find((grid) => grid.id === defaultGridIdWatch)
                  ?.gridColumns.map(
                    (gridColumn) => gridColumn.configurableGridColumn.caption,
                  )
                  .join(", ")}`}
              </OTypography>
            )}
          </CardTemplate>
        )}
      />
    </FormTemplate>
  );
};
