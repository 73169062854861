import { OCheckbox, OLabel } from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { UpsertLayoutFormFields } from "../../../../../../upsert-layout.form-schema";

interface CheckboxFieldProps {
  fieldName: keyof UpsertLayoutFormFields;
  label: string;
}

export const CheckboxField = ({ fieldName, label }: CheckboxFieldProps) => {
  const { setValue, watch } = useFormContext<UpsertLayoutFormFields>();
  const fieldValueWatched = watch(fieldName);

  return (
    <div className="d-flex align-items-center gap-2">
      <OCheckbox
        id={fieldName}
        name={fieldName}
        value={fieldName}
        checked={!!fieldValueWatched}
        onClick={() => setValue(fieldName, !fieldValueWatched)}
        size="sm"
      />
      <OLabel htmlFor={fieldName}>{label}</OLabel>
    </div>
  );
};
