import { OToastManager, modalManager } from "@maestro/react";
import { useCallback, useState } from "react";
import { service } from "services";
import { useCollectionBeneficiaryContext } from "../../../../collection-beneficiary.context";
import {
  FloatingSettingsModalFormFields,
  FloatingSettingsModalId,
} from "./floating-settings-modal.types";

export const useUpsertFloating = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { getBeneficiary } = useCollectionBeneficiaryContext();

  const upsertFloating = useCallback(
    async (
      formValues: FloatingSettingsModalFormFields,
      isCreating: boolean,
    ) => {
      try {
        setIsLoading(true);
        if (isCreating) {
          await service.adminBankinghub.collection.createFloating(formValues);
        } else {
          await service.adminBankinghub.collection.updateFloating(formValues);
        }
        OToastManager.success(
          `Floating ${isCreating ? "cadastrado" : "atualizado"} com sucesso`,
        );
        modalManager.hide(FloatingSettingsModalId);
        getBeneficiary();
      } catch {
        OToastManager.danger(
          `Ocorreu um erro para ${
            isCreating ? "cadastrar" : "atualizar"
          } o Floating do beneficiário.`,
        );
      } finally {
        setIsLoading(false);
      }
    },
    [getBeneficiary],
  );

  return { upsertFloating, isLoading };
};
