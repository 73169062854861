import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services/service";

export const useAddressFields = (
  fieldName: "addresses" | `representatives.${number}.addresses`,
  index: number,
) => {
  const [hasFoundAddress, setHasFoundAddress] = useState(true);

  const { callService, loading: loadingAddress } = useServiceCall(
    service.onboarding.getAddress,
  );

  const form = useFormContext<ScfDevelopers.UpdateCedenteCompleto>();
  const { getValues, setValue, watch } = form;

  const zipCodeWatch = watch(`${fieldName}.${index}.zipCode`);

  const getAddressFromZipCode = useCallback(async () => {
    if (!(zipCodeWatch && zipCodeWatch.length === 8)) {
      setValue(`${fieldName}.${index}.cityName`, "");
      setValue(`${fieldName}.${index}.countryCode`, "");
      setValue(`${fieldName}.${index}.neighborhood`, "");
      setValue(`${fieldName}.${index}.stateCode`, "");
      setValue(`${fieldName}.${index}.street`, "");
      return;
    }
    const { success, response } = await callService({ zipCode: zipCodeWatch });

    if (success) {
      const phoneNumber = getValues(`${fieldName}.${index}.phoneNumber`);
      const contactName = getValues(`${fieldName}.${index}.contactName`);
      const complement = getValues(`${fieldName}.${index}.complement`);
      const number = getValues(`${fieldName}.${index}.number`);

      const newAddress: ScfDevelopers.CedenteCompletoAddress = {
        phoneNumber,
        contactName,
        cityName: response.data.cityName.toUpperCase(),
        complement,
        countryCode: "BR",
        neighborhood: response.data.neighborhood.toUpperCase(),
        number,
        stateCode: response.data.stateCode.toUpperCase(),
        street: response.data.streetName.toUpperCase(),
        zipCode: zipCodeWatch,
        countryName: "BRASIL",
        stateName: response.data.stateName.toUpperCase(),
        type: "COMERCIAL",
        cityCode: response.data.cityCode.toUpperCase(),
      };

      setValue(`${fieldName}.${index}`, newAddress);
    } else {
      setHasFoundAddress(false);
      OToastManager.danger("Erro ao buscar o CEP");
    }
  }, [callService, fieldName, getValues, index, setValue, zipCodeWatch]);

  useEffect(() => {
    getAddressFromZipCode();
  }, [getAddressFromZipCode]);

  return {
    hasFoundAddress,
    loadingAddress,
  };
};
