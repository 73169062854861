import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { service } from "services/service";
import { getValueFromMap } from "utils/get-value-from-map";
import { documentSubtypeByDocumentMap } from "../../../../edit-cedente-completo.utils";

export const useDocumentField = (
  documentsWithError: number[],
  index: number,
) => {
  const [loading, setLoading] = useState(false);

  const form = useFormContext<ScfDevelopers.UpdateCedenteCompleto>();
  const {
    control,
    formState: { errors },
    getValues,
    register,
    watch,
  } = form;

  const watchDocumentType = watch(`documents.${index}.type`);

  const possibleDocumentSubtypes = useMemo(
    () => getValueFromMap(documentSubtypeByDocumentMap, watchDocumentType),
    [watchDocumentType],
  );

  const documentSubtypeError = errors?.documents?.[index]?.subType;

  const { append, fields, remove } = useFieldArray({
    control,
    name: `documents.${index}.files`,
  });

  const fileError = useMemo(() => {
    const documentsErrors = errors.documents;
    if (!documentsErrors) {
      return undefined;
    }

    const documentErrorMessage = documentsErrors[index]?.message;

    const fileErrorMessage = documentsErrors[index]?.files?.message;

    const genericFileErrorMessage = documentsErrors[index]?.files?.[0]
      ? "Há erro em algum arquivo anexado, abra o modal para ver mais detalhes"
      : undefined;

    return (
      documentErrorMessage ??
      fileErrorMessage ??
      genericFileErrorMessage ??
      undefined
    );
  }, [errors.documents, index]);

  const handleAddFile = useCallback(
    async (file: File) => {
      const [name, extension] = file.name.split(".");

      if (!name || !extension) {
        return;
      }

      const formData = new FormData();
      formData.set("image", file);

      setLoading(true);

      try {
        const { data } = await service.scfDevelopers.fileUpload(formData);

        append({
          name,
          pageType: "COMPLETO",
          url: data,
          extension,
        });
      } catch {
        OToastManager.danger(
          "Erro ao adicionar arquivo. Tente novamente mais tarde",
        );
      } finally {
        setLoading(false);
      }
    },
    [append],
  );

  const handleRemoveFile = (fileIndex: number) => {
    const file = getValues(`documents.${index}.files.${fileIndex}`);

    if (!file) {
      OToastManager.danger("Erro ao remover documento");
      return;
    }

    remove(fileIndex);
  };

  useEffect(() => {
    if (documentsWithError.includes(index)) {
      remove(index);
    }
  }, [documentsWithError, index, remove]);

  return {
    documentSubtypeError,
    fields,
    fileError,
    handleAddFile,
    handleRemoveFile,
    loading,
    possibleDocumentSubtypes,
    register,
  };
};
