import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetApiAdminLoanByIdDetailsResponse } from "services/hubloan/models";
import { format } from "utils/date";
import { parseInstallmentStatusType } from "./operations-installments.utils";

type HubLoanInstallmentItem = NonNullable<
  NonNullable<GetApiAdminLoanByIdDetailsResponse["settlement"]>["installments"]
>[number];

const loanInstallmentFrequencyMap: Record<string, string> = {
  MNTH: "Mensal",
  YEAR: "Anual",
  MTRY: "Bullet",
  BNTH: "Bimestral",
  QURT: "Trimestral",
  QUDR: "Quadrimestral",
  MIAN: "Semestral",
  NOT_SET: "-",
};

export const OperationsInstallmentFromHubLoanGrid = (
  installments: HubLoanInstallmentItem[],
): ODataGridGeneratorConfig<HubLoanInstallmentItem> => {
  return {
    datagrid: {
      noDataText: "Nenhuma parcela encontrada.",
      pager: {
        showInfo: true,
        infoText: "Página {0} de {1} ({2} parcelas)",
      },
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        dataField: "code",
        caption: "Parcela",
        width: "100",
        cellRender: ({ data }) => {
          const frequencyInstallments = installments.filter(
            (i) => i.frequencyCode === data.frequencyCode,
          ).length;

          return `${data.code}/${frequencyInstallments}`;
        },
      },
      {
        dataField: "frequencyCode",
        caption: "Tipo",
        alignment: "left",
        visible: installments.some((i) => i.frequencyCode !== "NOT_SET"),
        cellRender: ({ data }) =>
          loanInstallmentFrequencyMap[data.frequencyCode],
      },
      {
        dataField: "maturityDate",
        sortOrder: "desc",
        caption: "Data de vencimento",
        alignment: "left",
        cellRender: ({ data }) => format.date(data.maturityDate.toString()),
      },
      {
        dataField: "amount",
        caption: "Valor no vencimento",
        alignment: "left",
        cellRender: ({ data }) => masks.currency(data.amount, "R$", "."),
      },
      {
        dataField: "status",
        caption: "Status",
        alignment: "left",
        cellRender: ({ data }) => parseInstallmentStatusType[data.status],
      },
    ],
  } satisfies ODataGridGeneratorConfig<HubLoanInstallmentItem>;
};
