import { OCard, OTypography, OButton, OLink, OLoader } from "@maestro/react";
// eslint-disable-next-line no-restricted-imports
import { DetailsTemplate } from "templates/details-template";
import { PageTitle } from "components/page-title";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { buildCustomerLink } from "utils/build-customer-link";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useBehavior } from "contexts/behavior";
import { Behaviors } from "contexts/behavior/types";
import dayjs from "dayjs";
import { MoneyMask } from "../../../../cartoes/pages/legacy/utils";
import { companyDetailsGrid, dataSource } from "./company-details.grid";
import { Currency } from "../../../../cartoes/pages/legacy/cards/cards.types";
import { useCompanyDetails } from "./company-details.hook";

export enum TipoConta {
  contaGarantia = "Conta de garantia",
  contaTransacional = "Conta transacional",
}

export const CompanyDetails = () => {
  const navigate = useNavigate();
  const gridRef = useGridRef();
  const { behavior } = useBehavior();

  const {
    antecipacaoAutomaticaLigada,
    cedenteDeflator,
    cedenteSacadosInfo,
    contaGarantia,
    contaLiquidacao,
    contaTransacional,
    detail,
    cartaoProduct,
    limitsCedente,
    onboardingInfo,
    submit,
    vnpDays,
    customerInfo,
    redisponibilizarRecebiveis,
    vnpSummary,
    isProcessingReceivables
  } = useCompanyDetails();

  const valorEmAtraso = useMemo(() => {
    if (vnpSummary) {
      return (
        <>
          <li>
            <OTypography type="danger">{`Valor em atraso: ${MoneyMask(
              vnpSummary.posicaoVnpEJurosSum,
              Currency.BRL,
            )}`}</OTypography>
          </li>
          <li>{`Valor em aberto hoje: ${MoneyMask(
            vnpSummary.posicaoVencendoHojeNaoLiquidadaSum,
            Currency.BRL,
          )}`}</li>
          <li>{`Estado VNP: ${vnpSummary.estadoVnpEmpresa}`}</li>
        </>
      );
    }
  }, [vnpSummary]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes da empresa" />}>
      <OCard className="d-flex flex-row mb-4">
        <OCard className="d-flex p-4">
          <OTypography size="lg" className="mb-4">
            Configurações
          </OTypography>
          <ul style={{ listStyleType: "none" }}>
            <li
              style={{ color: "var(--theme-success)" }}
            >{`Onboarding: ${onboardingInfo.status} ${onboardingInfo.data}`}</li>
            <li style={{ color: "var(--theme-success)" }}>{`Conta: ${
              customerInfo?.btgAccounts.find(
                (acc) => acc.branch === "1" && acc.type === "ESCROW",
              )?.statusDescription || "Conta não encontrada"
            }`}</li>
            <li
              style={{
                color:
                  detail?.statusRFB === "ATIVA"
                    ? "var(--theme-success)"
                    : "var(--theme-danger)",
              }}
            >
              {`Status RFB: ${detail?.statusRFB || "Status não disponível"}`}
            </li>
            <li
              style={{
                color:
                  cartaoProduct?.eligibilityStatus === "APROVADO"
                    ? "var(--theme-success)"
                    : "var(--theme-danger)",
              }}
            >
              {`Elegibilidade: ${
                cartaoProduct?.eligibilityStatus ||
                "Elegibilidade não analisada"
              }
                ${
                  cartaoProduct?.updatedAt
                    ? ` (Última atualização: ${dayjs(
                        cartaoProduct.updatedAt,
                      ).format("DD/MM/YYYY HH:mm")})`
                    : ""
                }`}
            </li>
            <li>{`Deflator: ${
              Number(cedenteDeflator?.deflation_value) * 100
            } %`}</li>
            <li>
              {"Limite de operação: "}
              <ul style={{ listStyleType: "none", marginLeft: "5%" }}>
                <li>
                  {`Consumido: ${MoneyMask(limitsCedente.consumedAmount)}`}
                </li>
                <li>
                  {`Disponível: ${MoneyMask(limitsCedente.availableAmount)}`}
                </li>
                <li>{`Total: ${MoneyMask(limitsCedente.totalAmount)}`}</li>
              </ul>
            </li>
            <li
              style={{
                color:
                  vnpDays > 0
                    ? "var(--theme-danger)"
                    : vnpDays === 0
                    ? "var(--theme-success)"
                    : "",
              }}
            >
              {`Adimplência: ${
                vnpDays === 0
                  ? "em dia"
                  : vnpDays === 1
                  ? "em atraso " + vnpDays + " dia"
                  : "em atraso " + vnpDays + " dias"
              }`}
            </li>
            {valorEmAtraso}
            <li>
              Saldo em conta:
              <ul style={{ listStyleType: "none", marginLeft: "5%" }}>
                <li>{`Ag 1 - ${MoneyMask(contaGarantia.balance)}`}</li>
                <li>{`Ag 50 - ${MoneyMask(contaTransacional.balance)}`}</li>
              </ul>
            </li>
            <li>{`Conta de liquidação: ${
              contaLiquidacao
                ? "208-1-" + contaLiquidacao
                : "Sem conta de liquidação"
            }`}</li>
            <li>{`Automática ligada?: ${
              antecipacaoAutomaticaLigada ? "Sim" : "Não"
            }`}</li>
          </ul>
        </OCard>
        <OCard className="d-flex p-4">
          <OTypography size="lg" className="mb-4">
            Agenda
          </OTypography>
          <OCard className="d-flex flex-grow-1" style={{ border: 0 }}>
            <OCard className="d-flex flex-grow-1" style={{ border: 0 }}>
              <table>
                <tbody>
                  <tr>
                    <td>Sacado</td>
                    <td>Valor bruto disponível</td>
                    <td>Valor líquido disponível</td>
                    <td>Taxa média ponderada</td>
                  </tr>
                  {cedenteSacadosInfo.map((sacado) => (
                    <tr key={sacado.sacado?.identification}>
                      <td>
                        <OTypography size="md">
                          {sacado.sacado?.name.split(" ")[0]}
                        </OTypography>
                      </td>

                      <td>
                        <OTypography>
                          {MoneyMask(sacado.valorVencimento)}
                        </OTypography>
                      </td>

                      <td>
                        <OTypography>
                          {MoneyMask(sacado.valorDesembolso)}
                        </OTypography>
                      </td>

                      <td>
                        <OTypography>
                          {(sacado.taxaMediaPonderada * 100).toFixed(4)} %
                        </OTypography>
                      </td>
                      {behavior.id !== Behaviors.Corban && (
                        <td>
                          <OLink
                            type="info"
                            href={buildCustomerLink(
                              sacado.sacado?.identification || "",
                              corporateRouter.routes.recebiveis.customer
                                .cedentes.path,
                            )}
                          >
                            Taxas
                          </OLink>
                        </td>
                      )}
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <OTypography>Total</OTypography>
                    </td>

                    <td>
                      <OTypography>
                        {MoneyMask(
                          cedenteSacadosInfo.reduce(
                            (acc, curr) => acc + curr.valorVencimento,
                            0,
                          ),
                        )}
                      </OTypography>
                    </td>

                    <td>
                      <OTypography>
                        {MoneyMask(
                          cedenteSacadosInfo.reduce(
                            (acc, curr) => acc + curr.valorDesembolso,
                            0,
                          ),
                        )}
                      </OTypography>
                    </td>

                    <td>
                      <OTypography>
                        {cedenteSacadosInfo.length
                          ? (
                              cedenteSacadosInfo.reduce(
                                (acc, curr) =>
                                  acc + curr.taxaMediaPonderada * 100,
                                0,
                              ) / cedenteSacadosInfo.length
                            ).toFixed(4)
                          : 0}
                        %
                      </OTypography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </OCard>
            <OCard className="d-flex flex-row gap-2" style={{ border: 0 }}>
              {behavior.id !== Behaviors.Corban && (
                <OButton onClick={submit}>Enviar e-mail cotação</OButton>
              )}
              {behavior.id !== Behaviors.Corban && (
                <>
                  <OButton onClick={redisponibilizarRecebiveis} disabled={isProcessingReceivables}>
                    <span className="d-flex align-items-center gap-2">
                      {isProcessingReceivables ? (
                        <><OLoader size="xs" />Reprocessando recebíveis</>
                      ) : (
                        <>Reprocessar recebíveis</>
                      )}
                    </span>
                  </OButton>

                  <OButton
                    onClick={() =>
                      navigate(
                        corporateRouter.routes.antecipacaoDeCartoes.product
                          .intubateOperations.file.path,
                      )
                    }
                  >
                    Operação Entuba
                  </OButton>
                </>
              )}
              <OButton
                onClick={() =>
                  navigate(
                    corporateRouter.routes.antecipacaoDeCartoes.customer
                      .simulate.path,
                  )
                }
              >
                Simular Cotação
              </OButton>
            </OCard>
          </OCard>
        </OCard>
      </OCard>
      <GridTemplate
        gridRef={gridRef}
        showClearFiltersButton
        showRefreshButton
        showExportButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={companyDetailsGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
    </DetailsTemplate>
  );
};
