import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { isAxiosError } from "axios";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { service } from "services/service";
import {
  editCedenteCompletoValidationSchema,
  editeCedenteCompletoDefaultValues,
} from "./edit-cedente-completo.form";

export const useEditCedenteCompleto = () => {
  const [searchParams, _] = useSearchParams();

  const taxId = searchParams.get("taxId");

  if (!taxId) throw new Error("No taxId");

  const [documentsWithError, setDocumentsWithError] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const [ocurrences, setOcurrences] =
    useState<ScfDevelopers.CedenteCompletoRejection>();
  const [representatives, setRepresentatives] =
    useState<ScfDevelopers.CedenteCompletoRepresentative[]>();

  const form = useForm<ScfDevelopers.UpdateCedenteCompleto>({
    defaultValues: editeCedenteCompletoDefaultValues,
    resolver: yupResolver(editCedenteCompletoValidationSchema),
  });
  const { getValues, handleSubmit, setValue } = form;

  const canSubmit = getValues("status") === "PENDENTE_AJUSTE";

  const submit = handleSubmit(async (values) => {
    setLoading(true);
    try {
      const payload: ScfDevelopers.UpdateCedenteCompleto = {
        ...values,
        addresses: values.addresses.map((address) => ({
          ...address,
          phoneNumber:
            address.phoneNumber?.startsWith("55") ||
            address.phoneNumber?.startsWith("+55")
              ? address.phoneNumber
              : "55" + address.phoneNumber,
        })),
        phones: values.phones.map((phone) => ({
          number:
            phone.number.startsWith("55") || phone.number.startsWith("+55")
              ? phone.number
              : "55" + phone.number,
          type: phone.type,
        })),
        documents: values.documents.map((document) => ({
          type: document.type,
          subType: document.subType === "" ? null : document.subType,
          identificationNumber: document.identificationNumber,
          files: document.files,
        })),
        representatives: values.representatives.map((representative) => ({
          taxId: representative.taxId,
          name: representative.name,
          addresses: representative.addresses,
          phones: representative.phones,
          emails: representative.emails,
          document: {
            type: representative.document.type,
            subType:
              representative.document.subType === ""
                ? null
                : representative.document.subType,
            identificationNumber: representative.document.identificationNumber,
            files: representative.document.files,
          },
        })),
      };

      await service.scfDevelopers.updateCedenteCompleto(payload);
      OToastManager.success("Cedente completo atualizado com sucesso!");
    } catch (err) {
      if (isAxiosError<ScfDevelopers.VerifyError>(err)) {
        err.response?.data?.failures?.forEach((failure) => {
          failure.errorMessage && OToastManager.danger(failure.errorMessage);
        });
      } else {
        OToastManager.danger("Falha ao atualizar cedente completo");
      }
    } finally {
      setLoading(false);
    }
  });

  const getCedenteCompleto = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await service.scfDevelopers.fetchCedenteCompletoInfo(
        taxId,
      );

      const newAddresses: ScfDevelopers.CedenteCompletoAddress[] =
        data.addresses.map((address) => ({
          cityName: address.cityName?.toUpperCase() ?? null,
          complement: address.complement,
          countryCode: "BR",
          neighborhood: address.neighborhood.toUpperCase(),
          number: address.number,
          stateCode: address.stateCode?.toUpperCase() ?? null,
          street: address.street?.toUpperCase(),
          zipCode: address.zipCode,
          countryName: "BRASIL",
          stateName: address.stateName?.toUpperCase() ?? null,
          type: "COMERCIAL" as ScfDevelopers.AddressType,
          phoneNumber: address.phoneNumber,
          contactName: address.contactName?.toUpperCase() ?? null,
          cityCode: address.cityCode?.toUpperCase() ?? null,
        }));

      setValue("authClientId", data.authClientId);
      setValue("taxId", data.taxId);
      setValue("name", data.name);
      setValue("payerName", data.payerName);
      setValue("payerTaxId", data.payerTaxId);
      setValue("status", data.status);
      setValue("birthDate", dayjs(data.birthDate).format("DD/MM/YYYY"));
      setValue("phones", data.phones);
      setValue("addresses", newAddresses);
      setValue("emails", data.emails);
      setValue("documents", data.documents);
      setValue("workflowConfigurationId", data.workflowConfigurationId);

      setRepresentatives(data.representatives);
      setOcurrences(data.rejection);
    } catch {
      OToastManager.danger("Erro ao obter cedente completo");
    } finally {
      setLoading(false);
    }
  }, [setValue, taxId]);

  useEffect(() => {
    getCedenteCompleto();
  }, [getCedenteCompleto]);

  return {
    canSubmit,
    documentsWithError,
    form,
    loading,
    ocurrences,
    representatives,
    setDocumentsWithError,
    submit,
  };
};
