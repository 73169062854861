import { OModal, OModalBody, OModalHeader, OTypography } from "@maestro/react";
import { CustomPagination } from "components/custom-pagination";
import { ODataGridGenerator } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { useGridRef } from "hooks/grid-ref";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MoanaOwnerReceivable } from "services/moana/models";
import { GridTemplate } from "templates/grid-template";
import { useWalletDetails } from "../../../../../components/wallet-details/wallet-details.hook";
import { ownGrid } from "./own-wallet-management.grid";
import { ownWalletModal } from "./portfolio-mangament.utils";

interface OwnWalletModalProps {
  ranges?: number[] | null;
  documentNumber: string;
  totalItems: number;
}

export const OwnWalletModal = ({
  ranges,
  documentNumber,
  totalItems,
}: OwnWalletModalProps) => {
  const gridRef = useGridRef();
  const { getOwnWallet, rows, setRows, page, setPage, exportOwnWallet } =
    useWalletDetails(documentNumber);

  const [dataSource, setDataSource] = useState<MoanaOwnerReceivable[]>([]);

  const memoizedGrid = useMemo(
    () => (
      <ODataGridGenerator
        grid={ownGrid(rows)}
        gridRef={gridRef}
        dataSource={dataSource}
      />
    ),
    [gridRef, dataSource, rows],
  );

  const fetchOwnPortfolio = useCallback(async () => {
    const response = await getOwnWallet(page, rows, ranges);
    setDataSource(response);
  }, [getOwnWallet, page, rows, ranges]);

  useEffect(() => {
    fetchOwnPortfolio();
  }, [fetchOwnPortfolio]);

  return (
    <OModal id={ownWalletModal} size="lg" position="right">
      <OModalHeader>
        <OTypography size="lg">Detalhes da carteira própria</OTypography>
      </OModalHeader>
      <OModalBody>
        <GridTemplate
          gridRef={gridRef}
          actions={
            <IconButton
              icon={{ category: "far", icon: "fa-file-spreadsheet" }}
              style={{ width: "16px", marginRight: "30px" }}
              onClick={() => exportOwnWallet(ranges)}
              type="default"
              outline
            />
          }
        >
          {memoizedGrid}
          <CustomPagination
            totalCount={totalItems}
            currentPage={page}
            setCurrentPage={setPage}
            pagination={[10, 20, 30]}
            selectedPaginationSize={rows}
            setSelectedPaginationSize={setRows}
          />
        </GridTemplate>
      </OModalBody>
    </OModal>
  );
};
