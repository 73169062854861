import { OButton, OTypography } from "@maestro/react";
import { useNavigate } from "react-router";
import { PageTitle } from "components/page-title";
import { LandingPageProps } from "./landing-page.type";
import { Tips } from "../tips/tips.component";
import { bannerMap } from "../banner/banner.utils";

export const LandingPage = ({
  setLandingPage,
  landingPage,
  navigateBack,
}: LandingPageProps) => {
  const navigate = useNavigate();

  const { landingPageInfo } = bannerMap[landingPage];

  return (
    <div>
      <PageTitle
        title={landingPageInfo?.title}
        description={landingPageInfo?.description}
      />
      <div className="d-flex flex-column gap-5 align-items-start">
        <Tips
          title={landingPageInfo?.tips?.title}
          tipsList={landingPageInfo?.tips?.list}
        />
        <OButton
          outline
          onClick={() => (navigateBack ? navigate(-1) : setLandingPage?.(null))}
        >
          <OTypography type="dark">Voltar</OTypography>
        </OButton>
      </div>
    </div>
  );
};
