import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import dayjs from "dayjs";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { profileManagementRequestsStatusMap } from "../gerenciamento-de-perfis/profiles-management.utils";

export const adminProfilesRequestsGrid = {
  datagrid: {
    noDataText: "Nenhuma solicitação encontrada",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    remoteOperations: true,
  },
  columns: [
    {
      cellRender: ({ data }) =>
        data.status !== "CANCELADO" && (
          <DetailsLink
            href={corporateRouter.routes.ferramentas.product.gatekeeper.adminProfilesManagement.details.path(
              {
                requestId: data.id,
              },
            )}
          />
        ),
    },
    { dataField: "id" },
    { dataField: "user_name", caption: "Solicitante" },
    { dataField: "title", caption: "Titulo" },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ data }) =>
        getValueFromMap(profileManagementRequestsStatusMap, data.status) ??
        data.status,
    },
    {
      dataField: "updated_at",
      caption: "Última atualização",
      sortOrder: "desc",
      dataType: "datetime",
      customizeText: ({ value }) =>
        value
          ? dayjs(value).subtract(3, "hours").format("DD/MM/YYYY, HH:mm:ss")
          : "-",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.gatekeeper.getAdminProfileManagementRequests>
  >["data"]["data"][number]
>;
