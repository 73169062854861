import { OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { Trade } from "./trades.types";
import { warnLowBalance } from "./trades.utils";
import { MoneyMask } from "../../../../../cartoes/pages/legacy/utils";

export const dataSourceClient = (customer?: string) =>
  dataSourceCustomStoreGenerator<Trade>(
    (loadOptions) =>
      service.recebiveisCartao
        .getTrades(loadOptions as any)
        .then(({ data }) => ({
          data: data.data,
          totalCount: data.total_count,
        }))
        .catch(() => {
          const errorMessage = "Erro ao buscar trades";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    {
      dataSourceOptions: {
        filter: customer
          ? ["company_tax_id", "contains", customer.slice(0, 8)]
          : ["company_tax_id", "contains", ""],
      },
    },
  );

export const tradesColumnsClient: ODataGridGeneratorConfig<Trade>["columns"] = [
  {
    dataField: "agenda_date",
    dataType: "date",
    format: "shortDate",
    caption: "Data da agenda",
    allowHeaderFiltering: false,
  },
  {
    dataField: "type",
    dataType: "string",
    caption: "Tipo",
  },
  {
    dataField: "status",
    dataType: "string",
    caption: "Status",
  },
  {
    dataField: "maturity_amount",
    dataType: "string",
    caption: "Valor",
    format: (value) => MoneyMask(value),
    allowHeaderFiltering: false,
  },
  {
    dataField: "price",
    dataType: "string",
    caption: "Preço",
    format: (value) => MoneyMask(value),
    allowHeaderFiltering: false,
  },
  {
    dataField: "balance",
    dataType: "string",
    caption: "Posição",
    format: (value) => MoneyMask(value),
    allowHeaderFiltering: false,
  },
  {
    dataField: "aditado",
    dataType: "boolean",
    caption: "Aditado",
  },
  {
    dataField: "agenda_bandeira",
    dataType: "string",
    caption: "Bandeira",
  },
  {
    dataField: "maturity_date",
    dataType: "date",
    format: "shortDate",
    caption: "Vencimento",
    allowHeaderFiltering: false,
  },
  {
    dataField: "credenciadora_name",
    dataType: "string",
    caption: "Credenciadora",
    allowHeaderFiltering: false,
  },
  {
    dataField: "credenciadora_tax_id",
    dataType: "string",
    caption: "CNPJ (Cred.)",
    format: (value) => masks.cnpj(value),
    calculateFilterExpression: calculateTaxIdFilterExpression,
    allowHeaderFiltering: false,
  },
  {
    dataField: "company_name",
    dataType: "string",
    caption: "Cedente",
    allowHeaderFiltering: false,
  },
  {
    dataField: "company_tax_id",
    dataType: "string",
    caption: "CNPJ (Cedente)",
    format: (value) => masks.cnpj(value),
    calculateFilterExpression: calculateTaxIdFilterExpression,
    allowHeaderFiltering: false,
  },
  {
    dataField: "created_at",
    dataType: "datetime",
    format: "shortDateShortTime",
    caption: "Criado em",
    allowHeaderFiltering: false,
  },
  {
    dataField: "receivable_status",
    dataType: "string",
    caption: "Estado do Recebível",
    allowHeaderFiltering: false,
  },
  {
    dataField: "id",
    dataType: "number",
    caption: "ID",
    allowHeaderFiltering: false,
  },
  {
    dataField: "parent_id",
    dataType: "number",
    caption: "ID do trade de origem",
    allowHeaderFiltering: false,
  },
];

export const tradesGridClient: ODataGridGeneratorConfig<Trade> = {
  datagrid: {
    noDataText: "Nenhum trade",
    pager: { showPageSizeSelector: true },
    headerFilter: { visible: true },
    filterRow: { visible: true },
    remoteOperations: true,
    onCellPrepared: warnLowBalance,
  },
  columns: tradesColumnsClient,
};
