import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

export const useErrorsList = (
  setDocumentsWithError: React.Dispatch<React.SetStateAction<number[]>>,
  rejections?: ScfDevelopers.CedenteCompletoRejection,
) => {
  const [alreadyFilled, setAlreadyFilled] = useState(false);

  const form = useFormContext();
  const {
    formState: { errors },
    getValues,
    setError,
  } = form;

  const documents: ScfDevelopers.CedenteCompletoDocument[] =
    getValues("documents");

  const genericOcurrences: string[] = useMemo(
    () => rejections?.rejectionReasons ?? [],
    [rejections],
  );

  const documentOcurrences: string[] = useMemo(() => [], []);
  useEffect(() => {
    if (alreadyFilled) {
      return;
    }

    if (
      rejections?.documentRejectionReasons &&
      rejections.documentRejectionReasons.length > 0
    ) {
      setAlreadyFilled(true);
    }

    rejections?.documentRejectionReasons.forEach((documentOcurrence) => {
      documentOcurrence.files.forEach((fileOcurrence) => {
        documentOcurrences.push(
          `${documentOcurrence.documentType}: ${fileOcurrence.message}`,
        );
        const documentIndex = documents.findIndex(
          (document) => document.type === documentOcurrence.documentType,
        );
        if (documentIndex > -1 && !errors[`documents.${documentIndex}`]) {
          setDocumentsWithError((oldList) => [...oldList, documentIndex]);
          setError(`documents.${documentIndex}`, {
            type: "custom",
            message: fileOcurrence.message,
          });
        }
      });
    });
  }, [
    alreadyFilled,
    documentOcurrences,
    documents,
    errors,
    rejections,
    setError,
    getValues,
    setDocumentsWithError,
  ]);

  const ocurrences = [...genericOcurrences, ...documentOcurrences];

  return {
    ocurrences,
  };
};
