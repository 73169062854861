import type { Customer } from "contexts/customer";
import { corporateRouter } from "routes/corporate-router.context";
import { maskIdentification } from "utils/mask";
import type { CustomerSelectorItem } from "../customer-selector.types";
import { filterOptions, validateOption } from "./filter.utils";

/** Deep compare of 2 CustomerSelectorItem */
export const compareItems = (
  item1: CustomerSelectorItem,
  item2: CustomerSelectorItem,
) =>
  item1.identification === item2.identification &&
  item1.officialName === item2.officialName &&
  item1.type === item2.type;

/** Given an array of CustomerSelectorItem, returns the array without duplicates */
export const filterDuplicatedItems = (items: CustomerSelectorItem[]) =>
  items.reduce((unique, item) => {
    if (!unique.some((uniqueItem) => compareItems(uniqueItem, item))) {
      unique.push(item);
    }
    return unique;
  }, [] as CustomerSelectorItem[]);

/**
 * Helper to sort a CustomerSelectorItem array.
 * The rules are:
 * - Favorites come first
 * - Customers before users
 */
const calculateSortValue = (item: CustomerSelectorItem) => {
  let value = 0;
  if (item.isFavorite) value += 16;
  if (item.type === "costumer") value += 8;
  return value;
};

/** Sorts and returns the sorted CustomerSelectorItem array */
const sortOptions = (options: CustomerSelectorItem[]) => {
  options.sort((item1, item2) => {
    const value1 = calculateSortValue(item1);
    const value2 = calculateSortValue(item2);

    // put items without name at the end
    const name1 = item1.officialName ?? "\uFFFF";
    const name2 = item1.officialName ?? "\uFFFF";

    return value2 - value1 - name2.localeCompare(name1);
  });
  return options;
};

export const loadOptions = (
  options: CustomerSelectorItem[],
  mode: "fetch" | "select",
  filter?: string,
) => {
  if (mode === "select") {
    return sortOptions(filterOptions(options, filter));
  }

  const filteredOptions = options.reduce<CustomerSelectorItem[]>(
    (unique, item) => {
      if (!unique.some((uniqueItem) => compareItems(uniqueItem, item))) {
        if (options.filter((option) => compareItems(item, option)).length > 1) {
          unique.push({ ...item, isFavorite: true });
        } else if (!item.isFavorite || validateOption(item, filter)) {
          unique.push(item);
        }
      }
      return unique;
    },
    [],
  );

  return sortOptions(filteredOptions);
};

export const displayValueFromCustomer = (customer: Customer["value"]) =>
  `${maskIdentification(customer?.identification)} - ${customer?.officialName}`;

export const buildUserLink = (identification: string) =>
  corporateRouter.routes.usuarios.product.b2c.user.details.path({
    identification,
  });
