import { OSkeletonCard, OTypography } from "@maestro/react";
import { StatusBadge } from "components/status-badge";
import { useMemo } from "react";
import { useCollectionBeneficiaryContext } from "../../../../collection-beneficiary.context";
import { MapperBeneficiaryStatusBadge } from "./beneficiary-status-badge.utils";

export const BeneficiaryStatusBadge = () => {
  const { beneficiary, errorMessage, isLoading } =
    useCollectionBeneficiaryContext();

  const statusBadgeProps = useMemo(() => {
    if (!beneficiary?.status) return;
    return MapperBeneficiaryStatusBadge[beneficiary.status];
  }, [beneficiary?.status]);

  if (isLoading) return <OSkeletonCard height="1rem" width="10rem" rounded />;

  if (statusBadgeProps) {
    return (
      <StatusBadge type={statusBadgeProps.type} key={statusBadgeProps.label}>
        {statusBadgeProps.label}
      </StatusBadge>
    );
  }

  return (
    <OTypography type="dark-80" key={errorMessage}>
      {errorMessage}
    </OTypography>
  );
};
