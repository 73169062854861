import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { StandardLink } from "components/standard-link";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { ShowConfigurationsModal } from "../../../../components/show-configurations-modal";
import {
  dataSource,
  partnerConfigurationGrid,
} from "./domain-configuration-scf.grid";

export const DomainConfigsScf = () => {
  const gridRef = useGridRef();

  const [configurations, setConfigurations] =
    useState<ScfDevelopers.Configuration[]>();

  const grid = useMemo(() => {
    return partnerConfigurationGrid(setConfigurations);
  }, []);

  return (
    <>
      <PageTitle title="Listagem de domínios do hubonboarding scf" />

      <GridTemplate
        actions={
          <StandardLink
            href={
              corporateRouter.routes.ferramentas.product.onboarding
                .domainConfigScf.partnerConfiguration.add.path
            }
            role={
              roles.ferramentas.product.onboardingDomainConfigScfAddPage.role
            }
          >
            Adicionar configuração
          </StandardLink>
        }
        gridRef={gridRef}
        showRefreshButton
        showExportButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={grid}
          dataSource={dataSource}
        />
      </GridTemplate>

      <ShowConfigurationsModal
        configurations={configurations}
        title="Configurações"
      />
    </>
  );
};
