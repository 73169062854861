import styled from "styled-components";
import ReactGridLayout from "react-grid-layout";

export const ReactGridLayoutStyled = styled(ReactGridLayout)`
  position: relative;

  overflow-y: auto;
  overflow-x: hidden;
  height: 400px !important;
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #eaeaea;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 20px;
    border: 2px solid #eaeaea;
    height: 1px !important;
  }
`;
