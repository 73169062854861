import { ORow } from "@maestro/react";
import { DefaultErrorComponent, EmptyState } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { SignatureGroupFields, SignatureRuleConfigsFields } from "../_compose";
import { useNewSignatureRule } from "./new-signature-rule.hook";

export const NewSignatureRuleClient = () => {
  const {
    form,
    getCompanyHasError,
    getCompanyLoading,
    getCompanyWithOnboarding,
    legalRepresentatives,
    submit,
    submitLoading,
  } = useNewSignatureRule();

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Criar regra de assinaturas" />}
      actions={
        <LoadingButton
          dataAction="acoes:botao:enviar"
          dataLabel="enviar"
          loading={submitLoading}
          onClick={submit}
        >
          Enviar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <SignatureRuleConfigsFields />
          </ORow>
          <ORow className="position-relative">
            <ContentTemplate
              loading={getCompanyLoading}
              hasError={getCompanyHasError}
              value={legalRepresentatives}
              errorComponent={
                <DefaultErrorComponent
                  title="Não foi possível buscar os representantes legais"
                  callback={getCompanyWithOnboarding}
                />
              }
              emptyStateComponent={
                <EmptyState messageTitle="Nenhum representante legal" />
              }
              render={(_legalRepresentatives) => (
                <SignatureGroupFields
                  disabled={false}
                  legalRepresentatives={_legalRepresentatives}
                />
              )}
            />
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
