import { OCol, OOption, ORFieldSelect } from "@maestro/react";
import { EstrategiaFormLabels } from "../estrategia-form.types";

const options = [
  "PRE",
  "CDIE",
  "NIPCA",
  "PTAX",
  "IGPM",
  "NIGPM",
  "SELIC",
  "TR",
];
interface InterestIndexCodeFieldProps {
  required?: boolean;
  disabled?: boolean;
}

export const InterestIndexCodeField = ({
  required,
  disabled,
}: InterestIndexCodeFieldProps) => {
  return (
    <OCol xs={12} md={6}>
      <ORFieldSelect
        dataAction="geral:select:indice_juros"
        dataLabel="indice_juros"
        id="interestIndexCode"
        name="interestIndexCode"
        label={EstrategiaFormLabels.interestIndexCode}
        placeholder="Selecionar"
        required={required}
        disabled={disabled}
      >
        {options.map((value) => (
          <OOption key={value} value={value}>
            {value}
          </OOption>
        ))}
      </ORFieldSelect>
    </OCol>
  );
};
