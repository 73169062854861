import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import dayjs from "dayjs";
import { FormValuesType } from "../block.types";
import { validationSchema } from "../block.utils";

export const useBlockListDetails = (blockList: Validator.Block) => {
  const navigate = useNavigate();
  const { routes } = corporateRouter;

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = form;

  const submit = handleSubmit(async (values) => {
    try {
      if (!blockList) return;

      setLoading(true);

      const payload = {
        identification: values.identification,
        type: values.type,
        from: values.from ? dayjs(values.from).format("YYYY-MM-DD") : null,
        until: values.until ? dayjs(values.until).format("YYYY-MM-DD") : null,
      };

      await service.validator.EditBlock(blockList.id, payload);

      OToastManager.success("Bloqueio alterado com sucesso");

      navigate(routes.fidc.product.eligibilitys.eligibilityBlockList.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  const load = useCallback(async () => {
    if (!blockList) return;

    reset(blockList);
  }, [blockList, reset]);

  useEffect(() => {
    load();
  }, [blockList, load]);

  return {
    form,
    submit,
    loading,
  };
};
