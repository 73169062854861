import { OBadge, OIcon, OTooltip, OTypography } from "@maestro/react";
import React from "react";
import { Handle, Position } from "reactflow";
import { getValueFromMap } from "utils/get-value-from-map";
import { useWorkflow } from "../../../hooks/use-workflow.context";
import { mapProcessorType } from "../../canvas.utils";
import "./flow-node.css";
import { FlowNodeProps } from "./flow-node.types";

export const FlowNode = ({ data }: FlowNodeProps) => {
  const { id, readOnly } = useWorkflow();

  const {
    id: processorConfigId,
    processor,
    isWfEntry,
    name,
    validationStatus,
    validationMessages,
  } = data;

  return (
    <React.Fragment key={`processor-config-card-${processorConfigId}`}>
      {!isWfEntry && (
        <Handle
          type="target"
          position={Position.Left}
          id={`target-${processorConfigId}`}
          className="handle handle-left"
        />
      )}

      <div
        onDoubleClick={async () => {
          if (readOnly) return;
          const url = `/workflow/produto/configuracoes-de-workflows/${id}/editar/processadores/${processorConfigId}/dados-basicos`;
          window.open(url, "_blank");
        }}
      >
        <div className="d-flex justify-content-between align-items-center gap-3">
          <OTypography weight="bold">
            {name ||
              getValueFromMap(mapProcessorType, processor?.type) ||
              processor?.type}
          </OTypography>
          {isWfEntry && (
            <OBadge size="xs" type="tertiary">
              Entrada do workflow
            </OBadge>
          )}
        </div>
        <div className="d-flex justify-content-between gap-3">
          <div className="d-flex flex-column">
            <OTypography className="mb-1">
              {getValueFromMap(mapProcessorType, processor?.type) ||
                processor?.type}
            </OTypography>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center gap-5 mt-4">
          <OTypography size="sm">{`Id do processador: ${processorConfigId}`}</OTypography>

          {validationStatus === "INVALID" && (
            <OBadge
              size="xs"
              type="warning"
              className="d-flex align-items-center"
            >
              <OTooltip type="warning" position="bottom-left">
                <div slot="tooltip-content">
                  {validationMessages.map((message) => `- ${message}\n`)}
                </div>
                <div className="d-flex align-items-center justify-content-between gap-1">
                  <OTypography className="typography-style" size="xs">
                    Status: Pendente
                  </OTypography>
                  <OIcon
                    category="fa"
                    icon="fa-question-circle"
                    size="xs"
                    className="ml-1"
                  />
                </div>
              </OTooltip>
            </OBadge>
          )}
          {validationStatus === "VALID" && (
            <OBadge
              size="xs"
              type="success"
              className="d-flex align-items-center"
            >
              <OTypography className="typography-style" size="xs">
                Status: Ativo
              </OTypography>
            </OBadge>
          )}
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id={`source-${processorConfigId}`}
        className="handle handle-right"
      />
    </React.Fragment>
  );
};
