import { OIcon } from "@maestro/react";
import { actionTextBuilder, ruleTextBuilder, uncapitalize } from "../../_utils";

interface RuleAndActionsGridCellProps {
  rule: HubEnergy.DiligenceEventRuleResponse;
}

export const RuleAndActionsGridCell = ({
  rule,
}: RuleAndActionsGridCellProps) => {
  const ruleText = ruleTextBuilder(rule);
  const actionTexts =
    rule.actions?.map((a) => ({
      text: actionTextBuilder(a),
      isEnabled: a.isEnabled,
    })) ?? [];

  if (actionTexts.length === 0) {
    return (
      <p>
        <OIcon
          category="orq"
          icon="orq-status-warning"
          type="warning"
          size="md"
          className="d-inline"
        />{" "}
        {ruleText}
      </p>
    );
  }

  if (actionTexts.length === 1) {
    return (
      <p>
        {ruleText},{" "}
        <span
          style={{
            color: actionTexts[0].isEnabled
              ? "var(--theme-dark)"
              : "var(--theme-dark-20)",
          }}
        >
          {uncapitalize(actionTexts[0].text)}
        </span>
        {rule.stopProcessingMoreRules && " e parar de processar mais regras"}
      </p>
    );
  }

  return (
    <div>
      <p>{ruleText}</p>
      {actionTexts.map((at) => (
        <p
          style={{
            color: at.isEnabled ? "var(--theme-dark)" : "var(--theme-dark-20)",
          }}
          key={at.text}
        >
          - {at.text}
        </p>
      ))}
      {rule.stopProcessingMoreRules && <p>e parar de processar mais regras</p>}
    </div>
  );
};
