import {
  modalManager,
  OButton,
  OCard,
  OCardBody,
  OCardFooter,
  OCol,
  ODivider,
  OIcon,
  ORadio,
  ORFieldRadioGroup,
  ORow,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import dayjs from "dayjs";
import { PageTitle } from "components/page-title";
import { rateIndexerMask } from "utils/mask";
import { CardContainer } from "./offers.styles";
import { OfferItem } from "./compose/offer-item/offer-item.component";
import { SimulationFooter } from "../../footer/footer.component";
import { CalendarModal } from "../../calendar-modal/calendar-modal.component";
import { OffersSkeleton } from "./compose/skeleton/offers-skeleton.component";
import { useSimulation } from "../../../simulation.context";
import { bannerMap } from "../../../../../../landing-page/[type]/_compose/banner/banner.utils";
import { LandingPage } from "../../../../../../landing-page/[type]/_compose/landing-page/landing-page.component";

export const Offers = () => {
  const {
    simulationResult,
    selectOfferButtonDisabled,
    selectOffer,
    landingPage,
    setLandingPage,
    loadingSimulation,
  } = useSimulation();

  return landingPage ? (
    <LandingPage setLandingPage={setLandingPage} landingPage={landingPage} />
  ) : (
    <>
      <PageTitle
        title="Escolha uma oferta"
        description="Confira as ofertas para contratação da linha de crédito. Valores sujeitos a alterações de acordo com o valor máximo das modalidades selecionadas"
      />
      {loadingSimulation ? (
        <OffersSkeleton />
      ) : (
        <ORow>
          <ORFieldRadioGroup name="offer" id="offer" key="offer">
            <OCol className="gap-4">
              {simulationResult?.map(({ loans, limit }) => {
                return loans.map((loan) => {
                  return (
                    <div key={loan?.identification}>
                      <CardContainer>
                        <OCard>
                          <OCardBody>
                            <label role="button" className="w-100">
                              <div className="d-flex justify-content-between align-items-center w-100">
                                <div>
                                  <OTypography>
                                    {
                                      limit.creditLineConfiguration
                                        .creditLineVisibleName
                                    }
                                  </OTypography>
                                  <OTypography type="dark-60" size="sm">
                                    Simulação de{" "}
                                    {masks.currency(loan?.amount, "R$")}
                                  </OTypography>
                                </div>
                                <ORadio
                                  id={loan?.identification}
                                  value={loan?.identification}
                                />
                              </div>
                            </label>

                            <ODivider />
                            <div className="my-3">
                              <OfferItem
                                label="Parcelas"
                                value={
                                  loan?.term === 1
                                    ? `1 vez`
                                    : `${loan?.term} vezes`
                                }
                              />
                              <OfferItem
                                label="Primeira parcela"
                                value={dayjs(
                                  loan?.settlement.installments[0].maturityDate,
                                ).format("DD/MM/YYYY")}
                              />
                              <OfferItem
                                label="Taxa de juros mensal"
                                value={`${rateIndexerMask(
                                  loan?.rate,
                                  loan?.interest?.indexCode,
                                )} a.m.`}
                              />
                              <OfferItem
                                label="IOF"
                                value={`${masks.currency(
                                  loan?.settlement.iofAmount,
                                  "R$",
                                )}`}
                              />
                              <OfferItem
                                label="CET (a.a.)"
                                value={masks.percentage(loan?.cet)}
                              />
                            </div>
                            <div>
                              <OTypography type="dark-80">
                                Total a prazo
                              </OTypography>
                              <OTypography type="dark-60" size="sm">
                                {masks.currency(loan?.settlement.amount, "R$")}
                              </OTypography>
                            </div>
                          </OCardBody>
                          <OCardFooter>
                            <div className="d-flex align-items-center justify-content-between">
                              <OButton
                                outline
                                onClick={() => {
                                  modalManager.show(
                                    `calendar-modal-${loan?.identification}`,
                                  );
                                }}
                              >
                                <OTypography type="dark">
                                  Consultar parcelas
                                </OTypography>
                              </OButton>
                              {bannerMap[
                                `${loan?.product}_${limit.fundingType}`
                              ] && (
                                <OButton
                                  outline
                                  bordered={false}
                                  onClick={() =>
                                    setLandingPage(
                                      `${loan?.product}_${limit.fundingType}`,
                                    )
                                  }
                                >
                                  <div className="d-flex align-items-center gap-1">
                                    Saiba mais
                                    <OIcon
                                      category="orq"
                                      icon="orq-chevron-right"
                                    />
                                  </div>
                                </OButton>
                              )}
                            </div>
                          </OCardFooter>
                        </OCard>
                      </CardContainer>
                      <CalendarModal
                        selectedOfferLoan={loan}
                        id={`calendar-modal-${loan?.identification}`}
                      />
                    </div>
                  );
                });
              })}
            </OCol>
          </ORFieldRadioGroup>
        </ORow>
      )}
      <SimulationFooter
        continueButtonDisabled={selectOfferButtonDisabled}
        continueAction={selectOffer}
      />
    </>
  );
};
