import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { GuarantorsSetup } from "./offers.type";
import { Guarantor } from "./compose/simulation/simulation-form/simulation.types";

interface GuarantorsSetupProps {
  children: ReactNode;
}

export interface GuarantorsSetupData {
  guarantorsSetup: GuarantorsSetup | undefined;
  guarantors: Guarantor[] | undefined;
  setGuarantors: React.Dispatch<React.SetStateAction<Guarantor[]>>;
  setGuarantorsSetup: (paths: GuarantorsSetup | undefined) => void;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GuarantorsSetupContext = createContext({} as GuarantorsSetupData);

export const GuarantorsSetupProvider = ({ children }: GuarantorsSetupProps) => {
  const [guarantorsSetup, setGuarantorsSetup] = useState<GuarantorsSetup>();
  const [guarantors, setGuarantors] = useState<Guarantor[]>([]);
  const [error, setError] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      guarantorsSetup,
      guarantors,
      setGuarantors,
      setGuarantorsSetup,
      error,
      setError,
    }),
    [guarantorsSetup, guarantors, error],
  );

  return (
    <GuarantorsSetupContext.Provider value={contextValue}>
      {children}
    </GuarantorsSetupContext.Provider>
  );
};

export const useGuarantorsSetup = () => useContext(GuarantorsSetupContext);
