import {
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  OSelect,
  OTypography,
} from "@maestro/react";
import { RouterButton } from "components/router-button";
import { useState } from "react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import {
  integrationTypeModalId,
  integrationTypesList,
} from "./integration-type-modal.utils";

export const IntegrationTypeModal = () => {
  const [integrationType, setIntegrationType] = useState<string>();
  return (
    <OModal id={integrationTypeModalId} position="center" backdrop={false}>
      <OModalHeader closeButton>
        <OTypography>Selecione o tipo de integração</OTypography>
      </OModalHeader>
      <OModalBody>
        <OSelect
          id="SelectIntegrationType"
          className="w-100"
          defaultValue=""
          onInput={(e: any) => setIntegrationType(e.currentTarget.value)}
        >
          {Object.entries(integrationTypesList).map(([value, label]) => (
            <OOption key={value} value={value}>
              {label}
            </OOption>
          ))}
        </OSelect>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex flex-row justify-content-end w-100">
          <RouterButton
            disabled={!integrationType}
            href={corporateRouter.routes.ferramentas.product.onboarding.domainConfigScf.workflowConfiguration.add.path(
              {
                integrationType: integrationType ?? "",
              },
            )}
            role={
              roles.ferramentas.product.onboardingWorkflowConfigScfAddPage.role
            }
          >
            Confirmar
          </RouterButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
