import { OButton, OLoader, OTypography } from "@maestro/react";
import { useMemo } from "react";
import {
  BeneficiaryStatus,
  TreatyStatus,
} from "services/admin-bankinghub/collection/models/types";
import { useCollectionBeneficiaryContext } from "../../../../collection-beneficiary.context";
import { useBeneficiaryData } from "./beneficiary-header.hook";

export const BeneficiaryHeader = () => {
  const { beneficiary } = useCollectionBeneficiaryContext();
  const { createBeneficiary, isLoading } = useBeneficiaryData();

  const renderCreateAction = useMemo(() => {
    if (!beneficiary) return false;
    const isBeneficiaryUnable = beneficiary.status !== BeneficiaryStatus.ABLE;
    const hasUnableAccounts = beneficiary.treaties.some(
      ({ status }) => status !== TreatyStatus.ACTIVE,
    );
    return isBeneficiaryUnable || hasUnableAccounts;
  }, [beneficiary]);

  return (
    <div>
      {isLoading && <OLoader absolute backdrop />}
      <div className="d-flex align-items-center justify-content-between gap-3">
        <OTypography type="dark" size="lg">
          Informações do Beneficiário
        </OTypography>

        {renderCreateAction && (
          <OButton onClick={createBeneficiary} outline>
            Solicitar cadastro
          </OButton>
        )}
      </div>
    </div>
  );
};
