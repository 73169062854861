import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { PostsettlementStrategiesBody } from "services/quickfin/models";
import { service } from "services/service";
import { addSettlementStrategySchema } from "../_compose/formulario/settlement-strategies-form.form";

export const useAddSettlementStrategy = () => {
  const [isConsolidated, setIsConsolidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useForm({
    resolver: yupResolver(addSettlementStrategySchema),
  });
  const { handleSubmit } = form;
  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (values) => {
    setLoading(true);

    const body: PostsettlementStrategiesBody = {
      bankAccount: values.bankAccount,
      cedenteId: values.cedenteId,
      estrategiaComercialId: values.estrategiaComercialId,
      settlementMethodCode: values.settlementMethodCode,
      settlementLockInfo: values.settlementLockInfo,
      isConsolidated,
      tipoProduto: values.tipoProduto,
      sacadoId: values.sacadoId,
    };

    try {
      await service.quickfin.postNewSettlementStrategy(body);
      OToastManager.success(
        "A estratégia de liquidação foi salva com sucesso.",
      );
      navigate(
        corporateRouter.routes.antecipacaoDeCartoes.customer
          .settlementStrategies.path,
      );
    } catch {
      OToastManager.danger(
        "Não foi possível salvar a estratégia de liquidação!",
      );
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    isConsolidated,
    loading,
    onSubmit,
    setIsConsolidated,
  };
};
