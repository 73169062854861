import {
  ProductName,
  UpsertProductLayoutRequest,
} from "services/admin-bankinghub/cnab/models";
import { UpsertLayoutFormFields } from "./upsert-layout.form-schema";

const DEFAULT_PRODUCT_NAME = ProductName.Collection;

export const loadDefaultValues = (
  products: BankingHubAdminCNAB.Product[],
): Partial<UpsertLayoutFormFields> => {
  const productName = DEFAULT_PRODUCT_NAME;

  const selectedProduct = products?.find(({ name }) => name === productName);

  const selectedLayout = selectedProduct?.layouts.find(
    ({ selected }) => selected,
  );

  if (!selectedLayout) return { productName };

  const { erp = "", isBolepix, sendEmail, sendSMS, type } = selectedLayout;
  return { erp, isBolepix, sendEmail, sendSMS, productName, layoutType: type };
};

export const assembleUpsertLayoutPayload = (
  products: BankingHubAdminCNAB.Product[],
  formValues: UpsertLayoutFormFields,
  taxId: string,
): UpsertProductLayoutRequest => {
  const { productName, layoutType, erp, isBolepix, sendEmail, sendSMS } =
    formValues;

  const selectedProduct = products?.find(({ name }) => name === productName);

  const selectedLayout = selectedProduct?.layouts.find(
    ({ type }) => type === layoutType,
  );

  const isCollectionProduct = productName === ProductName.Collection;

  return {
    productId: selectedProduct?.id ?? "",
    layoutId: selectedLayout?.id ?? "",
    config: {
      status: "ACTIVE",
      erp,
      ...(isCollectionProduct && {
        isBolepix: !!isBolepix,
        sendEmail: !!sendEmail,
        sendSMS: !!sendSMS,
      }),
    },
    taxId,
  };
};
