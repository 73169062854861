import { OToastManager } from "@maestro/core";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import type DataSource from "devextreme/data/data_source";
import { useServiceCall } from "hooks/service-call";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import { PostAdminReceivablesSuggestBody } from "services/quickfin/models";
import { customer } from "contexts/customer";
import { useIntubateOperations } from "../../intubate-operations.context";
import { ReceivableSuggestion } from "./quote-suggest.types";
import { remapSuggestions } from "./quote-suggest.utils";

interface QuoteSuggestContext {
  dataSource: DataSource<ReceivableSuggestion>;
  requestedAmounts: Record<number, number>;
  searchPayload: PostAdminReceivablesSuggestBody | undefined;
  setSearchPayload: React.Dispatch<
    React.SetStateAction<PostAdminReceivablesSuggestBody | undefined>
  >;
  suggestions: ReceivableSuggestion[] | undefined;
  updateParentContext: () => void;
}

const quoteSuggestContext = createContext({} as QuoteSuggestContext);

interface QuoteSuggestProps {
  children: React.ReactNode;
}

export const QuoteSuggestProvider = ({ children }: QuoteSuggestProps) => {
  const [searchPayload, setSearchPayload] =
    useState<PostAdminReceivablesSuggestBody>();

  const { setCardReceivables } = useIntubateOperations();

  const { callService: suggestReceivables, value: suggestions } =
    useServiceCall(service.quickfin.suggestReceivables);

  const updateParentContext = useCallback(() => {
    setCardReceivables(remapSuggestions(suggestions ?? []));
  }, [setCardReceivables, suggestions]);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<ReceivableSuggestion>(async () => {
        if (!searchPayload) return Promise.resolve([]);

        const { success, response } = await suggestReceivables(searchPayload);

        if (success) return response.data;

        const errorMessage = "Erro ao buscar os recebíveis";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
    [searchPayload, suggestReceivables],
  );

  const requestedAmounts: Record<number, number> = useMemo(
    () =>
      Object.fromEntries(
        suggestions?.map((receivable) => [
          receivable.id,
          receivable.suggestedMaturityAmount,
        ]) ?? [],
      ),
    [suggestions],
  );

  useEffect(() => {
    setSearchPayload({
      bandeiras: [],
      cedentes: [customer.value?.identification || ""],
      dataVencimentoInicial: null,
      dataVencimentoFinal: null,
      sacados: ["01425787000104", "10440482000154", "01027058000191"],
      valorDesembolsoDesejado: 999999999,
    });
  }, []);

  const value = {
    dataSource,
    requestedAmounts,
    searchPayload,
    setSearchPayload,
    suggestions,
    updateParentContext,
  };

  return (
    <quoteSuggestContext.Provider value={value}>
      {children}
    </quoteSuggestContext.Provider>
  );
};

export const useQuoteSuggest = () => useContext(quoteSuggestContext);
