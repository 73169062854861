import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FerramentasProdutoOnboardingConfiguracaoDeWorkflowsScfByIntegrationType } from "../../../../../../routes/ferramentas.route-params";
import { workflowScfValidationSchema } from "../../workflow-configuration-scf.form";

export const useAddWorkflowConfigurationScf = () => {
  const { integrationType } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeWorkflowsScfByIntegrationType>();
  if (!integrationType) {
    throw new Error("No integration type");
  }

  const form = useForm({
    resolver: yupResolver(workflowScfValidationSchema),
  });
  const { reset } = form;

  useEffect(() => {
    reset({
      integrationType,
    });
  }, [integrationType, reset]);

  return {
    form,
  };
};
