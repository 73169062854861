import { OToastManager, modalManager } from "@maestro/react";
import { useCallback, useState } from "react";
import { service } from "services";
import { useCollectionBeneficiaryContext } from "../../../../collection-beneficiary.context";
import { CancelFloatingModalId } from "./cancel-floating-modal.types";

export const useCancelFloating = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { getBeneficiary } = useCollectionBeneficiaryContext();

  const cancelFloating = useCallback(async () => {
    try {
      setIsLoading(true);
      await service.adminBankinghub.collection.cancelFloating();
      OToastManager.success("Floating removido com sucesso");
      modalManager.hide(CancelFloatingModalId);
      getBeneficiary();
    } catch {
      OToastManager.danger(
        "Ocorreu um erro para remover o Floating do beneficiário.",
      );
    } finally {
      setIsLoading(false);
    }
  }, [getBeneficiary]);

  return { cancelFloating, isLoading };
};
