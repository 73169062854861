import {
  OBadge,
  OCard,
  OCardBody,
  OCardHeader,
  OCol,
  OIcon,
  ORow,
  OTypography,
} from "@maestro/react";
import { ITips } from "./tips.type";

export const Tips = ({ tipsList, title }: ITips) => {
  return (
    <OCard>
      <OCardHeader>
        <OTypography size="lg" tag="h2">
          {title}
        </OTypography>
      </OCardHeader>
      <OCardBody>
        <ORow gap={3}>
          {tipsList?.map((item) => (
            <OCol
              className="d-flex align-items-start gap-3"
              key={item.subtitle}
              sm={tipsList.length > 4 ? 4 : 6}
            >
              <OBadge circle type="tertiary" size="lg">
                <OIcon
                  category="orq"
                  icon={item.icon as any}
                  type="light"
                  size="xxl"
                />
              </OBadge>

              <div className="d-flex flex-column align-items-start gap-1">
                {!!item.title && <OTypography>{item.title}</OTypography>}
                <OTypography type="dark-60">{item.subtitle}</OTypography>
              </div>
            </OCol>
          ))}
        </ORow>
      </OCardBody>
    </OCard>
  );
};
