import { DeepNullable, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface ActionEditorForm {
  actionType: string;
  targetStepTypes: string[];
  targetStepStatuses: string[];
  targetPendencyTypes: string[];
  targetPendencyStatuses: string[];
  targetSamePendency: boolean;
  targetSameStep: boolean;
  isEnabled: boolean;
  payload: {
    addPendencyNoteActionPayload: {
      author: string;
      content: string;
    };
    changePendencyStatusActionPayload: {
      status: HubEnergy.EDiligenceStepPendencyStatus;
    };
    changePendencyStatusScheduledActionPayload: {
      delayDays: number;
      status: HubEnergy.EDiligenceStepPendencyStatus;
    };
    changeStepStatusActionPayload: {
      status: HubEnergy.EDiligenceStepStatus;
    };
    createPendencyActionPayload: {
      message: string;
      isPublic: boolean;
      isAutomation: boolean;
      hasBpoAction: boolean;
      status: string;
      type: string;
    };
    createPropertyActionPayload: {
      isPublic: boolean;
      isAutomation: boolean;
      type: string;
      documentType: string;
    };
    restartPendencyAnalysisScheduledActionPayload: {
      delayDays: number;
    };
  };
}

export const actionEditorFormFields = {
  actionType: {
    name: "actionType",
    label: "Ação",
    placeholder: "Selecionar",
    required: true,
  },
  targetStepTypes: {
    name: "targetStepTypes",
    label: "Tipo da step",
    placeholder: "Selecionar",
    multiple: true,
  },
  targetStepStatuses: {
    name: "targetStepStatuses",
    label: "Status da step",
    placeholder: "Selecionar",
    multiple: true,
  },
  targetPendencyTypes: {
    name: "targetPendencyTypes",
    label: "Tipo da pendência",
    placeholder: "Selecionar",
    multiple: true,
  },
  targetPendencyStatuses: {
    name: "targetPendencyStatuses",
    label: "Status da pendência",
    placeholder: "Selecionar",
    multiple: true,
  },
  targetSamePendency: {
    id: "targetSamePendency",
    name: "targetSamePendency",
    checkboxLabel: "Mesma pendência que disparou o evento",
  },
  targetSameStep: {
    id: "targetSameStep",
    name: "targetSameStep",
    checkboxLabel: "Mesma step que disparou o evento",
  },
  isEnabled: {
    id: "isEnabled",
    name: "isEnabled",
    checkboxLabel: "Habilitado",
  },
  // ADD_PENDENCY_NOTE
  "payload.addPendencyNoteActionPayload.author": {
    id: "payload.addPendencyNoteActionPayload.author",
    name: "payload.addPendencyNoteActionPayload.author",
    label: "Autor da anotação",
    tag: "text",
    required: false,
    autocomplete: "off",
  },
  "payload.addPendencyNoteActionPayload.content": {
    id: "payload.addPendencyNoteActionPayload.content",
    name: "payload.addPendencyNoteActionPayload.content",
    label: "Conteúdo da anotação",
    tag: "text",
    required: true,
    autocomplete: "off",
  },
  // CHANGE_PENDENCY_STATUS
  "payload.changePendencyStatusActionPayload.status": {
    name: "payload.changePendencyStatusActionPayload.status",
    label: "Status da pendência",
    placeholder: "Selecionar",
    multiple: false,
    required: true,
    dropdownPosition: "top",
  },
  // CHANGE_PENDENCY_STATUS_SCHEDULED
  "payload.changePendencyStatusScheduledActionPayload.status": {
    name: "payload.changePendencyStatusScheduledActionPayload.status",
    label: "Status da pendência",
    placeholder: "Selecionar",
    multiple: false,
    required: true,
    dropdownPosition: "top",
  },
  "payload.changePendencyStatusScheduledActionPayload.delayDays": {
    id: "payload.changePendencyStatusScheduledActionPayload.delayDays",
    name: "payload.changePendencyStatusScheduledActionPayload.delayDays",
    label: "Após quantos dias",
    tag: "number",
    required: true,
  },
  // CHANGE_STEP_STATUS
  "payload.changeStepStatusActionPayload.status": {
    name: "payload.changeStepStatusActionPayload.status",
    label: "Status da step",
    placeholder: "Selecionar",
    multiple: false,
    required: true,
    dropdownPosition: "top",
  },
  // CREATE_PENDENCY
  "payload.createPendencyActionPayload.isPublic": {
    id: "payload.createPendencyActionPayload.isPublic",
    name: "payload.createPendencyActionPayload.isPublic",
    checkboxLabel: "Mostra para o cliente",
  },
  "payload.createPendencyActionPayload.isAutomation": {
    id: "payload.createPendencyActionPayload.isAutomation",
    name: "payload.createPendencyActionPayload.isAutomation",
    checkboxLabel: "Automação",
  },
  "payload.createPendencyActionPayload.hasBpoAction": {
    id: "payload.createPendencyActionPayload.hasBpoAction",
    name: "payload.createPendencyActionPayload.hasBpoAction",
    checkboxLabel: "Tem ação de BPO",
  },
  "payload.createPendencyActionPayload.status": {
    name: "payload.createPendencyActionPayload.status",
    label: "Status da pendência",
    placeholder: "Selecionar",
    multiple: false,
    dropdownPosition: "top",
    required: true,
  },
  "payload.createPendencyActionPayload.type": {
    name: "payload.createPendencyActionPayload.type",
    label: "Tipo da pendência",
    placeholder: "Selecionar",
    multiple: false,
    required: true,
    dropdownPosition: "top",
  },
  "payload.createPendencyActionPayload.message": {
    id: "payload.createPendencyActionPayload.message",
    name: "payload.createPendencyActionPayload.message",
    tag: "textarea",
    label: "Mensagem",
    adaptHeight: true,
  },
  // CREATE_PROPERTY
  "payload.createPropertyActionPayload.isPublic": {
    id: "payload.createPropertyActionPayload.isPublic",
    name: "payload.createPropertyActionPayload.isPublic",
    checkboxLabel: "Mostra para o cliente",
  },
  "payload.createPropertyActionPayload.isAutomation": {
    id: "payload.createPropertyActionPayload.isAutomation",
    name: "payload.createPropertyActionPayload.isAutomation",
    checkboxLabel: "Automação",
  },
  "payload.createPropertyActionPayload.type": {
    name: "payload.createPropertyActionPayload.type",
    label: "Tipo da propriedade",
    placeholder: "Selecionar",
    multiple: false,
    required: true,
    dropdownPosition: "top",
  },
  "payload.createPropertyActionPayload.documentType": {
    name: "payload.createPropertyActionPayload.documentType",
    label: "Tipo do documento",
    placeholder: "Selecionar",
    multiple: false,
    dropdownPosition: "top",
  },
  // RESTART_PENDENCY_ANALYSIS_SCHEDULED
  "payload.restartPendencyAnalysisScheduledActionPayload.delayDays": {
    id: "payload.restartPendencyAnalysisScheduledActionPayload.delayDays",
    name: "payload.restartPendencyAnalysisScheduledActionPayload.delayDays",
    label: "Após quantos dias",
    tag: "number",
    required: true,
  },
} as const;

type Payload = ActionEditorForm["payload"];
type AddPendencyNote = Payload["addPendencyNoteActionPayload"];
type ChangePendencyStatus = Payload["changePendencyStatusActionPayload"];
type ChangePendencyStatusScheduled =
  Payload["changePendencyStatusScheduledActionPayload"];
type ChangeStepStatus = Payload["changeStepStatusActionPayload"];
type CreatePendencyAction = Payload["createPendencyActionPayload"];
type CreatePropertyAction = Payload["createPropertyActionPayload"];
type RestartPendencyAnalysisScheduledAction =
  Payload["restartPendencyAnalysisScheduledActionPayload"];

export const actionEditorFormValidationSchema = yup.object<
  ActionEditorForm,
  YupObjectSchema<ActionEditorForm, yup.Maybe<yup.AnyObject>>
>({
  actionType: yup.string().required("Campo obrigatório"),
  payload: yup.lazy((_, { parent }: { parent?: ActionEditorForm }) =>
    yup.object<Payload, YupObjectSchema<Payload>>({
      addPendencyNoteActionPayload: yup
        .object<AddPendencyNote, YupObjectSchema<AddPendencyNote>>({
          content: yup.string().required("Campo obrigatório"),
        })
        .when({
          is: () =>
            parent?.actionType ===
            ("ADD_PENDENCY_NOTE" satisfies HubEnergy.EDiligenceEventActionType),
          then: (schema) => schema,
          otherwise: () => yup.object().nullable().optional(),
        }),
      changePendencyStatusActionPayload: yup
        .object<ChangePendencyStatus, YupObjectSchema<ChangePendencyStatus>>({
          status: yup
            .string<HubEnergy.EDiligenceStepPendencyStatus>()
            .required("Campo obrigatório"),
        })
        .when({
          is: () =>
            parent?.actionType ===
            ("CHANGE_PENDENCY_STATUS" satisfies HubEnergy.EDiligenceEventActionType),
          then: (schema) => schema,
          otherwise: () => yup.object().nullable().optional(),
        }),
      changePendencyStatusScheduledActionPayload: yup
        .object<
          ChangePendencyStatusScheduled,
          YupObjectSchema<ChangePendencyStatusScheduled>
        >({
          status: yup
            .string<HubEnergy.EDiligenceStepPendencyStatus>()
            .required("Campo obrigatório"),
          delayDays: yup
            .number()
            .min(0, "Valor deve ser maior ou igual a 0")
            .required("Campo obrigatório"),
        })
        .when({
          is: () =>
            parent?.actionType ===
            ("CHANGE_PENDENCY_STATUS_SCHEDULED" satisfies HubEnergy.EDiligenceEventActionType),
          then: (schema) => schema,
          otherwise: () => yup.object().nullable().optional(),
        }),
      changeStepStatusActionPayload: yup
        .object<ChangeStepStatus, YupObjectSchema<ChangeStepStatus>>({
          status: yup
            .string<HubEnergy.EDiligenceStepStatus>()
            .required("Campo obrigatório"),
        })
        .when({
          is: () =>
            parent?.actionType ===
            ("CHANGE_STEP_STATUS" satisfies HubEnergy.EDiligenceEventActionType),
          then: (schema) => schema,
          otherwise: () => yup.object().nullable().optional(),
        }),
      createPendencyActionPayload: yup
        .object<CreatePendencyAction, YupObjectSchema<CreatePendencyAction>>({
          status: yup.string().required("Campo obrigatório"),
          type: yup.string().required("Campo obrigatório"),
        })
        .when({
          is: () =>
            parent?.actionType ===
            ("CREATE_PENDENCY" satisfies HubEnergy.EDiligenceEventActionType),
          then: (schema) => schema,
          otherwise: () => yup.object().nullable().optional(),
        }),
      createPropertyActionPayload: yup
        .object<CreatePropertyAction, YupObjectSchema<CreatePropertyAction>>({
          type: yup.string().required("Campo obrigatório"),
        })
        .when({
          is: () =>
            parent?.actionType ===
            ("CREATE_PROPERTY" satisfies HubEnergy.EDiligenceEventActionType),
          then: (schema) => schema,
          otherwise: () => yup.object().nullable().optional(),
        }),
      restartPendencyAnalysisScheduledActionPayload: yup
        .object<
          RestartPendencyAnalysisScheduledAction,
          YupObjectSchema<RestartPendencyAnalysisScheduledAction>
        >({
          delayDays: yup
            .number()
            .min(0, "Valor deve ser maior ou igual a 0")
            .required("Campo obrigatório"),
        })
        .when({
          is: () =>
            parent?.actionType ===
            ("RESTART_PENDENCY_ANALYSIS_SCHEDULED" satisfies HubEnergy.EDiligenceEventActionType),
          then: (schema) => schema,
          otherwise: () => yup.object().nullable().optional(),
        }),
    }),
  ),
});

export const actionEditorFormDefaultValues = {
  actionType: null,
  targetStepTypes: [],
  targetStepStatuses: [],
  targetPendencyTypes: [],
  targetPendencyStatuses: [],
  targetSamePendency: false,
  targetSameStep: false,
  isEnabled: true,
  payload: {
    addPendencyNoteActionPayload: {
      author: null,
      content: null,
    },
    changePendencyStatusActionPayload: {
      status: null,
    },
    changePendencyStatusScheduledActionPayload: {
      delayDays: null,
      status: null,
    },
    changeStepStatusActionPayload: {
      status: null,
    },
    createPendencyActionPayload: {
      isAutomation: false,
      hasBpoAction: false,
      isPublic: false,
      message: null,
      status: null,
      type: null,
    },
    createPropertyActionPayload: {
      isAutomation: false,
      isPublic: false,
      type: null,
      documentType: null,
    },
    restartPendencyAnalysisScheduledActionPayload: {
      delayDays: null,
    },
  },
} satisfies Partial<
  DeepNullable<ActionEditorForm>
> as unknown as ActionEditorForm;
