import * as yup from "yup";

export const editCollectionFloatingModalValidationSchema = yup.object({
  quantityInDays: yup
    .number()
    .typeError("Esse campo é obrigatório")
    .integer("Somente números inteiros são permitidos")
    .min(0, "Esse campo é obrigatório")
    .required("Esse campo é obrigatório"),
});
