import { BeneficiaryDetails } from "./_compose/beneficiary-details";
import { FloatingDetails } from "./_compose/floating-details";
import { TreatiesDetails } from "./_compose/treaties-details";

export const CollectionBeneficiary = () => {
  return (
    <div className="d-flex flex-column gap-4">
      <BeneficiaryDetails />
      <FloatingDetails />
      <TreatiesDetails />
    </div>
  );
};
