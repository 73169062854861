import { OCard, OCardBody } from "@maestro/react";
import { DetailsCard } from "components/details-card";
import { PageTitle } from "components/page-title";
import { Navigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { tradeFields } from "../../components/trade";
import { ValidationErrorComponent } from "../../components/validation-error-component";
import {
  ConsumerUnitsGrid,
  ContactsGrid,
  NotifySignatories,
  TradeGuarantorsGrid,
} from "./_compose";
import { energyCustomerFields } from "./energy-customer.details";
import { useEnergyCustomer } from "./energy-customer.hook";

export const EnergyCustomerPage = () => {
  const { currentTrade, error, getEntity, hasError, loading, value } =
    useEnergyCustomer();

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Matriz" />}
      actions={
        currentTrade?.status === "PENDENTE_ASSINATURA" && (
          <NotifySignatories currentTrade={currentTrade} />
        )
      }
    >
      <div className="d-flex flex-column gap-4 position-relative">
        <ContentTemplate
          loading={loading}
          hasError={hasError}
          errorComponent={
            <OCard>
              <OCardBody>
                <ValidationErrorComponent
                  error={error}
                  title="Erro ao buscar o cliente"
                  callback={getEntity}
                />
              </OCardBody>
            </OCard>
          }
          emptyStateComponent={
            value?.response?.consumerUnit?.currentTrade ? (
              <Navigate
                to={corporateRouter.routes.energia.customer.consumerUnit.details.path(
                  { consumerUnitId: value.response.consumerUnit.id },
                )}
              />
            ) : (
              <OCard>
                <OCardBody>Não possui o produto de Energia adquirido</OCardBody>
              </OCard>
            )
          }
          value={value?.response?.customerCurrentTrade && value?.response}
          render={(_customer) => (
            <>
              <DetailsCard fields={energyCustomerFields} value={_customer} />
              <DetailsCard
                fields={tradeFields}
                value={_customer.customerCurrentTrade}
              />
              <ConsumerUnitsGrid />
              <TradeGuarantorsGrid
                tradeId={_customer.customerCurrentTrade.id}
              />
              {_customer.customerId && (
                <ContactsGrid customerId={_customer.customerId} />
              )}
            </>
          )}
        />
      </div>
    </DetailsTemplate>
  );
};
