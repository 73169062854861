import { OButton, OTypography } from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { useMemo } from "react";
import { GenericPropertyFieldProps } from "../../property-field.types";
import { useMultiSelectField } from "./multi-select-field.hook";

export const MultiSelectField = ({
  processorConfigProperty,
  handleSubmitProperty,
}: GenericPropertyFieldProps) => {
  const { processorPropertyId, name } = processorConfigProperty;

  const { fieldWatch, processorConfigId, rejectionReasonsOptions } =
    useMultiSelectField({
      processorConfigProperty,
    });

  const genericPropertyField = useMemo(
    () => (
      <div className="d-flex flex-column gap-4 w-100">
        <OTypography size="lg">
          {processorConfigProperty.description}
        </OTypography>
        <div className="d-flex flex-row gap-4">
          <SelectSearchMultiple
            id={`properties.${name}_${processorPropertyId}`}
            name={`properties.${name}_${processorPropertyId}_value`}
            placeholder="Selecione"
            options={rejectionReasonsOptions ?? []}
          />
          <OButton
            onClick={() => {
              handleSubmitProperty(
                fieldWatch,
                processorPropertyId,
                processorConfigId,
              );
            }}
          >
            Salvar
          </OButton>
        </div>
      </div>
    ),
    [
      processorConfigProperty.description,
      name,
      processorPropertyId,
      rejectionReasonsOptions,
      handleSubmitProperty,
      fieldWatch,
      processorConfigId,
    ],
  );

  return <>{genericPropertyField}</>;
};
