import { OLoader, OTypography } from "@maestro/react";
import { IconStyled } from "./action-button-content.styles";
import { ActionButtonContentProps } from "./action-button-content.types";

export const ActionButtonContent = ({
  text,
  textType,
  textSize = "md",
  icon,
  isLoading,
  reverseRow,
  gapSize = 2,
}: ActionButtonContentProps) => {
  const isReverseRow = reverseRow ? "flex-row-reverse" : "";

  return (
    <div
      className={`d-flex align-items-center justify-content-center text-nowrap gap-${gapSize} ${isReverseRow}`}
    >
      <OTypography type={textType} key={text} size={textSize}>
        {text}
      </OTypography>
      {isLoading ? (
        <OLoader size="xs" />
      ) : (
        !!icon && <IconStyled className="align-self-center" {...icon} />
      )}
    </div>
  );
};
