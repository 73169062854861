import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import {
  OBadge,
  OButton,
  OIcon,
  OToastManager,
  modalManager,
} from "@maestro/react";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { getValueFromMap } from "utils/get-value-from-map";
import { OptinView } from "services/recebiveis-cartao/models/responses/get-optins.response";
import { gridStorage } from "utils/storage";
import { service } from "services";
import { ODataRequest } from "services/service.types";
import { optinViewStatusMap, optinViewTintMap } from "./optin-view.utils";
import { confirmationModalId } from "./confirmation-modal/confirmation-modal.component";
import { useOptinContext } from "./consulta-optin.context";

const OptOutButton = ({ data }: { data: OptinView }) => {
  const { setIdentop } = useOptinContext();

  if (!data.identop || data.status !== "aceito") return null;

  return (
    <OButton
      type="danger"
      size="sm"
      outline
      onClick={() => {
        modalManager.show(confirmationModalId);
        setIdentop(data.identop);
      }}
    >
      <OIcon category="fal" icon="fa-sign-out" size="sm" />
    </OButton>
  );
};

export const consultaOptinsGrid: (
  taxIds: string[],
  filterValue: string[],
) => ODataGridGeneratorConfig<OptinView> = (
  taxIds: string[],
  filterValue: string[],
) => ({
  datagrid: {
    filterRow: {
      visible: true,
    },
    headerFilter: { visible: true },
    remoteOperations: true,
    dateSerializationFormat: "yyyy-MM-dd",
    height: 900,
    scrolling: {
      showScrollbar: "always",
      mode: "infinite",
      rowRenderingMode: "virtual",
    },
    filterValue,
    stateStoring: gridStorage("view-optins"),
    dataSource: dataSourceCustomStoreGenerator<OptinView>((loadOptions) =>
      service.recebiveisCartao
        .getOptins({ ...(loadOptions as ODataRequest), taxIds })
        .then(({ data }) => ({
          data: data.data ?? [],
        }))
        .catch(() => {
          const errorMessage = "Não foi possível buscar as empresas";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    ),
  },
  columns: [
    {
      dataField: "company_name",
      dataType: "string",
      caption: "Nome da empresa",
      allowHeaderFiltering: false,
    },
    {
      dataField: "tax_id",
      dataType: "string",
      caption: "CNPJ",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      allowHeaderFiltering: false,
    },
    {
      dataField: "optin_sent_by_root",
      dataType: "boolean",
      caption: "Enviado pela raíz",
      allowHeaderFiltering: false,
    },
    {
      dataField: "bandeira",
      dataType: "string",
      caption: "Bandeira",
    },
    {
      dataField: "credenciadora",
      dataType: "string",
      caption: "Credenciadora",
    },
    {
      dataField: "credenciadora_tax_id",
      dataType: "string",
      caption: "CNPJ da Credenciadora",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      allowHeaderFiltering: false,
    },
    {
      dataField: "status",
      dataType: "string",
      caption: "Status",
      allowHeaderFiltering: filterValue.length === 0,
      cellRender: ({ data }) => (
        <OBadge
          size="sm"
          type={
            getValueFromMap(optinViewTintMap, data.status) || "warning-light"
          }
        >
          {getValueFromMap(optinViewStatusMap, data.status) || data.status}
        </OBadge>
      ),
    },
    {
      dataField: "identop",
      dataType: "string",
      caption: "Identop",
      allowHeaderFiltering: false,
    },
    {
      dataField: "inserted_at",
      dataType: "datetime",
      caption: "Enviado em",
      allowHeaderFiltering: false,
    },
    {
      dataField: "updated_at",
      dataType: "datetime",
      caption: "Atualizado em",
      allowHeaderFiltering: false,
    },
    {
      dataField: "start_date",
      dataType: "date",
      format: "shortDate",
      caption: "Data Inicial",
      allowHeaderFiltering: false,
    },
    {
      dataField: "end_date",
      dataType: "date",
      format: "shortDate",
      caption: "Data Final",
      allowHeaderFiltering: false,
    },
    {
      dataField: "details",
      dataType: "string",
      caption: "Detalhes",
      allowHeaderFiltering: false,
    },
    {
      caption: "Opt-out",
      cellRender: ({ data }) => <OptOutButton data={data} />,
    },
  ],
});
