import { OCard, OCardBody } from "@maestro/react";
import { BaseLayoutFields } from "./_compose/base-layout-fields";
import { CollectionConfigFields } from "./_compose/collection-config-fields";

export const LayoutFormCard = () => {
  return (
    <OCard>
      <OCardBody className="d-flex flex-column">
        <BaseLayoutFields />
        <CollectionConfigFields />
      </OCardBody>
    </OCard>
  );
};
