import { validators } from "@maestro/utils";
import {
  DateRangeSectionFilter,
  MultSelectionSectionFilter,
  SectionFilterType,
  SelectableSearchSectionFilter,
  SingleSelectionSectionFilter,
} from "components/side-filter-bar";
import { assembleSelectableSearchDefaultValues } from "components/side-filter-bar/side-filter-bar.utils";
import dayjs from "dayjs";
import {
  BadCreditStatus,
  BankslipGridColumn,
  CollectionFilterFields,
  CollectionFilterParams,
  CollectionStatus,
  CollectionType,
} from "./bankslip.type";

const BOLETED_AMOUNT = 250000;

export const MapperCollectionType: Record<CollectionType, string> = {
  [CollectionType.BANKSLIP]: "Boleto",
  [CollectionType.BANKSLIP_QRCODE]: "Boleto + QR Code",
};

export const MapperCollectionStatus: Record<
  CollectionStatus,
  {
    type: "info" | "success" | "warning" | "danger";
    label: string;
  }
> = {
  [CollectionStatus.Canceled]: {
    type: "danger",
    label: "Cancelado",
  },
  [CollectionStatus.CancelFailed]: {
    type: "danger",
    label: "Falha no cancelamento",
  },
  [CollectionStatus.Canceling]: {
    type: "info",
    label: "Cancelando",
  },
  [CollectionStatus.Created]: {
    type: "success",
    label: "Registrado",
  },
  [CollectionStatus.Expired]: {
    type: "danger",
    label: "Cancelado",
  },
  [CollectionStatus.Failed]: {
    type: "danger",
    label: "Falha",
  },
  [CollectionStatus.Overdue]: {
    type: "warning",
    label: "Vencido",
  },
  [CollectionStatus.Paid]: {
    type: "success",
    label: "Pago",
  },
  [CollectionStatus.Processing]: {
    type: "info",
    label: "Registrando",
  },
  [CollectionStatus.ProcessingPayment]: {
    type: "success",
    label: "Registrado",
  },
  [CollectionStatus.Updated]: {
    type: "success",
    label: "Atualizado",
  },
  [CollectionStatus.UpdateFailed]: {
    type: "danger",
    label: "Falha na atualização",
  },
  [CollectionStatus.Updating]: {
    type: "info",
    label: "Atualizando",
  },
};

export const MapperBadCreditStatus: Record<
  BadCreditStatus,
  {
    type: "info" | "success" | "warning" | "danger";
    label: string;
  }
> = {
  [BadCreditStatus.Canceled]: {
    type: "danger",
    label: "Cancelada",
  },
  [BadCreditStatus.Canceling]: {
    type: "info",
    label: "Cancelando",
  },
  [BadCreditStatus.Confirmed]: {
    type: "danger",
    label: "Negativado",
  },
  [BadCreditStatus.ConfirmedWithCancelPending]: {
    type: "info",
    label: "Cancelando",
  },
  [BadCreditStatus.Failed]: {
    type: "danger",
    label: "Erro",
  },
  [BadCreditStatus.FailedToCancel]: {
    type: "danger",
    label: "Erro",
  },
  [BadCreditStatus.FailedToSend]: {
    type: "info",
    label: "Em negativação",
  },
  [BadCreditStatus.Processing]: {
    type: "info",
    label: "Em negativação",
  },
  [BadCreditStatus.Starting]: {
    type: "info",
    label: "Em negativação",
  },
  [BadCreditStatus.WarningPeriod]: {
    type: "info",
    label: "Em negativação",
  },
};

export const MapperBankslipGridColumnWidth: Record<BankslipGridColumn, string> =
  {
    [BankslipGridColumn.CreatedAt]: "15%",
    [BankslipGridColumn.DocumentNumber]: "15%",
    [BankslipGridColumn.OurNumber]: "15%",
    [BankslipGridColumn.Payer]: "15%",
    [BankslipGridColumn.Status]: "12%",
    [BankslipGridColumn.BadCredit]: "12%",
    [BankslipGridColumn.DueDate]: "10%",
    [BankslipGridColumn.UpdatedAt]: "12%",
    [BankslipGridColumn.Amount]: "12%",
    [BankslipGridColumn.AmountPaid]: "12%",
    [BankslipGridColumn.CreationType]: "10%",
    [BankslipGridColumn.CollectionType]: "10%",
    [BankslipGridColumn.Anticipation]: "12%",
    [BankslipGridColumn.Floating]: "75px",
  };

export const MapperBankslipGridColumnCaption: Record<
  BankslipGridColumn,
  string
> = {
  [BankslipGridColumn.CreatedAt]: "Criação",
  [BankslipGridColumn.DocumentNumber]: "Nº Documento",
  [BankslipGridColumn.OurNumber]: "Nosso Número",
  [BankslipGridColumn.Payer]: "Pagador",
  [BankslipGridColumn.Status]: "Status Boleto",
  [BankslipGridColumn.BadCredit]: "Status Negativação",
  [BankslipGridColumn.DueDate]: "Vencimento",
  [BankslipGridColumn.UpdatedAt]: "Últ. Atualização",
  [BankslipGridColumn.Amount]: "Valor",
  [BankslipGridColumn.AmountPaid]: "Liquidado",
  [BankslipGridColumn.CreationType]: "Tipo de Emissão",
  [BankslipGridColumn.CollectionType]: "Tipo do Boleto",
  [BankslipGridColumn.Anticipation]: "Antecipação",
  [BankslipGridColumn.Floating]: "Floating",
};

export const isAbleToArchive = (collection: BankingHub.Collection) => {
  return (
    [
      CollectionStatus.Failed,
      CollectionStatus.Expired,
      CollectionStatus.Canceled,
    ].includes(collection.status) ||
    ([CollectionStatus.Processing].includes(collection.status) &&
      dayjs().isAfter(collection.dueDate, "day"))
  );
};

export const isAbleToReprocess = ({
  createdAt,
  status,
}: BankingHub.Collection) => {
  return (
    status === CollectionStatus.Processing &&
    dayjs(createdAt).add(1, "hours").isBefore(dayjs())
  );
};

export const isAbleToEditFloating = ({
  amount,
  anticipation: { isAnticipated },
  status,
}: BankingHub.Collection) => {
  return (
    amount < BOLETED_AMOUNT &&
    [
      CollectionStatus.Created,
      CollectionStatus.Updated,
      CollectionStatus.Overdue,
      CollectionStatus.CancelFailed,
    ].includes(status) &&
    !isAnticipated
  );
};

export const isAbleToCancel = (collection: BankingHub.Collection) => {
  const {
    anticipation: { isAnticipated },
    status,
  } = collection;

  return (
    isAbleToReprocess(collection) ||
    ([
      CollectionStatus.Created,
      CollectionStatus.Updated,
      CollectionStatus.Overdue,
      CollectionStatus.CancelFailed,
    ].includes(status) &&
      !isAnticipated)
  );
};

const CollectionStatusFilters = [
  { label: "Todos", value: "" },
  { label: "Processando", value: CollectionStatus.Processing },
  {
    label: "Em aberto",
    value: [
      CollectionStatus.Created,
      CollectionStatus.Updating,
      CollectionStatus.Updated,
      CollectionStatus.Canceling,
    ].join(),
  },
  {
    label: "Pago",
    value: [CollectionStatus.Paid, CollectionStatus.ProcessingPayment].join(),
  },
  { label: "Vencido", value: CollectionStatus.Overdue },
  {
    label: "Cancelado",
    value: [CollectionStatus.Canceled, CollectionStatus.Expired].join(),
  },
];

export const assembleSections = (currentFilter: CollectionFilterFields) => {
  const payer: SelectableSearchSectionFilter = {
    title: "Dados do pagador",
    formId: "payer",
    data: [
      {
        filterParam: "payerName",
        label: "Nome",
        maskOption: { mask: /^[\W\w]{0,50}$/ },
      },
      {
        filterParam: "payerTaxId",
        label: "CPF / CNPJ",
        maskOption: {
          mask: [
            {
              mask: "000.000.000-00",
            },
            {
              mask: "00.000.000/0000-00",
            },
          ],
        },
        unmaskedValidator: (value: string) => validators.cpfCnpj(value),
      },
    ],
    defaultValue: {
      ...assembleSelectableSearchDefaultValues<CollectionFilterFields>(
        currentFilter,
        ["payerName", "payerTaxId"],
      ),
    },
    type: SectionFilterType.SelectableSearch,
  };

  const collectionInfo: SelectableSearchSectionFilter = {
    title: "Identificador do boleto",
    formId: "collectionInfo",
    data: [
      {
        filterParam: "documentNumber",
        label: "Nº Documento",
        maskOption: { mask: /^[\w\W]{0,15}$/ },
      },
      {
        filterParam: "ourNumber",
        label: "Nosso número",
        maskOption: { mask: /^[\d]{0,20}$/ },
      },
      {
        filterParam: "barcode",
        label: "Código de barras",
        maskOption: {
          mask: "000 0 000000000000000 00000 000000 00000000000000",
        },
      },
      {
        filterParam: "digitableLine",
        label: "Linha digitável",
        maskOption: {
          mask: "00000.00000 00000.000000 00000.000000 0 00000000000000",
        },
      },
    ],
    defaultValue: {
      ...assembleSelectableSearchDefaultValues<CollectionFilterFields>(
        currentFilter,
        ["documentNumber", "ourNumber", "barcode", "digitableLine"],
      ),
    },
    type: SectionFilterType.SelectableSearch,
  };

  const createdAt: DateRangeSectionFilter = {
    title: "Data de emissão",
    formId: "createdAt",
    type: SectionFilterType.DateRange,
    defaultValue: currentFilter.createdAt,
  };

  const dueDate: DateRangeSectionFilter = {
    title: "Data de vencimento",
    formId: "dueDate",
    type: SectionFilterType.DateRange,
    defaultValue: currentFilter.dueDate,
  };

  const settledAt: DateRangeSectionFilter = {
    title: "Data de liquidação",
    formId: "settledAt",
    type: SectionFilterType.DateRange,
    defaultValue: currentFilter.settledAt,
  };

  const status: MultSelectionSectionFilter = {
    title: "Status do Boleto",
    formId: "status",
    defaultValue: currentFilter.status,
    type: SectionFilterType.MultSelection,
    data: CollectionStatusFilters.filter(({ value }) => !!value).map(
      ({ label, value }) => ({ label: { primary: label }, value }),
    ),
  };

  const badCreditStatus: MultSelectionSectionFilter = {
    title: "Status da Negativação",
    formId: "badCreditStatus",
    defaultValue: currentFilter.badCreditStatus ?? [],
    type: SectionFilterType.MultSelection,
    data: [
      {
        label: { primary: "Em negativação" },
        value: [
          BadCreditStatus.Starting,
          BadCreditStatus.Processing,
          BadCreditStatus.WarningPeriod,
          BadCreditStatus.FailedToSend,
        ].join(),
      },
      { label: { primary: "Negativado" }, value: BadCreditStatus.Confirmed },
      {
        label: { primary: "Negativação cancelada" },
        value: BadCreditStatus.Canceled,
      },
    ],
  };

  const anticipation: SingleSelectionSectionFilter = {
    title: "Status da Antecipação",
    formId: "isAnticipated",
    defaultValue: currentFilter.isAnticipated,
    type: SectionFilterType.SingleSelection,
    data: [
      { label: { primary: "Boleto antecipado" }, value: "true" },
      { label: { primary: "Boleto não antecipado" }, value: "false" },
    ],
  };

  return [
    payer,
    collectionInfo,
    createdAt,
    dueDate,
    settledAt,
    status,
    badCreditStatus,
    anticipation,
  ];
};

const formatDateFilter = (date: dayjs.Dayjs) => {
  return date.format("YYYY-MM-DD");
};

export const assembleFilterParams = (
  fields: CollectionFilterFields,
): CollectionFilterParams => {
  const filterParams: CollectionFilterParams = {};

  if (fields.payerName) filterParams["payer.name"] = fields.payerName;
  if (fields.payerTaxId) filterParams["payer.taxId"] = fields.payerTaxId;

  if (fields.documentNumber)
    filterParams.documentNumber = encodeURI(fields.documentNumber);
  if (fields.ourNumber) filterParams.ourNumber = fields.ourNumber;
  if (fields.barcode) filterParams.barcode = fields.barcode;
  if (fields.digitableLine) filterParams.digitableLine = fields.digitableLine;

  if (fields.createdAt) {
    const [startCreatedAt, endCreatedAt] = fields.createdAt;
    filterParams.startCreatedAt = formatDateFilter(startCreatedAt);
    filterParams.endCreatedAt = formatDateFilter(endCreatedAt);
  }

  if (fields.dueDate) {
    const [startDueDate, endDueDate] = fields.dueDate;
    filterParams.startDueDate = formatDateFilter(startDueDate);
    filterParams.endDueDate = formatDateFilter(endDueDate);
  }

  if (fields.settledAt) {
    const [startSettledAt, endSettledAt] = fields.settledAt;
    filterParams.startSettledAt = formatDateFilter(startSettledAt);
    filterParams.endSettledAt = formatDateFilter(endSettledAt);
  }

  if (fields.status) filterParams.status = fields.status.join();

  if (fields.badCreditStatus) {
    filterParams["badCredit.status"] = fields.badCreditStatus.join();
  }

  if (fields.isAnticipated) filterParams.isAnticipated = fields.isAnticipated;

  return filterParams;
};
