/**
 * Generates a regular expression that matches the string, ignoring possible spaces
 */
export const buildTextHighlight = (text = "") => {
  const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regExpString = text ? `(${escapedText.split(" ").join("|")})` : "";
  return new RegExp(regExpString.trim(), "gi");
};

/**
 * Generates a regular expression that matches a tax id, ignoring possible dots, dashes or slashed
 */
export const buildTaxIdHighlight = (value = "") => {
  const reString = value?.replace(/\D/g, "").split("").join("(\\.|-|/)?");
  return new RegExp(reString.trim(), "i");
};
