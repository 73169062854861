import { OCard, OCardBody, OCardHeader, OTypography } from "@maestro/react";
import {
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { workflowContextHistoryGrid } from "./workflow-context-history.grid";
import { workflowContextOutputsGrid } from "./workflow-context-outputs.grid";
import { WorkflowContext } from "./workflow-contexts.types";

export const workflowContextsGrid = {
  datagrid: {
    noDataText: "Nenhum contexto encontrado",
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
    },
    {
      dataField: "createdDate",
      caption: "Data de criação",
      dataType: "datetime",
    },
    {
      sortOrder: "desc",
      dataField: "updatedDate",
      caption: "Última atualização",
      dataType: "datetime",
    },
    {
      caption: "Status",
      dataField: "status",
    },
    {
      caption: "Processador",
      cellRender: ({ data }) =>
        data.processorConfig?.name ?? data.processorConfig?.processor?.type,
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => (
        <RouterButton
          role={
            roles.workflow.product.leadsDetailsContextsUpdateStatusPage.role
          }
          href={corporateRouter.routes.workflow.product.leads.details.contexts.updateStatus.path(
            {
              id: data.workflowLeadId,
              processorContextId: data.id,
              processorId: data.processorConfig?.processor?.id,
            },
          )}
          outline
        >
          Atualizar status
        </RouterButton>
      ),
    },
  ],
  masterDetail: {
    enabled: true,
    component: ({ data: { data } }) => (
      <div className="d-flex flex-column gap-4">
        <OCard>
          <OCardHeader>
            <OTypography weight="bold">Históricos de atualizações</OTypography>
          </OCardHeader>
          <OCardBody>
            <ODataGridGenerator
              grid={workflowContextHistoryGrid}
              dataSource={data.history}
            />
          </OCardBody>
        </OCard>
        <OCard>
          <OCardHeader>
            <OTypography weight="bold">Outputs</OTypography>
          </OCardHeader>
          <OCardBody>
            <ODataGridGenerator
              grid={workflowContextOutputsGrid}
              dataSource={data.processorOutputsOnContext}
            />
          </OCardBody>
        </OCard>
      </div>
    ),
  },
} satisfies ODataGridGeneratorConfig<WorkflowContext>;
