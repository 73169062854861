import { Roles } from "roles/roles.types";

export const empresasRoles = {
  product: {
    tab: {
      role: "admin:CompanyTabProduct.View",
      description: "Visualização da tab 'Empresas' na visão produto",
    },
    currentAccountPjPage: {
      role: "admin:CompanyCurrentAccountPjPageProduct.View",
      description:
        "Visualização da página 'Conta Corrente PJ' na visão produto",
    },
    currentAccountPjInvitePage: {
      role: "admin:CompanyCurrentAccountPjInvitePageProduct.View",
      description:
        "Visualização da página 'Conta Corrente PJ - Convite' na visão produto",
    },
    currentAccountPjNewAccountPage: {
      role: "admin:CompanyCurrentAccountPjNewAccountPageProduct.View",
      description:
        "Visualização da página 'Conta Corrente PJ - Nova Conta' na visão produto",
    },
    currentAccountPfPage: {
      role: "admin:CompanyCurrentAccountPfPageProduct.View",
      description:
        "Visualização da página 'Conta Corrente PF' na visão produto",
    },
    energyPage: {
      role: "admin:CompanyEnergyPageProduct.View",
      description: "Visualização da página 'Energia' na visão produto",
    },
    energyInvitePage: {
      role: "admin:CompanyEnergyInvitePageProduct.View",
      description:
        "Visualização da página 'Energia - Convite' na visão produto",
    },
    assignorPage: {
      role: "admin:CompanyAssignorPageProduct.View",
      description: "Visualização da página 'Fornecedores' na visão produto",
    },
    assignorNewAccountPage: {
      role: "admin:CompanyAssignorNewAccountPageProduct.View",
      description:
        "Visualização da página 'Fornecedores - Pré-cadastro de clientes' na visão produto",
    },
    cedenteCompletoPage: {
      role: "admin:CompanyCedenteCompletoPageProduct.View",
      description: "Visualização da página 'Cedente Completo' na visão produto",
    },
    updateCedenteCompletoPage: {
      role: "admin:CompanyUpdateCedenteCompletoPageProduct.View",
      description:
        "Visualização da página 'Cedente Completo - Editar' na visão produto",
    },
    payerPage: {
      role: "admin:CompanyPayerPageProduct.View",
      description: "Visualização da página 'Sacado Simples' na visão produto",
    },
    importPayerPage: {
      role: "admin:CompanyPayerImportPageProduct.View",
      description:
        "Visualização da página 'Registrar Sacado Simples' na visão produto",
    },
    batchImportPayerPage: {
      role: "admin:CompanyPayerBatchImportPageProduct.View",
      description:
        "Visualização da página 'Sacado Simples - Importar em lote' na visão produto",
    },
    reviewAccountMonitorPage: {
      role: "admin:CompanyReviewAccountMonitorPageProduct.View",
      description:
        "Visualização da página 'Revisão Cadastral' na visão produto",
    },
    dataUpdateMonitorPage: {
      role: "admin:CompanyDataUpdateMonitorPageProduct.View",
      description:
        "Visualização da página 'Atualizacao cadastral' na visão produto",
    },
    groupsPage: {
      role: "admin:CompanyGroupsPageProduct.View",
      description: "Visualização da página 'Grupos' na visão produto",
    },
    groupsAddPage: {
      role: "admin:CompanyGroupsAddPageProduct.View",
      description:
        "Visualização da página 'Grupos - Adicionar' na visão produto",
    },
    groupsDetailsPage: {
      role: "admin:CompanyGroupsDetailsPageProduct.View",
      description:
        "Visualização da página 'Grupos - Detalhes' na visão produto",
    },
    groupsEditPage: {
      role: "admin:CompanyGroupsEditPageProduct.View",
      description: "Visualização da página 'Grupos - Editar' na visão produto",
    },
    otherPage: {
      role: "admin:CompanyOtherPageProduct.View",
      description: "Visualização da página 'Outros' na visão produto",
    },
    b2bColumns: {
      role: "admin:CompanyB2BColumnsProduct.View",
      description: "Visualização das colunas de B2B no monitor de empresas",
    },
    itColumns: {
      role: "admin:CompanyITColumnsProduct.View",
      description: "Visualização das colunas de IT no monitor de empresas",
    },
    legalNatureColumns: {
      role: "admin:CompanyLegalNatureColumnsProduct.View",
      description:
        "Visualização das colunas de natureza legal no monitor de empresas",
    },
    clientDeskPage: {
      role: "admin:ClientDeskPageProduct.View",
      description: "Visualização da página 'Clientes - Mesa' na visão produto",
    },
    clientDeskSegmentColumns: {
      role: "admin:CompanyClientDeskSegmentColumnsProduct.View",
      description:
        "Visualização das colunas de Clientes - Mesa - Segmentos no monitor de empresas",
    },
    clientDeskIdColumn: {
      role: "admin:CompanyClientDeskIdColumnProduct.View",
      description:
        "Visualização das colunas de Clientes - Mesa - Id no monitor de empresas",
    },
    clientDeskNameColumn: {
      role: "admin:CompanyClientDeskNameColumnProduct.View",
      description:
        "Visualização das colunas de Clientes - Mesa - Nome no monitor de empresas",
    },
    clientDeskCodeColumn: {
      role: "admin:CompanyClientDeskCodeColumnProduct.View",
      description:
        "Visualização das colunas de Clientes - Mesa - Código no monitor de empresas",
    },
  },
} satisfies Roles;
