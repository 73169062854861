import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { customer } from "contexts/customer";
import { EdmLiteral } from "devextreme/data/odata/utils";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const referralsRelationshipsODataGrid = {
  datagrid: {
    noDataText: "Nenhuma indicação encontrada",
    filterRow: { visible: true },
    onDataErrorOccurred: (e) => {
      if (e.error) e.error.message = "Erro ao buscar indicações.";
    },
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubPartners.admin.odata.adminGetODataReferralRelationships,
      {
        dataSourceOptions: {
          sort: [
            {
              desc: true,
              selector: "createdDate",
            },
          ],
        },
        odataStoreOptions: {
          beforeSend: ({ params }) => {
            // eslint-disable-next-line no-param-reassign
            params.originatorIdentification = customer.value?.identification;
          },
        },
      },
    ),
  },
  columns: [
    {
      dataField: "officialName",
      caption: "Razão Social",
      width: 240,
    },
    {
      dataField: "identification",
      caption: "CNPJ",
      width: 160,
      format: (value) => masks.cpfCnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "referredOriginatorSubTypes",
      caption: "Subtipos do Indicado",
      filterOperations: ["contains", "notcontains"],
      allowSorting: false,
      calculateFilterExpression: (
        filterValue: string,
        selectedFilterOperation,
      ) => {
        let expression = "";

        if (selectedFilterOperation === "notcontains") {
          expression = `ReferredOriginatorSubTypes/any(subtype:not(contains(tolower(subtype), '${filterValue.toLowerCase()}')))`;
        } else {
          expression = `ReferredOriginatorSubTypes/any(subtype:contains(tolower(subtype), '${filterValue.toLowerCase()}'))`;
        }

        return [[new EdmLiteral(expression)]];
      },
      width: 200,
    },
    {
      dataField: "originatorName",
      caption: "Originador",
      allowEditing: false,
      width: 240,
      calculateFilterExpression: (filterValue) => [
        ["originatorName", "contains", filterValue],
        "or",
        [
          "originatorIdentification",
          "contains",
          filterValue.replace(/[^\w]/g, ""),
        ],
      ],
      cellRender: ({ data }) => (
        <div className="d-flex flex-column gap-2">
          <OTypography>{data.originatorName?.toUpperCase()}</OTypography>
          <OTypography type="default">
            {masks.cpfCnpj(data.originatorIdentification)}
          </OTypography>
        </div>
      ),
    },
    {
      dataField: "operatorName",
      caption: "Operador",
      allowEditing: false,
      width: 240,
      calculateFilterExpression: (filterValue) => [
        ["operatorName", "contains", filterValue],
        "or",
        [
          "operatorIdentification",
          "contains",
          filterValue.replace(/[^\w]/g, ""),
        ],
      ],
      cellRender: ({ data }) => (
        <div className="d-flex flex-column gap-2">
          <OTypography>{data.operatorName?.toUpperCase() || "-"}</OTypography>
          <OTypography type="default">
            {masks.cpfCnpj(data.operatorIdentification)}
          </OTypography>
        </div>
      ),
    },
    {
      dataField: "partnershipType",
      caption: "Tipo de Parceria",
    },
    {
      dataField: "originatorSubTypes",
      caption: "Subtipos do Originador",
      filterOperations: ["contains", "notcontains"],
      allowSorting: false,
      calculateFilterExpression: (
        filterValue: string,
        selectedFilterOperation,
      ) => {
        let expression = "";

        if (selectedFilterOperation === "notcontains") {
          expression = `OriginatorSubTypes/any(subtype:not(contains(tolower(subtype), '${filterValue.toLowerCase()}')))`;
        } else {
          expression = `OriginatorSubTypes/any(subtype:contains(tolower(subtype), '${filterValue.toLowerCase()}'))`;
        }

        return [[new EdmLiteral(expression)]];
      },
      width: 200,
    },
    {
      dataField: "mainOriginatorName",
      caption: "Instituição Responsável",
      width: 240,
    },
    {
      dataField: "createdDate",
      caption: "Data do vínculo",
    },
    {
      dataField: "createdBy",
      caption: "Criado por",
      width: 160,
    },
    {
      dataField: "isActive",
      caption: "Vínculo ativo",
    },
    {
      dataField: "deactivatedAt",
      caption: "Data da desativação",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<
        typeof service.hubPartners.odata.client.getReferralsRelationship
      >
    >["data"]["value"]
  >[number]
>;
