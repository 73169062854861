import {
  modalManager,
  OButton,
  OCol,
  OField,
  OIcon,
  OOption,
  ORFieldSelect,
  ORow,
  OTooltip,
} from "@maestro/react";
import { StyledSelect } from "../../../../edit-cedente-completo.styles";
import {
  DocumentSubtypeMap,
  documentTypeMap,
} from "../../../../edit-cedente-completo.utils";
import { FilesModal } from "../../../files-modal";
import { useDocumentField } from "./document-field.hook";

interface DocumentFieldProps {
  documentKey: string;
  documentsWithError: number[];
  index: number;
}

export const DocumentField = ({
  documentKey,
  documentsWithError,
  index,
}: DocumentFieldProps) => {
  const {
    documentSubtypeError,
    fields,
    fileError,
    handleAddFile,
    handleRemoveFile,
    loading,
    possibleDocumentSubtypes,
    register,
  } = useDocumentField(documentsWithError, index);

  return (
    <ORow key={documentKey} className="w-100">
      <OCol xs={3}>
        <OField
          htmlFor={`status_icon_${index}`}
          label="Status do documento"
          className="d-flex flex-column align-items-start gap-2"
        >
          <div className="d-flex flex-row gap-2 align-items-center">
            <OIcon
              id={`status_icon_${index}`}
              category="orq"
              icon={fileError ? "orq-close" : "orq-check"}
              type={fileError ? "danger" : "success"}
              size="xxl"
            />
            {fileError && (
              <OTooltip floating>
                <span slot="tooltip-content">{fileError}</span>
                <OIcon category="orq" icon="orq-info" size="lg" />
              </OTooltip>
            )}
          </div>
        </OField>
      </OCol>
      <OCol xs={3}>
        <ORFieldSelect
          id={`documents.${index}.type`}
          name={`documents.${index}.type`}
          label="Tipo de documento"
        >
          {Object.entries(documentTypeMap).map(([value, label]) => (
            <OOption key={value} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={3}>
        <OField
          htmlFor={`documents.${index}.subType`}
          label="Subtipo de documento"
          error={!!documentSubtypeError}
          message={documentSubtypeError?.message}
        >
          <StyledSelect
            {...register(`documents.${index}.subType`)}
            id={`documents.${index}.subType`}
            name={`documents.${index}.subType`}
            disabled={!possibleDocumentSubtypes}
          >
            {possibleDocumentSubtypes?.map((document) => (
              <option key={document} value={document}>
                {DocumentSubtypeMap[document]}
              </option>
            ))}
          </StyledSelect>
        </OField>
      </OCol>
      <OCol
        xs={3}
        className="d-flex flex-row align-items-center justify-content-end"
      >
        <OButton
          type="tertiary"
          onClick={() => modalManager.show(`documents.${index}-files-modal`)}
        >
          Ver arquivos anexados
        </OButton>
      </OCol>
      <FilesModal
        fieldName={`documents.${index}`}
        fields={fields}
        handleAddFile={handleAddFile}
        handleRemoveFile={handleRemoveFile}
        loading={loading}
      />
    </ORow>
  );
};
