export const defaultUserNotificationTypeData = [
  {
    label: "Nenhum",
    value: "",
  },
  {
    label: "Email",
    value: "EMAIL",
  },
  {
    label: "WhatsApp",
    value: "WHATSAPP",
  },
];

export const backButtonActionTypes = [
  {
    label: "Padrão",
    value: "DEFAULT",
  },
  {
    label: "Eventos",
    value: "EVENT",
  },
];
