import { OButton } from "@maestro/react";
import styled from "styled-components";

export const StyledButton = styled(OButton)`
  button {
    text-decoration: underline;
    padding-left: 0px;
    padding-right: 0px;
  }
`;
