import { OCol, ORow } from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { GetApiAdminViewResponse } from "services/hubcreditmanager/models/responses";

interface WorkflowConfigViewFormProps {
  viewOptions?: GetApiAdminViewResponse;
}

export const WorkflowConfigViewForm = ({
  viewOptions,
}: WorkflowConfigViewFormProps) => {
  const options =
    viewOptions?.map((option) => ({
      value: option.id,
      label: `${option.name} (${option.id})`,
    })) ?? [];

  return (
    <form spellCheck="false">
      <ORow>
        <OCol>
          <SelectSearchField
            id="viewId"
            name="viewId"
            label="View"
            labelSize="md"
            placeholder="Selecionar"
            options={options}
          />
        </OCol>
      </ORow>
    </form>
  );
};
