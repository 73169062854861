import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OLoader,
  ORFieldInput,
  ORFieldInputDate,
  ORow,
} from "@maestro/react";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { AddressesList } from "./_compose/addresses-list";
import { DocumentsList } from "./_compose/documents-list";
import { EmailsList } from "./_compose/emails-list";
import { ErrorsList } from "./_compose/errors-list";
import { PhonesList } from "./_compose/phones-list";
import { RepresentativesList } from "./_compose/representatives-list";
import { useEditCedenteCompleto } from "./edit-cedente-completo.hook";

export const EditCedenteCompleto = () => {
  const {
    canSubmit,
    documentsWithError,
    form,
    loading,
    ocurrences,
    representatives,
    setDocumentsWithError,
    submit,
  } = useEditCedenteCompleto();

  return (
    <>
      <PageTitle title="Editar cedente completo" />
      <FormTemplate
        actions={
          <OButton disabled={!canSubmit} onClick={submit}>
            Enviar
          </OButton>
        }
      >
        {loading && <OLoader absolute backdrop />}
        <FormProvider {...form}>
          <div className="d-flex flex-column gap-3">
            <ErrorsList
              rejections={ocurrences}
              setDocumentsWithError={setDocumentsWithError}
            />
            <OCard>
              <OCardBody>
                <ORow>
                  <OCol xs={4}>
                    <ORFieldInput
                      tag="cnpj"
                      id="taxId"
                      name="taxId"
                      label="Tax Id"
                      disabled
                    />
                  </OCol>
                  <OCol xs={4}>
                    <ORFieldInput
                      tag="text"
                      id="name"
                      name="name"
                      label="Nome"
                      disabled
                    />
                  </OCol>
                  <OCol xs={4}>
                    <ORFieldInput
                      tag="text"
                      id="payerName"
                      name="payerName"
                      label="Nome do sacado"
                      disabled
                    />
                  </OCol>
                  <OCol xs={4}>
                    <ORFieldInput
                      tag="cpfcnpj"
                      id="payerTaxId"
                      name="payerTaxId"
                      label="Tax ID do sacado"
                      disabled
                    />
                  </OCol>
                  <OCol xs={4}>
                    <ORFieldInput
                      tag="text"
                      id="status"
                      name="status"
                      label="Status"
                      disabled
                    />
                  </OCol>
                  <OCol xs={4}>
                    <ORFieldInputDate
                      id="birthDate"
                      name="birthDate"
                      label="Data de nascimento"
                      disabled
                    />
                  </OCol>
                  <OCol xs={12} className="d-flex flex-column gap-2">
                    <PhonesList fieldName="phones" />
                    <AddressesList fieldName="addresses" />
                    <EmailsList fieldName="emails" />
                    <DocumentsList documentsWithError={documentsWithError} />
                    <RepresentativesList representatives={representatives} />
                  </OCol>
                </ORow>
              </OCardBody>
            </OCard>
          </div>
        </FormProvider>
      </FormTemplate>
    </>
  );
};
