import {
  OButton,
  OCard,
  OCardBody,
  OCheckbox,
  OCol,
  OIcon,
  OLabel,
  OLoader,
  ORFieldCheckboxGroup,
  ORow,
} from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { service } from "services";

export const ExchangeDocumentGroupsForm = () => {
  const {
    callService: getNatureDocumentConfigs,
    hasError,
    loading,
    value: documentConfigs,
  } = useServiceCall(service.hubFx.nature.getNatureDocumentConfigs);

  const documentConfigsOptions = useMemo(
    () =>
      documentConfigs?.map((documentConfig) => ({
        label: documentConfig.typeDescription ?? "",
        value: documentConfig.id,
      })) ?? [],
    [documentConfigs],
  );

  useEffect(() => {
    getNatureDocumentConfigs();
  }, [getNatureDocumentConfigs]);

  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardBody>
          <ORow>
            <OCol sm={12} md={12} lg={4}>
              <ORFieldCheckboxGroup id="isRequired" name="isRequired">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox size="xs" id="isRequired-checkbox" value="true" />
                  <OLabel htmlFor="isRequired-checkbox">Obrigatório?</OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol sm={12} md={12} lg={4}>
              <ORFieldCheckboxGroup id="mustShowForPf" name="mustShowForPf">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="mustShowForPf-checkbox"
                    value="true"
                  />
                  <OLabel htmlFor="disableOutgoing-checkbox">
                    Mostrar para PF?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol sm={12} md={12} lg={4}>
              <ORFieldCheckboxGroup id="mustShowForPj" name="mustShowForPj">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="mustShowForPj-checkbox"
                    value="true"
                  />
                  <OLabel htmlFor="mustShowForPj-checkbox">
                    Mostrar para PJ?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol sm={12} md={12} lg={4}>
              <ORFieldCheckboxGroup
                id="mustShowWhenOutgoing"
                name="mustShowWhenOutgoing"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="mustShowWhenOutgoing-checkbox"
                    value="true"
                  />
                  <OLabel htmlFor="mustShowWhenOutgoing-checkbox">
                    Mostrar para remessa?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
            <OCol sm={12} md={12} lg={4}>
              <ORFieldCheckboxGroup
                id="mustShowWhenIncoming"
                name="mustShowWhenIncoming"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="mustShowWhenIncoming-checkbox"
                    value="true"
                  />
                  <OLabel htmlFor="mustShowWhenIncoming-checkbox">
                    Mostrar para internação?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
          </ORow>

          <OCol sm={12} md={6} className="align-items-center">
            <SelectSearchMultiple
              id="documentConfigIds"
              name="documentConfigIds"
              options={documentConfigsOptions}
              label="Documentos"
              placeholder="Buscar"
            />
            {loading && <OLoader />}
            {hasError && (
              <OButton
                type="default"
                outline
                onClick={() => getNatureDocumentConfigs()}
              >
                <OIcon
                  category="fa"
                  icon="fa-repeat"
                  size="sm"
                  type="default"
                />
              </OButton>
            )}
          </OCol>
        </OCardBody>
      </OCard>
    </div>
  );
};
