import {
  landingPageTipsBndes,
  landingPageTipsBtg,
  landingPageTipsSoftware,
  landingPageTipsSolar,
} from "../tips/tips.utils";
import { IBannerMap } from "./banner.type";

export const bannerMap: IBannerMap = {
  KGIRO_BNDES: {
    description:
      "Solicite agora a linha de crédito oferecida em parceria entre o BTG Pactual Empresas e o BNDES para Micro, Pequenas e Médias Empresas.",
    title: "Apoio financeiro para a sua empresa crescer",
    // image: {
    //   ref: bndesBanner,
    //   alt: "BNDES Crédito Digital",
    // },
    action: {
      text: "Solicitar agora",
      ref: "simulacao/kgiro/",
    },
    badge: "BNDES Crédito Digital",
    landingPageInfo: {
      title: "BNDES Crédito Digital",
      description:
        "Solução de crédito rotativo para as necessidades do dia-a-dia para micro e pequenas empresas.",
      tips: {
        title: "Solução para Micro e Pequenas Empresas",
        list: landingPageTipsBndes,
      },
    },
  },
  KGIRO_BTG: {
    description:
      "Disponível para empresas de todos os portes, facilitando tanto o crescimento quanto a estabilidade financeira",
    title: "Apoio da sua empresa na gestão financeira diária",
    // image: {
    //   ref: btgBanner,
    //   alt: "Capital de Giro BTG",
    // },
    action: {
      text: "Solicitar agora",
      ref: "simulacao/kgiro/",
    },
    badge: "Capital de Giro BTG",
    landingPageInfo: {
      title: "Capital de Giro BTG",
      description:
        "A linha de capital de giro do BTG Pactual é flexível, podendo ser personalizada de acordo com as necessidades específicas da sua empresa, incluindo prazos e condições competitivas.",
      tips: {
        title: "Solução para sua empresa",
        list: landingPageTipsBtg,
      },
    },
  },
  SOFTWARE_FINANCING_BTG: {
    description:
      "Prazos e condições competitivas, como taxas de juros pré-fixadas e opções de parcelamento, sujeito a análise de crédito.",
    title: "Mais tecnologia para a sua empresa",
    // image: {
    //   ref: softwareBanner,
    //   alt: "Financiamento de Software",
    // },
    action: {
      text: "Solicitar agora",
      ref: "simulacao/software_financing/",
    },
    badge: "Financiamento de Software",
    landingPageInfo: {
      title: "Financiamento de Software",
      description:
        "Opções de crédito que podem ser utilizadas para adquirir licenças de software, infraestrutura tecnológica e outros serviços relacionados, promovendo o desenvolvimento digital da sua empresa.",
      tips: {
        title: "Solução para sua empresa",
        list: landingPageTipsSoftware,
      },
    },
  },
  SOLAR_BTG: {
    description:
      "Dê mais energia para o seu negócio com o financiamento solar sem burocracia.",
    title: "Energia solar",
    // image: {
    //   ref: solarBanner,
    //   alt: "Crédito Solar",
    // },
    action: {
      text: "Solicitar agora",
      ref: "simulacao/solar/",
    },
    badge: "Crédito Solar",
    landingPageInfo: {
      title: "Crédito Solar",
      description:
        "Linha de crédito voltada para empresas e integradores de projetos de energia solar. Financie a compra de painéis fotovoltaicos, bem como os custos do projeto de instalação.",
      tips: {
        title: "Solução para sua empresa",
        list: landingPageTipsSolar,
      },
    },
  },
};
