import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const useRemoveSignatureGroup = (
  ruleId: string | number,
  groupId: string | number,
) => {
  const { callService, loading } = useServiceCall(
    service.paperclipv2.removeGroupOfSignatures,
  );

  const navigate = useNavigate();

  const removeGroupOfSignatures = useCallback(async () => {
    const { success } = await callService(ruleId, groupId, {
      groupId: Number(groupId),
      signatureRuleId: Number(ruleId),
    });

    if (success) {
      OToastManager.success("Grupo de assinaturas removido com sucesso");
      navigate(corporateRouter.routes.antecipacaoDeCartoes.customer.signatures.path);
    } else OToastManager.danger("Erro ao remover o grupo de assinaturas");
  }, [callService, groupId, navigate, ruleId]);

  return { loading, removeGroupOfSignatures };
};
