import {
  IIcon,
  OBadge,
  OButton,
  OCard,
  OCardBody,
  OCardHeader,
  OIcon,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import React from "react";
import { useNavigate } from "react-router";
import {
  CardContainer,
  CentralizedLoader,
  StyledButton,
} from "./products-card.style";
import { BNDESFrameStatus } from "../../offers.type";

interface ProductCardProps {
  id: number;
  title: string;
  subtitle: string;
  iconName: IIcon;
  limit?: number;
  redirectPath: string;
  creditLineVisibleName: string;
  creditLineIcon: string;
  creditLineDescription: string;
  offerId: number;
  fundingType: string;
  mustWaitForBndesFrame: boolean;
  frameStatus: BNDESFrameStatus;
  identification: string;
}

export const ProductCard: React.FC<ProductCardProps> = (props) => {
  const {
    title,
    iconName,
    limit = 0,
    redirectPath,
    creditLineDescription,
    mustWaitForBndesFrame,
    frameStatus,
    identification,
  } = props;

  const navigate = useNavigate();

  const disabledButton = mustWaitForBndesFrame && frameStatus === "PENDING";

  return (
    <CardContainer>
      <OCard>
        <OCardHeader divider>
          <div className="d-flex flex-column gap-2 align-items-start">
            <OBadge circle type="tertiary" size="lg">
              <OIcon category="orq" icon={iconName} size="xl" type="light" />
            </OBadge>
            <div className="position-relative w-100">
              <OTypography type="dark">{title}</OTypography>
            </div>
          </div>
        </OCardHeader>
        <OCardBody>
          <div className="d-flex flex-column gap-3">
            <OTypography type="dark-60" size="sm">
              {creditLineDescription}
            </OTypography>
            <div className="d-flex w-100 justify-content-between py-2">
              <OTypography size="sm">Oferta</OTypography>
              <OTypography size="sm">
                {masks.currency(limit, "R$", ".")}
              </OTypography>
            </div>
            <div className="d-flex gap-2">
              {limit > 0 && (
                <StyledButton
                  onClick={() => navigate(redirectPath)}
                  outline
                  disabled={disabledButton}
                >
                  {disabledButton ? (
                    <CentralizedLoader size="sm" />
                  ) : (
                    <OTypography type="dark" size="sm">
                      Conferir
                    </OTypography>
                  )}
                </StyledButton>
              )}
              <OButton
                bordered={false}
                outline
                onClick={() => {
                  navigate(`landing-page/${identification}`);
                }}
                type="dark"
              >
                <div className="d-flex gap-1 align-items-center">
                  <OTypography size="sm">Saiba mais</OTypography>
                  <OIcon category="orq" icon="orq-chevron-right" size="sm" />
                </div>
              </OButton>
            </div>
          </div>
        </OCardBody>
      </OCard>
    </CardContainer>
  );
};
