import { yupResolver } from "@hookform/resolvers/yup";
import { OUploadCustomEvent } from "@maestro/core";
import {
  OCol,
  ORFieldInput,
  ORFieldUploadInput,
  ORow,
  OToastManager,
} from "@maestro/react";
import { isAxiosError } from "axios";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  createCustomerCertificateFormDefaultValues,
  createCustomerCertificateFormSchema,
  CreateCustomerCertificateFormValues,
} from "./create-e-cnpj.schema";

export const CreateECNPJ = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm<CreateCustomerCertificateFormValues>({
    defaultValues: createCustomerCertificateFormDefaultValues,
    resolver: yupResolver(createCustomerCertificateFormSchema),
  });
  const { handleSubmit, setValue } = form;

  const navigate = useNavigate();

  const handleAddFile = useCallback(
    (e: OUploadCustomEvent<File>) => {
      const file = e.detail;

      setValue("file", [file]);
    },
    [setValue],
  );

  const submitCertificate = useMemo(
    () =>
      handleSubmit(async ({ file, taxId, password }) => {
        try {
          setLoading(true);

          const formData = new FormData();
          formData.append("file", file[0]);
          formData.append("taxId", taxId);
          formData.append("password", password);

          await service.hubCreditManager.createCustomerCertificate(formData);

          OToastManager.success("O certificado foi registrado com sucesso.");

          navigate(corporateRouter.routes.emprestimos.product.eCNPJs.path);
        } catch (e) {
          if (
            isAxiosError(e) &&
            e.response?.data.errorType === "CryptographicException"
          )
            OToastManager.danger("A senha especificada está incorreta.");
          else if (
            isAxiosError(e) &&
            e?.response?.data.exceptionMessage === "LegalEntityNotFound"
          ) {
            OToastManager.danger(
              "CNPJ não encontrado / Conta sem acesso à seção de Crédito",
            );
          } else
            OToastManager.danger(
              "Um erro ocorreu ao tentarmos registrar o certificado e-CNPJ.",
            );
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, navigate],
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Registrar certificado e-CNPJ"
          description="A senha para o arquivo correspondente deve estar correta"
        />
      }
      actions={
        <LoadingButton loading={loading} onClick={submitCertificate}>
          Criar certificado
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol>
              <ORFieldInput tag="cnpj" id="taxId" name="taxId" label="CNPJ" />
            </OCol>
            <OCol>
              <ORFieldInput
                tag="secret"
                id="password"
                name="password"
                label="Senha"
              />
            </OCol>
          </ORow>
          <ORow>
            <ORFieldUploadInput
              id="file"
              name="file"
              accept=".pfx"
              size="md"
              inputLabel="Selecionar certificado"
              multiple={false}
              disabled={loading}
              handleAddFile={handleAddFile}
            />
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
