import { OOption, ORFieldInputDate, ORFieldSelect } from "@maestro/react";
import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { currencySymbol } from "utils/currency";
import { getValueFromMap } from "utils/get-value-from-map";
import { LimitRequest } from "./limit-details.types";
import {
  getCreditType,
  parsedAmortizationFrequency,
} from "./limit-details.utils";

export const limitDetailsGenerator = (limitRequest: LimitRequest) => {
  limitRequest?.rate?.quotes?.sort(
    (a, b) =>
      Number(a.minTerm) - Number(b.minTerm) || a.monthlyValue - b.monthlyValue,
  );

  const hasCurrentLimit = limitRequest?.limitId && limitRequest?.currentLimit;
  const limitAmount = hasCurrentLimit
    ? limitRequest?.currentLimit
    : limitRequest;

  return {
    limit: {
      items: [
        {
          label: "Nome da empresa",
          value: hasCurrentLimit
            ? limitRequest?.currentLimit?.customer.legalEntity.name ?? "-"
            : limitRequest?.customer.legalEntity.name ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CPF/CNPJ",
          value: masks.cpfCnpj(
            hasCurrentLimit
              ? limitRequest?.currentLimit?.customer.legalEntity.taxId ?? "-"
              : limitRequest?.customer.legalEntity.taxId ?? "-",
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Limite",
          value: limitAmount
            ? masks.currency(
                limitAmount?.amount,
                getValueFromMap(currencySymbol, limitAmount?.currencyType) ??
                  currencySymbol.BRL,
              )
            : "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Valor disponível",
          value: limitRequest?.currentLimit?.availableAmount
            ? masks.currency(
                limitRequest?.currentLimit?.availableAmount,
                getValueFromMap(
                  currencySymbol,
                  limitRequest?.currentLimit?.currencyType,
                ) ?? currencySymbol.BRL,
              )
            : "-",
          visible: !!hasCurrentLimit,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Produto",
          value: hasCurrentLimit
            ? limitRequest?.currentLimit?.creditLine.name ?? "-"
            : limitRequest?.creditLineConfiguration.creditLine.name ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Tipo",
          value: limitRequest?.currentLimit?.limitType ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Identificação",
          value: hasCurrentLimit
            ? limitRequest?.currentLimit?.creditLine.identification ?? "-"
            : limitRequest?.creditLineConfiguration.creditLine.identification ??
              "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Aprovador",
          value: limitRequest?.typeRequest ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Origem",
          value: limitRequest?.originator ?? "-",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Book / Estratégia",
          grid: { md: 4, xl: 3 },
          value: limitRequest?.bookStrategy
            ? `${limitRequest.bookStrategy.book.bookName} (${limitRequest.bookStrategy.book.bookCr}) / ${limitRequest.bookStrategy.strategy.strategyName} (${limitRequest.bookStrategy.strategy.strategyCr})`
            : "-",
        },
        {
          label: "Tipo de financiamento",
          value: limitRequest?.fundingType,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    contingency: {
      items: [
        {
          label: "Data de expiração do limite",
          value: (
            <ORFieldInputDate
              size="md"
              id="expirationDate"
              name="expirationDate"
            />
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Status",
          value:
            hasCurrentLimit || limitRequest?.parentOfferId ? (
              <ORFieldSelect id="status" name="status">
                {[
                  "APPROVED",
                  "CONSUMED",
                  "PARTIALLY_CONSUMED",
                  "REJECTED",
                  "EXPIRED",
                  "ERROR",
                  "RECEIVED",
                  "PENDING_ACCEPT",
                  "ACCEPTED",
                  "PENDING_APPROVAL",
                  "INVALID_CONFIGURATION",
                  "PENDING_AGROMATIC_DILIGENCE",
                  "WAITING_DILIGENCE",
                  "DILIGENCE_VALIDATED",
                  "DILIGENCE_DOCUMENTS_AVAILABLE",
                  "INCONSISTENT",
                  "REJECTED_DILIGENCE",
                  "SYNC_CPR",
                ].map((option) => (
                  <OOption key={option} value={option}>
                    {option}
                  </OOption>
                ))}
              </ORFieldSelect>
            ) : (
              limitRequest?.status
            ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Rating",
          value: limitRequest.ratingType || "Não informado",
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    provision: {
      visible: !!limitRequest?.debtorsProvision,
      title: "Provisão de Rating",
      items: [
        {
          label: "Tipo de Crédito",
          value: getCreditType(limitRequest.debtorsProvision?.creditType),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Classificação do Carteira",
          value: limitRequest.debtorsProvision?.portfolioClassification,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Rating da Contraparte",
          value: limitRequest.debtorsProvision?.ratingCounterparty,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Exposição à Inadimplência",
          value: masks.percentage(
            limitRequest.debtorsProvision?.exposureAtDefault,
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Probabilidade de Incumprimento",
          value: `${masks.percentage(
            limitRequest.debtorsProvision?.defaultProbability,
          )}`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Perda em Função da Inadimplência",
          value: `${masks.percentage(
            limitRequest.debtorsProvision?.lostGivenDefault,
          )}`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Perda Esperada",
          value: `${masks.percentage(
            limitRequest.debtorsProvision?.expectedLoss,
          )}`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Fase",
          value: limitRequest.debtorsProvision?.stage,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Perda a Incorrer",
          value: `${masks.percentage(
            limitRequest.debtorsProvision?.incurredLoss,
          )}`,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    value: {
      visible: !!limitRequest?.rate?.quotes.length,
      title: "Opções de parcelamento",
      items: limitRequest?.rate?.quotes.flatMap((quote) => {
        return [
          {
            value: `De: ${quote.minTerm ?? ""}`,
            grid: { md: 6, xl: 3 },
          },
          {
            value: `Até: ${quote.maxTerm ?? ""}`,
            grid: { md: 6, xl: 3 },
          },
          {
            value: `Taxa: ${masks.percentage(
              quote.monthlyValue,
            )} (a.${parsedAmortizationFrequency(
              quote.amortizationFrequency,
            )}.)`,
            grid: { md: 6, xl: 3 },
          },
          {
            value: `Carência: ${quote.gracePeriod ?? ""} ${
              quote.gracePeriod === 1 ? "mês" : "meses"
            }`,
            grid: { md: 6, xl: 3 },
          },
        ];
      }),
    },
  } satisfies DetailsFields;
};
