import { ORFieldCheckboxGroup } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { GridTemplate } from "templates/grid-template";
import { customer } from "contexts/customer";
import {
  autoRelocationGridClient,
  dataSourceClient,
} from "./auto-relocation.grid";
import { useAutoRelocation } from "./auto-relocation.hook";
import {
  DisableRelocationButton,
  EnableDailyRelocationButton,
  EnableWeeklyRelocationButton,
  RelocationTimeModal,
} from "./_compose";

export const AutoRelocationClient = () => {
  const gridRef = useGridRef();

  const [relocationPeriod, setRelocationPeriod] = useState<
    "semanal" | "diario"
  >("diario");

  const { disableAutoRelocation, enableAutoRelocation, form, loading } =
    useAutoRelocation();

  return (
    <FormProvider {...form}>
      <GridTemplate
        pageTitle={<PageTitle title="Configurar realocação automática" />}
        actions={
          <>
            <EnableWeeklyRelocationButton
              loading={loading}
              setRelocationPeriod={setRelocationPeriod}
            />
            <EnableDailyRelocationButton
              loading={loading}
              setRelocationPeriod={setRelocationPeriod}
            />
            <DisableRelocationButton
              loading={loading}
              submit={disableAutoRelocation}
            />
          </>
        }
        gridRef={gridRef}
        showRefreshButton
        showExportButton
      >
        <ORFieldCheckboxGroup
          dataAction="auto_realocacao:checkbox:empresas"
          dataLabel="empresas"
          id="companyIds"
          name="companyIds"
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={autoRelocationGridClient}
            dataSource={dataSourceClient(customer.value?.identification)}
          />
        </ORFieldCheckboxGroup>
      </GridTemplate>
      <RelocationTimeModal
        form={form}
        relocationPeriod={relocationPeriod}
        submit={enableAutoRelocation}
      />
    </FormProvider>
  );
};
