import { OSkeletonLine } from "@maestro/react";

export const ConditionsSkeleton = () => {
  return (
    <div className="d-flex flex-column gap-3 w-100">
      <div className="w-20">
        <OSkeletonLine size="xs" />
      </div>
      <div className="w-100 ">
        <OSkeletonLine size="sm" />
      </div>
    </div>
  );
};
