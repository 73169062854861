import { client } from "../client";
import {
  DeleteOnboardingOfficerBody,
  DeleteOnboardingPartnerBody,
  GetOnboardingCedenteCompletoParams,
  PostOnboardingBatchBody,
  PostOnboardingBatchVerifyBody,
  PostOnboardingOfficerUpsertBody,
  PostOnboardingPartnerConfigurationBody,
  PostOnboardingPartnerUpsertBody,
  PostOnboardingPayerLowBody,
  PostOnboardingPayerLowIsUpsertAvailableBody,
  PostOnboardingWorkflowConfigurationBody,
  PutOnboardingCedenteCompletoByTaxId,
  PutOnboardingFileUploadToTempBucketBody,
} from "./models/requests";
import {
  DeleteOnboardingOfficerResponse,
  DeleteOnboardingPartnerResponse,
  GetOnboardingCedenteCompletoByTaxIdResponse,
  GetOnboardingCedenteCompletoResponse,
  GetOnboardingOfficerListResponse,
  GetOnboardingPartnerConfigurationByPkBySkResponse,
  GetOnboardingPartnerConfigurationResponse,
  GetOnboardingPartnerConfigurationWithoutStepsResponse,
  GetOnboardingPartnerListResponse,
  GetOnboardingPayerLowMandatoryFieldsResponse,
  GetOnboardingWorkflowConfigurationByPkBySkResponse,
  GetOnboardingWorkflowConfigurationResponse,
  PostOnboardingBatchResponse,
  PostOnboardingBatchVerifyResponse,
  PostOnboardingPartnerConfigurationResponse,
  PostOnboardingPayerLowIsUpsertAvailableResponse,
  PostOnboardingPayerLowResponse,
  PutOnboardingFileUploadToTempBucketResponse,
} from "./models/responses";
import { scfDevelopersEndpoints } from "./scf-developers.endpoints";

export const scfDevelopersService = {
  getConfigurations() {
    return client.get<GetOnboardingPartnerConfigurationResponse>(
      scfDevelopersEndpoints.onboarding.partnerConfiguration.endpoint,
    );
  },
  getConfiguration(authClientId: string, configurationId: string) {
    return client.get<GetOnboardingPartnerConfigurationByPkBySkResponse>(
      scfDevelopersEndpoints.onboarding.partnerConfiguration.byAuthClientId.byConfigurationId(
        authClientId,
        configurationId,
      ),
    );
  },
  upsertPartnerConfiguration(body: PostOnboardingPartnerConfigurationBody) {
    return client.post<PostOnboardingPartnerConfigurationResponse>(
      scfDevelopersEndpoints.onboarding.partnerConfiguration.endpoint,
      body,
    );
  },
  getConfigurationsWithoutSteps() {
    return client.get<GetOnboardingPartnerConfigurationWithoutStepsResponse>(
      scfDevelopersEndpoints.onboarding.partnerConfiguration.withoutSteps,
    );
  },
  verifyBatches(payload: PostOnboardingBatchVerifyBody) {
    return client.post<PostOnboardingBatchVerifyResponse>(
      scfDevelopersEndpoints.onboarding.batch.verify,
      payload,
    );
  },
  importBatches(payload: PostOnboardingBatchBody) {
    return client.post<PostOnboardingBatchResponse>(
      scfDevelopersEndpoints.onboarding.batch.endpoint,
      payload,
    );
  },
  fetchPartnersList() {
    return client.get<GetOnboardingPartnerListResponse>(
      scfDevelopersEndpoints.onboarding.partner.list,
    );
  },
  getFields() {
    return client.get<GetOnboardingPayerLowMandatoryFieldsResponse>(
      scfDevelopersEndpoints.onboarding.payerLow.mandatoryFields,
    );
  },
  upsertPartner(body: PostOnboardingPartnerUpsertBody) {
    return client.post(scfDevelopersEndpoints.onboarding.partner.upsert, body);
  },
  removePartner(body: DeleteOnboardingPartnerBody) {
    return client.delete<DeleteOnboardingPartnerResponse>(
      scfDevelopersEndpoints.onboarding.partner.endpoint,
      { data: body },
    );
  },
  fetchOfficers() {
    return client.get<GetOnboardingOfficerListResponse>(
      scfDevelopersEndpoints.onboarding.officer.list,
    );
  },
  upsertOfficer(body: PostOnboardingOfficerUpsertBody) {
    return client.post(scfDevelopersEndpoints.onboarding.officer.upsert, body);
  },
  removeOfficer(body: DeleteOnboardingOfficerBody) {
    return client.delete<DeleteOnboardingOfficerResponse>(
      scfDevelopersEndpoints.onboarding.officer.endpoint,
      { data: body },
    );
  },
  checkIfCanUpsert(body: PostOnboardingPayerLowIsUpsertAvailableBody) {
    return client.post<PostOnboardingPayerLowIsUpsertAvailableResponse>(
      scfDevelopersEndpoints.onboarding.payerLow.isUpsertAvailable,
      body,
    );
  },
  importPayer(body: PostOnboardingPayerLowBody) {
    return client.post<PostOnboardingPayerLowResponse>(
      scfDevelopersEndpoints.onboarding.payerLow.endpoint,
      body,
    );
  },
  fileUpload(body: PutOnboardingFileUploadToTempBucketBody) {
    return client.put<PutOnboardingFileUploadToTempBucketResponse>(
      scfDevelopersEndpoints.onboarding.file.uploadToTempBucket,
      body,
    );
  },
  getAllCedenteCompletos(params: GetOnboardingCedenteCompletoParams) {
    return client.get<GetOnboardingCedenteCompletoResponse>(
      scfDevelopersEndpoints.onboarding.cedenteCompleto.endpoint,
      {
        params,
      },
    );
  },
  fetchCedenteCompletoInfo(taxId: string) {
    return client.get<GetOnboardingCedenteCompletoByTaxIdResponse>(
      scfDevelopersEndpoints.onboarding.cedenteCompleto.byTaxId(taxId),
    );
  },
  updateCedenteCompleto(body: PutOnboardingCedenteCompletoByTaxId) {
    return client.put(
      scfDevelopersEndpoints.onboarding.cedenteCompleto.endpoint,
      body,
    );
  },
  getWorkflowConfigurations() {
    return client.get<GetOnboardingWorkflowConfigurationResponse>(
      scfDevelopersEndpoints.onboarding.workflowConfiguration.endpoint,
    );
  },
  getWorkflowConfiguration(
    authClientId: string,
    configurationId: string,
    integrationType: string,
  ) {
    return client.get<GetOnboardingWorkflowConfigurationByPkBySkResponse>(
      scfDevelopersEndpoints.onboarding.workflowConfiguration.byAuthClientId.byConfigurationId.byIntegrationType(
        authClientId,
        configurationId,
        integrationType,
      ),
    );
  },
  upsertWorkflowConfiguration(body: PostOnboardingWorkflowConfigurationBody) {
    return client.post<PostOnboardingPartnerConfigurationResponse>(
      scfDevelopersEndpoints.onboarding.workflowConfiguration.endpoint,
      body,
    );
  },
};
