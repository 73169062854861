import styled from "styled-components";

export const StyledHeader = styled.div`
  background: var(--primary-extended-90, #0b2859);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 15rem;

  span {
    color: white;
  }
`;
