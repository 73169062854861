import { receiveMessage } from "components/portal-embeded/portal-embeded.utils";
import { useBehavior } from "contexts/behavior";
import { Behaviors } from "contexts/behavior/types";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { service } from "services";
import { isProd } from "utils/environments";

export const useWalletDefense = (identification: string | null) => {
  const { behavior } = useBehavior();
  const navigate = useNavigate();

  const embeddedRoute = useMemo(() => {
    const channelAndView =
      behavior.id === Behaviors.Corban ? "rmadmin/originador" : "admin/admin";
    const portfolioDefenseWfId = isProd()
      ? "735f7482-96ad-4817-883d-15c747e7f7ad"
      : "80fab194-7815-40d5-914f-c95cec996902";
    const scopedParam = identification ? `?scoped=${identification}` : "";

    return `/emprestimos/workflows/${channelAndView}/${portfolioDefenseWfId}${scopedParam}`;
  }, [identification, behavior.id]);

  function getRouteByIdentification(identification: string | null) {
    return identification
      ? `/duplicatas/cliente/informacoes?select-customer=${identification}`
      : "/duplicatas/produto/carteiras";
  }

  const routeCb = receiveMessage("WORKFLOW_BACK_BUTTON_EVENT", () => {
    navigate(getRouteByIdentification(identification));
  });

  const {
    value: partnerIdentification,
    callService: getPartnerByAuthOperator,
    loading,
    hasError,
  } = useServiceCall(service.b2bCore.getPartnerByAuthOperator);

  return {
    embeddedRoute,
    routeCb,
    partnerIdentification,
    getPartnerByAuthOperator,
    loading,
    hasError,
  };
};

