import {
  modalManager,
  OButton,
  OCard,
  OCardBody,
  OCol,
  OField,
  OIcon,
  OOption,
  ORFieldSelect,
  ORow,
  OTooltip,
} from "@maestro/react";
import { FieldArrayWithId } from "react-hook-form";
import {
  DocumentSubtypeMap,
  documentTypeMap,
} from "../../../../edit-cedente-completo.utils";
import { FilesModal } from "../../../files-modal";
import { useRepresentativeDocument } from "./representatives-document.hook";

interface DocumentFieldProps {
  fields: FieldArrayWithId<
    ScfDevelopers.UpdateCedenteCompleto,
    `representatives.${number}.document.files`,
    "id"
  >[];
  handleAddFile: (file: File) => Promise<void>;
  handleRemoveFile: (fileIndex: number) => void;
  index: number;
  loading: boolean;
}

export const RepresentativeDocument = ({
  fields,
  index,
  handleAddFile,
  handleRemoveFile,
  loading,
}: DocumentFieldProps) => {
  const { fileError, possibleDocumentSubtypes } =
    useRepresentativeDocument(index);

  return (
    <OCard>
      <OCardBody>
        <ORow>
          <OCol xs={3}>
            <OField
              htmlFor={`representatives.${index}.document.status_icon`}
              label="Status do documento"
              className="d-flex flex-column align-items-start gap-2"
            >
              <div className="d-flex flex-row gap-2 align-items-center">
                <OIcon
                  id={`representatives.${index}.document.status_icon`}
                  category="orq"
                  icon={fileError ? "orq-close" : "orq-check"}
                  type={fileError ? "danger" : "success"}
                  size="xxl"
                />
                {fileError && (
                  <OTooltip floating>
                    <span slot="tooltip-content">{fileError}</span>
                    <OIcon category="orq" icon="orq-info" size="lg" />
                  </OTooltip>
                )}
              </div>
            </OField>
          </OCol>
          <OCol xs={3}>
            <ORFieldSelect
              id={`representatives.${index}.document.type`}
              name={`representatives.${index}.document.type`}
              label="Tipo de documento"
            >
              {Object.entries(documentTypeMap).map(([value, label]) => (
                <OOption key={value} value={value}>
                  {label}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
          <OCol xs={3}>
            <ORFieldSelect
              id={`representatives.${index}.document.subType`}
              name={`representatives.${index}.document.subType`}
              label="Subtipo de documento"
              disabled={!possibleDocumentSubtypes}
            >
              {possibleDocumentSubtypes?.map((document) => (
                <OOption key={document} value={document}>
                  {DocumentSubtypeMap[document]}
                </OOption>
              ))}
            </ORFieldSelect>
          </OCol>
          <OCol
            xs={3}
            className="d-flex flex-row align-items-center justify-content-end"
          >
            <OButton
              type="tertiary"
              onClick={() =>
                modalManager.show(
                  `representatives.${index}.document-files-modal`,
                )
              }
            >
              Ver arquivos anexados
            </OButton>
          </OCol>
        </ORow>
      </OCardBody>
      <FilesModal
        fieldName={`representatives.${index}.document`}
        fields={fields}
        handleAddFile={handleAddFile}
        handleRemoveFile={handleRemoveFile}
        loading={loading}
      />
    </OCard>
  );
};
